import { SxProps } from "@mui/material";
import color_pallette from "theme/color_pallette";

const styles: Record<string, SxProps> = {
  container: {},
  actions: { width: 25, opacity: 0.7, height: 25, cursor: "pointer" },
  filledIc: {
    width: 25,
    opacity: 0.7,
    height: 25,
    cursor: "pointer",
    color: color_pallette.primary,
  },
  actionLabel: {
    cursor: "pointer",
  },
};

export default styles;