import { FC } from "react";
import Box from "@mui/material/Box";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import styles from "./styles";
import { FormikValues, useFormikContext } from "formik";
import { CHAT_FORM } from "../footer_section/form";
/* import { CHAT_FORM } from "../footer_section/form"; */

const ChatSubmitButton: FC = () => {
  const { handleSubmit, values, resetForm /* values */ } =
    useFormikContext<FormikValues>();

  const handleFormSubmit = () => {
    handleSubmit(values[CHAT_FORM.message]);
    setTimeout(() => {
      resetForm();
    }, 2000);
  };

  return (
    <Box onClick={handleFormSubmit} sx={styles.sendButton}>
      <SendRoundedIcon sx={{ cursor: "pointer" }} />
    </Box>
  );
};

export default ChatSubmitButton;
