import * as yup from "yup";

export enum EditProfileFormNames {
  username = "username",
  bio = "bio",
  org = "org",
  profilePicture = "profilePicture",
  coverPicture = "coverImage",
}

const validationSchema: yup.Schema = yup.object({
  [EditProfileFormNames.username]: yup.string(),
  [EditProfileFormNames.bio]: yup.string(),
  [EditProfileFormNames.org]: yup.string(),
  [EditProfileFormNames.profilePicture]: yup.string(),
  [EditProfileFormNames.coverPicture]: yup.string(),
});

const initialValues: yup.InferType<typeof validationSchema> = {
  [EditProfileFormNames.username]: "",
  [EditProfileFormNames.bio]: "",
  [EditProfileFormNames.org]: "",
  [EditProfileFormNames.coverPicture]: "",
  [EditProfileFormNames.profilePicture]: "",
};

export { validationSchema, initialValues };
