import { FC } from "react";
import gpsSvg from "assets/svgs/gps_gradient.svg";
import online from "assets/svgs/support.svg";
import { WATCHNWO_FORM_NAME } from "./form";
import { FormikValues, useFormikContext } from "formik";
import { Stack } from "@mui/material";
import EventType from "./event_type";

const Form: FC = () => {
  const { setFieldValue } = useFormikContext<FormikValues>();

  const handleCheck = (type: WATCHNWO_FORM_NAME) => {
    setFieldValue(WATCHNWO_FORM_NAME.TYPE, type);
  };

  return (
    <>
      <Stack
        direction="row"
        justifyContent="center"
        marginTop={5}
        alignItems="center"
        columnGap={2}
      >
        <EventType
          name={WATCHNWO_FORM_NAME.ONLINE}
          onCheck={() => handleCheck(WATCHNWO_FORM_NAME.ONLINE)}
          label="Attende Online"
          img={online}
        />
        <EventType
          name={WATCHNWO_FORM_NAME.LOCATION}
          onCheck={() => handleCheck(WATCHNWO_FORM_NAME.LOCATION)}
          label="Attende On Site"
          img={gpsSvg}
        />
      </Stack>
    </>
  );
};

export default Form;
