import { ComponentType, useEffect } from "react";
import { useAppSelector } from "store/hooks";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "navigation/routes";

function withAuth(Component: ComponentType) {
  return () => {
    const token = useAppSelector(
      (state) => state.global.credentials.token.access_token
    );
    const navigate = useNavigate();

    useEffect(() => {
      if (!token) navigate(NavigationRoutes.login);
    }, []);

    return <Component />;
  };
}

export default withAuth;
