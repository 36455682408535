import { Identification } from "types/identification";
import { NAMESPACES } from "../namespaces";

export const shareServiceUrls = {
  postShare: () => `${NAMESPACES.share}/share`,

  getVideoSharesCount: (id: Identification) =>
    `${NAMESPACES.share}/shares/count/video/${id}`,
  getUserSharesCount: (id: Identification) =>
    `${NAMESPACES.share}/shares/user/count/${id}`,
};
