import { FC } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { globalSliceActions } from "store/global_slice";
import { SubMenuType } from "../menu_items/data";
import SubMenu from "../sub_menu";
import { useNavigate } from "react-router-dom";
import color_pallette from "theme/color_pallette";
import styles from "./styles";
import KeyboardArrowLefttIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { FormattedMessage } from "react-intl";
import { LanguageDirection } from "store/global_slice/types";

type Props = {
  frontLabel: SubMenuType;
  subItems?: Array<SubMenuType>;
};

const Label: FC<{
  label: SubMenuType;
  hightlight: boolean;
  canNavigate: boolean;
}> = ({ label, hightlight, canNavigate }) => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const handleActiveItem = () => {
    dispatch(globalSliceActions.setActiveMenuItem(label.label));
    if (canNavigate) navigate(label.path);
  };

  const { direction } = useAppSelector((state) => state.global.lang);

  return (
    <Box
      onClick={handleActiveItem}
      sx={{
        ...styles.labelWrapper,
        backgroundColor: hightlight ? color_pallette.primary : "",
        color: hightlight ? "white" : "black",
        paddingInline: hightlight ? 1.5 : 0.5,
      }}
    >
      <Typography>
        <FormattedMessage id={label.label} />
      </Typography>
      {hightlight && !canNavigate && <KeyboardArrowDownIcon />}
      {!hightlight &&
        !canNavigate &&
        (direction == LanguageDirection.leftToRight ? (
          <KeyboardArrowRightIcon />
        ) : (
          <KeyboardArrowLefttIcon />
        ))}
    </Box>
  );
};

const MenuItem: FC<Props> = ({ frontLabel, subItems }) => {
  const activeMenuItem = useAppSelector((state) => state.global.activeMenuItem);

  const isHightlighed = (): boolean => activeMenuItem === frontLabel.label;

  return (
    <>
      {isHightlighed() && subItems ? (
        <Box position="relative">
          <Label
            canNavigate={false}
            label={frontLabel}
            hightlight={isHightlighed()}
          />
          <SubMenu subItems={subItems} />
        </Box>
      ) : (
        <Label
          canNavigate={subItems == undefined}
          label={frontLabel}
          hightlight={isHightlighed()}
        />
      )}
    </>
  );
};

export default MenuItem;
