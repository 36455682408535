import { defineMessages } from 'react-intl';

const scope = 'FilterSection';

export default defineMessages({
  most_viewed: {
    id: `${scope}.most_viewed`,
    defaultMessage: "Most Viewed",
  },
  most_recent: {
    id: `${scope}.most_recent`,
    defaultMessage: "Most Recent",
  },
  all: {
    id: `${scope}.all`,
    defaultMessage: "All",
  },
  popular: {
    id: `${scope}.popular`,
    defaultMessage: "Popular",
  },
  high_rated: {
    id: `${scope}.high_rated`,
    defaultMessage: "High Rating",
  },
});