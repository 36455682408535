import { ChangeEvent, FC, useRef } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import InputField from "common/input_field";
import styles from "./styles";
import { NOMINATION_FORM_PROPERTIES } from "store/nomination_slice/form";
import { FormikValues, useFormikContext } from "formik";
import { countryList } from "utils/data";
import { Typography } from "@mui/material";
import Button from "components/custom_button";

const GeneralInfoForm: FC = () => {
  const { values, handleChange, setFieldValue, handleBlur } =
    useFormikContext<FormikValues>();

  const inputRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    inputRef?.current?.click();
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = e.target.files?.item(0);
    if (uploadedFile != null)
      setFieldValue(NOMINATION_FORM_PROPERTIES.WEB_LINKS_FILE, uploadedFile);
  };

  return (
    <Box sx={styles.container}>
      <Typography sx={{ fontWeight: "500" }}>
        My Personal Information
      </Typography>
      <Grid container columnGap={1.5} rowGap={1} sx={{ marginTop: 2 }}>
        <Grid item xs={12} md={5.7} lg={5.8}>
          <InputField
            inputMode="text"
            label="First Name"
            name={NOMINATION_FORM_PROPERTIES.FIRST_NAME}
          />
        </Grid>
        <Grid item xs={12} md={5.7} lg={5.8}>
          <InputField
            inputMode="text"
            label="Last Name"
            name={NOMINATION_FORM_PROPERTIES.LAST_NAME}
          />
        </Grid>
        <Grid item xs={12} md={5.7} lg={5.8}>
          <InputField
            inputMode="text"
            label="City"
            name={NOMINATION_FORM_PROPERTIES.CITY}
          />
        </Grid>
        <Grid item xs={12} md={5.7} lg={5.8}>
          <Select
            name={NOMINATION_FORM_PROPERTIES.COUNTRY}
            label="Country"
            defaultValue={1}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values[NOMINATION_FORM_PROPERTIES.COUNTRY]}
            sx={{ backgroundColor: "white" }}
            fullWidth
          >
            {countryList.map((entry) => (
              <MenuItem key={entry.name} value={entry.name}>
                {entry.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} md={5.7} lg={5.8}>
          <InputField
            inputMode="text"
            label="Email"
            name={NOMINATION_FORM_PROPERTIES.EMAIL}
          />
        </Grid>
        <Grid item xs={12} md={5.7} lg={5.8}>
          <InputField
            inputMode="text"
            label="Telephone Number"
            name={NOMINATION_FORM_PROPERTIES.TELEPHONE}
          />
        </Grid>
        <Grid mt={2} item xs={12} md={5.7} lg={5.8}>
          <Box mt={3}>
            <Typography mb={1}>
              Please share any web links or articles that pertain to the
              speaker?
            </Typography>
            <InputField
              placeholder="Web Link"
              name={NOMINATION_FORM_PROPERTIES.WEB_LINKS}
            />
            <input
              type="file"
              style={{ display: "none" }}
              ref={inputRef}
              onChange={handleFileChange}
              name={NOMINATION_FORM_PROPERTIES.WEB_LINKS_FILE}
            />
            <Button
              onClick={handleClick}
              extraStyles={styles.mg}
              label="Attach File"
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default GeneralInfoForm;
