import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    margin: "53px auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "80%",
  },
  headerTitle: {
    color: "#5D8495",
    marginTop: "50px",
    fontFamily: "Nizzar ,K2D, Inter",
    marginBottom: { xs: "34px", lg: 1 },
    fontSize: {
      xs: 45,
      md: 73,
    },
  },
  headerHightlighted: {
    color: "#008755",
    fontSize: {
      xs: 45,
      md: 73,
    },
  },
};

export default styles;
