import { FC } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import styles from "./styles";
import { FormattedMessage } from "react-intl";
import messages from "../messages";

const HeaderSection: FC = () => {
  return (
    <Box sx={styles.search}>
      <Typography sx={styles.label}>
        <FormattedMessage id={messages.search.id} />
      </Typography>
    </Box>
  );
};

export default HeaderSection;
