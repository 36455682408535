import { defineMessages } from 'react-intl';

const scope = 'InsightSection';

export default defineMessages({
  profileInsights: {
    id: `${scope}.profileInsights`,
    defaultMessage: "Profile Insights",
  },
  students: {
    id: `${scope}.students`,
    defaultMessage: "Total Students Engaged",
  },
  see_more: {
    id: `${scope}.see_more`,
    defaultMessage: "See More",
  },
  active_users: {
    id: `${scope}.active_users`,
    defaultMessage: "Active Users",
  },
  watched: {
    id: `${scope}.watched`,
    defaultMessage: "Watched",
  },
  rating: {
    id: `${scope}.rating`,
    defaultMessage: "Over All Rating",
  },
  insights: {
    id: `${scope}.insights`,
    defaultMessage: "Insights",
  },
});