import { NavigationRoutes } from "navigation/routes";
import { ScreenHighlight } from "types/screen_highlight";

export const data: Array<ScreenHighlight> = [
  {
    label: "Services",
    path: NavigationRoutes.home,
  },
  {
    label: "Lessons to Learn",
    path: NavigationRoutes.police_speakers,
  },
];
