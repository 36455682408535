import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {},
  sectionContainer: {
    display: "flex",
    flexDirection: {
      xs: "column",
      md: "row",
    },
    flexWrap: {
      xs: "wrap",
      lg: "nowrap",
    },
    justifyContent: { xs: "center", lg: "space-between" },
    columnGap: {
      md: 2,
      lg: 0,
    },
    paddingInline: {
      md: 2,
      lg: 0,
      xl: 1,
    },
    marginTop: {
      xs: 10,
      lg: 5,
    },
    width: {
      lg: "99%",
      xl: "100%",
    },
  },
};

export default styles;
