import { defineMessages } from "react-intl";

const scope = "Blog";

export default defineMessages({
  Search: {
    id: `${scope}.Search`,
    defaultMessage: "Search",
  },
  Trending: {
    id: `${scope}.Trending`,
    defaultMessage: "Trending",
  },
  Blogs: {
    id: `${scope}.Blogs`,
    defaultMessage: "Blogs",
  },
});
