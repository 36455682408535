import { FC, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
/* import Rating from "@mui/material/Rating"; */
import Stack from "@mui/material/Stack";
import styles from "./styles";
import IconText from "./icon_text";
import { ReactSVG } from "react-svg";
import Button from "components/custom_button";
import { useAppDispatch, useAppSelector } from "store/hooks";
import country from "assets/svgs/location.svg";
import bag from "assets/svgs/travel_bag.svg";
import shares from "assets/svgs/share_filled.svg";
/* import star from "assets/svgs/star.svg"; */
import viewIc from "assets/svgs/view.svg";
import { ProfileSliceActions } from "store/profile_slice";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { modalSliceActions } from "store/modal_slice";
import ShareModal from "components/share_modal";
import { useGetProfileViewCountQuery } from "store/api/view_service/endpoints";
import { FormattedMessage, useIntl } from "react-intl";
import messages from "./messages";
import {
  useAddShareMutation,
  useGetUserSharesCountQuery,
} from "store/api/share_service/endpoints";
import { MEDIA_TYPE } from "types/media_type";
import { verificationSliceActions } from "store/verification_slice";
import { NavigationRoutes } from "navigation/routes";
import { useNavigate } from "react-router-dom";

const BodySection: FC = () => {
  const dispatch = useAppDispatch();

  const { speakerProfile, credentials } = useAppSelector(
    (state) => state.global
  );

  const { verifyOTP } = useAppSelector((state) => state.otp);

  const navigate = useNavigate();

  const handleEditProfile = () => {
    if (!verifyOTP) {
      dispatch(ProfileSliceActions.showEditProfileModal());
      return;
    } else {
      dispatch(verificationSliceActions.setPath(NavigationRoutes.profile));
      dispatch(verificationSliceActions.setEmail(speakerProfile.email));
      dispatch(verificationSliceActions.setPhone(speakerProfile.mobile));

      dispatch(
        verificationSliceActions.setNavigationProps({
          state: {
            editProfile: true,
            noOTP: false,
            email: speakerProfile.email,
            mobile: speakerProfile.mobile,
          },
        })
      );

      navigate(NavigationRoutes.otp_confirmation, {
        state: {
          mobile: speakerProfile.mobile,
          email: speakerProfile.email,
        },
      });
    }
  };

  const { data: sharesCount } = useGetUserSharesCountQuery(
    {
      token: credentials.token.access_token,
      id: speakerProfile.customerId,
    },
    {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
    }
  );

  const [share] = useAddShareMutation();

  const handleShare = async () => {
    dispatch(modalSliceActions.shareVideoStep(true));
    dispatch(modalSliceActions.setSharedContent(speakerProfile.about));
    try {
      await share({
        body: {
          ownerId: speakerProfile.customerId,
          userId: speakerProfile.customerId,
          videoId: speakerProfile.customerId,
          digitalMediumType: MEDIA_TYPE.USER_PROFILE,
        },
        token: credentials.token.access_token,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const { data: viewsCount } = useGetProfileViewCountQuery({
    id: speakerProfile.customerId,
    token: credentials.token.access_token,
  });

  useEffect(() => {
    return () => {
      dispatch(modalSliceActions.shareVideoStep(false));
    };
  }, [dispatch]);

  const intl = useIntl();

  return (
    <Box sx={styles.container}>
      <ShareModal />
      <Typography sx={styles.headerTitle}>{speakerProfile.name}</Typography>
      <Typography sx={styles.headerSubTitle}>{speakerProfile.org}</Typography>
      <Stack sx={styles.statsContainer}>
        <IconText iconPath={bag} label={speakerProfile.country} />
        <IconText iconPath={country} label={speakerProfile.nationality} />
      </Stack>
      <Stack sx={{ paddingInline: 2, marginTop: 1.5 }}>
        <Typography sx={styles.bioHeader}>Bio</Typography>
        {speakerProfile.about ? (
          <Box sx={styles.bioContentContainer}>
            <Typography sx={styles.bioContent}>
              {speakerProfile.about}
            </Typography>
          </Box>
        ) : (
          <Stack
            direction="row"
            alignItems="center"
            onClick={handleEditProfile}
            sx={styles.emptyBio}
          >
            <Typography>
              <FormattedMessage id={messages.editBio.id} />
            </Typography>
            <ModeEditIcon sx={styles.editIcon} />
          </Stack>
        )}
      </Stack>
      <Stack sx={styles.stackContainer}>
        <Stack justifyContent="center" direction="row" columnGap={2}>
          <IconText
            iconPath={viewIc}
            label={`${viewsCount ?? 0} ${intl.formatMessage(messages.views)}`}
          />
          <IconText
            iconPath={shares}
            label={`${sharesCount ?? 0} ${intl.formatMessage(messages.shares)}`}
          />
          {/* <IconText iconPath={star} label="0 Rating" /> */}
        </Stack>
        <Stack sx={styles.footerContainer}>
          <>
            <ReactSVG style={{ cursor: "pointer" }} src={shares} />
            <Button
              label="Share"
              onClick={handleShare}
              variant="outlined"
              extraStyles={styles.shareButton}
            />
          </>
          {/* <>
            <Typography>Rate</Typography>
            <Rating readOnly size="small" sx={styles.ratingContainer} />
          </> */}
        </Stack>
      </Stack>
    </Box>
  );
};

export default BodySection;
