import { SxProps } from "@mui/material";
import color_pallette from "theme/color_pallette";

const styles: Record<string, SxProps> = {
  container: {
    backgroundColor: color_pallette.backgroundGray,
    borderRadius: 3,
    overflow: "hidden",
    paddingInline: 2,
    paddingBlock: 3,
    width: 200,
  },
  hostName: {
    fontSize: {
      xs: 25,
    },
    textAlign: "center",
    marginTop: 1.5,
  },
  sectionHeader: {
    fontSize: { xs: 30 },
    marginInlineStart: { lg: 5 },
    whiteSpace: "nowrap",
    marginBlock: 3,
    fontWeight: "800",
  },
  hostImage: {
    width: 120,
    height: 120,
  },
};

export default styles;
