import { defineMessages } from "react-intl";

const scope = "SidebarSection";

export default defineMessages({
  form_initialzed: {
    id: `${scope}.form_initialzed`,
    defaultMessage: "Form Reinitialized",
  },
  rate: {
    id: `${scope}.rate`,
    defaultMessage: "By Rating",
  },
  speaker: {
    id: `${scope}.speaker`,
    defaultMessage: "By Speaker",
  },
  sort: {
    id: `${scope}.sort`,
    defaultMessage: "Sort & Filter All",
  },
  reset: {
    id: `${scope}.reset`,
    defaultMessage: "Reset",
  },
  select_date: {
    id: `${scope}.select_date`,
    defaultMessage: "Select date",
  },
  category: {
    id: `${scope}.category`,
    defaultMessage: "By Category",
  },
});
