import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    height: 160,
    paddingBottom: 1.5,
    paddingTop: 1,
    paddingInline: 3,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  progress: {
    width: "100%",
    background:
      "linear-gradient(117deg, #227C5A 22.35%, rgba(34, 124, 90, 0) 157.61%)",
  },
};

export default styles;
