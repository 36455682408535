import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    display: "flex",
    maxWidth: {
      md: "95%",
      lg: "100%",
    },
    marginTop: { xs: 2, lg: 2, xl: 2 },
    flexDirection: {
      xs: "column",
      md: "row",
    },
    columnGap: {
      md: 1,
      lg: 0,
      xl: 1.5,
    },
    alignItems: {
      xs: "flex-start",
      sm: "center",
      md: "flex-start",
      xl: "center",
    },
    paddingBottom: {
      xs: 2,
      lg: 9,
      xl: 0,
    },
  },
  chronoLabel: {
    fontSize: {
      xl: 15,
    },
  },
  chronoBox: {
    width: {
      xs: 75,
      lg: 90,
    },
    height: {
      xs: 75,
      lg: 90,
    },
    fontSize: {
      xs: 45,
      lg: 45,
    },
  },
  chronoHeader: {
    fontSize: {
      xl: 20,
    },
  },
  bodyStyles: {
    height: {
      sm: 290,
      md: 340,
      lg: 350,
      xl: 370,
    },
    rowGap: {
      xs: 1.5,
    },
    width: {
      sm: "70%",
      md: "50%",
    },
    marginTop: {
      lg: 2,
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: {
      xs: "flex-start",
      md: "center",
      lg: "flex-start",
    },
    alignItems: { xs: "center", md: "flex-start" },
  },
};

export default styles;
