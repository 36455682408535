import { defineMessages } from 'react-intl';

const scope = 'HomeAboutSection';

export default defineMessages({
  about: {
    id: `${scope}.about`,
    defaultMessage: "About Live Tips",
  },
  empower: {
    id: `${scope}.empower`,
    defaultMessage: "Empower Your Journey with",
  },
  learnmore: {
    id: `${scope}.learnmore`,
    defaultMessage: "Learn More",
  },
  inspiring: {
    id: `${scope}.inspiring`,
    defaultMessage:
      "Inspiring Videos, Podcasts and Interactive from Top Coaches, Leaders and Speakers",
  },
  desc: {
    id: `${scope}.desc`,
    defaultMessage:
      "Live Tips is a platform offering informative content, live sessions, and streaming videos from popular speakers and coaches in The World .It helps the Communities and professionals aim high and stay motivated by connecting them with industry leaders and fostering personal and professional growth.",
  },
});