import { FC, useEffect } from "react";
import HeroSection from "./hero_section";
import OnlineSection from "./online_section";
import PodcastSection from "./podcast_section";
/* import InteractiveSection from "./interactive_section"; */
import LessonsSection from "./lessons_section";
/* import UpcomingSection from "./upcoming_section";
import LiveSection from "./live_section"; */
/* import MapSection from "./map_section"; */
import { useAppDispatch, useAppSelector } from "store/hooks";
import { globalSliceActions } from "store/global_slice";
import { QueryStatus } from "@reduxjs/toolkit/query";
import { useRefreshTokenMutation } from "store/api/identity_service/endpoints";
/* import CountersSection from "./counters_section"; */
import { useGetBookmarkDetailsQuery } from "store/api/bookmark_service/endpoints";
import { bookmarkSliceActions } from "store/bookmarks_slice";
import { useGetLiveStreamingsQuery } from "store/api/streaming_service/endpoints";
import { LivetipsInteractiveSliceActions } from "store/livetips_interactive_slice";
import { useGetUserByIdQuery } from "store/api/auth_service/endpoints";
import { ROLE } from "types/role";
import HomeAboutSection from "components/home_about_section";
import UpcomingSection from "./upcoming_section";
import LiveSection from "./live_section";
import { verificationSliceActions } from "store/verification_slice";

const Home: FC = () => {
  const {
    speakerProfile,
    credentials: { token },
    permission,
  } = useAppSelector((state) => state.global);

  const [refresh] = useRefreshTokenMutation();

  const { data: bookmarks } = useGetBookmarkDetailsQuery({
    id: speakerProfile.customerId,
    token: token.access_token,
  });

  const { data, isError, status } = useGetUserByIdQuery(
    {
      id: token.userData.username,
      token: token.access_token,
    },
    { refetchOnMountOrArgChange: true, refetchOnFocus: true }
  );

  const dispatch = useAppDispatch();

  const { data: expiredInteractiveResult } = useGetLiveStreamingsQuery({
    token: token.access_token,
    query: {
      is_live: false,
      is_expired: false,
    },
  });

  useEffect(() => {
    if (!isError && data) {
      /* emitToast("success", `Welcome ${data.user.name}`); */
      dispatch(globalSliceActions.submitUserData(data));
    }
    if (bookmarks) {
      dispatch(bookmarkSliceActions.pushBookmarks(bookmarks));
    } else if (status === QueryStatus.rejected && token.access_token) {
      /* emitToast("error", `Something Unexpected Happened !`); */
      refresh({ token: token.access_token });
    }

    if (expiredInteractiveResult?.results) {
      dispatch(
        LivetipsInteractiveSliceActions.setExpiredDate(
          expiredInteractiveResult?.results ?? []
        )
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
    dispatch,
    permission,
    expiredInteractiveResult,
    bookmarks,
    isError,
  ]);

  useEffect(() => {
    dispatch(verificationSliceActions.setVerifyOTP(true));
  }, []);

  return (
    <>
      <HeroSection role={permission} />
      <HomeAboutSection />
      <OnlineSection />
      <PodcastSection />
      {/* <InteractiveSection /> */}
      {permission != ROLE.normal && <LessonsSection />}
      <UpcomingSection />
      <LiveSection />
      {/* <MapSection /> */}
      {/* <CountersSection /> */}
    </>
  );
};

export default Home;
