import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  flex: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 2,
    opacity: 0.6,
  },
  onHover: {
    ":hover": {
      cursor: "pointer",
    },
  },
  textFormControlls: {
    opacity: 0.7,
    fontSize: {
      xs: 14,
    },
  },
};

export default styles;
