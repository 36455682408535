export enum NavigationRoutes {
  home = "/",
  speakers = "/speakers",
  nominations = "/nominations",
  podcasts = "/podcasts",
  upcomingPodcasts = "/upcoming_podcasts",
  interactive = "/interactive",
  joining_list = "/joining_list",
  employees = "/employees",
  finalResetPwd = "/finalResetPwd",
  services = "/services",
  nominationScope = "/nomination_scope",
  outsideLiveTips = "/outside_live_tips",
  insideLiveTips = "/inside_live_tips",
  otp_confirmation = "/otp_confirmation",
  police_speakers = "/police_speakers",
  police_lessons = "/police_lessons",
  alerts = "/my_alerts",
  police_online_videos = "/police_online_videos",
  public_speaker_profile = "/speakers/publicProfile",
  services_online_video = "/services/online_videos",
  services_live_video = "/services/live_videos",
  aboutUs = "/about-us",
  myBookmarks = "/my_bookmarks",
  streaming_online_page = "/live_streaming",
  streaming_podcast_page = "/live_streaming_podcast",
  upcoming_details = "/upcoming_details",
  myComments = "/my_comments",
  nomination_form = "/nomination_form",
  myNotifications = "/my_notifications",
  search = "/search",
  lessons = "/lessonsToLearn",
  blog = "/blog",
  login = "/login",
  signup = "/register",
  forgotPassword = "/login/reset-password",
  joinUs = "/join-us",
  profile = "/me",
  history = "/me/history",
  notifications = "/me/notifications",
  myStreams = "/me/myStreams",
  myVideos = "/user_publications",
  myPodcasts = "/myPodcasts",
  discussions = "/me/discussions",
  language = "/me/language",
  editProfile = "/me/editProfile",
  help = "/me/help",
  logout = "/me/logout",
  not_found = '*',
  police_official_website = "https://www.dubaipolice.gov.ae/wps/portal/home",
}
