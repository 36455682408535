import { FC } from "react";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Grow from "@mui/material/Grow";
import styles from "./styles";
import Button from "components/custom_button";
import { FlattenedMember } from "store/api/membership_service/endpoints";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "navigation/routes";
import avatar from "assets/images/placeholders/thumbnail_pl.webp";
import cropText from "utils/crop_text";
import { Checkbox } from "@mui/material";
import { Identification } from "types/identification";

type Props = FlattenedMember & {
  selected?: boolean;
  handleSelect?: (id: Identification) => void;
  isChecked?: 0 | 1;
};

const SpeakerListItem: FC<Props> = (props) => {
  const { id, name, org, profile_picture } = props;
  const navigate = useNavigate();

  const handleViewProfile = () => {
    if (!id) return;
    navigate(`${NavigationRoutes.public_speaker_profile}/${id}`);
  };

  const handleCheck = () => {
    if (props.handleSelect) props.handleSelect(props.id);
  };

  return (
    <Grow in timeout={700}>
      <Paper
        onClick={
          !props.handleSelect ? () => handleViewProfile() : () => handleCheck()
        }
        sx={styles.container}
      >
        <Box
          component="img"
          width={290}
          alt="Speaker Profile Image"
          sx={{
            objectFit: "cover",
            aspectRatio: "4 / 3",
          }}
          src={profile_picture ?? avatar}
        />
        {props.selected ? (
          <Checkbox
            onChange={() => handleCheck()}
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
            }}
            value={props.isChecked}
            color="secondary"
          />
        ) : null}
        <Typography sx={styles.speakerName}>{name}</Typography>
        <Stack
          sx={styles.footerContainer}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography sx={styles.speakerPosition}>
            {cropText(org, 20)}
          </Typography>
          <Button
            extraStyles={styles.viewProfile}
            label="View Profile"
            onClick={handleViewProfile}
          />
        </Stack>
      </Paper>
    </Grow>
  );
};

export default SpeakerListItem;
