import { FC } from "react";
import Stack from "@mui/material/Stack";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import styles from "./styles";
import { Comment, CommentPostBody } from "store/api/comment_service/types";
import FormContainer from "common/form_container";
import { COMMENT_REPLY_FORM, initialValues, validationSchema } from "./form";
import { FormikValues } from "formik";
import { useAppDispatch, useAppSelector } from "store/hooks";
import InputField from "common/input_field";
import Button from "components/custom_button";
import SubmitButton from "common/submit_button";
import {
  OPTIMISTIC_COMMENT_ID,
  commentSliceActions,
} from "store/comment_slice";
import { usePostCommentMutation } from "store/api/comment_service/endpoints";
import { MEDIA_TYPE } from "types/media_type";

type Props = {
  parent: Comment;
  onAbort: () => void;
  type: MEDIA_TYPE;
};

const CommentReplyForm: FC<Props> = ({ parent, type, onAbort }) => {
  const dispatch = useAppDispatch();

  const { speakerProfile, credentials } = useAppSelector(
    (state) => state.global
  );

  const { currentVideo } = useAppSelector((state) => state.livetipsOnline);

  const [reply] = usePostCommentMutation();

  const handleCommentReply = async (values: FormikValues) => {
    if (parent && parent.commentId == OPTIMISTIC_COMMENT_ID) {
      onAbort();
      console.log("CANNOT REPLY YET");
      return;
    }

    const body: Comment = {
      ownerId: currentVideo.user.customerId,
      videoId: currentVideo.id,
      deleted: false,
      digitalMediumType: type,
      hasParent: true,
      parent,
      children: [],
      likesCount: 0,
      dislikesCount: 0,
      likes: [],
      dislikes: [],
      user: speakerProfile,
      userId: speakerProfile.customerId,
      commentString: values[COMMENT_REPLY_FORM.body],
      commentId: OPTIMISTIC_COMMENT_ID,
    };

    dispatch(
      commentSliceActions.reply({
        parent,
        reply: body,
      })
    );

    const toPostBody: CommentPostBody = {
      ownerId: currentVideo.user.customerId,
      videoId: currentVideo.id,
      digitalMediumType: MEDIA_TYPE.ON_DEMAND_VIDEO,
      userId: speakerProfile.customerId,
      commentString: values[COMMENT_REPLY_FORM.body],
      children: null,
      parent,
    };

    try {
      const { data: postedReply } = (await reply({
        token: credentials.token.access_token,
        body: toPostBody,
      })) as { data: Comment };

      if (postedReply) {
        onAbort();
        dispatch(
          commentSliceActions.replaceReplyId({
            reply: postedReply.commentId,
            parent,
          })
        );
      } else
        dispatch(commentSliceActions.revertCommentReply(parent?.commentId));
    } catch (error) {
      dispatch(commentSliceActions.revertCommentReply(parent?.commentId));
    }
  };

  return (
    <Grow in timeout={600}>
      <Paper sx={styles.container}>
        <FormContainer
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={handleCommentReply}
        >
          <InputField
            extraStyles={{ width: "100%" }}
            multiline
            maxRows={3}
            placeholder="Enter Your Comment !"
            name={COMMENT_REPLY_FORM.body}
          />
          <Stack
            columnGap={2}
            direction="row"
            width="100%"
            justifyContent="flex-end"
          >
            <Button
              label="Cancel"
              variant="outlined"
              extraStyles={{
                ":hover": {
                  color: "white",
                },
              }}
              onClick={() => onAbort()}
            />
            <SubmitButton title="Submit" />
          </Stack>
        </FormContainer>
      </Paper>
    </Grow>
  );
};

export default CommentReplyForm;
