import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Blog } from "store/api/blog_service/types";

type State = {
  currentBlog: Blog | null;
  blogList: Array<Blog>;
};

const initialState: State = {
  currentBlog: null,
  blogList: [],
};

const blogSlice = createSlice({
  name: "blog_slice",
  initialState,
  reducers: {
    pushCurrentBlog: (state: State, { payload }: PayloadAction<Blog>) => {
      state.currentBlog = payload;
      return state;
    },
    pushBlogList: (state: State, { payload }: PayloadAction<Array<Blog>>) => {
      state.blogList = payload;
      return state;
    },
  },
});

export default blogSlice.reducer;
export const blogSliceActions = blogSlice.actions;
