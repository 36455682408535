import { defineMessages } from "react-intl";

const scope = "LiveStreamOnlinePageFooterSection";

export default defineMessages({
  interact_here: {
    id: `${scope}.interact_here`,
    defaultMessage: "Interact Here ..",
  },
  unable: {
    id: `${scope}.unable`,
    defaultMessage: "unable to send message",
  },
  leave_session: {
    id: `${scope}.leave_session`,
    defaultMessage: "Leave Session",
  },
  remove_attendee: {
    id: `${scope}.remove_attendee`,
    defaultMessage: "Remove Attendee",
  },
  end_session: {
    id: `${scope}.end_session`,
    defaultMessage: "End Session",
  },
});
