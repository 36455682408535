import { FC, useEffect } from "react";
import Box from "@mui/material/Box";
import styles from "./styles";
import InputField from "common/input_field";
import SubmitButton from "common/submit_button";
import { initialValues, OTP_FORM, validationSchema } from "./form_data";
import FormContainer from "common/form_container";
import { ValidateResponse } from "store/api/types";
import SnackBar from "components/snack_bar";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { globalSliceActions } from "store/global_slice";
import useSnack from "hooks/useSnack";
import { useLocation, useNavigate } from "react-router-dom";
import { useValidateRegistrationMutation } from "store/api/auth_service/endpoints";
import { Typography } from "@mui/material";
import Card from "components/card";
import color_pallette from "theme/color_pallette";
import FormActionSection from "./form_action_section";
import {
  useSendOTPMutation,
  useVerifyOTPMutation,
} from "store/api/otp_service/endpoints";
import { isEmail } from "utils/validate_login";
import { FormikValues } from "formik";
/* import { transactionSliceActions } from "store/transaction_slice";
import { ErrorResponse, SendOTPResponse } from "store/api/otp_service/types"; */
import { verificationSliceActions } from "store/verification_slice";
// import { TransactionID } from "store/api/otp_service/types";
import { transactionSliceActions } from "store/transaction_slice";
import { TransactionID } from "store/api/otp_service/types";

const OtpConfirmationPage: FC = () => {
  const { transactionId } = useAppSelector((state) => state.transaction);

  const {
    toNavigate,
    navigationProps,
    verifyOTP: requireVerifyOTP,
    email,
    registrationStep,
    sendOTP: reVerifyOTP,
    phoneNumber,
  } = useAppSelector((state) => state.otp);

  const dispatch = useAppDispatch();

  const token = useAppSelector(
    (state) => state.global.credentials.token.access_token
  );

  const { triggerSnack } = useSnack();

  const navigate = useNavigate();

  const [validate] = useValidateRegistrationMutation();

  const [sendOTP] = useSendOTPMutation();

  const [verifyOTP] = useVerifyOTPMutation();

  const { state } = useLocation();

  /* const [isMounted, setIsMounted] = useState(false); */

  const verifyOTPFunction = async (otp: string): Promise<boolean> => {
    try {
      await verifyOTP({
        token,
        body: {
          email,
          otp,
          transactionId,
        },
      });
      dispatch(verificationSliceActions.setLastOTP(otp));
      dispatch(verificationSliceActions.setVerifyOTP(false));
    } catch (error) {
      console.log(error);
      return false;
    }
    return true;
  };

  const handleSubmit = async (values: FormikValues) => {
    dispatch(verificationSliceActions.setLastOTP(values[OTP_FORM.otp]));
    if (reVerifyOTP) {
      navigate(toNavigate, navigationProps);
      /* dispatch(verificationSliceActions.setSendOTP(false)); */
    } else {
      if (requireVerifyOTP) {
        const res = await verifyOTPFunction(values[OTP_FORM.otp]);
        if (res) navigate(toNavigate, navigationProps);
        return;
      } else if (!requireVerifyOTP && registrationStep) {
        try {
          const res = (await validate({
            ...state,
            otp: values[OTP_FORM.otp],
          })) as unknown as { data: ValidateResponse };

          if (res.data.customerId) {
            dispatch(
              globalSliceActions.submitValidationData(
                res.data as ValidateResponse
              )
            );
            dispatch(globalSliceActions.setRole(res.data.role));
            dispatch(globalSliceActions.submitValidationData(res.data));
            dispatch(globalSliceActions.setToken(res.data.accessToken));

            triggerSnack("User Account Validated Successfully !", "success");

            navigate(toNavigate, navigationProps);
          }
        } catch (error: unknown) {
          console.log(error);
          triggerSnack(`Something Wrong occured !`, "error");
        }
      }
    }
  };

  const sendOTPFunction = async () => {
    const validatedKey = isEmail(phoneNumber);

    if (validatedKey == "invalid") return;

    try {
      const { data } = (await sendOTP({
        token,
        body: {
          [validatedKey]: phoneNumber,
          email,
          mobile: phoneNumber,
        },
      })) as unknown as TransactionID;
      if (data) {
        dispatch(
          transactionSliceActions.setCurrentTransactionId(data.transactionId)
        );
      }
    } catch (error: any) {
      console.log(error);
      triggerSnack(`${error.response.data.messsage}`, "error");
    }
  };

  useEffect(() => {
    if (reVerifyOTP) sendOTPFunction();
  }, [reVerifyOTP]);

  return (
    <Box sx={styles.container}>
      <SnackBar />
      <>
        <Box sx={styles.container}>
          <Card extraStyle={styles.cardStyle}>
            <Typography sx={styles.font}>Confirm OTP</Typography>
            <Typography sx={styles.desc}>
              Otp was sent to{" "}
              <span style={{ color: color_pallette.primary }}>
                phone number
              </span>{" "}
              and as{" "}
              <span style={{ color: color_pallette.primary }}>email</span>
            </Typography>
            <FormContainer
              validationSchema={validationSchema}
              initialValues={initialValues}
              onSubmit={handleSubmit}
            >
              <Box sx={styles.formHeader}>
                <InputField
                  label={"OTP"}
                  name={OTP_FORM.otp}
                  placeholder={"e.g 1234"}
                  extraStyles={styles.spacing}
                />
                <FormActionSection />
              </Box>
              <Box sx={styles.center}>
                <SubmitButton title={"Submit"} />
              </Box>
            </FormContainer>
          </Card>
        </Box>
      </>
    </Box>
  );
};

export default OtpConfirmationPage;
