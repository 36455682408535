import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    columnGap: 1,
    paddingInline: 2,
    marginTop: 5,
    borderRadius: 3,
    boxShadow: 1,
    backgroundColor: "#232323",
  },
  root: {
    paddingTop: 2,
    paddingBottom: 3,
    backgroundColor: "black",
  },
};

export default styles;