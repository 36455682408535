import { FC } from "react";
import { Pagination as MuiPagination } from "@mui/material";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { paginationSliceeActions } from "store/pagination_slice";

type Props = {
  scroll?: boolean;
  name: string;
};

const Pagination: FC<Props> = ({ scroll, name }) => {
  const item = useAppSelector((state) => state.pagination);

  console.log("ITEM ###  ", item);

  console.log("NAME ###  ", name);

  console.log("ITEM[NAME] ### ", item[name]);

  /* const { page, count } = item[name]; */

  const dispatch = useAppDispatch();

  const handleChange = (_: React.ChangeEvent<unknown>, value: number) => {
    dispatch(
      paginationSliceeActions.updatePaginationItemPage({
        page: value,
        key: name,
      })
    );
    if (scroll) window.scroll({ top: 0, behavior: "smooth" });
  };

  return (
    <MuiPagination
      count={item && item[name] ? item[name].count : 1}
      page={item && item[name] ? item[name].page : 1}
      variant="outlined"
      color="primary"
      sx={{ marginBlock: 2 }}
      onChange={handleChange}
      showFirstButton
      showLastButton
    />
  );
};

export default Pagination;
