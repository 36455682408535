import { FC, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import styles from "./styles";
import HeaderSection from "./header_section";
import ChatStream from "./chat_stream";
import FooterSection from "./footer_section";
import { useAppDispatch, useAppSelector } from "store/hooks";
import io, { Socket } from "socket.io-client";
import { baseUrl } from "store/api";
import { Identification } from "types/identification";
import { PostMessageResponse } from "store/api/chat/types";
import { chatSliceActions } from "store/chat_slice";

const IO_PATH = "/chat/event-processing/socket";

type Props = {
  convId: Identification;
};

let socket: Socket | null = null;

const Chat: FC<Props> = ({ convId }) => {
  const token = useAppSelector(
    (state) => state.global.credentials.token.access_token
  );

  const dispatch = useAppDispatch();

  const { customerId } = useAppSelector((state) => state.global.speakerProfile);

  const initiateSocket = () => {
    const options = {
      path: IO_PATH,
      extraHeaders: {
        Authorization: `Bearer ${token}`,
      },
      transports: ["websocket"],
      query: { conversation: convId, user: customerId },
    };

    socket = io(`${baseUrl}`, options);

    socket?.on("message:sent", (data: PostMessageResponse) => {
      console.log("received == > ", data);
      dispatch(
        chatSliceActions.addMessage({
          text: data.text,
          user: data.user.customerId,
          conversation: data.conversation,
          profilePicture: data.user.profilePicture,
          userName: data.user.name,
        })
      );
    });
  };

  const sendMessage = (msg: any) => {
    return new Promise((resolve, reject) => {
      socket
        ?.emitWithAck("message:send", msg)
        .then((res: any) => {
          if (res?.status == "OK") {
            resolve(msg);
          } else {
            reject(res);
          }
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  };

  const deinitiateSocket = () => {
    if (socket) socket?.disconnect();
  };

  useEffect(() => {
    initiateSocket();

    return () => {
      deinitiateSocket();
    };
  }, []);

  return (
    <Box sx={styles.container}>
      <Paper sx={styles.chatWrapper}>
        <HeaderSection />
        <ChatStream />
      </Paper>
      <FooterSection IOsendMessage={sendMessage} />
    </Box>
  );
};

export default Chat;