import { FC } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import styles from "./styles";
import live_logo from "assets/images/live_logo.png";
import color_pallette from "theme/color_pallette";
import Button from "components/custom_button";
import { Upcoming } from "store/api/streaming_service/types";
import { FormattedMessage, useIntl } from "react-intl";
import messages from "../messages";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "navigation/routes";

type HeadingProps = {
  src: string;
  name: string;
  title: string;
  startTime: string;
};

const Heading: FC<HeadingProps> = ({ src, name, title, startTime }) => {
  const intl = useIntl();

  const navigate = useNavigate();

  const handleMore = () => {
    navigate(NavigationRoutes.interactive);
  };

  return (
    <Stack
      width="100%"
      direction={{ xs: "column", md: "row" }}
      alignItems={{ xs: "flex-start", md: "center" }}
      marginTop={2}
      rowGap={{ xs: 2, md: 0 }}
      justifyContent="space-between"
    >
      <Stack
        width="100%"
        direction="row"
        justifyContent="space-between"
        marginRight={5}
      >
        <Stack direction="row" alignItems="center">
          <Avatar sx={{ width: 100, height: 100 }} src={src} />
          <Box sx={{ marginInlineStart: 1.5 }}>
            <Typography sx={styles.name}>{name}.</Typography>
            <Typography sx={styles.desc}>{title}</Typography>
          </Box>
        </Stack>
        <Stack direction="row" columnGap={2} alignItems="center">
          <Box
            component="img"
            sx={{ objectFit: "contain", aspectRatio: "4 / 2", width: 80 }}
            src={live_logo}
          />
          <Typography sx={styles.date}>
            <span style={{ fontWeight: "700" }}>{startTime}</span>
          </Typography>
        </Stack>
      </Stack>
      <Button
        onClick={handleMore}
        variant="outlined"
        extraStyles={{
          width: 350,
          height: 50,
          ":hover": {
            color: "white",
          },
          fontSize: { lg: 22 },
        }}
        label={intl.formatMessage(messages.explore_more)}
      />
    </Stack>
  );
};

type Props = {
  upcoming: Upcoming | null;
};

const HeaderSection: FC<Props> = ({ upcoming }) => {
  return (
    <Box sx={styles.container}>
      <Typography>
        <FormattedMessage id={messages.services.id} />{" "}
        <span style={{ marginInline: "5px" }}>{">"}</span>{" "}
        <span style={{ color: color_pallette.primary }}>
          <FormattedMessage id={messages.interactive.id} />
        </span>
      </Typography>
      <Typography sx={styles.header}>
        <FormattedMessage id={messages.tips.id} />
      </Typography>
      {upcoming && (
        <Heading
          src={upcoming.user.profilePicture}
          name={upcoming.title}
          startTime={upcoming.channel.startTime}
          title={upcoming.description}
        />
      )}
    </Box>
  );
};

export default HeaderSection;
