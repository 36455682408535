import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    boxShadow: 5,
    borderRadius: 3,
    marginTop: 5,
    marginInline: 2,
    paddingInline: 3,
    paddingBlock: 2.5,
    minWidth: {
      xs: 400,
      lg: 320,
      xl: 330,
    },
  },
  headerContainer: { marginBottom: 1.5 },
  reset: {
    opacity: 0.6,
    fontSize: 13,
    cursor: "pointer",
  },
  label: {
    fontSize: {
      xs: 20,
      lg: 17,
    },
  },
};

export default styles;
