import { SxProps } from "@mui/material";
import color_pallette from "theme/color_pallette";

const styles: Record<string, SxProps> = {
  recentValue: {
    opacity: 0.8,
  },
  container: {
    borderRadius: 3,
    cursor: "pointer",
    boxShadow: 0,
    backgroundColor: color_pallette.backgroundGray,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBlock: 1.5,
    paddingInline: 2,
  },
};

export default styles;
