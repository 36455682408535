import { FC } from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import styles from "./styles";
import { useAppSelector } from "store/hooks";
import bg from "assets/images/placeholders/empty_cover_picture.webp";
import avatar from "assets/images/placeholders/thumbnail_pl.webp";

const HeaderSection: FC = () => {
  const { speakerProfile } = useAppSelector((state) => state.global);

  return (
    <Box sx={styles.root}>
      <Paper
        component="img"
        alt="cover image"
        src={speakerProfile.coverImage ?? bg}
        sx={styles.container}
      />
      <Paper sx={styles.imageContainer}>
        <Box
          sx={styles.adminImage}
          component="img"
          alt="profile picture"
          src={speakerProfile.profilePicture ?? avatar}
        />
      </Paper>
    </Box>
  );
};

export default HeaderSection;