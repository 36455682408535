import { defineMessages } from "react-intl";

const scope = "NewUserModal";

export default defineMessages({
  edit_profile: {
    id: `${scope}.edit_profile`,
    defaultMessage: "Continue to Edit Profile",
  },
  title: {
    id: `${scope}.title`,
    defaultMessage: "Welcome to Live Tips",
  },
  hey: {
    id: `${scope}.hey`,
    defaultMessage: "Hey",
  },
  welcome: {
    id: `${scope}.welcome`,
    defaultMessage: "Welcome to Live Tips",
  },
  desc: {
    id: `${scope}.desc`,
    defaultMessage:
      "Welcome to Live Tips! We're excited to have you join our growing community of users. Whether you're here to get the latest tips, share your own insights, or connect with others who share your interests, we're confident you'll love the experience. Dive in and explore all the valuable resources and tools we have to offer.",
  },
  desc_2: {
    id: `${scope}.desc_2`,
    defaultMessage:
      "As you begin your journey with Live Tips, feel free to browse around, ask questions, and get involved with the community. If you ever need assistance, our support team is just a click away. We're here to help you make the most of your time with us!",
  },
});
