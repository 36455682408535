import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Nominee } from "./types";
import data from "./data";

type State = {
  data: Array<Nominee>;
  showForm: boolean;
};

const initialState: State = {
  data,
  showForm: true,
};

const nominationSlice = createSlice({
  name: "nomination",
  initialState,
  reducers: {
    addNominee: (state: State, { payload }: PayloadAction<Nominee>) => {
      state.data.unshift(payload);
      return state;
    },
    showNominationForm: (state: State, { payload }: PayloadAction<boolean>) => {
      state.showForm = payload;
      return state;
    },
  },
});

export default nominationSlice.reducer;
export const nominationSliceActions = nominationSlice.actions;
