import { FC } from "react";
import Box from "@mui/material/Box";
import styles from "./styles";
import InputField from "common/input_field";
import SubmitButton from "common/submit_button";
import FormActionSection from "../form_action_section";
import { FormNames, initialValues, validationSchema } from "./form_data";
import messages from "./messages";
import { useIntl } from "react-intl";
import FormContainer from "common/form_container";
import { GenerateToken, GenerateTokenResponse } from "store/api/types";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "navigation/routes";
import { useGenerateLoginTokenMutation } from "store/api/identity_service/endpoints";
import { useAppDispatch } from "store/hooks";
import { globalSliceActions } from "store/global_slice";
import useSnack from "hooks/useSnack";
import SnackBar from "components/snack_bar";
import { ROLE } from "types/role";
import {
  countryList,
  CountryType,
} from "pages/register_page/register_form/data";
import { Stack } from "@mui/material";
import SelectInputField from "common/select_input_field";
import { validateLoginCredentials } from "utils/validate_login";
import { verificationSliceActions } from "store/verification_slice";
import { transactionSliceActions } from "store/transaction_slice";

const LoginForm: FC = () => {
  const intl = useIntl();

  const navigate = useNavigate();

  const [generate, { isError }] = useGenerateLoginTokenMutation();

  const dispatch = useAppDispatch();

  const { triggerSnack } = useSnack();

  const handleSubmit = async (values: GenerateToken) => {
    triggerSnack(intl.formatMessage(messages.verifying), "info");

    const credentials = validateLoginCredentials({
      mobile: values[FormNames.mobile],
      countryCode: values[FormNames.countryCode],
      [FormNames.password]: values[FormNames.password],
    });

    if (credentials == null) return;

    const res = (await generate(credentials)) as {
      data: GenerateTokenResponse;
    };

    if (res.data.access_token == null) {
      dispatch(verificationSliceActions.setPath(NavigationRoutes.profile));
      dispatch(verificationSliceActions.setEmail(res.data.userData.email));
      dispatch(verificationSliceActions.setVerifyOTP(false));
      dispatch(verificationSliceActions.setIsRegistrationStep(true));
      dispatch(verificationSliceActions.setSendOTP(false));
      dispatch(verificationSliceActions.setPhone(res.data.userData.username));
      dispatch(
        transactionSliceActions.setCurrentTransactionId(res.data.transactionId)
      );
      dispatch(
        verificationSliceActions.setNavigationProps({
          state: {},
        })
      );

      navigate(NavigationRoutes.otp_confirmation, {
        state: {
          email: res.data.userData.email,
          /* mobile: res.data.userData.username, */
          transactionId: res.data.transactionId,
        },
      });

      return;
    }

    if (!isError) {
      dispatch(
        globalSliceActions.submitUserCredentials({
          ...res.data,
          userData: res.data["userData"] as GenerateTokenResponse["userData"],
        })
      );

      dispatch(
        globalSliceActions.setRole(res.data.userData.role ?? ROLE.normal)
      );

      /* if (res.data.access_token == null) {
        dispatch(verificationSliceActions.setPath(NavigationRoutes.profile));
        dispatch(verificationSliceActions.setEmail(res.data.userData.email));
        dispatch(verificationSliceActions.setPhone(res.data.userData.email));

        navigate(NavigationRoutes.otp_confirmation);

        return;
      } */

      navigate(NavigationRoutes.profile);
    } else triggerSnack(intl.formatMessage(messages.wrong), "error");
  };

  const mobileCodeLists = () => {
    const codes = countryList.map((entry) => {
      return {
        name: entry.mobileCode,
      };
    });
    return codes;
  };

  return (
    <Box sx={styles.container}>
      <SnackBar />
      <FormContainer
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        <Box sx={styles.formHeader}>
          <Stack
            columnGap={1}
            marginBottom={1}
            direction="row"
            alignItems="center"
          >
            <SelectInputField
              label="Country Code"
              placeholder="Choose Country Code"
              name={FormNames.countryCode}
              dataList={mobileCodeLists() as CountryType[]}
            />
            <InputField
              label="Phone Number / Email"
              name={FormNames.mobile}
              placeholder={intl.formatMessage(messages.mobilePlaceholder)}
              extraStyles={styles.spacing}
            />
          </Stack>
          <InputField
            label={intl.formatMessage(messages.password)}
            name={FormNames.password}
            type="password"
            placeholder={intl.formatMessage(messages.passwordPlaceholder)}
            extraStyles={styles.spacing}
          />
          <FormActionSection />
        </Box>
        <Box sx={styles.center}>
          <SubmitButton title={intl.formatMessage(messages.submitLabel)} />
        </Box>
      </FormContainer>
    </Box>
  );
};

export default LoginForm;
