import { SxProps } from "@mui/material";
import color_pallette from "theme/color_pallette";

const styles: Record<string, SxProps> = {
  container: {},
  ratingContainer: {
    border: `2px solid ${color_pallette.primary}`,
    borderWidth: 1.9,
    borderRadius: 5,
    paddingInline: { lg: 1 },
    paddingBlock: 0.5,
    color: color_pallette.primary,
    "&:hover": {
      color: color_pallette.primary,
    },
  },
  ratingValue: {
    fontSize: {
      lg: 12,
    },
    marginInlineEnd: 2,
  },
  ratingLabel: {
    fontSize: {
      lg: 12,
    },
  },
};

export default styles;
