import { FC } from "react";
import Stack from "@mui/material/Stack";
import UserProfileSection from "../user_profile_section";
import { MenuItemsData } from "components/appbar/menu_items/data";
import MobileMenuItem from "../mobile_menu_item";

const MenuSection: FC = () => {
  return (
    <Stack
      direction="column"
      alignItems="flex-start"
      rowGap={3}
      width="80%"
      paddingInlineStart={5}
      flexGrow={1}
      borderRight="2px solid #D6ECEC"
    >
      {MenuItemsData.map((entry, index) => (
        <MobileMenuItem key={entry.item.path} entry={entry} index={index} />
      ))}
      <UserProfileSection />
    </Stack>
  );
};

export default MenuSection;
