import { SxProps } from "@mui/material";
import color_pallette from "theme/color_pallette";

const styles: Record<string, SxProps> = {
  container: {
    borderRadius: 3,
    boxShadow: 3,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginX: "auto",
    overflow: "hidden",
    paddingBlock: {
      xs: 3,
    },
    width: {
      xs: "90%",
    },
  },
  headerContainer: {
    width: { xs: "100%", md: "50%" },
  },
  description: { fontWeight: "500", fontSize: 13 },
  actionLabel: { fontSize: { xs: 11 } },
  podcastLength: { fontSize: { xs: 12 } },
  caption: { fontWeight: "600", fontSize: 20 },
  subTitle: { fontWeight: "500", fontSize: 13 },
  avatar: { width: 30, height: 30 },
  contentContainer: {
    textAlign: "start",
    width: { md: "50%" },
    rowGap: 0.5,
    marginTop: { xs: 1.5, md: 2 },
  },
  rating: { color: color_pallette.primary },
  ratingValue: { fontSize: { xs: 11 } },
  viewProfile: { borderColor: "black", color: "black" },
  thumb: {
    width: "100%",
    height: { md: "80%" },
    objectFit: "cover",
  },
  notifyButton: {
    borderRadius: 10,
    fontSize: {
      xs: 12,
    },
    marginInlineStart: 2,
  },
  speaker: {
    fontWeight: "700",
  },
  datePublished: {
    opacity: 0.7,
    marginBottom: 1,
  },
  flex: {
    marginTop: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "90%",
  },
  statsContainer: {
    display: "flex",
    flexDirection: {
      xs: "column",
      lg: "row",
    },
    width: "100%",
    justifyContent: { lg: "space-between" },
    alignItems: "center",
    rowGap: 2,
    mt: 3,
  },
  videoContainer: {
    position: "relative",
    width: "90%",
    height: 450,
  },
};

export default styles;
