import { defineMessages } from 'react-intl';

const scope = 'PublicViewProfile';

export default defineMessages({
  my_upcoming_list: {
    id: `${scope}.my_upcoming_list`,
    defaultMessage: "My Upcoming List ",
  },
  podcast_content: {
    id: `${scope}.podcast_content`,
    defaultMessage: "Podcast Content",
  },
  online_content: {
    id: `${scope}.online_content`,
    defaultMessage: "Online Content",
  },
});