import { FC, PropsWithChildren } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Grow from "@mui/material/Grow";
import styles from "./styles";
import red_logo from "assets/svgs/government-of-dubai-red.svg";
import police from "assets/svgs/police_logo.svg";
import online_image1 from "assets/images/placeholders/thumbnail_pl.webp";
import color_pallette from "theme/color_pallette";
import HeroCard from "types/hero_card";
import { FormattedMessage } from "react-intl";

const CardItem: FC<PropsWithChildren<HeroCard>> = ({
  hightlightedImage,
  label,
  policeDepartement,
  children,
}) => {
  return (
    <Grow in timeout={1300}>
      <Paper sx={styles.container}>
        <Stack
          paddingInlineStart={3}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography sx={styles.header}>
            <span
              style={{
                color: color_pallette.primary,
                fontFamily: "Nizzar ,K2D, Inter",
                fontWeight: "700",
              }}
            >
              <FormattedMessage id={label} />
            </span>
          </Typography>
          {policeDepartement && (
            <Box>
              <Box
                sx={{
                  width: 70,
                  marginInlineEnd: 2,
                  objectFit: "scale-down",
                  height: 30,
                }}
                component="img"
                alt="Police Departement Logo"
                src={police}
              />
              <Box
                sx={{ width: 70, objectFit: "contain", height: 30 }}
                component="img"
                alt="Police Departement Logo"
                src={red_logo}
              />
            </Box>
          )}
        </Stack>
        <Box
          sx={styles.img}
          component="img"
          alt="Live Tips Events Types"
          src={hightlightedImage ?? online_image1}
        />
        <Box sx={styles.childrenWrapper}>{children}</Box>
      </Paper>
    </Grow>
  );
};

export default CardItem;
