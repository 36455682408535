import { Methods } from "types/http";
import {
  Authorization,
  Request,
  UserQuery,
  ValidateBody,
  Validation,
} from "../types";
import apiUrls from "../urls";

export const getUserById = ({ token, id }: UserQuery) => ({
  url: apiUrls.userById(id),
  method: Methods.GET,
  headers: {
    Lng: "EN",
    "Content-type": "application/json",
    Authorization: `Bearer ${token}`,
    Accept: "*/*",
  },
});

export const validateTransactionId = (body: ValidateBody) => ({
  url: apiUrls.validateTransactionId(),
  method: Methods.POST,
  body,
  headers: {
    Lng: "EN",
    "Content-type": "application/json",
    Accept: "*/*",
  },
});

export const submitRegisterCredentials = (body: Request) => ({
  url: apiUrls.registerUser(),
  method: Methods.POST,
  body,
  headers: {
    Lng: "EN",
    "Content-type": "application/json",
    Accept: "*/*",
  },
});

export const refreshTokenAction = (body: Authorization) => ({
  url: apiUrls.refreshToken(),
  method: Methods.POST,
  body,
  headers: {
    Lng: "EN",
    "Content-type": "application/json",
    Accept: "*/*",
  },
});


export const validateUserCredentials = (body: Validation) => ({
  url: apiUrls.validateOTP(),
  method: Methods.POST,
  body,
  headers: {
    Lng: "EN",
    "Content-type": "application/json",
    Accept: "*/*",
  },
});

export const findAllUsersAction = () => ({
  url: apiUrls.fineAllUser(),
  method: Methods.GET,
  headers: {
    Lng: "EN",
    "Content-type": "application/json",
    Accept: "*/*",
  },
});