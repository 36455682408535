import { forwardRef, Ref, useCallback, useImperativeHandle } from "react";
import { Paper, Skeleton, Stack, Typography } from "@mui/material";
import styles from "./styles";
import SwipablePodcaster, {
  SwipablePodcasterProps,
} from "components/swipable_podcaster";
import withSwiper from "hoc/withSwiper";
import { useAppSelector } from "store/hooks";
import { useGetPodcastLiveStreamingsQuery } from "store/api/streaming_service/endpoints";
import { Upcoming } from "store/api/streaming_service/types";
import { UploadedFileStatus } from "types/uploaded_file_status";
import { FormattedMessage } from "react-intl";
import messages from "./messages";

const UpcomingPodcasters = forwardRef((_, ref: Ref<any>) => {
  useImperativeHandle(ref, () => ({
    getIsEmpty,
    isEmpty: isSuccess && data.length == 0,
  }));

  const { access_token } = useAppSelector(
    (state) => state.global.credentials.token
  );

  const {
    data: upcoming,
    isLoading,
    isSuccess,
  } = useGetPodcastLiveStreamingsQuery({
    token: access_token,
    query: {
      is_live: false,
      is_expired: false,
      status: UploadedFileStatus.ACCEPTED,
    },
  });

  let data: Array<Upcoming> = [];

  if (upcoming && upcoming.results) data = upcoming.results;

  const renderSwiper = useCallback(() => {
    if (isLoading || (upcoming?.results?.length || 0) > 0) {
      return withSwiper<SwipablePodcasterProps, Upcoming>(
        SwipablePodcaster,
        data
      )({
        componentProps: {
          itemData: {} as Upcoming,
        },
        SkeletonComponent: (
          <Paper sx={{ height: 650, width: "100%" }}>
            <Stack
              columnGap={1}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Skeleton sx={{ borderRadius: 3 }} height={130} width={100} />
              <Skeleton sx={{ borderRadius: 3 }} height={130} width={100} />
              <Skeleton sx={{ borderRadius: 3 }} height={130} width={100} />
            </Stack>
            <Stack direction="row" justifyContent="center" alignItems="center">
              <Skeleton sx={{ borderRadius: 3 }} height={350} width={300} />
            </Stack>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Skeleton sx={{ borderRadius: 3 }} height={150} width="90%" />
            </Stack>
          </Paper>
        ),
        swiperProps: {
          style: {
            paddingInline: 25,
          },
          slidesPerView: 1,
        },
      });
    }
    return null;
  }, [data]);

  const getIsEmpty = useCallback(() => {
    return isSuccess && data.length == 0;
  }, [data, isSuccess]);

  if (getIsEmpty()) {
    return null;
  }

  return (
    <Paper sx={styles.root}>
      <Typography sx={styles.header}>
        <FormattedMessage id={messages.upcoming_podcaster.id} />
      </Typography>
      {renderSwiper()}
    </Paper>
  );
});

export default UpcomingPodcasters;
