import { FC } from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grow from "@mui/material/Grow";
import Modal from "@mui/material/Modal";
import styles from "./styles";
import { useAppDispatch, useAppSelector } from "store/hooks";
import InputField from "common/input_field";
import { EditProfileFormNames } from "./form";
import SubmitButton from "common/submit_button";
import { ReactSVG } from "react-svg";
import CoverImageSection from "./cover_image_section";
import ProfileImageSection from "./profile_image_section";
import { ProfileSliceActions } from "store/profile_slice";
import cancel from "assets/svgs/cancel.svg";
import { FormikValues, useFormikContext } from "formik";
import { useIntl } from "react-intl";
import messages from "../messages";
import { verificationSliceActions } from "store/verification_slice";

const EditProfileModal: FC = () => {
  const showEditModal = useAppSelector(
    (state) => state.adminProfil.modals.showEditModal
  );

  const { resetForm } = useFormikContext<FormikValues>();

  const dispatch = useAppDispatch();

  const handleCloseEditModal = () => {
    resetForm();
    dispatch(ProfileSliceActions.closeModals());
    dispatch(verificationSliceActions.setVerifyOTP(true));
  };

  const intl = useIntl();

  return (
    <Modal open={showEditModal} sx={styles.container}>
      <Grow in={showEditModal} timeout={700}>
        <Paper sx={styles.paper}>
          <Box onClick={handleCloseEditModal} sx={styles.flexEnd}>
            <ReactSVG src={cancel} />
          </Box>
          <CoverImageSection />
          <ProfileImageSection />
          <InputField
            extraStyles={{ marginTop: 1 }}
            placeholder={intl.formatMessage(messages.username)}
            name={EditProfileFormNames.username}
            label={intl.formatMessage(messages.username)}
          />
          <InputField
            extraStyles={{ marginTop: 1 }}
            placeholder={intl.formatMessage(messages.org)}
            name={EditProfileFormNames.org}
            label={intl.formatMessage(messages.org)}
          />
          <InputField
            label={intl.formatMessage(messages.bio)}
            placeholder={intl.formatMessage(messages.bio)}
            multiline
            rows={5}
            name={EditProfileFormNames.bio}
            extraStyles={{ marginBlock: 1.5 }}
          />
          <Box sx={styles.flexEnd}>
            <SubmitButton
              title={intl.formatMessage(messages.update_edit_profile)}
            />
          </Box>
        </Paper>
      </Grow>
    </Modal>
  );
};

export default EditProfileModal;
