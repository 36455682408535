import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    paddingInlineStart: 10,
    paddingTop: 5,
  },
  scopeContainer: {
    boxShadow: 5,
    borderRadius: 3,
    display: "flex",
    flexDirection: "column",
    padding: 5,
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
};

export default styles;
