export type SpeakerClass = { value: number; label: string };

export const speakerClassData: Array<SpeakerClass> = [
  { label: "Technology", value: 1 },
  { label: "Environment", value: 2 },
  { label: "Science", value: 3 },
  { label: "Business", value: 4 },
  { label: "Social Change", value: 5 },
  { label: "Health", value: 6 },
  { label: "Nature", value: 7 },
  { label: "Community", value: 9 },
  { label: "Identity", value: 8 },
  { label: "Humanity", value: 10 },
  { label: "Communication", value: 11 },
  { label: "The Future", value: 12 },
];
