import { FC } from "react";
import Typography from "@mui/material/Typography";
import UpcomingPodcastListItem from "components/upcoming_podcast_list_item";
import styles from "./styles";
import { useAppSelector } from "store/hooks";
import { useGetLiveStreamingsQuery } from "store/api/streaming_service/endpoints";
import { UploadedFileStatus } from "types/uploaded_file_status";
import { UploadPrivacy } from "types/upload_privacy";
import { Box } from "@mui/material";
import { FormattedMessage } from "react-intl";
import messages from "../messages";

const UpcomingSection: FC = () => {
  const token = useAppSelector(
    (state) => state.global.credentials.token.access_token
  );

  const { data } = useGetLiveStreamingsQuery({
    query: {
      status: UploadedFileStatus.PENDING,
      privacy: UploadPrivacy.PUBLIC,
    },
    token,
  });

  return (
    <>
      <Typography sx={styles.header}>
        <span style={{ fontWeight: "700", letterSpacing: 1.3 }}>
          <FormattedMessage id={messages.upcoming.id} />
        </span>{" "}
        <FormattedMessage id={messages.section.id} />
      </Typography>
      {data &&
        data.results &&
        data.results.length > 0 &&
        data.results.slice(0, 2).map((entry) => (
          <Box sx={{ marginBlock: 2 }}>
            <UpcomingPodcastListItem key={entry.id} {...entry} />
          </Box>
        ))}
    </>
  );
};

export default UpcomingSection;
