import { Methods } from "types/http";
import { notificationUrls } from "./urls";
import { NotificationType } from "./types";

export const getNotifications = (param: { token: string }) => ({
  url: notificationUrls.getAllNotifications(),
  method: Methods.GET,
  headers: {
    Lng: "EN",
    Accept: "*/*",
    "Content-type": "application/json",
    Authorization: `Bearer ${param.token}`,
  },
});

export const postNotification = (param: {
  token: string;
  body: NotificationType;
}) => ({
  url: notificationUrls.postNotification(),
  method: Methods.POST,
  body: param.body,
  headers: {
    Lng: "EN",
    Accept: "*/*",
    "Content-type": "application/json",
    Authorization: `Bearer ${param.token}`,
  },
});
