import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { NavigationRoutes } from "navigation/routes";
import { ReactNode } from "react";
import { SidebarAction } from "types/sidebar_action";
import styles from "./styles";
import messages from "./messages";

export type MenuItem = {
  id: string;
  label: string;
  url: string;
  class?: boolean;
  action?: SidebarAction;
  badge?: ReactNode;
  publicView: boolean;
  anchorLink?: string;
};

const sideBarMenuItems: Array<MenuItem> = [
  {
    id: "2",
    label: messages.insights.id,
    publicView: true,
    url: NavigationRoutes.history,
    anchorLink: "#insights",
  },
  /* {
    id: "8",
    label: messages.joining_list.id,
    publicView: false,
    url: NavigationRoutes.joining_list,
    anchorLink: "#joining_list",
  }, */
  {
    id: "3",
    label: messages.notification.id,
    publicView: false,
    url: "",
    /* badge: (
      <Box sx={styles.notificationBadgeWrapper}>
        <Typography sx={styles.notificationBadgeText}>+1</Typography>
      </Box>
    ), */
  },
  {
    id: "4",
    label: messages.streams.id,
    publicView: false,
    url: NavigationRoutes.myStreams,
    anchorLink: "#streams",
  },
  {
    id: "5",
    label: messages.discussions.id,
    anchorLink: "#discussions",
    url: "",
    publicView: false,
    class: true,
    /* badge: (
      <Box sx={styles.discussionsWrapper}>
        <Typography sx={styles.discussionsText}>1</Typography>
      </Box>
    ), */
  },
  {
    id: "6",
    label: messages.language.id,
    publicView: true,
    url: "",
    badge: (
      <Box sx={styles.languageWrapper}>
        <Typography sx={styles.languageText}>English</Typography>
      </Box>
    ),
  },
  {
    id: "7",
    label: messages.editprofile.id,
    url: NavigationRoutes.editProfile,
    action: SidebarAction.EDIT_PROFILE,
    publicView: false,
    class: true,
  },
  { id: "7", publicView: true, label: messages.help.id, url: "" },
  { id: "7", publicView: false, label: messages.logout.id, url: "" },
];

export default sideBarMenuItems;
