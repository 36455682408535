import { FC, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import styles from "./styles";
import data from "./data";
import { Stack } from "@mui/material";
import { useAppDispatch } from "store/hooks";
import { sidebarSliceActions } from "store/sidebar_slice";
import useSnack from "hooks/useSnack";
import SnackBar from "components/snack_bar";
import { FormattedMessage } from "react-intl";
import messages from "./messages";

const SidebarSection: FC = () => {
  const dispatch = useAppDispatch();

  const { triggerSnack } = useSnack();

  const resetFilters = () => {
    dispatch(sidebarSliceActions.reset());
    triggerSnack("Form Reinitialized", "success");
  };

  useEffect(() => {
    return () => {
      dispatch(sidebarSliceActions.reset());
    };
  }, []);

  return (
    <Paper sx={styles.container}>
      <Stack
        sx={styles.headerContainer}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography sx={styles.label}>
          <FormattedMessage id={messages.sort.id} />
        </Typography>
        <Typography onClick={() => resetFilters()} sx={styles.reset}>
          <FormattedMessage id={messages.reset.id} />
        </Typography>
      </Stack>
      <Divider />
      <SnackBar />
      {data.map((entry) => (
        <Box sx={{ marginBlock: 1.5 }} key={entry.id}>
          {entry.value()}
        </Box>
      ))}
    </Paper>
  );
};

export default SidebarSection;
