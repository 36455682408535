import { FC } from "react";
import Box from "@mui/material/Box";
import styles from "./styles";
import hero from "assets/images/placeholders/thumbnail_pl.webp";

const PlaceholderImage: FC = () => {
  return (
    <Box
      component="img"
      alt="PlaceholderImage"
      src={hero}
      sx={styles.container}
    />
  );
};
export default PlaceholderImage;
