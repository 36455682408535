import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {},
  name: {
    fontSize: {
      lg: 14,
    },
    fontWeight: "700",
  },
  org: {
    fontSize: {
      lg: 11,
    },
    opacity: 0.6,
  },
  avatar: { height: 50, width: 50 },
};

export default styles;
