import { FC, useCallback } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useLocation, useParams } from "react-router-dom";
import OnlineWrapper from "components/online_wrapper";
import PodcastWrapper from "components/podcast_wrapper";
import styles from "./styles";
import { LIST_TYPE } from "types/list_types";
import { UploadedFileStatus } from "types/uploaded_file_status";
import usePagination from "hooks/use_pagination";
import Pagination from "components/pagination";

const MyVideos: FC = () => {
  const { state } = useLocation();

  const params = useParams();

  const { paginationKey } = usePagination({
    name: "my_videos",
    perPage: 6,
  });

  const { paginationKey: podcastsKey } = usePagination({
    name: "my_podcasts",
    perPage: 6,
  });

  const Content = useCallback(() => {
    switch (state.listType) {
      case LIST_TYPE.ONLINE:
        return {
          Component: (
            <>
              <OnlineWrapper
                paginationName={paginationKey}
                listLabel=""
                videoQuery={{
                  status: UploadedFileStatus.ACCEPTED,
                  user: params.id,
                }}
              />
              <Pagination name={paginationKey} />
            </>
          ),
          title: state.username
            ? `" ${state.username} " Online Videos`
            : "My Online",
        };
      case LIST_TYPE.PODCAST:
        return {
          Component: (
            <>
              <PodcastWrapper
                sectionLabel=""
                videoQuery={{
                  status: UploadedFileStatus.ACCEPTED,
                  user: params.id,
                }}
                dimensions={{ lg: 4.6 }}
              />
              <Pagination name={podcastsKey} />
            </>
          ),
          title: state.username
            ? `" ${state.username} " Podcasts`
            : "My Podcasts",
        };
      case LIST_TYPE.INTERACTIVE:
        return {
          Component: (
            <OnlineWrapper
              paginationName={"interactive"}
              listLabel=""
              videoQuery={{
                user: params.id,
                status: UploadedFileStatus.ACCEPTED,
              }}
            />
          ),
          title: state.username
            ? `" ${state.username} " Interactive`
            : "My Interactive",
        };
      default:
        return {
          Component: (
            <OnlineWrapper
              paginationName={"my_videos"}
              listLabel=""
              videoQuery={{
                user: params.id,
                status: UploadedFileStatus.ACCEPTED,
              }}
            />
          ),
          title: state.username
            ? `" ${state.username} " Online Videos`
            : "My Online",
        };
    }
  }, [state, params.id]);

  return (
    <>
      <Box sx={styles.container}>
        <Stack marginX="auto" sx={{ maxWidth: "80%" }}>
          <Typography sx={styles.header}>{Content()?.title}</Typography>
          {Content()?.Component}
        </Stack>
      </Box>
    </>
  );
};

export default MyVideos;
