import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    width: { xs: "80%", sm: "90%", lg: "100%", xl: "80%" },
  },
  header: {
    fontSize: { xs: 30, lg: 25 },
    fontFamily: "Nizzar ,K2D, Inter",
    fontWeight: "600",
    marginBlock: 5,
  },
};

export default styles;
