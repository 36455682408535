import { FC } from "react";
import {
  Box,
  BoxProps,
  SxProps,
  Typography,
  TypographyProps,
} from "@mui/material";
import styles from "./styles";
import InteractiveEvent from "types/interactive_item";
import combineStyles from "utils/combile_styles";

type Props = {
  Icon: any;
  title: string;
  subTitle: keyof InteractiveEvent["stats"];
  containerStyle?: SxProps<BoxProps>;
  titleStyle?: SxProps<TypographyProps>;
  subTitleStyle?: SxProps<TypographyProps>;
};

const InteractiveIcon: FC<Props> = ({
  subTitle,
  title,
  Icon,
  containerStyle,
  titleStyle,
  subTitleStyle,
}) => {
  return (
    <Box sx={combineStyles(styles.container, containerStyle)}>
      {Icon}
      <Typography sx={combineStyles(styles.iconTitle, titleStyle)}>
        {title}
      </Typography>
      <Typography sx={combineStyles(styles.iconSubTitle, subTitleStyle)}>
        {subTitle}
      </Typography>
    </Box>
  );
};

export default InteractiveIcon;
