import { NavigationRoutes } from "navigation/routes";
import { Blog } from "store/api/blog_service/types";
import { ScreenHighlight } from "types/screen_highlight";
import { UploadedFileStatus } from "types/uploaded_file_status";
import gallery_1 from "assets/images/blog_1.png";
import gallery_2 from "assets/images/blog_2.png";
import gallery_3 from "assets/images/blog_3.png";

export const data: Array<ScreenHighlight> = [
  {
    label: "Home",
    path: NavigationRoutes.home,
  },
  {
    label: "Blog",
    path: NavigationRoutes.blog,
  },
];

const generateFakeBlogs = (): Array<Blog> => {
  return [
    {
      user: "user1",
      category: "Technology",
      status: UploadedFileStatus.ACCEPTED,
      slug: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptas, ea. Eveniet magnam omnis hic.",
      main_image: gallery_1,
      main_image_landscape: gallery_1,
      id: "blog1",
      created_date: "2023-05-15T12:00:00Z",
      updated_date: "2023-05-20T15:30:00Z",
    },
    {
      user: "user2",
      category: "Health",
      status: UploadedFileStatus.ACCEPTED,
      slug: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptas, ea. Eveniet magnam omnis hic.",
      main_image: gallery_2,
      main_image_landscape: gallery_2,
      id: "blog2",
      created_date: "2023-06-10T08:45:00Z",
      updated_date: "2023-06-12T10:00:00Z",
    },
    {
      user: "user3",
      category: "Travel",
      status: UploadedFileStatus.ACCEPTED,
      slug: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptas, ea. Eveniet magnam omnis hic.",
      main_image: gallery_3,
      main_image_landscape: gallery_3,
      id: "blog3",
      created_date: "2023-07-01T09:30:00Z",
      updated_date: "2023-07-03T12:00:00Z",
    },
  ];
};

export const blogs = generateFakeBlogs();