import { SxProps } from "@mui/material";
import color_pallette from "theme/color_pallette";

const styles: Record<string, SxProps> = {
  paper: {
    borderRadius: 3,
    padding: 1.5,
    paddingBottom: 5,
    paddingTop: 3.5,
    background:
      "linear-gradient(100.26deg, #008755 16.92%, rgba(153, 191, 198, .9) 163.21%);",
  },
  headerWrapper: {
    paddingInlineEnd: 2,
  },
  close: {
    width: 30,
    height: 30,
    cursor: "pointer",
  },
  header: {
    fontSize: {
      lg: 20,
    },
    flexGrow: 1,
    marginBottom: 5,
  },
  copyLink: {
    height: 40,
    flexGrow: 0.3,
    fontSize: { lg: 17 },
    color: "white",
    fontWeight: "700",
    letterSpacing: 1.3,
    backgroundColor: color_pallette.primary,
    borderRadius: 15,
  },
  container: {
    marginX: "auto",
    textAlign: "center",
    overflow: "scroll",
    marginTop: {
      xs: 2,
      lg: 5,
    },
    width: {
      xs: "90%",
      sm: "85%",
      md: 700,
      lg: 800,
    },
  },
  inputLike: {
    backgroundColor: "white",
    borderRadius: 3,
    paddingInline: 2,
    height: 60,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  link: {
    color: "black",
    fontWeight: "700",
    letterSpacing: 1.1,
  },
};

export default styles;
