import { FC, useEffect } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import HeaderSection from "./header_section";
import OnlineBookmarksSection from "./online_bookmarks_section";
import PodcastBookmarkSection from "./podcast_bookmark_section";
import { useGetUserBookmarksListQuery } from "store/api/bookmark_service/endpoints";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { bookmarkSliceActions } from "store/bookmarks_slice";
import SkeletonCards from "common/skeleton_section";
import EmptyList from "components/empty_list";
import styles from "./styles";
import { useIntl } from "react-intl";
import messages from "./messages";
import { badgeSliceActions } from "store/badge_slice";

const MyBookmarks: FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(badgeSliceActions.setSaved(0));
  }, []);

  const {
    speakerProfile: user,
    credentials: { token },
  } = useAppSelector((state) => state.global);

  const { data, isLoading } = useGetUserBookmarksListQuery(
    {
      id: user.customerId,
      token: token.access_token,
    },
    {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
      pollingInterval: 600000,
    }
  );

  useEffect(() => {
    if (data) dispatch(bookmarkSliceActions.pushBookmarks(data));
  }, [data, dispatch, isLoading]);

  useEffect(() => {
    return () => {
      dispatch(bookmarkSliceActions.clearBookmarks());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(badgeSliceActions.setSaved(0));
  }, [data]);

  const intl = useIntl();

  return (
    <Box sx={styles.container}>
      <HeaderSection />
      {!isLoading && data && data.length > 0 ? (
        <>
          <OnlineBookmarksSection />
          <PodcastBookmarkSection />
        </>
      ) : isLoading ? (
        <SkeletonCards
          marginTop={5}
          length={8}
          xs={12}
          sm={10}
          md={6}
          lg={3.7}
          xl={2.6}
          marginInlineStart={{ xs: 10, md: 0 }}
        />
      ) : (
        <Stack
          marginTop={10}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <EmptyList
            icon="interactive"
            label={intl.formatMessage(messages.no_bookmarks)}
          />
        </Stack>
      )}
    </Box>
  );
};

export default MyBookmarks;
