import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    width: "100%",
    fontFamily: "Nizzar ,K2D, Inter",
    columnGap: 3,
  },
  header: {
    fontSize: { xl: 20 },
    fontWeight: "700",
  },
  desc: {
    fontSize: { xl: 16 },
    fontWeight: "400",
  },
  divider: {
    width: "100%",
    borderWidth: 1.5,
    backgroundColor: "black",
    opacity: 0.33,
    marginBlock: 2,
    borderRadius: 5,
  },
  subHeader: {
    fontSize: { xl: 16 },
    fontWeight: "400",
  },
};

export default styles;
