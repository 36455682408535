import { FC } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import styles from "./styles";
import live_logo from "assets/images/live_logo.png";
import color_pallette from "theme/color_pallette";
import Button from "components/custom_button";
import { Upcoming } from "store/api/streaming_service/types";

type HeadingProps = {
  src: string;
  name: string;
  title: string;
  startTime: string;
};

const Heading: FC<HeadingProps> = ({ src, name, title, startTime }) => {
  return (
    <Stack
      width="100%"
      direction="row"
      alignItems="center"
      marginTop={2}
      justifyContent="space-between"
    >
      <Stack direction="row" alignItems="center">
        <Avatar sx={{ width: 100, height: 100 }} src={src} />
        <Box sx={{ marginInlineStart: 1.5 }}>
          <Typography sx={styles.name}>{name}.</Typography>
          <Typography sx={styles.desc}>{title}</Typography>
        </Box>
      </Stack>
      <Stack direction="row" columnGap={2} alignItems="center">
        <Box
          component="img"
          sx={{ objectFit: "contain", aspectRatio: "4 / 2", width: 80 }}
          src={live_logo}
        />
        <Typography sx={styles.date}>
          <span style={{ fontWeight: "700" }}>{startTime}</span>
        </Typography>
      </Stack>
      <Button
        variant="outlined"
        extraStyles={{ width: 350, height: 50, fontSize: { lg: 22 } }}
        label="Explore More Live Tips"
      />
    </Stack>
  );
};

type Props = {
  upcoming: Upcoming | null;
};

const HeaderSection: FC<Props> = ({ upcoming }) => {
  return (
    <Box sx={styles.container}>
      <Typography>
        Services <span style={{ marginInline: "5px" }}>{">"}</span>{" "}
        <span style={{ color: color_pallette.primary }}>Interactive</span>
      </Typography>
      <Typography sx={styles.header}>Tips Interactive</Typography>
      {upcoming && (
        <Heading
          src={upcoming.user.profilePicture}
          name={upcoming.channel.name}
          startTime={upcoming.channel.startTime}
          title={upcoming.channel.title}
        />
      )}
    </Box>
  );
};

export default HeaderSection;
