import rootApi from "store/api";
import {
  validateUserCredentials,
  submitRegisterCredentials,
  findAllUsersAction,
  getUserById,
  refreshTokenAction,
  validateTransactionId,
} from "./action_creators";
import {
  Authorization,
  RegisterResponse,
  Request,
  UserByIdResponse,
  UserQuery,
  ValidateBody,
  ValidateResponse,
  Validation,
} from "../types";

export const authApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    registerUser: builder.mutation<RegisterResponse, Request>({
      query: (body) => submitRegisterCredentials(body),
    }),
    validateRegistration: builder.mutation<ValidateResponse, ValidateBody>({
      query: (body) => validateTransactionId(body),
    }),
    getUserById: builder.query<UserByIdResponse, UserQuery>({
      query: (body) => getUserById(body),
    }),
    validateUser: builder.mutation<void, Validation>({
      query: (body) => validateUserCredentials(body),
    }),
    refreshToken: builder.mutation<void, Authorization>({
      query: (body) => refreshTokenAction(body),
    }),
    findAllUsers: builder.query<void, void>({
      query: () => findAllUsersAction(),
    }),
  }),
});

export const {
  useRegisterUserMutation,
  useFindAllUsersQuery,
  useValidateUserMutation,
  useValidateRegistrationMutation,
  useRefreshTokenMutation,
  useGetUserByIdQuery,
} = authApi;
