import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Bookmark } from "store/api/bookmark_service/types";
import { NotificationType } from "store/api/notification_service/types";

type State = {
  notifications: number;
  saved: number;
  bookmarkList: Array<Bookmark>;
  notificationList: Array<NotificationType>;
};

const initialState: State = {
  notifications: 0,
  saved: 0,
  bookmarkList: [],
  notificationList: [],
};

const badgeSlice = createSlice({
  name: "badge_slice",
  initialState,
  reducers: {
    setNotifications: (state: State, { payload }: PayloadAction<number>) => {
      state.notifications = payload;
      return state;
    },
    setNotificationList: (
      state: State,
      { payload }: PayloadAction<Array<NotificationType>>
    ) => {
      state.notificationList = payload;
      return state;
    },
    setBookmarkList: (
      state: State,
      { payload }: PayloadAction<Array<Bookmark>>
    ) => {
      state.bookmarkList = payload;
      return state;
    },
    setSaved: (state: State, { payload }: PayloadAction<number>) => {
      state.saved = payload;
      return state;
    },
  },
});

export default badgeSlice.reducer;
export const badgeSliceActions = badgeSlice.actions;
