import { FC } from "react";
import Box from "@mui/material/Box";
import join_us_logo from "assets/images/join_us_logo.webp";
import joinus_background from "assets/images/joinus_background.webp";

const HeaderSection: FC = () => {
  return (
    <Box
      sx={{
        maxWidth: 1500,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        marginX: "auto",
        marginTop: 5,
      }}
    >
      <Box sx={{ width: "5%" }}>
        <img alt="join us logo" src={join_us_logo} />
      </Box>
      <Box sx={{ width: "100%", flexGrow: 1 }}>
        <img alt="join us background" src={joinus_background} />
      </Box>
    </Box>
  );
};

export default HeaderSection;
