import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CategoryItem } from "store/api/types";

type State = {
  categories: Array<CategoryItem>;
  activeCategory: string;
};

const initialState: State = {
  categories: [],
  activeCategory: "",
};

const categorySlice = createSlice({
  name: "category_slice",
  initialState,
  reducers: {
    setCategories: (
      state: State,
      { payload }: PayloadAction<Array<CategoryItem>>
    ) => {
      state.categories = payload;
      return state;
    },
    setActiveCategory: (state: State, { payload }: PayloadAction<string>) => {
      state.activeCategory = payload;
      return state;
    },
  },
});

export default categorySlice.reducer;
export const categorySliceActions = categorySlice.actions;
