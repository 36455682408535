import { defineMessages } from "react-intl";

const scope = "ExploreInteractive";

export default defineMessages({
  Home: {
    id: `${scope}.Home`,
    defaultMessage: "Home",
  },
  Interactive: {
    id: `${scope}.Interactive`,
    defaultMessage: "Interactive",
  },
  expolore_Interactive: {
    id: `${scope}.expolore_Interactive`,
    defaultMessage: "Explore Interactive",
  },
  Search: {
    id: `${scope}.Search`,
    defaultMessage: "Search",
  },
  empty_result: {
    id: `${scope}.empty_result`,
    defaultMessage: "Found 0 Results",
  },
});
