export const enum UploadedFileStatus {
  PENDING = 0,
  DRAFTED = 1,
  ACCEPTED = 2,
  REJECTED = 3,
  DELETED = 4,
  INACTIVE = 5,
}

export const enum NominationStatus {
  IDLE = 0,
}
