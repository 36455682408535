import { FC } from "react";
import styles from "./styles";
import { Grow, Modal, Paper, Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import messages from "./messages";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { modalSliceActions } from "store/modal_slice";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

const PrivacyPolicyModal: FC = () => {
  const { privacyPolicy: open } = useAppSelector((state) => state.modals);

  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(modalSliceActions.setPrivacyPolicy({ privacy: false }));
  };

  return (
    <Modal
      open={open}
      sx={{
        width: "60%",
        overflow: "scroll",
        marginX: "auto",
        marginTop: 2,
      }}
    >
      <Grow in={open} timeout={600}>
        <Paper
          sx={{
            boxShadow: 3,
            borderRadius: 3,
            overflow: "hidden",
          }}
        >
          <Stack
            direction="row"
            paddingTop={2}
            paddingInlineEnd={5}
            justifyContent="flex-end"
            alignItems="center"
          >
            <CloseRoundedIcon
              onClick={handleClose}
              sx={{ width: 30, cursor: "pointer", height: 30 }}
            />
          </Stack>
          <Stack direction="column" alignItems="center" sx={styles.container}>
            <Typography
              sx={{ fontSize: { lg: 20 }, fontWeight: "600", marginBlock: 5 }}
            >
              <FormattedMessage id={messages.title.id} />
            </Typography>
            <Typography sx={{ lineHeight: 1.7 }}>
              {/* <FormattedMessage id={messages.content.id} /> */}
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iste at
              molestias sit earum veniam ratione eos deleniti accusamus ab
              laboriosam, eaque eveniet soluta blanditiis, esse veritatis
              doloremque pariatur nihil cumque reprehenderit qui. Eos labore a
              pariatur vero, dolores alias quasi laboriosam repellendus
              corrupti. Iusto autem at error, eos labore facilis ipsum
              dignissimos nesciunt commodi necessitatibus nam quam sit aliquam,
              maxime beatae eum. At commodi eveniet odio blanditiis culpa
              voluptates eum, repudiandae debitis laborum cumque veritatis,
              adipisci, molestias alias atque consectetur doloribus veniam id ab
              quisquam dignissimos. Deserunt optio pariatur magnam atque nam, in
              numquam eveniet ullam. Adipisci reprehenderit minima natus
              asperiores at accusantium facere aliquam iusto, maiores iste
              officia quam placeat mollitia maxime deserunt odit architecto,
              magni aliquid non itaque repudiandae necessitatibus. Molestias,
              nesciunt culpa atque obcaecati rerum porro facilis maxime
              perspiciatis. Vel officia, officiis architecto doloribus aliquid
              quia soluta, impedit recusandae accusamus ducimus sit excepturi
              praesentium laborum hic tempore possimus culpa aperiam sunt
              voluptatem laboriosam fugiat quas, dolores sequi. Delectus autem
              veniam repudiandae, excepturi, eos ab placeat commodi unde velit
              magni nesciunt? Nostrum natus veritatis neque nulla atque incidunt
              cumque accusamus tempora sunt sint impedit quas expedita explicabo
              nisi, sed facilis commodi aspernatur suscipit, tempore
              necessitatibus maxime a nobis dolorum. Vitae labore ipsa nesciunt
              aut quasi. Dolore, tempore obcaecati omnis sapiente aspernatur,
              fugiat fugit ea unde quidem repellendus dolores sed minima nemo!
              Quaerat magni vel quia a! Delectus, officia debitis? Fuga aperiam
              sit exercitationem magnam veniam dolorem itaque voluptates dolore
              a eligendi? At minus incidunt, autem ducimus eveniet ipsam
              voluptates temporibus, harum similique commodi exercitationem
              reiciendis nostrum cupiditate molestiae omnis? Consectetur eveniet
              eius quo perferendis minus, nemo nobis iusto recusandae a at illo
              labore voluptas sapiente fuga, ipsa culpa aliquam earum aut quasi
              molestiae. Ad itaque quam beatae amet sed nemo blanditiis ipsum
              dicta. Quas id esse ad sequi dolores? Quidem itaque ab repudiandae
              autem ullam? Eos reprehenderit voluptas perferendis, ea obcaecati
              nihil, ab earum iure quis molestias culpa a tempora voluptatem ut
              pariatur. Adipisci et molestias mollitia, natus necessitatibus
              atque pariatur aliquam, nulla quia aliquid, suscipit voluptatem
              deleniti assumenda? Similique debitis nemo architecto voluptas
              adipisci! Nulla fugit nisi inventore, adipisci cupiditate magni
              reprehenderit quo ducimus culpa mollitia incidunt facilis
              necessitatibus vitae sit delectus. Ut quibusdam quis aliquid nam
              error ipsum facilis animi vel facere ad saepe ratione, commodi
              repellendus ex distinctio quia explicabo ab earum tempora sint
              suscipit quo? Temporibus obcaecati mollitia ducimus sunt rem
              error, architecto aperiam maxime! Iusto repudiandae nisi et, neque
              delectus provident ut natus fugit laborum vitae perspiciatis
              laudantium quaerat alias accusamus, aspernatur voluptatum commodi
              eaque beatae labore reiciendis in a fuga rerum nostrum? A modi
              reiciendis maiores voluptatibus, inventore minima voluptate!
              Assumenda soluta distinctio nisi id. Eveniet unde corporis
              temporibus, harum, a atque illo porro reprehenderit commodi iusto
              architecto repellat! Est error voluptatem optio nostrum facere
              iusto consectetur dignissimos cum enim at modi ipsa tempore dicta
              deleniti neque dolore libero dolorum impedit et accusamus, commodi
              minima, aliquid id. Nam ipsam, perspiciatis quidem minus magni,
              eaque adipisci assumenda quo deleniti eligendi nesciunt nobis
              tempora?
            </Typography>
          </Stack>
        </Paper>
      </Grow>
    </Modal>
  );
};

export default PrivacyPolicyModal;
