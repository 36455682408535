import { NavigationRoutes } from "navigation/routes";
import { ScreenHighlight } from "types/screen_highlight";

export const data: Array<ScreenHighlight> = [
  {
    label: "Home",
    path: NavigationRoutes.home,
  },
  {
    label: "Search",
    path: NavigationRoutes.search,
  },
];

export const types: Array<any> = [
  {
    id: 1,
    name: "Online Videos",
  },
  {
    id: 2,
    name: "Podcasts",
  },
  {
    id: 3,
    name: "Live Interactive",
  },
  {
    id: 3,
    name: "Speakers",
  },
  {
    id: 4,
    name: "Employees",
  },
  {
    id: 5,
    name: "Nominees",
  },
  {
    id: 6,
    name: "Dubai Police Speakers",
  },
];

export const limits: Array<any> = [
  {
    id: 1,
    name: "5",
  },
  {
    id: 2,
    name: "10",
  },
  {
    id: 3,
    name: "20",
  },
];
