import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { sidebarSliceActions } from "store/sidebar_slice";
import { FocusedInput } from "types/date_picker";

const useDateRangePicker = () => {
  const date = useAppSelector((state) => state.sidebar.dateFilter);

  const [focused, setFocused] = useState<FocusedInput>({ focused: null });

  const dispatch = useAppDispatch();

  const handleDateChange = (date: {
    startDate: moment.Moment | null;
    endDate: moment.Moment | null;
  }) => {
    dispatch(sidebarSliceActions.setDateFilter(date));
  };

  useEffect(() => {
    handleDateChange(date);
  }, [date]);

  return {
    startDate: date?.startDate,
    endDate: date?.endDate,
    focused,
    handleDateChange,
    setFocused,
  };
};

export default useDateRangePicker;
