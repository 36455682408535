import { FC } from "react";
import Stack from "@mui/material/Stack";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import Grow from "@mui/material/Grow";
import Checkbox from "@mui/material/Checkbox";
import styles from "./styles";
import useCategories from "hooks/use_categories";
import { useAppDispatch, useAppSelector } from "store/hooks";
import ArrowDown from "@mui/icons-material/KeyboardArrowDown";
import ArrowUp from "@mui/icons-material/KeyboardArrowUp";
import { sidebarSliceActions } from "store/sidebar_slice";
import { FormattedMessage } from "react-intl";
import messages from "pages/explore_speakers/sidebar_section/messages";

const CategorieFilter: FC = () => {
  const { visible, setVisible, selected } = useCategories();

  const dispatch = useAppDispatch();

  const { categories } = useAppSelector((state) => state.category);

  const handleCategoryChange = (value: number) => {
    if (selected === value) {
      dispatch(sidebarSliceActions.setCategoryFilter(null));
      return;
    }
    dispatch(sidebarSliceActions.setCategoryFilter(value));
  };

  const getArrowIcon = () => {
    return visible ? (
      <ArrowUp onClick={() => setVisible((visible) => !visible)} />
    ) : (
      <ArrowDown onClick={() => setVisible((visible) => !visible)} />
    );
  };

  return (
    <Stack sx={styles.container}>
      <Stack
        direction="row"
        sx={{
          width: "100%",
        }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography>
          <FormattedMessage id={messages.category.id} />
        </Typography>
        {getArrowIcon()}
      </Stack>
      <Stack sx={{ marginTop: 2 }} direction="column" alignItems="flex-start">
        {visible &&
          categories.map((entry) => (
            <Grow in={true} timeout={600}>
              <FormControlLabel
                key={entry.categoryId.toString()}
                sx={{ cursor: "pointer" }}
                label={entry.nameEn}
                control={
                  <Checkbox
                    value={entry.nameEn}
                    checked={entry.categoryId === selected}
                    onChange={() =>
                      handleCategoryChange(entry.categoryId as number)
                    }
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
              />
            </Grow>
          ))}
      </Stack>
    </Stack>
  );
};

export default CategorieFilter;
