import online_image2 from "assets/images/online_image1.webp";
import online_image3 from "assets/images/online_image2.webp";
import online_image4 from "assets/images/online_image3.webp";
import online_image5 from "assets/images/online_image4.webp";
import online_image1 from "assets/images/online_image1.webp";
import PoliceMember from "types/police_member";

export const data: Array<PoliceMember> = [
  {
    id: "1",
    image: online_image2,
    name: "Seif Eddine Kharrachi",
    position:
      "Director of Missions Department, Dubai Police Vice President of the Dubai Police Academy",
  },
  {
    id: "2",
    image: online_image3,
    name: "Seif Eddine Kharrachi",
    position:
      "Director of Missions Department, Dubai Police Vice President of the Dubai Police Academy",
  },
  {
    id: "3",
    image: online_image5,
    name: "Seif Eddine Kharrachi",
    position:
      "Director of Missions Department, Dubai Police Vice President of the Dubai Police Academy",
  },
  {
    id: "4",
    image: online_image1,
    name: "Seif Eddine Kharrachi",
    position:
      "Director of Missions Department, Dubai Police Vice President of the Dubai Police Academy",
  },
  {
    id: "5",
    image: online_image4,
    name: "Seif Eddine Kharrachi",
    position:
      "Director of Missions Department, Dubai Police Vice President of the Dubai Police Academy",
  },
];
