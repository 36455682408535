import { FC, useEffect } from "react";
import styles from "./styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { VideoResponse } from "store/api/types";
import { usePostViewMutation } from "store/api/view_service/endpoints";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { LivetipsOnlineSliceActions } from "store/livetips_online_slice";
import { View } from "store/api/view_service/types";
import FooterSection from "../footer_section";
import VideoPlayer from "../video_player";
import UserInfoSection from "../user_info_section";
import useSnack from "hooks/useSnack";
import SnackBar from "components/snack_bar";
import { useTheme } from "@mui/material/styles";
import { MEDIA_TYPE } from "types/media_type";
import ScreenNavigationTracker from "components/screen_navigation_tracker";
import { data as paths } from "../data";
import { NavigationRoutes } from "navigation/routes";
import { useNavigate } from "react-router-dom";
import PublishedDate from "components/published_date";
import { viewSliceActions } from "store/view_slice";
import { modalSliceActions } from "store/modal_slice";

type Props = {
  data?: VideoResponse;
};

const HeaderSection: FC<Props> = ({ data }) => {
  const theme = useTheme();

  const {
    global: { credentials, speakerProfile },
    livetipsOnline: { currentVideo, currentVideoViewsDetails },
  } = useAppSelector((state) => state);

  useEffect(() => {
    dispatch(
      viewSliceActions.setCurrentViewCount(
        parseInt(currentVideo.views_count?.toString())
      )
    );
  }, []);

  const upLg = useMediaQuery(theme.breakpoints.up("lg"));

  const [view] = usePostViewMutation();

  const dispatch = useAppDispatch();

  const { triggerSnack } = useSnack();

  const navigate = useNavigate();

  const viewProfile = () => {
    navigate(
      `${NavigationRoutes.public_speaker_profile}/${data?.user.customerId}`
    );
  };

  async function markAsViewed() {
    if (!currentVideoViewsDetails.viewed) {
      try {
        const res = (await view({
          body: {
            userId: speakerProfile.customerId,
            ownerId: currentVideo.user.customerId,
            videoId: currentVideo.id,
            digitalMediumType: MEDIA_TYPE.ON_DEMAND_VIDEO,
          },
          token: credentials.token.access_token,
        })) as { data: View };
        dispatch(LivetipsOnlineSliceActions.addDetailedVideoView(res.data));
        dispatch(viewSliceActions.updateCurrentViews());
      } catch (error) {
        triggerSnack(
          "Something Unexpected happened ! please try again",
          "error"
        );
      }
    }
  }

  const handleEditVideo = () => {
    dispatch(LivetipsOnlineSliceActions.setToEditVideo(currentVideo));
    dispatch(modalSliceActions.setEditVideo(true));
  };

  return (
    <Box sx={styles.container}>
      {upLg && (
        <Stack
          width="100%"
          marginTop={1}
          marginBottom={3}
          justifyContent="flex-start"
          direction="row"
          columnGap={2}
          alignItems="center"
        >
          <SnackBar />
          <ScreenNavigationTracker
            path={[
              ...paths,
              {
                label: data?.user.name || "Video",
                path: `${NavigationRoutes.public_speaker_profile}/${data?.user.customerId}`,
              },
            ]}
          />
        </Stack>
      )}
      <VideoPlayer videoUrl={data?.media} markAsViewed={markAsViewed} />
      <Box sx={styles.flex}>
        <UserInfoSection
          handleEditVideo={handleEditVideo}
          handleViewProfile={() => viewProfile()}
          profile_picture={data?.user.profilePicture}
          username={data?.user.name}
        />
        <Box textAlign="start" mt={1.5}>
          <Typography sx={styles.speaker}>{data?.title}</Typography>
          <PublishedDate publishDate={data?.created_date ?? ""} />
          <Typography sx={{ maxWidth: "80%" }}>{data?.description}</Typography>
        </Box>
        <FooterSection />
      </Box>
    </Box>
  );
};

export default HeaderSection;
