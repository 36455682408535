import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    marginTop: 5,
  },
  header: {
    fontSize: {
      lg: 25,
    },
    paddingInlineStart: 1.5,
    marginBottom: 2,
  },
};

export default styles;
