import { FC } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import styles from "./styles";
import PoliceSpeakerListItem from "../police_speaker_list_item";
import { FlattenedMember } from "store/api/membership_service/endpoints";
import HeaderSection from "../header_section";
import SkeletonCards from "common/skeleton_section";

type Props = {
  isLoading: boolean;
  data: FlattenedMember[];
};

const PoliceSpeakerList: FC<Props> = ({ data, isLoading }) => {
  return (
    <Box sx={styles.container}>
      <HeaderSection />
      <br />
      <Grid
        container
        columnGap={{ xs: 2, lg: 10, xl: 1 }}
        rowGap={5}
        justifyContent={{ xs: "center", md: "flex-start" }}
      >
        {!isLoading ? (
          data.map((entry) => (
            <Grid key={entry.id} xs={8.5} md={7} lg={3} xl={2.5}>
              <PoliceSpeakerListItem {...entry} />
            </Grid>
          ))
        ) : (
          <SkeletonCards xs={12} sm={10} md={6} lg={3.5} xl={3} />
        )}
      </Grid>
    </Box>
  );
};

export default PoliceSpeakerList;
