import { FC } from "react";
import { Paper, Stack, Typography } from "@mui/material";
import styles from "./styles";
import { ReactSVG } from "react-svg";
import { SocialMedia } from "components/share_modal/data";

const SocialChannel: FC<SocialMedia> = ({ label, icon }) => {
  return (
    <Stack direction="column" alignItems="center">
      <Paper sx={styles.container}>
        <ReactSVG src={icon} />
      </Paper>
      <Typography>{label}</Typography>
    </Stack>
  );
};

export default SocialChannel;
