import { FC } from "react";
import Box from "@mui/material/Box";
import Grow from "@mui/material/Grow";
import Typography from "@mui/material/Typography";
import styles from "./styles";
import color_pallette from "theme/color_pallette";

type Props = {
  icon: string;
  label: string;
  onClick: () => void;
};

const NominateAction: FC<Props> = ({ icon, label, onClick }) => {
  return (
    <Grow in timeout={700}>
      <Box onClick={onClick} sx={styles.root}>
        <Box sx={styles.container}>
          <Box component="img" src={icon} />
        </Box>
        <Typography sx={styles.label}>
          Create <span style={{ color: color_pallette.primary }}>{label}</span>
        </Typography>
      </Box>
    </Grow>
  );
};

export default NominateAction;
