import { FC } from "react";
import Box from "@mui/material/Box";
import styles from "./styles";
import { useGetPodcastListQuery } from "store/api/podcast_api/endpoints";
import { UploadedFileStatus } from "types/uploaded_file_status";
import EmptyList from "components/empty_list";
import PodcastList from "pages/home/podcast_section/podcast_list";
import { VideoQuery } from "types/video_query";
import { EmptyIconTypes } from "types/empty_list_icon_mapper";
import { GridProps, SxProps, Typography } from "@mui/material";
import PodcastSkeletonCards from "components/podcast_skeleton_card";

type Props = {
  videoQuery?: VideoQuery;
  emptyLabel?: string;
  icon?: EmptyIconTypes;
  limit?: [number, number];
  dimensions?: GridProps;
  skeletons?: GridProps;
  gridContainerProps?: SxProps;
  sectionLabel: string;
  skeletonsLength?: number;
};

const PodcastWrapper: FC<Props> = ({
  videoQuery,
  emptyLabel,
  skeletons,
  gridContainerProps,
  icon,
  skeletonsLength,
  sectionLabel,
  dimensions,
  limit,
}) => {
  const { data, isLoading } = useGetPodcastListQuery({
    status: UploadedFileStatus.ACCEPTED,
    ...videoQuery,
  });

  const podcasts = data?.results ? data.results : [];

  return (
    <Box sx={styles.container}>
      {!isLoading && podcasts.length === 0 ? (
        <>
          {icon || emptyLabel ? (
            <EmptyList label={emptyLabel} icon={icon ? icon : "podcast"} />
          ) : null}
        </>
      ) : !isLoading ? (
        <>
          {podcasts.length > 0 && (
            <Typography sx={styles.header}>{sectionLabel}</Typography>
          )}
          <PodcastList
            data={limit ? podcasts.slice(limit[0], limit[1]) : podcasts}
            dimensions={dimensions}
            gridContainerProps={{
              justifyContent: "flex-start",
              ...gridContainerProps,
            }}
            wrapperStyles={{
              width: { lg: "100%" },
            }}
          />
        </>
      ) : (
        <PodcastSkeletonCards
          xs={12}
          sm={10}
          md={5.9}
          lg={5.5}
          xl={3.8}
          marginInlineStart={{ xs: 5, md: 0 }}
          length={skeletonsLength}
          {...skeletons}
        />
      )}
    </Box>
  );
};

export default PodcastWrapper;
