import { SxProps } from "@mui/material";
import color_pallette from "theme/color_pallette";

const styles: Record<string, SxProps> = {
  paper: {
    borderRadius: 3,
    paddingInline: 1.5,
  },
  bodyContainer: {
    display: "flex",
    flexDirection: {
      xs: "column",
      md: "row",
    },
    marginTop: 1.5,
    columnGap: {
      md: 2,
    },
    justifyContent: "center",
    alignItems: { xs: "flex-start", md: "center" },
  },
  bg: { backgroundColor: color_pallette.backgroundGray },
  container: {
    marginX: "auto",
    textAlign: "center",
    paddingBottom: 4,
    marginTop: {
      xs: 2.5,
    },
    overflow: "scroll",
    width: {
      xs: "90%",
      sm: "85%",
      md: 800,
      lg: 800,
    },
  },
  formContainer: {
    paddingInline: { xs: 4, md: 0 },
    width: { xs: "100%" },
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    rowGap: { xs: 2, lg: 3.3 },
  },
  nextButton: {
    marginBlock: 3,
    width: {
      xs: 120,
    },
    fontSize: {
      xs: 17,
    },
  },
};

export default styles;
