import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    marginTop: 3,
    position: "relative",
    width: {
      xs: "100%",
      lg: "85%",
      xl: "75%",
    },
    top: 20,
    left: 0,
    display: "flex",
    flexDirection: {
      xs: "column",
      md: "row",
    },
    alignItems: "center",
  },
  backgroundImg: {
    background: "src/assets/images/circles.webp",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "contain",
  },
  headerContainer: {
    display: "flex",
    marginBottom: { xs: 20, lg: 10 },
    width: {
      xs: "100%",
      lg: "90%",
      xl: "60%",
    },
    flexDirection: "column",
    alignItems: {
      xs: "flex-start",
    },
  },
  sectionHeader: {
    color: "#5D8495",
    fontSize: {
      xs: 60,
      sm: 65,
      md: 73,
      xl: 90,
    },
    fontFamily: "Nizzar ,K2D, Inter",
    paddingInlineStart: {
      xs: 5,
    },
  },
  headerHightlighted: {
    color: "#008755",
    paddingInlineStart: {
      xs: "30%",
    },
    fontFamily: "Nizzar ,K2D, Inter",
    fontSize: {
      xs: 60,
      sm: 65,
      md: 73,
      xl: 90,
    },
  },
};

export default styles;
