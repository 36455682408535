import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    justifyContent: {
      xs: "center",
      sm: "center",
      md: "flex-start",
    },
  },
};

export default styles;
