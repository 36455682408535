import { FC, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import styles from "./styles";
import { FormikValues, useFormikContext } from "formik";
import getUrl from "./urls";
import { SEARCH_FORM } from "../form";
import axios from "axios";
import useSnack from "hooks/useSnack";
import { Grid } from "@mui/material";
import EmptyList from "components/empty_list";
import OnlineCardListItem from "components/online_card_list_item";
import PodcastCardListItem from "components/podcast_card_list_item";
import SpeakerListItem from "pages/explore_speakers/speaker_list_item";
import SnackBar from "components/snack_bar";
import { useAppSelector } from "store/hooks";
import { useIntl } from "react-intl";
import messages from "../messages";

const SearchContent: FC = () => {
  const [searchResult, setSearchResult] = useState<[any]>();
  const { values } = useFormikContext<FormikValues>();

  const token = useAppSelector(
    (state) => state.global.credentials.token.access_token
  );

  const { triggerSnack } = useSnack();

  const fetchData = async () => {
    const toFetch = getUrl(
      values[SEARCH_FORM.type],
      values[SEARCH_FORM.q],
      values[SEARCH_FORM.limit]
    );

    try {
      const res = (await axios.get(toFetch, {
        headers: { Authorization: `Bearer ${token}` },
      })) as {
        data: {
          results: [any];
        };
      };
      setSearchResult(res.data.results);
      triggerSnack("search updated", "info");
    } catch (error) {
      triggerSnack("Error occured while searching", "error");
    }
  };

  const getListItem = (entry: any) => {
    console.log("SPEAKER ###", entry);
    switch (values[SEARCH_FORM.type]) {
      case "Online Videos":
        return (
          <Grid item lg={3}>
            <OnlineCardListItem {...entry} />
          </Grid>
        );
      case "Podcasts":
        return (
          <Grid item>
            <PodcastCardListItem {...entry} />
          </Grid>
        );
      case "Speakers":
        return (
          <Grid item>
            <SpeakerListItem
              profile_picture={entry.user.profilePicture}
              name={entry.user.name}
              org={entry.user.org}
              id={entry.id}
            />
          </Grid>
        );
      case "Nominees":
        return (
          <Grid item>
            <SpeakerListItem {...entry} />
          </Grid>
        );
    }
  };

  const intl = useIntl();

  useEffect(() => {
    fetchData();
  }, [values]);

  return (
    <Box sx={styles.container}>
      <Grid container gap={3}>
        {searchResult && searchResult.length > 0 ? (
          searchResult
            .slice(0, parseInt(values[SEARCH_FORM.limit]))
            .map((entry) => getListItem(entry))
        ) : (
          <EmptyList
            label={intl.formatMessage(messages.no_results)}
            icon="interactive"
          />
        )}
      </Grid>
      <SnackBar />
    </Box>
  );
};

export default SearchContent;
