import { SxProps } from "@mui/material";
import color_pallette from "theme/color_pallette";

const styles: Record<string, SxProps> = {
  container: {
    boxShadow: 1,
    borderRadius: 3,
    position: "relative",
    overflow: "hidden",
    width: 280,
    cursor: "pointer",
    backgroundColor: color_pallette.backgroundGray,
  },
  footerContainer: {
    paddingInline: 1.5,
    paddingBottom: 1,
  },
  viewProfile: {
    width: 100,
    height: 30,
    boxShadow: 0,
    fontSize: {
      xs: 12,
    },
    opacity: 0.8,
    color: "white",
    fontWeight: "400",
  },
  speakerName: {
    paddingInline: 1.5,
    paddingTop: 0.5,
    fontSize: {
      xs: 19,
    },
  },
  speakerPosition: {
    fontSize: {
      xs: 12,
    },
  },
};

export default styles;