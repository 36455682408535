import { FC, useMemo } from "react";
import { Box, SxProps } from "@mui/material";
import styles from "./styles";

export type ImageWrapperProps = {
  itemData: { src: string; extraStyles?: SxProps };
};

const Image: FC<ImageWrapperProps> = ({ itemData }) => {
  const containerStyle = useMemo(() => {
    return { ...styles.container, ...itemData.extraStyles };
  }, [itemData.extraStyles]);

  return (
    <Box
      component="img"
      alt="Image Component"
      src={itemData.src}
      loading="lazy"
      sx={containerStyle}
    />
  );
};

export default Image;
