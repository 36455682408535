import { SxProps } from "@mui/material";
import color_pallette from "theme/color_pallette";

const styles: Record<string, SxProps> = {
  container: {
    borderRadius: 1.5,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    marginInline: 0.3,
  },
  time: {
    backgroundColor: "#F3F3F3",
    height: 40,
    width: 50,
    display: "flex",
    borderRadius: 2,
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "600",
    fontFamily: "Nizzar ,K2D, Inter",
    fontSize: {
      lg: 20,
    },
    color: color_pallette.primary,
  },
  label: {
    color: color_pallette.primary,
    fontFamily: "Nizzar ,K2D, Inter",
    fontSize: {
      lg: 10,
    },
  },
};

export default styles;
