import { FC, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grow from "@mui/material/Grow";
import Stack from "@mui/material/Stack";
import { MenuItemData } from "components/appbar/menu_items/data";
import { useNavigate } from "react-router-dom";
import ArrowRight from "@mui/icons-material/ArrowRight";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";

type Props = {
  entry: MenuItemData;
  index: number;
};

const MobileMenuItem: FC<Props> = ({ entry, index }) => {
  const [show, setShow] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  return (
    <Box
      key={entry.item.path}
      onClick={
        entry.subMenu
          ? () => setShow(!show)
          : () => handleNavigate(entry.item.path)
      }
      sx={{ paddingTop: index === 0 ? 3 : 0, cursor: "pointer" }}
    >
      <Stack direction="row" alignItems="center">
        <Typography>{entry.item.label}</Typography>
        {entry.subMenu ? !show ? <ArrowRight /> : <ArrowDropDown /> : null}
      </Stack>
      {show && entry.subMenu && (
        <Stack rowGap={1} sx={{ paddingInlineStart: 3 }}>
          {entry.subMenu.map((entry, index) => (
            <Grow key={entry.path} in timeout={700}>
              <Typography
                sx={{
                  paddingTop: index === 0 ? 1.5 : 0,
                  opacity: 0.7,
                  cursor: "pointer",
                }}
                onClick={() => handleNavigate(entry.path)}
              >
                {entry.label}
              </Typography>
            </Grow>
          ))}
        </Stack>
      )}
    </Box>
  );
};

export default MobileMenuItem;
