import { FC } from "react";
import Paper from "@mui/material/Paper";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grow from "@mui/material/Grow";
import styles from "./styles";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { ReactSVG } from "react-svg";
import Button from "components/custom_button";
import upload from "assets/svgs/upload_podcast.svg";
import cancelIc from "assets/svgs/cancel.svg";
import { MODAL_ACTION_TYPE, ProfileSliceActions } from "store/profile_slice";
import { FormattedMessage } from "react-intl";
import messages from "./messages";
import PrivacyPolicy from "components/privacy_policy";
import { Box } from "@mui/material";

const StepGuidanceModalOnline: FC = () => {
  const showModal = useAppSelector(
    (state) => state.adminProfil.modals.showGuideModal
  );

  const actionType = useAppSelector((state) => state.adminProfil.actionType);

  const dispatch = useAppDispatch();

  const handleCancel = () => {
    dispatch(ProfileSliceActions.clearActionType());
    dispatch(ProfileSliceActions.closeModals());
  };

  const handleNext = () => {
    if (actionType === MODAL_ACTION_TYPE.online) {
      dispatch(ProfileSliceActions.showUploadModal());
      return;
    }
    dispatch(ProfileSliceActions.showOnlinePremier());
  };

  return (
    <Modal open={showModal} sx={styles.container}>
      <Grow in={showModal} timeout={600}>
        <Paper sx={styles.paper}>
          <Stack
            width="100%"
            direction="row"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
          >
            <Typography flexGrow={0.9} sx={styles.header}>
              Online
            </Typography>
            <ReactSVG
              onClick={handleCancel}
              style={{ cursor: "pointer", paddingBottom: "25px" }}
              src={cancelIc}
            />
          </Stack>
          <ReactSVG src={upload} />
          <Typography sx={styles.instruction}>
            User can upload pre Recorded, Live and Scheduled Online Videos
          </Typography>
          <Typography sx={styles.notice}>
            Your Videos will be private until you publish them.
          </Typography>
          <Button
            onClick={() => handleNext()}
            extraStyles={styles.button}
            label="Next Step"
          />
          <Typography sx={styles.privacy}>
            <FormattedMessage id={messages.footer.id} />
          </Typography>
          <Box sx={{ paddingBottom: 3 }}>
            <PrivacyPolicy />
          </Box>
        </Paper>
      </Grow>
    </Modal>
  );
};

export default StepGuidanceModalOnline;
