export const enum NAMESPACES {
  category = "category",
  user = "user",
  identity = "identity",
  video = "video",
  blog = "blog",
  cms = "cms",
  speaker = "speaker",
  podcast = "podcast",
  otp = "verification",
  bookmark = "bookmark",
  nomination = "nomination",
  notification = "inAppNotification",
  share = "share",
  nominees = "nominees",
  comment = "comment",
  rate = "rate",
  view = "view",
  like = "like",
  liveStreaming = "live_streaming",
  streaming = "streaming",
  messages = "chat/data-management/messages",
  participants = "participants",
  conversations = "conversations",
}
