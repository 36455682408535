import { useEffect, useState } from "react";
import { useGetAllCategoriesQuery } from "store/api/categorie_service/endpoints";
import { CategoryItem } from "store/api/types";
import { categorySliceActions } from "store/category_slice";
import { useAppDispatch, useAppSelector } from "store/hooks";

const useCategories = () => {
  const dispatch = useAppDispatch();

  const [visible, setVisible] = useState<boolean>(false);

  const selected = useAppSelector((state) => state.sidebar.categoryFilter);

  const token = useAppSelector(
    (state) => state.global.credentials.token.access_token
  );

  const res = useGetAllCategoriesQuery(
    {
      token,
    },
    {
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
    }
  ) as { data: Array<CategoryItem> };

  const getAllCategories = async () => {
    dispatch(categorySliceActions.setCategories(res.data ? res.data : []));
  };

  useEffect(() => {
    getAllCategories();
  }, []);

  return { visible, setVisible, selected };
};

export default useCategories;
