import { SxProps } from "@mui/material";
import color_pallette from "theme/color_pallette";

const styles: Record<string, SxProps> = {
  container: {
    marginTop: { xs: 15, lg: 8 },
    flexGrow: 1,
    textAlign: {
      xs: "center",
    },
    order: {
      md: 2,
    },
    paddingInlineEnd: {
      lg: 25,
      xl: 35,
    },
  },
  editIcon: {
    width: 20,
    cursor: "pointer",
    color: "black",
    marginInline: 2,
    height: 20,
  },
  ratingContainer: {
    border: `2px solid ${color_pallette.primary}`,
    borderWidth: 1.9,
    borderRadius: 5,
    paddingInline: 1.5,
    paddingBlock: 0.5,
    color: color_pallette.primary,
    "&:hover": {
      color: color_pallette.primary,
    },
  },
  shareButton: {
    borderRadius: 5,
    borderColor: color_pallette.primary,
    color: "black",
    borderWidth: 1.8,
    paddingInline: 3,
    "&:hover": {
      color: "white",
    },
  },
  statsContainer: {
    flexDirection: "row",
    alignItems: "center",
    columnGap: 3,
    mt: { xs: 1, xl: 2.5 },
    justifyContent: "center",
  },
  emptyBio: {
    opacity: 0.5,
    backgroundColor: color_pallette.backgroundGray,
    border: "2px solid dashed",
    borderColor: color_pallette.backgroundGray,
    height: 100,
    width: 500,
    display: "flex",
    marginX: "auto",
    alignItems: "center",
    justifyContent: "center",
  },
  bioContentContainer: {
    width: "fit-content",
    marginX: "auto",
    padding: 2,
    borderRadius: 2,
    backgroundColor: color_pallette.backgroundGray,
    marginTop: { lg: 1, xl: 1.5 },
  },
  bioContent: {
    minHeight: "100%",
    width: "100%",
  },
  headerTitle: {
    fontSize: {
      xs: 30,
    },
    fontWeight: "700",
    color: color_pallette.primary,
  },
  headerSubTitle: {
    fontSize: {
      xs: 18,
    },
  },
  footerContainer: {
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    columnGap: 2,
    display: "flex",
  },
  stackContainer: {
    marginTop: { xs: 2, lg: 3, xl: 4 },
    justifyContent: "center",
    flexDirection: { lg: "row" },
    alignItems: "center",
    columnGap: 5,
    rowGap: 1.5,
  },
  bioHeader: {
    color: color_pallette.primary,
    fontSize: {
      xs: 22,
    },
    marginTop: { xs: 2, xl: 4 },
  },
};

export default styles;