import { defineMessages } from "react-intl";

const scope = "ServiceSection";

export default defineMessages({
  online_t: {
    id: `${scope}.online_t`,
    defaultMessage: "Online",
  },
  online_d: {
    id: `${scope}.online_d`,
    defaultMessage: "Engage in real-time learning experiences.",
  },
  podcast_d: {
    id: `${scope}.podcast_d`,
    defaultMessage: "Tune into thought-provoking podcasts.",
  },
  podcast_t: {
    id: `${scope}.podcast_t`,
    defaultMessage: "Podcast",
  },
  interactive_d: {
    id: `${scope}.interactive_d`,
    defaultMessage: "Participate in interactive discussions and activities.",
  },
  interactive_t: {
    id: `${scope}.interactive_t`,
    defaultMessage: "Interactive",
  },
  employee_d: {
    id: `${scope}.employee_d`,
    defaultMessage: "Access exclusive insights from professionals.",
  },
  employee_t: {
    id: `${scope}.employee_t`,
    defaultMessage: "Employees",
  },
  nomination_d: {
    id: `${scope}.nomination_d`,
    defaultMessage:
      "Nominate or be nominated for special recognition. or as speaker",
  },
  nomination_t: {
    id: `${scope}.nomination_t`,
    defaultMessage: "Nomination",
  },
});
