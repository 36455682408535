import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    marginTop: 2,
    height: { lg: 700 },
    width: "75%",
    /* TODO: put background here... */
  },
  loadingModal: {
    borderRadius: 3,
    boxShadow: 3,
    width: "100%",
    height: "100%",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
};

export default styles;
