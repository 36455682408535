import { FC } from "react";
import styles from "./styles";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { Grow, Modal, Paper, Stack, Typography } from "@mui/material";
import Form from "./form_section";
import { initialValues, validationSchema } from "./form";
import { modalSliceActions } from "store/modal_slice";
import FormContainer from "common/form_container";
import CloseIcon from "@mui/icons-material/Close";
import SubmitButton from "common/submit_button";
import useSnack from "hooks/useSnack";
import SnackBar from "components/snack_bar";

const WatchNowModal: FC = () => {
  const showModal = useAppSelector((state) => state.modals.watchNow);

  const dispatch = useAppDispatch();

  const { triggerSnack } = useSnack();

  const handleSubmit = () => {
    triggerSnack("You will be notified 2 days prior", "success");
    setTimeout(() => {
      dispatch(modalSliceActions.watchNow(false));
    }, 2000);
  };

  const handleClose = () => {
    dispatch(modalSliceActions.watchNow(false));
  };

  return (
    <Modal open={showModal} sx={styles.container}>
      <FormContainer
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Grow in={showModal} timeout={600}>
          <Paper sx={styles.paper}>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <CloseIcon onClick={() => handleClose()} sx={styles.icon} />
            </Stack>
            <Typography sx={styles.header}>
              How would you like to watch this Interactive Event
            </Typography>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              width="100%"
            >
              <Form />
            </Stack>
            <SnackBar />
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              sx={{ paddingInlineEnd: { xs: 5, lg: 11.5 }, paddingTop: 1.5 }}
            >
              <SubmitButton title="Confirm" />
            </Stack>
          </Paper>
        </Grow>
      </FormContainer>
    </Modal>
  );
};

export default WatchNowModal;
