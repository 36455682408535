import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    paddingInline: 5,
    zIndex: 1,
    paddingBottom: 3,
    overflow: "scroll",
  },
  agree: {
    height: 50,
    boxShadow: 5,
    width: "100%",
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
    display: "flex",
    paddingInline: 2,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
};

export default styles;
