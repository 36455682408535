import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  root: {
    overflow: "hidden",
    borderRadius: 8,
    flexGrow: 1,
    boxShadow: 4,
  },
  container: {
    display: "flex",
    marginTop: { xs: 2, lg: 5 },
    flexDirection: {
      xs: "column",
      md: "row",
      lg: "row",
    },
    alignItems: {
      xs: "flex-start",
      sm: "center",
      md: "flex-start",
      xl: "center",
    },
    paddingBottom: {
      xs: 2,
    },
  },
  bodyStyles: {
    paddingInline: {
      xs: 5,
      sm: 5,
      md: 1,
    },
    rowGap: {
      xs: 1.5,
      md: 3,
      lg: 2.5,
      xl: 2,
    },
    marginInlineEnd: { sm: 4, xl: 3 },
    marginBottom: 5,
  },
  header: {
    fontFamily: "Nizzar ,K2D, Inter",
    fontSize: {
      xs: 30,
      md: 35,
      lg: 55,
    },
    marginTop: { lg: 7, xl: 1 },
    fontWeight: "700",
    textAlign: {
      xs: "center",
      md: "start",
      lg: "center",
    },
    background:
      "linear-gradient(94.9deg, #FE9867 20.84%, #5A834F 52.4%, #E1D2B5 88.64%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    paddingInlineStart: {
      md: 5,
    },
    paddingTop: { xs: 1, lg: 2, xl: 5 },
    marginBottom: 3,
  },
};

export default styles;
