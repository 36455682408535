import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    marginTop: { xs: 5, xl: 3 },
    /* flexGrow: { lg: 0.8 }, */
  },
};

export default styles;
