import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {},
  name: {
    fontWeight: "600",
    fontSize: {
      lg: 14,
    },
    width: "80%",
    whiteSpace: "break-spaces",
  },
};

export default styles;
