import { Methods } from "types/http";
import apiUrls from "../urls";
import { VideoQuery } from "types/video_query";
import { UploadVideoRequest, VideoDetailsRequest } from "./endpoints";
import handleFormDataRequests from "utils/upload_form_data";
import { OnlineFormData } from "./types";
import { Token } from "../podcast_api/types";
import { UpdateVideoBody, VideoResponse } from "../types";
import { Identification } from "types/identification";

export const uploadVideo = (body: FormData) => ({
  url: apiUrls.createVideo(),
  method: Methods.POST,
  body,
  headers: {
    Lng: "EN",
    Accept: "*/*",
    "Content-Type": "multipart/form-data",
  },
});

export const checkHealthAction = () => ({
  url: apiUrls.healthCheck(),
  method: Methods.GET,
});

export const getVideoListAction = (param: VideoQuery) => ({
  url: apiUrls.getVideoList(param),
  method: Methods.GET,
  headers: {
    Lng: "EN",
    Accept: "application/json",
  },
});

export const createVideoAction = ({ token, body }: UploadVideoRequest) => ({
  url: apiUrls.createVideo(),
  method: Methods.POST,
  body,
  headers: {
    Lng: "EN",
    Accept: "application/json",
    "Accept-Encoding": "gzip, deflate, br",
    "Content-Type": "multipart/form-data; boundry=6000000000",
    Authorization: `Bearer ${token}`,
  },
});

export const getVideoByIdAction = (param: VideoDetailsRequest) => ({
  url: apiUrls.videoById(param?.id),
  method: Methods.GET,
  headers: {
    Lng: "EN",
    Accept: "application/json",
    "Accept-Encoding": "gzip, deflate, br",
    Authorization: `Bearer ${param.token}`,
  },
});

export const updateVideoByIdAction = (params: {
  id: Identification;
  token: string;
  body: Partial<UpdateVideoBody>;
}) => ({
  url: apiUrls.videoById(params.id),
  method: Methods.PUT,
  body: params.body,
  headers: {
    Lng: "EN",
    Accept: "application/json",
    Authorization: `Bearer ${params.token}`,
  },
});

export const deleteVideoByIdAction = (id: string) => ({
  url: apiUrls.videoById(id),
  method: Methods.DELETE,
});

export const partialUpdateVideoByIdAction = (id: string) => ({
  url: apiUrls.videoById(id),
  method: Methods.PATCH,
});

export const uploadVideoFormData = async (body: OnlineFormData & Token) => {
  const res = await handleFormDataRequests<VideoResponse>({
    code: Methods.POST,
    url: apiUrls.createVideo(),
    body,
    headers: {
      Lng: "EN",
      Accept: "application/json",
      "Accept-Encoding": "gzip, deflate, br",
      "Content-Type": "multipart/form-data; boundry=6000000000",
      Authorization: `Bearer ${body.token}`,
    },
  });
  return res;
};

export const updateVideoFormData = async (body: OnlineFormData & Token) => {
  const res = await handleFormDataRequests<void>({
    code: Methods.PATCH,
    url: apiUrls.videoById(),
    body,
    headers: {
      Lng: "EN",
      Accept: "application/json",
      "Accept-Encoding": "gzip, deflate, br",
      "Content-Type": "multipart/form-data; boundry=6000000000",
      Authorization: `Bearer ${body.token}`,
    },
  });
  return res;
};