import { FC, useMemo } from "react";
import Box from "@mui/material/Box";
import styles from "./styles";
import { EditLiveStreamModal } from "../form";
import { FormikValues, useFormikContext } from "formik";
import thumbNail from "assets/images/placeholders/thumbnail_pl.webp";
import PlaceholderImage from "components/placeholder_image";
import Button from "components/custom_button";
import { Typography } from "@mui/material";
import { useAppDispatch } from "store/hooks";
import { ProfileSliceActions } from "store/profile_slice";

const MetaSection: FC = () => {
  const { values } = useFormikContext<FormikValues>();
  const thumb = values[EditLiveStreamModal.thumbnail];

  const dispatch = useAppDispatch();

  const url = useMemo(() => {
    try {
      return URL.createObjectURL(thumb);
    } catch (error) {
      return thumbNail;
    }
  }, [thumb]);

  const handleInvite = () => {
    /* TODO: display invite_speakers_modal */
    dispatch(ProfileSliceActions.showInviteSpeakersModal());
  };

  return (
    <Box sx={styles.container}>
      {thumb ? (
        <img width={350} height={200} src={url} />
      ) : (
        <PlaceholderImage />
      )}
      <Box>
        <Button
          onClick={() => handleInvite()}
          extraStyles={{ width: 150, marginBottom: 1.5 }}
          label="Invite Speakers"
        />
        <Typography sx={{ opacity: 0.55, fontSize: 12 }}>
          click to show list of speakers you would like to invite
        </Typography>
      </Box>
    </Box>
  );
};

export default MetaSection;
