import { FC } from "react";
import Box from "@mui/material/Box";
import styles from "./styles";
import callToJoinImg from "assets/images/call_to_join_img.webp";
import CustomButton from "components/custom_button";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "navigation/routes";
import { useAppSelector } from "store/hooks";
import { UploadedFileStatus } from "types/uploaded_file_status";
import { useIntl } from "react-intl";
import messages from "../messages";

type Props = {
  img?: string;
  nominate?: boolean;
};

const CallToJoin: FC<Props> = ({ img, nominate }) => {
  const navigate = useNavigate();

  const { status } = useAppSelector((state) => state.global.speakerProfile);

  const handleJoinRequest = () => {
    navigate(NavigationRoutes.joinUs);
  };

  const getLabel = () => {
    switch (status) {
      case UploadedFileStatus.PENDING:
        return "Pending Status";
      case UploadedFileStatus.DRAFTED:
        return "Drafted Status";
      default:
        return "JOIN US";
    }
  };

  const intl = useIntl();

  return (
    <Box sx={styles.container}>
      <Box component="img" src={img ?? callToJoinImg} />
      <CustomButton
        label={
          !nominate ? getLabel() : `${intl.formatMessage(messages.nominate)}`
        }
        onClick={() => handleJoinRequest()}
        extraStyles={{
          width: 200,
          height: 50,
          marginTop: 5,
          fontSize: { lg: 20 },
        }}
      />
    </Box>
  );
};

export default CallToJoin;
