import { FC } from "react";
import {
  Box,
  BoxProps,
  SxProps,
  Typography,
  TypographyProps,
} from "@mui/material";
import styles from "./styles";
import combineStyles from "utils/combile_styles";

type Props = {
  label: string;
  remainingTime: number;
  timeBoxStyle?: SxProps<BoxProps>;
  timeLabelStyle?: SxProps<TypographyProps>;
};

const ChronometerBox: FC<Props> = ({
  label,
  remainingTime,
  timeBoxStyle,
  timeLabelStyle,
}) => {
  return (
    <Box sx={styles.container}>
      <Box sx={combineStyles(styles.time, timeBoxStyle)}>{remainingTime}</Box>
      <Typography sx={combineStyles(styles.label, timeLabelStyle)}>
        {label}
      </Typography>
    </Box>
  );
};

export default ChronometerBox;
