import { FC, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import styles from "./styles";
import ContentSection from "./content_section";
import SwiperCard from "components/swiper_card";
import CommentSection from "pages/view_admin_profile/comment_section";
import { useGetMembershipByIdQuery } from "store/api/membership_service/endpoints";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { ProfileSliceActions } from "store/profile_slice";
import { UserResponse } from "store/api/types";
import { usePostViewMutation } from "store/api/view_service/endpoints";
import { MEDIA_TYPE } from "types/media_type";
import ShareModal from "components/share_modal";

const PublicViewProfile: FC = () => {
  const params = useParams();

  const dispatch = useAppDispatch();

  const {
    credentials: { token },
    speakerProfile,
  } = useAppSelector((state) => state.global);

  const [view] = usePostViewMutation();

  const markProfileViewed = async () => {
    /* HANDLE CASE IF ALREADY VIEWED */
    try {
      await view({
        token: token.access_token,
        body: {
          userId: speakerProfile.customerId,
          ownerId: params.id as string,
          videoId: params.id as string,
          digitalMediumType: MEDIA_TYPE.USER_PROFILE,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    markProfileViewed();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data, isError } = useGetMembershipByIdQuery(
    {
      id: params.id as string,
      token: token.access_token,
    },
    { refetchOnMountOrArgChange: true, refetchOnFocus: true }
  );

  useEffect(() => {
    if (!isError)
      dispatch(ProfileSliceActions.submitCurrentMember(data as UserResponse));

    return () => {
      dispatch(ProfileSliceActions.clearCurrentMember());
    };
  }, [data, dispatch, isError]);

  return (
    <Box sx={styles.container}>
      <ContentSection />
      <Stack
        mt={{ xs: 5, lg: 20 }}
        direction={{ xs: "column", md: "row" }}
        alignItems={{ xs: "center", md: "flex-start" }}
        justifyContent="space-around"
      >
        <SwiperCard />
        <ShareModal />
        {data && (
          <CommentSection
            ownerId={data.user.customerId}
            profileName={data?.user.name}
          />
        )}
      </Stack>
    </Box>
  );
};

export default PublicViewProfile;
