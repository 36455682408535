import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    marginTop: 3,
  },
  noticeMsg: { fontWeight: "700", font: "Inter, K2D" },
  noticeMsg2: {
    fontWeight: "400",
    marginTop: 2,
    fontSize: 16,
    font: "Inter, K2D",
  },
  mg: { marginTop: 1 },
  font: { fontWeight: "300" },
};

export default styles;
