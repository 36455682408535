import { SxProps } from "@mui/material";
import color_pallette from "theme/color_pallette";

const styles: Record<string, SxProps> = {
  container: {
    marginTop: 2,
  },
  flex: {
    display: "flex",
    flexDirection: {
      xs: "column",
      sm: "row",
    },
  },
  mgInline: { marginInline: 1 },
  mg: { marginTop: 2 },
  highlightFont: { color: color_pallette.primary, fontWeight: "300" },
};

export default styles;
