import { FC } from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grow from "@mui/material/Grow";
import Stack from "@mui/material/Stack";
import Rating from "@mui/material/Rating";
import microphone from "assets/svgs/microphone.svg";
import styles from "./styles";
import cropText from "utils/crop_text";
import Button from "components/custom_button";
import color_pallette from "theme/color_pallette";
import profilePL from "assets/images/placeholders/thumbnail_pl.webp";
import { PodcastItemResponse } from "store/api/podcast_api/types";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "navigation/routes";
import { useIntl } from "react-intl";
import messages from "pages/register_page/messages";

const PodcastCardListItem: FC<PodcastItemResponse> = (props) => {
  const navigate = useNavigate();

  const handlePodcastDetails = () => {
    navigate(`${NavigationRoutes.podcasts}/${props.id}`);
  };

  const intl = useIntl();

  return (
    <Grow in timeout={700}>
      <Paper onClick={handlePodcastDetails} sx={styles.container}>
        <img
          style={{
            borderRadius: "15px",
            aspectRatio: "4 / 4",
            objectFit: "cover",
            width: 140,
          }}
          alt="podcast item image"
          src={props.thumbnail ?? profilePL}
        />
        <Box sx={styles.textContainer}>
          <Typography sx={styles.title}>
            {cropText(props.user.name, 10)}
          </Typography>
          <Typography sx={styles.subTitle}>
            {cropText(props.user.org, 45)}
          </Typography>
          <Typography sx={styles.desc}>
            {cropText(props.description, 15)}
          </Typography>
        </Box>
        <Box sx={styles.infoContainer}>
          <Stack
            columnGap={1}
            marginInlineEnd={0.6}
            alignItems="center"
            direction="row"
          >
            <Rating
              sx={{
                color: color_pallette.primary,
              }}
              readOnly
              size="small"
              value={0}
            />
            <Typography fontSize={{ lg: 10 }}>0</Typography>
          </Stack>
          <img
            style={{ aspectRatio: "4 / 4", width: 70 }}
            alt="Microphone Logo"
            src={microphone}
          />
          <Button
            extraStyles={styles.button}
            onClick={handlePodcastDetails}
            label={intl.formatMessage(messages.listen)}
          />
        </Box>
      </Paper>
    </Grow>
  );
};

export default PodcastCardListItem;
