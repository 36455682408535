import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    height: 1.1,
    opacity: 0.3,
    width: "70%",
    backgroundColor: "black",
    borderRadius: 2,
  },
};

export default styles;
