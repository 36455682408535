import { FC, useMemo } from "react";
import styles from "./styles";
import { Stack, SxProps, Typography, TypographyProps } from "@mui/material";
import { ScreenHighlight } from "types/screen_highlight";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "navigation/routes";
import { FormattedMessage } from "react-intl";
import combineStyles from "utils/combile_styles";
import color_pallette from "theme/color_pallette";

type Props = {
  path: Array<ScreenHighlight>;
  extraStyles?: SxProps<TypographyProps>;
};

const ScreenNavigationTracker: FC<Props> = ({ path, extraStyles }) => {
  const navigate = useNavigate();

  const handlePathClick = (path: NavigationRoutes | string) => {
    navigate(path);
  };

  const style = useMemo(() => {
    return combineStyles(styles.label, extraStyles);
  }, [extraStyles]);

  return (
    <Stack alignItems="center" columnGap={1} direction="row" sx={styles.path}>
      {path.map((entry, index) => (
        <>
          <Typography
            onClick={() => handlePathClick(entry.path)}
            key={entry.label}
            sx={style}
          >
            <FormattedMessage id={entry.label} />
          </Typography>
          {index < path.length - 1 ? (
            <span style={{ color: color_pallette.primary }}>{" > "}</span>
          ) : null}
        </>
      ))}
    </Stack>
  );
};

export default ScreenNavigationTracker;
