import { baseUrl } from "store/api";
import { NAMESPACES } from "store/api/namespaces";
import { UploadPrivacy } from "types/upload_privacy";
import { UploadedFileStatus } from "types/uploaded_file_status";

const getUrl = (type: string, q: string, limit: number): string => {
  let endpoint = getEndpoint(type);
  if (q) endpoint = endpoint.concat(`?keyword=${q}&limit=${limit}`);
  else endpoint = endpoint.concat(`?limit=${limit}`);

  endpoint = endpoint.concat(
    `&status=${UploadedFileStatus.ACCEPTED}&privacy=${UploadPrivacy.PUBLIC}`
  );
  return endpoint;
};

const getEndpoint = (type: string): string => {
  switch (type) {
    case "Online Videos": {
      return `${baseUrl}${NAMESPACES.video}/on_demand/all`;
    }
    case "Podcasts": {
      return `${baseUrl}${NAMESPACES.podcast}/on_demand/all`;
    }
    case "Speakers": {
      return `${baseUrl}${NAMESPACES.speaker}/membership/`;
    }
    case "Nominees": {
      return `${baseUrl}${NAMESPACES.nomination}/nominations`;
    }
    default:
      return `${baseUrl}${NAMESPACES.video}/on_demand/all`;
  }
};

export default getUrl;
