import { FormikValues } from "formik";
import * as yup from "yup";
import { OnlineFormProperties } from "./form_properties";

export type SpeakerForm = FormikValues;

const validateSchema = yup.object({
  [OnlineFormProperties.UPLOADED_FILE]: yup.string(),
  [OnlineFormProperties.COUNTRY]: yup.string().required(),
  [OnlineFormProperties.CITY]: yup.string().required(),
  [OnlineFormProperties.DESCRIPTION]: yup.string().required(),
  [OnlineFormProperties.WEB_LINKS]: yup.string(),
  [OnlineFormProperties.WEB_LINKS_FILE]: yup.string(),
  [OnlineFormProperties.CONSENTED]: yup
    .bool()
    .oneOf([true], "you must consent to the terms"),
  [OnlineFormProperties.GENDER]: yup.string().required(),
  [OnlineFormProperties.SPEAKER_CLASS]: yup.number().required(),
});

const initialFormState = {
  [OnlineFormProperties.UPLOADED_FILE]: "",
  [OnlineFormProperties.DESCRIPTION]: "",
  [OnlineFormProperties.COUNTRY]: "United Arab Emirates",
  [OnlineFormProperties.CITY]: "",
  [OnlineFormProperties.GENDER]: "",
  [OnlineFormProperties.SPEAKER_CLASS]: 1,
  [OnlineFormProperties.WEB_LINKS]: "",
  [OnlineFormProperties.WEB_LINKS_FILE]: "",
  [OnlineFormProperties.CONSENTED]: false,
};

export default SpeakerForm;
export { validateSchema, initialFormState };
