import rootApi from "store/api";
import {
  ChatMessage,
  ChatMessageListResponse,
  GetParticipantsArgs,
  PostMessageResponse,
} from "../types";
import {
  getChatMessageById,
  getChatMessages,
  postChatMessage,
} from "./action_creators";
import { Authorization } from "store/api/types";
import { Identification } from "types/identification";

export const ChatMessageServiceApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    addMessage: builder.mutation<
      PostMessageResponse,
      { body: ChatMessage; auth: Authorization }
    >({
      query: (body) => postChatMessage(body),
    }),
    getChatMessages: builder.query<
      Array<ChatMessage>,
      { auth: Authorization; args: Partial<GetParticipantsArgs> }
    >({
      query: ({ auth, args }) => getChatMessages({ auth, args }),
      transformResponse: (res: ChatMessageListResponse) => {
        return res.results.length > 0
          ? res.results
          : ([] as Array<ChatMessage>);
      },
    }),
    getMessageById: builder.query<
      ChatMessage,
      { auth: Authorization; id: Identification }
    >({
      query: ({ auth, id }) => getChatMessageById({ auth, id }),
    }),
  }),
});

export const {
  useAddMessageMutation,
  useGetChatMessagesQuery,
  useGetMessageByIdQuery,
} = ChatMessageServiceApi;
