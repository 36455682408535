import { FC } from "react";
import styles from "./styles";
import FormContainer from "common/form_container";
import {
  initialValues,
  RESETPWD_FORM_FINAL,
  validationSchema,
} from "./form_data";
import { FormikValues } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid, Paper, Typography } from "@mui/material";
import InputField from "common/input_field";
import SubmitButton from "common/submit_button";
import { useResetPwdMutation } from "store/api/identity_service/endpoints";
import { NavigationRoutes } from "navigation/routes";

const ResetPwd: FC = () => {
  const { state } = useLocation();

  const [reset] = useResetPwdMutation();

  const navigate = useNavigate();

  const handleResetPwd = async (values: FormikValues) => {
    try {
      await reset({
        mobile: state.mobile,
        email: state.email,
        transactionId: state.transactionId,
        newPass: values[RESETPWD_FORM_FINAL.newPass],
        otp: values[RESETPWD_FORM_FINAL.otp],
      });
      navigate(NavigationRoutes.profile);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Paper sx={styles.container}>
      <Typography
        sx={{
          textAlign: "center",
          fontWeight: "700",
          paddingTop: 3,
          fontSize: { lg: 20 },
        }}
      >
        Reset Password
      </Typography>
      <FormContainer
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleResetPwd}
      >
        <Grid container gap={2} paddingBlock={2} justifyContent="center">
          {/* <Grid item lg={5}>
            <InputField
              label="Mobile"
              value={state}
              placeholder="e.g 971523547807"
              name={RESETPWD_FORM_FINAL.mobile}
            />
          </Grid> */}
          <Grid item lg={5}>
            <InputField label="OTP" name={RESETPWD_FORM_FINAL.otp} />
          </Grid>
          <Grid item lg={5}>
            <InputField
              label="New Password"
              type="password"
              name={RESETPWD_FORM_FINAL.newPass}
            />
          </Grid>
          <SubmitButton title="Reset Password" />
        </Grid>
      </FormContainer>
    </Paper>
  );
};

export default ResetPwd;
