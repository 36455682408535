import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  videoContainer: {
    marginBlock: 10,
    marginX: "auto",
    overflow: "hidden",
    borderTopLeftRadius: 100,
    borderTopRightRadius: 100,
    borderBottomLeftRadius: 100,
    width: { xs: 400, md: 700 },
    height: { xs: 300, md: 400 },
  },
};

export default styles;
