import { QueryStatus } from "@reduxjs/toolkit/query";
import { NavigationRoutes } from "navigation/routes";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRefreshTokenMutation } from "store/api/identity_service/endpoints";
import { uploadMembershipAction } from "store/api/membership_service/action_creators";
import { useGetMembershipByIdQuery } from "store/api/membership_service/endpoints";
import { MembershipBody, MembershipResponse } from "store/api/types";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { LivetipsOnlineSliceActions } from "store/livetips_online_slice";
import { UploadedFileStatus } from "types/uploaded_file_status";
import useSnack from "./useSnack";

const useJoinUs = () => {
  const [member, setMember] = useState<MembershipResponse | null>(null);

  const { access_token } = useAppSelector(
    (state) => state.global.credentials.token
  );

  const { speakerProfile } = useAppSelector((state) => state.global);

  const { triggerSnack } = useSnack();

  const navigate = useNavigate();

  const [refresh] = useRefreshTokenMutation();

  const { isSuccess, isLoading, status, data } = useGetMembershipByIdQuery(
    {
      id: speakerProfile.customerId,
      token: access_token,
    },
    { refetchOnMountOrArgChange: true, refetchOnFocus: true }
  );

  useEffect(() => {
    if (!access_token) navigate(NavigationRoutes.login);
    if (status === QueryStatus.rejected) refresh({ token: access_token });
    if (
      isSuccess &&
      data &&
      (data.status == UploadedFileStatus.ACCEPTED ||
        data.status == UploadedFileStatus.PENDING)
    )
      navigate(NavigationRoutes.profile);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [access_token, isSuccess, navigate, data?.status, status, isLoading]);

  const dispatch = useAppDispatch();

  const onSubmit = async (values: MembershipBody) => {
    triggerSnack("Verifying Data Integrity...", "info");
    dispatch(LivetipsOnlineSliceActions.submitSpeaker(values));
    const { error, data } = await uploadMembershipAction({
      token: access_token,
      body: getValues({
        ...values,
        id: speakerProfile.customerId,
        user: speakerProfile.customerId,
      }),
    });
    if (!error && data) setMember(data);
    triggerSnack(
      !error ? "Submitted Successfully" : "Failed to submit data retry again !",
      !error ? "success" : "error"
    );
  };

  const getValues = (body: MembershipBody): MembershipBody => {
    return {
      category: body.category,
      city: body.city,
      country: body.country,
      bio: body.bio,
      user: body.user,
      identity: body.identity,
      article_file: body.article_file,
      status: UploadedFileStatus.PENDING,
      id: body.id,
      article_url: body.article_url,
      gender: body.gender,
    };
  };

  return {
    navigate,
    onSubmit,
    isLoading,
    isSuccess,
    success: data && data.status == UploadedFileStatus.PENDING,
    member,
    data,
  };
};

export default useJoinUs;
