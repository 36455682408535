import { FC, useState } from "react";
import Grow from "@mui/material/Grow";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import styles from "./styles";
import ActionCard from "./action_card";
import { useAppDispatch } from "store/hooks";
import { MODAL_ACTION_TYPE, ProfileSliceActions } from "store/profile_slice";
import addStream from "assets/svgs/add.svg";
import { LivetipsPodcastSliceActions } from "store/livetips_podcast_slice";
import { useIntl } from "react-intl";
import messages from "../messages";

const ProfileActions: FC = () => {
  const [actionsVisible, setVisible] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const handleOnlineTips = () => {
    dispatch(ProfileSliceActions.setActionType(MODAL_ACTION_TYPE.online));
    dispatch(ProfileSliceActions.showGuideModal());
  };

  const handleInteractive = () => {
    dispatch(ProfileSliceActions.setActionType(MODAL_ACTION_TYPE.interactive));
    dispatch(ProfileSliceActions.showGuideModal());
  };

  const handlePodcasts = () => {
    dispatch(ProfileSliceActions.setActionType(MODAL_ACTION_TYPE.podcast));
    dispatch(LivetipsPodcastSliceActions.showGuideModal());
  };

  const intl = useIntl();

  return (
    <Box sx={styles.container}>
      <ActionCard
        onClick={() => setVisible(true)}
        iconPath={addStream}
        label={intl.formatMessage(messages.add_streaming)}
      />
      {actionsVisible && (
        <Grow in={actionsVisible} timeout={700}>
          <Grid
            container
            rowGap={3}
            columnGap={2}
            mt={4}
            justifyContent="center"
          >
            <Grid item>
              <ActionCard
                onClick={handleOnlineTips}
                label={intl.formatMessage(messages.create_online)}
              />
            </Grid>
            <Grid item>
              <ActionCard
                onClick={handleInteractive}
                label="Create Interactive"
              />
            </Grid>
            <Grid item>
              <ActionCard
                onClick={handlePodcasts}
                label={intl.formatMessage(messages.create_podcast)}
              />
            </Grid>
          </Grid>
        </Grow>
      )}
    </Box>
  );
};

export default ProfileActions;
