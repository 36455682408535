import { FC, useEffect } from "react";
import Grid from "@mui/material/Grid";
import styles from "./styles";
import TopBlog from "components/top_blog";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "navigation/routes";
import { useAppDispatch /* useAppSelector */ } from "store/hooks";
/* import { useGetBlogsQuery } from "store/api/blog_service/endpoints"; */
import { blogSliceActions } from "store/blog_slice";
import { blogs as data } from "../data";
import { Blog } from "store/api/blog_service/types";

const BodySection: FC = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const handleClick = (blog: Blog) => {
    dispatch(blogSliceActions.pushCurrentBlog(blog));
    if (blog.id) navigate(`${NavigationRoutes.blog}/${blog.id}`);
  };

  /* const token = useAppSelector(
    (state) => state.global.credentials.token.access_token
  ); */

  /* const { data } = useGetBlogsQuery(
    {
      token,
    },
    { refetchOnFocus: true, refetchOnMountOrArgChange: true }
  ); */

  useEffect(() => {
    dispatch(blogSliceActions.pushBlogList(data ?? []));
  }, []);

  return (
    <Grid container sx={styles.container}>
      {data &&
        data.map((entry) => (
          <Grid key={entry.id} lg={2.3} item>
            <TopBlog
              onClick={() => handleClick(entry)}
              img={entry.main_image}
              title={entry.slug}
            />
          </Grid>
        ))}
    </Grid>
  );
};

export default BodySection;
