import { MEDIA_TYPE } from "types/media_type";
import rootApi from "..";
import { Token } from "../podcast_api/types";
import { GetById } from "../types";
import {
  getComments,
  getProfileComments,
  getUserComments,
  getVideoComments,
  likeComment,
  postComment,
  updateComment,
} from "./action_creators";
import { Comment, CommentPostBody, CommentReaction } from "./types";

type CommentLikeBody = { comment: Comment };

export const commentApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    postComment: builder.mutation<
      Comment,
      { token: string; body: CommentPostBody }
    >({
      query: (body) => postComment(body),
    }),
    updateComment: builder.mutation<Comment, { token: string; body: Comment }>({
      query: (body) => updateComment(body),
    }),
    getComments: builder.query<Array<Comment>, Token>({
      query: (body) => getComments(body),
    }),
    getVideoComments: builder.query<
      Array<Comment>,
      { creds: GetById; type: MEDIA_TYPE }
    >({
      query: (body) => getVideoComments(body),
      transformResponse(res: Array<Comment>) {
        return res.length > 0 ? res : ([] as Array<Comment>);
      },
    }),
    getUserComments: builder.query<Array<Comment>, GetById>({
      query: (body) => getUserComments(body),
      transformResponse(res: Array<Comment>) {
        return res.length > 0 ? res : ([] as Array<Comment>);
      },
    }),
    getProfileComments: builder.query<Array<Comment>, GetById>({
      query: (body) => getProfileComments(body),
      transformResponse(res: Array<Comment>) {
        return res.length > 0 ? res : ([] as Array<Comment>);
      },
    }),
    likeComment: builder.mutation<
      CommentReaction,
      { body: CommentLikeBody; token: string }
    >({
      query: (body) => likeComment(body.body, body.token),
    }),
  }),
});

export const {
  useGetCommentsQuery,
  useUpdateCommentMutation,
  useGetProfileCommentsQuery,
  useGetVideoCommentsQuery,
  useLikeCommentMutation,
  usePostCommentMutation,
  useGetUserCommentsQuery,
} = commentApi;
