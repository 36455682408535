import { defineMessages } from "react-intl";

const scope = "LoginPage";

export default defineMessages({
  loginLabel: {
    id: `${scope}.loginLabel`,
    defaultMessage: "Login",
  },
  views: {
    id: `${scope}.views`,
    defaultMessage: "Views",
  },
  rating: {
    id: `${scope}.rating`,
    defaultMessage: "Rating",
  },
  likes: {
    id: `${scope}.likes`,
    defaultMessage: "Likes",
  },
  share: {
    id: `${scope}.share`,
    defaultMessage: "Shares",
  },
  orLabel: {
    id: `${scope}.orLabel`,
    defaultMessage: "OR",
  },
  forgotpwd: {
    id: `${scope}.forgotpwd`,
    defaultMessage: "Forgot Password ?",
  },
});
