import { FC } from "react";
import { Modal, Paper } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import styles from "./styles";
import { FormikValues, useFormikContext } from "formik";
import { ONLINE_PREMIER_FORM } from "../form";
import dayjs from "dayjs";
import { useAppSelector } from "store/hooks";
import { ROLE } from "types/role";

type Props = {
  showDay: boolean;
  onPick: () => void;
};

const UPCOMING_THRESHOLD_DAYS = 5;

const CalendarModal: FC<Props> = ({ showDay, onPick }) => {
  const { setFieldValue } = useFormikContext<FormikValues>();

  const role = useAppSelector((state) => state.global.speakerProfile.role);

  const handleDayPick = (value: any) => {
    setFieldValue(ONLINE_PREMIER_FORM.DAY, value["$d"]);
    onPick();
  };

  return (
    <Modal sx={styles.container} open={showDay}>
      <Paper>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateCalendar
            minDate={dayjs(
              role == ROLE.normal
                ? new Date(
                    new Date().setDate(
                      new Date().getDate() + UPCOMING_THRESHOLD_DAYS
                    )
                  )
                : new Date()
            )}
            onChange={handleDayPick}
          />
        </LocalizationProvider>
      </Paper>
    </Modal>
  );
};

export default CalendarModal;
