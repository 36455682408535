import { FC } from "react";
import { Box } from "@mui/material";
import styles from "./styles";
import ActionIcon from "components/action_icon";
import { ReactSVG } from "react-svg";
import share from "assets/svgs/share.svg";
import save from "assets/svgs/save.svg";

const FooterSection: FC = () => {
  return (
    <Box sx={styles.footerContainer}>
      <ActionIcon label="Share" Icon={<ReactSVG src={share} />} />
      <ActionIcon label="Save" Icon={<ReactSVG src={save} />} />
    </Box>
  );
};

export default FooterSection;
