import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    width: "60%",
    marginX: "auto",
    marginTop: 3,
  },
};

export default styles;
