import { FC, useEffect } from "react";
import Stack from "@mui/material/Stack";
import styles from "./styles";
import MenuItem from "../menu_item";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { globalSliceActions } from "store/global_slice";
import { MenuItemData, MenuItemsData } from "./data";
import { NavigationRoutes } from "navigation/routes";

const MenuItems: FC = () => {
  const role = useAppSelector((state) => state.global.permission);

  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(globalSliceActions.setActiveMenuItem(""));
    };
  }, [dispatch]);

  const menuItems = (): Array<MenuItemData> => {
    if (role == "normal")
      return MenuItemsData.filter(
        (entry) =>
          entry.item.path !== NavigationRoutes.police_speakers &&
          entry.item.path !== NavigationRoutes.employees
      );
    return MenuItemsData;
  };

  return (
    <Stack direction="column" justifyContent="flex-end" rowGap={1.5}>
      <Stack
        sx={styles.menuItemsWrapper}
        columnGap={{ md: 1, xs: 0 }}
        direction="row"
      >
        {menuItems().map((entry) => (
          <MenuItem
            key={entry.item.label}
            frontLabel={entry.item}
            subItems={entry.subMenu}
          />
        ))}
      </Stack>
    </Stack>
  );
};

export default MenuItems;
