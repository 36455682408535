import rootApi from "store/api";
import { SendOTPBody, TransactionID, VerifyOTPBody } from "./types";
import { sendOTPAc, verifyOTPAc } from "./action_creators";

export const otpServiceApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    sendOTP: builder.mutation<
      TransactionID,
      { token: string; body: SendOTPBody }
    >({
      query: (body) => sendOTPAc(body),
    }),
    verifyOTP: builder.mutation<string, { token: string; body: VerifyOTPBody }>(
      {
        query: (body) => verifyOTPAc(body),
      }
    ),
  }),
});

export const { useSendOTPMutation, useVerifyOTPMutation } = otpServiceApi;
