import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {},
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "80%",
    marginX: "auto",
    columnGap: 5,
  },
  thumbImage: {
    borderRadius: 3,
    width: { xs: 376, lg: 476, xl: 600 },
    aspectRatio: "4 / 4",
    boxShadow: 3,
  },
  viewProfile: {
    borderColor: "black",
    color: "black",
  },
};

export default styles;
