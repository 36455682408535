import { FC, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import styles from "./styles";
import FormContainer from "common/form_container";
import InputField from "common/input_field";
import Button from "components/custom_button";
import { CHAT_FORM, initialValues, validationSchema } from "./form";
import SnackBar from "components/snack_bar";
import { FormikValues } from "formik";
import ChatSubmitButton from "../chat_submit_button";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { chatSliceActions } from "store/chat_slice";
import { useAddMessageMutation } from "store/api/chat/messages/endpoints";
import useSnack from "hooks/useSnack";
import {
  useLocalCameraTrack,
  useLocalMicrophoneTrack,
  useRTCClient,
} from "agora-rtc-react";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "navigation/routes";
import { PostMessageResponse } from "store/api/chat/types";
import { useIntl } from "react-intl";
import messages from "./messages";

type Props = {
  IOsendMessage: (msg: any) => void;
};

const FooterSection: FC<Props> = ({ IOsendMessage }) => {
  const { triggerSnack } = useSnack();

  const agoraEngine = useRTCClient();

  const video = useLocalCameraTrack();
  const audio = useLocalMicrophoneTrack();

  const dispatch = useAppDispatch();

  const [postMessage] = useAddMessageMutation();

  const navigate = useNavigate();

  const {
    speakerProfile,
    credentials: { token },
  } = useAppSelector((state) => state.global);

  const { upcoming } = useAppSelector((state) => state.channel);

  const handleLiveChatSubmit = async (values: FormikValues) => {
    const message = {
      user: speakerProfile.customerId ?? token.userData.username,
      text: values[CHAT_FORM.message],
      conversation: upcoming?.conversation.id,
    };

    try {
      const res = (await postMessage({
        body: message,
        auth: {
          token: token.access_token,
        },
      })) as unknown as { data: PostMessageResponse };

      await IOsendMessage(res.data);

      dispatch(
        chatSliceActions.addMessage({
          text: res.data.text,
          user: res.data.user.customerId,
          conversation: res.data.conversation,
          profilePicture: res.data.user.profilePicture,
          userName: res.data.user.name,
        })
      );
    } catch (error) {
      dispatch(chatSliceActions.removeMessage(message));
      triggerSnack(intl.formatMessage(messages.unable), "error");
    }
  };

  const handleEndSession = async () => {
    await agoraEngine.leave();
    video.localCameraTrack?.close();
    audio.localMicrophoneTrack?.close();
    navigate(NavigationRoutes.profile);
  };

  const handleLeaveSession = () => {
    video.localCameraTrack?.close();
    audio.localMicrophoneTrack?.close();
    navigate(NavigationRoutes.profile);
  };

  useEffect(() => {
    return () => {
      dispatch(chatSliceActions.clearChat());
      if (speakerProfile.customerId == upcoming?.user.customerId) {
        handleEndSession();
      } else {
        handleLeaveSession();
      }
    };
  }, []);

  const intl = useIntl();

  return (
    <Stack direction="column" sx={styles.container}>
      <Stack direction="row" columnGap={2} alignItems="center">
        <FormContainer
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleLiveChatSubmit}
        >
          <InputField
            extraStyles={{ width: "100%" }}
            name={CHAT_FORM.message}
            placeholder={intl.formatMessage(messages.interact_here)}
          />
          <ChatSubmitButton />
        </FormContainer>
      </Stack>
      <Grid
        container
        marginTop={1.5}
        justifyContent="center"
        columnGap={1}
        rowGap={1}
      >
        {upcoming?.user.customerId != speakerProfile.customerId && (
          <Grid item lg={5.8}>
            <Button
              onClick={() => handleLeaveSession()}
              variant="outlined"
              extraStyles={styles.button}
              label={intl.formatMessage(messages.leave_session)}
            />
          </Grid>
        )}
        <SnackBar />
        {upcoming?.user.customerId == speakerProfile.customerId && (
          <Grid item lg={11.8}>
            <Button
              variant="outlined"
              extraStyles={styles.button}
              onClick={() => handleEndSession()}
              label={intl.formatMessage(messages.end_session)}
            />
          </Grid>
        )}
      </Grid>
    </Stack>
  );
};

export default FooterSection;
