import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  root: {
    paddingBlock: 1.5,
    paddingInline: 2,
    width: { lg: 1000 },
    overflow: "hidden",
    marginBlock: 5,
    marginX: "auto",
  },
};

export default styles;
