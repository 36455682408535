import { SxProps } from "@mui/material";
import color_pallette from "theme/color_pallette";

const styles: Record<string, SxProps> = {
  counter: {
    fontSize: {
      xs: 13,
    },
  },
  flex: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  cursor: {
    cursor: "pointer",
  },
  full: {
    color: color_pallette.primary,
    cursor: "pointer",
  },
  full_dislike: {
    color: color_pallette.warning,
    cursor: "pointer",
  },
  empty: {
    opacity: 0.5,
    cursor: "pointer",
  },
};

export default styles;
