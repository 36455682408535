import { SxProps } from "@mui/material";
import color_pallette from "theme/color_pallette";

const styles: Record<string, SxProps> = {
  container: {
    marginTop: 2,
    backgroundColor: color_pallette.backgroundGray,
    borderRadius: 3,
    overflow: "hidden",
    padding: {
      xs: 2,
    },
  },
  labels: {
    marginTop: 10,
  },
  header: {
    fontFamily: "Nizzar ,K2D, Inter",
    color: color_pallette.primary,
    fontWeight: "700",
    fontSize: {
      xs: 20,
    },
  },
  subHeader: {
    fontFamily: "Nizzar ,K2D, Inter",
    fontWeight: "400",
    marginTop: 1,
    fontSize: {
      xs: 14,
    },
  },
};

export default styles;
