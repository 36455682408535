import { FC } from "react";
import Box from "@mui/material/Box";
import styles from "./styles";
import HeroSection from "./hero_section";
import IntroSection from "./intro_section";
import ServiceSection from "./service_section";
import EventSection from "./event_section";
import PartnerSection from "./partner_section";
import VideoIntro from "./video_intro";

const AboutUs: FC = () => {
  return (
    <Box sx={styles.container}>
      <HeroSection />
      <IntroSection />
      <ServiceSection />
      <EventSection />
      <VideoIntro />
      <PartnerSection />
    </Box>
  );
};

export default AboutUs;
