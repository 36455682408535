import { forwardRef, Ref, useCallback, useImperativeHandle, useMemo } from "react";
import styles from "./styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useAppSelector } from "store/hooks";
import withSwiper from "hoc/withSwiper";
import SwipableOnlineItem, {
  SwipableOnlineItemProps,
} from "components/swipable_online_item";
import { useWidth } from "hooks/useWidth";
import useTheme from "@mui/material/styles/useTheme";
import { Breakpoint, Paper, Skeleton } from "@mui/material";
import { useGetLiveStreamingsQuery } from "store/api/streaming_service/endpoints";
import { Upcoming } from "store/api/streaming_service/types";
import { UploadedFileStatus } from "types/uploaded_file_status";

const UpcomingOnlineSection = forwardRef((_, ref: Ref<any>) => {
  useImperativeHandle(ref, () => ({
    getIsEmpty,
    isEmpty: isSuccess && data.length == 0
  }));

  const { access_token } = useAppSelector(
    (state) => state.global.credentials.token
  );

  const { data: upcoming, isLoading, isSuccess } = useGetLiveStreamingsQuery({
    token: access_token,
    query: {
      is_live: false,
      is_expired: false,
      status: UploadedFileStatus.ACCEPTED,
    },
  });

  const theme = useTheme();
  const mdScreen = useMediaQuery(theme.breakpoints.up("md"));

  const br = useWidth();

  const getPaddingInline = (br: Breakpoint) => {
    if (br === "xs" || br === "sm") return 25;
    return 50;
  };

  const swiperProps = useMemo(() => {
    return {
      style: {
        paddingInline: getPaddingInline(br),
        paddingBottom: 10,
        paddingTop: 20,
      },
      slidesPerView: mdScreen ? 1 : 1,
      spaceBetween: mdScreen ? 50 : 80,
      freeMode: true,
      autoplay: true,
      autoFocus: true,
      navigation: true,
      loop: true,
    };
  }, [mdScreen, br]);

  let data: Array<Upcoming> = [];

  if (upcoming && upcoming.results) data = upcoming.results.slice(0, 3);

  const renderSwiper = useCallback(() => {
    if (isLoading || (upcoming?.results?.length || 0) > 0) {
      return withSwiper<SwipableOnlineItemProps, Upcoming>(
        SwipableOnlineItem,
        data
      )({
        swiperProps,
        SkeletonComponent: (
          <Paper sx={{ width: 200, height: 300 }}>
            <Skeleton animation="wave" height={200} width={300} />
          </Paper>
        ),
        componentProps: {
          containerStyles: styles.containerStyle,
          itemData: {} as Upcoming,
        },
      });
    }
    return null
  }, [data, swiperProps]);

  const getIsEmpty = useCallback(() => {
    return isSuccess && data.length == 0
  }, [data, isSuccess])

  if (getIsEmpty()) {
    return null
  }

  return <>{renderSwiper()}</>;
});

export default UpcomingOnlineSection;
