import { object, string } from "yup";

export enum RESETPWD_FORM_FINAL {
  mobile = "mobile",
  newPass = "newPass",
  otp = "otp",
}

const initialValues = {
  [RESETPWD_FORM_FINAL.mobile]: "",
  [RESETPWD_FORM_FINAL.newPass]: "",
  [RESETPWD_FORM_FINAL.otp]: "",
};

const validationSchema = object({
  [RESETPWD_FORM_FINAL.mobile]: string().required(),
  [RESETPWD_FORM_FINAL.newPass]: string().required(),
  [RESETPWD_FORM_FINAL.otp]: string().required(),
});

export { initialValues, validationSchema };
