import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
    height: "fit-content",
    flexDirection: "column",
    width: {
      md: 500,
    },
    columnGap: { xs: 2, md: 2.5 },
    rowGap: { xs: 1.5, md: 2 },
    marginBlock: 4,
    order: {
      md: 1,
    },
  },
};

export default styles;
