import { FC, useEffect, useMemo, useState } from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import styles from "./styles";
import ListenButton from "components/listen_button";
import ReplyRoundedIcon from "@mui/icons-material/ReplyRounded";
import BookmarkBorderRoundedIcon from "@mui/icons-material/BookmarkBorderRounded";
import BookmarkIconFilled from "@mui/icons-material/Bookmark";
import { useAppDispatch, useAppSelector } from "store/hooks";
import Chronometer from "components/chronometer";
import { calculateStartTime } from "utils/calculate_start_time";
import { modalSliceActions } from "store/modal_slice";
import ShareModal from "components/share_modal";
import { useAddBookmarkMutation } from "store/api/bookmark_service/endpoints";
import { MEDIA_TYPE } from "types/media_type";
import { LivetipsPodcastSliceActions } from "store/livetips_podcast_slice";
import { bookmarkSliceActions } from "store/bookmarks_slice";
import SnackBar from "components/snack_bar";
import useSnack from "hooks/useSnack";
import { Identification } from "types/identification";
import { OPTIMISTIC_COMMENT_ID } from "store/comment_slice";
import { Bookmark } from "store/api/bookmark_service/types";
import { FormattedMessage, useIntl } from "react-intl";
import messages from "components/explore_footer_section/messages";
import color_pallette from "theme/color_pallette";

type Props = {
  handleToggleAudio?: () => void;
  playing?: boolean;
  duration?: string;
  podcastId: Identification;
};

const ActionSection: FC<Props> = ({
  duration,
  playing = false,
  podcastId,
  handleToggleAudio,
}) => {
  const [isBookmarked, setIsBookmarked] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const podcasts = useAppSelector((state) => state.bookmarks.podcast);

  useMemo(() => {
    const isBookmarked = podcasts.findIndex(
      (entry) => entry.bookmarkId == podcastId
    );
    setIsBookmarked(isBookmarked !== -1 ? true : false);
  }, [podcastId, podcasts]);

  const { upcoming } = useAppSelector(
    (state) => state.adminProfil.onlineTips.meta
  );

  const { triggerSnack } = useSnack();

  const {
    credentials: { token },
    speakerProfile: user,
  } = useAppSelector((state) => state.global);

  const handleShare = () => {
    dispatch(modalSliceActions.shareVideoStep(true));
  };

  const [bookmark] = useAddBookmarkMutation();

  useEffect(() => {
    return () => {
      dispatch(modalSliceActions.shareVideoStep(false));
    };
  }, [dispatch]);

  const timeLeft = useMemo(() => {
    if (upcoming) {
      /* TODO: this is the new useTime hook  */
      const hoursLeft = calculateStartTime(upcoming.day, upcoming.time);
      const time = new Date();
      time.setSeconds(time.getSeconds() + parseInt(hoursLeft) * 60 * 60);
      return time;
    }
    return 0;
  }, [upcoming]);

  const handleBookmarkPodcast = async () => {
    const body = {
      digitalMediumType: MEDIA_TYPE.ON_DEMAND_PODCAST,
      userId: user.customerId,
      videoId: podcastId,
      bookmarkId: OPTIMISTIC_COMMENT_ID,
    };
    /* optimistic bookmark in bookmarks list */
    dispatch(LivetipsPodcastSliceActions.toggleBookmarkCurrentRecord(true));
    dispatch(bookmarkSliceActions.pushBookmark(body));
    try {
      const res = (await bookmark({
        token: token.access_token,
        body,
      })) as { data: Bookmark };

      triggerSnack("Podcast Saved", "success");

      dispatch(
        bookmarkSliceActions.replaceBookmarkId({
          type: MEDIA_TYPE.ON_DEMAND_PODCAST,
          id: res.data.bookmarkId as string,
        })
      );
    } catch (error) {
      triggerSnack("Unexpected Error Occurred", "error");
      dispatch(LivetipsPodcastSliceActions.toggleBookmarkCurrentRecord(false));
      dispatch(
        bookmarkSliceActions.revertBookmark({
          id: body.videoId,
          type: MEDIA_TYPE.ON_DEMAND_PODCAST,
        })
      );
    }
  };

  const intl = useIntl();

  return (
    <Stack
      direction="row"
      justifyContent={{ lg: "space-between" }}
      width={{ lg: "100%" }}
      columnGap={{ xs: 1.5, lg: 5 }}
      alignItems="center"
    >
      <ShareModal />
      <SnackBar />
      <Stack
        direction="row"
        justifyContent="flex-start"
        columnGap={1}
        alignItems="center"
      >
        {timeLeft === 0 && (
          <ListenButton
            playing={playing ?? false}
            handleClick={() => (handleToggleAudio ? handleToggleAudio() : {})}
          />
        )}
        <Typography sx={{ ...styles.podcastLength, color: "white" }}>
          {playing ? duration : `${intl.formatMessage(messages.playpodcast)}`}
        </Typography>
      </Stack>
      {timeLeft === 0 ? (
        <Stack
          direction="row"
          sx={{ cursor: "pointer" }}
          columnGap={{ xs: 1.5, lg: 1 }}
          alignItems="center"
        >
          <>
            {isBookmarked ? (
              <BookmarkBorderRoundedIcon
                onClick={handleBookmarkPodcast}
                sx={styles.actions}
              />
            ) : (
              <BookmarkIconFilled sx={styles.filledIc} />
            )}
            <Typography
              onClick={handleBookmarkPodcast}
              sx={{ ...styles.actionLabel, color: "white" }}
            >
              {isBookmarked
                ? `${intl.formatMessage(messages.savelist)}`
                : `${intl.formatMessage(messages.saved)}`}
            </Typography>
          </>
          <Stack
            sx={{ cursor: "pointer" }}
            direction="row"
            columnGap={{ xs: 1.5, lg: 1 }}
            alignItems="center"
            onClick={handleShare}
          >
            <ReplyRoundedIcon
              sx={{ ...styles.actions, color: color_pallette.primary }}
            />
            <Typography sx={{ ...styles.actionLabel, color: "white" }}>
              <FormattedMessage id={messages.share.id} />
            </Typography>
          </Stack>
        </Stack>
      ) : (
        <Chronometer expiryTimestamp={timeLeft} />
      )}
    </Stack>
  );
};

export default ActionSection;
