import { FC } from "react";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Grow from "@mui/material/Grow";
import styles from "./styles";
import { useAppSelector } from "store/hooks";
import HeaderSection from "./header_section";
import { UserEditVideoFormNames } from "./form";
import Checkbox from "common/checkbox";
import { FormikValues, useFormikContext } from "formik";
import InputField from "common/input_field";
import MetaSection from "./meta_section";
import SubmitButton from "common/submit_button";
import SnackBar from "components/snack_bar";
import { useGetAllCategoriesQuery } from "store/api/categorie_service/endpoints";
import { UploadPrivacy } from "types/upload_privacy";
import { Typography } from "@mui/material";
import color_pallette from "theme/color_pallette";

const EditVideoModal: FC = () => {
  const showModal = useAppSelector((state) => state.modals.showEditVideo);

  return (
    <Modal open={showModal} sx={styles.container}>
      <Grow in={showModal} timeout={600}>
        <Paper sx={styles.paper}>
          <HeaderSection confirmation={true} />
          <SnackBar />
          <Stack sx={styles.bodyContainer}>
            <MetaSection />
            <Stack sx={styles.formContainer}>
              <InputField
                inputMode="text"
                label="Video Title"
                fullWidth
                sx={styles.bg}
                name={UserEditVideoFormNames.videoTitle}
              />
              <InputField
                inputMode="text"
                label="Description"
                fullWidth
                multiline
                rows={4}
                sx={styles.bg}
                name={UserEditVideoFormNames.description}
              />
              <SelectMenu />
              <Stack direction="row">
                <Checkbox
                  value={UploadPrivacy.DUBAI_POLICE}
                  label={"Dubai Police"}
                  name={UserEditVideoFormNames.exclusivity}
                />
                <Checkbox
                  value={UploadPrivacy.PUBLIC}
                  label={"Public"}
                  name={UserEditVideoFormNames.exclusivity}
                />
              </Stack>
            </Stack>
          </Stack>
          <SubmitButton
            extraStyles={styles.nextButton}
            title="Update Content"
          />
          <Typography sx={{ paddingBottom: 2, fontSize: { lg: 13 } }}>
            As you edit your content , these changes{" "}
            <span style={{ color: color_pallette.primary }}>
              will not reflect immediately
            </span>{" "}
            as they need to go through{" "}
            <span style={{ color: color_pallette.primary }}>approval</span> from{" "}
            <span style={{ color: color_pallette.primary }}>admin</span> first
          </Typography>
        </Paper>
      </Grow>
    </Modal>
  );
};

export default EditVideoModal;

const SelectMenu: FC = () => {
  const token = useAppSelector(
    (state) => state.global.credentials.token.access_token
  );

  const { handleChange, handleBlur, values } = useFormikContext<FormikValues>();

  const { data } = useGetAllCategoriesQuery({
    token,
  });

  return (
    <Select
      name={UserEditVideoFormNames.category}
      onBlur={handleBlur}
      onChange={handleChange}
      value={values[UserEditVideoFormNames.category]}
      defaultValue={1}
      sx={styles.bg}
      fullWidth
    >
      {data &&
        data.length > 0 &&
        data.map((entry) => (
          <MenuItem key={entry.categoryId.toString()} value={entry.categoryId}>
            {entry.nameEn}
          </MenuItem>
        ))}
    </Select>
  );
};
