import { defineMessages } from "react-intl";

const scope = "UpcomingOnlineDetails";

export default defineMessages({
  podcast_saved: {
    id: `${scope}.podcast_saved`,
    defaultMessage: "Podcast Saved",
  },
  viewprofile: {
    id: `${scope}.viewprofile`,
    defaultMessage: "View Profile",
  },
  save: {
    id: `${scope}.save`,
    defaultMessage: "Save In List",
  },
  share: {
    id: `${scope}.share`,
    defaultMessage: "share",
  },
  saved: {
    id: `${scope}.saved`,
    defaultMessage: "Saved",
  },
  play: {
    id: `${scope}.play`,
    defaultMessage: "Play Podcast",
  },
  unexpected: {
    id: `${scope}.unexpected`,
    defaultMessage: "Unexpected Error Occurred",
  },
});
