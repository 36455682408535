import { FC } from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { ReactSVG } from "react-svg";

type Props = {
  label: string;
  iconPath: string;
};

const IconText: FC<Props> = ({ label, iconPath }) => {
  return (
    <Stack direction="row" alignItems="center" columnGap={1}>
      {iconPath && <ReactSVG src={iconPath} />}
      <Typography
        sx={{
          fontWeight: "400",
          fontSize: {
            lg: 12,
          },
          whiteSpace: { md: "nowrap" },
        }}
      >
        {label}
      </Typography>
    </Stack>
  );
};

export default IconText;
