import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Upcoming } from "store/api/streaming_service/types";

type State = {
  expiredData: Array<Upcoming>;
  audio: boolean;
  video: boolean;
};

const initialState: State = {
  expiredData: [],
  audio: true,
  video: true,
};

const LivetipsInteractiveSlice = createSlice({
  name: "livetipsInteractive",
  initialState,
  reducers: {
    setExpiredDate: (
      state: State,
      { payload }: PayloadAction<Array<Upcoming>>
    ) => {
      state.expiredData = payload;
    },
    toggleAudio: (state: State) => {
      state.audio = !state.audio;
      return state;
    },
    toggleVideo: (state: State) => {
      state.audio = !state.video;
      return state;
    },
  },
});

export default LivetipsInteractiveSlice.reducer;
export const LivetipsInteractiveSliceActions = LivetipsInteractiveSlice.actions;
