import { FC } from "react";
import Paper from "@mui/material/Paper";
import Modal from "@mui/material/Modal";
import Grow from "@mui/material/Grow";
import styles from "./styles";
import { useAppDispatch, useAppSelector } from "store/hooks";
import EditModalHeaderSection from "../edit_podcast_modal/header_section";
import FooterSection from "./footer_section";
import BodySection from "./body_section";
import { LivetipsPodcastSliceActions } from "store/livetips_podcast_slice";

const PodcastConfirmationModal: FC = () => {
  const showModal = useAppSelector(
    (state) => state.livetipsPodcast.showConfirmationModal
  );
  const dispatch = useAppDispatch();

  const handleBack = () => {
    dispatch(LivetipsPodcastSliceActions.showEditModal());
  };

  return (
    <Modal open={showModal} sx={styles.container}>
      <Grow in={showModal} timeout={600}>
        <Paper>
          <EditModalHeaderSection onBack={handleBack} confirmation={true} />
          <BodySection />
          <FooterSection />
        </Paper>
      </Grow>
    </Modal>
  );
};

export default PodcastConfirmationModal;
