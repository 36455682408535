import { FC } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import styles from "./styles";
import { ReactSVG } from "react-svg";
import Button from "components/custom_button";
import country from "assets/svgs/location.svg";
import bag from "assets/svgs/travel_bag.svg";
import shares from "assets/svgs/share_filled.svg";
/* import star from "assets/svgs/star.svg"; */
import views from "assets/svgs/view.svg";
import IconText from "pages/view_admin_profile/body_section/icon_text";
/* import ProfileRate from "pages/public_view_profile/profile_rate"; */
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useGetProfileViewCountQuery } from "store/api/view_service/endpoints";
import { FormattedMessage, useIntl } from "react-intl";
import messages from "../messages";
import { modalSliceActions } from "store/modal_slice";
import {
  useAddShareMutation,
  useGetUserSharesCountQuery,
} from "store/api/share_service/endpoints";
import { MEDIA_TYPE } from "types/media_type";
import { usePostNotificationMutation } from "store/api/notification_service/endpoints";
import { NOTIFICATION_REPLY_TYPE } from "store/api/notification_service/types";

const BodySection: FC = () => {
  const { currentMember: speakerProfile } = useAppSelector(
    (state) => state.adminProfil
  );

  const intl = useIntl();

  const dispatch = useAppDispatch();

  const {
    speakerProfile: speaker,
    credentials: { token },
  } = useAppSelector((state) => state.global);

  const { data: viewsCount } = useGetProfileViewCountQuery(
    {
      id: speakerProfile?.user.customerId ?? "",
      token: token.access_token,
    },
    {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
      pollingInterval: 3000,
    }
  );

  const { data: sharesCount } = useGetUserSharesCountQuery(
    {
      id: speakerProfile?.user.customerId ?? "",
      token: token.access_token,
    },
    { refetchOnFocus: true, refetchOnMountOrArgChange: true }
  );

  const [share] = useAddShareMutation();

  const [notify] = usePostNotificationMutation();

  if (speakerProfile != null && speakerProfile.user == null) return null;

  const handleShareProfile = async () => {
    if (speakerProfile && speakerProfile.user == null) return;
    dispatch(modalSliceActions.shareVideoStep(true));
    dispatch(modalSliceActions.setSharedContent(speakerProfile!.identity));
    try {
      await share({
        body: {
          ownerId: speaker.customerId,
          videoId: speakerProfile!.user?.customerId,
          userId: speakerProfile!.user?.customerId,
          digitalMediumType: MEDIA_TYPE.USER_PROFILE,
        },
        token: token.access_token,
      });
    } catch (error) {
      console.log(error);
    }
    try {
      await notify({
        token: token.access_token,
        body: {
          actionType: NOTIFICATION_REPLY_TYPE.SHARE,
          digitalMediumType: MEDIA_TYPE.USER_PROFILE,
          receiverId: speakerProfile!.user.customerId,
          messageEn: `${speaker.name} ${intl.formatMessage(
            messages.shared_your_profile
          )} `,
          triggeredBy: "USER",
          senderId: speaker.customerId,
          senderName: speaker.name,
          senderPic: speaker.profilePicture,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box sx={styles.container}>
      {speakerProfile != null && speakerProfile.user != null && (
        <>
          <Typography sx={styles.headerTitle}>
            {speakerProfile?.user.name}
          </Typography>
          <Typography sx={styles.headerSubTitle}>
            {speakerProfile?.user.org}
          </Typography>
          <Stack sx={styles.statsContainer}>
            <IconText iconPath={bag} label={speakerProfile?.country} />
            <IconText iconPath={country} label={speakerProfile?.city} />
          </Stack>
          <Stack sx={{ paddingInline: 2, marginTop: 1.5 }}>
            <Typography sx={styles.bioHeader}>Bio</Typography>
            {speakerProfile.bio ? (
              <Box sx={styles.bioContentContainer}>
                <Typography sx={styles.bioContent}>
                  {speakerProfile.bio}
                </Typography>
              </Box>
            ) : (
              <Stack direction="row" alignItems="center" sx={styles.emptyBio}>
                <Typography>
                  <FormattedMessage id={messages.no_bio.id} />
                </Typography>
              </Stack>
            )}
          </Stack>
          <Stack sx={styles.stackContainer}>
            <Stack justifyContent="center" direction="row" columnGap={2}>
              <IconText
                iconPath={views}
                label={`${viewsCount} ${intl.formatMessage(messages.views)}`}
              />
              <IconText
                iconPath={shares}
                label={`${sharesCount} ${intl.formatMessage(messages.shares)}`}
              />
              {/* <IconText iconPath={star} label="0 Rating" /> */}
            </Stack>
            <Stack sx={styles.footerContainer}>
              <>
                <ReactSVG style={{ cursor: "pointer" }} src={shares} />
                <Button
                  label={`${intl.formatMessage(messages.share)}`}
                  variant="outlined"
                  onClick={() => handleShareProfile()}
                  extraStyles={styles.shareButton}
                />
              </>
              {/*  <ProfileRate ownerId={speakerProfile.user.customerId} /> */}
            </Stack>
          </Stack>
        </>
      )}
    </Box>
  );
};

export default BodySection;
