import { createSlice } from "@reduxjs/toolkit";
import data from "./data";
import UpcomingPodcast from "types/upcoming_podcast";

type State = Array<UpcomingPodcast>;

const initialState: State = data;

const UpcomingPodcasts = createSlice({
  name: "upcomingPodcasts",
  initialState,
  reducers: {},
});

export default UpcomingPodcasts.reducer;
export const UpcomingPodcastsActions = UpcomingPodcasts.actions;
