import { SxProps } from "@mui/material";
import color_pallette from "theme/color_pallette";

const styles: Record<string, SxProps> = {
  search: {
    height: 40,
    width: "95%",
    borderRadius: 3,
    backgroundColor: color_pallette.backgroundGray,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingInlineStart: 3,
    marginTop: 1,
    opacity: 0.6,
    fontSize: { xl: 20 },
    marginBottom: 5,
  },
  label: {
    opacity: 0.5,
    fontSize: { xs: 20 },
  },
};

export default styles;
