import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    marginTop: 10,
    width: { lg: "80%" },
    justifyContent: "center",
  },
  chronoStyles: { width: 60, height: 60, fontSize: { lg: 32, xl: 35 } },
  labelStyle: { fontFamily: "Nizzar ,K2D, Inter" },
};

export default styles;
