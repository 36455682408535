import { FC, useEffect } from "react";
/* import CommentSection from "./comment_section"; */
import RelatedVideosSection from "./related_videos_section";
import HeaderSection from "./header_section";
import Box from "@mui/material/Box";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
/* import { NavigationRoutes } from "navigation/routes"; */
import {
  useGetVideoByIdQuery,
  useUpdateVideoByIdMutation,
} from "store/api/video_service/endpoints";
import { LivetipsOnlineSliceActions } from "store/livetips_online_slice";
import { useGetVideoViewsDetailsQuery } from "store/api/view_service/endpoints";
import ShareModal from "components/share_modal";
import { modalSliceActions } from "store/modal_slice";
import { useGetMediaBookmarkCountQuery } from "store/api/bookmark_service/endpoints";
import { useGetVideoRateDetailsQuery } from "store/api/rate_service/endpoints";
import { RatingSliceActions } from "store/rate_slice";
import styles from "./styles";
import { MEDIA_TYPE } from "types/media_type";
import EditVideoModal from "components/edit_online_modal";
import FormContainer from "common/form_container";
import {
  initialValues,
  UserEditVideoFormNames,
  validationSchema,
} from "components/edit_online_modal/form";
import { FormikValues } from "formik";
import { UploadedFileStatus } from "types/uploaded_file_status";
import { UpdateVideoBody } from "store/api/types";
/* import CommentListModal from "components/comment_list_modal"; */

const OnlineVideoDetails: FC = () => {
  const params = useParams();

  const navigate = useNavigate();

  const {
    credentials: { token },
    speakerProfile: user,
  } = useAppSelector((state) => state.global);

  const dispatch = useAppDispatch();

  const { data } = useGetVideoByIdQuery(
    {
      id: params.id as string,
      token: token.access_token,
    },
    { refetchOnMountOrArgChange: true, refetchOnFocus: true }
  );

  const { data: viewsDetails } = useGetVideoViewsDetailsQuery(
    {
      id: params.id as string,
      token: token.access_token,
    },
    {
      refetchOnMountOrArgChange: true,
      pollingInterval: 3000,
      refetchOnFocus: true,
    }
  );

  const { data: bookmarksCount } = useGetMediaBookmarkCountQuery(
    {
      id: params.id as string,
      token: token.access_token,
      media: MEDIA_TYPE.ON_DEMAND_VIDEO,
    },
    { refetchOnMountOrArgChange: true, refetchOnFocus: true }
  );

  const { data: totalRatings } = useGetVideoRateDetailsQuery(
    {
      id: params.id as string,
      token: token.access_token,
    },
    { refetchOnMountOrArgChange: true, refetchOnFocus: true }
  );

  useEffect(() => {
    /* if (!token.access_token) navigate(NavigationRoutes.login); */
    if (data) dispatch(LivetipsOnlineSliceActions.submitCurrentVideo(data));
    if (viewsDetails)
      dispatch(
        LivetipsOnlineSliceActions.postVideoViewsDetails({
          data: viewsDetails,
          myId: user.customerId,
        })
      );
    if (bookmarksCount)
      dispatch(LivetipsOnlineSliceActions.submitBookmakrsCount(bookmarksCount));
    if (totalRatings)
      dispatch(RatingSliceActions.submitTotalRatings(totalRatings.length ?? 0));
  }, [
    token.access_token,
    viewsDetails,
    bookmarksCount,
    user.customerId,
    totalRatings,
    dispatch,
    navigate,
    data,
  ]);

  useEffect(() => {
    return () => {
      dispatch(modalSliceActions.setEditVideo(false));
    };
  }, [dispatch]);

  const [updateVideo] = useUpdateVideoByIdMutation();

  const handleUpdateVideo = async (values: FormikValues) => {
    if (!data) return;

    try {
      const body: Partial<UpdateVideoBody> = {
        user: user.customerId,
        category: values[UserEditVideoFormNames.category]
          ? values[UserEditVideoFormNames.category]
          : data.category.code,
        title: values[UserEditVideoFormNames.videoTitle],
        description: values[UserEditVideoFormNames.description],
        privacy: values[UserEditVideoFormNames.exclusivity],
        status: UploadedFileStatus.PENDING,
        id: data.id,
      };

      await updateVideo({
        body,
        id: data.id,
        token: token.access_token,
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Box sx={styles.container}>
        <HeaderSection data={data} />
        {/* {data && <CommentSection />} */}
        <ShareModal />
        <FormContainer
          initialValues={{
            ...initialValues,
            ...data,
            category: data?.category.code,
          }}
          validationSchema={validationSchema}
          onSubmit={handleUpdateVideo}
        >
          <EditVideoModal />
        </FormContainer>
        {/* <CommentListModal /> */}
        <RelatedVideosSection />
      </Box>
    </>
  );
};

export default OnlineVideoDetails;
