import { defineMessages } from "react-intl";

const scope = "RegisterPage";

export default defineMessages({
  signup: {
    id: `${scope}.signup`,
    defaultMessage: "Sign up",
  },
  country: {
    id: `${scope}.country`,
    defaultMessage: "Country",
  },
  username_placeholder: {
    id: `${scope}.username_placeholder`,
    defaultMessage: "e.g Rashid Khalid",
  },
  email_placeholder: {
    id: `${scope}.email_placeholder`,
    defaultMessage: "e.g rashid@example.com",
  },
  explore_dp: {
    id: `${scope}.explore_dp`,
    defaultMessage: "Explore Dubai Police",
  },
  listen: {
    id: `${scope}.listen`,
    defaultMessage: "Listen",
  },
  countryCode: {
    id: `${scope}.countryCode`,
    defaultMessage: "Country Code",
  },
  phone: {
    id: `${scope}.phone`,
    defaultMessage: "Phone Number",
  },
  chooseCountryCode: {
    id: `${scope}.chooseCountryCode`,
    defaultMessage: "Choose Country Code",
  },
  or: {
    id: `${scope}.or`,
    defaultMessage: "Or",
  },
  email: {
    id: `${scope}.email`,
    defaultMessage: "Email",
  },
  fullname: {
    id: `${scope}.fullname`,
    defaultMessage: "Full name",
  },
});
