import { FC } from "react";
import Stack from "@mui/material/Stack";
import Grow from "@mui/material/Grow";
import styles from "./styles";
import MenuSection from "../menu_section";
import IconSection from "../icon_section";

const BodySection: FC = () => {
  return (
    <Grow in timeout={700}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        sx={styles.container}
      >
        <MenuSection />
        <IconSection />
      </Stack>
    </Grow>
  );
};

export default BodySection;
