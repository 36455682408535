import { FC, useMemo } from "react";
import { Button, ButtonProps, SxProps } from "@mui/material";
import styles from "./styles";
import { FormikValues, useFormikContext } from "formik";

type Props = {
  title: string;
  extraStyles?: SxProps<ButtonProps>;
};

const SubmitButton: FC<Props> = ({ title, extraStyles }) => {
  const { handleSubmit } = useFormikContext<FormikValues>();

  const containerStyles = useMemo(() => {
    return { ...styles.button, ...extraStyles };
  }, [extraStyles]);

  return (
    <Button
      onClick={() => handleSubmit()}
      sx={containerStyles}
      variant="contained"
      data-testid="submit-button-testid"
    >
      {title}
    </Button>
  );
};

export default SubmitButton;
