import { Methods } from "types/http";
import apiUrls from "../urls";
import { PreResetPwd, ResetPwd, VerifyToken } from "../types";
import generateFormData from "utils/to_form_data";
import axios from "axios";
import { baseUrl } from "..";
import { Identification } from "types/identification";
import { LoginValidationReturnType } from "utils/validate_login";

export const generateTokenAction = (body: LoginValidationReturnType) => ({
  url: apiUrls.generateToken(),
  method: Methods.POST,
  body,
  headers: {
    Lng: "EN",
    "Content-type": "application/json",
    Accept: "*/*",
  },
});

export const refreshTokenAction = (body: VerifyToken) => ({
  url: apiUrls.refreshToken(),
  method: Methods.POST,
  body,
  headers: {
    Lng: "EN",
    "Content-type": "application/json",
    Accept: "*/*",
  },
});

export const verifyTokenAction = (body: VerifyToken) => ({
  url: apiUrls.verifyToken(),
  method: Methods.POST,
  body,
  headers: {
    Lng: "EN",
    "Content-type": "application/json",
    Accept: "*/*",
  },
});

export const resetPwdAction = (body: ResetPwd) => ({
  url: apiUrls.resetPwd(),
  method: Methods.POST,
  body,
  headers: {
    Lng: "EN",
    "Content-type": "application/json",
    Accept: "*/*",
  },
});

export const preResetPwdAction = (body: PreResetPwd) => ({
  url: apiUrls.preResetPwd(),
  method: Methods.POST,
  body,
  headers: {
    Lng: "EN",
    "Content-type": "application/json",
    Accept: "*/*",
  },
});

export const getRolesAction = () => ({
  url: apiUrls.getRoles(),
  method: Methods.GET,
  headers: {
    Lng: "EN",
    "Content-type": "application/json",
    Accept: "*/*",
  },
});

type Param = {
  body: object;
  token: string;
  id: Identification;
};

export const updateProfileAC = async ({ body, token }: Param) => {
  if (!body) return null;

  const formData = generateFormData(body);

  const res = await axios.put(apiUrls.updateUser(), formData, {
    baseURL: baseUrl,
    headers: {
      Accept: "*/*",
      "Content-Type": "multipart/form-data",
      lng: "EN",
      Authorization: `Bearer ${token}`,
    },
  });
  return res;
};