import { FC, useState } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import styles from "./styles";
import { ONLINE_PREMIER_FORM } from "../form";
import InputField from "common/input_field";
import CalendarModal from "../calendar_modal";
import TimerModal from "../timer_modal";
import { Slider } from "@mui/material";
import { FormikValues, useFormikContext } from "formik";
import { UPCOMING_DURATION } from "types/upcoming_duration";

const FormSection: FC = () => {
  const [showDay, setShowDay] = useState<boolean>(false);
  const [showTime, setShowTime] = useState<boolean>(false);

  const { values, handleChange } = useFormikContext<FormikValues>();

  const handleCloseCalendarModal = () => {
    setShowDay(false);
  };

  const handleCloseTimerModal = () => {
    setShowTime(false);
  };

  return (
    <Box sx={styles.container}>
      <Stack direction="column" alignItems="center" width="100%" rowGap={1.5}>
        <Box sx={styles.inputWrapper} onClick={() => setShowDay(true)}>
          <InputField
            extraStyles={{ width: "100%" }}
            placeholder="Podcast Date "
            name={ONLINE_PREMIER_FORM.DAY}
          />
        </Box>
        <Box sx={styles.inputWrapper} onClick={() => setShowTime(true)}>
          <InputField
            extraStyles={{ width: "100%" }}
            placeholder="Podcast Hour"
            name={ONLINE_PREMIER_FORM.TIME}
          />
        </Box>
        <Box sx={styles.inputWrapper} onClick={() => setShowTime(true)}>
          <Slider
            name={ONLINE_PREMIER_FORM.DURATION}
            value={values[ONLINE_PREMIER_FORM.DURATION]}
            onChange={handleChange}
            step={100}
            marks={[
              { value: 3600, label: UPCOMING_DURATION.ONE_HOUR },
              { value: 7200, label: UPCOMING_DURATION.TWO_HOURS },
            ]}
            valueLabelDisplay="auto"
          />
        </Box>
      </Stack>
      <CalendarModal
        onPick={() => handleCloseCalendarModal()}
        showDay={showDay}
      />
      <TimerModal onPick={() => handleCloseTimerModal()} showTime={showTime} />
    </Box>
  );
};

export default FormSection;
