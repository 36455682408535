import { FC } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import styles from "./styles";
import color_pallette from "theme/color_pallette";
import OnlineWrapper from "components/online_wrapper";
import { UploadPrivacy } from "types/upload_privacy";
import { UploadedFileStatus } from "types/uploaded_file_status";
import { FormattedMessage } from "react-intl";
import messages from "../messages";

const RelatedVideos: FC = () => {
  return (
    <Box sx={styles.container}>
      <Typography sx={styles.header}>
        <FormattedMessage id={messages.streaming_related.id} />{" "}
        <span style={{ color: color_pallette.primary }}>
          <FormattedMessage id={messages.streaming_videos.id} />
        </span>
      </Typography>
      <Box sx={styles.relatedWrapper}>
        <OnlineWrapper
          paginationName={"related_videos_2"}
          listLabel=""
          icon={"online"}
          limit={[0, 6]}
          skeletons={{
            lg: 3.5,
          }}
          perPage={3}
          theme="black"
          dimensions={{ lg: 4 }}
          videoQuery={{
            privacy: UploadPrivacy.PUBLIC,
            status: UploadedFileStatus.ACCEPTED,
          }}
        />
      </Box>
    </Box>
  );
};

export default RelatedVideos;
