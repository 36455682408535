import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    paddingBottom: 10,
    width: "100%",
  },
  heroImg: {
    order: { md: 2 },
    width: "100%",
    height: 400,
    flexGrow: 1,
  },
  herolabel: {
    flexGrow: { xl: 0.3 },
    paddingInlineStart: { md: 6, lg: 10, xl: 20 },
    fontSize: {
      xs: 30,
      lg: 40,
    },
    width: { xs: "80%", md: "40%", xl: "30%" },
    marginTop: { xs: 1.5, xl: 0 },
    textAlign: "center",
    order: {
      md: 1,
    },
  },
};

export default styles;
