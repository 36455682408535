import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    width: "100%",
    height: {
      xs: "95%",
      sm: "95%",
      md: "80%",
    },
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 20,
  },
  center: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 1,
  },
  orLabel: { marginX: 5, opacity: 0.6, textAlign: "center" },
  cardStyle: {
    height: {
      sm: "100%",
      md: "80%",
    },
    width: {
      xs: "100%",
      sm: "80%",
      md: "60%",
      lg: "50%",
    },
    backgroundColor: "#F3F3F3",
    marginTop: 5,
  },
  font: {
    fontWeight: "600",
    textAlign: "center",
    fontSize: 25,
  },
  orSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingY: 3,
  },
};

export default styles;
