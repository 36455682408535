import { defineMessages } from 'react-intl';

const scope = 'SearchPage';

export default defineMessages({
  searchby: {
    id: `${scope}.searchby`,
    defaultMessage: "Search by",
  },
  searchtypes: {
    id: `${scope}.searchtypes`,
    defaultMessage: "Search Types",
  },
  no_results: {
    id: `${scope}.no_results`,
    defaultMessage: "0 Results were found",
  },
  results: {
    id: `${scope}.results`,
    defaultMessage: "Result for",
  },
  searchLimits: {
    id: `${scope}.searchLimits`,
    defaultMessage: "Search Limit",
  },
  searchKeywords: {
    id: `${scope}.searchKeywords`,
    defaultMessage: "search by keywords..",
  },
});