import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {},
  header: {
    fontSize: { xs: 30 },
    fontFamily: "Nizzar ,K2D, Inter",
    fontWeight: "400",
    marginBottom: 2,
  },
};

export default styles;