import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {},
  root: { background: "#008755", mt: 30, position: "relative" },
  elevatedBox: {
    width: { sm: "55%", md: "40%" },
    margin: "auto",
    borderRadius: "13px",
    color: "#fff",
    padding: "30px 46px",
    textAlign: "center",
    position: "absolute",
    top: { md: -160 },
    left: 0,
    right: 0,
  },
  queries: {
    fontSize: "18px",
    fontWeight: 700,
    color: "white",
  },
  copyrights: {
    fontSize: "20px",
    fontWeight: 400,
    color: "white",
  },
  button: {
    width: "113px",
    height: "52px",
    fontSize: "16px",
    color: "#fff",
    outline: "none",
    borderRadius: "0",
  },
  footerWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "baseline",
  },
  colorWhite: { color: "white" },
  gridWrapper1: {
    display: "flex",
    flexDirection: "column",
    height: 200,
    paddingInlineStart: 4,
    justifyContent: "space-evenly",
  },
  gridWrapper: {
    width: "85%",
    margin: "auto",
    padding: "162px 115px 60px 115px",
  },
  boxDesc: {
    fontSize: "14px",
    fontWeight: 500,
    color: "#fff",
  },
  boxLabel: {
    fontSize: "25px",
    fontWeight: 500,
    color: "#fff",
    marginBottom: "16px",
  },
};

export default styles;