import { defineMessages } from 'react-intl';

const scope = 'SidebarSection';

export default defineMessages({
  insights: {
    id: `${scope}.insights`,
    defaultMessage: "Insights",
  },
  joining_list: {
    id: `${scope}.joining_list`,
    defaultMessage: "My Joining List",
  },
  editprofile: {
    id: `${scope}.editprofile`,
    defaultMessage: "Edit Profile",
  },
  help: {
    id: `${scope}.help`,
    defaultMessage: "Help",
  },
  logout: {
    id: `${scope}.logout`,
    defaultMessage: "Logout",
  },
  language: {
    id: `${scope}.language`,
    defaultMessage: "Language",
  },
  discussions: {
    id: `${scope}.discussions`,
    defaultMessage: "Discussions",
  },
  streams: {
    id: `${scope}.streams`,
    defaultMessage: "My Streams",
  },
  notification: {
    id: `${scope}.notification`,
    defaultMessage: "Notifications",
  },
});