import { FC } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import styles from "./styles";
import FormContainer from "common/form_container";
import InputField from "common/input_field";
import SelectInputField from "common/select_input_field";
import { countryList } from "./data";
import SubmitButton from "common/submit_button";
import color_pallette from "theme/color_pallette";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "navigation/routes";
import { useRegisterUserMutation } from "store/api/auth_service/endpoints";
import { RegisterResponse, Request } from "store/api/types";
import {
  validationSchema,
  initialValues,
  RegisterFormNames,
} from "./form_data";
import { useAppDispatch } from "store/hooks";
import { globalSliceActions } from "store/global_slice";
import { CountryType } from "utils/data";
import { FormattedMessage, useIntl } from "react-intl";
import messages from "../messages";
import messageslogin from "./messages";
import { FormikValues } from "formik";
/* import ConcentSection from "../concent_section"; */
import PrivacyPolicy from "components/privacy_policy";
import ConcentSection from "../concent_section";
import { ROLE } from "types/role";
import SnackBar from "components/snack_bar";
import useSnack from "hooks/useSnack";
import { verificationSliceActions } from "store/verification_slice";

const RegisterForm: FC = () => {
  const navigate = useNavigate();

  const [register] = useRegisterUserMutation();

  const { triggerSnack } = useSnack();

  const dispatch = useAppDispatch();

  const handleSubmit = async (values: FormikValues) => {
    if (!values[RegisterFormNames.concent]) return;

    const request: Request = {
      mobile: `${values[RegisterFormNames.countryCode]}${
        values[RegisterFormNames.mobile]
      }`,
      country: values[RegisterFormNames.country],
      nationality: values[RegisterFormNames.nationality],
      email: values[RegisterFormNames.email],
      name: values[RegisterFormNames.username],
      org: values[RegisterFormNames.university],
      passWord: values[RegisterFormNames.password],
      role: ROLE.normal,
    };

    const { data } = (await register(request)) as { data: RegisterResponse };

    if (data) {
      dispatch(globalSliceActions.submitUserTransactionId(data.transactionId));
      dispatch(globalSliceActions.setSpeakerProfile(data));
      dispatch(globalSliceActions.submitValidationData(data));
      dispatch(globalSliceActions.setToken(data.accessToken));

      dispatch(verificationSliceActions.setEmail(data.email));
      dispatch(verificationSliceActions.setPath(NavigationRoutes.profile));
      dispatch(verificationSliceActions.setPhone(data.accessToken));
      dispatch(verificationSliceActions.setVerifyOTP(false));
      dispatch(verificationSliceActions.setSendOTP(false));
      dispatch(verificationSliceActions.setIsRegistrationStep(true));

      dispatch(
        verificationSliceActions.setNavigationProps({
          state: {
            transactionId: data.transactionId,
            email: data.email,
            mobile: data.mobile,
            newUser: true,
          },
        })
      );

      triggerSnack(
        "15 days left to go through Multifactor Authentication",
        "info"
      );

      navigate(NavigationRoutes.otp_confirmation, {
        state: {
          transactionId: data.transactionId,
          email: data.email,
          /* mobile: data.mobile, */
        },
      });
    }
  };

  const handleLogin = () => {
    navigate(NavigationRoutes.login);
  };

  const mobileCodeLists = () => {
    const codes = countryList.map((entry) => {
      return {
        name: entry.mobileCode,
      };
    });
    return codes;
  };

  const intl = useIntl();

  return (
    <Box sx={styles.container}>
      <FormContainer
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <SnackBar />
        <Grid justifyContent="center" container rowGap={2} columnGap={1.5}>
          <Grid item xs={12} md={5.5}>
            <InputField
              inputMode="text"
              label={intl.formatMessage(messages.fullname)}
              placeholder={intl.formatMessage(messages.username_placeholder)}
              name={RegisterFormNames.username}
            />
          </Grid>
          <Grid item xs={12} md={5.5}>
            <InputField
              inputMode="email"
              placeholder={intl.formatMessage(messages.email_placeholder)}
              label={intl.formatMessage(messages.email)}
              name={RegisterFormNames.email}
            />
          </Grid>

          <Grid item xs={12} md={5.5}>
            <Stack columnGap={1} direction="row" alignItems="center">
              <SelectInputField
                label={intl.formatMessage(messages.countryCode)}
                placeholder={intl.formatMessage(messages.chooseCountryCode)}
                name={RegisterFormNames.countryCode}
                dataList={mobileCodeLists() as CountryType[]}
              />
              <InputField
                inputMode="numeric"
                label={intl.formatMessage(messages.phone)}
                placeholder="581196897"
                name={RegisterFormNames.mobile}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} md={5.5}>
            <SelectInputField
              label={intl.formatMessage(messages.country)}
              inputMode="search"
              placeholder="United Arab Emirates"
              name={RegisterFormNames.country}
              dataList={countryList}
            />
          </Grid>

          <Grid item xs={12} md={5.5}>
            <InputField
              inputMode="text"
              label={intl.formatMessage(messageslogin.univ)}
              placeholder="Univerisity"
              name={RegisterFormNames.university}
            />
          </Grid>
          <Grid item xs={12} md={5.5}>
            <SelectInputField
              label={intl.formatMessage(messageslogin.nationality)}
              inputMode="search"
              placeholder="Nationality"
              name={RegisterFormNames.nationality}
              dataList={countryList}
            />
          </Grid>

          <Grid item xs={12} md={5.5}>
            <InputField
              inputMode="text"
              label={intl.formatMessage(messageslogin.password)}
              type="password"
              placeholder={intl.formatMessage(
                messageslogin.passwordPlaceholder
              )}
              name={RegisterFormNames.password}
            />
          </Grid>
        </Grid>
        <Stack
          alignItems="center"
          direction="column"
          sx={styles.buttonContainer}
        >
          <SubmitButton
            extraStyles={styles.button}
            title={intl.formatMessage(messageslogin.register)}
          />
          <Typography onClick={() => handleLogin()} sx={styles.loginLabel}>
            <FormattedMessage id={messageslogin.have_account_already.id} />
            <span
              style={{
                color: color_pallette.primary,
                cursor: "pointer",
              }}
            >
              <FormattedMessage id={messageslogin.login.id} />
            </span>
          </Typography>
        </Stack>
        <Stack
          sx={{ marginTop: 1 }}
          direction="row"
          alignItems="center"
          justifyItems="flex-start"
        >
          <ConcentSection />
          <PrivacyPolicy />
        </Stack>
      </FormContainer>
    </Box>
  );
};

export default RegisterForm;