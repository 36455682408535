import { FC } from "react";
import OnlineWrapper from "components/online_wrapper";
import { UploadedFileStatus } from "types/uploaded_file_status";
import { UploadPrivacy } from "types/upload_privacy";
import { Stack } from "@mui/material";

type Props = {
  category: number;
  sectionHeader: string;
};

const SectionByCategory: FC<Props> = ({ category, sectionHeader }) => {
  return (
    <>
      <OnlineWrapper
        listLabel={sectionHeader}
        videoQuery={{
          category,
          page: 1,
          status: UploadedFileStatus.ACCEPTED,
          privacy: UploadPrivacy.PUBLIC,
        }}
        perPage={6}
        skeletons={{
          xs: 12,
          sm: 6,
          md: 9,
          lg: 4.5,
          xl: 4,
        }}
        dimensions={{
          xs: 12,
          sm: 6,
          md: 9,
          lg: 3.8,
          xl: 4,
        }}
      />
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        width="100%"
        marginBlock={2}
      ></Stack>
    </>
  );
};

export default SectionByCategory;
