import rootApi from "store/api";
import { getNotifications, postNotification } from "./action_creators";
import { NotificationType } from "./types";

export const NotificationApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllNotifications: builder.query<
      Array<NotificationType>,
      { token: string }
    >({
      query: (body) => getNotifications(body),
    }),
    postNotification: builder.mutation<
      string,
      { token: string; body: NotificationType }
    >({
      query: (body) => postNotification(body),
    }),
  }),
});

export const { useGetAllNotificationsQuery, usePostNotificationMutation } =
  NotificationApi;
