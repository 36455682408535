import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  iconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    columnGap: { xs: 1, md: 1.5 },
    marginInlineStart: { lg: 2 },
  },
  shareButton: {
    color: "black",
    borderRadius: 10,
    fontSize: {
      lg: 12,
    },
    ":hover": {
      backgroundColor: "white",
    },
  },
};

export default styles;
