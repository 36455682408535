import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    marginX: "auto",
    textAlign: "center",
    paddingBottom: 2,
    marginTop: {
      xs: 2.5,
      lg: 0.5,
    },
    width: {
      xs: "90%",
      sm: "85%",
      md: 800,
      lg: 800,
    },
  },
  profilePic: {
    position: "absolute",
    top: "25%",
    left: "40%",
    borderRadius: 3,
  },
  paper: {
    borderRadius: 3,
    paddingInline: 3,
    position: "relative",
    paddingBlock: 2,
  },
  flexEnd: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    cursor: "pointer",
  },
};

export default styles;
