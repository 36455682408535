import { FC } from "react";
import Box from "@mui/material/Box";
import styles from "./styles";
import { limits, types } from "./data";
import FormContainer from "common/form_container";
import InputField from "common/input_field";
import { SEARCH_FORM, initialValues, validationSchema } from "./form";
import { Stack, Typography } from "@mui/material";
import SelectInputField from "common/select_input_field";
import FormResultSection from "./form_result_section";
import { FormattedMessage, useIntl } from "react-intl";
import messages from "./messages";
/* import { FormikValues } from "formik"; */

const SearchPage: FC = () => {
  const handleSubmit = (/* values: FormikValues */) => {};

  const intl = useIntl();

  return (
    <Box sx={styles.container}>
      <FormContainer
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Typography sx={styles.header}>
          <FormattedMessage id={messages.searchby.id} />
        </Typography>
        <Stack
          columnGap={2}
          justifyContent="center"
          marginTop={2}
          direction="row"
          alignItems="center"
        >
          <SelectInputField
            label={intl.formatMessage(messages.searchtypes)}
            inputMode="search"
            name={SEARCH_FORM.type}
            dataList={types}
          />
          <InputField
            name={SEARCH_FORM.q}
            placeholder={intl.formatMessage(messages.searchKeywords)}
          />
          <SelectInputField
            label={intl.formatMessage(messages.searchLimits)}
            inputMode="search"
            name={SEARCH_FORM.limit}
            dataList={limits}
          />
        </Stack>
        <FormResultSection />
      </FormContainer>
    </Box>
  );
};

export default SearchPage;
