import { FC } from "react";
import { Bookmark } from "store/api/bookmark_service/types";
import { useAppSelector } from "store/hooks";
import { useGetPodcastByIdQuery } from "store/api/podcast_api/endpoints";
import PodcastCardListItem from "components/podcast_card_list_item";

const PodcastItemBookmarkWrapper: FC<Bookmark> = ({ videoId }) => {
  const { access_token } = useAppSelector(
    (state) => state.global.credentials.token
  );

  const { data: podcastDetails, isLoading } = useGetPodcastByIdQuery({
    id: videoId as string,
    token: access_token,
  });

  if (!videoId) return null;

  return (
    <>
      {!isLoading && podcastDetails && (
        <PodcastCardListItem {...podcastDetails} />
      )}
    </>
  );
};

export default PodcastItemBookmarkWrapper;
