import { FC } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import styles from "./styles";
import { ReactSVG } from "react-svg";
import { useAppDispatch } from "store/hooks";
import playvideo from "assets/svgs/play_video.svg";
import statusFull from "assets/svgs/video_status_full.svg";
import statusempty from "assets/svgs/video_status_empty.svg";
import cancelIc from "assets/svgs/cancel.svg";
import { modalSliceActions } from "store/modal_slice";

type Props = {
  confirmation: boolean;
};

const HeaderSection: FC<Props> = ({ confirmation = false }) => {
  const dispatch = useAppDispatch();

  const handleCloseModal = () => {
    dispatch(modalSliceActions.setEditVideo(false));
  };

  return (
    <Box sx={styles.container}>
      <Stack
        paddingInline={2}
        paddingBlock={1.5}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box sx={styles.videoInfo}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
          >
            <ReactSVG src={playvideo} />
            <Typography sx={styles.videoName}>
              Live tips Online Session
            </Typography>
          </Stack>
        </Box>
        <ReactSVG
          style={{ cursor: "pointer" }}
          onClick={() => handleCloseModal()}
          src={cancelIc}
        />
      </Stack>
      <Stack
        sx={styles.videoStatusContainer}
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <ReactSVG src={statusFull} />
        <Typography sx={{ marginInline: 0.6 }}>Details</Typography>
        <Divider sx={styles.divider} orientation="horizontal" />
        <ReactSVG src={confirmation ? statusFull : statusempty} />
        <Typography sx={{ marginInline: 0.6 }}>Final Preview</Typography>
      </Stack>
    </Box>
  );
};

export default HeaderSection;
