import { ChangeEvent, FC, useRef, useState } from "react";
import { useAppSelector } from "store/hooks";
import Box from "@mui/material/Box";
import styles from "./styles";
import SnackBar from "components/snack_bar";
import { useFormikContext } from "formik";
import thumbNail from "assets/images/placeholders/profile_pic_pl.webp";
import { EditProfileFormNames } from "../form";

const ProfileImageSection: FC = () => {
  const [cover, setCover] = useState<string>("");

  const { speakerProfile } = useAppSelector((state) => state.global);

  const profileInputRef = useRef<HTMLInputElement>(null);

  const handleUploadProfile = () => {
    profileInputRef.current?.click();
  };

  const { setFieldValue } = useFormikContext();

  const handleChangeUploadProfile = async (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const uploadedFile = e.target.files?.item(0);
    setFieldValue(EditProfileFormNames.profilePicture, uploadedFile);
    try {
      handleImageUpload(uploadedFile);
    } catch (error) {
      console.log(error);
    }
  };

  const handleImageUpload = (file: any) => {
    if (file) {
      setCover(URL.createObjectURL(file));
    }
  };

  return (
    <>
      <Box
        component="img"
        src={
          cover
            ? cover
            : speakerProfile.profilePicture
            ? speakerProfile.profilePicture
            : thumbNail
        }
        sx={styles.profilePic}
        alt="Profile Picture"
        width={170}
        height={170}
        onClick={handleUploadProfile}
      />
      <SnackBar />
      <input
        style={{ display: "none" }}
        ref={profileInputRef}
        accept="image/*"
        type="file"
        onChange={handleChangeUploadProfile}
      />
    </>
  );
};

export default ProfileImageSection;
