import { FC } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import styles from "./styles";
import sideBarMenuItems from "./data";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { ProfileSliceActions } from "store/profile_slice";
import SideBarItem from "./side_bar_item";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "navigation/routes";
import { useIntl } from "react-intl";
import messages from "../messages";
import { verificationSliceActions } from "store/verification_slice";
import { transactionSliceActions } from "store/transaction_slice";

const SidebarSection: FC = () => {
  const {
    credentials: { transactionId },
  } = useAppSelector((state) => state.global);

  const { verifyOTP, sendOTP } = useAppSelector((state) => state.otp);

  const speakerProfile = useAppSelector((state) => state.global.speakerProfile);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const handleEditProfile = () => {
    if (!verifyOTP || sendOTP) {
      dispatch(ProfileSliceActions.showEditProfileModal());
      dispatch(verificationSliceActions.setSendOTP(false));
      return;
    } else {
      dispatch(verificationSliceActions.setPath(NavigationRoutes.profile));
      dispatch(verificationSliceActions.setEmail(speakerProfile.email));
      dispatch(verificationSliceActions.setPhone(speakerProfile.mobile));
      dispatch(transactionSliceActions.setCurrentTransactionId(transactionId));
      dispatch(verificationSliceActions.setSendOTP(true));
      dispatch(verificationSliceActions.setVerifyOTP(false));

      dispatch(
        verificationSliceActions.setNavigationProps({
          state: {
            editProfile: true,
            noOTP: false,
            email: speakerProfile.email,
            mobile: speakerProfile.mobile,
            transactionId,
          },
        })
      );

      navigate(NavigationRoutes.otp_confirmation, {
        state: {
          mobile: speakerProfile.mobile,
          email: speakerProfile.email,
          transactionId,
        },
      });

    }
  };

  const handleJoinus = () => {
    navigate(NavigationRoutes.joinUs);
  };

  const handlePublicProfile = () => {
    navigate(
      `${NavigationRoutes.public_speaker_profile}/${speakerProfile.customerId}`
    );
  };

  const handleViewAlert = () => {
    navigate(`${NavigationRoutes.alerts}`);
  };

  const intl = useIntl();

  return (
    <Paper sx={styles.container}>
      <Typography sx={styles.headerTitle}>
        Live tips id:
        <span
          style={{
            marginInlineStart: 3,
            fontWeight: "700",
          }}
        >
          {speakerProfile.customerId}
        </span>
      </Typography>
      <Stack sx={styles.menu}>
        <Box sx={{ marginBlock: 0.8, cursor: "pointer" }}>
          <SideBarItem
            onClick={() => handlePublicProfile()}
            label={intl.formatMessage(messages.view_profile)}
          />
        </Box>
        <SideBarItem
          onClick={() => handleViewAlert()}
          label={intl.formatMessage(messages.alerts)}
        />
        {!speakerProfile && (
          <Box sx={{ marginBlock: 0.8, cursor: "pointer" }}>
            <SideBarItem
              onClick={() => handleJoinus()}
              label={intl.formatMessage(messages.join_speakers)}
            />
          </Box>
        )}
        <Divider sx={styles.divider} />
        {sideBarMenuItems.map((entry) => (
          <Box sx={{ marginBlock: 0.8, cursor: "pointer" }} key={entry.id}>
            <SideBarItem onClick={entry.action && handleEditProfile} {...entry}>
              {entry.badge}
            </SideBarItem>
            {entry.class && <Divider sx={styles.divider} />}
          </Box>
        ))}
      </Stack>
    </Paper>
  );
};

export default SidebarSection;
