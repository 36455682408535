export type QueryParams = {
  [key: string]: string | number | boolean | undefined;
};

function generateUrl(baseURL: string, params: QueryParams): string {
  const queryString = Object.keys(params)
    .map((key) => {
      const value = params[key];
      if (value === undefined || value === null) return "";
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    })
    .filter((param) => param.length > 0)
    .join("&");

  return `${baseURL}?${queryString}`;
}

export default generateUrl;
