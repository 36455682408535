import { defineMessages } from 'react-intl';

const scope = 'InsideLiveTips';

export default defineMessages({
  nominees: {
    id: `${scope}.nominees`,
    defaultMessage: "Nominees",
  },
  no_nominees: {
    id: `${scope}.no_nominees`,
    defaultMessage: "0 Nominees Found",
  },
  submit: {
    id: `${scope}.submit`,
    defaultMessage: "Next",
  },
});