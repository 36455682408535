import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    color: "red",
    opacity: 0.6,
  },
};

export default styles;
