import { FC } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import styles from "./styles";
import { ReactSVG } from "react-svg";
import { useAppDispatch } from "store/hooks";
import { ProfileSliceActions } from "store/profile_slice";
import playvideo from "assets/svgs/play_video.svg";
import statusFull from "assets/svgs/video_status_full.svg";
import statusempty from "assets/svgs/video_status_empty.svg";
import cancelIc from "assets/svgs/cancel.svg";
import { LivetipsPodcastSliceActions } from "store/livetips_podcast_slice";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { FormattedMessage } from "react-intl";
import messages from "pages/view_admin_profile/messages";

type Props = {
  confirmation: boolean;
  onBack?: () => void;
};

const HeaderSection: FC<Props> = ({ confirmation = false, onBack }) => {
  const dispatch = useAppDispatch();

  const handleCloseModal = () => {
    dispatch(LivetipsPodcastSliceActions.closeModals());
    dispatch(ProfileSliceActions.clearUploadFiles());
  };

  const handleBack = () => {
    if (onBack) onBack();
  };

  return (
    <Box sx={styles.container}>
      <Stack
        paddingInline={2}
        paddingBlock={1.5}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <ArrowBackIcon
          sx={{ marginInlineEnd: 2, cursor: "pointer" }}
          onClick={handleBack}
        />
        <Box sx={styles.videoInfo}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
          >
            <ReactSVG src={playvideo} />
            <Typography sx={styles.videoName}>
              Live tips
              <FormattedMessage id={messages.session.id} />
            </Typography>
          </Stack>
        </Box>
        <ReactSVG
          style={{ cursor: "pointer" }}
          onClick={() => handleCloseModal()}
          src={cancelIc}
        />
      </Stack>
      <Stack
        sx={styles.videoStatusContainer}
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <ReactSVG src={statusFull} />
        <Typography sx={{ marginInline: 0.6 }}>
          <FormattedMessage id={messages.details.id} />
        </Typography>
        <Divider sx={styles.divider} orientation="horizontal" />
        <ReactSVG src={confirmation ? statusFull : statusempty} />
        <Typography sx={{ marginInline: 0.6 }}></Typography>
      </Stack>
    </Box>
  );
};

export default HeaderSection;
