import { FC, ReactNode, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { UploadedFileStatus } from "types/uploaded_file_status";
import EmptyList from "components/empty_list";
import { useGetVideoListQuery } from "store/api/video_service/endpoints";
import { VideoServiceResponse } from "store/api/types";
import OnlineItemList from "pages/home/online_section/online_item_list";
import styles from "./styles";
import { VideoQuery } from "types/video_query";
import { EmptyIconTypes } from "types/empty_list_icon_mapper";
import { GridProps, Stack, Typography } from "@mui/material";
import { useAppDispatch } from "store/hooks";
import { paginationSliceeActions } from "store/pagination_slice";

type Props = {
  videoQuery?: VideoQuery;
  emptyLabel?: string;
  icon?: EmptyIconTypes;
  limit?: [number, number];
  perPage?: number;
  dimensions?: GridProps;
  skeletons?: GridProps;
  listLabel: string | ReactNode;
  paginationName?: string;
  theme?: "black" | "white";
};

const OnlineWrapper: FC<Props> = ({
  videoQuery,
  emptyLabel,
  dimensions,
  perPage,
  icon,
  paginationName,
  listLabel,
  skeletons,
  theme,
}) => {
  const [videosList, setVideosList] = useState<Array<VideoServiceResponse>>([]);

  const dispatch = useAppDispatch();

  const { data, isLoading } = useGetVideoListQuery(
    {
      ...videoQuery,
      status: UploadedFileStatus.ACCEPTED,
    },
    {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
      refetchOnReconnect: true,
    }
  ) as { data: { results: [] }; isSuccess: boolean; isLoading: boolean };

  useEffect(() => {
    getVideoList();
  }, [videoQuery, data]);

  const getVideoList = () => {
    setVideosList(data?.results ?? []);
  };

  useEffect(() => {
    if (paginationName) {
      dispatch(
        paginationSliceeActions.updatePaginationItemCount({
          key: paginationName,
          count: Math.ceil(data?.results.length / (perPage ? perPage : 3)),
        })
      );
    }
  }, [paginationName]);

  return (
    <Box sx={styles.container}>
      {videosList.length === 0 ? (
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          width="90%"
          height={500}
        >
          <EmptyList label={emptyLabel} icon={icon ? icon : "online"} />
        </Stack>
      ) : (
        <>
          {typeof listLabel == "string" ? (
            <Typography sx={styles.header}>{listLabel}</Typography>
          ) : (
            listLabel
          )}
          <OnlineItemList
            dimensions={dimensions}
            theme={theme}
            loadingSkeletons={skeletons}
            videoList={videosList.slice(0, perPage)}
            isLoading={isLoading}
          />
        </>
      )}
    </Box>
  );
};

export default OnlineWrapper;
