import { FC } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import InputField from "common/input_field";
import styles from "./styles";
import { OnlineFormProperties } from "store/livetips_online_slice/form_properties";
import { FormikValues, useFormikContext } from "formik";
import { countryList } from "utils/data";
import { useIntl } from "react-intl";
import messages from "./messages";

const GeneralInfoForm: FC = () => {
  const intl = useIntl();
  const { values, handleChange, handleBlur } = useFormikContext<FormikValues>();
  return (
    <Box sx={styles.container}>
      <Grid container columnGap={1.5} rowGap={1} sx={{ marginTop: 2 }}>
        <Grid item xs={12} md={5.7} lg={5.8}>
          <InputField
            inputMode="text"
            label={intl.formatMessage(messages.city)}
            name={OnlineFormProperties.CITY}
          />
        </Grid>
        <Grid item xs={12} md={5.7} lg={5.8}>
          <Select
            name={OnlineFormProperties.COUNTRY}
            label={intl.formatMessage(messages.country)}
            defaultValue={1}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values[OnlineFormProperties.COUNTRY]}
            sx={{ backgroundColor: "white" }}
            fullWidth
          >
            {countryList.map((entry) => (
              <MenuItem key={entry.name} value={entry.name}>
                {entry.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
    </Box>
  );
};

export default GeneralInfoForm;
