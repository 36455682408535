import { Swiper, SwiperSlide, SwiperProps } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-cards";
import "theme/css/home.css";
import { Identification } from "types/identification";

type Props<P extends object> = {
  componentProps: P;
  swiperProps?: SwiperProps;
  SkeletonComponent?: React.ReactNode;
};

function withSwiper<P extends object, R extends { id: Identification }>(
  Component: React.ComponentType<P>,
  data: Array<R>
): React.FC<Props<P>> {
  return function ({
    swiperProps,
    componentProps,
    SkeletonComponent,
  }: Props<P>): React.ReactNode {
    return (
      <>
        {data && data.length > 0 ? (
          <Swiper
            className="home-lives-swiper"
            pagination={{
              clickable: true,
              renderBullet: function (_: number, className: string) {
                return (
                  '<span class="' +
                  className +
                  ' lives-swiper-pagination"><img class="lives-pagination-bullet"/></span>'
                );
              },
            }}
            modules={[Navigation, Autoplay]}
            navigation
            style={{
              paddingInline: 5,
            }}
            slidesPerView={1}
            spaceBetween={50}
            freeMode
            autoplay
            autoFocus
            loop
            {...swiperProps}
          >
            {data &&
              data.length > 0 &&
              data.map((entry) => (
                <SwiperSlide key={entry.id}>
                  <Component {...componentProps} itemData={entry} />
                </SwiperSlide>
              ))}
          </Swiper>
        ) : (
          <>{SkeletonComponent && SkeletonComponent}</>
        )}
      </>
    );
  };
}

export default withSwiper;
