import { ChangeEvent, FC, useRef } from "react";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Grow from "@mui/material/Grow";
import styles from "./styles";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { ReactSVG } from "react-svg";
import Button from "components/custom_button";
import upload from "assets/svgs/upload.svg";
import cancelIc from "assets/svgs/cancel.svg";
import { ProfileSliceActions } from "store/profile_slice";

type Props = {
  handleUploadedFile: (file: File, path: string) => void;
};

const UploadVideoModal: FC<Props> = ({ handleUploadedFile }) => {
  const showModal = useAppSelector(
    (state) => state.adminProfil.modals.showUploadFile
  );

  const dispatch = useAppDispatch();

  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = e.target.files?.item(0);
    if (uploadedFile != null) handleUploadedFile(uploadedFile, e.target.value);
  };

  const handleClick = () => {
    inputRef?.current?.click();
  };

  const handleCancel = () => {
    dispatch(ProfileSliceActions.closeModals());
    dispatch(ProfileSliceActions.clearUploadFiles());
  };

  return (
    <Modal open={showModal} sx={styles.container}>
      <Grow timeout={600} in={showModal}>
        <Paper sx={styles.paper}>
          <Stack
            width="100%"
            direction="row"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
          >
            <Typography flexGrow={0.9} sx={styles.header}>
              Live Tips Online Video
            </Typography>
            <ReactSVG
              onClick={handleCancel}
              style={{ cursor: "pointer", paddingBottom: "25px" }}
              src={cancelIc}
            />
          </Stack>
          <ReactSVG src={upload} />
          <Typography sx={styles.instruction}>
            <span style={{ fontWeight: "700" }}>Drag And Drop</span> video file
            to upload
          </Typography>
          <Typography sx={styles.notice}>
            Your Video will be private until you publish them
          </Typography>
          <Button
            onClick={() => handleClick()}
            extraStyles={styles.button}
            label="Select Files"
          />
          <form encType="multipart/form-data">
            <input
              type="file"
              ref={inputRef}
              accept="video/*"
              style={{ display: "none" }}
              name="upload_video"
              onChange={handleChange}
            />
          </form>
          <Typography sx={styles.privacy}>
            By submitting your Content to Live Tips, you acknowledge that you
            agree to Live Tips's Terms of Service and Community Guidelines.
            Please be sure not to violate others' copyright or privacy rights.
            Learn more
          </Typography>
        </Paper>
      </Grow>
    </Modal>
  );
};

export default UploadVideoModal;
