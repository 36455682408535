import { FC, useMemo } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Grow from "@mui/material/Grow";
import styles from "./styles";
import online_image2 from "assets/images/view_profile_hero.webp";
import color_pallette from "theme/color_pallette";
import { VideoServiceResponse } from "store/api/types";

const stats = ["views", "shares", "likes"];

type Props = {
  switchSides?: boolean;
  onClick?: () => void;
  includeStats?: boolean;
} & Pick<
  VideoServiceResponse,
  "description" | "thumbnail" | "likes_count" | "shares_count" | "views_count"
> & { name: string };

const RelatedVideoItem: FC<Props> = ({
  switchSides = false,
  name,
  thumbnail,
  description,
  likes_count,
  includeStats = true,
  shares_count,
  views_count,
  onClick,
}) => {
  const statsMapper = useMemo(() => {
    return {
      views: views_count,
      shares: shares_count,
      likes: likes_count,
    };
  }, [likes_count, shares_count, views_count]);

  return (
    <Grow in timeout={700}>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        columnGap={1}
        sx={styles.container}
        onClick={onClick}
      >
        <Paper
          component="img"
          alt="Related Video Thumbnail"
          src={thumbnail ?? online_image2}
          sx={{
            ...styles.pic,
            order: {
              lg: switchSides ? 2 : 1,
            },
          }}
        />
        <Box
          sx={{
            order: {
              lg: switchSides ? 1 : 2,
            },
            marginInlineEnd: switchSides ? 1 : 0,
          }}
        >
          <Typography sx={styles.publisherName}>{name}</Typography>
          <Typography sx={styles.desc}>{description}</Typography>
          {includeStats && (
            <Stack direction="row" alignItems="center" sx={styles.footer}>
              {stats.map((entry) => (
                <Typography key={entry} sx={styles.footerSection}>
                  <span
                    style={{
                      color: color_pallette.primary,
                      fontWeight: "600",
                    }}
                  >
                    {(statsMapper as any)[entry]}
                  </span>{" "}
                  {entry}
                </Typography>
              ))}
            </Stack>
          )}
        </Box>
      </Stack>
    </Grow>
  );
};

export default RelatedVideoItem;
