import { FC } from "react";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import styles from "./styles";
import thumbNail from "assets/images/placeholders/thumbnail_pl.webp";
import { ChatMessage } from "store/api/chat/types";

const ChatListItem: FC<ChatMessage> = ({ text, userName, profilePicture }) => {
  return (
    <Stack
      direction="row"
      columnGap={1.5}
      alignItems="flex-start"
      sx={styles.container}
    >
      <Avatar
        src={profilePicture ?? thumbNail}
        sx={{ width: 30, height: 30 }}
      />
      <Typography sx={styles.name}>
        {userName}
        <span
          style={{
            fontWeight: "400",
            paddingInlineStart: "5px",
            fontSize: 11,
            // whiteSpace: "wrap",
            color: "black",
          }}
        >
          {text}
        </span>
      </Typography>
    </Stack>
  );
};

export default ChatListItem;
