import { Methods } from "types/http";
import apiUrls from "../urls";
import { View } from "./types";
import { GetById, PublicUser } from "../types";

export const postView = (param: { body: View; token: string }) => ({
  url: apiUrls.postView(),
  method: Methods.POST,
  body: param.body,
  headers: {
    "Accept-Language": "EN",
    Accept: "*/*",
    Authorization: `Bearer ${param.token}`,
  },
});

export const getVideoViewsCount = (param: GetById) => ({
  url: apiUrls.getVideoViewsCount(param.id),
  method: Methods.GET,
  headers: {
    "Accept-Language": "EN",
    Accept: "*/*",
    Authorization: `Bearer ${param.token}`,
  },
});

export const getVideoViewsDetails = (param: GetById) => ({
  url: apiUrls.getVideoViewsDetails(param.id),
  method: Methods.GET,
  headers: {
    "Accept-Language": "EN",
    Accept: "*/*",
    Authorization: `Bearer ${param.token}`,
  },
});

export const getPodcastViewDetails = (param: GetById) => ({
  url: apiUrls.getPodcastViewsDetails(param.id),
  method: Methods.GET,
  headers: {
    "Accept-Language": "EN",
    Accept: "*/*",
    Authorization: `Bearer ${param.token}`,
  },
});

export const getPodcastViewCount = (param: PublicUser) => ({
  url: apiUrls.getPodcastViewsCount(param.id),
  method: Methods.GET,
  headers: {
    "Accept-Language": "EN",
    Accept: "*/*",
  },
});

export const getProfileViewsCount = (param: GetById) => ({
  url: apiUrls.getProfileViewsCount(param.id),
  method: Methods.GET,
  headers: {
    "Accept-Language": "EN",
    Accept: "*/*",
    Authorization: `Bearer ${param.token}`,
  },
});
