// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CalendarDay__selected_span {
    background: #008755;
    color: white;
    border: none
}

.CalendarDay__selected {
    background: #008755;
    color: white;
}

.CalendarDay__selected:hover {
    background: #008755;
    color: white;
    border: none
}

/* .CalendarDay__hovered_span:hover, */
.CalendarDay__hovered_span {
    background: #008755;
        border: none
}`, "",{"version":3,"sources":["webpack://./src/theme/css/date_picker_override.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,YAAY;IACZ;AACJ;;AAEA;IACI,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,mBAAmB;IACnB,YAAY;IACZ;AACJ;;AAEA,sCAAsC;AACtC;IACI,mBAAmB;QACf;AACR","sourcesContent":[".CalendarDay__selected_span {\n    background: #008755;\n    color: white;\n    border: none\n}\n\n.CalendarDay__selected {\n    background: #008755;\n    color: white;\n}\n\n.CalendarDay__selected:hover {\n    background: #008755;\n    color: white;\n    border: none\n}\n\n/* .CalendarDay__hovered_span:hover, */\n.CalendarDay__hovered_span {\n    background: #008755;\n        border: none\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
