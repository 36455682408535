import { defineMessages } from "react-intl";

const scope = "StreamingPage";

export default defineMessages({
  explore_more: {
    id: `${scope}.explore_more`,
    defaultMessage: "Explore More Live Tips",
  },
  streaming_related: {
    id: `${scope}.streaming_related`,
    defaultMessage: "Related",
  },
  streaming_videos: {
    id: `${scope}.streaming_videos`,
    defaultMessage: "Videos",
  },
  services: {
    id: `${scope}.services`,
    defaultMessage: "Services",
  },
  tips: {
    id: `${scope}.tips`,
    defaultMessage: "Tips Interactive",
  },
  interactive: {
    id: `${scope}.interactive`,
    defaultMessage: "Interactive",
  },
});
