import { SxProps } from "@mui/material";
import color_pallette from "theme/color_pallette";

const styles: Record<string, SxProps> = {
  container: {
    width: { xs: 400, lg: "90%" },
    marginX: "auto",
    marginTop: 10,
    justifyContent: "center",
  },
  callWrapper: {
    marginTop: 5,
    backgroundColor: color_pallette.backgroundGray,
    borderRadius: 5,
    paddingBottom: 5,
    paddingTop: 3,
  },
};

export default styles;
