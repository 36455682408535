import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {},
  contentLabel: {
    color: "black",
    marginBottom: 2,
    fontSize: {
      xs: 32,
      md: 40,
      lg: 30,
    },
    fontWeight: "600",
  },
  wrapper: {
    width: {
      xs: "95%",
      lg: "100%",
    },
  },
  gridContainer: {
    marginX: "auto",
    justifyContent: { xs: "center", md: "flex-start", lg: "center" },
    columnGap: {
      xs: 0,
      md: 1.5,
      lg: 2.5,
    },
    rowGap: {
      xs: 3.5,
    },
  },
};

export default styles;
