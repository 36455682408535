import { SxProps } from "@mui/material";
import color_pallette from "theme/color_pallette";

const styles: Record<string, SxProps> = {
  container: {
    boxShadow: 3,
    borderRadius: 3,
    width: {
      xs: 400,
      md: 450,
      lg: 700,
    },
    order: {
      xs: 1,
      lg: 2,
    },
  },
  header: {
    width: "100%",
    height: 80,
    borderRadius: 3,
    backgroundColor: color_pallette.primary,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headerTitle: {
    color: "white",
    font: "Inter, K2D",
    fontWeight: "300",
    fontSize: { xs: 35 },
  },
};

export default styles;
