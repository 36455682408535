import { FC } from "react";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import Grow from "@mui/material/Grow";
import styles from "./styles";
import Button from "components/custom_button";
import { FlattenedMember } from "store/api/membership_service/endpoints";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "navigation/routes";
import avatar from "assets/images/placeholders/thumbnail_pl.webp";
import cropText from "utils/crop_text";
import { useIntl } from "react-intl";
import messages from "../messages";

type Props = FlattenedMember;

const PoliceSpeakerListItem: FC<Props> = ({
  id,
  name,
  org,
  profile_picture,
}) => {
  const navigate = useNavigate();

  const handleViewProfile = () => {
    if (!id) return;
    navigate(`${NavigationRoutes.public_speaker_profile}/${id}`);
  };

  const intl = useIntl();

  return (
    <Grow in timeout={600}>
      <Paper sx={styles.container} onClick={handleViewProfile}>
        <Avatar
          alt="Police Speaker Profile Image"
          src={profile_picture ?? avatar}
          sx={styles.profilePic}
        />
        <Typography textAlign="center" whiteSpace="pre-line" sx={styles.name}>
          {cropText(name, 50)}
        </Typography>
        <Typography textAlign="center" whiteSpace="pre-line" sx={styles.org}>
          {cropText(org, 55)}
        </Typography>
        <Button label={intl.formatMessage(messages.viewprofile)} />
      </Paper>
    </Grow>
  );
};

export default PoliceSpeakerListItem;
