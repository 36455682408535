import { FC } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import styles from "./styles";
import ActionSection from "./action_section";
import { FormattedMessage } from "react-intl";
import messages from "./messages";

const Nominations: FC = () => {
  return (
    <Box sx={styles.container}>
      <Typography sx={styles.header}>
        <FormattedMessage id={messages.nominate.id} />
      </Typography>
      <ActionSection />
    </Box>
  );
};

export default Nominations;
