import { LinearProgress } from "@mui/material";
import { FC, useEffect, useState } from "react";

type Props = {
  /* for now record it can be GENERIC */
  playing: boolean;
  recordLength?: number;
  playRate: number;
  skipBackword?: boolean;
  skipForward?: boolean;
  completed: () => void;
};

const AudioTracker: FC<Props> = ({
  recordLength,
  playRate = 1,
  skipBackword = false,
  skipForward = false,
  playing,
  completed,
}) => {
  const [progress, setProgress] = useState<number>(0);
  const [per, setPer] = useState<number>(1000);

  useEffect(() => {
    if (recordLength) {
      setPer((recordLength * 1000) / 100);
    }
  }, [recordLength]);

  useEffect(() => {
    if (skipBackword) handleSkipBackward();
    if (skipForward) handleSkipForward();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skipBackword, skipForward]);

  const handleSkipForward = () => {
    setProgress(progress + 15);
  };

  const handleSkipBackward = () => {
    setProgress(progress - 15);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      if (playing && progress < 100) {
        setProgress((prevPro) => {
          return (prevPro += playRate);
        });
      } else if (progress >= 100) {
        setProgress(0);
        completed();
      }
    }, per);

    return () => {
      clearInterval(timer);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playing, per, progress, playRate]);

  return <LinearProgress variant="determinate" value={progress} />;
};

export default AudioTracker;
