import { defineMessages } from 'react-intl';

const scope = 'ExploreFooterSection';

export default defineMessages({
  explore: {
    id: `${scope}.explore`,
    defaultMessage: "Explore More",
  },
  share: {
    id: `${scope}.share`,
    defaultMessage: "Share",
  },
  savelist: {
    id: `${scope}.savelist`,
    defaultMessage: "Save in List",
  },
  saved: {
    id: `${scope}.saved`,
    defaultMessage: "Saved",
  },
  playpodcast: {
    id: `${scope}.playpodcast`,
    defaultMessage: "Play Podcast",
  },
});