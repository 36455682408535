import { FC, useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import SidebarSection from "pages/explore_speakers/sidebar_section";
import TrendingSection from "./trending_section";
import MostViewedSection from "./most_viewed_section";
import SectionByCategory from "./section_by_category";
import UpcomingSection from "./upcoming_section";
import FilteredSection from "./filtered_section";
import { ReactSVG } from "react-svg";
import microphone from "assets/svgs/microphone.svg";
import styles from "./styles";
import { useAppSelector } from "store/hooks";

const ExplorePodcasts: FC = () => {
  const [filter, setFilter] = useState<boolean>(false);

  const {
    categoryFilter,
    dateFilter,
    rateFilter,
    isFiltering,
    isInitialRate,
    speakerFilter,
  } = useAppSelector((state) => state.sidebar);

  const { direction } = useAppSelector((state) => state.global.lang);

  const isFilter = () => {
    const res =
      categoryFilter != null ||
      rateFilter != null ||
      speakerFilter != null ||
      dateFilter?.endDate !== null ||
      dateFilter?.startDate !== null;

    setFilter(res);
  };

  useEffect(() => {
    isFilter();
  }, [
    categoryFilter,
    isInitialRate,
    isFiltering,
    rateFilter,
    dateFilter,
    speakerFilter,
  ]);

  return (
    <Box component="div" dir={direction} sx={styles.container}>
      <SidebarSection />
      {!filter ? (
        <Box>
          <Stack sx={styles.gridWrapper}>
            <Grid container sx={styles.gridContainer}>
              <TrendingSection />
              <MostViewedSection />
              <Stack direction="row" sx={styles.mic} justifyContent="center">
                <ReactSVG src={microphone} />
              </Stack>
            </Grid>
            <Box sx={styles.upcomingContainer}>
              <UpcomingSection />
            </Box>
          </Stack>
          <Box sx={styles.footerContainer}>
            <SectionByCategory sectionHeader="Technology" category={1} />
            <SectionByCategory sectionHeader="Environment" category={2} />
          </Box>
        </Box>
      ) : (
        <FilteredSection />
      )}
    </Box>
  );
};

export default ExplorePodcasts;
