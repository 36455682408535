import rootApi from "store/api";
import {
  getPodcastViewCount,
  getPodcastViewDetails,
  getProfileViewsCount,
  getVideoViewsCount,
  getVideoViewsDetails,
  postView,
} from "./action_creators";
import { View } from "./types";
import { GetById, PublicUser } from "../types";

export const ViewService = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    postView: builder.mutation<View, { body: View; token: string }>({
      query: (body) => postView(body),
    }),
    getVideoViews: builder.query<number, GetById>({
      query: (body) => getVideoViewsCount(body),
    }),
    getVideoViewsDetails: builder.query<Array<View>, GetById>({
      query: (body) => getVideoViewsDetails(body),
    }),
    getPodcastViewsDetails: builder.query<Array<View>, GetById>({
      query: (body) => getPodcastViewDetails(body),
    }),
    getPodcastViewsCount: builder.query<number, PublicUser>({
      query: (body) => getPodcastViewCount(body),
    }),
    getProfileViewCount: builder.query<number, GetById>({
      query: (body) => getProfileViewsCount(body),
    }),
  }),
});

export const {
  useGetProfileViewCountQuery,
  usePostViewMutation,
  useGetPodcastViewsCountQuery,
  useGetPodcastViewsDetailsQuery,
  useGetVideoViewsDetailsQuery,
  useGetVideoViewsQuery,
} = ViewService;
