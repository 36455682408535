import { FC } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import styles from "./styles";
import FormSection from "./form_section";
import NotificationSection from "./notification_section";
import HeaderSection from "./header_section";
import FormContainer from "common/form_container";
import {
  initialFormState,
  validateSchema,
} from "store/livetips_online_slice/form";
import ConfirmationModal from "./confirmation_modal";
import useJoinUs from "hooks/use_joinus";
import useSnack from "hooks/useSnack";
import SnackBar from "components/snack_bar";
import { UploadedFileStatus } from "types/uploaded_file_status";

const JoinUs: FC = () => {
  const { isLoading, member, onSubmit } = useJoinUs();

  const { triggerSnack } = useSnack();

  const RenderComponent = () => {
    if (isLoading) {
      triggerSnack("Verifying Status ...", "info");
      return <Typography sx={styles.notice}>Verifying Status...</Typography>;
    }
    if (member && member.status == UploadedFileStatus.PENDING) {
      triggerSnack("Pending Status !", "info");
      window.scrollTo({ behavior: "smooth", top: 0 });
      window.scrollX = 0;
      window.scrollY = 0;
      return (
        <Typography sx={styles.notice}>
          Already Submitted Speaker and your status is still pending, try to
          wait for few more minuts...
        </Typography>
      );
    } else {
      return (
        <>
          <ConfirmationModal />
          <FormContainer
            initialValues={initialFormState}
            validationSchema={validateSchema}
            onSubmit={onSubmit}
          >
            <FormSection />
            <SnackBar />
            <NotificationSection />
          </FormContainer>
        </>
      );
    }
  };

  return (
    <>
      <HeaderSection />
      <Box sx={styles.container}>{RenderComponent()}</Box>
    </>
  );
};

export default JoinUs;
