import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {},
  flexEnd: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    position: "absolute",
    top: 200,
    right: 50,
    cursor: "pointer",
  },
  img: {
    marginBottom: 13,
    marginTop: "20px",
    borderRadius: 3,
    overflow: "hidden",
    position: "relative",
    cursor: "pointer",
  },
};

export default styles;
