import { CSSProperties, FC, useCallback } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import styles from "./styles";
import color_pallette from "theme/color_pallette";
import { Autoplay } from "swiper/modules";
import { useWidth } from "hooks/useWidth";
import withSwiper from "hoc/withSwiper";
import Image, { ImageWrapperProps } from "components/image";
import { list_one } from "./data";
import { Breakpoint } from "@mui/material";
import { FormattedMessage } from "react-intl";
import messages from "../messages";

const highlighted: CSSProperties = {
  color: color_pallette.primary,
  fontWeight: "700",
};

const PartnerSection: FC = () => {
  const br = useWidth();

  const getSlides = (br: Breakpoint) => {
    if (br === "xs" || br === "sm") return 1;
    return 3;
  };

  const renderSwiperListOne = useCallback(() => {
    return withSwiper(
      Image,
      list_one
    )({
      componentProps: {} as ImageWrapperProps,
      swiperProps: {
        style: {
          height: 150,
          width: "70%",
        },
        slidesPerView: getSlides(br),
        spaceBetween: 3,
        pagination: false,
        loop: true,
        modules: [Autoplay],
        speed: 2000,
        autoplay: {
          delay: 0,
          pauseOnMouseEnter: true,
        },
      },
    });
  }, [br]);

  return (
    <Box sx={styles.container}>
      {" "}
      <Typography sx={styles.header}>
        <span style={highlighted}>
          <FormattedMessage id={messages.partners.id} />
        </span>
      </Typography>
      {renderSwiperListOne()}
    </Box>
  );
};

export default PartnerSection;
