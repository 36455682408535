import { FC, PropsWithChildren } from "react";
import Paper from "@mui/material/Paper";
import Grow from "@mui/material/Grow";
import styles from "./styles";
import { Upcoming } from "store/api/streaming_service/types";
import { Avatar, Box, Rating, Stack, Typography } from "@mui/material";
import indicator from "assets/images/live_podcast_indicator.png";
import live_podcast_fg from "assets/images/live_podcast_fg.png";
import Button from "components/custom_button";
import color_pallette from "theme/color_pallette";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "navigation/routes";

type Props = {
  itemData: PropsWithChildren<Upcoming & { policeDepartement?: boolean }>;
};

const UpcomingLivePodcast: FC<Props> = ({ itemData }) => {
  const navigate = useNavigate();

  const handleJoinStream = () => {
    navigate(`${NavigationRoutes.streaming_podcast_page}/${itemData.id}`);
  };

  return (
    <Grow in timeout={600}>
      <Paper sx={styles.container}>
        <Stack
          sx={{
            position: "relative",
            justifyContent: "space-between",
            alignItems: "center",
            paddingInlineEnd: 5,
            columnGap: 2,
          }}
          direction="row"
        >
          <Avatar src={itemData.user.profilePicture} sx={styles.avatar} />
          <Box component="img" src={indicator} />
          <Stack direction="column" rowGap={0.3} sx={{ marginInlineEnd: 20 }}>
            <Typography sx={{ fontWeight: "700", fontSize: { lg: 20 } }}>
              {itemData.user.name}
            </Typography>
            <Typography sx={{ fontSize: { lg: 16 } }}>
              {itemData.user.org}
            </Typography>
            <Button
              label="Join now"
              onClick={() => handleJoinStream()}
              extraStyles={{ height: 40, marginTop: 1.5 }}
            />
          </Stack>
        </Stack>
        <Typography
          sx={{
            textAlign: "start",
            width: "70%",
            marginTop: 2,
            fontSize: { lg: 20 },
          }}
        >
          {itemData.description}
        </Typography>
        <Box
          component="img"
          sx={{ position: "absolute", bottom: -1, left: 0 }}
          src={live_podcast_fg}
        />
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          width={550}
          sx={{
            position: "absolute",
            bottom: 15,
            left: 30,
          }}
        >
          <Typography
            sx={{
              fontSize: { lg: 40 },
              color: "white",
            }}
          >
            Podcast
          </Typography>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography sx={styles.ratingLabel}>Rating</Typography>
            <Rating
              sx={{ marginInline: 0.5, color: color_pallette.primary }}
              size="small"
              readOnly
              defaultValue={1}
              value={Number(itemData.rating)}
            />
            <Typography sx={styles.rate}>{Number(itemData.rating)}</Typography>
          </Stack>
        </Stack>
      </Paper>
    </Grow>
  );
};

export default UpcomingLivePodcast;
