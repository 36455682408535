import { SxProps } from "@mui/material";
import color_pallette from "theme/color_pallette";

const styles: Record<string, SxProps> = {
  iconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    columnGap: { xs: 0.8, md: 1.5 },
    marginInlineStart: { lg: 2 },
  },
  icon: {
    cursor: "pointer",
    color: color_pallette.primary,
  },
  shareButton: {
    color: "black",
    borderRadius: 10,
    fontSize: {
      lg: 12,
    },
    ":hover": {
      backgroundColor: "white",
    },
  },
};

export default styles;
