import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { FlattenedMember } from "store/api/membership_service/endpoints";

export type CheckedMember = FlattenedMember & { checked: boolean };

type State = {
  members: Array<FlattenedMember>;
  invitedSpeakers: Array<CheckedMember>;
};

const initialState: State = {
  members: [],
  invitedSpeakers: [],
};

const MembersSlice = createSlice({
  name: "membersSlice",
  initialState,
  reducers: {
    submitSpeakers: (
      state: State,
      { payload }: PayloadAction<Array<FlattenedMember>>
    ) => {
      state.members = payload;
      state.invitedSpeakers = payload.map((entry) => {
        return {
          ...entry,
          checked: false,
        };
      });
      return state;
    },
    inviteSpeaker: (
      state: State,
      { payload }: PayloadAction<CheckedMember>
    ) => {
      payload.checked = !payload.checked;
      return state;
    },
  },
});

export default MembersSlice.reducer;
export const MembersSliceActions = MembersSlice.actions;
