import { FC, useMemo } from "react";
import { Avatar, Box, Paper, Stack, Typography } from "@mui/material";
import styles from "./styles";
import { useAppSelector } from "store/hooks";
import ReactPlayer from "react-player/lazy";
import IconText from "pages/view_admin_profile/body_section/icon_text";
import ReactionCounter from "components/reaction_counter";
import IconSection from "../icon_section";
import avatar from "assets/images/avatar_place.webp";
import view from "assets/svgs/view.svg";
import sharedFill from "assets/svgs/share_filled.svg";
import star from "assets/svgs/star.svg";
import thumbNail from "assets/images/placeholders/thumbnail_pl.webp";

const BodySection: FC = () => {
  const { file, meta } = useAppSelector(
    (state) => state.adminProfil.onlineTips
  );

  const { profilePicture: profilePicture, name } = useAppSelector(
    (state) => state.global.speakerProfile
  );

  const handleDislike = () => {};

  const handleLike = () => {};

  const url = useMemo(() => {
    try {
      return URL.createObjectURL(file.file);
    } catch (error) {
      return thumbNail;
    }
  }, [file.file]);

  return (
    <Paper sx={styles.container}>
      <Box sx={styles.videoContainer}>
        <ReactPlayer
          controls
          playing={false}
          stopOnUnmount
          width="100%"
          height="100%"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
          }}
          muted
          url={url}
        />
      </Box>
      <Box sx={styles.flex}>
        <Stack direction="row" marginTop={1} columnGap={1} alignItems="center">
          <Avatar sx={styles.avatar} src={profilePicture ?? avatar} />
          <Typography sx={styles.speaker}>{name}</Typography>
        </Stack>
        <Box textAlign="start" mt={1.5}>
          <Typography sx={styles.speaker}>{meta.title}</Typography>
          <Typography sx={styles.datePublished}>Just now</Typography>
          <Typography>{meta.description}</Typography>
        </Box>
        <Stack sx={styles.statsContainer}>
          <Stack justifyContent="center" direction="row" columnGap={1.3}>
            <IconText iconPath={view} label="0 Views" />
            <IconText iconPath={sharedFill} label="0 Shares" />
            <IconText iconPath={star} label="0 Rating" />
          </Stack>
          <ReactionCounter
            reaction="idle"
            onDisLike={handleDislike}
            onLike={handleLike}
            likes={1}
            dislikes={0}
          />
          <IconSection />
        </Stack>
      </Box>
    </Paper>
  );
};

export default BodySection;
