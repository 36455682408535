import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {},
  statsContainer: {
    display: "flex",
    flexDirection: {
      xs: "column",
      lg: "row",
    },
    width: "100%",
    justifyContent: { lg: "space-between" },
    alignItems: "center",
    rowGap: 2,
    mt: 3,
  },
};

export default styles;
