import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    boxShadow: 10,
    borderRadius: 5,
    padding: 1.5,
    width: { xs: "95%", sm: 500, lg: 480 },
    height: "fit-content",
    paddingBottom: 2,
  },
  header: { fontSize: { lg: 25 } },
  img: {
    marginBlock: 1.5,
    width: { xs: "100%", lg: "85%" },
    height: 200,
    objectFit: "cover",
  },
  childrenWrapper: { textAlign: "start", paddingInlineStart: 3 },
};

export default styles;
