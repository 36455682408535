import { FC, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import styles from "./styles";
import InputField from "common/input_field";
import FormContainer from "common/form_container";
import SubmitButton from "common/submit_button";
import Comment from "components/comment";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { FormikValues } from "formik";
import { Comment as CommentType } from "store/api/comment_service/types";
import {
  useGetProfileCommentsQuery,
  usePostCommentMutation,
} from "store/api/comment_service/endpoints";
import {
  COMMENT_FORM,
  initialValues,
  validationSchema,
} from "pages/online_video_details/comment_section/form";
import {
  OPTIMISTIC_COMMENT_ID,
  commentSliceActions,
} from "store/comment_slice";
import useSnack from "hooks/useSnack";
import SnackBar from "components/snack_bar";
import { MEDIA_TYPE } from "types/media_type";
import { usePostNotificationMutation } from "store/api/notification_service/endpoints";
import { NOTIFICATION_REPLY_TYPE } from "store/api/notification_service/types";
import messages from "./messages";
import { FormattedMessage, useIntl } from "react-intl";

type Props = {
  profileName?: string;
  ownerId: string | number;
};

const CommentSection: FC<Props> = ({ profileName, ownerId }) => {
  const dispatch = useAppDispatch();

  const { speakerProfile, credentials } = useAppSelector(
    (state) => state.global
  );

  const { data } = useGetProfileCommentsQuery(
    {
      token: credentials.token.access_token,
      id: speakerProfile.customerId,
    },
    {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
      pollingInterval: 30000,
    }
  );

  const [notify] = usePostNotificationMutation();

  const intl = useIntl();

  const notifyOwner = async () => {
    try {
      await notify({
        token: credentials.token.access_token,
        body: {
          actionType: NOTIFICATION_REPLY_TYPE.REPLY,
          digitalMediumType: MEDIA_TYPE.USER_PROFILE,
          receiverId: ownerId,
          messageEn: `${speakerProfile.name} ${intl.formatMessage(
            messages.commented
          )} `,
          triggeredBy: "USER",
          senderId: speakerProfile.customerId,
          senderName: speakerProfile.name,
          senderPic: speakerProfile.profilePicture,
        },
      });
    } catch (error) {
      triggerSnack(intl.formatMessage(messages.error), "error");
    }
  };

  const commentList = useAppSelector((state) => state.comment.commentList);

  useEffect(() => {
    if (data)
      dispatch(
        commentSliceActions.pushCommentList({
          data,
          userId: speakerProfile.customerId,
        })
      );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, dispatch]);

  const { triggerSnack } = useSnack();

  const [post] = usePostCommentMutation();

  const handleSubmitComment = async (values: FormikValues) => {
    const body: CommentType = {
      deleted: false,
      hasParent: false,
      ownerId,
      userId: speakerProfile.customerId,
      commentString: values[COMMENT_FORM.comment],
      digitalMediumType: MEDIA_TYPE.USER_PROFILE,
      commentId: OPTIMISTIC_COMMENT_ID,
      parent: null,
      children: null,
      likesCount: 0,
      dislikesCount: 0,
      likes: [],
      dislikes: [],
      user: speakerProfile,
    };

    dispatch(commentSliceActions.pushComment(body));

    try {
      const { data } = (await post({
        token: credentials.token.access_token,
        body: {
          ownerId,
          parent: null,
          videoId: speakerProfile.customerId,
          userId: speakerProfile.customerId,
          commentString: values[COMMENT_FORM.comment],
          digitalMediumType: MEDIA_TYPE.USER_PROFILE,
          children: null,
        },
      })) as { data: CommentType };

      dispatch(commentSliceActions.replaceCommentId(data));

      triggerSnack(intl.formatMessage(messages.comment_success), "info");
      notifyOwner();
    } catch (error) {
      triggerSnack(intl.formatMessage(messages.error), "error");
      dispatch(commentSliceActions.revertComment());
    }
  };

  return (
    <>
      <Box sx={styles.container}>
        <Typography sx={styles.sectionLabel}>
          <FormattedMessage id={messages.ask.id} />
          <span style={{ fontWeight: "700", marginInlineStart: "10px" }}>
            {profileName ? profileName : speakerProfile.name}
          </span>
        </Typography>
        <SnackBar />
        <FormContainer
          onSubmit={handleSubmitComment}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          <InputField
            name={COMMENT_FORM.comment}
            placeholder={intl.formatMessage(messages.write_comment)}
            sx={styles.commentInput}
            rows={10}
            multiline
            fullWidth
          />
          <SubmitButton title={intl.formatMessage(messages.submit)} />
        </FormContainer>
        <Divider sx={{ marginBlock: 3 }} />
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          sx={{ paddingInline: 2 }}
        >
          <Typography sx={styles.font}>Replies</Typography>
          <Typography sx={styles.font}>
            {commentList.length} <FormattedMessage id={messages.comments.id} />
          </Typography>
        </Stack>
        {commentList && commentList.length > 0 ? (
          commentList.map((entry) => (
            <Box sx={styles.commentContainer} key={entry.commentId}>
              <Comment {...entry} />
            </Box>
          ))
        ) : (
          <Typography>
            <FormattedMessage id={messages.no_comments.id} />
          </Typography>
        )}
      </Box>
    </>
  );
};

export default CommentSection;
