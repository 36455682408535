import { ComponentType, useEffect } from "react";
import { useAppSelector } from "store/hooks";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "navigation/routes";
import { ROLE } from "types/role";

function withPrivilage(Component: ComponentType) {
  return () => {
    const {
      credentials: { token },
      speakerProfile,
    } = useAppSelector((state) => state.global);
    const navigate = useNavigate();

    useEffect(() => {
      if (speakerProfile.role == ROLE.normal || !token.access_token)
        navigate(NavigationRoutes.login);
    }, []);

    return <Component />;
  };
}

export default withPrivilage;
