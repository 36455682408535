import { Moment } from "moment";
import { FocusedInputShape } from "react-dates";

export enum DATES {
  startDate = "startDate",
  endDate = "endDate",
}

export type Calendar = {
  [keyof in FocusedInputShape]: Moment | null;
};

export type FocusedInput = {
  focused: FocusedInputShape | null;
};
