import { FC } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import styles from "./styles";
import Searchbar from "components/searchbar";
import ContentSection from "./content_section";
import { FormattedMessage } from "react-intl";
import messages from "./messages";

const OnlineSection: FC = () => {
  return (
    <Box sx={styles.container}>
      <Searchbar />
      <Typography
        sx={{
          marginTop: 5,
          fontSize: { lg: 40 },
          fontWeight: "500",
          cursor: "pointer",
          opacity: 0.8,
        }}
      >
        <FormattedMessage id={messages.title.id} /> Live Tips
      </Typography>
      <ContentSection />
    </Box>
  );
};

export default OnlineSection;
