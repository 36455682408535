import { FC, useEffect, useMemo, useState } from "react";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import styles from "./styles";
import Button from "components/custom_button";
import ListenButton from "components/listen_button";
import { ReactSVG } from "react-svg";
import black_share from "assets/svgs/black_save.svg";
import black_save from "assets/svgs/black_share.svg";
import placeholderImage from "assets/images/placeholders/thumbnail_pl.webp";
import cropText from "utils/crop_text";
import useAudioPreview from "hooks/useAudioPreview";
import { useAppSelector } from "store/hooks";

const BodySection: FC = () => {
  const [audioFile, setAudioFile] = useState<HTMLAudioElement | null>(null);
  const [audioPlaying, setAudioPlaying] = useState<boolean>(false);

  const { file } = useAppSelector((state) => state.adminProfil.onlineTips);

  useEffect(() => {
    if (file) {
      let url: string = "";
      try {
        url = URL.createObjectURL(file.file);
      } catch (error) {
        url = "";
      }
      if (url) {
        const audio = new Audio(url);
          setAudioFile(audio);
      }
    }
  }, [file]);

  const toggleState = async () => {
    setAudioPlaying(!audioPlaying);
    if (audioFile && audioFile.HAVE_ENOUGH_DATA) {
      !audioPlaying ? await audioFile?.play() : audioFile.pause();
    }
  };

  const { getDuration, meta, profile_picture, user } = useAudioPreview();

  const url = useMemo(() => {
    try {
      return URL.createObjectURL(meta.thumbnail);
    } catch (error) {
      return placeholderImage;
    }
  }, [meta.thumbnail]);

  return (
    <Paper sx={styles.container}>
      <Stack
        width={{ xs: "90%", md: "100%" }}
        direction={{ xs: "column", md: "row" }}
        justifyContent={{ md: "space-around" }}
        alignItems="flex-start"
      >
        <Box sx={styles.headerContainer}>
          <img
            width={400}
            height={250}
            style={{
              marginBottom: "10px",
              borderRadius: "10px",
            }}
            src={url}
          />
          <Stack
            direction="row"
            justifyContent="space-around"
            alignItems="center"
            width="90%"
          >
            <Button
              label="View Profile"
              extraStyles={styles.viewProfile}
              variant="outlined"
            />
            <>
              <Typography sx={styles.ratingValue}>Rating 0</Typography>
              <Rating size="small" sx={styles.rating} readOnly value={0} />
            </>
          </Stack>
        </Box>
        <Stack sx={styles.contentContainer}>
          <Stack direction="row" columnGap={1} alignItems="center">
            <Avatar src={profile_picture} sx={styles.avatar} />
            <Typography>{user.name}</Typography>
          </Stack>
          <Typography sx={styles.subTitle}>{user.org}</Typography>
          <Typography sx={styles.caption}>{meta.title}</Typography>
          {/* ACTIONS */}
          <Stack direction="row" columnGap={5} alignItems="center">
            <Stack direction="row" columnGap={1.5} alignItems="center">
              <ListenButton playing={audioPlaying} handleClick={toggleState} />
              <Typography sx={styles.podcastLength}>
                {audioPlaying ? getDuration() : "Preview"}
              </Typography>
            </Stack>
            <Stack direction="row" columnGap={1.5} alignItems="center">
              <>
                <ReactSVG src={black_save} />
                <Typography sx={styles.actionLabel}>Save in List</Typography>
              </>
              <>
                <ReactSVG src={black_share} />
                <Typography sx={styles.actionLabel}>Share</Typography>
              </>
            </Stack>
          </Stack>
          {/* LONG DESCRIPTION */}
          <Typography sx={styles.description}>
            {cropText(meta.description, 50)}
          </Typography>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default BodySection;
