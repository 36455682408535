import { Methods } from "types/http";
import apiUrls from "../urls";
import {
  Channel,
  LiveStreaming,
  PostChannelBody,
  PostChannelTokenBody,
  Upcoming,
} from "./types";
import { GetById } from "../types";
import handleFormDataRequests from "utils/upload_form_data";
import { QueryArgs } from "types/query_args";

export const postChannel = (body: {
  token: string;
  data: PostChannelBody;
}) => ({
  url: apiUrls.postChannel(),
  method: Methods.POST,
  body: body.data,
  headers: {
    Lng: "EN",
    Accept: "application/json",
    Authorization: `Bearer ${body.token}`,
  },
});

export const updateChannel = (body: { token: string; data: Channel }) => ({
  url: apiUrls.updateChannel(),
  method: Methods.PUT,
  body: body.data,
  headers: {
    Lng: "EN",
    Accept: "application/json",
    Authorization: `Bearer ${body.token}`,
  },
});

export const getAllScheduledChannels = (body: { token: string }) => ({
  url: apiUrls.getAllScheduledChannels(),
  method: Methods.GET,
  headers: {
    Lng: "EN",
    Accept: "application/json",
    Authorization: `Bearer ${body.token}`,
  },
});

export const getAllActiveChannels = (body: { token: string }) => ({
  url: apiUrls.getAllActiveChannels(),
  method: Methods.GET,
  headers: {
    Lng: "EN",
    Accept: "application/json",
    Authorization: `Bearer ${body.token}`,
  },
});

export const getAllExpiredChannels = (body: { token: string }) => ({
  url: apiUrls.getAllExpiredChannels(),
  method: Methods.GET,
  headers: {
    Lng: "EN",
    Accept: "application/json",
    Authorization: `Bearer ${body.token}`,
  },
});

export const getAllChannels = (body: { token: string }) => ({
  url: apiUrls.getAllChannels(),
  method: Methods.GET,
  headers: {
    Lng: "EN",
    Accept: "application/json",
    Authorization: `Bearer ${body.token}`,
  },
});

export const getChannelById = (param: GetById) => ({
  url: apiUrls.getChannelById(param.id),
  method: Methods.GET,
  headers: {
    Lng: "EN",
    Accept: "application/json",
    Authorization: `Bearer ${param.token}`,
  },
});

export const getLiveStreams = (param: GetById) => ({
  url: apiUrls.getChannelById(param.id),
  method: Methods.GET,
  headers: {
    Lng: "EN",
    Accept: "application/json",
    Authorization: `Bearer ${param.token}`,
  },
});

export const postChannelTokenAC = (param: {
  token: string;
  body: PostChannelTokenBody;
}) => ({
  url: apiUrls.postChannelTokenUrl(),
  method: Methods.POST,
  body: param.body,
  headers: {
    Lng: "EN",
    Accept: "application/json",
    Authorization: `Bearer ${param.token}`,
  },
});

export const getLiveStreamings = (param: {
  token: string;
  query: Partial<QueryArgs>;
}) => ({
  url: apiUrls.getLiveStreamings(param.query),
  method: Methods.GET,
  headers: {
    Lng: "EN",
    Accept: "application/json",
    Authorization: `Bearer ${param.token}`,
  },
});

export const getLiveStreamingsDetails = (param: {
  token: string;
  query: Partial<QueryArgs>;
}) => ({
  url: apiUrls.getLiveStreamingsByID({
    category: param.query.category,
    description: param.query.description,
    is_expired: param.query.is_expired,
    is_live: param.query.is_live,
    page: param.query.page,
    privacy: param.query.privacy,
    user: param.query.user,
    status: param.query.status,
  }),
  method: Methods.GET,
  headers: {
    Lng: "EN",
    Accept: "application/json",
    Authorization: `Bearer ${param.token}`,
  },
});

export const getVideoLiveStream = (param: GetById) => ({
  url: apiUrls.liveVideoById(param?.id),
  method: Methods.GET,
  headers: {
    Lng: "EN",
    "Accept-Language": "EN",
    Accept: "application/json",
    "Accept-Encoding": "gzip, deflate, br",
    Authorization: `Bearer ${param.token}`,
  },
});

export const patchVideoLiveStream = (param: GetById) => ({
  url: apiUrls.liveVideoById(param?.id),
  method: Methods.PATCH,
  body: { status: 5 },
  headers: {
    Lng: "EN",
    "Accept-Language": "EN",
    Accept: "application/json",
    "Accept-Encoding": "gzip, deflate, br",
    Authorization: `Bearer ${param.token}`,
  },
});

export const getPodcastLiveStreamings = (param: {
  token: string;
  query: Partial<QueryArgs>;
}) => ({
  url: apiUrls.getPodcastLiveStreamings(param.query),
  method: Methods.GET,
  headers: {
    Lng: "EN",
    Accept: "application/json",
    Authorization: `Bearer ${param.token}`,
  },
});

export const postLiveStreaming = async ({
  body,
  token,
}: {
  body: LiveStreaming;
  token: string;
}) => {
  try {
    const res = await handleFormDataRequests<Upcoming>({
      body,
      code: Methods.POST,
      url: apiUrls.postLiveStreaming(),
      headers: {
        Lng: "EN",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return res;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
