import { FC, useMemo } from "react";
import Box from "@mui/material/Box";
import styles from "./styles";
import { SxProps, Typography, TypographyProps } from "@mui/material";
import color_pallette from "theme/color_pallette";
import PrivacyPolicyModal from "components/privacy_policy_modal";
import { useAppDispatch } from "store/hooks";
import { modalSliceActions } from "store/modal_slice";
import combineStyles from "utils/combile_styles";

type Props = {
  extraStyle?: SxProps<TypographyProps>;
  hightlightColor?: string;
};

const PrivacyPolicy: FC<Props> = ({ extraStyle, hightlightColor }) => {
  const dispatch = useAppDispatch();

  const handleShowPrivacy = () => {
    dispatch(modalSliceActions.setPrivacyPolicy({ privacy: true }));
  };

  const style = useMemo(() => {
    return combineStyles(styles.text, extraStyle);
  }, [extraStyle]);

  return (
    <Box sx={styles.container}>
      <Typography onClick={handleShowPrivacy} sx={style}>
        By Creating a Live Tips account you agree to our{" "}
        <span
          style={{
            cursor: "pointer",
            color: hightlightColor ? hightlightColor : color_pallette.primary,
          }}
        >
          Terms of use
        </span>{" "}
        <span
          style={{
            cursor: "pointer",
            color: hightlightColor ? hightlightColor : color_pallette.primary,
          }}
        >
          and Privacy policy
        </span>
      </Typography>
      <PrivacyPolicyModal />
    </Box>
  );
};

export default PrivacyPolicy;
