import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    width: {
      xl: 1400,
    },
  },
  header: {
    fontSize: 30,
    fontFamily: "Nizzar ,K2D, Inter",
    fontWeight: "600",
    marginInlineStart: 1,
    marginBottom: 0.6,
    marginTop: 2,
  },
};

export default styles;