import { FC, useMemo } from "react";
import Box, { BoxProps } from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Grow from "@mui/material/Grow";
import Avatar from "@mui/material/Avatar";
import Rating from "@mui/material/Rating";
import { SxProps } from "@mui/material/styles";
import cropText from "utils/crop_text";
import color_pallette from "theme/color_pallette";
import { VideoServiceResponse } from "store/api/types";
import online_image2 from "assets/images/placeholders/thumbnail_pl.webp";
import cover_placeholder from "assets/images/view_profile_hero.webp";
import styles from "./styles";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "navigation/routes";
import { FormattedMessage } from "react-intl";
import messages from "pages/login_page/messages";
import { ReactSVG } from "react-svg";
import small_logo from "assets/svgs/small_logo.svg";

type Props = {
  containerStyles?: SxProps<BoxProps>;
  theme?: "black" | "white";
} & VideoServiceResponse;

const stats = [messages.views.id, messages.likes.id, messages.share.id];

const OnlineCardListItem: FC<Props> = (props) => {
  const {
    description,
    containerStyles,
    thumbnail,
    title,
    theme = "white",
  } = props;

  const navigate = useNavigate();

  const handleVideoDetails = () => {
    navigate(`${NavigationRoutes.services_online_video}/${props.id}`);
    window.scroll({ top: 0, behavior: "smooth" });
  };

  const style = useMemo(() => {
    return { ...styles.container, ...containerStyles };
  }, [containerStyles]);

  const statsValue = useMemo(() => {
    return {
      [messages.views.id]: props.views_count,
      [messages.likes.id]: props.likes_count,
      [messages.share.id]: props.shares_count,
    };
  }, [props]);

  const getStats = (prop: string) => {
    return (statsValue as any)[prop];
  };

  const getRate = () => {
    const rate = parseInt(props.rating as string);
    return isNaN(rate) ? 0 : rate;
  };

  return (
    <Grow in timeout={700}>
      <Box onClick={handleVideoDetails} sx={style}>
        <Box sx={styles.imgWrapper}>
          <Box
            component="img"
            alt="online item image"
            sx={styles.img}
            src={thumbnail ?? cover_placeholder}
          />
          <ReactSVG
            style={{ position: "absolute", top: 15, left: 15 }}
            src={small_logo}
          />
          <Box sx={styles.thumbContainer}>
            <Typography
              sx={{
                ...styles.captionText,
                color: theme === "black" ? "black" : "white",
              }}
            >
              {cropText(title, 60)}
            </Typography>
          </Box>
        </Box>
        <Stack direction="row" alignItems="center" sx={{ marginTop: 1 }}>
          <Avatar
            alt="Online Event Image"
            sx={styles.avatarImg}
            src={props.user?.profilePicture ?? online_image2}
          />
          <Typography
            sx={{
              ...styles.username,
              color: theme === "black" ? "white" : "black",
            }}
          >
            {props.user.name}
          </Typography>
        </Stack>
        <Typography
          sx={{ ...styles.desc, color: theme === "black" ? "white" : "black" }}
        >
          {cropText(description, 80)}
          {description.length >= 75 && (
            <span
              style={{
                color: theme === "white" ? color_pallette.primary : "black",
              }}
            >
              read more..
            </span>
          )}
        </Typography>
        <Stack direction="row" alignItems="center" sx={styles.footer}>
          {stats.map((entry) => (
            <Typography
              key={entry}
              sx={{
                ...styles.stats,
                color: theme === "black" ? "white" : "black",
              }}
            >
              <span
                style={{
                  color: theme === "white" ? color_pallette.primary : "black",
                  fontWeight: "600",
                }}
              >
                {getStats(entry)}
              </span>{" "}
              <FormattedMessage id={entry} />
            </Typography>
          ))}
          <Stack direction="row" alignItems="center">
            <Typography
              sx={{
                fontSize: 13,
                color: theme === "black" ? "white" : "black",
              }}
            >
              <FormattedMessage id={messages.rating.id} />
            </Typography>
            <Rating
              sx={{
                ...styles.rating,
                backgroundColor: "white",
                borderRadius: 10,
                border: color_pallette.primary,
                color: color_pallette.primary,
              }}
              readOnly
              size="small"
              defaultValue={1}
              value={getRate()}
            />
            <Typography
              sx={{
                fontSize: 11,
                color: theme === "black" ? "white" : "black",
              }}
            >
              {getRate()}
            </Typography>
          </Stack>
        </Stack>
      </Box>
    </Grow>
  );
};

export default OnlineCardListItem;
