import { FC, useEffect, useRef } from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import UpcomingPodcasters from "components/upcoming_podcaster";
/* import UpcomingOnlineSection from "./upcoming_online_section"; */
import styles from "./styles";
/* import Button from "components/custom_button";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "navigation/routes"; */
import { Grid, Paper } from "@mui/material";
import WatchNowModal from "components/watch_now_modal";
import UpcomingOnlineSection from "./upcoming_online_section";
import Seperator from "common/seperator";
import { FormattedMessage } from "react-intl";
import messages from "./messages";

const UpcomingSection: FC = () => {
  /* const navigate = useNavigate(); */

  const upcomingOnlineSectionRef = useRef<any>(null);
  const upcomingOnlineSection2Ref = useRef<any>(null);
  const upcomingPodcastersRef = useRef<any>(null);

  /* const handleExploreUpcomingPodcasts = () => {
    navigate(NavigationRoutes.upcomingPodcasts);
  }; */

  useEffect(() => {}, []);

  if (
    upcomingOnlineSectionRef.current?.isEmpty &&
    upcomingOnlineSection2Ref.current?.isEmpty &&
    upcomingPodcastersRef.current?.isEmpty
  ) {
    return null;
  }

  return (
    <>
      <Typography sx={styles.sectionHeader}>
        <FormattedMessage id={messages.whatscoming.id} />
      </Typography>
      <Grid
        container
        sx={{ marginBlock: 5, justifyContent: "center" }}
        columnGap={2}
        rowGap={2}
      >
        <Grid item lg={5.5}>
          <Paper
            sx={{
              paddingY: { xs: 1, lg: 0.5 },
              overflow: "hidden",
              borderRadius: 8,
              boxShadow: 4,
            }}
          >
            <Typography sx={styles.blogTitle}>
              <FormattedMessage id={messages.upcoming_interactive.id} />
            </Typography>
            <UpcomingOnlineSection ref={upcomingOnlineSectionRef} />
            <Stack justifyContent="center" alignItems="center" direction="row">
              <Seperator />
            </Stack>
            <UpcomingOnlineSection ref={upcomingOnlineSection2Ref} />
          </Paper>
        </Grid>
        <Grid item lg={5.5}>
          <UpcomingPodcasters ref={upcomingPodcastersRef} />
        </Grid>
      </Grid>
      <WatchNowModal />
      {/* <Stack direction="row" width="92%" justifyContent="center">
        <Button
          label={"Explore More"}
          onClick={handleExploreUpcomingPodcasts}
          extraStyles={{ width: 150, marginTop: 5 }}
        />
      </Stack> */}
    </>
  );
};

export default UpcomingSection;
