import { Methods } from "types/http";
import apiUrls from "../urls";
import { Rate } from "./types";
import { GetById } from "../types";

export const postRate = (body: { token: string; data: Rate }) => ({
  url: apiUrls.postRate(),
  method: Methods.POST,
  body: body.data,
  headers: {
    Lng: "EN",
    Accept: "application/json",
    Authorization: `Bearer ${body.token}`,
  },
});

export const getVideoRate = (body: GetById) => ({
  url: apiUrls.getVideoRate(body.id),
  method: Methods.GET,
  headers: {
    Lng: "EN",
    Accept: "application/json",
    Authorization: `Bearer ${body.token}`,
  },
});

export const getVideoRateDetails = (body: GetById) => ({
  url: apiUrls.getVideoRatesDetails(body.id),
  method: Methods.GET,
  headers: {
    Lng: "EN",
    Accept: "application/json",
    Authorization: `Bearer ${body.token}`,
  },
});

export const getUserRate = (body: GetById) => ({
  url: apiUrls.rateUser(body.id),
  method: Methods.GET,
  headers: {
    Lng: "EN",
    Accept: "application/json",
    Authorization: `Bearer ${body.token}`,
  },
});
