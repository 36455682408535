import { FC } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import styles from "./styles";

type Props = {
  Icon: any;
  label: string;
  onClick?: () => void;
};

const ActionIcon: FC<Props> = ({ Icon, onClick, label }) => {
  return (
    <Box onClick={onClick} sx={styles.container}>
      {Icon}
      <Typography sx={{ marginInlineStart: 1 }} fontSize={{ xs: 13, lg: 13 }}>
        {label}
      </Typography>
    </Box>
  );
};

export default ActionIcon;
