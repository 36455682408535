import { Methods } from "types/http";
import { shareServiceUrls } from "./urls";
import { Share } from "./types";
import { GetById } from "../types";

export const submitShare = (param: { body: Share; token: string }) => ({
  url: shareServiceUrls.postShare(),
  method: Methods.POST,
  body: param.body,
  headers: {
    Lng: "EN",
    Accept: "*/*",
    "Content-type": "application/json",
    Authorization: `Bearer ${param.token}`,
  },
});

export const getVideoSharesCount = (param: GetById) => ({
  url: shareServiceUrls.getVideoSharesCount(param.id),
  method: Methods.GET,
  headers: {
    Lng: "EN",
    Accept: "*/*",
    "Content-type": "application/json",
    Authorization: `Bearer ${param.token}`,
  },
});

export const getUserSharesCount = (param: GetById) => ({
  url: shareServiceUrls.getUserSharesCount(param.id),
  method: Methods.GET,
  headers: {
    Lng: "EN",
    Accept: "*/*",
    "Content-type": "application/json",
    Authorization: `Bearer ${param.token}`,
  },
});
