import useDateRangePicker from "hooks/useDateRangePicker";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { FC } from "react";
import { DateRangePicker } from "react-dates";
import { DATES } from "types/date_picker";
import styles from "./styles";

const DateFilterSection: FC = () => {
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up("md"));

  const { startDate, endDate, handleDateChange, focused, setFocused } =
    useDateRangePicker();

  return (
    <Box sx={styles.root}>
      <DateRangePicker
        startDate={startDate}
        endDate={endDate}
        startDatePlaceholderText="From"
        endDatePlaceholderText="To"
        startDateId={DATES.startDate}
        endDateId={DATES.endDate}
        showDefaultInputIcon
        small
        keepFocusOnInput
        withPortal
        daySize={!upMd ? 26 : 40}
        isRTL={false}
        onDatesChange={(date) => handleDateChange(date)}
        focusedInput={focused.focused}
        onFocusChange={(focused) => setFocused({ focused })}
      />
    </Box>
  );
};

export default DateFilterSection;
