import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  iconsContainer: {
    marginTop: { xs: 1, md: 0.5, lg: 0 },
    display: "flex",
    flexDirection: "row",
    justifyContent: { xs: "center", md: "flex-start" },
    columnGap: 1.5,
    alignItems: "center",
  },
  iconSubTitle: {
    fontSize: {
      xs: 9,
      lg: 11,
    },
  },
  iconContainerStyle: {
    paddingBlock: {
      xs: 5,
      lg: 5.6,
    },
    paddingInline: {
      xs: 4,
      lg: 4.6,
    },
  },
  container: {},
  headerLabel: {
    fontWeight: "500",
    fontFamily: "Nizzar ,K2D, Inter",
    fontSize: {
      xs: 13,
      sm: 15,
    },
    textAlign: {
      xs: "center",
      sm: "center",
      md: "start",
    },
  },
  body: {
    textAlign: {
      xs: "start",
      sm: "center",
      md: "start",
    },
  },
  position: {
    fontWeight: "700",
    fontFamily: "Nizzar ,K2D, Inter",
    fontSize: {
      xs: 13,
      sm: 13,
      lg: 11,
      xl: 14,
    },
    marginTop: 0.5,
    textAlign: { xs: "center", md: "start" },
  },
  name: {
    fontWeight: "600",
    fontFamily: "Nizzar ,K2D, Inter",
    fontSize: {
      xs: 13,
      sm: 15,
      lg: 11,
      xl: 14,
    },
    textAlign: { xs: "center", md: "start" },
  },
  desc: {
    fontWeight: "400",
    fontFamily: "Nizzar ,K2D, Inter",
    fontSize: {
      xs: 12,
      sm: 12,
      lg: 11,
      xl: 14,
    },
    width: { xs: "60%", md: "100%" },
    textAlign: {
      xs: "center",
      sm: "center",
      md: "start",
    },
  },
};

export default styles;