import { FC } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import styles from "./styles";
import FormContainer from "common/form_container";
import InputField from "common/input_field";
import SubmitButton from "common/submit_button";
import { linkItems } from "./data";
import livetips from "assets/svgs/white_live_tips.svg";
import whitepolice from "assets/svgs/white_police.svg";
import whiteGov from "assets/svgs/white_gob.svg";
import { ReactSVG } from "react-svg";
import twitter from "assets/home/footer/twitter.svg";
import instagram from "assets/home/footer/instagram.svg";
import facebook from "assets/home/footer/facebook.svg";
import linkedin from "assets/home/footer/linkedin.svg";

const MobileFooter: FC = () => {
  return (
    <Box sx={styles.container}>
      <Box sx={styles.footerIntro}>
        <Typography sx={styles.colorWhite}>About Live Tips</Typography>
        <Typography sx={styles.colorWhite}>
          Live tips is an initiative initiated by the College and University
          Council of Dubai Police to allow for University Students to
          participate in informative and awareness content for the purpose of
          self- development, motivation and excellence.The Platform is used to
          provide online based content in the form of Online Sessions where
          speakers from different backgrounds can attend to provide their
          knowledge and insight on certain topics as well as success stories
          which boost the morality of university students to greater heights
        </Typography>
      </Box>
      <Stack
        alignItems="center"
        sx={styles.footerContainer}
        direction="column"
        flexGrow={1}
      >
        <Box>
          <Typography sx={{ textAlign: "start", ...styles.colorWhite }}>
            NOT QUITE READY FOR SURVEY ?
          </Typography>
          <Typography sx={{ textAlign: "start", ...styles.colorWhite }}>
            join our online no-community for free. no spam,ever.
          </Typography>
        </Box>
        <Stack
          direction="row"
          alignItems="center"
          columnGap={{ xs: 2 }}
          justifyContent="space-around"
          width={{ xs: "80%", sm: "55%" }}
        >
          <FormContainer
            initialValues={{}}
            validationSchema={{}}
            onSubmit={() => {}}
          >
            <InputField
              placeholder="enter your email"
              name="subscribe_to_email"
              extraStyles={{ width: "70%" }}
            />
            <SubmitButton extraStyles={{ height: 55 }} title="Subscribe" />
          </FormContainer>
        </Stack>
        <Grid
          container
          width={"80%"}
          marginTop={5}
          justifyContent="center"
          textAlign="center"
          gap={3}
        >
          {linkItems.map((entry) => (
            <Grid
              sx={{ ...styles.colorWhite, cursor: "pointer" }}
              xs={5}
              key={entry.label}
              item
            >
              {entry.label}
            </Grid>
          ))}
        </Grid>
        <Box textAlign="center" sx={{ marginTop: 3 }}>
          <ReactSVG src={livetips} />
          <Stack
            alignItems="center"
            columnGap={4}
            sx={{ marginTop: 2 }}
            direction="row"
          >
            <ReactSVG src={whitepolice} />
            <ReactSVG src={whiteGov} />
          </Stack>
        </Box>
        <Stack>
          <Typography sx={styles.colorWhite}>
            Any Queries? Visit us on WWW.LVETIPS.COM
          </Typography>
          <Stack
            width="80%"
            justifyContent="space-around"
            direction="row"
            alignItems="center"
            marginX={"auto"}
            marginTop={3}
          >
            <ReactSVG src={instagram} />
            <ReactSVG src={twitter} />
            <ReactSVG src={linkedin} />
            <ReactSVG src={facebook} />
          </Stack>
          <Typography
            sx={{ ...styles.colorWhite, marginTop: 3, textAlign: "center" }}
          >
            © Live Tips - All Rights Reserved
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

export default MobileFooter;
