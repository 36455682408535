import { FC, useMemo } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grow from "@mui/material/Grow";
import { SubMenuType } from "../menu_items/data";
import { useNavigate } from "react-router-dom";
import { globalSliceActions } from "store/global_slice";
import { useAppDispatch, useAppSelector } from "store/hooks";
import styles from "./styles";
/* import { UploadedFileStatus } from "types/uploaded_file_status";
 */import { NavigationRoutes } from "navigation/routes";
import { FormattedMessage } from "react-intl";
import { ROLE } from "types/role";
import { UploadedFileStatus } from "types/uploaded_file_status";

type Props = {
  subItems: Array<SubMenuType>;
};

const SubMenu: FC<Props> = ({ subItems }) => {
  const dispatch = useAppDispatch();
  const role = useAppSelector((state) => state.global.permission);

  const { status } = useAppSelector((state) => state.global.speakerProfile);

  const filteredSubItems = useMemo(() => {
    const newItems = subItems.filter((entry) => {
      if (entry.path === NavigationRoutes.joinUs) {
        return status === UploadedFileStatus.PENDING ? true : false;
      }
      return true;
    });

    if (role == ROLE.normal)
      return newItems.filter(
        (entry) => entry.path !== NavigationRoutes.employees
      );
    return newItems;
  }, [subItems, status]);

  const navigate = useNavigate();

  const handleNavigation = ({ label, path }: SubMenuType) => {
    navigate(path);
    dispatch(globalSliceActions.setActiveMenuItem(label));
  };

  return (
    <Grow in timeout={700}>
      <Box className="speakers-submenu-content" sx={styles.menu}>
        {filteredSubItems.map((entry) => (
          <Box key={entry.label} onClick={() => handleNavigation(entry)}>
            <Typography sx={styles.item}>
              <FormattedMessage id={entry.label} />
            </Typography>
          </Box>
        ))}
      </Box>
    </Grow>
  );
};

export default SubMenu;
