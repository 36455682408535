import OnlineItem from "types/online_item";
import rootApi from "..";
import { submitOnlineEventAC } from "./action_creators";
import apiUrls from "../urls";

export const onlineSliceApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getOnlineEvents: builder.query<Array<OnlineItem>, void>({
      query: () => apiUrls.getOnlineEvents(),
    }),
    submitOnlineEvent: builder.mutation<OnlineItem, OnlineItem>({
      query: submitOnlineEventAC,
    }),
  }),
});

export const {
  useGetOnlineEventsQuery,
  useLazyGetOnlineEventsQuery,
  useSubmitOnlineEventMutation,
} = onlineSliceApi;
