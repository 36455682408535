import * as yup from "yup";

export enum COMMENT_REPLY_FORM {
  body = "body",
}

const validationSchema: yup.Schema = yup.object({
  [COMMENT_REPLY_FORM.body]: yup.string().required().max(200),
});

const initialValues: yup.InferType<typeof validationSchema> = {
  [COMMENT_REPLY_FORM.body]: "",
};

export { validationSchema, initialValues };
