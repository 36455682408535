import { defineMessages } from "react-intl";

const scope = "MyBookmarks";

export default defineMessages({
  no_bookmarks: {
    id: `${scope}.no_bookmarks`,
    defaultMessage: "No Bookmarks Were Saved",
  },
  saved: {
    id: `${scope}.saved`,
    defaultMessage: "Saved",
  },
  home_saved: {
    id: `${scope}.home_saved`,
    defaultMessage: "Home",
  },
  online_videos_saved: {
    id: `${scope}.online_videos_saved`,
    defaultMessage: "Online Videos",
  },
  podcasts_saved: {
    id: `${scope}.podcasts_saved`,
    defaultMessage: "Podcasts",
  },
});
