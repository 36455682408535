import { FC } from "react";
import styles from "./styles";
import { Typography } from "@mui/material";
import { substractDates, TIME_UNIT } from "utils/date_subtracter";

type Props = {
  publishDate: string;
};

const PublishedDate: FC<Props> = ({ publishDate }) => {
  const normalizeDate = (result: number): string => {
    let append: string = "";

    if (result < 24)
      append = `${substractDates(publishDate, TIME_UNIT.HOUR)} Hour ago`;

    if (result >= 24)
      append = `${substractDates(publishDate, TIME_UNIT.DAY)} Day ago`;

    /* 730 is how many hours in month */
    if (result >= 730)
      append = `${substractDates(publishDate, TIME_UNIT.MONTH)} Month ago`;

    /* 8760 is how many hours in year */
    if (result >= 8760)
      append = `${substractDates(publishDate, TIME_UNIT.MONTH)} Year ago`;

    return append;
  };

  const getDate = (): string => {
    let result: number = 0;

    if (publishDate) {
      result = substractDates(publishDate);
    }

    return normalizeDate(result);
  };

  return <Typography sx={styles.datePublished}>{getDate()}</Typography>;
};

export default PublishedDate;
