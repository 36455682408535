import { FC } from "react";
import { Avatar, Checkbox, Stack, Typography } from "@mui/material";
import styles from "./styles";
import { useAppDispatch } from "store/hooks";
import { CheckedMember, MembersSliceActions } from "store/members_slice";

const SpeakerCheckboxListItem: FC<CheckedMember> = (props) => {
  const dispatch = useAppDispatch();

  const handleToggleCheck = (speaker: CheckedMember) => {
    dispatch(MembersSliceActions.inviteSpeaker(speaker));
  };

  return (
    <Stack direction="row" columnGap={2} sx={styles.container}>
      <Checkbox
        onSelect={() => handleToggleCheck(props)}
        checked={props.checked}
      />
      <Avatar sx={styles.avatar} src={props.profile_picture} />
      <Stack direction="column">
        <Typography sx={styles.name}>{props.name}</Typography>
        <Typography sx={styles.org}>{props.org}</Typography>
      </Stack>
    </Stack>
  );
};

export default SpeakerCheckboxListItem;
