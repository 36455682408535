import OnlineItem, { Status } from "types/online_item";
import image1 from "assets/images/online_image4.webp";
import online_image2 from "assets/images/online_image1.webp";
import online_image3 from "assets/images/online_image2.webp";
import online_image4 from "assets/images/online_image3.webp";
import online_image5 from "assets/images/online_image4.webp";
import online_image1 from "assets/images/online_image1.webp";

const data: Array<OnlineItem> = [
  {
    id: "210",
    status: Status.APPROVED,
    captionText:
      "How to Increase you employement chances after graduation and after high school",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, commodi!.",
    image: image1,
    ratings: 4.3,
    speakerAvatar: image1,
    speakerName: "Trainer Aisha Al Naqbi",
    stats: {
      views: 12315,
      saved: 12312,
      shared: 1236,
    },
  },
  {
    id: "212",
    status: Status.APPROVED,
    captionText:
      "How to Increase you employement chances after graduation and after high school",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, commodi!.",
    image: online_image1,
    ratings: 4.3,
    speakerAvatar: online_image1,
    speakerName: "Trainer Aisha Al Naqbi",
    stats: {
      views: 12315,
      saved: 12312,
      shared: 1236,
    },
  },
  {
    id: "21",
    status: Status.APPROVED,
    captionText:
      "How to Increase you employement chances after graduation and after high school",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, commodi!.",
    image: online_image2,
    ratings: 4.3,
    speakerAvatar: online_image2,
    speakerName: "Trainer Aisha Al Naqbi",
    stats: {
      views: 12315,
      saved: 12312,
      shared: 1236,
    },
  },
  {
    id: "22",
    status: Status.APPROVED,
    captionText:
      "How to Increase you employement chances after graduation and after high school",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, commodi!.",
    image: online_image3,
    ratings: 4.3,
    speakerAvatar: online_image3,
    speakerName: "Trainer Aisha Al Naqbi",
    stats: {
      views: 12315,
      saved: 12312,
      shared: 1236,
    },
  },
  {
    id: "32",
    status: Status.APPROVED,
    captionText:
      "How to Increase you employement chances after graduation and after high school",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, commodi!.",
    image: online_image4,
    ratings: 4.3,
    speakerAvatar: online_image4,
    speakerName: "Trainer Aisha Al Naqbi",
    stats: {
      views: 12315,
      saved: 12312,
      shared: 1236,
    },
  },
  {
    id: "3",
    status: Status.APPROVED,
    captionText:
      "How to Increase you employement chances after graduation and after high school",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, commodi!.",
    image: online_image4,
    ratings: 4.3,
    speakerAvatar: online_image4,
    speakerName: "Trainer Aisha Al Naqbi",
    stats: {
      views: 12315,
      saved: 12312,
      shared: 1236,
    },
  },
  {
    id: "1",
    status: Status.APPROVED,
    captionText:
      "How to Increase you employement chances after graduation and after high school",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, commodi!.",
    image: online_image5,
    ratings: 4.3,
    speakerAvatar: online_image5,
    speakerName: "Trainer Aisha Al Naqbi",
    stats: {
      views: 12315,
      saved: 12312,
      shared: 1236,
    },
  },
  {
    id: "5",
    status: Status.APPROVED,
    captionText:
      "How to Increase you employement chances after graduation and after high school",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, commodi!.",
    image: online_image3,
    ratings: 4.3,
    speakerAvatar: online_image3,
    speakerName: "Trainer Aisha Al Naqbi",
    stats: {
      views: 12315,
      saved: 12312,
      shared: 1236,
    },
  },
];

export default data;
