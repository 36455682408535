import { FC } from "react";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import styles from "./styles";
import MetaSection from "./meta_section";
/* import ActionSection from "./action_section"; */
import UpcomingMeta from "components/upcoming_meta";
import Chronometer from "components/chronometer";
import useTime from "hooks/useTime";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "store/hooks";
import { useGetLiveStreamingsByIdQuery } from "store/api/streaming_service/endpoints";
import { UploadedFileStatus } from "types/uploaded_file_status";
import { NAMESPACES } from "store/api/namespaces";
import { getUpcomingDate } from "utils/get_upcoming_date";
import { NavigationRoutes } from "navigation/routes";

const UpcomingDetails: FC = () => {
  const { access_token } = useAppSelector(
    (state) => state.global.credentials.token
  );

  const id = useParams();

  const { state } = useLocation();

  const { data } = useGetLiveStreamingsByIdQuery(
    {
      token: access_token,
      query: {
        is_live: false,
        is_expired: false,
        endpoint: state ? state.endpoint : NAMESPACES.video,
        id: id!.toString(),
        status: UploadedFileStatus.ACCEPTED,
      },
    },
    {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
    }
  );

  const upcoming = data?.results[0];

  const time = useTime(getUpcomingDate(upcoming?.scheduled_on ?? ""));

  const navigate = useNavigate();

  const handleTimeExpires = (seconds: number) => {
    if (seconds <= 5)
      navigate(`${NavigationRoutes.streaming_online_page}/${upcoming?.id}`);
  };

  if (!upcoming) return null;

  return (
    <>
      <Box sx={styles.root}>
        <Box sx={styles.container}>
          <Stack sx={styles.wrapper}>
            <MetaSection
              profileId={upcoming.user.customerId}
              thumb={upcoming.thumbnail}
              rating={upcoming.rating}
            />
            <Stack rowGap={2} sx={styles.contentContainer}>
              <Stack
                direction={{ xs: "column", md: "row" }}
                justifyContent="space-between"
                alignItems="center"
                columnGap={3}
              >
                <Stack direction="row" columnGap={1} alignItems="center">
                  <Avatar
                    src={upcoming.user.profilePicture}
                    sx={styles.avatar}
                  />
                  <Typography sx={styles.username}>
                    {upcoming.user.name}
                  </Typography>
                </Stack>
                <Chronometer
                  labelStyles={styles.timeLabel}
                  timeBoxStyle={styles.timebox}
                  expiryTimestamp={time}
                  onTimeExpires={handleTimeExpires}
                />
              </Stack>
              <Typography sx={styles.subTitle}>{upcoming.user.org}</Typography>
              <Typography sx={styles.caption}>{upcoming.title}</Typography>
              {/* <ActionSection
                playing={false}
                podcastId={"93"}
                duration={`${(Math.ceil(1300) / 60).toPrecision(2)} Mins`}
                handleToggleAudio={() => {}}
              /> */}
              <Typography sx={styles.description}>
                {upcoming.description}
              </Typography>
              <UpcomingMeta entry={upcoming} />
            </Stack>
          </Stack>
        </Box>
      </Box>
    </>
  );
};

export default UpcomingDetails;
