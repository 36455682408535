import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  button: {
    border: "0.7px solid black",
    color: "black",
    whiteSpace: "nowrap",
    borderRadius: 1.5,
    fontFamily: "Nizzar ,K2D, Inter",
    fontSize: {
      sm: 13,
    },
    ":hover": {
      backgroundColor: "white",
    },
    fontWeight: "300",
  },
  ratingLabel: {
    fontSize: {
      sm: 13,
    },
    whiteSpace: "nowrap",
  },
  image: {
    width: {
      xs: 350,
      sm: 420,
      lg: 280,
      xl: 350,
    },
    borderRadius: 3,
    aspectRatio: "4 / 4",
  },
  headerFooter: {
    display: "flex",
    alignItems: "center",
    marginTop: {
      xs: 1.5,
    },
    justifyContent: {
      xs: "space-around",
      lg: "center",
    },
    columnGap: {
      lg: 2,
    },
    width: {
      xs: "100%",
    },
  },
  container: {
    width: "fit-content",
    display: "flex",
    paddingInline: {
      md: 1.5,
    },
    paddingBlock: 2,
    paddingInlineStart: {
      lg: 0,
    },
    flexDirection: "column",
    alignItems: {
      xs: "center",
      lg: "flex-start",
    },
    marginX: "auto",
    rowGap: 1,
  },
};

    export default styles;