import { FC } from "react";
import Box from "@mui/material/Box";
import styles from "./styles";
import ChatListItem from "../chat_list_item";
import { useAppSelector } from "store/hooks";

const ChatStream: FC = () => {
  const chatList = useAppSelector((state) => state.chat.chatHistory);

  return (
    <Box sx={styles.container}>
      {chatList.map((entry) => (
        <ChatListItem key={entry.id?.toString()} {...entry} />
      ))}
    </Box>
  );
};

export default ChatStream;
