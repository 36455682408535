import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    marginTop: { xs: 3, lg: 0 },
    marginInline: { xs: 1.5, lg: 0 },
    paddingInlineStart: { xs: 1.5, lg: 0, xl: 2 },
    paddingBlock: 2,
    maxWidth: { xs: 400, sm: 450, lg: 325, xl: 340 },
    minWidth: { xs: 400, sm: 450, lg: 325, xl: 340 },
    marginX: "auto",
    order: {
      md: 0,
      lg: 3,
    },
  },
  sectionLabel: { fontWeight: "600", fontSize: { lg: 23 } },
};

export default styles;
