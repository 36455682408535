import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { useAppSelector } from "store/hooks";
import "theme/css/date_picker_override.css";
import LanguageProvider from "translation/provider";
import { pages } from "navigation/data";
// import { AgoraRTCProvider, useRTCClient } from "agora-rtc-react";
// import AgoraRTC from "agora-rtc-sdk-ng";

const App: FC = () => {
  const local = useAppSelector((state) => state.global.lang);

  // const agoraEngine = useRTCClient(
  //   AgoraRTC.createClient({
  //     mode: "rtc",
  //     codec: "vp8",
  //   }) as any
  // );

  return (
    // <AgoraRTCProvider client={agoraEngine}>
      <LanguageProvider local={local.prefix}>
        <Routes>
          {pages.map((entry) => (
            <Route
              key={entry.id}
              element={<entry.Element />}
              path={entry.path}
            />
          ))}
        </Routes>
      </LanguageProvider>
    // </AgoraRTCProvider>
  );
};

export default App;