import { defineMessages } from 'react-intl';

const scope = 'GeneralInfoForm';

export default defineMessages({
  city: {
    id: `${scope}.city`,
    defaultMessage: "City",
  },
  country: {
    id: `${scope}.country`,
    defaultMessage: "Country",
  },
});