import { FC } from "react";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import styles from "./styles";

const PodcastDetailSkeleton: FC = () => {
  return (
    <Box sx={styles.headerContainer}>
      <Skeleton sx={styles.thumbImage} />
      <Stack direction="column" flexGrow={0.6} marginTop={5}>
        <Skeleton sx={{ height: 30, width: "30%" }} />
        <Skeleton sx={{ height: 70, width: "80%" }} />
        <Skeleton sx={{ height: 30, width: "50%" }} />
        <Skeleton sx={{ height: 200, width: "70%" }} />
      </Stack>
    </Box>
  );
};

export default PodcastDetailSkeleton;
