import online_image4 from "assets/images/online_image3.webp";
import podcast1 from "assets/images/colonel.webp";
import online_image3 from "assets/images/online_image3.webp";
import online_image2 from "assets/images/online_image2.webp";
import online_image5 from "assets/images/online_image5.webp";
import UpcomingPodcast from "types/upcoming_podcast";

const data: Array<UpcomingPodcast> = [
  {
    id: "20",
    title: "Lorem, ipsum dolor sit amet consectetur adipisicing elit.",
    podcasterPosition:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit.",
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, commodi!.",
    podcasterImage: podcast1,
    rating: 4.3,
    podcasterName: "Trainer Aisha Al Naqbi",
    timestamp: 33511,
  },
  {
    id: "213",
    title: "Lorem, ipsum dolor sit amet consectetur adipisicing elit.",
    podcasterPosition:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit.",
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, commodi!.",
    podcasterImage: online_image3,
    rating: 4.3,
    podcasterName: "Trainer Aisha Al Naqbi",
    timestamp: 50050,
  },
  {
    id: "1111",
    title: "Lorem, ipsum dolor sit amet consectetur adipisicing elit.",
    podcasterPosition:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit.",
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, commodi!.",
    podcasterImage: online_image5,
    rating: 4.3,
    podcasterName: "Trainer Aisha Al Naqbi",
    timestamp: 5004,
  },
  {
    id: "3244",
    title: "Lorem, ipsum dolor sit amet consectetur adipisicing elit.",
    podcasterPosition:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit.",
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, commodi!.",
    podcasterImage: online_image4,
    rating: 4.3,
    podcasterName: "Trainer Aisha Al Naqbi",
    timestamp: 50504,
  },
  {
    id: "35514",
    title: "Lorem, ipsum dolor sit amet consectetur adipisicing elit.",
    podcasterPosition:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit.",
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, commodi!.",
    podcasterImage: online_image2,
    rating: 4.3,
    podcasterName: "Trainer Aisha Al Naqbi",
    timestamp: 5005,
  },
];

export default data;
