import { FC } from "react";
import styles from "./styles";
import about_comment from "assets/images/about_comment.png";
import about_event from "assets/images/about_event.png";
import { Box, Divider, Paper, Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import messages from "./messages";

const Bubble: FC = () => {
  return (
    <Paper
      sx={{
        boxShadow: 3,
        borderRadius: 5,
        paddingInline: 3,
        paddingBlock: 1,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        columnGap: 1,
      }}
    >
      <Box component="img" src={about_comment} />
      <Typography sx={{ whiteSpace: "nowrap", fontWeight: "300" }}>
        <FormattedMessage id={messages.make.id} />
        <span style={{ textDecoration: "underline", fontWeight: "600" }}>
          {""}
          <FormattedMessage id={messages.question.id} />
        </span>
      </Typography>
    </Paper>
  );
};

const EventSection: FC = () => {
  return (
    <Box>
      <Stack direction="row" alignItems="center" sx={styles.container}>
        <Divider sx={{ width: "32%" }} />
        <Bubble />
        <Divider sx={{ width: "32%" }} />
      </Stack>
      <Stack
        direction="row"
        width="90%"
        marginX="auto"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box component="img" src={about_event} />
        <Stack width="50%" rowGap={2} direction="column">
          <Typography sx={{ fontSize: { lg: 40 }, fontWeight: "400" }}>
            <FormattedMessage id={messages.event_location_title.id} />
          </Typography>
          <Typography
            sx={{ fontSize: { lg: 25 }, width: "80%", fontWeight: "300" }}
          >
            <FormattedMessage id={messages.event_location.id} />
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

export default EventSection;
