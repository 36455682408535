import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type State = {
  rateValue: number;
  prevValue: number;
  userRate: number;
  totalRatings: number;
  rateFilterValue: number;
};

const initialState: State = {
  rateValue: 0,
  totalRatings: 0,
  userRate: 0,
  prevValue: 0,
  rateFilterValue: 0,
};

const RatingSlice = createSlice({
  name: "ratingSlice",
  initialState,
  reducers: {
    submitRate: (state: State, { payload }: PayloadAction<number>) => {
      state.rateValue = payload;
      return state;
    },
    submitPresentRate: (state: State, { payload }: PayloadAction<number>) => {
      state.rateValue = payload;
      return state;
    },
    setRateFilterValue: (state: State, { payload }: PayloadAction<number>) => {
      state.rateFilterValue = payload;
      return state;
    },
    submitTotalRatings: (state: State, { payload }: PayloadAction<number>) => {
      state.totalRatings = payload;
      return state;
    },
    revertRate: (state: State) => {
      state.rateValue = state.prevValue;
      state.prevValue = 0;
      state.totalRatings = state.totalRatings - 1;
      state.userRate = 0;
      return state;
    },
    clear: (state: State) => {
      state.rateValue = 0;
      state.prevValue = 0;
      state.totalRatings = 0;
      state.userRate = 0;
      return state;
    },
  },
});

export default RatingSlice.reducer;
export const RatingSliceActions = RatingSlice.actions;
