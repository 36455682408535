import { SxProps } from "@mui/material";
import color_pallette from "theme/color_pallette";

const styles: Record<string, SxProps> = {
  container: {
    borderRadius: 3,
    cursor: "pointer",
    boxShadow: 0,
    backgroundColor: color_pallette.backgroundGray,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBlock: 1.5,
    paddingInline: 2,
  },
  slider: {
    marginBlock: 2,
    flexGrow: 0.8,
    alignSelf: "start",
    width: "60%",
  },
  cursor: {
    cursor: "pointer",
    width: "100%",
  },
  starIcon: {
    color: color_pallette.primary,
    width: 15,
    height: 15,
    marginInlineStart: 3,
  },
  rating: {
    cursor: "pointer",
    width: "100%",
    border: "2px solid red",
    marginTop: 1.5,
  },
};

export default styles;
