import { defineMessages } from 'react-intl';

const scope = 'FormSection';

export default defineMessages({
  speaker_form: {
    id: `${scope}.speaker_form`,
    defaultMessage: "Speaker Form",
  },
  fill_form: {
    id: `${scope}.fill_form`,
    defaultMessage:
      "Please utilize this form to fill your information as speaker for an upcoming Live Tips.",
  },
});