import { Methods } from "types/http";
import chatUrls from "../urls";
import { Identification } from "types/identification";
import { ChatMessage, GetParticipantsArgs } from "../types";
import { Authorization } from "store/api/types";

export const getChatMessageById = ({
  id,
  auth,
}: {
  id: Identification;
  auth: Authorization;
}) => ({
  url: chatUrls.chatMessageById(id),
  method: Methods.GET,
  headers: {
    Lng: "EN",
    "Content-type": "application/json",
    Accept: "*/*",
    Authorization: `Bearer ${auth.token}`,
  },
});

export const getChatMessages = ({
  auth,
  args,
}: {
  auth: Authorization;
  args: Partial<GetParticipantsArgs>;
}) => ({
  url: chatUrls.getChatMessages(args),
  method: Methods.GET,
  headers: {
    Lng: "EN",
    "Content-type": "application/json",
    Accept: "*/*",
    Authorization: `Bearer ${auth.token}`,
  },
});

export const postChatMessage = ({
  body,
  auth,
}: {
  body: ChatMessage;
  auth: Authorization;
}) => ({
  url: chatUrls.postMessage(),
  method: Methods.POST,
  body,
  headers: {
    "Content-type": "application/json",
    Authorization: `Bearer ${auth.token}`,
  },
});