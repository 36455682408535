import { defineMessages } from 'react-intl';

const scope = 'BodySection';

export default defineMessages({
  views: {
    id: `${scope}.views`,
    defaultMessage: "Views",
  },
  shares: {
    id: `${scope}.shares`,
    defaultMessage: "Shares",
  },
  editBio: {
    id: `${scope}.editBio`,
    defaultMessage: "Edit Bio",
  },
  rate: {
    id: `${scope}.rate`,
    defaultMessage: "Rate",
  },
  rating: {
    id: `${scope}.rating`,
    defaultMessage: "Rating",
  },
});