import { SxProps } from "@mui/material";
import pallette from "theme/color_pallette";

const styles: Record<string, SxProps> = {
  button: {
    paddingX: 3,
    paddingBlock: 0.4,
    borderRadius: 2,
    backgroundColor: pallette.primary,
  },
};

export default styles;
