import { useState } from "react";
import { useGetMembershipListQuery } from "store/api/membership_service/endpoints";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { sidebarSliceActions } from "store/sidebar_slice";
import { Identification } from "types/identification";
import { UploadedFileStatus } from "types/uploaded_file_status";

const useSpeakers = () => {
  const dispatch = useAppDispatch();

  const [visible, setVisible] = useState<boolean>(false);

  const selected = useAppSelector((state) => state.sidebar.speakerFilter);

  const token = useAppSelector(
    (state) => state.global.credentials.token.access_token
  );

  const handleSpeakerChange = (value: Identification) => {
    if (value == selected) {
      dispatch(sidebarSliceActions.setSpeakerFilter(""));
      return;
    }
    dispatch(sidebarSliceActions.setSpeakerFilter(value));
  };

  const { data, isError } = useGetMembershipListQuery(
    {
      token,
      status: UploadedFileStatus.ACCEPTED,
    },
    { refetchOnFocus: true, refetchOnMountOrArgChange: true }
  );

  return {
    visible,
    data,
    handleSpeakerChange,
    isError,
    setVisible,
    selected,
  };
};

export default useSpeakers;
