import { FC } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useAppSelector } from "store/hooks";
import PodcastItemBookmarkWrapper from "components/podcast_item_bookmark_wrapper";
import styles from "./styles";
import messages from "../messages";
import { FormattedMessage } from "react-intl";

const PodcastBookmarksSection: FC = () => {
  const data = useAppSelector((state) => state.bookmarks.podcast);
  if (data.length == 0) return null;

  return (
    <Box sx={styles.container}>
      <Typography sx={styles.header}>
        <FormattedMessage id={messages.podcasts_saved.id} />
      </Typography>
      <Grid container rowGap={2} columnGap={1.5}>
        {data.map((entry) => (
          <Grid
            item
            xs={12}
            sm={7.3}
            md={9}
            lg={5}
            xl={3.4}
            key={entry.bookmarkId}
          >
            <PodcastItemBookmarkWrapper {...entry} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default PodcastBookmarksSection;
