import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    boxShadow: 10,
    borderRadius: 10,
    overflow: "hidden",
    width: { xs: 400, sm: 500, lg: 610, xl: 610 },
    height: "fit-content",
    paddingBottom: 2,
    zIndex: 10,
  },
  name: {
    fontWeight: "700",
    fontSize: { lg: 18 },
    marginBottom: -0.6,
  },
  ratingLabel: {
    fontSize: {
      xs: 11,
      md: 13,
    },
  },
  rate: {
    fontSize: {
      xs: 13,
    },
  },
  cardHeader: {
    background:
      "linear-gradient(100.26deg, #008755 16.92%, rgba(153, 191, 198, 0) 163.21%)",
    width: "100%",
    justifyContent: "flex-start",
    height: "fit-content",
    paddingInlineStart: 2,
    zIndex: 5,
    paddingBlock: 0.5,
  },
  cardHeaderLabel: {
    fontSize: { lg: 25 },
    marginInlineStart: 2,
    color: "white",
  },
  header: { fontSize: { lg: 25 } },
  img: {
    marginBlock: 0.5,
    width: { xs: "100%", lg: 610 },
    aspectRatio: "5 / 2",
    objectFit: "contain",
  },
  childrenWrapper: { textAlign: "start", paddingInlineStart: 3 },
};

export default styles;
