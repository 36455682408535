import { SxProps } from "@mui/material";
import color_pallette from "theme/color_pallette";

const styles: Record<string, SxProps> = {
  container: {
    padding: 1.5,
    backgroundColor: color_pallette.backgroundGray,
    borderRadius: 8,
    boxShadow: 0,
  },
  content: {
    fontSize: {
      xs: 13,
    },
  },
  createdAt: {
    fontSize: {
      xs: 13,
    },
    marginInlineStart: 5,
  },
  counter: {
    fontSize: {
      xs: 13,
    },
  },
  flex: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
};

export default styles;
