import { FC } from "react";
import { Box, Paper, Typography } from "@mui/material";
import styles from "./styles";
import PoliceMember from "types/police_member";

export type ExploreCardProps = {
  itemData: PoliceMember;
};

const ExploreMemberCard: FC<ExploreCardProps> = ({ itemData }) => {
  return (
    <Paper sx={styles.container}>
      <Box
        component="img"
        alt="ExploreMemberCard"
        src={itemData.image}
        sx={styles.img}
      />
      <Box sx={styles.contentContainer}>
        <Typography sx={styles.header}>{itemData.name}</Typography>
        <Typography sx={styles.position}>{itemData.position}</Typography>
      </Box>
    </Paper>
  );
};

export default ExploreMemberCard;
