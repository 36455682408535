import { defineMessages } from "react-intl";

const scope = "RegisterPage";

export default defineMessages({
  nomination: {
    id: `${scope}.nomination`,
    defaultMessage: "Nominations",
  },
  podcast: {
    id: `${scope}.podcast`,
    defaultMessage: "Podcast",
  },
  rate: {
    id: `${scope}.rate`,
    defaultMessage: "Rate",
  },
  employees: {
    id: `${scope}.employees`,
    defaultMessage: "Employees",
  },
  employees_desc: {
    id: `${scope}.employees_desc`,
    defaultMessage:
      "This service is intended to Dubai Police Employees, it follows the same principals as Live Tips Online but is only dedicated for employees",
  },
  podcast_desc: {
    id: `${scope}.podcast_desc`,
    defaultMessage:
      "An audio-based session that included multiple speakers whihn the session, it follows the standard rules of a podcast where we include images .....",
  },
  online: {
    id: `${scope}.online`,
    defaultMessage: "Online",
  },
  online_desc: {
    id: `${scope}.online_desc`,
    defaultMessage:
      "A service which provides online session for the mentioned above user groups by introducing different topics that may be of interest to either employees or students, different categories are supported for these sessions",
  },
  nomination_desc: {
    id: `${scope}.nomination_desc`,
    defaultMessage:
      "Allows users to nominate speakers through either a poll that is generated by the admin with a list of speakers or nominate a certain topic to be discussed.",
  },
});
