import * as yup from "yup";

export enum UserEditVideoFormNames {
  videoTitle = "title",
  description = "description",
  category = "category",
  exclusivity = "exclusivity",
}

const validationSchema: yup.Schema = yup.object({
  [UserEditVideoFormNames.videoTitle]: yup.string().required(),
  [UserEditVideoFormNames.description]: yup.string().required(),
  [UserEditVideoFormNames.category]: yup.string(),
  [UserEditVideoFormNames.exclusivity]: yup.string(),
});

const initialValues: yup.InferType<typeof validationSchema> = {
  [UserEditVideoFormNames.videoTitle]: "",
  [UserEditVideoFormNames.description]: "",
  [UserEditVideoFormNames.category]: "1",
  [UserEditVideoFormNames.exclusivity]: "1",
};

export { validationSchema, initialValues };
