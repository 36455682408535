import { ComponentType, useEffect } from "react";
import { useAppDispatch } from "store/hooks";
import { snackSliceActions } from "store/snack_slice";

function withCloseSnack(Component: ComponentType) {
  return () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
      return () => {
        dispatch(snackSliceActions.closeSnack());
      };
    }, [dispatch]);

    return <Component />;
  };
}

export default withCloseSnack;
