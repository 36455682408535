import { SxProps } from "@mui/material";
import color_pallette from "theme/color_pallette";

const styles: Record<string, SxProps> = {
  container: {
    width: { xs: 400, lg: 600 },
    height: { xs: 390, lg: 400 },
    marginX: "auto",
    marginTop: 15,
  },
  icon: {
    width: 40,
    cursor: "pointer",
    marginInlineEnd: 3,
    height: 40,
  },
  paper: {
    width: "100%",
    height: "100%",
    borderRadius: 5,
    paddingTop: 1,
    boxShadow: 3,
  },
  header: {
    fontSize: {
      lg: 23,
    },
    textAlign: "center",
    marginTop: 5,
    fontWeight: "600",
  },
  iconX: { height: 70, objectFit: "contain", width: 70 },
  typeWrapper: {
    backgroundColor: color_pallette.backgroundGray,
    width: { lg: 200, xs: 160 },
    paddingBlock: 2,
    borderRadius: 3,
  },
  checkbox: { width: 30, height: 30, marginInlineEnd: 1.5 },
  label: {
    fontSize: {
      xl: 12,
    },
    marginTop: 1.5,
  },
};

export default styles;
