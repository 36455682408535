import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    paddingInline: 2,
    paddingTop: { xs: 30, sm: 15 },
    position: "relative",
    marginTop: { xs: 30 },
    background:
      "linear-gradient(116.89deg, #E5E5E5 5.05%, #A1C7CD 26%, #5D8495 82.59%, #FF5757 125.77%);",
  },
  footerIntro: {
    textAlign: "center",
    borderRadius: 5,
    zIndex: 10,
    padding: 3,
    width: "80%",
    position: "absolute",
    top: -150,
    left: { xs: 50, sm: 70 },
    boxShadow: "0px 4px 20px 0px #00000040;",
    backgroundColor: "#008755",
  },
  footerContainer: {
    paddingBlock: 5,
    rowGap: 5,
  },
  colorWhite: {
    color: "white",
  },
};

export default styles;
