import { FC } from "react";
import Stack from "@mui/material/Stack";
import ReactionCounter from "components/reaction_counter";
import IconText from "pages/view_admin_profile/body_section/icon_text";
import shares from "assets/svgs/share_filled.svg";
import star from "assets/svgs/save.svg";
import viewed from "assets/svgs/view.svg";
import { useAppSelector } from "store/hooks";
import useLikes from "hooks/useLikes";
import ActionSection from "../action_section";
import styles from "./styles";
import { useIntl } from "react-intl";
import messages from "../messages";
import { usePostNotificationMutation } from "store/api/notification_service/endpoints";
import { NOTIFICATION_REPLY_TYPE } from "store/api/notification_service/types";
import { MEDIA_TYPE } from "types/media_type";
import useSnack from "hooks/useSnack";

const FooterSection: FC = () => {
  const { currentVideoReaction, currentVideoBookmarksCount, currentVideo } =
    useAppSelector((state) => state.livetipsOnline);

  const {
    credentials: { token },
    speakerProfile,
  } = useAppSelector((state) => state.global);

  const viewCount = useAppSelector((state) => state.view.views);

  const { likeVideo, dislikeVideo } = useLikes();

  const intl = useIntl();

  const [notify] = usePostNotificationMutation();

  const notifyUser = async () => {
    try {
      await notify({
        token: token.access_token,
        body: {
          actionType: NOTIFICATION_REPLY_TYPE.SHARE,
          digitalMediumType: MEDIA_TYPE.USER_PROFILE,
          receiverId: currentVideo.user.customerId,
          messageEn: `${speakerProfile.name} ${intl.formatMessage(
            messages.reacted
          )} `,
          triggeredBy: "USER",
          senderId: speakerProfile.customerId,
          senderName: speakerProfile.name,
          senderPic: speakerProfile.profilePicture,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const { triggerSnack } = useSnack();

  const handleLikeVideo = async () => {
    if (!token.access_token) {
      triggerSnack("You Must Login !", "error");
      return;
    }
    await notifyUser();
    likeVideo();
  };

  const handleDislikeLikeVideo = async () => {
    if (!token.access_token) {
      triggerSnack("You Must Login !", "error");
      return;
    }
    await notifyUser();
    dislikeVideo();
  };

  return (
    <Stack sx={styles.statsContainer}>
      <Stack justifyContent="center" direction="row" columnGap={1.3}>
        <IconText
          iconPath={viewed}
          label={`${viewCount} ${intl.formatMessage(messages.views)}`}
        />
        <IconText
          iconPath={shares}
          label={`${currentVideo.shares_count} ${intl.formatMessage(
            messages.share
          )}`}
        />
        <IconText
          iconPath={star}
          label={`${currentVideoBookmarksCount} ${intl.formatMessage(
            messages.saved
          )}`}
        />
      </Stack>
      <ReactionCounter
        reaction={currentVideoReaction?.userReact}
        likes={currentVideoReaction.likesCount}
        dislikes={currentVideoReaction.dislikesCount}
        onLike={handleLikeVideo}
        onDisLike={handleDislikeLikeVideo}
      />
      <ActionSection />
    </Stack>
  );
};

export default FooterSection;
