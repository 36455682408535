import { FC, useContext } from "react";
import Stack from "@mui/material/Stack";
import styles from "./styles";
import schedule from "assets/images/schedule.webp";
import instant from "assets/images/instant.webp";
import on_demand from "assets/images/on_demand.webp";
import TypeWrapper from "../type_wrapper";
import { SCHEDULE_TYPE, ScheduleContext } from "context/schedule_context";
import { FormikValues, useFormikContext } from "formik";
import { PODCAST_PREMIER_FORM } from "../form";
import { useAppSelector } from "store/hooks";
import { ROLE } from "types/role";

const PodcastScheduleSection: FC = () => {
  const { setShowSchedule } = useContext(ScheduleContext);

  const { setFieldValue } = useFormikContext<FormikValues>();

  const handleCheck = (type: SCHEDULE_TYPE) => {
    setShowSchedule(type);
    setFieldValue(PODCAST_PREMIER_FORM.TYPE, type);
  };

  const { role } = useAppSelector((state) => state.global.speakerProfile);

  return (
    <>
      <Stack
        direction="row"
        columnGap={2}
        alignItems="center"
        sx={styles.container}
      >
        <TypeWrapper
          name={SCHEDULE_TYPE.PRERECORDED}
          onCheck={() => handleCheck(SCHEDULE_TYPE.PRERECORDED)}
          label="Pre Recorded Podcast"
          img={on_demand}
        />
        {role != ROLE.normal && (
          <TypeWrapper
            name={SCHEDULE_TYPE.INSTANT}
            onCheck={() => handleCheck(SCHEDULE_TYPE.INSTANT)}
            label="Instant Podcast"
            img={instant}
          />
        )}
        <TypeWrapper
          name={SCHEDULE_TYPE.SCHEDULED}
          onCheck={() => handleCheck(SCHEDULE_TYPE.SCHEDULED)}
          label="Schedule Podcast"
          img={schedule}
        />
      </Stack>
    </>
  );
};

export default PodcastScheduleSection;
