import { FC } from "react";
import PodcastWrapper from "components/podcast_wrapper";
import { UploadedFileStatus } from "types/uploaded_file_status";

type Props = {
  category: number;
  sectionHeader: string;
};

const SectionByCategory: FC<Props> = ({ category, sectionHeader }) => {
  return (
    <PodcastWrapper
      sectionLabel={sectionHeader}
      dimensions={{
        xs: 12,
        sm: 6,
        lg: 5.8,
        xl: 4.5,
      }}
      videoQuery={{ category, status: UploadedFileStatus.ACCEPTED }}
    />
  );
};

export default SectionByCategory;
