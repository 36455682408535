import { FC } from "react";
import Box from "@mui/material/Box";
import styles from "./styles";
import { Grow, Typography } from "@mui/material";
import Button from "components/custom_button";
import { FormattedMessage, useIntl } from "react-intl";
import messages from "components/appbar/account_section/messages";

type Props = {
  handleSignout: () => void;
  handleCancel: () => void;
  isVisible: boolean;
};

const SignoutConfirmation: FC<Props> = ({
  handleSignout,
  handleCancel,
  isVisible,
}) => {
  const intl = useIntl();

  return (
    <Grow in={true} timeout={700}>
      <Box
        visibility={isVisible ? "visible" : "hidden"}
        sx={styles.accountMenu}
      >
        <Typography sx={{ fontSize: { lg: 15 }, fontWeight: "700" }}>
          <FormattedMessage id={messages.signout_confirmation.id} />
        </Typography>
        <Button
          onClick={handleSignout}
          extraStyles={{ width: "100%" }}
          label={intl.formatMessage(messages.signout)}
        />
        <Button
          onClick={handleCancel}
          extraStyles={{
            width: "100%",
            ":hover": {
              backgroundColor: "white",
            },
          }}
          label={"Cancel"}
          variant="outlined"
        />
      </Box>
    </Grow>
  );
};

export default SignoutConfirmation;
