type OnlineItem = {
  id: string;
  image: string;
  captionText: string;
  speakerName: string;
  speakerAvatar: string;
  description: string;
  ratings: number;
  status: Status;
  stats: {
    views: string | number;
    saved: string | number;
    shared: string | number;
  };
};

export enum Status {
  APPROVED = "approved",
  REJECTED = "rejected",
  PENDING = "pending",
}

export default OnlineItem;
