import { FC, useState } from "react";
import Stack from "@mui/material/Stack";
import styles from "./styles";
import appLogo from "assets/svgs/logo_green.svg";
import { ReactSVG } from "react-svg";
import Menu from "@mui/icons-material/Menu";
import Close from "@mui/icons-material/Close";
import LanguagePicker from "components/language_picker";
import BodySection from "./body_section";

const MobileAppbar: FC = () => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={styles.container}
      >
        {open && <LanguagePicker />}
        <ReactSVG src={appLogo} />
        {open ? (
          <Close sx={styles.cursor} onClick={() => setOpen(!open)} />
        ) : (
          <Menu sx={styles.cursor} onClick={() => setOpen(!open)} />
        )}
      </Stack>
      {open && <BodySection />}
    </>
  );
};

export default MobileAppbar;
