import { ProfileSliceActions } from "store/profile_slice";
import { UploadedFileStatus } from "types/uploaded_file_status";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useState } from "react";
import { LivetipsPodcastSliceActions } from "store/livetips_podcast_slice";
import { uploadPodcastFormData } from "store/api/podcast_api/action_creators";
import useSnack from "./useSnack";

const usePodcastUpload = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showPreview, setShowPreview] = useState<boolean>(false);

  const [uploadedVideoId, setUploadedVideoId] = useState<number>(0);

  const { file, meta } = useAppSelector(
    (state) => state.adminProfil.onlineTips
  );

  const { triggerSnack } = useSnack();

  const token = useAppSelector(
    (state) => state.global.credentials.token.access_token
  );

  const dispatch = useAppDispatch();

  const handleChangeStatus = async (status: UploadedFileStatus) => {
    setIsLoading(true);
    triggerSnack("Uploading... !", "info");

    dispatch(ProfileSliceActions.changeUploadedVideoStatus(status));
    const { data, error } = await uploadPodcastFormData({
      media: file.file,
      category: meta.category,
      description: meta.description,
      thumbnail: meta.thumbnail,
      privacy: meta.exclusivity,
      status,
      title: meta.title,
      token,
    });
    triggerSnack("Upload Done !", "success");
    if (!error && data) {
      setUploadedVideoId(data.id);
      dispatch(LivetipsPodcastSliceActions.insertPodcast(data));
      setShowPreview(true);
      setIsLoading(false);
    } else triggerSnack("Something went wrong !", "error");
  };

  const handleBackToEdit = () => {
    dispatch(LivetipsPodcastSliceActions.showEditModal());
  };

  return {
    handleChangeStatus,
    handleBackToEdit,
    showPreview,
    isLoading,
    uploadedVideoId,
  };
};

export default usePodcastUpload;
