import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    cursor: "pointer",
    borderRadius: 5,
    height: 175,
    paddingBlock: 1.5,
    paddingInline: 1,
    paddingInlineStart: 2,
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  infoContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "space-around",
    rowGap: 1.5,
    alignItems: "center",
  },
  desc: {
    fontFamily: "Nizzar ,K2D, Inter",
    fontWeight: "400",
    fontSize: 13,
  },
  subTitle: {
    fontFamily: "Nizzar ,K2D, Inter",
    fontWeight: "700",
    fontSize: 13,
  },
  title: {
    fontWeight: "700",
    lineHeight: 1.44,
    fontFamily: "Nizzar ,K2D, Inter",
    fontSize: 15,
  },
  button: {
    fontWeight: "800",
    letterSpacing: 1.5,
    background:
      "linear-gradient(100.26deg, #008755 16.92%, rgba(153, 191, 198, 0) 163.21%);",
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "flex-start",
    rowGap: 1.3,
    height: 145,
    marginInline: { xs: 1.3, lg: 1.4, xl: 1.5 },
  },
};

export default styles;
