import { object, boolean, string } from "yup";

export enum RegisterFormNames {
  username = "name",
  nationality = "nationality",
  country = "country",
  email = "email",
  university = "org",
  password = "passWord",
  countryCode = "countryCode",
  mobile = "mobile",
  concent = "concent",
  role = "role",
  /* gender = "gender", */
}

const initialValues = {
  [RegisterFormNames.username]: "",
  [RegisterFormNames.password]: "",
  [RegisterFormNames.nationality]: "United Arab Emirates",
  [RegisterFormNames.email]: "",
  [RegisterFormNames.mobile]: "",
  [RegisterFormNames.concent]: false,
  [RegisterFormNames.university]: "",
  [RegisterFormNames.countryCode]: "+971",
  [RegisterFormNames.country]: "United Arab Emirates",
  [RegisterFormNames.role]: "normal",
  /* [RegisterFormNames.gender]: "male", */
};

const validationSchema = object({
  [RegisterFormNames.username]: string().required(),
  [RegisterFormNames.password]: string().required(),
  [RegisterFormNames.nationality]: string().required(),
  [RegisterFormNames.email]: string().email().required(),
  [RegisterFormNames.country]: string().required(),
  [RegisterFormNames.countryCode]: string().required(),
  [RegisterFormNames.concent]: boolean().required(),
  [RegisterFormNames.mobile]: string()
    .min(9)
    .max(9, "E.g 523547807")
    .required(),
  [RegisterFormNames.role]: string().required(),
  /* [RegisterFormNames.gender]: string(), */
  [RegisterFormNames.university]: string().required(),
});

export { initialValues, validationSchema };
