import {
  useLikeCommentMutation,
  useUpdateCommentMutation,
} from "store/api/comment_service/endpoints";
import { Comment, CommentReaction } from "store/api/comment_service/types";
import { commentSliceActions } from "store/comment_slice";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { Reaction } from "types/reactions";

type Return = {
  handleDislike: () => Promise<void>;
  handleLike: () => Promise<void>;
  updateComment: (comment: Comment) => Promise<void>;
  userId: string | number;
  reaction: Reaction;
};

const useComment = (comment: Comment): Return => {
  const {
    global: { credentials, speakerProfile },
    comment: currentComment,
  } = useAppSelector((state) => state);

  const dispatch = useAppDispatch();

  const reaction =
    currentComment.myCommentReactions?.[comment.commentId?.toString()]
      ?.reaction ?? "idle";

  const [react] = useLikeCommentMutation();
  const [updateComment] = useUpdateCommentMutation();

  function getBody(like: boolean) {
    return {
      comment,
      deleted: false,
      like,
      userId: speakerProfile.customerId,
      user: {
        profilePicture: speakerProfile.profilePicture,
        name: speakerProfile.name,
      },
    };
  }

  async function handleDislike() {
    const body = getBody(false);

    const res = (await react({
      body,
      token: credentials.token.access_token,
    })) as unknown as CommentReaction;

    if (res) dispatch(commentSliceActions.pushDisLike(res));
  }

  async function handleUpdateCommentReaction(comment: Comment) {
    (await updateComment({
      body: comment,
      token: credentials.token.access_token,
    })) as unknown as CommentReaction;
  }

  async function handleLike() {
    const body = getBody(true);

    const res = (await react({
      body,
      token: credentials.token.access_token,
    })) as unknown as CommentReaction;

    if (res) dispatch(commentSliceActions.pushLike(res));
  }

  return {
    handleDislike,
    handleLike,
    updateComment: handleUpdateCommentReaction,
    userId: speakerProfile.customerId,
    reaction,
  };
};

export default useComment;
