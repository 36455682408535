import { defineMessages } from 'react-intl';

const scope = 'ExploreOnlineVideos';

export default defineMessages({
  results: {
    id: `${scope}.results`,
    defaultMessage: "Results",
  },
  no_online: {
    id: `${scope}.no_online`,
    defaultMessage: "No Online Videos were found",
  },
  section: {
    id: `${scope}.section`,
    defaultMessage: "Section",
  },
  upcoming: {
    id: `${scope}.upcoming`,
    defaultMessage: "Upcoming",
  },
  trending: {
    id: `${scope}.trending`,
    defaultMessage: "Trending",
  },
  mostviewed: {
    id: `${scope}.mostviewed`,
    defaultMessage: "Most Viewed",
  },
  no_podcast: {
    id: `${scope}.no_podcast`,
    defaultMessage: "No Podcast were found",
  },
});