import { defineMessages } from 'react-intl';

const scope = 'UploadVideoModal';

export default defineMessages({
  footer: {
    id: `${scope}.footer`,
    defaultMessage:
      "By submitting your Content to Live Tips, you acknowledge that yo agree to Live Tips's Terms of Service and Community Guidelines. Pleas be sure not to violate others' copyright or privacy rights. Lear more",
  },
});