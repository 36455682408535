import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    boxShadow: 1,
    borderRadius: 3,
    width: 340,
    height: 250,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    rowGap: 3,
  },
};

export default styles;
