import { object, string } from "yup";

export enum OTP_FORM {
  otp = "otp",
}

const initialValues = {
  [OTP_FORM.otp]: "",
};

const validationSchema = object({
  [OTP_FORM.otp]: string().required("OTP is a required Field"),
});

export { initialValues, validationSchema };
