import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    paddingBlock: 3,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    rowGap: {
      xs: 2,
    },
    alignItems: {
      xs: "center",
    },
    paddingInline: { xs: 1.5, lg: 6 },
  },
  backButton: {
    color: "black",
    border: "1px solid gray",
    width: {
      xs: "40%",
      sm: 160,
    },
  },
  publish: {
    color: "white",
    width: {
      xs: "40%",
      sm: 160,
    },
    marginInline: 1,
  },
};

export default styles;
