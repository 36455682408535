// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video-grid {
    display: grid;
    gap: 5px;
    width: 100%;
    height: 700px;
        border: 2px solid red;
}

/* Layouts based on the number of participants */
.participants-1 {
    grid-template-columns: 1fr;
}

.participants-2 {
    grid-template-columns: 1fr 1fr;
}

.participants-3,
.participants-4 {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
}

.participants-5,
.participants-6 {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
}

.participants-7,
.participants-8,
.participants-9 {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
}

.participants-10,
.participants-11,
.participants-12 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
}

/* Video Tile Styling */
.video-tile {
    position: relative;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
}

.video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.name {
    position: absolute;
    bottom: 10px;
    left: 10px;
    color: white;
    font-size: 1rem;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 2px 8px;
    border-radius: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/video_grid/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,QAAQ;IACR,WAAW;IACX,aAAa;QACT,qBAAqB;AAC7B;;AAEA,gDAAgD;AAChD;IACI,0BAA0B;AAC9B;;AAEA;IACI,8BAA8B;AAClC;;AAEA;;IAEI,8BAA8B;IAC9B,2BAA2B;AAC/B;;AAEA;;IAEI,kCAAkC;IAClC,2BAA2B;AAC/B;;AAEA;;;IAGI,kCAAkC;IAClC,+BAA+B;AACnC;;AAEA;;;IAGI,sCAAsC;IACtC,+BAA+B;AACnC;;AAEA,uBAAuB;AACvB;IACI,kBAAkB;IAClB,uBAAuB;IACvB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,UAAU;IACV,YAAY;IACZ,eAAe;IACf,oCAAoC;IACpC,gBAAgB;IAChB,kBAAkB;AACtB","sourcesContent":[".video-grid {\n    display: grid;\n    gap: 5px;\n    width: 100%;\n    height: 700px;\n        border: 2px solid red;\n}\n\n/* Layouts based on the number of participants */\n.participants-1 {\n    grid-template-columns: 1fr;\n}\n\n.participants-2 {\n    grid-template-columns: 1fr 1fr;\n}\n\n.participants-3,\n.participants-4 {\n    grid-template-columns: 1fr 1fr;\n    grid-template-rows: 1fr 1fr;\n}\n\n.participants-5,\n.participants-6 {\n    grid-template-columns: 1fr 1fr 1fr;\n    grid-template-rows: 1fr 1fr;\n}\n\n.participants-7,\n.participants-8,\n.participants-9 {\n    grid-template-columns: 1fr 1fr 1fr;\n    grid-template-rows: 1fr 1fr 1fr;\n}\n\n.participants-10,\n.participants-11,\n.participants-12 {\n    grid-template-columns: 1fr 1fr 1fr 1fr;\n    grid-template-rows: 1fr 1fr 1fr;\n}\n\n/* Video Tile Styling */\n.video-tile {\n    position: relative;\n    background-color: black;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.video {\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n}\n\n.name {\n    position: absolute;\n    bottom: 10px;\n    left: 10px;\n    color: white;\n    font-size: 1rem;\n    background-color: rgba(0, 0, 0, 0.5);\n    padding: 2px 8px;\n    border-radius: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
