import { Formik, FormikValues } from "formik";
import { PropsWithChildren } from "react";

function FormContainer({
  children,
  initialValues,
  onSubmit,
  validationSchema,
}: PropsWithChildren<FormikValues>) {
  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize
    >
      <>{children}</>
    </Formik>
  );
}

export default FormContainer;
