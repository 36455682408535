import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    width: "70%",
    marginX: "auto",
  },
  header: {
    fontSize: {
      lg: 40,
    },
    fontWeight: "600",
    textAlign: "center",
    marginBlock: {
      lg: 5,
    },
    marginBottom: { xl: 15 },
  },
};

export default styles;
