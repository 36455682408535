type FooterItem = {
  label: string;
  path: string;
};

export const linkItems: Array<FooterItem> = [
  {
    label: "ABOUT THIS APP",
    path: "",
  },
  {
    label: "Home",
    path: "",
  },
  {
    label: "Get in Touch",
    path: "",
  },
  {
    label: "Our Services",
    path: "",
  },
  {
    label: "Help",
    path: "",
  },
  {
    label: "Testimonial",
    path: "",
  },
  {
    label: "How It works",
    path: "",
  },
  {
    label: "Dubai Police",
    path: "",
  },
];
