import { FC } from "react";
import OnlineWrapper from "components/online_wrapper";
import { UploadPrivacy } from "types/upload_privacy";
import Box from "@mui/material/Box";
import { UploadedFileStatus } from "types/uploaded_file_status";

type Props = {
  category: number;
  sectionHeader: string;
};

const SectionByCategory: FC<Props> = ({ category, sectionHeader }) => {
  return (
    <Box sx={{ marginBlock: 1.5 }}>
      <OnlineWrapper
        paginationName={sectionHeader}
        listLabel={sectionHeader}
        videoQuery={{
          category,
          privacy: UploadPrivacy.DUBAI_POLICE,
          status: UploadedFileStatus.ACCEPTED,
        }}
        skeletons={{
          xs: 12,
          sm: 6,
          md: 9,
          lg: 4.5,
          xl: 4,
        }}
        dimensions={{
          xs: 12,
          sm: 6,
          md: 12,
          lg: 4.8,
          xl: 3,
        }}
      />
    </Box>
  );
};

export default SectionByCategory;
