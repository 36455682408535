import { FC } from "react";
import Stack from "@mui/material/Stack";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Grow from "@mui/material/Grow";
import Typography from "@mui/material/Typography";
import styles from "./styles";
import { useAppDispatch, useAppSelector } from "store/hooks";
import Close from "@mui/icons-material/Close";
import { modalSliceActions } from "store/modal_slice";
import Button from "components/custom_button";
import { FormattedMessage, useIntl } from "react-intl";
import messages from "./messages";
import { verificationSliceActions } from "store/verification_slice";
import { NavigationRoutes } from "navigation/routes";
import { ProfileSliceActions } from "store/profile_slice";
import { useNavigate } from "react-router-dom";

const NewUserModal: FC = () => {
  const dispatch = useAppDispatch();

  const open = useAppSelector((state) => state.modals.newUser);

  const { name, email, mobile } = useAppSelector(
    (state) => state.global.speakerProfile
  );

  const { verifyOTP } = useAppSelector((state) => state.otp);

  const handleCloseShare = () => {
    dispatch(modalSliceActions.setNewUserModal(false));
  };

  const intl = useIntl();

  const navigate = useNavigate();

  const handleEditProfile = () => {
    dispatch(modalSliceActions.setNewUserModal(false));

    if (!verifyOTP) {
      dispatch(ProfileSliceActions.showEditProfileModal());
      return;
    } else {
      dispatch(verificationSliceActions.setPath(NavigationRoutes.profile));
      dispatch(verificationSliceActions.setEmail(email));
      dispatch(verificationSliceActions.setPhone(mobile));

      dispatch(
        verificationSliceActions.setNavigationProps({
          state: {
            editProfile: true,
            noOTP: false,
            email,
            mobile,
          },
        })
      );

      navigate(NavigationRoutes.otp_confirmation, {
        state: {
          mobile,
          email,
        },
      });
    }
  };

  return (
    <Modal open={open} sx={styles.container}>
      <Grow in={open} timeout={600}>
        <Paper sx={styles.paper}>
          <Stack
            sx={styles.headerWrapper}
            justifyContent="space-between"
            direction="row"
          >
            <Typography sx={styles.header}>
              <FormattedMessage id={messages.hey.id} /> `` {name} ``{" "}
              <FormattedMessage id={messages.welcome.id} />
            </Typography>
            <Close onClick={handleCloseShare} sx={styles.close} />
          </Stack>
          <Typography sx={styles.desc}>
            <FormattedMessage id={messages.desc.id} />
          </Typography>
          <Typography sx={styles.desc}>
            <FormattedMessage id={messages.desc_2.id} />
          </Typography>
          <Button
            onClick={handleEditProfile}
            label={intl.formatMessage(messages.edit_profile)}
          />
        </Paper>
      </Grow>
    </Modal>
  );
};

export default NewUserModal;
