import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    borderRadius: 5,
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    textAlign: "start",
    rowGap: 1.5,
  },
  desc: {
    marginTop: 0.5,
    fontSize: {
      xs: 15,
      lg: 12,
    },
    fontFamily: "Nizzar ,K2D, Inter",
    lineHeight: 1.25,
  },
  title: {
    fontFamily: "Nizzar ,K2D, Inter",
    fontWeight: "600",
    paddingInlineStart: 1,
    maxWidth: "100%",
    fontSize: {
      xs: 15,
      lg: 13,
    },
  },
};

export default styles;
