import { FC } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import star from "assets/svgs/star.svg";
import styles from "./styles";
import { useIntl } from "react-intl";
import messages from "./messages";

const FilterSection: FC = () => {
  const intl = useIntl();
  return (
    <Box sx={styles.container}>
      <Box mb="35px">
        <Stack
          direction="row"
          justifyContent="flex-start"
          sx={styles.filterStack}
        >
          <Chip
            sx={styles.chipStyle}
            size="medium"
            label={intl.formatMessage(messages.all)}
            clickable
          />
          <Chip
            sx={styles.chipStyle}
            size="medium"
            label={intl.formatMessage(messages.most_viewed)}
            clickable
          />
          <Chip
            sx={styles.chipStyle}
            size="medium"
            label={intl.formatMessage(messages.most_recent)}
            clickable
          />
          <Chip
            sx={styles.chipStyle}
            size="medium"
            label={intl.formatMessage(messages.popular)}
            clickable
          />
          <Chip
            sx={styles.chipStyle}
            size="medium"
            label={intl.formatMessage(messages.high_rated)}
            clickable
            icon={<img alt="Filter Image" src={star} />}
          />
        </Stack>
      </Box>
    </Box>
  );
};

export default FilterSection;
