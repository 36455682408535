import red_logo from "assets/svgs/government-of-dubai-red_re.svg";
import positive from "assets/images/positive.webp";
import pass from "assets/images/pass.webp";
import uae from "assets/images/uae.webp";
import smart_dubai from "assets/images/smart_dubai.webp";
import dubai_gov from "assets/images/dubai_gov.webp";
import digital_dubai from "assets/images/digital_dubai.webp";
import issad from "assets/images/issad.webp";

export const list_one: Array<{ id: string; src: string }> = [
  {
    id: "1",
    src: red_logo,
  },
  {
    id: "2",
    src: digital_dubai,
  },
  {
    id: "3",
    src: dubai_gov,
  },
  {
    id: "4",
    src: smart_dubai,
  },
  {
    id: "6",
    src: issad,
  },
  {
    id: "5",
    src: uae,
  },
  {
    id: "4",
    src: positive,
  },
  {
    id: "3",
    src: pass,
  },
];
