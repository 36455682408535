export type CountryType = {
  name: string;
  nameAr: string;
  code: string;
  timezone: string;
  utc: string;
  mobileCode: string;
};

export const countryList: Array<CountryType> = [
  {
    name: "Afghanistan",
    nameAr: "أفغانستان",
    code: "AF",
    timezone: "Afghanistan Standard Time",
    utc: "UTC+04:30",
    mobileCode: "+93"
  },
  {
    name: "Åland Islands",
    nameAr: "جزر آلاند",
    code: "AX",
    timezone: "FLE Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+358-18"
  },
  {
    name: "Albania",
    nameAr: "ألبانيا",
    code: "AL",
    timezone: "Central Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+355"
  },
  {
    name: "Algeria",
    nameAr: "الجزائر",
    code: "DZ",
    timezone: "W. Central Africa Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+213"
  },
  {
    name: "American Samoa",
    nameAr: "ساموا الأمريكية",
    code: "AS",
    timezone: "UTC-11",
    utc: "UTC-11:00",
    mobileCode: "+1-684"
  },
  {
    name: "Andorra",
    nameAr: "أندورا",
    code: "AD",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+376"
  },
  {
    name: "Angola",
    nameAr: "أنغولا",
    code: "AO",
    timezone: "W. Central Africa Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+244"
  },
  {
    name: "Anguilla",
    nameAr: "أنغويلا",
    code: "AI",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+1-264"
  },
  {
    name: "Antarctica",
    nameAr: "القارة القطبية الجنوبية",
    code: "AQ",
    timezone: "Pacific SA Standard Time",
    utc: "UTC-03:00",
    mobileCode: "+"
  },
  {
    name: "Antigua and Barbuda",
    nameAr: "أنتيغوا وبربودا",
    code: "AG",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+1-268"
  },
  {
    name: "Argentina",
    nameAr: "الأرجنتين",
    code: "AR",
    timezone: "Argentina Standard Time",
    utc: "UTC-03:00",
    mobileCode: "+54"
  },
  {
    name: "Armenia",
    nameAr: "أرمينيا",
    code: "AM",
    timezone: "Caucasus Standard Time",
    utc: "UTC+04:00",
    mobileCode: "+374"
  },
  {
    name: "Aruba",
    nameAr: "أروبا",
    code: "AW",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+297"
  },
  {
    name: "Australia",
    nameAr: "أستراليا",
    code: "AU",
    timezone: "AUS Eastern Standard Time",
    utc: "UTC+10:00",
    mobileCode: "+61"
  },
  {
    name: "Austria",
    nameAr: "النمسا",
    code: "AT",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+43"
  },
  {
    name: "Azerbaijan",
    nameAr: "أذربيجان",
    code: "AZ",
    timezone: "Azerbaijan Standard Time",
    utc: "UTC+04:00",
    mobileCode: "+994"
  },
  {
    name: "Bahamas, The",
    nameAr: "جزر البهاما",
    code: "BS",
    timezone: "Eastern Standard Time",
    utc: "UTC-05:00",
    mobileCode: "+1-242"
  },
  {
    name: "Bahrain",
    nameAr: "البحرين",
    code: "BH",
    timezone: "Arab Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+973"
  },
  {
    name: "Bangladesh",
    nameAr: "بنغلاديش",
    code: "BD",
    timezone: "Bangladesh Standard Time",
    utc: "UTC+06:00",
    mobileCode: "+880"
  },
  {
    name: "Barbados",
    nameAr: "بربادوس",
    code: "BB",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+1-246"
  },
  {
    name: "Belarus",
    nameAr: "بيلاروسيا",
    code: "BY",
    timezone: "Belarus Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+375"
  },
  {
    name: "Belgium",
    nameAr: "بلجيكا",
    code: "BE",
    timezone: "Romance Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+32"
  },
  {
    name: "Belize",
    nameAr: "بليز",
    code: "BZ",
    timezone: "Central America Standard Time",
    utc: "UTC-06:00",
    mobileCode: "+501"
  },
  {
    name: "Benin",
    nameAr: "بنين",
    code: "BJ",
    timezone: "W. Central Africa Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+229"
  },
  {
    name: "Bermuda",
    nameAr: "برمودا",
    code: "BM",
    timezone: "Atlantic Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+1-441"
  },
  {
    name: "Bhutan",
    nameAr: "بوتان",
    code: "BT",
    timezone: "Bangladesh Standard Time",
    utc: "UTC+06:00",
    mobileCode: "+975"
  },
  {
    name: "Bolivarian Republic of Venezuela",
    nameAr: "جمهورية فينزويلا البوليفارية",
    code: "VE",
    timezone: "Venezuela Standard Time",
    utc: "UTC-04:30",
    mobileCode: "+58"
  },
  {
    name: "Bolivia",
    nameAr: "بوليفيا",
    code: "BO",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+591"
  },
  {
    name: "Bonaire, Sint Eustatius and Saba",
    nameAr: "بونير، سانت أيوستاتيوس وسابا",
    code: "BQ",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+599"
  },
  {
    name: "Bosnia and Herzegovina",
    nameAr: "البوسنة والهرسك",
    code: "BA",
    timezone: "Central European Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+387"
  },
  {
    name: "Botswana",
    nameAr: "بتسوانا",
    code: "BW",
    timezone: "South Africa Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+267"
  },
  {
    name: "Bouvet Island",
    nameAr: "جزيرة بوفيه",
    code: "BV",
    timezone: "UTC",
    utc: "UTC",
    mobileCode: "+"
  },
  {
    name: "Brazil",
    nameAr: "البرازيل",
    code: "BR",
    timezone: "E. South America Standard Time",
    utc: "UTC-03:00",
    mobileCode: "+55"
  },
  {
    name: "British Indian Ocean Territory",
    nameAr: "إقليم المحيط الهندي البريطاني",
    code: "IO",
    timezone: "Central Asia Standard Time",
    utc: "UTC+06:00",
    mobileCode: "+246"
  },
  {
    name: "Brunei",
    nameAr: "بروناي",
    code: "BN",
    timezone: "Singapore Standard Time",
    utc: "UTC+08:00",
    mobileCode: "+673"
  },
  {
    name: "Bulgaria",
    nameAr: "بلغاريا",
    code: "BG",
    timezone: "FLE Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+359"
  },
  {
    name: "Burkina Faso",
    nameAr: "بوركينا فاسو",
    code: "BF",
    timezone: "Greenwich Standard Time",
    utc: "UTC",
    mobileCode: "+226"
  },
  {
    name: "Burundi",
    nameAr: "بوروندي",
    code: "BI",
    timezone: "South Africa Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+257"
  },
  {
    name: "Cabo Verde",
    nameAr: "الرأس الأخضر",
    code: "CV",
    timezone: "Cape Verde Standard Time",
    utc: "UTC-01:00",
    mobileCode: "+238"
  },
  {
    name: "Cambodia",
    nameAr: "كمبوديا",
    code: "KH",
    timezone: "SE Asia Standard Time",
    utc: "UTC+07:00",
    mobileCode: "+855"
  },
  {
    name: "Cameroon",
    nameAr: "الكاميرون",
    code: "CM",
    timezone: "W. Central Africa Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+237"
  },
  {
    name: "Canada",
    nameAr: "كندا",
    code: "CA",
    timezone: "Eastern Standard Time",
    utc: "UTC-05:00",
    mobileCode: "+1"
  },
  {
    name: "Cayman Islands",
    nameAr: "جزر كايمان",
    code: "KY",
    timezone: "SA Pacific Standard Time",
    utc: "UTC-05:00",
    mobileCode: "+1-345"
  },
  {
    name: "Central African Republic",
    nameAr: "جمهورية أفريقيا الوسطى",
    code: "CF",
    timezone: "W. Central Africa Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+236"
  },
  {
    name: "Chad",
    nameAr: "تشاد",
    code: "TD",
    timezone: "W. Central Africa Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+235"
  },
  {
    name: "Chile",
    nameAr: "تشيلي",
    code: "CL",
    timezone: "Pacific SA Standard Time",
    utc: "UTC-03:00",
    mobileCode: "+56"
  },
  {
    name: "China",
    nameAr: "الصين",
    code: "CN",
    timezone: "China Standard Time",
    utc: "UTC+08:00",
    mobileCode: "+86"
  },
  {
    name: "Christmas Island",
    nameAr: "جزيرة الكريسماس",
    code: "CX",
    timezone: "SE Asia Standard Time",
    utc: "UTC+07:00",
    mobileCode: "+61"
  },
  {
    name: "Cocos (Keeling) Islands",
    nameAr: "جزر كوكوس (كيلينغ)",
    code: "CC",
    timezone: "Myanmar Standard Time",
    utc: "UTC+06:30",
    mobileCode: "+61"
  },
  {
    name: "Colombia",
    nameAr: "كولومبيا",
    code: "CO",
    timezone: "SA Pacific Standard Time",
    utc: "UTC-05:00",
    mobileCode: "+57"
  },
  {
    name: "Comoros",
    nameAr: "جزر القمر",
    code: "KM",
    timezone: "E. Africa Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+269"
  },
  {
    name: "Congo",
    nameAr: "الكونغو",
    code: "CG",
    timezone: "W. Central Africa Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+242"
  },
  {
    name: "Congo (DRC)",
    nameAr: "جمهورية الكونغو الديمقراطية",
    code: "CD",
    timezone: "W. Central Africa Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+243"
  },
  {
    name: "Cook Islands",
    nameAr: "جزر كوك",
    code: "CK",
    timezone: "Hawaiian Standard Time",
    utc: "UTC-10:00",
    mobileCode: "+682"
  },
  {
    name: "Costa Rica",
    nameAr: "كوستاريكا",
    code: "CR",
    timezone: "Central America Standard Time",
    utc: "UTC-06:00",
    mobileCode: "+506"
  },
  {
    name: "Côte d'Ivoire",
    nameAr: "ساحل العاج",
    code: "CI",
    timezone: "Greenwich Standard Time",
    utc: "UTC",
    mobileCode: "+225"
  },
  {
    name: "Croatia",
    nameAr: "كرواتيا",
    code: "HR",
    timezone: "Central European Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+385"
  },
  {
    name: "Cuba",
    nameAr: "كوبا",
    code: "CU",
    timezone: "Eastern Standard Time",
    utc: "UTC-05:00",
    mobileCode: "+53"
  },
  {
    name: "Curaçao",
    nameAr: "كوراساو",
    code: "CW",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+599"
  },
  {
    name: "Cyprus",
    nameAr: "قبرص",
    code: "CY",
    timezone: "E. Europe Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+357"
  },
  {
    name: "Czech Republic",
    nameAr: "الجمهورية التشيكية",
    code: "CZ",
    timezone: "Central Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+420"
  },
  {
    name: "Democratic Republic of Timor-Leste",
    nameAr: "جمهورية تيمور الشرقية الديمقراطية",
    code: "TL",
    timezone: "Tokyo Standard Time",
    utc: "UTC+09:00",
    mobileCode: "+670"
  },
  {
    name: "Denmark",
    nameAr: "الدنمارك",
    code: "DK",
    timezone: "Romance Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+45"
  },
  {
    name: "Djibouti",
    nameAr: "جيبوتي",
    code: "DJ",
    timezone: "E. Africa Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+253"
  },
  {
    name: "Dominica",
    nameAr: "دومينيكا",
    code: "DM",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+1-767"
  },
  {
    name: "Dominican Republic",
    nameAr: "جمهورية الدومينيكان",
    code: "DO",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+1-809 and 1-829"
  },
  {
    name: "Ecuador",
    nameAr: "الإكوادور",
    code: "EC",
    timezone: "SA Pacific Standard Time",
    utc: "UTC-05:00",
    mobileCode: "+593"
  },
  {
    name: "Egypt",
    nameAr: "مصر",
    code: "EG",
    timezone: "Egypt Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+20"
  },
  {
    name: "El Salvador",
    nameAr: "السلفادور",
    code: "SV",
    timezone: "Central America Standard Time",
    utc: "UTC-06:00",
    mobileCode: "+503"
  },
  {
    name: "Equatorial Guinea",
    nameAr: "غينيا الاستوائية",
    code: "GQ",
    timezone: "W. Central Africa Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+240"
  },
  {
    name: "Eritrea",
    nameAr: "إريتريا",
    code: "ER",
    timezone: "E. Africa Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+291"
  },
  {
    name: "Estonia",
    nameAr: "إستونيا",
    code: "EE",
    timezone: "FLE Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+372"
  },
  {
    name: "Ethiopia",
    nameAr: "إثيوبيا",
    code: "ET",
    timezone: "E. Africa Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+251"
  },
  {
    name: "Falkland Islands (Islas Malvinas)",
    nameAr: "جزر فوكلاند (مالفيناس)",
    code: "FK",
    timezone: "SA Eastern Standard Time",
    utc: "UTC-03:00",
    mobileCode: "+500"
  },
  {
    name: "Faroe Islands",
    nameAr: "جزر فارو",
    code: "FO",
    timezone: "GMT Standard Time",
    utc: "UTC",
    mobileCode: "+298"
  },
  {
    name: "Fiji Islands",
    nameAr: "جزر فيجي",
    code: "FJ",
    timezone: "Fiji Standard Time",
    utc: "UTC+12:00",
    mobileCode: "+679"
  },
  {
    name: "Finland",
    nameAr: "فنلندا",
    code: "FI",
    timezone: "FLE Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+358"
  },
  {
    name: "France",
    nameAr: "فرنسا",
    code: "FR",
    timezone: "Romance Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+33"
  },
  {
    name: "French Guiana",
    nameAr: "غويانا الفرنسية",
    code: "GF",
    timezone: "SA Eastern Standard Time",
    utc: "UTC-03:00",
    mobileCode: "+594"
  },
  {
    name: "French Polynesia",
    nameAr: "بولينيزيا الفرنسية",
    code: "PF",
    timezone: "Hawaiian Standard Time",
    utc: "UTC-10:00",
    mobileCode: "+689"
  },
  {
    name: "French Southern and Antarctic Lands",
    nameAr: "الأراضي الجنوبية الفرنسية والأنتاركتيكية",
    code: "TF",
    timezone: "West Asia Standard Time",
    utc: "UTC+05:00",
    mobileCode: "+"
  },
  {
    name: "Gabon",
    nameAr: "الغابون",
    code: "GA",
    timezone: "W. Central Africa Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+241"
  },
  {
    name: "Gambia, The",
    nameAr: "غامبيا",
    code: "GM",
    timezone: "Greenwich Standard Time",
    utc: "UTC",
    mobileCode: "+220"
  },
  {
    name: "Georgia",
    nameAr: "جورجيا",
    code: "GE",
    timezone: "Georgian Standard Time",
    utc: "UTC+04:00",
    mobileCode: "+995"
  },
  {
    name: "Germany",
    nameAr: "ألمانيا",
    code: "DE",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+49"
  },
  {
    name: "Ghana",
    nameAr: "غانا",
    code: "GH",
    timezone: "Greenwich Standard Time",
    utc: "UTC",
    mobileCode: "+233"
  },
  {
    name: "Gibraltar",
    nameAr: "جبل طارق",
    code: "GI",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+350"
  },
  {
    name: "Greece",
    nameAr: "اليونان",
    code: "GR",
    timezone: "GTB Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+30"
  },
  {
    name: "Greenland",
    nameAr: "جرينلاند",
    code: "GL",
    timezone: "Greenland Standard Time",
    utc: "UTC-03:00",
    mobileCode: "+299"
  },
  {
    name: "Grenada",
    nameAr: "غرينادا",
    code: "GD",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+1-473"
  },
  {
    name: "Guadeloupe",
    nameAr: "غوادلوب",
    code: "GP",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+590"
  },
  {
    name: "Guam",
    nameAr: "غوام",
    code: "GU",
    timezone: "West Pacific Standard Time",
    utc: "UTC+10:00",
    mobileCode: "+1-671"
  },
  {
    name: "Guatemala",
    nameAr: "غواتيمالا",
    code: "GT",
    timezone: "Central America Standard Time",
    utc: "UTC-06:00",
    mobileCode: "+502"
  },
  {
    name: "Guernsey",
    nameAr: "جيرنزي",
    code: "GG",
    timezone: "GMT Standard Time",
    utc: "UTC",
    mobileCode: "+44-1481"
  },
  {
    name: "Guinea",
    nameAr: "غينيا",
    code: "GN",
    timezone: "Greenwich Standard Time",
    utc: "UTC",
    mobileCode: "+224"
  },
  {
    name: "Guinea-Bissau",
    nameAr: "غينيا بيساو",
    code: "GW",
    timezone: "Greenwich Standard Time",
    utc: "UTC",
    mobileCode: "+245"
  },
  {
    name: "Guyana",
    nameAr: "غيانا",
    code: "GY",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+592"
  },
  {
    name: "Haiti",
    nameAr: "هايتي",
    code: "HT",
    timezone: "Eastern Standard Time",
    utc: "UTC-05:00",
    mobileCode: "+509"
  },
  {
    name: "Heard Island and McDonald Islands",
    nameAr: "جزيرة هيرد وجزر ماكدونالد",
    code: "HM",
    timezone: "Mauritius Standard Time",
    utc: "UTC+04:00",
    mobileCode: "+ "
  },
  {
    name: "Honduras",
    nameAr: "هندوراس",
    code: "HN",
    timezone: "Central America Standard Time",
    utc: "UTC-06:00",
    mobileCode: "+504"
  },
  {
    name: "Hong Kong SAR",
    nameAr: "هونغ كونغ",
    code: "HK",
    timezone: "China Standard Time",
    utc: "UTC+08:00",
    mobileCode: "+852"
  },
  {
    name: "Hungary",
    nameAr: "هنغاريا",
    code: "HU",
    timezone: "Central Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+36"
  },
  {
    name: "Iceland",
    nameAr: "أيسلندا",
    code: "IS",
    timezone: "Greenwich Standard Time",
    utc: "UTC",
    mobileCode: "+354"
  },
  {
    name: "India",
    nameAr: "الهند",
    code: "IN",
    timezone: "India Standard Time",
    utc: "UTC+05:30",
    mobileCode: "+91"
  },
  {
    name: "Indonesia",
    nameAr: "إندونيسيا",
    code: "ID",
    timezone: "SE Asia Standard Time",
    utc: "UTC+07:00",
    mobileCode: "+62"
  },
  {
    name: "Iran",
    nameAr: "إيران",
    code: "IR",
    timezone: "Iran Standard Time",
    utc: "UTC+03:30",
    mobileCode: "+98"
  },
  {
    name: "Iraq",
    nameAr: "العراق",
    code: "IQ",
    timezone: "Arabic Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+964"
  },
  {
    name: "Ireland",
    nameAr: "أيرلندا",
    code: "IE",
    timezone: "GMT Standard Time",
    utc: "UTC",
    mobileCode: "+353"
  },
  {
    name: "Israel",
    nameAr: "إسرائيل",
    code: "IL",
    timezone: "Israel Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+972"
  },
  {
    name: "Italy",
    nameAr: "إيطاليا",
    code: "IT",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+39"
  },
  {
    name: "Jamaica",
    nameAr: "جامايكا",
    code: "JM",
    timezone: "SA Pacific Standard Time",
    utc: "UTC-05:00",
    mobileCode: "+1-876"
  },
  {
    name: "Jan Mayen",
    nameAr: "جان ماين",
    code: "SJ",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+47"
  },
  {
    name: "Japan",
    nameAr: "اليابان",
    code: "JP",
    timezone: "Tokyo Standard Time",
    utc: "UTC+09:00",
    mobileCode: "+81"
  },
  {
    name: "Jersey",
    nameAr: "جيرسي",
    code: "JE",
    timezone: "GMT Standard Time",
    utc: "UTC",
    mobileCode: "+44-1534"
  },
  {
    name: "Jordan",
    nameAr: "الأردن",
    code: "JO",
    timezone: "Jordan Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+962"
  },
  {
    name: "Kazakhstan",
    nameAr: "كازاخستان",
    code: "KZ",
    timezone: "Central Asia Standard Time",
    utc: "UTC+06:00",
    mobileCode: "+7"
  },
  {
    name: "Kenya",
    nameAr: "كينيا",
    code: "KE",
    timezone: "E. Africa Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+254"
  },
  {
    name: "Kiribati",
    nameAr: "كيريباتي",
    code: "KI",
    timezone: "UTC+12",
    utc: "UTC+12:00",
    mobileCode: "+686"
  },
  {
    name: "Korea",
    nameAr: "كوريا",
    code: "KR",
    timezone: "Korea Standard Time",
    utc: "UTC+09:00",
    mobileCode: "+82"
  },
  {
    name: "Kosovo",
    nameAr: "كوسوفو",
    code: "XK",
    timezone: "Central European Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+"
  },
  {
    name: "Kuwait",
    nameAr: "الكويت",
    code: "KW",
    timezone: "Arab Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+965"
  },
  {
    name: "Kyrgyzstan",
    nameAr: "قيرغيزستان",
    code: "KG",
    timezone: "Central Asia Standard Time",
    utc: "UTC+06:00",
    mobileCode: "+996"
  },
  {
    name: "Laos",
    nameAr: "لاوس",
    code: "LA",
    timezone: "SE Asia Standard Time",
    utc: "UTC+07:00",
    mobileCode: "+856"
  },
  {
    name: "Latvia",
    nameAr: "لاتفيا",
    code: "LV",
    timezone: "FLE Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+371"
  },
  {
    name: "Lebanon",
    nameAr: "لبنان",
    code: "LB",
    timezone: "Middle East Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+961"
  },
  {
    name: "Lesotho",
    nameAr: "ليسوتو",
    code: "LS",
    timezone: "South Africa Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+266"
  },
  {
    name: "Liberia",
    nameAr: "ليبيريا",
    code: "LR",
    timezone: "Greenwich Standard Time",
    utc: "UTC",
    mobileCode: "+231"
  },
  {
    name: "Libya",
    nameAr: "ليبيا",
    code: "LY",
    timezone: "E. Europe Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+218"
  },
  {
    name: "Liechtenstein",
    nameAr: "ليختنشتاين",
    code: "LI",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+423"
  },
  {
    name: "Lithuania",
    nameAr: "ليتوانيا",
    code: "LT",
    timezone: "FLE Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+370"
  },
  {
    name: "Luxembourg",
    nameAr: "لوكسمبورغ",
    code: "LU",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+352"
  },
  {
    name: "Macao SAR",
    nameAr: "ماكاو (الصين)",
    code: "MO",
    timezone: "China Standard Time",
    utc: "UTC+08:00",
    mobileCode: "+853"
  },
  {
    name: "Macedonia, Former Yugoslav Republic of",
    nameAr: "مقدونيا",
    code: "MK",
    timezone: "Central European Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+389"
  },
  {
    name: "Madagascar",
    nameAr: "مدغشقر",
    code: "MG",
    timezone: "E. Africa Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+261"
  },
  {
    name: "Malawi",
    nameAr: "ملاوي",
    code: "MW",
    timezone: "South Africa Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+265"
  },
  {
    name: "Malaysia",
    nameAr: "ماليزيا",
    code: "MY",
    timezone: "Singapore Standard Time",
    utc: "UTC+08:00",
    mobileCode: "+60"
  },
  {
    name: "Maldives",
    nameAr: "المالديف",
    code: "MV",
    timezone: "West Asia Standard Time",
    utc: "UTC+05:00",
    mobileCode: "+960"
  },
  {
    name: "Mali",
    nameAr: "مالي",
    code: "ML",
    timezone: "Greenwich Standard Time",
    utc: "UTC",
    mobileCode: "+223"
  },
  {
    name: "Malta",
    nameAr: "مالطا",
    code: "MT",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+356"
  },
  {
    name: "Man, Isle of",
    nameAr: "جزيرة مان",
    code: "IM",
    timezone: "GMT Standard Time",
    utc: "UTC",
    mobileCode: "+44-1624"
  },
  {
    name: "Marshall Islands",
    nameAr: "جزر المارشال",
    code: "MH",
    timezone: "UTC+12",
    utc: "UTC+12:00",
    mobileCode: "+692"
  },
  {
    name: "Martinique",
    nameAr: "مارتينيك",
    code: "MQ",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+596"
  },
  {
    name: "Mauritania",
    nameAr: "موريتانيا",
    code: "MR",
    timezone: "Greenwich Standard Time",
    utc: "UTC",
    mobileCode: "+222"
  },
  {
    name: "Mauritius",
    nameAr: "موريشيوس",
    code: "MU",
    timezone: "Mauritius Standard Time",
    utc: "UTC+04:00",
    mobileCode: "+230"
  },
  {
    name: "Mayotte",
    nameAr: "مايوت",
    code: "YT",
    timezone: "E. Africa Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+262"
  },
  {
    name: "Mexico",
    nameAr: "المكسيك",
    code: "MX",
    timezone: "Central Standard Time (Mexico)",
    utc: "UTC-06:00",
    mobileCode: "+52"
  },
  {
    name: "Micronesia",
    nameAr: "ميكرونيزيا",
    code: "FM",
    timezone: "West Pacific Standard Time",
    utc: "UTC+10:00",
    mobileCode: "+691"
  },
  {
    name: "Moldova",
    nameAr: "مولدوفا",
    code: "MD",
    timezone: "GTB Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+373"
  },
  {
    name: "Monaco",
    nameAr: "موناكو",
    code: "MC",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+377"
  },
  {
    name: "Mongolia",
    nameAr: "منغوليا",
    code: "MN",
    timezone: "Ulaanbaatar Standard Time",
    utc: "UTC+08:00",
    mobileCode: "+976"
  },
  {
    name: "Montenegro",
    nameAr: "الجبل الأسود",
    code: "ME",
    timezone: "Central European Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+382"
  },
  {
    name: "Montserrat",
    nameAr: "مونتسيرات",
    code: "MS",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+1-664"
  },
  {
    name: "Morocco",
    nameAr: "المغرب",
    code: "MA",
    timezone: "Morocco Standard Time",
    utc: "UTC",
    mobileCode: "+212"
  },
  {
    name: "Mozambique",
    nameAr: "موزمبيق",
    code: "MZ",
    timezone: "South Africa Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+258"
  },
  {
    name: "Myanmar",
    nameAr: "ميانمار (بورما)",
    code: "MM",
    timezone: "Myanmar Standard Time",
    utc: "UTC+06:30",
    mobileCode: "+95"
  },
  {
    name: "Namibia",
    nameAr: "ناميبيا",
    code: "NA",
    timezone: "Namibia Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+264"
  },
  {
    name: "Nauru",
    nameAr: "ناورو",
    code: "NR",
    timezone: "UTC+12",
    utc: "UTC+12:00",
    mobileCode: "+674"
  },
  {
    name: "Nepal",
    nameAr: "نيبال",
    code: "NP",
    timezone: "Nepal Standard Time",
    utc: "UTC+05:45",
    mobileCode: "+977"
  },
  {
    name: "Netherlands",
    nameAr: "هولندا",
    code: "NL",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+31"
  },
  {
    name: "New Caledonia",
    nameAr: "كاليدونيا الجديدة",
    code: "NC",
    timezone: "Central Pacific Standard Time",
    utc: "UTC+11:00",
    mobileCode: "+687"
  },
  {
    name: "New Zealand",
    nameAr: "نيوزيلندا",
    code: "NZ",
    timezone: "New Zealand Standard Time",
    utc: "UTC+12:00",
    mobileCode: "+64"
  },
  {
    name: "Nicaragua",
    nameAr: "نيكاراغوا",
    code: "NI",
    timezone: "Central America Standard Time",
    utc: "UTC-06:00",
    mobileCode: "+505"
  },
  {
    name: "Niger",
    nameAr: "النيجر",
    code: "NE",
    timezone: "W. Central Africa Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+227"
  },
  {
    name: "Nigeria",
    nameAr: "نيجيريا",
    code: "NG",
    timezone: "W. Central Africa Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+234"
  },
  {
    name: "Niue",
    nameAr: "نيوي",
    code: "NU",
    timezone: "UTC-11",
    utc: "UTC-11:00",
    mobileCode: "+683"
  },
  {
    name: "Norfolk Island",
    nameAr: "جزيرة نورفولك",
    code: "NF",
    timezone: "Central Pacific Standard Time",
    utc: "UTC+11:00",
    mobileCode: "+672"
  },
  {
    name: "North Korea",
    nameAr: "كوريا الشمالية",
    code: "KP",
    timezone: "Korea Standard Time",
    utc: "UTC+09:00",
    mobileCode: "+850"
  },
  {
    name: "Northern Mariana Islands",
    nameAr: "جزر ماريانا الشمالية",
    code: "MP",
    timezone: "West Pacific Standard Time",
    utc: "UTC+10:00",
    mobileCode: "+1-670"
  },
  {
    name: "Norway",
    nameAr: "النرويج",
    code: "NO",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+47"
  },
  {
    name: "Oman",
    nameAr: "عمان",
    code: "OM",
    timezone: "Arabian Standard Time",
    utc: "UTC+04:00",
    mobileCode: "+968"
  },
  {
    name: "Pakistan",
    nameAr: "باكستان",
    code: "PK",
    timezone: "Pakistan Standard Time",
    utc: "UTC+05:00",
    mobileCode: "+92"
  },
  {
    name: "Palau",
    nameAr: "بالاو",
    code: "PW",
    timezone: "Tokyo Standard Time",
    utc: "UTC+09:00",
    mobileCode: "+680"
  },
  {
    name: "Palestinian Authority",
    nameAr: "السلطة الفلسطينية",
    code: "PS",
    timezone: "Egypt Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+970"
  },
  {
    name: "Panama",
    nameAr: "بنما",
    code: "PA",
    timezone: "SA Pacific Standard Time",
    utc: "UTC-05:00",
    mobileCode: "+507"
  },
  {
    name: "Papua New Guinea",
    nameAr: "بابوا غينيا الجديدة",
    code: "PG",
    timezone: "West Pacific Standard Time",
    utc: "UTC+10:00",
    mobileCode: "+675"
  },
  {
    name: "Paraguay",
    nameAr: "باراغواي",
    code: "PY",
    timezone: "Paraguay Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+595"
  },
  {
    name: "Peru",
    nameAr: "بيرو",
    code: "PE",
    timezone: "SA Pacific Standard Time",
    utc: "UTC-05:00",
    mobileCode: "+51"
  },
  {
    name: "Philippines",
    nameAr: "الفلبين",
    code: "PH",
    timezone: "Singapore Standard Time",
    utc: "UTC+08:00",
    mobileCode: "+63"
  },
  {
    name: "Pitcairn Islands",
    nameAr: "جزر بيتكيرن",
    code: "PN",
    timezone: "Pacific Standard Time",
    utc: "UTC-08:00",
    mobileCode: "+870"
  },
  {
    name: "Poland",
    nameAr: "بولندا",
    code: "PL",
    timezone: "Central European Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+48"
  },
  {
    name: "Portugal",
    nameAr: "البرتغال",
    code: "PT",
    timezone: "GMT Standard Time",
    utc: "UTC",
    mobileCode: "+351"
  },
  {
    name: "Puerto Rico",
    nameAr: "بورتو ريكو",
    code: "PR",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+1-787 and 1-939"
  },
  {
    name: "Qatar",
    nameAr: "قطر",
    code: "QA",
    timezone: "Arab Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+974"
  },
  {
    name: "Reunion",
    nameAr: "روينيون",
    code: "RE",
    timezone: "Mauritius Standard Time",
    utc: "UTC+04:00",
    mobileCode: "+262"
  },
  {
    name: "Romania",
    nameAr: "رومانيا",
    code: "RO",
    timezone: "GTB Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+40"
  },
  {
    name: "Russia",
    nameAr: "روسيا",
    code: "RU",
    timezone: "Russian Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+7"
  },
  {
    name: "Rwanda",
    nameAr: "رواندا",
    code: "RW",
    timezone: "South Africa Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+250"
  },
  {
    name: "Saint Barthélemy",
    nameAr: "سان بارتيلمي",
    code: "BL",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+590"
  },
  {
    name: "Saint Helena, Ascension and Tristan da Cunha",
    nameAr: "سانت هيلينا، أسنسيون وتريستان دا كونها",
    code: "SH",
    timezone: "Greenwich Standard Time",
    utc: "UTC",
    mobileCode: "+290"
  },
  {
    name: "Saint Kitts and Nevis",
    nameAr: "سانت كيتس ونيفيس",
    code: "KN",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+1-869"
  },
  {
    name: "Saint Lucia",
    nameAr: "سانت لوسيا",
    code: "LC",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+1-758"
  },
  {
    name: "Saint Martin (French part)",
    nameAr: "سانت مارتن (الجزء الفرنسي)",
    code: "MF",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+590"
  },
  {
    name: "Saint Pierre and Miquelon",
    nameAr: "سان بيير وميكلون",
    code: "PM",
    timezone: "Greenland Standard Time",
    utc: "UTC-03:00",
    mobileCode: "+508"
  },
  {
    name: "Saint Vincent and the Grenadines",
    nameAr: "سانت فينسنت وجزر غرينادين",
    code: "VC",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+1-784"
  },
  {
    name: "Samoa",
    nameAr: "ساموا",
    code: "WS",
    timezone: "Samoa Standard Time",
    utc: "UTC+13:00",
    mobileCode: "+685"
  },
  {
    name: "San Marino",
    nameAr: "سان مارينو",
    code: "SM",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+378"
  },
  {
    name: "São Tomé and Príncipe",
    nameAr: "ساو تومي وبرينسيبي",
    code: "ST",
    timezone: "Greenwich Standard Time",
    utc: "UTC",
    mobileCode: "+239"
  },
  {
    name: "Saudi Arabia",
    nameAr: "المملكة العربية السعودية",
    code: "SA",
    timezone: "Arab Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+966"
  },
  {
    name: "Senegal",
    nameAr: "السنغال",
    code: "SN",
    timezone: "Greenwich Standard Time",
    utc: "UTC",
    mobileCode: "+221"
  },
  {
    name: "Serbia",
    nameAr: "صربيا",
    code: "RS",
    timezone: "Central Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+381"
  },
  {
    name: "Seychelles",
    nameAr: "سيشل",
    code: "SC",
    timezone: "Mauritius Standard Time",
    utc: "UTC+04:00",
    mobileCode: "+248"
  },
  {
    name: "Sierra Leone",
    nameAr: "سيراليون",
    code: "SL",
    timezone: "Greenwich Standard Time",
    utc: "UTC",
    mobileCode: "+232"
  },
  {
    name: "Singapore",
    nameAr: "سنغافورة",
    code: "SG",
    timezone: "Singapore Standard Time",
    utc: "UTC+08:00",
    mobileCode: "+65"
  },
  {
    name: "Sint Maarten (Dutch part)",
    nameAr: "سانت مارتن (الجزء الهولندي)",
    code: "SX",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+599"
  },
  {
    name: "Slovakia",
    nameAr: "سلوفاكيا",
    code: "SK",
    timezone: "Central Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+421"
  },
  {
    name: "Slovenia",
    nameAr: "سلوفينيا",
    code: "SI",
    timezone: "Central Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+386"
  },
  {
    name: "Solomon Islands",
    nameAr: "جزر سليمان",
    code: "SB",
    timezone: "Central Pacific Standard Time",
    utc: "UTC+11:00",
    mobileCode: "+677"
  },
  {
    name: "Somalia",
    nameAr: "الصومال",
    code: "SO",
    timezone: "E. Africa Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+252"
  },
  {
    name: "South Africa",
    nameAr: "جنوب أفريقيا",
    code: "ZA",
    timezone: "South Africa Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+27"
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    nameAr: "جورجيا الجنوبية وجزر ساندويتش الجنوبية",
    code: "GS",
    timezone: "UTC-02",
    utc: "UTC-02:00",
    mobileCode: "+"
  },
  {
    name: "South Sudan",
    nameAr: "جنوب السودان",
    code: "SS",
    timezone: "E. Africa Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+211"
  },
  {
    name: "Spain",
    nameAr: "إسبانيا",
    code: "ES",
    timezone: "Romance Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+34"
  },
  {
    name: "Sri Lanka",
    nameAr: "سيريلانكا",
    code: "LK",
    timezone: "Sri Lanka Standard Time",
    utc: "UTC+05:30",
    mobileCode: "+94"
  },
  {
    name: "Sudan",
    nameAr: "السودان",
    code: "SD",
    timezone: "E. Africa Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+249"
  },
  {
    name: "Suriname",
    nameAr: "سورينام",
    code: "SR",
    timezone: "SA Eastern Standard Time",
    utc: "UTC-03:00",
    mobileCode: "+597"
  },
  {
    name: "Svalbard",
    nameAr: "سفالبارد",
    code: "SJ",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+47"
  },
  {
    name: "Swaziland",
    nameAr: "إسواتيني",
    code: "SZ",
    timezone: "South Africa Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+268"
  },
  {
    name: "Sweden",
    nameAr: "السويد",
    code: "SE",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+46"
  },
  {
    name: "Switzerland",
    nameAr: "سويسرا",
    code: "CH",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+41"
  },
  {
    name: "Syria",
    nameAr: "سوريا",
    code: "SY",
    timezone: "Syria Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+963"
  },
  {
    name: "Taiwan",
    nameAr: "تايوان",
    code: "TW",
    timezone: "Taipei Standard Time",
    utc: "UTC+08:00",
    mobileCode: "+886"
  },
  {
    name: "Tajikistan",
    nameAr: "طاجيكستان",
    code: "TJ",
    timezone: "West Asia Standard Time",
    utc: "UTC+05:00",
    mobileCode: "+992"
  },
  {
    name: "Tanzania",
    nameAr: "تنزانيا",
    code: "TZ",
    timezone: "E. Africa Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+255"
  },
  {
    name: "Thailand",
    nameAr: "تايلاند",
    code: "TH",
    timezone: "SE Asia Standard Time",
    utc: "UTC+07:00",
    mobileCode: "+66"
  },
  {
    name: "Togo",
    nameAr: "توغو",
    code: "TG",
    timezone: "Greenwich Standard Time",
    utc: "UTC",
    mobileCode: "+228"
  },
  {
    name: "Tokelau",
    nameAr: "توكيلاو",
    code: "TK",
    timezone: "Tonga Standard Time",
    utc: "UTC+13:00",
    mobileCode: "+690"
  },
  {
    name: "Tonga",
    nameAr: "تونغا",
    code: "TO",
    timezone: "Tonga Standard Time",
    utc: "UTC+13:00",
    mobileCode: "+676"
  },
  {
    name: "Trinidad and Tobago",
    nameAr: "ترينيداد وتوباغو",
    code: "TT",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+1-868"
  },
  {
    name: "Tunisia",
    nameAr: "تونس",
    code: "TN",
    timezone: "W. Central Africa Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+216"
  },
  {
    name: "Turkey",
    nameAr: "تركيا",
    code: "TR",
    timezone: "Turkey Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+90"
  },
  {
    name: "Turkmenistan",
    nameAr: "تركمانستان",
    code: "TM",
    timezone: "West Asia Standard Time",
    utc: "UTC+05:00",
    mobileCode: "+993"
  },
  {
    name: "Turks and Caicos Islands",
    nameAr: "جزر تركس وكايكوس",
    code: "TC",
    timezone: "Eastern Standard Time",
    utc: "UTC-05:00",
    mobileCode: "+1-649"
  },
  {
    name: "Tuvalu",
    nameAr: "توفالو",
    code: "TV",
    timezone: "UTC+12",
    utc: "UTC+12:00",
    mobileCode: "+688"
  },
  {
    name: "U.S. Minor Outlying Islands",
    nameAr: "جزر الولايات المتحدة البعيدة الصغيرة",
    code: "UM",
    timezone: "UTC-11",
    utc: "UTC-11:00",
    mobileCode: "+1"
  },
  {
    name: "Uganda",
    nameAr: "أوغندا",
    code: "UG",
    timezone: "E. Africa Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+256"
  },
  {
    name: "Ukraine",
    nameAr: "أوكرانيا",
    code: "UA",
    timezone: "FLE Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+380"
  },
  {
    name: "United Arab Emirates",
    nameAr: "الإمارات العربية المتحدة",
    code: "AE",
    timezone: "Arabian Standard Time",
    utc: "UTC+04:00",
    mobileCode: "+971"
  },
  {
    name: "United Kingdom",
    nameAr: "المملكة المتحدة",
    code: "GB",
    timezone: "GMT Standard Time",
    utc: "UTC",
    mobileCode: "+44"
  },
  {
    name: "United States",
    nameAr: "الولايات المتحدة",
    code: "US",
    timezone: "Pacific Standard Time",
    utc: "UTC-08:00",
    mobileCode: "+1"
  },
  {
    name: "Uruguay",
    nameAr: "أوروغواي",
    code: "UY",
    timezone: "Montevideo Standard Time",
    utc: "UTC-03:00",
    mobileCode: "+598"
  },
  {
    name: "Uzbekistan",
    nameAr: "أوزبكستان",
    code: "UZ",
    timezone: "West Asia Standard Time",
    utc: "UTC+05:00",
    mobileCode: "+998"
  },
  {
    name: "Vanuatu",
    nameAr: "فانواتو",
    code: "VU",
    timezone: "Central Pacific Standard Time",
    utc: "UTC+11:00",
    mobileCode: "+678"
  },
  {
    name: "Vatican City",
    nameAr: "الفاتيكان",
    code: "VA",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+379"
  },
  {
    name: "Vietnam",
    nameAr: "فيتنام",
    code: "VN",
    timezone: "SE Asia Standard Time",
    utc: "UTC+07:00",
    mobileCode: "+84"
  },
  {
    name: "Virgin Islands, U.S.",
    nameAr: "جزر العذراء الأمريكية",
    code: "VI",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+1-340"
  },
  {
    name: "Virgin Islands, British",
    nameAr: "جزر العذراء البريطانية",
    code: "VG",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+1-284"
  },
  {
    name: "Wallis and Futuna",
    nameAr: "والس وفوتونا",
    code: "WF",
    timezone: "UTC+12",
    utc: "UTC+12:00",
    mobileCode: "+681"
  },
  {
    name: "Yemen",
    nameAr: "اليمن",
    code: "YE",
    timezone: "Arab Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+967"
  },
  {
    name: "Zambia",
    nameAr: "زامبيا",
    code: "ZM",
    timezone: "South Africa Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+260"
  },
  {
    name: "Zimbabwe",
    nameAr: "زيمبابوي",
    code: "ZW",
    timezone: "South Africa Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+263"
  }
];
