import { FC } from "react";
import Paper from "@mui/material/Paper";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grow from "@mui/material/Grow";
import styles from "./styles";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { ReactSVG } from "react-svg";
import Button from "components/custom_button";
import upload from "assets/svgs/upload_podcast.svg";
import cancelIc from "assets/svgs/cancel.svg";
import { LivetipsPodcastSliceActions } from "store/livetips_podcast_slice";
import { MODAL_ACTION_TYPE, ProfileSliceActions } from "store/profile_slice";
import { FormattedMessage } from "react-intl";
import messages from "pages/view_admin_profile/messages";
import { Box } from "@mui/material";
import PrivacyPolicy from "components/privacy_policy";

const StepGuidanceModal: FC = () => {
  const showModal = useAppSelector(
    (state) => state.livetipsPodcast.showGuideModal
  );

  const dispatch = useAppDispatch();

  const handleCancel = () => {
    dispatch(ProfileSliceActions.clearActionType());
    dispatch(LivetipsPodcastSliceActions.closeModals());
  };

  const handleNext = () => {
    dispatch(ProfileSliceActions.setActionType(MODAL_ACTION_TYPE.podcast));
    dispatch(LivetipsPodcastSliceActions.showPodcastPremier());
  };

  return (
    <Modal open={showModal} sx={styles.container}>
      <Grow in={showModal} timeout={600}>
        <Paper sx={styles.paper}>
          <Stack
            width="100%"
            direction="row"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
          >
            <Typography flexGrow={0.9} sx={styles.header}>
              <FormattedMessage id={messages.podcast.id} />
            </Typography>
            <ReactSVG
              onClick={handleCancel}
              style={{ cursor: "pointer", paddingBottom: "25px" }}
              src={cancelIc}
            />
          </Stack>
          <ReactSVG src={upload} />
          <Typography sx={styles.instruction}>
            <FormattedMessage id={messages.users_can.id} />
          </Typography>
          <Typography sx={styles.notice}>
            <FormattedMessage id={messages.privacy.id} />
          </Typography>
          <Button
            onClick={() => handleNext()}
            extraStyles={styles.button}
            label="Next Step"
          />
          <Typography sx={styles.privacy}>
            <FormattedMessage id={messages.notice.id} />
          </Typography>
          <Box sx={{ paddingBottom: 3 }}>
            <PrivacyPolicy />
          </Box>
        </Paper>
      </Grow>
    </Modal>
  );
};

export default StepGuidanceModal;
