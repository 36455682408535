import { Methods } from "types/http";
import apiUrls from "../urls";
import { GetById, MembershipRequest, MembershipResponse } from "../types";
import { MembershipQuery } from "types/membershipQuery";
import handleFormDataRequests from "utils/upload_form_data";

export const getMembershipList = (param: MembershipQuery) => ({
  url: apiUrls.getMembershipList(param),
  method: Methods.GET,
  headers: {
    Lng: "EN",
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${param.token}`,
  },
});

export const createMembershipAction = ({ body, token }: MembershipRequest) => ({
  url: apiUrls.postMembership(),
  method: Methods.POST,
  body,
  headers: {
    Lng: "EN",
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
});

export const uploadMembershipAction = async ({
  body,
  token,
}: MembershipRequest) => {
  const res = await handleFormDataRequests<MembershipResponse>({
    url: apiUrls.postMembership(),
    code: Methods.POST,
    body,
    headers: {
      Lng: "EN",
      Accept: "application/json",
      "Accept-Encoding": "gzip, deflate, br",
      "Content-Type": "multipart/form-data; boundry=6000000000",
      Authorization: `Bearer ${token}`,
    },
  });
  return res;
};

export const updateMembershipAction = async ({
  body,
  token,
}: MembershipRequest) => {
  const res = await handleFormDataRequests({
    url: apiUrls.membershipById(body.id.toString()),
    code: Methods.PATCH,
    body,
    headers: {
      Lng: "EN",
      Accept: "application/json",
      "Accept-Encoding": "gzip, deflate, br",
      "Content-Type": "multipart/form-data; boundry=6000000000",
      Authorization: `Bearer ${token}`,
    },
  });
  return res;
};

export const getMembershipByIdAction = ({ id, token }: GetById) => ({
  url: apiUrls.membershipById(id),
  method: Methods.GET,
  headers: {
    Lng: "EN",
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
});

export const updateMembershipByIdAction = (id: string) => ({
  url: apiUrls.membershipById(id),
  method: Methods.PUT,
});

export const deleteMembershipByIdAction = (id: string) => ({
  url: apiUrls.membershipById(id),
  method: Methods.DELETE,
});

export const partialUpdateMembershipByIdAction = (id: string) => ({
  url: apiUrls.membershipById(id),
  method: Methods.PATCH,
});
