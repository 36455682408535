import { FC, useEffect } from "react";
import {
  CircularProgress,
  Grow,
  Modal,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "store/hooks";
import styles from "./styles";
import SpeakerCheckboxListItem from "components/speaker_checkbox_list_item";
import { useGetMembershipListQuery } from "store/api/membership_service/endpoints";
import { UploadedFileStatus } from "types/uploaded_file_status";
import { MembersSliceActions } from "store/members_slice";
import { UploadPrivacy } from "types/upload_privacy";
import Button from "components/custom_button";
import { FormattedMessage, useIntl } from "react-intl";
import messages from "../messages";

const SpeakerList: FC = () => {
  const members = useAppSelector((state) => state.members.invitedSpeakers);

  return (
    <Stack sx={{ paddingBlock: 2 }} direction="column" rowGap={1.5}>
      {members &&
        members.length > 0 &&
        members.map((entry) => (
          <SpeakerCheckboxListItem key={entry.id} {...entry} />
        ))}
    </Stack>
  );
};

const InviteSpeakerModal: FC = () => {
  const show = useAppSelector(
    (state) => state.adminProfil.modals.showInviteSpeakers
  );

  /* const invitedMembers = useAppSelector(
    (state) => state.members.invitedSpeakers
  ); */

  const dispatch = useAppDispatch();

  const { access_token } = useAppSelector(
    (state) => state.global.credentials.token
  );

  const { data: members, isLoading } = useGetMembershipListQuery(
    {
      token: access_token,
      status: UploadedFileStatus.ACCEPTED,
      privacy: UploadPrivacy.PUBLIC,
    },
    { refetchOnFocus: true, refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    dispatch(MembersSliceActions.submitSpeakers(members ?? []));
  }, [members]);

  const handleSubmitInvite = () => {
    /* const toInvite = invitedMembers.filter((entry) => entry.checked); */
    /* TODO: DO POST REQUEST TO INVITE  */
  };

  const intl = useIntl();

  return (
    <Modal sx={styles.root} open={show}>
      <Grow in={show} timeout={600}>
        <Paper sx={styles.container}>
          <Typography
            sx={{ textAlign: "center", fontSize: { lg: 25 }, paddingBlock: 5 }}
          >
            <FormattedMessage id={messages.verified_speakers.id} />
          </Typography>
          {isLoading && <CircularProgress />}
          <SpeakerList />
          <Button
            onClick={handleSubmitInvite}
            extraStyles={{ width: 100 }}
            label={intl.formatMessage(messages.invite)}
          />
        </Paper>
      </Grow>
    </Modal>
  );
};

export default InviteSpeakerModal;
