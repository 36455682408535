import rootApi from "store/api";
import { getBlogById, getBlogList, postBlog } from "./action_creators";
import { Blog } from "./types";
import { Identification } from "types/identification";

export const blogServiceApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getBlogs: builder.query<Array<Blog>, { token: string }>({
      query: (body) => getBlogList(body),
    }),
    getBlogById: builder.query<Blog, { token: string; id: Identification }>({
      query: (body) => getBlogById(body),
    }),
    postBlog: builder.mutation<Blog, { token: string; body: Blog }>({
      query: (body) => postBlog(body),
    }),
  }),
});

export const { useGetBlogByIdQuery, useGetBlogsQuery, usePostBlogMutation } =
  blogServiceApi;
