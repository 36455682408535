import rootApi from "store/api";
import {
  createMembershipAction,
  deleteMembershipByIdAction,
  getMembershipByIdAction,
  getMembershipList,
  partialUpdateMembershipByIdAction,
  updateMembershipByIdAction,
} from "./action_creators";
import { GetById, MembershipRequest, UserResponse } from "../types";
import { MembershipQuery } from "types/membershipQuery";
import { Identification } from "types/identification";

export type UploadVideoRequest = {
  token: string;
  body: FormData;
};

export type FlattenedMember = {
  profile_picture: string;
  id: Identification;
  name: string;
  org: string;
};

export const membershipServiceApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getMembershipList: builder.query<Array<FlattenedMember>, MembershipQuery>({
      query: (body) => getMembershipList(body),
      transformResponse: (response: { results: Array<UserResponse> }) => {
        if (!response) return [];
        const res = response.results.map((entry) => ({
          name: entry.user.name,
          profile_picture: entry.user.profilePicture,
          id: entry.user.customerId.toString(),
          org: entry.user.org,
        }));
        return res;
      },
    }),
    getDetailedMemberList: builder.query<
      { results: Array<UserResponse> },
      MembershipQuery
    >({
      query: (body) => getMembershipList(body),
    }),
    postMembership: builder.mutation<void, MembershipRequest>({
      query: (body) => createMembershipAction(body),
    }),
    getMembershipById: builder.query<UserResponse, GetById>({
      query: (param) => getMembershipByIdAction(param),
    }),
    deleteMembershipById: builder.mutation<void, string>({
      query: (id) => deleteMembershipByIdAction(id),
    }),
    updateMembershipById: builder.mutation<void, string>({
      query: (id) => updateMembershipByIdAction(id),
    }),
    partialUpdateMembershipById: builder.mutation<void, string>({
      query: (id) => partialUpdateMembershipByIdAction(id),
    }),
  }),
});

export const {
  useDeleteMembershipByIdMutation,
  useGetMembershipByIdQuery,
  useGetMembershipListQuery,
  useGetDetailedMemberListQuery,
  usePartialUpdateMembershipByIdMutation,
  usePostMembershipMutation,
  useUpdateMembershipByIdMutation,
} = membershipServiceApi;
