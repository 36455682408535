import { FC } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import styles from "./styles";
import GeneralInfoForm from "../general_info_form";
import UploadDocumentForm from "../upload_document_form";
import NominationForm from "../nomination_form";
import { FormattedMessage } from "react-intl";
import messages from "./messages";

const FormSection: FC = () => {
  return (
    <>
      <Box sx={styles.labels}>
        <Typography sx={styles.header}>
          <FormattedMessage id={messages.speaker_form.id} />
        </Typography>
        <Typography sx={styles.subHeader}>
          <FormattedMessage id={messages.fill_form.id} />
        </Typography>
      </Box>
      <Box sx={styles.container}>
        <GeneralInfoForm />
        <UploadDocumentForm />
        <NominationForm />
      </Box>
    </>
  );
};

export default FormSection;
