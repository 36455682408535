import { FC } from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import RelatedVideoItem from "components/related_video_item";
import { UploadedFileStatus } from "types/uploaded_file_status";
import { useGetPodcastListQuery } from "store/api/podcast_api/endpoints";
import { PodcastItemResponse } from "store/api/podcast_api/types";
import { useAppSelector } from "store/hooks";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import styles from "./styles";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "navigation/routes";
import { LIST_TYPE } from "types/list_types";
import { FormattedMessage } from "react-intl";
import messages from "../messages";
import { Identification } from "types/identification";
import cropText from "utils/crop_text";

const MyPodcasts: FC = () => {
  const navigate = useNavigate();

  const {
    global: { credentials },
    adminProfil: { currentMember },
  } = useAppSelector((state) => state);

  const { data } = useGetPodcastListQuery(
    {
      token: credentials.token.access_token,
      user: currentMember?.user.customerId,
      status: UploadedFileStatus.ACCEPTED,
    },
    {
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
    }
  );

  const videosList: Array<PodcastItemResponse> = data?.results ?? [];

  const handleDisplayAll = () => {
    navigate(`${NavigationRoutes.myVideos}/${currentMember?.user.customerId}`, {
      state: {
        listType: LIST_TYPE.PODCAST,
        username: currentMember?.user.name,
      },
    });
  };

  const handlePodcastDetails = (id: Identification) => {
    navigate(`${NavigationRoutes.podcasts}/${id}`);
  };

  return (
    <Stack sx={styles.container}>
      {videosList && videosList.length > 0 && (
        <>
          <Stack
            onClick={handleDisplayAll}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ paddingInlineEnd: 2 }}
          >
            <Typography sx={styles.sectionLabel}>
              <FormattedMessage id={messages.podcast_content.id} />
            </Typography>
            <ArrowForwardRoundedIcon
              onClick={handleDisplayAll}
              sx={{ opacity: 0.6, cursor: "pointer" }}
            />
          </Stack>
          <Stack rowGap={1} mt={1.5}>
            {videosList?.slice(0, 3).map((entry) => (
              <RelatedVideoItem
                includeStats={false}
                onClick={() => handlePodcastDetails(entry.id)}
                likes_count={0}
                shares_count={0}
                views_count={0}
                switchSides
                key={entry.id}
                name={entry.user.name}
                description={cropText(entry.description, 70)}
                thumbnail={entry.thumbnail}
              />
            ))}
          </Stack>
        </>
      )}
    </Stack>
  );
};

export default MyPodcasts;
