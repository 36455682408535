import rootApi from "store/api";
import {
  getAllCategoriesAction,
  submitCategoryAction,
  getCustomerAction,
} from "./action_creators";
import { CategoryItem } from "../types";

export const CategoryServiceApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    addCategory: builder.mutation<void, CategoryItem>({
      query: (body) => submitCategoryAction(body),
    }),
    getAllCategories: builder.query<Array<CategoryItem>, { token: string }>({
      query: (body) => getAllCategoriesAction(body),
    }),
    getCustomer: builder.query<CategoryItem, string>({
      query: (id) => getCustomerAction(id),
    }),
  }),
});

export const {
  useAddCategoryMutation,
  useGetAllCategoriesQuery,
  useGetCustomerQuery,
} = CategoryServiceApi;
