import { ComponentType } from "react";
import { useAppSelector } from "store/hooks";
import { Box } from "@mui/material";

function withRTL(Component: ComponentType) {
  return () => {
    const { direction } = useAppSelector((state) => state.global.lang);

    return (
      <Box component="div" dir={direction}>
        <Component />
      </Box>
    );
  };
}

export default withRTL;
