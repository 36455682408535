import { Identification } from "types/identification";
import { MEDIA_TYPE } from "types/media_type";

export const enum NOTIFICATION_REPLY_TYPE {
  REPLY = "REPLY",
  SHARE = "SHARE",
}

export type NotificationType = {
  actionId?: Identification;
  actionType: NOTIFICATION_REPLY_TYPE;
  digitalMediumId?: Identification;
  digitalMediumType: MEDIA_TYPE;
  receiverId: Identification;
  senderId: Identification;
  notificationId?: Identification;
  triggeredBy: "USER";
  senderName: string;
  senderPic: string;
  messageEn: string;
  messageAr?: string;
  action?: string;
  createdAt?: string;
  updatedAt?: string;
  read?: boolean;
};
