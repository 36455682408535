import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    width: "100%",
    height: {
      xs: "95%",
      sm: "95%",
      md: "80%",
    },
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 20,
  },
  headerContainer: {
    background:
      "linear-gradient(117deg, #227C5A 22.35%, rgba(34, 124, 90, 0) 157.61%)",
    borderTopRightRadius: 15,
    borderTopLeftRadius: 15,
    marginTop: 5,
    marginX: "auto",
    width: {
      xs: "100%",
      sm: "80%",
      md: "60%",
      lg: "50%",
    },
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingBlock: 2,
  },
  orSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingY: 3,
  },
  font: {
    fontWeight: "700",
    color: "white",
  },
  cardStyle: {
    height: {
      sm: "100%",
      md: "80%",
    },
    width: {
      xs: "100%",
      sm: "80%",
      md: "60%",
      lg: "50%",
    },
    backgroundColor: "#F3F3F3",
  },
  orLabel: { marginX: 5, opacity: 0.6, textAlign: "center" },
  center: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
};

export default styles;
