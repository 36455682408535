import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  GenerateTokenResponse,
  RegisterResponse,
  UserByIdResponse,
} from "store/api/types";
import { Local } from "types/local";
import clearStorage from "utils/clear_storage";
import { GlobalState, LanguageDirection } from "./types";
import { globalInitialState } from "./state";
import { ROLE } from "types/role";

const initialState: GlobalState = globalInitialState;

const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    changeLanguage: (state: GlobalState, { payload }: PayloadAction<Local>) => {
      state.lang.prefix = payload;
      payload === Local.Arabic
        ? (state.lang.direction = LanguageDirection.rightToLeft)
        : (state.lang.direction = LanguageDirection.leftToRight);
      return state;
    },
    setActiveMenuItem: (
      state: GlobalState,
      { payload }: PayloadAction<string>
    ) => {
      state.activeMenuItem = payload;
      return state;
    },
    changeTheme: (state: GlobalState) => {
      !state.darkTheme;
      return state;
    },
    submitUserTransactionId: (
      state: GlobalState,
      { payload }: PayloadAction<string>
    ) => {
      state.credentials.transactionId = payload;
      return state;
    },
    submitUserCredentials: (
      state: GlobalState,
      { payload }: PayloadAction<GenerateTokenResponse>
    ) => {
      state.credentials.token = payload;
      return state;
    },
    setSpeakerProfile: (
      state: GlobalState,
      { payload }: PayloadAction<RegisterResponse>
    ) => {
      state.speakerProfile = payload;
      return state;
    },
    setToken: (state: GlobalState, { payload }: PayloadAction<string>) => {
      state.credentials.token.access_token = payload;
      return state;
    },
    submitUserData: (
      state: GlobalState,
      { payload }: PayloadAction<UserByIdResponse>
    ) => {
      state.speakerProfile = payload;
      return state;
    },
    submitValidationData: (
      state: GlobalState,
      { payload }: PayloadAction<UserByIdResponse>
    ) => {
      state.speakerProfile = payload;
      return state;
    },
    submitUserUpdate: (
      state: GlobalState,
      { payload }: PayloadAction<UserByIdResponse>
    ) => {
      state.speakerProfile = payload;
      return state;
    },
    clearStorage: () => {
      clearStorage();
    },
    signout: (state: GlobalState) => {
      state.speakerProfile = globalInitialState.speakerProfile;
      state.credentials = globalInitialState.credentials;
      return state;
    },
    setRole: (state: GlobalState, { payload }: PayloadAction<ROLE>) => {
      state.speakerProfile.role = payload;
      state.permission = payload;
      return state;
    },
  },
});

export default globalSlice.reducer;
export const globalSliceActions = globalSlice.actions;
