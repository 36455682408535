import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    width: "100%",
    marginTop: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundImage: "src('src/assets/images/home_about_bg.png') no-repeat",
  },
  title: {
    fontSize: { lg: 35, xs: 25 },
    lineHeight: 1.7,
    width: "100%",
    fontWeight: "200",
  },
  desc: {
    fontSize: { lg: 17, xs: 20 },
    fontFamily: "'Nizar Bukra', K2D, Inter",
    fontWeight: "200",
    marginInline: 5,
    textAlign: "center",
    width: "100%",
    lineHeight: 1.7,
  },
};

export default styles;
