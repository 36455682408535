import { SxProps } from "@mui/material";
import color_pallette from "theme/color_pallette";

const styles: Record<string, SxProps> = {
  paper: {
    borderRadius: 3,
  },
  container: {
    marginX: "auto",
    textAlign: "center",
    marginTop: {
      xs: 10,
      lg: 5,
    },
    width: {
      xs: "90%",
      sm: "85%",
      md: 700,
      lg: 800,
    },
    height: {
      xs: 500,
      sm: 700,
    },
  },
  header: {
    color: color_pallette.primary,
    fontSize: {
      xs: 30,
    },
    fontWeight: "600",
    paddingBlock: 3,
  },
  instruction: {
    fontSize: {
      xs: 20,
    },
    fontWeight: "300",
    paddingTop: 2,
    paddingBottom: 0.5,
  },
  notice: {
    fontSize: {
      xs: 17,
    },
    fontWeight: "300",
  },
  button: {
    width: 200,
    marginBlock: 2,
    paddingBlock: 1.5,
    fontSize: {
      xs: 18,
    },
  },
  privacy: {
    fontSize: {
      xs: 12,
    },
    width: { xs: "85%" },
    marginX: "auto",
    paddingBlock: 2.5,
  },
};

export default styles;
