import rootApi from "..";
import { PodcastItemResponse, PodcastListResponse } from "./types";
import { VideoQuery } from "types/video_query";
import { VideoDetailsRequest } from "../video_service/endpoints";
import { getPodcastByIdAC, getPodcastListAC } from "./action_creators";

export const podcastApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getPodcastList: builder.query<PodcastListResponse, VideoQuery>({
      query: (params) => getPodcastListAC(params),
    }),
    getPodcastById: builder.query<PodcastItemResponse, VideoDetailsRequest>({
      query: (param) => getPodcastByIdAC(param),
    }),
    deletePodcastById: builder.mutation<void, VideoDetailsRequest>({
      query: (param) => getPodcastByIdAC(param),
    }),
  }),
});

export const {
  useGetPodcastByIdQuery,
  useDeletePodcastByIdMutation,
  useGetPodcastListQuery,
} = podcastApi;
