import { FC, useContext, useEffect, useState } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import Grow from "@mui/material/Grow";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import MembersContext from "context/explore_members";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import styles from "./styles";

type Props = {
  filterValue: string;
  items?: Array<{ label: string; value: string | number }>;
};

const FilterComponent: FC<Props> = ({ filterValue, items }) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [selected, setSelected] = useState<string>("1");

  const { setActiveCategory, setLocation } = useContext(MembersContext);

  const handleOnChange = (value: string) => {
    setSelected(value.toString());
    if (filterValue === "Subject" && setActiveCategory)
      setActiveCategory(value);
    if (filterValue === "Location" && setLocation) setLocation(value);
  };

  useEffect(() => {
    return () => {
      setSelected("");
      setVisible(false);
    };
  }, []);

  return (
    <>
      <Paper
        onClick={() => setVisible((visible) => !visible)}
        sx={styles.container}
      >
        <Typography sx={styles.recentValue}>{filterValue}</Typography>
        <Box onClick={() => setVisible((visible) => !visible)}>
          {visible ? <KeyboardArrowDownIcon /> : <ArrowForwardIosIcon />}
        </Box>
      </Paper>
      <Stack justifyContent="center">
        {visible &&
          items &&
          items.map((entry) => (
            <Grow in={visible} timeout={600}>
              <FormControlLabel
                key={entry.value}
                sx={{ cursor: "pointer" }}
                label={entry.label}
                control={
                  <Checkbox
                    value={entry.value}
                    checked={entry.value.toString() === selected}
                    onChange={() => handleOnChange(entry.value.toString())}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
              />
            </Grow>
          ))}
      </Stack>
    </>
  );
};

export default FilterComponent;
