import { defineMessages } from "react-intl";

const scope = "homepagesearchbar";

export default defineMessages({
  search: {
    id: `${scope}.search`,
    defaultMessage: "Search",
  },
});
