import { FC } from "react";
import Typography from "@mui/material/Typography";
import Grow from "@mui/material/Grow";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import Stack from "@mui/material/Stack";
import styles from "./styles";

const LanguagePicker: FC = () => {
  return (
    <Grow in timeout={700}>
      <Stack direction="row" alignItems="center" sx={styles.container}>
        <Typography>English</Typography>
        <ArrowDropDown />
      </Stack>
    </Grow>
  );
};

export default LanguagePicker;
