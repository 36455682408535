import { defineMessages } from 'react-intl';

const scope = 'ExploreUpcomingPodcasts';

export default defineMessages({
  upcoming: {
    id: `${scope}.upcoming`,
    defaultMessage: "Up Coming",
  },
  upcoming_podcasts: {
    id: `${scope}.upcoming_podcasts`,
    defaultMessage: "Up Coming Podcasts",
  },
  podcasts: {
    id: `${scope}.podcasts`,
    defaultMessage: "Podcasts",
  },
  home: {
    id: `${scope}.home`,
    defaultMessage: "Home",
  },
  search: {
    id: `${scope}.search`,
    defaultMessage: "Search",
  },
});