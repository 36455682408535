import { FC, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import styles from "./styles";
import BodySection from "./body_section";
import HistorySection from "./history_section";
import RelatedPodcastsSection from "./related_podcasts_section";
import HostSection from "./host_section";
import PodcastControls from "components/podcast_controls";
import AudioContext from "context/audio_context";
import PodcastDetailSkeleton from "components/podcast_detail_skeleton";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { useGetPodcastByIdQuery } from "store/api/podcast_api/endpoints";
import { LivetipsPodcastSliceActions } from "store/livetips_podcast_slice";
import { NavigationRoutes } from "navigation/routes";
import { usePostViewMutation } from "store/api/view_service/endpoints";
import { MEDIA_TYPE } from "types/media_type";
import { View } from "store/api/view_service/types";
import useSnack from "hooks/useSnack";
import { modalSliceActions } from "store/modal_slice";
import EditPodcastModal from "components/edit_podcast_modal";
import FormContainer from "common/form_container";
import {
  initialValues,
  UserEditPodcastFormNames,
  validationSchema,
} from "components/edit_podcast_modal/form";
import { FormikValues } from "formik";
import { updatePodcastFormData } from "store/api/podcast_api/action_creators";
import { UploadedFileStatus } from "types/uploaded_file_status";
import { EditPodcastFormNames } from "pages/view_admin_profile/create_podcast/edit_podcast_modal/form";

const PodcastDetails: FC = () => {
  const [audioFile, setAudioFile] = useState<HTMLAudioElement | null>(null);

  const navigate = useNavigate();

  const { speakerProfile } = useAppSelector((state) => state.global);

  const { access_token } = useAppSelector(
    (state) => state.global.credentials.token
  );

  const { currentRecord } = useAppSelector((state) => state.livetipsPodcast);

  const dispatch = useAppDispatch();

  const params = useParams();

  const { data: podcastDetails } = useGetPodcastByIdQuery(
    { token: access_token, id: params.id as string },
    { refetchOnFocus: true, refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    if (podcastDetails) {
      const audio = new Audio(podcastDetails.media);
      dispatch(
        LivetipsPodcastSliceActions.insertCurrentPodcast({
          media: podcastDetails.media,
          podcastId: podcastDetails.id,
          hostName: podcastDetails.user.name,
          profilePic: podcastDetails.user.speaker.profile_picture ?? "",
          duration: 0,
          length: 0,
          playing: false,
          progress: 0,
          playbackSpeed: 1,
          publisherId: podcastDetails.user.customerId,
        })
      );
      setAudioFile(audio);
    }
  }, [podcastDetails, dispatch]);

  const [view] = usePostViewMutation();

  const { triggerSnack } = useSnack();

  async function markAsViewed() {
    if (podcastDetails) {
      try {
        (await view({
          body: {
            userId: speakerProfile.customerId,
            ownerId: podcastDetails.user.customerId,
            videoId: podcastDetails.id,
            digitalMediumType: MEDIA_TYPE.ON_DEMAND_PODCAST,
          },
          token: access_token,
        })) as { data: View };
      } catch (error) {
        triggerSnack(
          "Something Unexpected happened ! please try again",
          "error"
        );
      }
    }
  }

  useEffect(() => {
    markAsViewed();
  }, []);

  useEffect(() => {
    toggleState();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRecord.playing]);

  useEffect(() => {
    return () => {
      audioFile?.pause();
      dispatch(LivetipsPodcastSliceActions.clearCurrentPodcast());
    };
  }, [dispatch, audioFile]);

  const toggleState = () => {
    if (audioFile && audioFile.HAVE_ENOUGH_DATA)
      currentRecord.playing ? audioFile?.play() : audioFile.pause();
  };

  const handleViewProfile = () => {
    navigate(
      `${NavigationRoutes.public_speaker_profile}/${podcastDetails?.user.customerId}`
    );
  };

  const handleEditContent = () => {
    dispatch(
      LivetipsPodcastSliceActions.setToEditPodcast(
        podcastDetails ? podcastDetails : null
      )
    );
    dispatch(modalSliceActions.setEditPodcast(true));
  };

  const handleUpdatePodcast = async (values: FormikValues) => {
    console.log(values);
    if (!podcastDetails) return;

    try {
      await updatePodcastFormData({
        token: access_token,
        body: {
          ...values,
          id: podcastDetails.id,
          user: speakerProfile.customerId,
          status: UploadedFileStatus.PENDING,
          privacy: values[EditPodcastFormNames.exclusivity],
        },
      });
      dispatch(modalSliceActions.setEditPodcast(false));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    return () => {
      dispatch(modalSliceActions.setEditPodcast(false));
    };
  }, []);

  if (!podcastDetails) return null;

  return (
    <>
      <Box sx={styles.container}>
        {podcastDetails ? (
          <AudioContext.Provider value={{ setAudioFile, audioFile }}>
            <BodySection podcastDetails={podcastDetails} />
            <PodcastControls
              handleEditContent={handleEditContent}
              userId={podcastDetails.user.customerId}
              profilePic={podcastDetails.user.profilePicture}
              hostName={podcastDetails.user.name}
            />
          </AudioContext.Provider>
        ) : (
          <PodcastDetailSkeleton />
        )}
        <Grid
          container
          width={{ xs: "95%" }}
          columnGap={5}
          marginX={{ xs: "auto", md: "10px" }}
          justifyContent="center"
        >
          <HostSection
            onClick={handleViewProfile}
            image={podcastDetails?.user.profilePicture}
            name={podcastDetails?.user?.name}
          />
          <HistorySection name={podcastDetails?.user?.name} />
        </Grid>
        <FormContainer
          initialValues={{
            ...initialValues,
            [UserEditPodcastFormNames.videoTitle]: podcastDetails.title,
            [UserEditPodcastFormNames.description]: podcastDetails.description,
            [UserEditPodcastFormNames.thumbnail]: podcastDetails.thumbnail,
            category: podcastDetails?.category.categoryId,
          }}
          validationSchema={validationSchema}
          onSubmit={handleUpdatePodcast}
        >
          <EditPodcastModal />
        </FormContainer>
        <RelatedPodcastsSection />
      </Box>
    </>
  );
};

export default PodcastDetails;
