import { FC, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import styles from "./styles";
import Button from "components/custom_button";
import { UploadedFileStatus } from "types/uploaded_file_status";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "navigation/routes";
import { useAppDispatch } from "store/hooks";
import { ProfileSliceActions } from "store/profile_slice";
import { LivetipsPodcastSliceActions } from "store/livetips_podcast_slice";
import useVideoUpload from "hooks/use_video_upload";
import SnackBar from "components/snack_bar";
import UploadProgress from "components/upload_progress";

const FooterSection: FC = () => {
  const dispatch = useAppDispatch();

  const {
    handleBackToEdit,
    isLoading,
    uploadedVideoId,
    handleChangeStatus,
    showPreview,
    showError,
  } = useVideoUpload();

  const navigate = useNavigate();

  useEffect(() => {
    if (showPreview) {
      dispatch(LivetipsPodcastSliceActions.closeModals());
      dispatch(ProfileSliceActions.clearUploadFiles());
      navigate(`${NavigationRoutes.services_online_video}/${uploadedVideoId}`);
    }
  }, [showPreview, dispatch, navigate, uploadedVideoId]);

  const handleUpload = (status: UploadedFileStatus) => {
    handleChangeStatus(status);
  };

  return (
    <Stack sx={styles.container}>
      <SnackBar />
      {!isLoading && (
        <Button
          onClick={() => handleBackToEdit()}
          variant="outlined"
          extraStyles={styles.backButton}
          label="Back"
        />
      )}
      {isLoading && !showError && <UploadProgress isLoading={isLoading} />}
      {!isLoading && (
        <Box>
          <Button
            onClick={() => handleUpload(UploadedFileStatus.PENDING)}
            extraStyles={styles.publish}
            label="Publish"
          />
          <Button
            onClick={() => handleUpload(UploadedFileStatus.DRAFTED)}
            variant="outlined"
            extraStyles={styles.backButton}
            label="Draft"
          />
        </Box>
      )}
    </Stack>
  );
};

export default FooterSection;
