import { FC, useEffect } from "react";
import Box from "@mui/material/Box";
import styles from "./styles";
import ScreenNavigationTracker from "components/screen_navigation_tracker";
import { NavigationRoutes } from "navigation/routes";
import { Paper, PaperProps, Stack, SxProps, Typography } from "@mui/material";
import inside from "assets/svgs/inside_livetips.svg";
import outside from "assets/svgs/outside_livetips.svg";
import { useNavigate } from "react-router-dom";
import color_pallette from "theme/color_pallette";
import combineStyles from "utils/combile_styles";
import { useIntl } from "react-intl";
import messages from "./messages";
import { ReactSVG } from "react-svg";
import { useAppSelector } from "store/hooks";

export type ScopeProps = {
  icon: string;
  handleClick: () => void;
  label: string;
  highlight?: boolean;
  extraStyles?: SxProps<PaperProps>;
};

const Scope: FC<ScopeProps> = ({
  icon,
  highlight = false,
  handleClick,
  extraStyles,
  label,
}) => {
  return (
    <Stack
      onClick={() => handleClick()}
      rowGap={2}
      direction="column"
      alignItems="center"
    >
      <Paper sx={combineStyles(styles.scopeContainer, extraStyles)}>
        {/* <Box component="img" src={icon} /> */}
        <ReactSVG style={{ height: 100, width: 100 }} src={icon} />
      </Paper>
      <Typography
        sx={{
          textAlign: "center",
          cursor: "pointer",
          backgroundColor: highlight ? color_pallette.primary : "white",
          borderRadius: 2,
          padding: 1,
          paddingInline: 3,
          color: highlight ? "white" : "black",
          fontSize: { lg: 20 },
          fontWeight: "300",
        }}
      >
        {label}
      </Typography>
    </Stack>
  );
};

const NominationScope: FC = () => {
  const intl = useIntl();

  const access_token = useAppSelector(
    (state) => state.global.credentials.token.access_token
  );

  const navigate = useNavigate();

  const handleOutside = () => {
    navigate(NavigationRoutes.outsideLiveTips);
  };

  const handleInside = () => {
    navigate(NavigationRoutes.insideLiveTips);
  };

  useEffect(() => {
    if (!access_token) navigate(NavigationRoutes.login);
  }, []);

  return (
    <Box sx={styles.container}>
      <ScreenNavigationTracker
        path={[
          {
            label: intl.formatMessage(messages.home),
            path: NavigationRoutes.home,
          },
          {
            label: intl.formatMessage(messages.nomination),
            path: NavigationRoutes.nominations,
          },
        ]}
      />
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="flex-end"
        columnGap={15}
        height={400}
        width="90%"
      >
        <Scope
          handleClick={handleInside}
          label={intl.formatMessage(messages.inside)}
          extraStyles={{ border: `2px solid ${color_pallette.primary}` }}
          icon={inside}
        />
        <Scope
          handleClick={handleOutside}
          highlight
          label={intl.formatMessage(messages.outside)}
          extraStyles={{
            boxShadow: 0,
            paddingInline: 7,
            border: `3px solid #F7F7F7`,
          }}
          icon={outside}
        />
      </Stack>
    </Box>
  );
};

export default NominationScope;
