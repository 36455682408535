import { defineMessages } from "react-intl";

const scope = "LoginForm";

export default defineMessages({
  mobile: {
    id: `${scope}.mobile`,
    defaultMessage: "Email / Phone Number",
  },
  verifying: {
    id: `${scope}.verifying`,
    defaultMessage: "verifying your data",
  },
  wrong: {
    id: `${scope}.wrong`,
    defaultMessage: "Wrong username or password !",
  },
  mobilePlaceholder: {
    id: `${scope}.mobilePlaceholder`,
    defaultMessage: "e.g 581196897 or rashid@example.com",
  },
  password: {
    id: `${scope}.password`,
    defaultMessage: "Password",
  },
  passwordPlaceholder: {
    id: `${scope}.passwordPlaceholder`,
    defaultMessage: "Password",
  },
  submitLabel: {
    id: `${scope}.submitLabel`,
    defaultMessage: "Submit",
  },
});
