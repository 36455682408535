import { Dispatch, SetStateAction, createContext } from "react";

export const enum SCHEDULE_TYPE {
  INSTANT = "instant",
  SCHEDULED = "scheduled",
  PRERECORDED = "precorded",
}

export type ScheduleState = {
  schedule: SCHEDULE_TYPE | undefined;
  setShowSchedule: Dispatch<SetStateAction<SCHEDULE_TYPE | undefined>>;
};

export const ScheduleContext = createContext<ScheduleState>({
  schedule: undefined,
  setShowSchedule: () => {},
});
