import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    boxShadow: 1,
    borderRadius: 3,
    width: "60%",
    marginTop: 5,
    marginX: "auto",
  },
};

export default styles;
