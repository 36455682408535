import { ChangeEvent, FC, useRef, useState } from "react";
import Box from "@mui/material/Box";
import styles from "./styles";
import { useAppSelector } from "store/hooks";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import SnackBar from "components/snack_bar";
import { EditProfileFormNames } from "../form";
import { useFormikContext } from "formik";
import thumbNail from "assets/images/placeholders/empty_cover_picture.webp";

const CoverImageSection: FC = () => {
  const [cover, setCover] = useState<string>("");

  const { speakerProfile } = useAppSelector((state) => state.global);

  const coverInputRef = useRef<HTMLInputElement>(null);

  const handleUploadCover = () => {
    coverInputRef.current?.click();
  };

  const { setFieldValue } = useFormikContext();

  const handleChangeUploadCover = async (e: ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = e.target.files?.item(0);
    setFieldValue(EditProfileFormNames.coverPicture, uploadedFile);
    try {
      handleImageUpload(uploadedFile);
    } catch (error) {
      console.log(error);
    }
  };

  const handleImageUpload = (file: any) => {
    if (file) {
      setCover(URL.createObjectURL(file));
    }
  };

  return (
    <>
      <Box
        component="img"
        src={
          cover
            ? cover
            : speakerProfile.coverImage
            ? speakerProfile.coverImage
            : thumbNail
        }
        width="100%"
        height={200}
        alt="Profile Cover Image"
        sx={styles.img}
        onClick={handleUploadCover}
      />
      <SnackBar />
      <input
        style={{ display: "none" }}
        ref={coverInputRef}
        accept="image/*"
        type="file"
        onChange={handleChangeUploadCover}
      />
      <Box onClick={handleUploadCover} sx={styles.flexEnd}>
        <Box sx={{ backgroundColor: "white", padding: 0.4, borderRadius: 20 }}>
          <ModeEditIcon sx={{ width: 20, height: 20 }} />
        </Box>
      </Box>
    </>
  );
};

export default CoverImageSection;
