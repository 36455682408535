import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    width: "80%",
    marginTop: 4,
    justifyContent: "flex-start",
    cursor: "pointer",
    columnGap: 2,
  },
};

export default styles;
