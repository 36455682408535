import { FC } from "react";
import styles from "./styles";
import {
  Avatar,
  Checkbox,
  FormControlLabel,
  Grow,
  Stack,
  Typography,
} from "@mui/material";
import useSpeakers from "hooks/use_speakers";
import ArrowDown from "@mui/icons-material/KeyboardArrowDown";
import ArrowUp from "@mui/icons-material/KeyboardArrowUp";
import messages from "pages/explore_speakers/sidebar_section/messages";
import { FormattedMessage } from "react-intl";

const SpeakerFilter: FC = () => {
  const { data, isError, setVisible, visible, handleSpeakerChange, selected } =
    useSpeakers();

  const getArrowIcon = () => {
    return visible ? (
      <ArrowUp onClick={() => setVisible((visible) => !visible)} />
    ) : (
      <ArrowDown onClick={() => setVisible((visible) => !visible)} />
    );
  };

  return (
    <Stack sx={styles.container}>
      <Stack
        direction="row"
        sx={{
          width: "100%",
        }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography>
          <FormattedMessage id={messages.speaker.id} />
        </Typography>
        {getArrowIcon()}
      </Stack>
      <Stack sx={{ marginTop: 2 }} direction="column" alignItems="flex-start">
        {visible &&
          !isError &&
          data &&
          data.map((entry) => (
            <Grow key={entry.id.toString()} in={true} timeout={600}>
              <FormControlLabel
                key={entry.id.toString()}
                sx={{ cursor: "pointer" }}
                label={entry.name}
                control={
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <Avatar
                      sx={{ height: 30, width: 30 }}
                      src={entry.profile_picture}
                    />
                    <Checkbox
                      value={entry.id}
                      checked={entry.id.toString() === selected}
                      onChange={() => handleSpeakerChange(entry.id)}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </Stack>
                }
              />
            </Grow>
          ))}
      </Stack>
    </Stack>
  );
};

export default SpeakerFilter;
