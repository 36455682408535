import { ChangeEvent, FC, useRef } from "react";
import { Stack, Typography } from "@mui/material";
import styles from "./styles";
import { FormikValues, useFormikContext } from "formik";
import { UserEditPodcastFormNames } from "../form";

const UploadThumbSection: FC = () => {
  const { setFieldValue } = useFormikContext<FormikValues>();

  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = e.target.files?.item(0);
    if (uploadedFile != null) {
      setFieldValue(UserEditPodcastFormNames.thumbnail, uploadedFile);
    }
  };

  const handleClick = () => {
    inputRef?.current?.click();
  };

  return (
    <Stack onClick={handleClick} sx={styles.container}>
      <Typography sx={styles.uploadLabel}>Upload Thumbnail</Typography>
      <form encType="multipart/form-data">
        <input
          type="file"
          ref={inputRef}
          accept="image/*"
          style={{ display: "none" }}
          name={UserEditPodcastFormNames.thumbnail}
          onChange={handleChange}
        />
      </form>
    </Stack>
  );
};

export default UploadThumbSection;
