import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import color_pallette from "theme/color_pallette";

export type Toast = "error" | "info" | "success";

type SnackSlice = {
  open: boolean;
  bg: Toast;
  msg: string;
  backgroundColor: string;
};

const initialState: SnackSlice = {
  open: false,
  bg: "success",
  msg: "",
  backgroundColor: "",
};

const snackSlice = createSlice({
  name: "snack",
  initialState,
  reducers: {
    toggleSnack: (
      state: SnackSlice,
      { payload }: PayloadAction<{ msg: string; open: boolean; bg: Toast }>
    ) => {
      state.open = payload.open;
      state.bg = payload.bg;
      state.msg = payload.msg;
      switch (payload.bg) {
        case "success": {
          state.backgroundColor = color_pallette.primary;
          break;
        }
        case "error": {
          state.backgroundColor = color_pallette.danger;
          break;
        }
        case "info": {
          state.backgroundColor = color_pallette.info;
          break;
        }
      }
      return state;
    },
    closeSnack: (state: SnackSlice) => {
      state.open = false;
      return state;
    },
  },
});

export default snackSlice.reducer;
export const snackSliceActions = snackSlice.actions;
