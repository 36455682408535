import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {},
  header: {
    fontSize: {
      lg: 40,
    },
    fontWeight: "600",
    textAlign: "center",
    marginBlock: {
      lg: 5,
    },
    marginBottom: { xl: 15 },
  },
  listWrapper: {
    width: { lg: "90%" },
    marginX: "auto",
  },
};

export default styles;
