import { FC } from "react";
import { Checkbox, Stack, Typography } from "@mui/material";
import { FormikValues, useFormikContext } from "formik";
import { RegisterFormNames } from "../register_form/form_data";
import ErrorMessage from "common/error_message";

const ConcentSection: FC = () => {
  const { setFieldValue, errors, setFieldError } =
    useFormikContext<FormikValues>();

  const handleConcentChange = (_: any, value: boolean) => {
    if (value == false) {
      setFieldError(
        RegisterFormNames.concent,
        "You Must Agree to The Terms of Services"
      );
      return;
    }
    setFieldValue(RegisterFormNames.concent, true);
  };

  return (
    <>
      <Stack direction="row" alignItems="center">
        <Checkbox
          onChange={handleConcentChange}
          name={RegisterFormNames.concent}
        />
        <ErrorMessage>
          <Typography>
            {errors[RegisterFormNames.concent]?.toString()}
          </Typography>
        </ErrorMessage>
      </Stack>
    </>
  );
};

export default ConcentSection;
