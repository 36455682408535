import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  accountMenuAvatar: {
    width: 75,
    height: 75,
  },
  accountMenu: {
    width: 350,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "flex-start",
    paddingInline: 1.5,
    height: 140,
    position: "absolute",
    borderRadius: 3,
    boxShadow: 3,
    top: 35,
    right: 20,
    backgroundColor: "white",
    zIndex: 10,
  },
};

export default styles;
