import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    width: { xs: 400, md: 350, lg: 400 },
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  chatWrapper: {
    overflow: "scroll",
    background: "linear-gradient(145.46deg, #ACCFD2 -23.98%, #FFFFFF 126.67%)",
    marginTop: 1,
    borderRadius: 5,
    paddingBottom: 3,
    boxShadow: 1,
    height: 550,
    width: "100%",
  },
};

export default styles;
