import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    boxShadow: 1,
    overflow: "hidden",
    width: "100%",
    paddingBlock: 1,
    position: "sticky",
    top: 0,
    left: 0,
    zIndex: 10,
    background: "linear-gradient(145.46deg, #ACCFD2 -23.98%, #FFFFFF 126.67%)",
  },
  counter: {
    fontWeight: "400",
    textAlign: "end",
    paddingInlineEnd: 2,
    fontSize: {
      lg: 13,
    },
  },
  header: {
    fontWeight: "600",
    fontSize: {
      lg: 20,
    },
  },
};

export default styles;
