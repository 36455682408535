import { FC } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useAppSelector } from "store/hooks";
import OnlineItemBookmarkWrapper from "components/online_item_bookmark_wrapper";
import styles from "./styles";
import { FormattedMessage } from "react-intl";
import messages from "../messages";

const OnlineBookmarksSection: FC = () => {
  const data = useAppSelector((state) => state.bookmarks.online);
  return (
    <Box sx={styles.container}>
      {data.length > 0 && (
        <Typography sx={styles.header}>
          <FormattedMessage id={messages.online_videos_saved.id} />
        </Typography>
      )}
      <Grid container columnGap={1.5} rowGap={3}>
        {data.map((entry) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={9}
            lg={3.7}
            xl={2.7}
            key={entry.bookmarkId}
          >
            <OnlineItemBookmarkWrapper {...entry} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default OnlineBookmarksSection;
