import { FC, useContext } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import styles from "./styles";
import { ScheduleContext } from "context/schedule_context";

type Props = {
  img: string;
  label: string;
  name: string;
  onCheck: () => void;
};

const TypeWrapper: FC<Props> = ({ img, name, label, onCheck }) => {
  const { schedule } = useContext(ScheduleContext);

  return (
    <Stack
      onClick={onCheck}
      sx={styles.typeWrapper}
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        width="100%"
        direction="row"
        alignItems="flex-start"
        justifyContent="flex-end"
      >
        <Checkbox
          checked={schedule === name}
          sx={styles.checkbox}
          name="instant"
          value="instant"
        />
      </Stack>
      <Box component="img" sx={styles.icon} src={img} />
      <Typography sx={styles.label}>{label}</Typography>
    </Stack>
  );
};
export default TypeWrapper;
