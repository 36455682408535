import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    boxShadow: 1,
    borderRadius: 3,
    marginBottom: 1,
    padding: 1.5,
    backgroundColor: "white",
    width: 85,
    height: 85,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
};

export default styles;
