import { NavigationRoutes } from "navigation/routes";
import messages from "./messages";

type Label = string;

export type SubMenuType = { label: Label; path: NavigationRoutes };

export type MenuItemData = {
  item: SubMenuType;
  subMenu?: Array<SubMenuType>;
};

export const MenuItemsData: Array<MenuItemData> = [
  {
    item: {
      label: messages.Home.id,
      path: NavigationRoutes.home,
    },
  },
  {
    item: {
      label: messages.about_us.id,
      path: NavigationRoutes.aboutUs,
    },
  },
  {
    item: {
      label: messages.Speakers.id,
      path: NavigationRoutes.speakers,
    },
    subMenu: [
      {
        label: messages.explore_Speakers.id,
        path: NavigationRoutes.speakers,
      },
      {
        label: messages.join_us.id,
        path: NavigationRoutes.joinUs,
      },
    ],
  },
  {
    item: {
      label: messages.dubai_police.id,
      path: NavigationRoutes.police_speakers,
    },
    subMenu: [
      {
        label: messages.dubai_police_speakers.id,
        path: NavigationRoutes.police_speakers,
      },
      {
        label: messages.dubai_police_speakers_online.id,
        path: NavigationRoutes.police_online_videos,
      },
    ],
  },
  {
    item: {
      label: messages.services.id,
      path: NavigationRoutes.services_online_video,
    },
    subMenu: [
      {
        label: messages.live_tips_online.id,
        path: NavigationRoutes.services_online_video,
      },
      {
        label: messages.live_tips_podcast.id,
        path: NavigationRoutes.podcasts,
      },
      {
        label: messages.live_interactive.id,
        path: NavigationRoutes.interactive,
      },
      {
        label: messages.live_tips_employees.id,
        path: NavigationRoutes.employees,
      },
      {
        label: messages.live_tips_nomination.id,
        path: NavigationRoutes.nominations,
      },
    ],
  },
  /* {
    item: {
      label: messages.blog.id,
      path: NavigationRoutes.blog,
    },
  }, */
];
