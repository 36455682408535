import { SxProps } from "@mui/material";
import color_pallette from "theme/color_pallette";

const styles: Record<string, SxProps> = {
  container: {
    marginTop: 3,
    marginInline: 1.5,
    paddingInlineStart: 1.5,
    paddingBlock: 2,
    boxShadow: 5,
    borderRadius: 3,
    maxWidth: { xs: 400, sm: 300, lg: 320, xl: 340 },
    minWidth: { xs: 400, sm: 300, lg: 320, xl: 340 },
    marginX: "auto",
    order: {
      md: 0,
    },
  },
  divider: { marginInlineEnd: 2, marginBlock: 1 },
  menuItem: {
    font: "Inter, K2D",
    fontWeight: "400",
    paddingBlock: 1,
    cursor: "pointer",
  },
  headerTitle: {
    font: "Inter, K2D",
  },
  menu: {
    borderRadius: 3,
    backgroundColor: color_pallette.lightGray,
    marginInlineEnd: 1.5,
    paddingInlineStart: 2,
    marginTop: 2,
    paddingTop: 1.5,
  },
  notificationBadgeWrapper: {
    borderRadius: 30,
    width: 30,
    height: 30,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: color_pallette.primary,
  },
  notificationBadgeText: {
    color: "white",
    fontWeight: "400",
    fontSize: { lg: 14 },
  },
  discussionsWrapper: {
    borderRadius: 30,
    width: 30,
    height: 30,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: color_pallette.primary,
  },
  discussionsText: { color: "white", fontWeight: "400", fontSize: { lg: 14 } },
  languageWrapper: {
    borderRadius: 2,
    display: "flex",
    justifyContent: "center",
    paddingInline: 1.5,
    paddingBlock: 0.5,
    alignItems: "center",
    backgroundColor: color_pallette.primary,
  },
  languageText: {
    color: "white",
    whiteSpace: "nowrap",
    fontWeight: "400",
    fontSize: { lg: 14 },
  },
};

export default styles;