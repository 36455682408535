import { useEffect } from "react";
import {
  useDisLikeVideoMutation,
  useGetVideoDisLikesCountQuery,
  useGetVideoDisLikesDetailsQuery,
  useGetVideoLikesCountQuery,
  useGetVideoLikesDetailsQuery,
  useLikeVideoMutation,
  useUpdateUserReactionMutation,
} from "store/api/likes_service/endpoints";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { LivetipsOnlineSliceActions } from "store/livetips_online_slice";
import { MEDIA_TYPE } from "types/media_type";

const useLikes = () => {
  const dispatch = useAppDispatch();

  const {
    global: { credentials, speakerProfile },
    livetipsOnline: { currentVideo, currentVideoReaction },
  } = useAppSelector((state) => state);

  const [reactWithLike] = useLikeVideoMutation();
  const [reactWithDisLike] = useDisLikeVideoMutation();
  const [updateReaction] = useUpdateUserReactionMutation();

  const { data: likesCount } = useGetVideoLikesCountQuery(
    {
      id: currentVideo.id,
      token: credentials.token.access_token,
    },
    {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
      pollingInterval: 20000,
    }
  );


  const { data: likesDetails } = useGetVideoLikesDetailsQuery(
    {
      id: currentVideo.id,
      token: credentials.token.access_token,
    },
    {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
      pollingInterval: 20000,
    }
  );

  const { data: dislikesCount } = useGetVideoDisLikesCountQuery(
    {
      id: currentVideo.id,
      token: credentials.token.access_token,
    },
    {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
      pollingInterval: 20000,
    }
  );

  const { data: dislikesDetails } = useGetVideoDisLikesDetailsQuery(
    {
      id: currentVideo.id,
      token: credentials.token.access_token,
    },
    {
      refetchOnFocus: true,
    }
  );

  useEffect(() => {
    dispatch(
      LivetipsOnlineSliceActions.setVideoLikes(
        likesCount ?? parseInt(currentVideo.likes_count as string)
      )
    );
    dispatch(
      LivetipsOnlineSliceActions.setVideoDisLikes(
        dislikesCount ?? parseInt(currentVideo.dislikes_count as string)
      )
    );
    dispatch(
      LivetipsOnlineSliceActions.setVideoLikesReactionDetails({
        data: likesDetails ?? [],
        myId: speakerProfile.customerId,
      })
    );
    dispatch(
      LivetipsOnlineSliceActions.setVideoDisLikesReactionDetails({
        data: dislikesDetails ?? [],
        myId: speakerProfile.customerId,
      })
    );
  }, [
    dispatch,
    speakerProfile.customerId,
    dislikesCount,
    dislikesDetails,
    likesDetails,
    likesCount,
  ]);

  async function handleLikeVideo() {
    if (currentVideoReaction.userReact == "liked") return;

    if (currentVideoReaction.userReact == "disliked") {
      dispatch(LivetipsOnlineSliceActions.decreaseDislikes());
    }

    dispatch(LivetipsOnlineSliceActions.likeVideo());

    const body = {
      deleted: false,
      like: true,
      digitalMediumType: MEDIA_TYPE.ON_DEMAND_VIDEO,
      ownerId: currentVideo.user.customerId,
      user: speakerProfile,
      videoId: currentVideo.id,
      userId: speakerProfile.customerId,
    };

    if (currentVideoReaction.userReact == "idle") {
      try {
        await reactWithLike({
          body,
          token: credentials.token.access_token,
        });
      } catch (error) {
        dispatch(LivetipsOnlineSliceActions.disLikeVideo());
        dispatch(LivetipsOnlineSliceActions.revertReaction());
      }
    } else {
      try {
        await updateReaction({
          param: {
            id: speakerProfile.customerId,
            token: credentials.token.access_token,
          },
          body,
        });
        dispatch(LivetipsOnlineSliceActions.likeVideoDetails(body));
      } catch (error) {
        dispatch(LivetipsOnlineSliceActions.disLikeVideo());
        dispatch(LivetipsOnlineSliceActions.revertReaction());
      }
    }
  }

  async function handleDisLikeVideo() {
    if (currentVideoReaction.userReact == "disliked") return;

    if (currentVideoReaction.userReact == "liked") {
      dispatch(LivetipsOnlineSliceActions.decreaseLikes());
    }

    dispatch(LivetipsOnlineSliceActions.disLikeVideo());

    const body = {
      deleted: false,
      like: false,
      digitalMediumType: MEDIA_TYPE.ON_DEMAND_VIDEO,
      ownerId: currentVideo.user.customerId,
      user: speakerProfile,
      videoId: currentVideo.id,
      userId: speakerProfile.customerId,
    };

    if (currentVideoReaction.userReact == "idle") {
      try {
        await reactWithDisLike({
          body,
          token: credentials.token.access_token,
        });
      } catch (error) {
        dispatch(LivetipsOnlineSliceActions.likeVideo());
        dispatch(LivetipsOnlineSliceActions.revertReaction());
      }
    } else {
      try {
        await updateReaction({
          param: {
            id: speakerProfile.customerId,
            token: credentials.token.access_token,
          },
          body,
        });
        dispatch(LivetipsOnlineSliceActions.disLikeVideoDetails(body));
      } catch (error) {
        dispatch(LivetipsOnlineSliceActions.likeVideo());
        dispatch(LivetipsOnlineSliceActions.revertReaction());
      }
    }
  }

  return {
    likeVideo: handleLikeVideo,
    dislikeVideo: handleDisLikeVideo,
  };
};

export default useLikes;
