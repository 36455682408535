import { GenerateTokenResponse, UserByIdResponse } from "store/api/types";
import { Local } from "types/local";
import { ROLE } from "types/role";

export enum LanguageDirection {
  rightToLeft = "rtl",
  leftToRight = "ltr",
}

type UserCredentials = {
  transactionId: string;
  token: GenerateTokenResponse;
};

type Language = {
  prefix: Local;
  direction: LanguageDirection;
};

export type GlobalState = {
  lang: Language;
  darkTheme?: boolean;
  permission: ROLE;
  activeMenuItem?: string;
  credentials: UserCredentials;
  speakerProfile: UserByIdResponse;
};
