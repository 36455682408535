import { FC } from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import color_pallette from "theme/color_pallette";
import styles from "./styles";
import { FormattedMessage } from "react-intl";
import messages from "../messages";

const HeaderSection: FC = () => {
  return (
    <Stack direction="column">
      <Typography>
        <FormattedMessage id={messages.home_saved.id} />
        <span style={{ paddingInline: "5px" }}>{" > "}</span>
        <span style={{ color: color_pallette.primary }}>
          <FormattedMessage id={messages.saved.id} />
        </span>
      </Typography>
      <Typography sx={styles.header}>
        <FormattedMessage id={messages.saved.id} />
      </Typography>
    </Stack>
  );
};

export default HeaderSection;
