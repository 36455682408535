import { FFmpeg } from "@ffmpeg/ffmpeg";
import { createContext } from "react";

type ThumbContext = {
  setMpegInstance: React.Dispatch<React.SetStateAction<FFmpeg | null>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setReady: React.Dispatch<React.SetStateAction<boolean>>;
  setThumb: React.Dispatch<React.SetStateAction<string>>;
  mpegInstance: FFmpeg | null;
  loading: boolean;
  ready: boolean;
  thumb: string;
};

const ThumbContext = createContext<ThumbContext>({
  mpegInstance: null,
  loading: false,
  ready: false,
  setReady: () => {},
  setLoading: () => {},
  setMpegInstance: () => {},
  setThumb: () => {},
  thumb: "",
});

export default ThumbContext;
