import { Box, MenuItem, Select, TextFieldProps } from "@mui/material";
import styles from "./styles";
import { FormikValues, useFormikContext } from "formik";
import { CountryType } from "pages/register_page/register_form/data";

type Props<T> = {
  name: string;
  dataList: Array<T>;
} & TextFieldProps;

function SelectionInputField<T extends CountryType>({
  name,
  dataList,
  label,
}: Props<T>) {
  const { values, handleBlur, handleChange } = useFormikContext<FormikValues>();

  return (
    <Box sx={styles.container}>
      <Select
        name={name}
        label={label ?? ""}
        defaultValue={1}
        onBlur={handleBlur}
        onChange={handleChange}
        value={values[name]}
        sx={{ backgroundColor: "white" }}
        fullWidth
      >
        {dataList.map((entry) => (
          <MenuItem key={entry.name} value={entry.name}>
            {entry.name}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
}

export default SelectionInputField;
