import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {},
  profilePic: {
    position: "absolute",
    top: "25%",
    left: "40%",
    borderRadius: 6,
    zIndex: 100,
    overflow: "hidden",
    cursor: "pointer",
  },
  flexEnd: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    position: "absolute",
    top: 100,
    right: 50,
  },
};

export default styles;
