import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    maxWidth: {
      xs: "90%",
      lg: "80%",
    },
    marginX: "auto",
    marginTop: 5,
    marginBottom: 30,
  },
  notice: {
    fontSize: 30,
    color: "black",
    maxWidth: {
      lg: "70%",
    },
  },
};

export default styles;
