import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type State = {
  transactionId: string;
};

const initialState: State = {
  transactionId: "",
};

const transactionSlice = createSlice({
  name: "transactionSlice",
  initialState,
  reducers: {
    setCurrentTransactionId: (
      state: State,
      { payload }: PayloadAction<string>
    ) => {
      state.transactionId = payload;
      return state;
    },
  },
});

export default transactionSlice.reducer;
export const transactionSliceActions = transactionSlice.actions;
