import React from "react";
import CategorieFilter from "components/categorie_filter";
import RateFilter from "components/rate_filter";
import SpeakerFilter from "components/speaker_filter";
import DateFilter from "components/date_filter";

export type Filter = {
  id: string;
  value: () => React.ReactElement;
};

const filters: Array<Filter> = [
  {
    id: "4",
    value: () => <DateFilter />,
  },
  {
    id: "1",
    value: () => <CategorieFilter />,
  },
  {
    id: "2",
    value: () => <RateFilter />,
  },
  {
    id: "3",
    value: () => <SpeakerFilter />,
  },
];

export default filters;
