import { FC } from "react";
import { ReactSVG } from "react-svg";
import lessons from "assets/svgs/lessons_svg.svg";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import styles from "./styles";
import { FormattedMessage } from "react-intl";
import messages from "../messages";

const HeaderSection: FC = () => {
  return (
    <Stack width={"100%"}>
      <Box sx={styles.search}>
        <FormattedMessage id={messages.search.id} />
      </Box>
      <Stack
        direction="row"
        width={{ xs: "50%", lg: "73%", xl: "75%" }}
        sx={{ marginBottom: 5 }}
        justifyContent="center"
        alignItems="center"
      >
        <ReactSVG style={{ width: 100, height: 100 }} src={lessons} />
      </Stack>
    </Stack>
  );
};

export default HeaderSection;
