import { combineReducers, configureStore } from "@reduxjs/toolkit";
import rootApi from "./api/index";
import globalReducer from "./global_slice/index";
import LivetipsOnlineReducer from "./livetips_online_slice";
import LivetipsPodcastReducer from "./livetips_podcast_slice/index";
import LivetipsInteractiveReducer from "./livetips_interactive_slice";
import RateReducer from "./rate_slice";
import UpcomingPodcastReducer from "./upcoming_podcasts";
import ProfileSliceReducer from "./profile_slice";
import { authApi } from "./api/auth_service/endpoints";
import { CategoryServiceApi } from "./api/categorie_service/endpoints";
import { commentApi } from "./api/comment_service/endpoints";
import { identityServiceApi } from "./api/identity_service/endpoints";
import { nomineeApi } from "./api/nomination_service/endpoints";
import { videoServiceApi } from "./api/video_service/endpoints";
import { onlineSliceApi } from "./api/online_slice_api/endpoints";
import { rateApi } from "./api/rate_service/endpoints";
import { membershipServiceApi } from "./api/membership_service/endpoints";
import { blogServiceApi } from "./api/blog_service/endpoints";
import { podcastApi } from "./api/podcast_api/endpoints";
import { otpServiceApi } from "./api/otp_service/endpoints";
import { ViewService } from "./api/view_service/endpoints";
import { NotificationApi } from "./api/notification_service/endpoints";
import { LikesApi } from "./api/likes_service/endpoints";
import { ShareApi } from "./api/share_service/endpoints";
import { streamingApi } from "./api/streaming_service/endpoints";
import { BookmarkApi } from "./api/bookmark_service/endpoints";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import heroReducer from "./hero_slice";
import commentReducer from "./comment_slice";
import modalReducer from "./modal_slice";
import memberReducer from "./members_slice";
import snackReducer from "./snack_slice";
import bookmarkReducer from "./bookmarks_slice/index";
import blogReducer from "./blog_slice/index";
import nominationReducer from "./nomination_slice";
import badgeReducer from "./badge_slice";
import chatReducer from "./chat_slice";
import channelReducer from "./channel_slice";
import paginationReducer from "./pagination_slice";
import verificationReducer from "./verification_slice";
import transactionReducer from "./transaction_slice";
import categoryReducer from "./category_slice";
import viewReducer from "./view_slice";
import sidebarReducer from "./sidebar_slice";

const persistConfig = {
  key: "root",
  storage,
  timeout: 200,
};

const reducers = combineReducers({
  [rootApi.reducerPath]: rootApi.reducer,
  global: globalReducer,
  livetipsOnline: LivetipsOnlineReducer,
  livetipsPodcast: LivetipsPodcastReducer,
  livetipsInteractive: LivetipsInteractiveReducer,
  upcomingPodcasts: UpcomingPodcastReducer,
  adminProfil: ProfileSliceReducer,
  pagination: paginationReducer,
  snack: snackReducer,
  view: viewReducer,
  hero: heroReducer,
  modals: modalReducer,
  transaction: transactionReducer,
  blog: blogReducer,
  comment: commentReducer,
  otp: verificationReducer,
  rate: RateReducer,
  badge: badgeReducer,
  members: memberReducer,
  bookmarks: bookmarkReducer,
  nomination: nominationReducer,
  category: categoryReducer,
  sidebar: sidebarReducer,
  channel: channelReducer,
  chat: chatReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(authApi.middleware)
      .concat(CategoryServiceApi.middleware)
      .concat(identityServiceApi.middleware)
      .concat(videoServiceApi.middleware)
      .concat(blogServiceApi.middleware)
      .concat(onlineSliceApi.middleware)
      .concat(membershipServiceApi.middleware)
      .concat(LikesApi.middleware)
      .concat(commentApi.middleware)
      .concat(ShareApi.middleware)
      .concat(streamingApi.middleware)
      .concat(otpServiceApi.middleware)
      .concat(nomineeApi.middleware)
      .concat(ViewService.middleware)
      .concat(BookmarkApi.middleware)
      .concat(rateApi.middleware)
      .concat(podcastApi.middleware)
      .concat(NotificationApi.middleware),
});

export default () => {
  const persistor = persistStore(store);
  return { store, persistor };
};

export type RootState = ReturnType<typeof store.getState>;
export type DispatchType = typeof store.dispatch;
