import { FC } from "react";
import styles from "./styles";
import { Box, Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

type Props = {
  title: string;
  desc: string;
  ic: string;
};

const AboutUsServiceItem: FC<Props> = ({ title, desc, ic }) => {
  return (
    <Stack
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      rowGap={1}
      sx={styles.container}
    >
      <Box
        component="img"
        sx={{ width: 40, objectFit: "contain", height: 40 }}
        src={ic}
      />
      <Typography sx={{ fontWeight: "600", fontSize: { lg: 20 } }}>
        <FormattedMessage id={title} />
      </Typography>
      <Typography
        sx={{
          textAlign: "center",
          width: "90%",
          opacity: 0.7,
          fontSize: { lg: 14 },
        }}
      >
        <FormattedMessage id={desc} />
      </Typography>
    </Stack>
  );
};

export default AboutUsServiceItem;
