import { FC } from "react";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import styles from "./styles";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { FormattedMessage } from "react-intl";
import messages from "./messages";
import VolumeUpRoundedIcon from "@mui/icons-material/VolumeUpRounded";
import VolumeOffRoundedIcon from "@mui/icons-material/VolumeOffRounded";
import VideocamRoundedIcon from "@mui/icons-material/VideocamRounded";
import VideocamOffRoundedIcon from "@mui/icons-material/VideocamOffRounded";
import { LivetipsInteractiveSliceActions } from "store/livetips_interactive_slice";

const HeaderSection: FC = () => {
  const { length } = useAppSelector((state) => state.chat.chatHistory);

  const { audio, video } = useAppSelector((state) => state.livetipsInteractive);

  const dispatch = useAppDispatch();

  const handleToggleVideo = () => {
    dispatch(LivetipsInteractiveSliceActions.toggleAudio());
  };

  const handleToggleAudio = () => {
    dispatch(LivetipsInteractiveSliceActions.toggleVideo());
  };

  return (
    <Paper sx={styles.container}>
      <Stack
        direction="row"
        columnGap={1.5}
        justifyContent="space-around"
        alignItems="center"
      >
        <Stack
          direction="row"
          sx={{ paddingInlineStart: 2 }}
          justifyContent="flex-start"
          alignItems="center"
        >
          {audio ? (
            <VolumeUpRoundedIcon
              sx={{ cursor: "pointer" }}
              onClick={handleToggleAudio}
            />
          ) : (
            <VolumeOffRoundedIcon
              sx={{ cursor: "pointer" }}
              onClick={handleToggleAudio}
            />
          )}
        </Stack>
        <Stack
          direction="row"
          sx={{ paddingInlineStart: 2 }}
          justifyContent="flex-start"
          alignItems="center"
        >
          {video ? (
            <VideocamRoundedIcon
              sx={{ cursor: "pointer" }}
              onClick={handleToggleVideo}
            />
          ) : (
            <VideocamOffRoundedIcon
              sx={{ cursor: "pointer" }}
              onClick={handleToggleVideo}
            />
          )}
        </Stack>
        <Typography flexGrow={1} sx={styles.counter}>
          {length ?? 0} <FormattedMessage id={messages.interaction.id} />
        </Typography>
      </Stack>
    </Paper>
  );
};

export default HeaderSection;
