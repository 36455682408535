import { FC } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import styles from "./styles";
import useTime from "hooks/useTime";
import Chronometer from "components/chronometer";
import HeaderSection from "components/upcoming_podcaster/header_section";
import ContentSection from "components/upcoming_podcaster/content_section";
import FooterSection from "components/upcoming_podcaster/footer_section";
import CustomButton from "components/custom_button";
import { useAppDispatch } from "store/hooks";
import { modalSliceActions } from "store/modal_slice";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "navigation/routes";
import { Upcoming } from "store/api/streaming_service/types";
import { getUpcomingDate } from "utils/get_upcoming_date";
import { NAMESPACES } from "store/api/namespaces";

export type SwipablePodcasterProps = {
  itemData: Upcoming;
};

const SwipablePodcaster: FC<SwipablePodcasterProps> = ({ itemData }) => {
  const time = useTime(getUpcomingDate(itemData.created_date));

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const handleListen = () => {
    dispatch(modalSliceActions.watchNow(true));
  };

  const onViewProfile = () => {
    navigate(`${NavigationRoutes.public_speaker_profile}/${itemData.id}`);
  };

  const handleUpcomingDetails = () => {
    navigate(`${NavigationRoutes.upcoming_details}/${itemData.id}`, {
      state: {
        endpoint: NAMESPACES.podcast,
      },
    });
  };

  return (
    <Stack rowGap={5} direction="column" alignItems="center">
      <Box onClick={handleUpcomingDetails} sx={{ marginTop: { lg: 0 } }}>
        <Chronometer
          labelStyles={styles.chronoLabel}
          timeBoxStyle={styles.chronoBox}
          timeLabelStyle={styles.chronoLabel}
          expiryTimestamp={time}
        />
      </Box>
      <Box sx={styles.container}>
        <Stack
          direction="row"
          justifyContent="flex-start"
          columnGap={1.5}
          marginTop={2}
          alignItems="center"
        ></Stack>
        <HeaderSection
          handleViewProfile={() => onViewProfile()}
          rate={parseInt(itemData.rating)}
          image={itemData.thumbnail}
        />
        <Stack sx={styles.bodyStyles}>
          <ContentSection
            title={itemData.title}
            podcasterName={itemData.user.name}
            podcasterPosition={itemData.user.org}
            desc={itemData.user.about}
          />
          <FooterSection />
          <Stack
            width="100%"
            direction={{ xs: "row" }}
            justifyContent={{ xs: "center", lg: "flex-start" }}
            alignItems={{ xs: "center" }}
          >
            <CustomButton
              onClick={() => handleListen()}
              extraStyles={{ width: 200 }}
              label="Join"
            />
          </Stack>
        </Stack>
      </Box>
    </Stack>
  );
};

export default SwipablePodcaster;
