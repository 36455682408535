import { FC, ReactNode, SyntheticEvent, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box, { BoxProps } from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import styles from "./styles";
import Button from "components/custom_button";
import { useNavigate } from "react-router-dom";
import { TabContent } from "pages/view_admin_profile/my_stream_section";
import { NavigationRoutes } from "navigation/routes";
import { useIntl } from "react-intl";
import messages from "./messages";

type TabPanelProps = {
  children?: ReactNode;
  index: number;
  value: number;
} & BoxProps;

const CustomTabPanel: FC<TabPanelProps> = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </Box>
  );
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

type Props = {
  content: Array<TabContent>;
};

const BasicTabs: FC<Props> = ({ content }) => {
  const [value, setValue] = useState(0);

  const navigate = useNavigate();

  const handleChange = (_: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleNavigate = () => {
    navigate(NavigationRoutes.myVideos, {
      state: {
        listType: content[value].listType,
      },
    });
  };

  const intl = useIntl();

  return (
    <Stack direction="column" alignItems="center" sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange} aria-label="my streams tab">
          <Tab
            label={intl.formatMessage(messages.live_online)}
            {...a11yProps(0)}
          />
          <Tab
            label={intl.formatMessage(messages.podcasts)}
            {...a11yProps(1)}
          />
          {/* <Tab
            label={intl.formatMessage(messages.interactive)}
            {...a11yProps(2)}
          /> */}
        </Tabs>
      </Box>
      <Box sx={{ maxWidth: { lg: "75%" } }}>
        {content.map((entry) => (
          <CustomTabPanel key={entry.id} value={value} index={entry.index}>
            <Typography sx={styles.tabLabel}>{entry.label}</Typography>
            {entry.Content}
          </CustomTabPanel>
        ))}
        <Box sx={styles.buttonWrapper}>
          <Button
            onClick={handleNavigate}
            label={intl.formatMessage(messages.explore)}
            variant="outlined"
            extraStyles={styles.exploreButton}
          />
        </Box>
      </Box>
    </Stack>
  );
};

export default BasicTabs;
