import { defineMessages } from 'react-intl';

const scope = 'ContentSection';

export default defineMessages({
  no_bio: {
    id: `${scope}.no_bio`,
    defaultMessage: "Empty Bio.",
  },
  public_view: {
    id: `${scope}.public_view`,
    defaultMessage: "Public View Profile",
  },
  shared_your_profile: {
    id: `${scope}.shared_your_profile`,
    defaultMessage: "Shared Your Profile",
  },
  share: {
    id: `${scope}.share`,
    defaultMessage: "Share",
  },
  shares: {
    id: `${scope}.shares`,
    defaultMessage: "Shares",
  },
  views: {
    id: `${scope}.views`,
    defaultMessage: "views",
  },
  join_speakers: {
    id: `${scope}.join_speakers`,
    defaultMessage: "Join Our Speakers",
  },
});