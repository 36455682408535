import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {},
  footer: {
    display: "flex",
    justifyContent: "center",
    marginTop: { xs: 10, lg: 1 },
  },
};

export default styles;