import { SxProps } from "@mui/material";
import color_pallette from "theme/color_pallette";

const styles: Record<string, SxProps> = {
  container: {
    backgroundColor: color_pallette.backgroundGray,
    borderRadius: 5,
    display: "inline-flex",
    justifyContent: "space-around",
    alignItems: "center",
    paddingY: 3,
    paddingX: 5,
  },
  iconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: color_pallette.lightPrimary,
    borderRadius: 40,
    minWidth: 70,
    minHeight: 70,
  },
  textSectionValueStyle: {
    fontWeight: "bold",
    fontSize: 25,
  },
  textSectionLabelStyle: {
    fontSize: 15,
    opacity: 0.6,
  },
  textSection: {
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    marginInlineStart: 2,
  },
};

export default styles;
