import { SxProps } from "@mui/material";
import color_pallette from "theme/color_pallette";

const styles: Record<string, SxProps> = {
  container: {
    boxShadow: 2.5,
    borderRadius: 3,
    width: 500,
    padding: 1.5,
    backgroundColor: color_pallette.backgroundGray,
  },
  publishedDate: { fontSize: { xs: 14 }, marginInlineStart: 1 },
  caption: { fontSize: { xs: 13 }, marginBlock: 1 },
  length: { fontSize: { xs: 13 } },
  channel: { fontSize: { xs: 15 } },
  fileContainer: { flexGrow: 1 },
  thumb: { width: 100, borderRadius: 2, height: 100 },
};

export default styles;
