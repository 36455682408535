import { defineMessages } from 'react-intl';

const scope = 'AboutUs';

export default defineMessages({
  intro: {
    id: `${scope}.intro`,
    defaultMessage:
      "LiveTips is an Online Platform for Students and Professionals seeking to Excel in their Skills, Learn New Abilities, and Elevate their Knowledge. By joining LiveTips, we offer you the opportunity to connect with international speakers and professionals, enabling you to contribute to the growth of our society.",
  },
  partners: {
    id: `${scope}.partners`,
    defaultMessage: "Our Partners",
  },
  livetips: {
    id: `${scope}.livetips`,
    defaultMessage: "What is Live Tips",
  },
});