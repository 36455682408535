import { FC, useEffect } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import styles from "./styles";
import Button from "components/custom_button";
import { UploadedFileStatus } from "types/uploaded_file_status";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "navigation/routes";
import { useAppDispatch } from "store/hooks";
import { ProfileSliceActions } from "store/profile_slice";
import usePodcastUpload from "hooks/use_podcast_upload";
import { LivetipsPodcastSliceActions } from "store/livetips_podcast_slice";
import { useIntl } from "react-intl";
import messages from "../../../messages";
import SnackBar from "components/snack_bar";
import UploadProgress from "components/upload_progress";

const FooterSection: FC = () => {
  const {
    handleBackToEdit,
    isLoading,
    uploadedVideoId,
    handleChangeStatus,
    showPreview,
  } = usePodcastUpload();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const intl = useIntl();

  useEffect(() => {
    if (showPreview) {
      dispatch(LivetipsPodcastSliceActions.closeModals());
      dispatch(ProfileSliceActions.clearUploadFiles());
      navigate(`${NavigationRoutes.podcasts}/${uploadedVideoId}`);
    }
  }, [showPreview, dispatch, navigate, uploadedVideoId]);

  return (
    <Stack sx={styles.container}>
      <SnackBar />
      {isLoading && <UploadProgress isLoading={isLoading} />}
      <Button
        onClick={() => handleBackToEdit()}
        variant="outlined"
        extraStyles={styles.backButton}
        label={intl.formatMessage(messages.back)}
      />
      <Box>
        <Button
          onClick={() => handleChangeStatus(UploadedFileStatus.PENDING)}
          extraStyles={styles.publish}
          label={intl.formatMessage(messages.publish)}
        />
        <Button
          onClick={() => handleChangeStatus(UploadedFileStatus.DRAFTED)}
          variant="outlined"
          extraStyles={styles.backButton}
          label={intl.formatMessage(messages.draft)}
        />
      </Box>
    </Stack>
  );
};

export default FooterSection;
