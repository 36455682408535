import { SxProps } from "@mui/material";
import color_pallette from "theme/color_pallette";

const styles: Record<string, SxProps> = {
  container: {
    boxShadow: 2,
    borderRadius: 2,
    overflow: "hidden",
    width: 300,
    height: 400,
    paddingInline: 1.5,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    cursor: "pointer",
    flexWrap: "wrap",
  },
  name: {
    fontSize: {
      xs: 23,
    },
    color: color_pallette.primary,
  },
  org: {
    fontSize: {
      xs: 17,
    },
  },
  profilePic: {
    boxShadow: 2,
    width: 150,
    height: 150,
  },
};

export default styles;
