import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "105px",
    background:
      "linear-gradient(333deg, #008755 -27.87%, rgba(188, 224, 225, 0.00) 108.64%)",
    padding: {
      xs: 1,
    },
  },
  footer: { paddingBottom: 10, marginTop: 5 },
  footerButton: { borderRadius: 3, marginTop: 10, backgroundColor: "#008755" },
  subHeaderHighlighted: {
    fontWeight: "600",
    fontSize: "38px",
    color: "black",
  },
  subHeader: {
    fontSize: "38px",
    color: "black",
    marginBottom: "24px",
    marginTop: "30px",
    fontWeight: "200",
  },
  contentLabel: {
    color: "black",
    marginBottom: 2,
    fontSize: {
      xs: 32,
      md: 40,
      lg: 30,
    },
    fontWeight: "600",
  },
  highlighted: {
    fontSize: "85px",
    color: "#008755",
    position: "absolute",
    top: "55%",
    left: "62%",
  },
  headerTitle: {
    fontSize: "85px",
    color: "#5D8495",
    position: "absolute",
    top: "35%",
    left: "21%",
  },
  flex: {
    display: "flex",
    flexDirection: {
      xs: "column",
      lg: "row",
    },
    width: "85%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  gridContainer: {
    marginX: "auto",
    justifyContent: { xs: "center", md: "flex-start", lg: "start" },
    columnGap: {
      xs: 0,
      md: 1.5,
      lg: 2.5,
    },
    rowGap: {
      xs: 3.5,
    },
  },
};

export default styles;
