import { FC } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { ReactSVG } from "react-svg";
import viewed from "assets/svgs/viewed.svg";
import styles from "./styles";

type Props = {
  label: string;
  value: string;
};

const InsightsCard: FC<Props> = ({ label, value }) => {
  return (
    <Grid item xs={12} sm={10} md={5} lg={3} xl={2.4} sx={styles.container}>
      <Box sx={styles.iconContainer}>
        <ReactSVG
          style={{
            height: 35,
            width: 35,
            paddingInline: 3,
          }}
          src={viewed}
        />
      </Box>
      <Box sx={styles.textSection}>
        <Typography noWrap sx={styles.textSectionValueStyle}>
          {value}
        </Typography>
        <Typography noWrap sx={styles.textSectionLabelStyle}>
          {label}
        </Typography>
      </Box>
    </Grid>
  );
};

export default InsightsCard;
