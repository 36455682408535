import { countryList } from "pages/join_us/general_info_form/data";
import { speakerClassData } from "pages/join_us/nomination_form/data";

export type Filter = {
  id: string;
  value: string;
  items?: Array<{ label: string; value: string | number }>;
};

export type RecentSearch = {
  id: string;
  value: string;
};

const filters: Array<RecentSearch> = [
  {
    id: "1",
    value: "Most Watched Speaker...",
  },
  {
    id: "2",
    value: "Who is ..",
  },
  {
    id: "3",
    value: "Dubai Famous Speaker",
  },
];

const recentSearches: Array<Filter> = [
  {
    id: "1",
    value: "Date",
  },
  {
    id: "2",
    value: "Location",
    items: countryList,
  },
  {
    id: "3",
    value: "Speakers",
  },
  {
    id: "4",
    value: "Rating",
  },
  {
    id: "5",
    value: "Subject",
    items: speakerClassData,
  },
];

export default {
  filters,
  recentSearches,
};
