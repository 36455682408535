import { FC } from "react";
import Stack from "@mui/material/Stack";
import styles from "./styles";
import { FormikValues, useFormikContext } from "formik";
import { Typography } from "@mui/material";
import { SEARCH_FORM } from "../form";
import SearchContent from "../search_content";
import { FormattedMessage } from "react-intl";
import messages from "../messages";

const FormResultSection: FC = () => {
  const { values } = useFormikContext<FormikValues>();

  return (
    <>
      <Stack direction="row" alignItems="center" sx={styles.container}>
        <Typography sx={styles.br}>
          <FormattedMessage id={messages.results.id} />
        </Typography>
        <Typography sx={styles.res}>{values[SEARCH_FORM.type]}</Typography>
        <Typography sx={styles.br}>{" > "}</Typography>
        <Typography sx={styles.res}>{values[SEARCH_FORM.q]}</Typography>
      </Stack>
      <SearchContent />
    </>
  );
};

export default FormResultSection;
