import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    paddingTop: 5,
  },
  res: {
    fontSize: {
      lg: 25,
    },
    fontWeight: "600",
  },
  br: {
    paddingInline: 2.5,
  },
};

export default styles;
