import { ChangeEvent, FC, useRef } from "react";
import Typography from "@mui/material/Typography";
import FormGroup from "@mui/material/FormGroup";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import styles from "./styles";
import InputField from "common/input_field";
import Button from "components/custom_button";
import { OnlineFormProperties } from "store/livetips_online_slice/form_properties";
import Checkbox from "common/checkbox";
import ErrorMessage from "common/error_message";
import { FormikValues, useFormikContext } from "formik";
import { useGetAllCategoriesQuery } from "store/api/categorie_service/endpoints";
import { useAppSelector } from "store/hooks";

const NominationForm: FC = () => {
  const { errors, setFieldValue } = useFormikContext<FormikValues>();

  const inputRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    inputRef?.current?.click();
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = e.target.files?.item(0);
    if (uploadedFile != null)
      setFieldValue(OnlineFormProperties.WEB_LINKS_FILE, uploadedFile);
  };
  const token = useAppSelector(
    (state) => state.global.credentials.token.access_token
  );

  const { data } = useGetAllCategoriesQuery({
    token,
  });

  return (
    <Box sx={styles.container}>
      <Typography sx={styles.font}>
        In which of the following categories would you classify this individual?
      </Typography>
      <FormGroup>
        <Grid container>
          {data &&
            data.length > 0 &&
            data.map((entry) => (
              <Grid key={entry.categoryId} item xs={3.5} sm={3} md={4}>
                <Checkbox
                  value={entry.categoryId}
                  key={entry.nameEn}
                  label={entry.nameEn}
                  name={OnlineFormProperties.SPEAKER_CLASS}
                />
              </Grid>
            ))}
        </Grid>
        <ErrorMessage>
          {(errors as FormikValues)[OnlineFormProperties.SPEAKER_CLASS]}
        </ErrorMessage>
      </FormGroup>
      <Box mt={3}>
        <Typography mb={1}>
          Please share any web links or articles that pertain to the speaker?
        </Typography>
        <InputField
          placeholder="Web Link"
          name={OnlineFormProperties.WEB_LINKS}
        />
        <input
          type="file"
          style={{ display: "none" }}
          ref={inputRef}
          onChange={handleChange}
          name={OnlineFormProperties.WEB_LINKS_FILE}
        />
        <Button
          onClick={handleClick}
          extraStyles={styles.mg}
          label="Attach File"
        />
      </Box>
      <Box mt={3}>
        <Typography sx={styles.noticeMsg}>
          Before submitting your nomination, please review how LiveTips
          processes the personal information you provide to us.
        </Typography>
        <Typography sx={styles.noticeMsg2}>
          * Due to the volume of Speaker Applications, we regret that we cannot
          personally respond to each suggestion. However, if necessary, someone
          from LiveTips will contact you.
        </Typography>
        <Typography sx={styles.noticeMsg2}>
          * Rest assured that we will process your information in accordance
          with LiveTips' privacy policy. If you have any concerns regarding
          LiveTips managing your data, please don't hesitate to reach out to
          privacy@liveTips.com.
        </Typography>
      </Box>
    </Box>
  );
};

export default NominationForm;
