import { FC } from "react";
import FormContainer from "common/form_container";
import { FormikValues } from "formik";
import { MODAL_ACTION_TYPE, ProfileSliceActions } from "store/profile_slice";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  validationSchema,
  initialValues,
} from "pages/view_admin_profile/create_online/edit_video_modal/form";
import {
  initialValues as premierValues,
  validationSchema as premierSchema,
  PODCAST_PREMIER_FORM,
} from "pages/view_admin_profile/create_podcast/podcast_premier/form";
import UploadedFileMeta from "types/uploaded_file_meta";
import { LivetipsPodcastSliceActions } from "store/livetips_podcast_slice";
import EditPodcastModal from "pages/view_admin_profile/create_podcast/edit_podcast_modal";
import EditVideoModal from "pages/view_admin_profile/create_online/edit_video_modal";
import PodcastPremier from "pages/view_admin_profile/create_podcast/podcast_premier";
import { SCHEDULE_TYPE } from "context/schedule_context";
import SnackBar from "components/snack_bar";
import OnlinePremier from "pages/view_admin_profile/create_online/online_premier";
import { ONLINE_PREMIER_FORM } from "pages/view_admin_profile/create_online/online_premier/form";
import { channelSliceActions } from "store/channel_slice";
import { UploadedFileStatus } from "types/uploaded_file_status";
import EditLiveStreamingModal from "pages/view_admin_profile/create_online/edit_live_streaming_modal";
import {
  validationSchema as liveStreamingSchema,
  initialValues as liveStreamingInitial,
  EditLiveStreamModal,
} from "../../create_online/edit_live_streaming_modal/form";
import { postLiveStreaming } from "store/api/streaming_service/action_creators";
import useSnack from "hooks/useSnack";
import {
  LiveStreaming /* Upcoming */,
} from "store/api/streaming_service/types";
/* import moment from "moment"; */
import {
  /* CHANNEL_TOKEN_DURATION, */
  UPCOMING_DURATION,
} from "types/upcoming_duration";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "navigation/routes";
/* import { CHANNEL_ROLE } from "types/upcoming_role"; */
/* import { usePostChannelTokenActionMutation } from "store/api/streaming_service/endpoints"; */
import InviteSpeakerModal from "pages/view_admin_profile/invite_speaker_modal";
import { useIntl } from "react-intl";
import messages from "../messages";

/* add extra minute for instant live stream to start */
export const FUTURE_SPARE_TIME = 6000;

const FormSection: FC = () => {
  const dispatch = useAppDispatch();

  const showEditPodcast = useAppSelector(
    (state) => state.livetipsPodcast.showEditModal
  );

  const { actionType } = useAppSelector((state) => state.adminProfil);

  const { triggerSnack } = useSnack();

  const navigate = useNavigate();

  const access_token = useAppSelector(
    (state) => state.global.credentials.token.access_token
  );

  /* const [postChannelToken] = usePostChannelTokenActionMutation(); */

  const { liveStreaming, type } = useAppSelector((state) => state.channel);

  const intl = useIntl();

  const {
    modals: { showEditUploadFile, showEditLiveStream },
    onlineTips,
  } = useAppSelector((state) => state.adminProfil);

  const handleSubmit = (values: FormikValues) => {
    dispatch(ProfileSliceActions.editUpload(values as UploadedFileMeta));
    dispatch(ProfileSliceActions.showUploadConfirmationModal());
  };

  const handlePodcastSubmit = (values: FormikValues) => {
    dispatch(ProfileSliceActions.editUpload(values as UploadedFileMeta));
    dispatch(LivetipsPodcastSliceActions.showConfirmationModal());
  };

  const handleSubmitPremier = async (values: FormikValues) => {
    dispatch(
      channelSliceActions.setLiveStreamType(values[PODCAST_PREMIER_FORM.TYPE])
    );

    if (values[PODCAST_PREMIER_FORM.TYPE] === SCHEDULE_TYPE.PRERECORDED) {
      dispatch(LivetipsPodcastSliceActions.showUploadModal());
      return;
    }

    if (values[PODCAST_PREMIER_FORM.TYPE] === SCHEDULE_TYPE.INSTANT) {
      dispatch(
        channelSliceActions.setLiveStreaming({
          duration: UPCOMING_DURATION.TWO_HOURS,
          status: UploadedFileStatus.ACCEPTED,
        })
      );
    }

    if (values[PODCAST_PREMIER_FORM.TYPE] === SCHEDULE_TYPE.SCHEDULED) {
      dispatch(
        channelSliceActions.setLiveStreaming({
          duration: UPCOMING_DURATION.TWO_HOURS,
          status: UploadedFileStatus.PENDING,
          scheduled_on: new Date(
            values[PODCAST_PREMIER_FORM.DAY]
          ).toISOString(),
        })
      );
    }

    dispatch(ProfileSliceActions.closeModals());
    dispatch(ProfileSliceActions.showEditLiveStream());
  };

  const handleSubmitPremierOnline = async (values: FormikValues) => {
    dispatch(
      channelSliceActions.setLiveStreamType(values[ONLINE_PREMIER_FORM.TYPE])
    );

    if (values[ONLINE_PREMIER_FORM.TYPE] === SCHEDULE_TYPE.PRERECORDED) {
      dispatch(ProfileSliceActions.showUploadModal());
      return;
    }

    if (values[ONLINE_PREMIER_FORM.TYPE] === SCHEDULE_TYPE.INSTANT) {
      dispatch(
        channelSliceActions.setLiveStreaming({
          duration: UPCOMING_DURATION.TWO_HOURS,
          status: UploadedFileStatus.ACCEPTED,
          scheduled_on: new Date().toISOString(),
        })
      );
    }

    if (values[ONLINE_PREMIER_FORM.TYPE] === SCHEDULE_TYPE.SCHEDULED) {
      dispatch(
        channelSliceActions.setLiveStreaming({
          duration: UPCOMING_DURATION.TWO_HOURS,
          status: UploadedFileStatus.PENDING,
          scheduled_on: new Date(values[ONLINE_PREMIER_FORM.DAY]).toISOString(),
        })
      );
    }

    dispatch(ProfileSliceActions.closeModals());
    dispatch(ProfileSliceActions.showEditLiveStream());
  };

  const handleLiveStreamSubmit = async (values: FormikValues) => {
    try {
      const date = new Date();

      date.setTime(date.getTime());

      const instantSchedule = date.toISOString();

      const res = await postLiveStreaming({
        body: {
          ...liveStreaming,
          title: values[EditLiveStreamModal.videoTitle],
          category: values[EditLiveStreamModal.category],
          description: values[EditLiveStreamModal.description],
          duration: liveStreaming.duration,
          channelName: values[EditLiveStreamModal.videoTitle],
          privacy: values[EditLiveStreamModal.exclusivity],
          thumbnail: values[EditLiveStreamModal.thumbnail],
          startTime:
            type == SCHEDULE_TYPE.INSTANT
              ? instantSchedule
              : liveStreaming.scheduled_on,
          scheduled_on:
            type == SCHEDULE_TYPE.INSTANT
              ? instantSchedule
              : liveStreaming.scheduled_on,
        } as LiveStreaming,
        token: access_token,
      });

      if (res.data) {
        dispatch(channelSliceActions.setUpcoming(res.data));
        dispatch(ProfileSliceActions.closeModals());
        /* generateChannelToken(res.data); */

        triggerSnack(intl.formatMessage(messages.success), "success");

        let route: string;

        const resolvePath = (actionType: MODAL_ACTION_TYPE) => {
          switch (actionType) {
            case MODAL_ACTION_TYPE.interactive:
              return `${NavigationRoutes.streaming_online_page}/${res.data?.id}`;
            // case MODAL_ACTION_TYPE.online:
            //   return NavigationRoutes.streaming_online_page;
            // case MODAL_ACTION_TYPE.podcast:
            //   return NavigationRoutes.streaming_podcast_page;
            default:
              return `${NavigationRoutes.streaming_podcast_page}/${res.data?.id}`;
          }
        };

        if (SCHEDULE_TYPE.INSTANT) route = resolvePath(actionType);
        /* if (SCHEDULE_TYPE.SCHEDULED)
          route = `${NavigationRoutes.upcoming_details}/${res.data?.id}`; */ else
          route = `${NavigationRoutes.streaming_podcast_page}/${res.data?.id}`;

        // const path = SCHEDULE_TYPE.INSTANT
        //   ? actionType == MODAL_ACTION_TYPE.interactive
        //     ? NavigationRoutes.streaming_online_page
        //     : NavigationRoutes.streaming_podcast_page
        //   : actionType == MODAL_ACTION_TYPE.podcast
        //   ? NavigationRoutes.upcomingPodcasts
        //   : NavigationRoutes.upcomingPodcasts;

        navigate(route);
      }
    } catch (error) {
      triggerSnack(intl.formatMessage(messages.error), "error");
    }
  };

  /* const generateChannelToken = async (upcoming: Upcoming) => {
    if (upcoming == null) return;

    let resultToken: { data: { rtcToken: string } };

    try {
      setTimeout(async () => {
        resultToken = (await postChannelToken({
          token: access_token,
          body: {
            duration: CHANNEL_TOKEN_DURATION.ONE_HOUR,
            channelName: upcoming.channel.name,
            startTime: upcoming.channel.startTime,
            role: CHANNEL_ROLE.publisher,
          },
        })) as { data: { rtcToken: string } };

        if (resultToken.data)
          dispatch(
            channelSliceActions.setCurrentChannelToken(
              resultToken.data.rtcToken
            )
          );

        triggerSnack(intl.formatMessage(messages.token_success), "success");
      }, FUTURE_SPARE_TIME);
    } catch (error) {
      triggerSnack(intl.formatMessage(messages.error), "error");
    }
  }; */

  return (
    <>
      {showEditPodcast && (
        <FormContainer
          onSubmit={handlePodcastSubmit}
          validationSchema={validationSchema}
          initialValues={onlineTips.meta ?? initialValues}
        >
          <EditPodcastModal />
        </FormContainer>
      )}
      <FormContainer
        onSubmit={handleSubmitPremier}
        validationSchema={premierSchema}
        initialValues={premierValues}
      >
        <PodcastPremier />
      </FormContainer>
      <FormContainer
        onSubmit={handleSubmitPremierOnline}
        validationSchema={premierSchema}
        initialValues={premierValues}
      >
        <OnlinePremier />
      </FormContainer>
      <SnackBar />
      <InviteSpeakerModal />
      {showEditUploadFile && (
        <FormContainer
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          initialValues={onlineTips.meta ?? initialValues}
        >
          <EditVideoModal />
        </FormContainer>
      )}
      {showEditLiveStream && (
        <FormContainer
          onSubmit={handleLiveStreamSubmit}
          validationSchema={liveStreamingSchema}
          initialValues={liveStreamingInitial}
        >
          <EditLiveStreamingModal />
        </FormContainer>
      )}
    </>
  );
};

export default FormSection;
