import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { NavigationRoutes } from "navigation/routes";

type NavigationProps = {
  state: object;
};

type State = {
  toNavigate: NavigationRoutes;
  phoneNumber: string;
  transactionId: string;
  email: string;
  navigationProps: NavigationProps;
  verifyOTP: boolean;
  sendOTP: boolean /* only for verifying */;
  lastOTP: string;
  registrationStep: boolean;
};

const initialState: State = {
  toNavigate: NavigationRoutes.profile,
  phoneNumber: "",
  email: "",
  sendOTP: false,
  registrationStep: false,
  verifyOTP: true,
  lastOTP: "",
  transactionId: "",
  navigationProps: {
    state: {},
  },
};

const verificationSlice = createSlice({
  name: "verification_slice",
  initialState,
  reducers: {
    setPath: (state: State, { payload }: PayloadAction<NavigationRoutes>) => {
      state.toNavigate = payload;
      return state;
    },
    setIsRegistrationStep: (
      state: State,
      { payload }: PayloadAction<boolean>
    ) => {
      state.registrationStep = payload;
      return state;
    },
    setVerifyOTP: (state: State, { payload }: PayloadAction<boolean>) => {
      state.verifyOTP = payload;
      return state;
    },
    setSendOTP: (state: State, { payload }: PayloadAction<boolean>) => {
      state.sendOTP = payload;
      return state;
    },
    setLastOTP: (state: State, { payload }: PayloadAction<string>) => {
      state.lastOTP = payload;
      return state;
    },
    setNavigationProps: (
      state: State,
      { payload }: PayloadAction<NavigationProps>
    ) => {
      state.navigationProps = payload;
      return state;
    },
    setEmail: (state: State, { payload }: PayloadAction<string>) => {
      state.email = payload;
      return state;
    },
    setPhone: (state: State, { payload }: PayloadAction<string>) => {
      state.phoneNumber = payload;
      return state;
    },
    setTransactionId: (state: State, { payload }: PayloadAction<string>) => {
      state.transactionId = payload;
      return state;
    },
  },
});

export default verificationSlice.reducer;
export const verificationSliceActions = verificationSlice.actions;
