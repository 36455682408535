import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    marginTop: 5,
    marginInline: 3,
    columnGap: {
      md: 5,
      lg: 10,
      xl: 20,
    },
    display: "flex",
    flexDirection: {
      xs: "column",
      md: "row",
    },
    justifyContent: {
      md: "center",
    },
  },
};

export default styles;