import { defineMessages } from 'react-intl';

const scope = 'ExploreSpeakers';

export default defineMessages({
  no_police_speakers: {
    id: `${scope}.no_police_speakers`,
    defaultMessage: "No Police Speakers Were Found",
  },
  search: {
    id: `${scope}.search`,
    defaultMessage: "search",
  },
  sort: {
    id: `${scope}.sort`,
    defaultMessage: "Sort & Filter All",
  },
  viewprofile: {
    id: `${scope}.viewprofile`,
    defaultMessage: "viewprofile",
  },
  most_watched: {
    id: `${scope}.most_watched`,
    defaultMessage: "Most Watched Speaker..",
  },
  date: {
    id: `${scope}.date`,
    defaultMessage: "Date",
  },
  location: {
    id: `${scope}.location`,
    defaultMessage: "Location",
  },
  speakers: {
    id: `${scope}.speakers`,
    defaultMessage: "Speakers",
  },
  rating: {
    id: `${scope}.rating`,
    defaultMessage: "Rating",
  },
});