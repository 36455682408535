import { useAppDispatch } from "store/hooks";
import { Toast, snackSliceActions } from "store/snack_slice";

/* ms */
export const SNACK_DURATION = 1500 

const useSnack = () => {
  const dispatch = useAppDispatch();

  function triggerSnack(msg: string, bg: Toast) {
    dispatch(snackSliceActions.toggleSnack({ msg, bg, open: true }));
    const timer = setTimeout(() => {
      closeSnack();
      clearTimeout(timer);
    }, SNACK_DURATION);
  }

  function closeSnack() {
    dispatch(snackSliceActions.closeSnack());
  }

  return {
    triggerSnack,
    closeSnack,
  };
};

export default useSnack;
