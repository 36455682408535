import { substractDates, TIME_UNIT } from "./date_subtracter";

const normalizeDate = (publishDate: string): string => {
  const result: number = substractDates(publishDate);

  let append: string = "";

  if (result < 24)
    append = `${substractDates(publishDate, TIME_UNIT.HOUR)} Hour ago`;

  if (result >= 24)
    append = `${substractDates(publishDate, TIME_UNIT.DAY)} Day ago`;

  /* 730 is how many hours in month */
  if (result >= 730)
    append = `${substractDates(publishDate, TIME_UNIT.MONTH)} Month ago`;

  /* 8760 is how many hours in year */
  if (result >= 8760)
    append = `${substractDates(publishDate, TIME_UNIT.MONTH)} Year ago`;

  return append;
};

export default normalizeDate;
