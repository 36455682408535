import { Methods } from "types/http";
import apiUrls from "../urls";
import { VideoQuery } from "types/video_query";
import { VideoDetailsRequest } from "../video_service/endpoints";
import handleFormDataRequests from "utils/upload_form_data";
import { PodcastRequest, ToUpdatePodcast } from "./types";
import PodcastItem from "types/podcast_item";

export const getPodcastListAC = (body: VideoQuery) => ({
  url: apiUrls.getPodcastList(body),
  method: Methods.GET,
  headers: {
    Lng: "EN",
    Accept: "application/json",
    Authorization: `Bearer ${body.token}`,
  },
});

export const getPodcastByIdAC = (body: VideoDetailsRequest) => ({
  url: apiUrls.podcastById(body.id),
  method: Methods.GET,
  headers: {
    Lng: "EN",
    Accept: "application/json",
    Authorization: `Bearer ${body.token}`,
  },
});

export const deletePodcastByIdAC = (body: VideoDetailsRequest) => ({
  url: apiUrls.podcastById(body.id),
  method: Methods.DELETE,
  headers: {
    Lng: "EN",
    Accept: "application/json",
    Authorization: `Bearer ${body.token}`,
  },
});

export const uploadPodcastFormData = async (body: PodcastRequest) => {
  const res = await handleFormDataRequests<PodcastItem>({
    code: Methods.POST,
    url: apiUrls.createPodcast(),
    body,
    headers: {
      Lng: "EN",
      Accept: "application/json",
      "Accept-Encoding": "gzip, deflate, br",
      "Content-Type": "multipart/form-data; boundry=6000000000",
      Authorization: `Bearer ${body.token}`,
    },
  });
  return res;
};

export const updatePodcastFormData = async (params: {
  body: Partial<ToUpdatePodcast>;
  token: string;
}) => {
  const res = await handleFormDataRequests<Partial<ToUpdatePodcast>>({
    code: Methods.PUT,
    url: apiUrls.podcastById(params.body.id),
    body: params.body,
    headers: {
      Lng: "EN",
      Accept: "application/json",
      "Accept-Encoding": "gzip, deflate, br",
      "Content-Type": "multipart/form-data; boundry=6000000000",
      Authorization: `Bearer ${params.token}`,
    },
  });
  return res;
};