import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  modal: {
    borderRadius: 5,
    boxShadow: 5,
    marginBlock: "auto",
    marginX: "auto",
    height: {
      xs: 400,
      lg: 460,
    },
    width: {
      xs: "90%",
      md: 700,
      lg: 800,
    },
    textAlign: "center",
  },
  container: { width: "100%", height: "100%", paddingBlock: 10 },
  header: {
    fontSize: {
      md: 25,
      lg: 30,
    },
    font: "Inter, K2D",
    fontWeight: "700",
  },
  subHeader: {
    fontSize: {
      md: 20,
      lg: 25,
    },
    font: "Inter, K2D",
    opacity: 0.6,
    marginTop: 1,
  },
  button: {
    width: { md: 170, lg: 200 },
    fontSize: {
      md: 17,
      lg: 20,
    },
    marginTop: { xs: 4, md: 3, lg: 4 },
  },
  resendMessage: {
    opacity: 0.6,
    fontSize: {
      md: 18,
      lg: 25,
    },
    font: "Inter, K2D",
    marginTop: { md: 1.6, lg: 2 },
  },
};

export default styles;
