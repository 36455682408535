import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type PaginationKeyItem = {
  page: number;
  perPage: number;
  count?: number;
};

type State = {
  [K in string]: PaginationKeyItem;
};

const initialState: State = {};

const paginationSlice = createSlice({
  name: "pagination_slice",
  initialState,
  reducers: {
    setPaginationItem: (
      state: State,
      { payload }: PayloadAction<{ key: string; body: PaginationKeyItem }>
    ) => {
      state[payload.key] = payload.body;
      return state;
    },
    updatePaginationItemPage: (
      state: State,
      { payload }: PayloadAction<{ key: string; page: number }>
    ) => {
      if (state[payload.key]) {
        state[payload.key].page = payload.page;
      }
      return state;
    },
    updatePaginationItemCount: (
      state: State,
      { payload }: PayloadAction<{ key: string; count: number }>
    ) => {
      if (state[payload.key]) {
        state[payload.key].count = payload.count;
      }
      return state;
    },
    resetPaginationItem: (
      state: State,
      { payload }: PayloadAction<{ key: string }>
    ) => {
      if (state[payload.key]) {
        state[payload.key].page = 1;
      }
      return state;
    },
  },
});

export default paginationSlice.reducer;
export const paginationSliceeActions = paginationSlice.actions;
