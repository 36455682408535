import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    width: {
      lg: "100%",
    },
  },
  listContainer: {
    width: {
      lg: "90%",
    },
    marginX: "auto",
  },
  gridContainer: {
    justifyContent: { xs: "center", md: "flex-start", lg: "flex-start" },
    columnGap: {
      xs: 0,
      md: 1.5,
      lg: 2.5,
    },
    rowGap: {
      xs: 3.5,
    },
  },
  contentLabel: {
    color: "black",
    marginBottom: 2,
    marginInlineStart: 2,
    fontSize: {
      xs: 32,
      md: 40,
      lg: 30,
    },
    fontWeight: "600",
  },
};

export default styles;
