import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {},
  headerContainer: {
    marginInlineStart: { md: 10 },
  },
  thumbImage: {
    marginBottom: 2,
    borderRadius: 3,
    width: { xs: 376, lg: 476 },
    aspectRatio: "4 / 3",
    boxShadow: 3,
  },
  viewProfile: {
    borderColor: "black",
    color: "black",
    marginBottom: { xs: 2, md: 0 },
  },
  ratingValue: {
    fontSize: { lg: 14 },
  },
};

export default styles;
