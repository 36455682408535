import { defineMessages } from 'react-intl';

const scope = "ExplorePodcasts";

export default defineMessages({
  Trending: {
    id: `${scope}.Trending`,
    defaultMessage: "Trending",
  },
  no_result: {
    id: `${scope}.no_result`,
    defaultMessage: "0 Online Videos Found",
  },
  Most_Viewed: {
    id: `${scope}.Most_Viewed`,
    defaultMessage: "Most Viewed",
  },
  upcoming: {
    id: `${scope}.upcoming`,
    defaultMessage: "Upcoming",
  },
  this_week: {
    id: `${scope}.this_week`,
    defaultMessage: "This Week",
  },
  results: {
    id: `${scope}.results`,
    defaultMessage: "Results",
  },
});