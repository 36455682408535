import { SxProps } from "@mui/material";
import color_pallette from "theme/color_pallette";

const styles: Record<string, SxProps> = {
  container: {
    width: 45,
    height: 45,
    padding: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    border: `1px solid ${color_pallette.primary}`,
    borderRadius: 2,
  },
  iconSubTitle: { fontSize: { xs: 9, lg: 13 }, whiteSpace: "nowrap" },
  iconTitle: {
    fontSize: { xs: 10, lg: 13 },
    fontWeight: "400",
    color: color_pallette.primary,
    letterSpacing: 0.4,
    marginTop: 0.5,
  },
};

export default styles;
