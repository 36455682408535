import { defineMessages } from "react-intl";

const scope = "Appbar";

export default defineMessages({
  Home: {
    id: `${scope}.Home`,
    defaultMessage: "Home",
  },
  about_us: {
    id: `${scope}.about_us`,
    defaultMessage: "About Us",
  },
  live_interactive: {
    id: `${scope}.live_interactive`,
    defaultMessage: "Live Tips Interactive",
  },
  languages: {
    id: `${scope}.languages`,
    defaultMessage: "Languages",
  },
  Speakers: {
    id: `${scope}.Speakers`,
    defaultMessage: "Speakers",
  },
  explore_Speakers: {
    id: `${scope}.explore_Speakers`,
    defaultMessage: "Explore Speakers",
  },
  join_us: {
    id: `${scope}.join_us`,
    defaultMessage: "Join Us",
  },
  dubai_police: {
    id: `${scope}.dubai_police`,
    defaultMessage: "Dubai Police",
  },
  dubai_police_speakers: {
    id: `${scope}.dubai_police_speakers`,
    defaultMessage: "Dubai Police Speakers",
  },
  dubai_police_speakers_online: {
    id: `${scope}.dubai_police_speakers_online`,
    defaultMessage: "Dubai Police Online",
  },
  services: {
    id: `${scope}.services`,
    defaultMessage: "Services",
  },
  live_tips_online: {
    id: `${scope}.live_tips_online`,
    defaultMessage: "Live Tips Online",
  },
  live_tips_podcast: {
    id: `${scope}.live_tips_podcast`,
    defaultMessage: "Live Tips Podcast",
  },
  live_tips_employees: {
    id: `${scope}.live_tips_employees`,
    defaultMessage: "Live Tips Employees",
  },
  live_tips_nomination: {
    id: `${scope}.live_tips_nomination`,
    defaultMessage: "Live Tips Nominations",
  },
  blog: {
    id: `${scope}.blog`,
    defaultMessage: "Blog",
  },
});
