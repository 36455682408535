import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    paddingBlock: 2,
    paddingInline: 4,
    position: "relative",
  },
  cursor: {
    cursor: "pointer",
  },
};

export default styles;
