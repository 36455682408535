import { FC } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import styles from "./styles";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import PauseRoundedIcon from "@mui/icons-material/PauseRounded";

type Props = {
  handleClick: () => void;
  playing: boolean;
};

const ListenButton: FC<Props> = ({ handleClick, playing }) => {
  return (
    <Box onClick={handleClick} sx={styles.container}>
      {playing ? (
        <PauseRoundedIcon sx={styles.icons} />
      ) : (
        <PlayArrowRoundedIcon sx={styles.icons} />
      )}
      {playing && <Typography sx={styles.listenLabel}>Playing</Typography>}
    </Box>
  );
};

export default ListenButton;
