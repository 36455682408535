import { Identification } from "types/identification";
import { NAMESPACES } from "../namespaces";

export const nominationUrls = {
  postNomination: () => `${NAMESPACES.nomination}/nomination`,

  getNominationById: (id: Identification) =>
    `${NAMESPACES.nomination}/nomination/${id}`,

  getNomineeList: () => `${NAMESPACES.nomination}/nominations`,
};
