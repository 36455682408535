import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    height: 180,
    borderRadius: 5,
    overflow: "hidden",
    boxShadow: 3,
  },
};

export default styles;
