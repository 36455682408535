import { FC } from "react";
import OnlineWrapper from "components/online_wrapper";
import { UploadedFileStatus } from "types/uploaded_file_status";
import { UploadPrivacy } from "types/upload_privacy";
import messages from "../messages";
import { useIntl } from "react-intl";
import { Stack } from "@mui/material";

const TrendingSection: FC = () => {
  const intl = useIntl();

  return (
    <>
      <OnlineWrapper
        listLabel={intl.formatMessage(messages.Trending)}
        icon="online"
        emptyLabel={intl.formatMessage(messages.no_result)}
        videoQuery={{
          status: UploadedFileStatus.ACCEPTED,
          privacy: UploadPrivacy.PUBLIC,
        }}
        perPage={3}
        skeletons={{
          xs: 12,
          sm: 6,
          md: 9,
          lg: 5.6,
          xl: 4,
        }}
        dimensions={{
          xs: 12,
          sm: 6,
          md: 9,
          lg: 3.8,
          xl: 4,
        }}
      />
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        width="100%"
        marginBlock={2}
      ></Stack>
    </>
  );
};

export default TrendingSection;
