import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    display: "flex",
    flexDirection: "column",
    borderRadius: 5,
    padding: 3,
    boxShadow: 1,
  },
};

export default styles;
