import React from "react";

type MemberContext = {
  activeCategory?: string;
  location?: string;
  setActiveCategory?: React.Dispatch<React.SetStateAction<string>>;
  setLocation?: React.Dispatch<React.SetStateAction<string>>;
};

const MembersContext = React.createContext<MemberContext>({
  activeCategory: "",
  location: "",
  setLocation: () => {},
  setActiveCategory: () => {},
});

export default MembersContext;
