import { Identification } from "types/identification";
import { GetConversationArgs, GetParticipantsArgs } from "./types";
import { NAMESPACES } from "../namespaces";

const chatUrls = {
  postParticipant: () => `${NAMESPACES.participants}`,
  participantById: (id: Identification) => `${NAMESPACES.participants}/${id}`,
  getParticipants: ({
    conversation,
    user,
    role,
    page,
  }: Partial<GetParticipantsArgs>) =>
    `${NAMESPACES.participants}?conversation=${conversation}&user=${user}&role=${role}&page=${page}`,

  chatMessageById: (id: Identification) => `${NAMESPACES.messages}/${id}`,
  getChatMessages: ({
    conversation,
    user,
    page,
  }: Partial<GetParticipantsArgs>) =>
    `${NAMESPACES.messages}?conversation=${conversation}&user=${user}&page=${page}`,
  postMessage: () => `${NAMESPACES.messages}`,

  conversationById: (id: Identification) => `${NAMESPACES.conversations}/${id}`,
  getConversation: ({ streaming, page }: Partial<GetConversationArgs>) =>
    `${NAMESPACES.conversations}?streaming=${streaming}&page=${page}`,
  postConversation: () => `${NAMESPACES.conversations}`,
};

export default chatUrls;
