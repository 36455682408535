import { defineMessages } from 'react-intl';

const scope = 'NominationScope';

export default defineMessages({
  home: {
    id: `${scope}.home`,
    defaultMessage: "Home",
  },
  nomination: {
    id: `${scope}.nomination`,
    defaultMessage: "Nomination",
  },
  inside: {
    id: `${scope}.inside`,
    defaultMessage: "Inside Live Tips",
  },
  outside: {
    id: `${scope}.outside`,
    defaultMessage: "Outside Live Tips",
  },
});