import { SxProps } from "@mui/material";
import color_pallette from "theme/color_pallette";

const styles: Record<string, SxProps> = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBlock: 5,
  },
  headerLabel: {
    fontSize: {
      xs: 30,
      md: 33,
    },
    color: color_pallette.primary,
    mb: {
      xs: 5,
      md: 0,
    },
  },
  header: {
    mt: { xs: 2, lg: 5 },
    width: { xs: "100%", lg: "90%" },
    alignSelf: "start",
    marginInlineStart: { lg: 5 },
    justifyContent: { xs: "center", md: "space-between" },
  },
  label: {
    fontSize: 30,
    fontWeight: "400",
  },
  insightsCardContainer: {
    width: {
      xs: "85%",
      md: `100%`,
    },
    rowGap: {
      xs: 3,
      md: 2,
    },
    columnGap: {
      md: 2,
    },
    marginTop: 5,
    marginInlineStart: {
      lg: 10,
    },
  },
  insighHeader: {
    width: "100%",
    backgroundColor: color_pallette.primary,
    height: 100,
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  seeMore: {
    backgroundColor: color_pallette.backgroundGray,
    color: "black",
    boxShadow: 1,
    fontWeight: "300",
    marginTop: { xs: 2, lg: 10 },
    ":hover": {
      backgroundColor: color_pallette.backgroundGray,
      color: "black",
    },
  },
};

export default styles;
