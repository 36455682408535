import { FC, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Badge from "@mui/material/Badge";
import nav_save_ic from "assets/svgs/nav_save_ic.svg";
import notificationsIC from "assets/svgs/notification.svg";
import styles from "./styles";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "navigation/routes";
import { useGetUserBookmarksListQuery } from "store/api/bookmark_service/endpoints";
import { useGetAllNotificationsQuery } from "store/api/notification_service/endpoints";
import { useAppDispatch, useAppSelector } from "store/hooks";
import Menu from "components/menu";
import { Local } from "types/local";
import { globalSliceActions } from "store/global_slice";
import { useIntl } from "react-intl";
import messages from "../menu_items/messages";
import { badgeSliceActions } from "store/badge_slice";

const IconSection: FC = () => {
  const navigate = useNavigate();

  const handleNavigate = (path: NavigationRoutes) => {
    navigate(path);
  };

  const dispatch = useAppDispatch();

  const { speakerProfile, credentials } = useAppSelector(
    (state) => state.global
  );

  const oldNotification = useAppSelector(
    (state) => state.badge.notificationList
  );

  const { notifications: notificationCount, saved: savedCount } =
    useAppSelector((state) => state.badge);

  const { data: bookmarks } = useGetUserBookmarksListQuery(
    {
      id: speakerProfile.customerId,
      token: credentials.token.access_token,
    },
    {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
    }
  );

  const { data: notificationList } = useGetAllNotificationsQuery(
    {
      token: credentials.token.access_token,
    },
    {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
    }
  );

  const oldbookmarkList = useAppSelector((state) => state.badge.bookmarkList);

  useEffect(() => {
    if (
      notificationList &&
      oldNotification &&
      notificationList.length == oldNotification.length
    ) {
      dispatch(badgeSliceActions.setNotifications(0));
    } else if (notificationList && oldNotification) {
      dispatch(
        badgeSliceActions.setNotifications(
          notificationList?.length - oldNotification.length
        )
      );
    }
    if (
      bookmarks &&
      oldbookmarkList &&
      bookmarks.length == oldbookmarkList.length
    ) {
      dispatch(badgeSliceActions.setSaved(0));
    } else if (bookmarks && oldbookmarkList) {
      dispatch(
        badgeSliceActions.setSaved(bookmarks?.length - oldbookmarkList.length)
      );
    }
    dispatch(badgeSliceActions.setNotificationList(notificationList ?? []));
    dispatch(badgeSliceActions.setBookmarkList(bookmarks ?? []));
  }, [bookmarks, notificationList]);

  const intl = useIntl();

  return (
    <Stack
      justifyContent="space-between"
      alignItems="center"
      columnGap={4}
      direction="row"
    >
      <Menu
        menuLabel={intl.formatMessage(messages.languages)}
        menuItems={[
          {
            label: Local.Arabic,
            handleClick: () =>
              dispatch(globalSliceActions.changeLanguage(Local.Arabic)),
          },
          {
            label: Local.English,
            handleClick: () =>
              dispatch(globalSliceActions.changeLanguage(Local.English)),
          },
        ]}
      />
      <Badge badgeContent={savedCount} color="primary">
        <Box
          sx={styles.icon}
          onClick={() => handleNavigate(NavigationRoutes.myBookmarks)}
          component="img"
          src={nav_save_ic}
        />
      </Badge>
      <Badge badgeContent={notificationCount} color="primary">
        <Box
          sx={styles.icon}
          onClick={() => handleNavigate(NavigationRoutes.myNotifications)}
          component="img"
          src={notificationsIC}
        />
      </Badge>
    </Stack>
  );
};

export default IconSection;
