import { PropsWithChildren } from "react";
import { IntlProvider } from "react-intl";
import { Local } from "types/local";
import LanguageMapper from "./languages";

type Props = {
  local: Local;
};

const LanguageProvider = ({ children, local }: PropsWithChildren<Props>) => {
  return (
    <IntlProvider
      locale={local}
      defaultLocale={Local.English}
      messages={LanguageMapper[local]}
    >
      {children}
    </IntlProvider>
  );
};

export default LanguageProvider;
