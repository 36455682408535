import { FC } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import styles from "./styles";
import UploadDocumentForm from "../upload_document_form";
import { useLocation } from "react-router-dom";
import { useGetMembershipByIdQuery } from "store/api/membership_service/endpoints";
import { useAppSelector } from "store/hooks";
import { Stack } from "@mui/material";
import Nominee from "components/nominee";
import DoubleArrowRoundedIcon from "@mui/icons-material/DoubleArrowRounded";
import color_pallette from "theme/color_pallette";
/* import { Stack } from "@mui/material"; */
/* import GeneralInfoForm from "../general_info_form";
import NomineeFormSection from "../nominee_form_section"; */
/* import UploadDocumentForm from "../upload_document_form"; */
/* import NominationForm from "../nomination_form"; */

const FormSection: FC = () => {
  const { state } = useLocation();

  const { speakerProfile, credentials } = useAppSelector(
    (state) => state.global
  );

  const { data } = useGetMembershipByIdQuery(
    {
      id: state,
      token: credentials.token.access_token,
    },
    {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
    }
  );

  return (
    <>
      <Box sx={styles.labels}>
        <Typography sx={styles.header}>Nomination Form</Typography>
        <Typography sx={styles.subHeader}>
          Please utilize this form to fill your information to nominate future
          speaker
        </Typography>
      </Box>
      <Stack direction="row" marginTop={2} columnGap={2} alignItems="center">
        <Nominee
          id={speakerProfile.customerId}
          name={speakerProfile.name}
          org={speakerProfile.org}
          profile_picture={speakerProfile.profilePicture}
        />
        <DoubleArrowRoundedIcon
          sx={{ width: 100, color: color_pallette.primary, height: 100 }}
        />
        {data && (
          <Nominee
            id={data?.user.profilePicture}
            name={data?.user.name}
            org={data?.user.org}
            profile_picture={data?.user.profilePicture}
          />
        )}
      </Stack>
      <Box sx={styles.container}>
        {/* <GeneralInfoForm />
        <NomineeFormSection /> */}
        <UploadDocumentForm />
        {/* <NominationForm /> */}
      </Box>
    </>
  );
};

export default FormSection;
