import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    borderRadius: 3,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginX: "auto",
    overflow: "hidden",
    paddingBlock: {
      xs: 3,
    },
    width: {
      xs: "90%",
    },
  },
  datePublished: {
    opacity: 0.7,
    marginBottom: 1,
  },
  flex: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "90%",
    marginTop: {
      xs: 10,
      lg: 3,
    },
  },
  statsContainer: {
    display: "flex",
    flexDirection: {
      xs: "column",
      lg: "row",
    },
    width: "100%",
    justifyContent: { lg: "space-between" },
    alignItems: "center",
    rowGap: 2,
    mt: 3,
  },
};

export default styles;
