import { FC } from "react";
import Box from "@mui/material/Box";
import styles from "./styles";
import Typography from "@mui/material/Typography";
import Grow from "@mui/material/Grow";
import Stack from "@mui/material/Stack";
import Button from "components/custom_button";
import color_pallette from "theme/color_pallette";
import group_4 from "assets/images/group_4.webp";

const PollSuggestionVote: FC = () => {
  return (
    <Grow in timeout={600}>
      <Box sx={styles.container}>
        <Typography sx={styles.header}>
          Suggestion <span style={{ fontWeight: "700" }}>Poll</span>
        </Typography>
        <Stack alignItems="center" columnGap={2} direction="row">
          <Typography sx={styles.subHeader}>
            Vote your Favourite Speaker you want to listen
          </Typography>
          <Button
            label="Vote"
            extraStyles={{
              backgroundColor: "white",
              color: color_pallette.primary,
              fontWeight: "700",
              boxShadow: 3,
              marginInlineEnd: { xs: 1.5, md: 0.5 },
              borderRadius: 3,
              ":hover": {
                backgroundColor: "white",
                color: color_pallette.primary,
              },
            }}
          />
        </Stack>
        <Box
          component="img"
          sx={{ width: "100%", objectFit: "cover", marginTop: 2 }}
          src={group_4}
        />
      </Box>
    </Grow>
  );
};

export default PollSuggestionVote;
