import { FC } from "react";
import Paper from "@mui/material/Paper";
import Modal from "@mui/material/Modal";
import Grow from "@mui/material/Grow";
import styles from "./styles";
import { useAppDispatch, useAppSelector } from "store/hooks";
import EditModalHeaderSection from "../edit_video_modal/header_section";
import FooterSection from "./footer_section";
import BodySection from "./body_section";
import { ProfileSliceActions } from "store/profile_slice";

const ConfirmationModal: FC = () => {
  const dispatch = useAppDispatch();

  const showModal = useAppSelector(
    (state) => state.adminProfil.modals.showConfirmation
  );

  const handleBack = () => {
    dispatch(ProfileSliceActions.showEditUploadModal());
  };

  return (
    <Modal open={showModal} sx={styles.container}>
      <Grow in={showModal} timeout={600}>
        <Paper>
          <EditModalHeaderSection
            onBackClick={handleBack}
            confirmation={true}
          />
          <BodySection />
          <FooterSection />
        </Paper>
      </Grow>
    </Modal>
  );
};

export default ConfirmationModal;
