import { Box, Theme, useMediaQuery } from "@mui/material";
import { ComponentType, useEffect } from "react";
import FooterSection from "pages/home/footer_section";
import Appbar from "components/appbar";
import MobileAppbar from "components/mobile_appbar";
import MobileFooter from "components/mobile_footer";

function withScreenHeaders(Component: ComponentType) {
  return () => {
    const mdScreen = useMediaQuery((theme: Theme) =>
      theme.breakpoints.up("md")
    );

    useEffect(() => {
      window.scrollTo({ behavior: "smooth", top: 0 });
      window.scrollX = 0;
      window.scrollY = 0;
    }, []);

    return (
      <Box>
        {mdScreen ? <Appbar /> : <MobileAppbar />}
        <Component />
        {mdScreen ? <FooterSection /> : <MobileFooter />}
      </Box>
    );
  };
}

export default withScreenHeaders;
