import { defineMessages } from 'react-intl';

const scope = "PodcastSectionsHeaderSection";

export default defineMessages({
  livetips_podcasts: {
    id: `${scope}.livetips_podcasts`,
    defaultMessage: "Podcasts",
  },
  livetips: {
    id: `${scope}.livetips`,
    defaultMessage: "Live Tips",
  },
  podcasts: {
    id: `${scope}.podcasts`,
    defaultMessage: "Podcasts",
  },
});