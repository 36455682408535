import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { VideoResponse } from "store/api/types";

type State = {
  dataList: Array<VideoResponse>;
};

const initialState: State = {
  dataList: [],
};

const VideoServiceSlice = createSlice({
  name: "videoServiceSlice",
  initialState,
  reducers: {
    insertVideo: (
      state: State,
      { payload }: PayloadAction<VideoResponse | null>
    ) => {
      if (payload != null) state.dataList.push(payload);
      return state;
    },
  },
});

export default VideoServiceSlice.reducer;
export const VideoServiceSliceActions = VideoServiceSlice.actions;
