export default {
  primary: "#008755",
  /* primary: "#008755", */
  lightGray: "#f5f5f5",
  backgroundGray: "#FAFAFA",
  lightPrimary: "#DBE8EE4D",
  iconTint: "#7196A5",
  transparent: "rgba(112, 152, 169, 0.7)",
  transparent_green: "rgba(0, 135, 86, 0.5)",
  cyanPrimary: "#8DD4D6",
  danger: "#d9534f",
  info: "#5bc0de",
  warning: "#cc3300",
};
