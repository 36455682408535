import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import data from "./data";
import PodcastItem from "types/podcast_item";
import { Channel } from "store/api/streaming_service/types";
import { Identification } from "types/identification";

/* type Step = Record<string, boolean>;

const step_1: Step = {
  showConfirmationModal: true,
  showUploadModal: false,
  showEditModal: false,
};

const step_2: Step = {
  showConfirmationModal: true,
  showUploadModal: false,
  showEditModal: false,
};

const step_3: Step = {
  showConfirmationModal: true,
  showUploadModal: false,
  showEditModal: false,
};

const step_4: Step = {
  showConfirmationModal: true,
  showUploadModal: false,
  showEditModal: false,
}; */

export type PodcastRecord = {
  podcastId: string | number;
  duration: number;
  progress: number;
  media: string;
  playing: boolean;
  profilePic: string;
  hostName: string;
  playbackSpeed: number;
  length?: number;
  is_bookmarked?: boolean;
  publisherId?: Identification;
};

type State = {
  data: Array<PodcastItem>;
  showUploadModal: boolean;
  showEditModal: boolean;
  showConfirmationModal: boolean;
  showGuideModal: boolean;
  showPodcastPremier: boolean;
  toEditPodcast: PodcastItem | null;
  upcomingPodcast: Channel;
  currentRecord: PodcastRecord;
  podcastRecords: Array<PodcastRecord>;
};

const initialState: State = {
  data,
  showConfirmationModal: false,
  showEditModal: false,
  toEditPodcast: null,
  showGuideModal: false,
  showUploadModal: false,
  showPodcastPremier: false,
  upcomingPodcast: {} as Channel,
  podcastRecords: [],
  currentRecord: {
    duration: 0,
    hostName: "",
    media: "",
    playbackSpeed: 1,
    is_bookmarked: false,
    playing: false,
    podcastId: "",
    profilePic: "",
    length: 0,
    progress: 0,
  },
};

const LivetipsPodcastSlice = createSlice({
  name: "livetipsPodcast",
  initialState,
  reducers: {
    insertPodcast: (state: State, { payload }: PayloadAction<PodcastItem>) => {
      state.data.push(payload);
      return state;
    },
    clearCurrentPodcast: (state: State) => {
      state.currentRecord = initialState.currentRecord;
      return state;
    },
    setToEditPodcast: (
      state: State,
      { payload }: PayloadAction<PodcastItem | null>
    ) => {
      state.toEditPodcast = payload;
      return state;
    },
    insertCurrentPodcast: (
      state: State,
      { payload }: PayloadAction<PodcastRecord>
    ) => {
      state.currentRecord = payload;
      state.podcastRecords?.push({ ...payload, playing: false });
      return state;
    },
    toggleBookmarkCurrentRecord: (
      state: State,
      { payload }: PayloadAction<boolean>
    ) => {
      state.currentRecord.is_bookmarked = payload;
      return state;
    },
    insertPodcastChannel: (
      state: State,
      { payload }: PayloadAction<Channel>
    ) => {
      state.upcomingPodcast = payload;
      return state;
    },
    updateCurrentPodcastDuration: (
      state: State,
      { payload }: PayloadAction<number>
    ) => {
      if (state.currentRecord) {
        const duration = Math.round(payload / 60);
        state.currentRecord.duration = duration;
        state.currentRecord.length = payload;
      }
      return state;
    },
    updatePlaybackRate: (state: State, { payload }: PayloadAction<number>) => {
      if (state.currentRecord) {
        state.currentRecord.playbackSpeed = payload;
      }
      return state;
    },
    toggleCurrentPodcast: (state: State) => {
      if (state.currentRecord) {
        state.currentRecord.playing = !state.currentRecord.playing;
      }
      return state;
    },

    /* TODO: Instead of this make ready objects called steps with ready values */
    showConfirmationModal: (state: State) => {
      state.showConfirmationModal = true;
      state.showUploadModal = false;
      state.showPodcastPremier = false;
      state.showEditModal = false;
      state.showGuideModal = false;
      return state;
    },
    showPodcastPremier: (state: State) => {
      state.showConfirmationModal = false;
      state.showUploadModal = false;
      state.showPodcastPremier = true;
      state.showEditModal = false;
      state.showGuideModal = false;
      return state;
    },
    showUploadModal: (state: State) => {
      state.showUploadModal = true;
      state.showConfirmationModal = false;
      state.showPodcastPremier = false;
      state.showEditModal = false;
      state.showGuideModal = false;
      return state;
    },
    showEditModal: (state: State) => {
      state.showConfirmationModal = false;
      state.showPodcastPremier = false;
      state.showUploadModal = false;
      state.showEditModal = true;
      state.showGuideModal = false;
      return state;
    },
    showGuideModal: (state: State) => {
      state.showConfirmationModal = false;
      state.showPodcastPremier = false;
      state.showUploadModal = false;
      state.showEditModal = false;
      state.showGuideModal = true;
      return state;
    },
    closeModals: (state: State) => {
      state.showConfirmationModal = false;
      state.showUploadModal = false;
      state.showPodcastPremier = false;
      state.showEditModal = false;
      state.showGuideModal = false;
      return state;
    },
  },
});

export default LivetipsPodcastSlice.reducer;
export const LivetipsPodcastSliceActions = LivetipsPodcastSlice.actions;
