import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  datePublished: {
    opacity: 0.7,
    marginBottom: 1,
  },
};

export default styles;
