import { defineMessages } from "react-intl";

const scope = "Nominations";

export default defineMessages({
  nominate: {
    id: `${scope}.nominate`,
    defaultMessage: "Nominate",
  },
  no_nominees: {
    id: `${scope}.no_nominees`,
    defaultMessage: "0 Nominees Found",
  },
  suggestion: {
    id: `${scope}.suggestion`,
    defaultMessage: "suggestion",
  },
  coming_soon_feature: {
    id: `${scope}.coming_soon_feature`,
    defaultMessage: "This Feature Will be available Soon !",
  },
  poll: {
    id: `${scope}.poll`,
    defaultMessage: "Poll",
  },
});
