import { Breakpoint, useMediaQuery } from "@mui/material";
import { useWidth } from "hooks/useWidth";
import { useMemo } from "react";
import { Autoplay, Pagination } from "swiper/modules";
import theme from "theme";

const getNumberOfSlides = (breakPoint: Breakpoint): number => {
  if (breakPoint === "md") return 2;
  return 1;
};

const getPaddingInline = (breakPoint: Breakpoint): number => {
  if (breakPoint === "xs") return 0;
  return 35;
};

const getLivePodcastPaddingInline = (br: Breakpoint): number => {
  if (br === "xs") return 0;
  return 35;
};

/* const getLessonsPaddingInline = (br: Breakpoint) => {
  if (br === "xs" || br === "sm") return 25;
  return 50;
}; */

const getSpaceBetween = (br: Breakpoint): number => {
  if (br === "lg" || br === "xl") return 60;
  if (br === "xs") return 2;
  return 0;
};

const useLiveSwiperProps = () => {
  const breakPoint = useWidth();

  const swiperProps = useMemo(() => {
    return {
      spaceBetween: getSpaceBetween(breakPoint),
      style: {
        paddingInline: getPaddingInline(breakPoint),
        paddingBottom: 20,
        paddingTop: 20,
        width: "100%",
      },
      slidesPerView: getNumberOfSlides(breakPoint),
      freeMode: true,
      autoplay: true,
      autoFocus: true,
      modules: [Pagination, Autoplay],
      pagination: {
        clickable: true,
        renderBullet: function () {
          return (
            '<span class="' +
            " custom-top-swiper " +
            '"><img class="pagination-bullet"/></span>'
          );
        },
      },
      navigation: true,
      loop: true,
    };
  }, [breakPoint]);

  const mdScreen = useMediaQuery(theme.breakpoints.up("md"));

  const lessonsSwiperProps = useMemo(() => {
    return {
      style: {
        paddingBottom: 10,
        paddingTop: 20,
        width: 850,
      },
      slidesPerView: 2.5,
      spaceBetween: 30,
      freeMode: true,
      autoplay: true,
      autoFocus: true,
      navigation: true,
      loop: true,
    };
  }, [mdScreen, breakPoint]);

  const livePodcastSwiperProps = useMemo(() => {
    return {
      spaceBetween: getSpaceBetween(breakPoint),
      style: {
        paddingInline: getLivePodcastPaddingInline(breakPoint),
        paddingBottom: 20,
        paddingTop: 20,
      },
      slidesPerView: getNumberOfSlides(breakPoint),
      freeMode: true,
      autoplay: true,
      autoFocus: true,
      modules: [Pagination, Autoplay],
      pagination: {
        clickable: true,
        renderBullet: function () {
          return (
            '<span class="' +
            " custom-top-swiper " +
            '"><img class="pagination-bullet"/></span>'
          );
        },
      },
      navigation: true,
      loop: true,
    };
  }, [breakPoint]);

  return {
    swiperProps,
    lessonsSwiperProps,
    livePodcastSwiperProps,
  };
};

export default useLiveSwiperProps;
