import { SxProps } from "@mui/material";
import color_pallette from "theme/color_pallette";

const styles: Record<string, SxProps> = {
  container: {
    backgroundColor: color_pallette.lightPrimary,
    borderRadius: 5,
    boxShadow: 0.1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    width: 190,
    paddingBlock: 3,
  },
  img: { height: 100, width: 100 },
  header: {
    marginTop: 1.4,
    fontSize: {
      lg: 16,
    },
  },
  subHeader: {
    fontSize: {
      lg: 15,
    },
    opacity: 0.7,
  },
};

export default styles;
