import { FC } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import styles from "./styles";

type Props = {
  img: string;
  label: string;
  name: string;
  onCheck: () => void;
};

const EventType: FC<Props> = ({ img, label, onCheck }) => {
  return (
    <Stack
      onClick={onCheck}
      sx={styles.typeWrapper}
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        width="100%"
        direction="row"
        alignItems="flex-start"
        justifyContent="flex-end"
      >
        <Checkbox sx={styles.checkbox} name="instant" value="instant" />
      </Stack>
      <Box component="img" sx={styles.iconX} src={img} />
      <Typography sx={styles.label}>{label}</Typography>
    </Stack>
  );
};
export default EventType;
