import { FC } from "react";
import Box from "@mui/material/Box";
import styles from "./styles";
import HeaderSection from "./header_section";
import BodySection from "./body_section";
import MyOnline from "../my_online";
import MyPodcasts from "../my_podcasts";

const ContentSection: FC = () => {
  return (
    <Box sx={styles.container}>
      <HeaderSection />
      <Box sx={styles.sectionContainer}>
        <BodySection />
        <MyOnline />
        <MyPodcasts />
      </Box>
    </Box>
  );
};

export default ContentSection;
