import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    marginX: "auto",
    textAlign: "center",
    paddingBottom: 4,
    marginTop: {
      xs: 2.5,
      lg: 5,
      xl: 10,
    },
    overflow: "scroll",
    width: {
      xs: "90%",
      sm: "85%",
      md: 800,
      lg: 1000,
    },
  },
  button: { marginBlock: 2, width: 120, height: 40 },
  paper: {
    borderRadius: 3,
    paddingInline: 1.5,
  },
};

export default styles;
