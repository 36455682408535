import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    paddingBlock: 30,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  relatedWrapper: {
    width: "75%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  header: {
    color: "white",
    fontWeight: "500",
    fontSize: {
      lg: 40,
    },
  },
};

export default styles;
