import { FC, Fragment, useState } from "react";
import Box from "@mui/material/Box";
import EmptyList from "components/empty_list";
import styles from "./styles";
import { UploadedFileStatus } from "types/uploaded_file_status";
import { useAppSelector } from "store/hooks";
import { useGetMembershipListQuery } from "store/api/membership_service/endpoints";
import { Stack, Typography } from "@mui/material";
import SpeakerListItem from "pages/explore_speakers/speaker_list_item";
import Button from "components/custom_button";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "navigation/routes";
import { FormattedMessage, useIntl } from "react-intl";
import messages from "./messages";
import { Identification } from "types/identification";

const InsideLiveTips: FC = () => {
  const [checked, setChecked] = useState<Identification>();

  const access_token = useAppSelector(
    (state) => state.global.credentials.token.access_token
  );

  const { data, isLoading } = useGetMembershipListQuery({
    token: access_token,
    status: UploadedFileStatus.ACCEPTED,
  });

  const handleCheck = (id: Identification) => {
    setChecked(id);
  };

  const isItemSelected = (id: Identification): 0 | 1 => {
    return checked == id ? 1 : 0;
  };

  const navigate = useNavigate();

  const handleSubmitNominees = () => {
    navigate(NavigationRoutes.nomination_form, {
      state: checked,
    });
  };

  const intl = useIntl();

  return (
    <Stack direction="column" alignItems="center">
      <Typography sx={styles.header}>
        <FormattedMessage id={messages.nominees.id} />
      </Typography>
      <Box sx={styles.container}>
        {!isLoading && data && data.length > 0 ? (
          data.map((entry) => (
            <Fragment key={entry.id}>
              <SpeakerListItem
                handleSelect={handleCheck}
                isChecked={isItemSelected(entry.id)}
                selected
                {...entry}
              />
            </Fragment>
          ))
        ) : (
          <EmptyList
            extraStyle={{ width: "100%", alignSelf: "center" }}
            icon="speakers"
            label={intl.formatMessage(messages.no_nominees)}
          />
        )}
      </Box>
      <Button
        label={intl.formatMessage(messages.submit)}
        extraStyles={{ marginTop: 5 }}
        onClick={handleSubmitNominees}
      />
    </Stack>
  );
};

export default InsideLiveTips;
