import rootApi from "store/api";
import {
  checkHealthAction,
  createVideoAction,
  deleteVideoByIdAction,
  getVideoByIdAction,
  getVideoListAction,
  partialUpdateVideoByIdAction,
  updateVideoByIdAction,
  uploadVideo,
} from "./action_creators";
import { VideoQuery } from "types/video_query";
import { UpdateVideoBody, VideoResponse, VideoServiceResponse } from "../types";
import { Identification } from "types/identification";

export type UploadVideoRequest = {
  token: string;
  body: FormData;
};

export type VideoDetailsRequest = {
  token: string;
  id: string | undefined;
};

export const videoServiceApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    checkHealth: builder.query<void, void>({
      query: () => checkHealthAction(),
    }),
    getVideoList: builder.query<void, VideoQuery>({
      query: (body) => getVideoListAction(body),
    }),
    postVideo: builder.mutation<VideoResponse, UploadVideoRequest>({
      query: (body) => createVideoAction(body),
    }),
    getVideoById: builder.query<VideoResponse, VideoDetailsRequest>({
      query: (param) => getVideoByIdAction(param),
    }),
    deleteVideoById: builder.mutation<void, string>({
      query: (id) => deleteVideoByIdAction(id),
    }),
    updateVideoById: builder.mutation<
      VideoServiceResponse,
      { id: Identification; body: Partial<UpdateVideoBody>; token: string }
    >({
      query: (body) => updateVideoByIdAction(body),
    }),
    partialUpdateVideoById: builder.mutation<void, string>({
      query: (id) => partialUpdateVideoByIdAction(id),
    }),
    uploadVideo: builder.mutation<void, FormData>({
      query: (body) => uploadVideo(body),
    }),
  }),
});

export const {
  useCheckHealthQuery,
  useDeleteVideoByIdMutation,
  useGetVideoByIdQuery,
  useGetVideoListQuery,
  useLazyGetVideoListQuery,
  usePartialUpdateVideoByIdMutation,
  usePostVideoMutation,
  useUpdateVideoByIdMutation,
  useUploadVideoMutation,
} = videoServiceApi;
