import { FC } from "react";
import OnlineWrapper from "components/online_wrapper";
import { UploadPrivacy } from "types/upload_privacy";
import { UploadedFileStatus } from "types/uploaded_file_status";
import { useIntl } from "react-intl";
import messages from "../messages";

const TrendingSection: FC = () => {
  const intl = useIntl();
  return (
    <OnlineWrapper
      paginationName={intl.formatMessage(messages.trending)}
      listLabel={intl.formatMessage(messages.trending)}
      icon="online"
      emptyLabel={intl.formatMessage(messages.no_online)}
      limit={[0, 3]}
      skeletons={{
        xs: 12,
        sm: 6,
        md: 9,
        lg: 5.6,
        xl: 4,
      }}
      videoQuery={{
        privacy: UploadPrivacy.DUBAI_POLICE,
        status: UploadedFileStatus.ACCEPTED,
      }}
      dimensions={{
        xs: 12,
        sm: 6,
        md: 9,
        lg: 6,
        xl: 4,
      }}
    />
  );
};

export default TrendingSection;
