import { FC } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ScheduleRoundedIcon from "@mui/icons-material/ScheduleRounded";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import InteractiveIcon from "components/interactive_icon";
import { ReactSVG } from "react-svg";
import InteractiveEvent from "types/interactive_item";
import gpsSvg from "assets/svgs/gps_gradient.svg";
import online from "assets/svgs/support.svg";
import attendees from "assets/svgs/attendees.svg";
import styles from "./styles";
import Button from "components/custom_button";
import { Upcoming } from "store/api/streaming_service/types";

enum IconSubtitleMapper {
  online = "Online",
  onLocation = "Location",
  attendees = "Attendees",
}

const stats = {
  online: "301",
  attendees: "103",
  onLocation: "121",
};

const IconMapper: Record<string, string> = {
  onLocation: gpsSvg,
  online: online,
  attendees: attendees,
};

type PairProps = {
  Icon: typeof ScheduleRoundedIcon;
  label: string;
};

const PairItem: FC<PairProps> = ({ Icon, label }) => {
  return (
    <Stack direction="row" flexWrap="wrap" columnGap={1} alignItems="center">
      <Icon />
      <Typography sx={{ whiteSpace: "nowrap" }}>{label}</Typography>
    </Stack>
  );
};

type Props = {
  entry: Upcoming;
};

const UpcomingMeta: FC<Props> = ({ entry }) => {
  return (
    <Box>
      <Stack
        direction="row"
        columnGap={{ xs: 2, md: 5 }}
        marginTop={{ md: 3 }}
        sx={styles.container}
      >
        <PairItem Icon={CalendarMonthRoundedIcon} label={entry.scheduled_on} />
        <PairItem
          Icon={ScheduleRoundedIcon}
          label={`${entry.duration} Hours`}
        />
      </Stack>
      <Box sx={styles.iconsContainer}>
        {stats &&
          Object.keys(stats).map((entry) => (
            <InteractiveIcon
              key={entry}
              containerStyle={styles.iconContainerStyle}
              subTitleStyle={styles.iconSubTitle}
              Icon={<ReactSVG src={IconMapper[entry]} />}
              subTitle={
                (IconSubtitleMapper as any)[
                  entry
                ] as keyof InteractiveEvent["stats"]
              }
              title={(stats as any)[entry]}
            />
          ))}
        <Button label="Enroll" extraStyles={styles.button} />
      </Box>
    </Box>
  );
};

export default UpcomingMeta;
