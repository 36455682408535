import { SxProps } from "@mui/material";
import color_pallette from "theme/color_pallette";

const styles: Record<string, SxProps> = {
  container: {
    width: "100%",
  },
  sectionContainer: {
    display: "flex",
    flexDirection: {
      xs: "column",
      md: "row",
    },
    justifyContent: "center",
    columnGap: {
      md: 2,
    },
    paddingInline: {
      md: 2,
    },
    width: {
      xl: "95%",
    },
  },
  insighHeader: {
    width: "100%",
    backgroundColor: color_pallette.primary,
    height: 100,
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBlock: { lg: 10 },
  },
  label: {
    fontSize: 30,
    fontWeight: "400",
  },
};

export default styles;
