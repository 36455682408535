import { defineMessages } from "react-intl";

const scope = "ViewAdminProfile";

export default defineMessages({
  discussions: {
    id: `${scope}.discussions`,
    defaultMessage: "Discussions",
  },
  publish: {
    id: `${scope}.publish`,
    defaultMessage: "Publish",
  },
  alerts: {
    id: `${scope}.alerts`,
    defaultMessage: "Alerts",
  },
  add_streaming: {
    id: `${scope}.add_streaming`,
    defaultMessage: "Add Streaming",
  },
  join_speakers: {
    id: `${scope}.join_speakers`,
    defaultMessage: "Join Our Speakers",
  },
  view_profile: {
    id: `${scope}.view_profile`,
    defaultMessage: "Public View Profile",
  },
  create_online: {
    id: `${scope}.create_online`,
    defaultMessage: "Create Online Tips",
  },
  create_interactive: {
    id: `${scope}.create_interactive`,
    defaultMessage: "Create Interactive",
  },
  create_podcast: {
    id: `${scope}.create_podcast`,
    defaultMessage: "Create Live Tips Podcast",
  },
  profile_insights: {
    id: `${scope}.profile_insights`,
    defaultMessage: "Profile Insights",
  },
  insights: {
    id: `${scope}.insights`,
    defaultMessage: "Insights",
  },
  live: {
    id: `${scope}.live`,
    defaultMessage: "Live Podcast",
  },
  notice: {
    id: `${scope}.notice`,
    defaultMessage:
      "By submitting your Content to Live Tips, you acknowledge that you agree to Live Tips's Terms of Service and Community Guidelines. Please be sure not to violate others' copyright or privacy rights. Learn more",
  },
  podcast: {
    id: `${scope}.podcast`,
    defaultMessage: "Podcast",
  },
  privacy: {
    id: `${scope}.privacy`,
    defaultMessage: "Your Podcasts will be private until you publish them.",
  },
  users_can: {
    id: `${scope}.users_can`,
    defaultMessage: "User can upload pre Recorded, Live and Scheduled Podcast",
  },
  dubai_police: {
    id: `${scope}.dubai_police`,
    defaultMessage: "Dubai Police",
  },
  public: {
    id: `${scope}.public`,
    defaultMessage: "Public",
  },
  title: {
    id: `${scope}.title`,
    defaultMessage: "Podcast Title",
  },
  update_edit_profile: {
    id: `${scope}.update_edit_profile`,
    defaultMessage: "Update Profile",
  },
  desc: {
    id: `${scope}.desc`,
    defaultMessage: "Podcast Description",
  },
  link: {
    id: `${scope}.link`,
    defaultMessage: "Podcast Link",
  },
  details: {
    id: `${scope}.details`,
    defaultMessage: "Details",
  },
  session: {
    id: `${scope}.session`,
    defaultMessage: "session",
  },
  draft: {
    id: `${scope}.draft`,
    defaultMessage: "Draft",
  },
  back: {
    id: `${scope}.back`,
    defaultMessage: "Back",
  },
  invite: {
    id: `${scope}.invite`,
    defaultMessage: "Invite",
  },
  verified_speakers: {
    id: `${scope}.verified_speakers`,
    defaultMessage: "Verified Speakers List",
  },
  bio: {
    id: `${scope}.bio`,
    defaultMessage: "Bio",
  },
  updateProfile: {
    id: `${scope}.update`,
    defaultMessage: "Update Profile",
  },
  org: {
    id: `${scope}.org`,
    defaultMessage: "Organization",
  },
  pending: {
    id: `${scope}.pending`,
    defaultMessage: "Pending Status",
  },
  username: {
    id: `${scope}.username`,
    defaultMessage: "Username",
  },
  upload_thumb: {
    id: `${scope}.upload_thumb`,
    defaultMessage: "Upload Thumbnail",
  },
  viewprofile: {
    id: `${scope}.viewprofile`,
    defaultMessage: "View Profile",
  },
  save: {
    id: `${scope}.save`,
    defaultMessage: "Save",
  },
  share: {
    id: `${scope}.share`,
    defaultMessage: "Share",
  },
  drafted: {
    id: `${scope}.drafted`,
    defaultMessage: "Drafted Status",
  },
  joinus: {
    id: `${scope}.joinus`,
    defaultMessage: "Join us",
  },
  nominate: {
    id: `${scope}.nominate`,
    defaultMessage: "Nominate",
  },
});
