import { Methods } from "types/http";
import { otpUrls } from "./urls";
import { SendOTPBody, VerifyOTPBody } from "./types";

export const sendOTPAc = (param: { token: string; body: SendOTPBody }) => ({
  url: otpUrls.setOTP(),
  method: Methods.POST,
  body: param.body,
  headers: {
    Lng: "EN",
    // "Content-type": "application/json",
    Accept: "*/*",
    Authorization: `Bearer ${param.token}`,
  },
});

export const verifyOTPAc = (param: { token: string; body: VerifyOTPBody }) => ({
  url: otpUrls.verifyOTP(),
  method: Methods.POST,
  body: param.body,
  headers: {
    Lng: "EN",
    "Content-type": "application/json",
    Accept: "*/*",
    Authorization: `Bearer ${param.token}`,
  },
});
