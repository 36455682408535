import { FC, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import styles from "./styles";
import HeaderSection from "./header_section";
import BodySection from "./body_section";
import SidebarSection from "./sidebar_section";
import ActionSection from "./action_section";
import InsightSection from "./insight_section";
import CommentSection from "./comment_section";
import EditProfileModal from "./edit_profile_modal";
import FormContainer from "common/form_container";
import useViewProfile from "hooks/use_view_profile";
import MyStreamSection from "./my_stream_section";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import ThumbContext from "context/ThumbnailContext";
import {
  EditProfileFormNames,
  validationSchema,
} from "./edit_profile_modal/form";
import { FormattedMessage } from "react-intl";
import messages from "./messages";
import { useLocation } from "react-router-dom";
import { useAppDispatch } from "store/hooks";
import { modalSliceActions } from "store/modal_slice";
import NewuserModal from "components/newuser_modal";

const ViewAdminProfile: FC = () => {
  const ffmpeg = new FFmpeg();

  const [ready, setReady] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [thumb, setThumb] = useState<string>("");
  const [mpegInstance, setMpegInstance] = useState<FFmpeg | null>(null);

  const load = async () => {
    await ffmpeg.load();
    setMpegInstance(ffmpeg);
    setReady(ffmpeg.loaded);
  };

  const dispatch = useAppDispatch();

  const { state } = useLocation();

  useEffect(() => {
    if (state && state?.newUser) {
      dispatch(modalSliceActions.setNewUserModal(true));
    }

    return () => {
      dispatch(modalSliceActions.setNewUserModal(false));
    };
  }, [state]);

  useEffect(() => {
    load();
  }, []);

  const { handleSubmit, bio, org, name, ownerId } = useViewProfile();

  return (
    <Box sx={styles.container}>
      <HeaderSection />
      <FormContainer
        initialValues={{
          [EditProfileFormNames.username]: name ?? "",
          [EditProfileFormNames.bio]: bio ?? "",
          [EditProfileFormNames.org]: org ?? "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <EditProfileModal />
      </FormContainer>
      <NewuserModal />
      <Box sx={styles.sectionContainer}>
        <BodySection />
        <SidebarSection />
      </Box>
      <ThumbContext.Provider
        value={{
          mpegInstance,
          thumb,
          setThumb,
          loading,
          setLoading,
          ready,
          setReady,
          setMpegInstance,
        }}
      >
        <ActionSection />
      </ThumbContext.Provider>
      <MyStreamSection />
      <InsightSection />
      <Box id="discussions" sx={styles.insighHeader}>
        <Typography sx={styles.label}>
          <FormattedMessage id={messages.discussions.id} />
        </Typography>
      </Box>
      <CommentSection ownerId={ownerId} />
    </Box>
  );
};

export default ViewAdminProfile;
