import { FC } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import styles from "./styles";
import thumbNail from "assets/images/placeholders/thumbnail_pl.webp";
import Button from "components/custom_button";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "navigation/routes";
import { useIntl } from "react-intl";
import messages from "../messages";
import { Identification } from "types/identification";

type Props = {
  thumb: string;
  profileId: string | number;
  rating: Identification;
};

const MetaSection: FC<Props> = ({ thumb, profileId, rating }) => {
  const navigate = useNavigate();

  const handleViewProfile = () => {
    navigate(`${NavigationRoutes.public_speaker_profile}/${profileId}`);
  };

  const intl = useIntl();

  return (
    <Box sx={styles.headerContainer}>
      <Paper
        component="img"
        alt="Podcast Thumbnail"
        sx={styles.thumbImage}
        src={thumb ?? thumbNail}
      />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Button
          label={intl.formatMessage(messages.viewprofile)}
          onClick={handleViewProfile}
          extraStyles={styles.viewProfile}
          variant="outlined"
        />
        <Stack direction="row" columnGap={1} alignItems="center">
          <Typography sx={styles.ratingValue}>Rating {rating}</Typography>
          <Rating
            size="small"
            sx={styles.rating}
            readOnly
            value={parseInt(rating.toString())}
          />
        </Stack>
      </Stack>
    </Box>
  );
};

export default MetaSection;
