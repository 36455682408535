import { SxProps } from "@mui/material";
import color_pallette from "theme/color_pallette";

const styles: Record<string, SxProps> = {
  container: {
    boxShadow: 10,
    borderRadius: 10,
    padding: 1.5,
    paddingBlock: 4.5,
    paddingTop: 7,
    overflow: "hidden",
    paddingInlineStart: 4,
    width: { xs: "100%", sm: 500, lg: 610 },
    height: { lg: 450 },
    zIndex: 10,
    backgroundColor: color_pallette.backgroundGray,
  },
  ratingLabel: {
    fontSize: {
      xs: 11,
      md: 13,
    },
  },
  avatar: {
    position: "absolute",
    top: 45,
    left: 75,
    width: 70,
    height: 70,
  },
  rate: {
    fontSize: {
      xs: 13,
    },
  },
  cardHeader: {
    backgroundColor: "green",
    width: "100%",
    justifyContent: "flex-start",
    height: "fit-content",
    paddingInlineStart: 2,
    zIndex: 5,
    paddingBlock: 1,
  },
  cardHeaderLabel: { fontSize: { lg: 25 }, color: "white" },
  header: { fontSize: { lg: 25 } },
  img: {
    marginBlock: 0.5,
    width: { xs: "100%", lg: "100%" },
    height: 230,
    objectFit: "cover",
  },
  childrenWrapper: { textAlign: "start", paddingInlineStart: 3 },
};

export default styles;
