import { defineMessages } from 'react-intl';

const scope = 'WhatsComingSection';

export default defineMessages({
  upcoming_interactive: {
    id: `${scope}.upcoming_interactive`,
    defaultMessage: "Upcoming Interactive",
  },
  whatscoming: {
    id: `${scope}.whatscoming`,
    defaultMessage: "See Whats Coming",
  },
});