import { ProfileSliceActions } from "store/profile_slice";
import { UploadedFileStatus } from "types/uploaded_file_status";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useState } from "react";
import { VideoServiceSliceActions } from "store/video_service_slice";
import { uploadVideoFormData } from "store/api/video_service/action_creators";
import useSnack from "./useSnack";
import { Identification } from "types/identification";

const useVideoUpload = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);

  const [uploadedVideoId, setUploadedVideoId] = useState<Identification>(0);

  const { file, meta } = useAppSelector(
    (state) => state.adminProfil.onlineTips
  );

  const { triggerSnack } = useSnack();

  const token = useAppSelector(
    (state) => state.global.credentials.token.access_token
  );

  const dispatch = useAppDispatch();

  const handleChangeStatus = async (status: UploadedFileStatus) => {
    setIsLoading(true);

    dispatch(ProfileSliceActions.changeUploadedVideoStatus(status));

    const { data, error } = await uploadVideoFormData({
      media: file.file,
      category: meta.category,
      description: meta.description,
      privacy: meta.exclusivity,
      title: meta.title,
      status,
      token,
    });
    if (!error && data) {
      setShowError(false);
      triggerSnack("Upload Done !", "success");
      setUploadedVideoId(data?.id);
      dispatch(VideoServiceSliceActions.insertVideo(data));
      setShowPreview(true);
      setIsLoading(false);
    } else if (error) {
      setShowError(true);
      triggerSnack("Something went wrong !", "error");
    } else triggerSnack("Upload in progress !", "info");
  };

  const handleBackToEdit = () => {
    dispatch(ProfileSliceActions.showEditUploadModal());
  };

  return {
    handleChangeStatus,
    handleBackToEdit,
    showPreview,
    showError,
    isLoading,
    uploadedVideoId,
  };
};

export default useVideoUpload;
