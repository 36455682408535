import AudioContext from "context/audio_context";
import { useContext } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { LivetipsPodcastSliceActions } from "store/livetips_podcast_slice";
import { playBackRate } from "types/play_rate";

const useAudio = () => {
  const dispatch = useAppDispatch();

  const { audioFile } = useContext(AudioContext);

  const { currentRecord } = useAppSelector((state) => state.livetipsPodcast);

  const handleAudioBack = () => {
    if (audioFile && audioFile.currentTime > 15) {
      audioFile.currentTime = audioFile.currentTime - 15;
    }
  };

  const handleAudioForward = () => {
    if (audioFile && audioFile.currentTime < audioFile.duration - 15) {
      audioFile.currentTime = audioFile.currentTime + 15;
    }
  };

  const handleAudioVolume = (value: number) => {
    if (audioFile) {
      audioFile.volume = value;
    }
  };

  const handleAudioPlaySpeed = () => {
    const currentSpeedIndex = playBackRate.findIndex(
      (entry) => entry === currentRecord.playbackSpeed
    );

    const newSpeed =
      playBackRate[
        currentSpeedIndex === playBackRate.length - 1
          ? 0
          : currentSpeedIndex + 1
      ];

    dispatch(LivetipsPodcastSliceActions.updatePlaybackRate(newSpeed));

    if (audioFile) {
      audioFile.playbackRate = newSpeed;
    }
  };

  const getAudioDuration = (): number => {
    if (audioFile) return audioFile.duration;
    return 0;
  };

  const handleToggleAudio = () => {
    dispatch(LivetipsPodcastSliceActions.toggleCurrentPodcast());
  };

  return {
    handleAudioBack,
    handleAudioForward,
    handleAudioPlaySpeed,
    handleAudioVolume,
    getAudioDuration,
    handleToggleAudio,
    currentRecord,
  };
};

export default useAudio;
