import { SxProps } from "@mui/material";
import color_pallette from "theme/color_pallette";

const styles: Record<string, SxProps> = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: {
      xs: "center",
    },
    width: { xs: "100%", lg: "65%" },
  },
  meta: {
    backgroundColor: color_pallette.backgroundGray,
    borderRadius: 3,
    paddingBlock: 1.5,
    border: "1px solid gray",
    width: {
      xs: "80%",
      sm: "60%",
      md: "100%",
    },
    marginBlock: 2,
  },
  videoName: {
    opacity: 0.7,
  },
  link: {
    color: color_pallette.primary,
  },
  thumbnail: {
    height: { xs: 170, sm: 200 },
    width: { xs: "80%", sm: "60%", md: "100%" },
    borderRadius: 3,
    overflow: "hidden",
    marginBottom: 2,
  },
};

export default styles;
