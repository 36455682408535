import { SxProps } from "@mui/material";
import color_pallette from "theme/color_pallette";

const styles: Record<string, SxProps> = {
  container: {
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    justifyContent: "flex-start",
    alignItems: { xs: "center", md: "flex-start" },
    overflow: "hidden",
    textAlign: { xs: "center", md: "start" },
    cursor: "pointer",
    maxWidth: {
      xs: "100%",
      md: 470,
      lg: 490,
    },
  },
  iconSubTitle: {
    fontSize: {
      xs: 9,
      lg: 11,
    },
  },
  iconContainerStyle: {
    paddingBlock: {
      xs: 5,
      lg: 5.6,
    },
    paddingInline: {
      xs: 4,
      lg: 4.6,
    },
  },
  speakerAvatar: {
    marginInlineStart: 1,
    width: {
      lg: 35,
    },
    height: {
      lg: 35,
    },
  },
  footerBox: {
    marginTop: {
      xs: 1,
      lg: 1,
    },
    display: "flex",
    flexDirection: "row",
    justifyContent: { xs: "center", lg: "flex-start" },
  },
  enrollButton: {
    fontSize: 12,
  },
  icon: { width: 20, marginInlineEnd: 0.5, height: 20 },
  contentTitle: {
    fontSize: {
      xs: 15,
      lg: 22,
    },
    fontFamily: "Nizzar ,K2D, Inter",
  },
  contentSubTitle: {
    fontSize: {
      xs: 14,
    },
    lineHeight: { md: 1.3 },
  },
  ratingContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: { xs: "center", md: "flex-start" },
    alignItems: "center",
    color: color_pallette.primary,
  },
  iconsContainer: {
    marginTop: { xs: 1, md: 0.5, lg: 0 },
    display: "flex",
    flexDirection: "row",
    justifyContent: { xs: "center", md: "flex-start" },
    columnGap: 1.5,
    alignItems: "center",
  },
  contentContainer: {
    marginInlineStart: 1.5,
    marginTop: { xs: 1.5, lg: 0 },
    maxWidth: { xs: "80%", md: "100%" },
    display: "flex",
    flexDirection: "column",
    justifyContent: {
      xs: "space-around",
      lg: "flex-start",
    },
    rowGap: {
      lg: 1,
    },
  },
  avatarLabel: {
    color: "white",
    fontSize: { xs: 14, lg: 12 },
    marginInlineStart: 1,
  },
  captionText: {
    color: "white",
    padding: 1,
    fontSize: {
      xs: 14,
      lg: 15,
    },
    lineHeight: 1.3,
  },
  thumbContainer: {
    overflow: "hidden",
    borderRadius: 3,
    backgroundColor: color_pallette.transparent,
    width: {
      xs: 220,
      lg: 217,
    },
    height: {
      xs: 260 / 2.3,
      lg: 350 / 2.3,
      xl: 280 / 2.3,
    },
    zIndex: 10,
    position: "absolute",
    left: 0,
    bottom: 0,
    borderTopRightRadius: 3,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  avatarContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    position: "absolute",
    zIndex: 10,
    left: 0,
    top: 0,
    paddingTop: 0.8,
    width: {
      xs: 220,
      lg: 240,
    },
  },
  footerContainer: {
    display: "flex",
    justifyContent: { xs: "center", md: "flex-start" },
    alignItems: "center",
    columnGap: 2,
    marginTop: {
      xs: 2,
      md: 1,
    },
  },
};

export default styles;