import { FC } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import styles from "./styles";
import OnlineWrapper from "components/online_wrapper";
import { FormattedMessage } from "react-intl";
import messages from "../messages";
import { UploadedFileStatus } from "types/uploaded_file_status";
import { UploadPrivacy } from "types/upload_privacy";

const RelatedVideosSection: FC = () => {
  return (
    <Box sx={styles.container}>
      <OnlineWrapper
        paginationName={"related_videos"}
        listLabel={
          <Typography sx={styles.headerLabel}>
            <FormattedMessage id={messages.related_videos.id} />
          </Typography>
        }
        videoQuery={{
          status: UploadedFileStatus.ACCEPTED,
          privacy: UploadPrivacy.PUBLIC,
        }}
        limit={[0, 4]}
      />
      <OnlineWrapper
        paginationName={"related_video_1"}
        listLabel={
          <Typography sx={{ ...styles.headerLabel, marginTop: 10 }}>
            <FormattedMessage id={messages.most_viewed.id} />
          </Typography>
        }
        videoQuery={{
          status: UploadedFileStatus.ACCEPTED,
          privacy: UploadPrivacy.PUBLIC,
        }}
        dimensions={{ xl: 3 }}
        limit={[0, 4]}
      />
    </Box>
  );
};

export default RelatedVideosSection;
