import { FC, useEffect, useMemo, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import styles from "./styles";
import ListenButton from "components/listen_button";
import ActionIcon from "components/action_icon";
import { ReactSVG } from "react-svg";
import share from "assets/svgs/share.svg";
import save from "assets/svgs/save.svg";
/* import played from "assets/svgs/played.svg"; */
import { PodcastItemResponse } from "store/api/podcast_api/types";
import thumbNail from "assets/images/placeholders/thumbnail_pl.webp";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { modalSliceActions } from "store/modal_slice";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "navigation/routes";
import cropText from "utils/crop_text";
import normalizeDate from "utils/normalize_date";
import { useAddBookmarkMutation } from "store/api/bookmark_service/endpoints";
import { MEDIA_TYPE } from "types/media_type";
import { bookmarkSliceActions } from "store/bookmarks_slice";
import { Bookmark } from "store/api/bookmark_service/types";
import SnackBar from "components/snack_bar";
import useSnack from "hooks/useSnack";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import color_pallette from "theme/color_pallette";
import { useAddShareMutation } from "store/api/share_service/endpoints";
import { useGetPodcastViewsCountQuery } from "store/api/view_service/endpoints";

const PodcastHistoryItem: FC<PodcastItemResponse & { viewed?: boolean }> = (
  props
) => {
  const [isSaved, setIsSaved] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const {
    credentials: { token },
    speakerProfile,
  } = useAppSelector((state) => state.global);

  const savedPodcasts = useAppSelector((state) => state.bookmarks.podcast);

  const [shareItem] = useAddShareMutation();

  const handleShare = async () => {
    try {
      await shareItem({
        token: token.access_token,
        body: {
          digitalMediumType: MEDIA_TYPE.ON_DEMAND_PODCAST,
          ownerId: speakerProfile.customerId,
          userId: props.user.customerId,
          videoId: props.id,
        },
      });
      dispatch(modalSliceActions.shareVideoStep(true));
      dispatch(modalSliceActions.setSharedContent(props.media));
      triggerSnack("Item shared", "info");
    } catch (error) {
      console.log(error);
    }
  };

  const { data: listeners } = useGetPodcastViewsCountQuery(
    {
      id: props.id,
    },
    {
      refetchOnFocus: true,
      pollingInterval: 3000,
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    return () => {
      dispatch(modalSliceActions.shareVideoStep(false));
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const currentPodcastIndex = savedPodcasts.findIndex(
      (entry) => entry.videoId == props.id
    );

    currentPodcastIndex == -1 ? setIsSaved(false) : setIsSaved(true);
  }, [savedPodcasts]);

  const getDateDifference = useMemo(() => {
    return normalizeDate(props.created_date);
  }, [props.created_date]);

  const handleNavigate = () => {
    navigate(`${NavigationRoutes.podcasts}/${props.id}`);
    window.scroll({ top: 0, behavior: "smooth" });
  };

  const [bookmark] = useAddBookmarkMutation();

  const { triggerSnack } = useSnack();

  const handleSaveHistory = async () => {
    const bookmarkItem: Bookmark = {
      digitalMediumType: MEDIA_TYPE.ON_DEMAND_PODCAST,
      userId: speakerProfile.customerId,
      videoId: props.id,
    };
    setIsSaved(true);
    try {
      dispatch(bookmarkSliceActions.pushBookmark(bookmarkItem));
      await bookmark({
        token: token.access_token,
        body: bookmarkItem,
      });
      triggerSnack("Saved Successfully", "success");
    } catch (error) {
      setIsSaved(false);
      console.log(error);
    }
  };

  return (
    <Paper sx={styles.container}>
      <Stack columnGap={1.5} direction="row">
        <Box
          component="img"
          alt="PodcastHistoryItem Avatar"
          sx={styles.thumb}
          src={props.thumbnail ?? thumbNail}
        />
        <SnackBar />
        <Box sx={styles.fileContainer}>
          <Typography sx={styles.channel}>{props.title}</Typography>
          <Typography sx={styles.length}>{props.user.org}</Typography>
          <Typography sx={styles.caption}>
            {cropText(props.description, 60)}
          </Typography>
        </Box>
        <Box>
          <ListenButton handleClick={handleNavigate} playing={false} />
        </Box>
      </Stack>
      <Stack marginTop={2} justifyContent="space-between" direction="row">
        <Typography sx={styles.publishedDate}>
          Publised :{" "}
          <span style={{ fontWeight: "500" }}>{getDateDifference}</span>
        </Typography>
        <Typography sx={{ fontSize: { lg: 14 } }}>{`${
          listeners ? listeners : 0
        } Listeners`}</Typography>
        <Stack direction="row" columnGap={2} alignItems="center">
          {/* <ActionIcon label="played" Icon={<ReactSVG src={played} />} /> */}
          <ActionIcon
            onClick={handleShare}
            label="Share"
            Icon={<ReactSVG src={share} />}
          />
          <ActionIcon
            onClick={() => handleSaveHistory()}
            label={isSaved ? "Saved" : "Save in list"}
            Icon={
              isSaved ? (
                <BookmarkIcon sx={{ color: color_pallette.primary }} />
              ) : (
                <ReactSVG src={save} />
              )
            }
          />
        </Stack>
      </Stack>
    </Paper>
  );
};

export default PodcastHistoryItem;
