import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    width: {
      xs: "95%",
      lg: "100%",
      xl: "90%",
    },
    margin: "50px auto",
    display: "flex",
    flexDirection: {
      xs: "column",
      lg: "row",
    },
    columnGap: {
      xs: 3,
      lg: 2,
      xl: 2,
    },
    rowGap: {
      xs: 3,
    },
    justifyContent: "center",
    alignItems: "stretch",
  },

  containerStyle: {
    width: {
      xs: "100%",
    },
  },
  blogsContainer: {
    marginTop: 2,
    rowGap: {
      xs: 3,
      md: 0,
    },
    columnGap: {
      xs: 0,
      md: 1.5,
      xl: 1,
    },
  },
  gridContainer: {
    width: {
      lg: "60%",
      xl: "45%",
    },
  },
  paperContainer: {
    paddingY: { xs: 1, lg: 0.5 },
    overflow: "hidden",
    borderRadius: 8,
    boxShadow: 4,
  },
  blogContainer: {
    marginTop: 3,
    paddingTop: 3,
    overflow: "hidden",
    borderRadius: 8,
    boxShadow: 2,
    rowGap: 3,
  },
  featuredLessonsContainer: {
    marginTop: 3,
  },
};

export default styles;
