import { FC, ReactNode, useMemo } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import styles from "./styles";
import BasicTabs from "common/tab_container";
import { NavigationRoutes } from "navigation/routes";
import PodcastWrapper from "components/podcast_wrapper";
import OnlineWrapper from "components/online_wrapper";
/* import InteractiveWrapper from "./interactive_wrapper"; */
import { LIST_TYPE } from "types/list_types";
import { useAppSelector } from "store/hooks";
import { Identification } from "types/identification";
import { UploadedFileStatus } from "types/uploaded_file_status";
import { FormattedMessage } from "react-intl";
import messages from "./messages";

export type TabContent = {
  id: number;
  index: number;
  label: string;
  Content: ReactNode;
  listType: LIST_TYPE;
  path: NavigationRoutes;
};

const getTabs = (userId: Identification): Array<TabContent> => {
  return [
    {
      id: 1,
      index: 0,
      label: "My Online",
      listType: LIST_TYPE.ONLINE,
      path: NavigationRoutes.myVideos,
      Content: (
        <OnlineWrapper
          paginationName="my_videos_3"
          videoQuery={{ user: userId, status: UploadedFileStatus.ACCEPTED }}
          listLabel=""
          emptyLabel="No Recorded Videos"
        />
      ),
    },
    {
      id: 2,
      index: 1,
      path: NavigationRoutes.myPodcasts,
      label: "My Podcasts",
      listType: LIST_TYPE.PODCAST,
      Content: (
        <PodcastWrapper
          videoQuery={{ user: userId, status: UploadedFileStatus.ACCEPTED }}
          dimensions={{ lg: 4.8 }}
          sectionLabel=""
          icon="podcast"
          emptyLabel="No Podcasts"
        />
      ),
    },
    /* {
      id: 3,
      index: 2,
      label: "My Streams",
      listType: LIST_TYPE.INTERACTIVE,
      path: NavigationRoutes.myStreams,
      Content: <InteractiveWrapper />,
    }, */
  ];
};

const MyStreamSection: FC = () => {
  const user = useAppSelector((state) => state.global.speakerProfile);

  const tabs = useMemo(() => {
    return getTabs(user.customerId);
  }, [user]);

  return (
    <Box id="streams" sx={styles.container}>
      <Box sx={styles.insighHeader}>
        <Typography sx={styles.label}>
          <FormattedMessage id={messages.streams.id} />
        </Typography>
      </Box>
      <BasicTabs content={tabs} />
    </Box>
  );
};

export default MyStreamSection;
