import { FC, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import NotificationListItem from "components/notification_list_item";
import styles from "./styles";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useGetAllNotificationsQuery } from "store/api/notification_service/endpoints";
import EmptyList from "components/empty_list";
import { FormattedMessage, useIntl } from "react-intl";
import messages from "./messages";
import { badgeSliceActions } from "store/badge_slice";

const MyNotifications: FC = () => {
  const token = useAppSelector(
    (state) => state.global.credentials.token.access_token
  );

  const dispatch = useAppDispatch();

  const { data: notificationList, isError } = useGetAllNotificationsQuery(
    {
      token,
    },
    {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
      pollingInterval: 600000,
    }
  );

  useEffect(() => {
    dispatch(badgeSliceActions.setNotifications(0));
  }, [notificationList]);

  const intl = useIntl();

  return (
    <Box sx={styles.container}>
      <Stack direction="row" justifyContent="center">
        <Typography sx={styles.header}>
          <FormattedMessage id={messages.notifications.id} />
        </Typography>
      </Stack>
      {!isError && notificationList && notificationList.length > 0 ? (
        notificationList.map((entry) => (
          <NotificationListItem key={entry.notificationId} {...entry} />
        ))
      ) : (
        <Stack direction="row" width="100%" justifyContent="center">
          <EmptyList
            label={intl.formatMessage(messages.empty)}
            icon="interactive"
          />
        </Stack>
      )}
    </Box>
  );
};

export default MyNotifications;
