import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  sectionHeader: {
    textAlign: "center",
    fontWeight: "600",
    fontSize: {
      xs: 25,
      lg: 40,
    },
    fontFamily: "Nizzar ,K2D, Inter",
  },
  blogTitle: {
    fontFamily: "Nizzar ,K2D, Inter",
    fontSize: {
      xs: 30,
      md: 35,
      lg: 40,
    },
    background:
      "linear-gradient(94.9deg, #000000 20.84%, #008755 30.4%, #E1D2B5 60.64%);",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontWeight: "700",
    textAlign: {
      xs: "center",
      md: "center",
    },
    paddingInlineStart: {
      md: 5,
    },
    paddingBottom: {
      xs: 2,
      lg: 0,
    },
  },
  container: {
    width: {
      xs: "95%",
      lg: "90%",
    },
    marginX: "auto",
    marginTop: { xs: "50px", lg: 2 },
    display: "flex",
    flexDirection: {
      xs: "column",
      lg: "row",
    },
    columnGap: {
      xs: 3,
      lg: 2,
      xl: 2,
    },
    rowGap: {
      xs: 3,
    },
    justifyContent: "center",
    alignItems: "stretch",
  },
  blogsContainer: {
    marginTop: 2,
    rowGap: {
      xs: 3,
      md: 0,
    },
    columnGap: {
      xs: 0,
      md: 1.5,
      xl: 1,
    },
  },
  gridContainer: {
    width: {
      lg: "80%",
      xl: "80%",
    },
    border: "2px solid red",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginX: "auto",
    marginBlock: 5,
  },
  paperContainer: {
    paddingY: 2,
    overflow: "hidden",
    borderRadius: 8,
    boxShadow: 2,
  },
  blogContainer: {
    marginTop: 3,
    paddingTop: 3,
    overflow: "hidden",
    borderRadius: 8,
    boxShadow: 2,
    rowGap: 3,
  },
  featuredLessonsContainer: {
    marginTop: 3,
  },
};

export default styles;
