import { FC } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Grow from "@mui/material/Grow";
import Typography from "@mui/material/Typography";
import styles from "./styles";
import { useAppDispatch, useAppSelector } from "store/hooks";
import SocialChannel from "components/social_channel";
import { SocialMediaPlatforms } from "./data";
import Button from "components/custom_button";
import cropText from "utils/crop_text";
import Close from "@mui/icons-material/Close";
import { modalSliceActions } from "store/modal_slice";

const ShareModal: FC = () => {
  const dispatch = useAppDispatch();

  const open = useAppSelector((state) => state.modals.share);

  const shared_content = useAppSelector((state) => state.modals);

  const handleCloseShare = () => {
    dispatch(modalSliceActions.shareVideoStep(false));
  };

  return (
    <Modal open={open} sx={styles.container}>
      <Grow in={open} timeout={600}>
        <Paper sx={styles.paper}>
          <Stack
            sx={styles.headerWrapper}
            justifyContent="space-between"
            direction="row"
          >
            <Typography sx={styles.header}>
              Share in Social Channels or Copy Link
            </Typography>
            <Close onClick={handleCloseShare} sx={styles.close} />
          </Stack>
          <Grid rowGap={4} container>
            {SocialMediaPlatforms.map((entry) => (
              <Grid xs={3} lg={2.3} item>
                <SocialChannel key={entry.label} {...entry} />
              </Grid>
            ))}
          </Grid>
          <Stack
            marginX="auto"
            width="95%"
            direction={{ xs: "column", lg: "row" }}
            alignItems="center"
            marginTop={3}
            columnGap={1.5}
            rowGap={{ xs: 2, md: 0 }}
          >
            <Box flexGrow={0.4} sx={styles.inputLike}>
              <Typography sx={styles.link}>
                {cropText(shared_content.shared_content?.url ?? "", 50)}
              </Typography>
            </Box>
            <Button extraStyles={styles.copyLink} label="Copy" />
          </Stack>
        </Paper>
      </Grow>
    </Modal>
  );
};

export default ShareModal;
