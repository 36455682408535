import { FC } from "react";
import Box from "@mui/material/Box";
import styles from "./styles";
import { Grid, Typography } from "@mui/material";
import UpcomingPodcasters from "components/upcoming_podcaster";

const MyJoiningList: FC = () => {
  return (
    <Box sx={styles.container}>
      <Typography sx={{ fontSize: { lg: 30 }, fontWeight: "500" }}>
        My Joining List
      </Typography>
      <Grid
        container
        sx={{ marginBlock: 5, justifyContent: "flex-start" }}
        columnGap={2}
        rowGap={2}
      >
        <Grid item lg={5.5}>
          <UpcomingPodcasters />
        </Grid>
      </Grid>
    </Box>
  );
};

export default MyJoiningList;
