import { FC, useContext } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import styles from "./styles";
import { useAppSelector } from "store/hooks";
import cropText from "utils/crop_text";
import ThumbContext from "context/ThumbnailContext";

const MetaSection: FC = () => {
  const { file } = useAppSelector((state) => state.adminProfil.onlineTips);

  const { thumb, loading } = useContext(ThumbContext);

  return (
    <Box sx={styles.container}>
      {!loading ? (
        <Box
          component="img"
          src={thumb}
          sx={{ aspectRatio: "3 / 2", borderRadius: "15px", width: 320 }}
          width={350}
        />
      ) : (
        <Skeleton width={350} height={200} />
      )}
      <Box sx={styles.meta}>
        <>
          <Typography>Video Link</Typography>
          <Typography sx={styles.link}>
            {cropText(`${file?.path ?? ""}`, 35)}
          </Typography>
        </>
        <Box mt={2}>
          <Typography>Filename</Typography>
          <Typography sx={styles.videoName}>
            {cropText(file.file?.name ?? "", 30)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default MetaSection;
