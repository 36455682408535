import { FC } from "react";
import styles from "./styles";
import { Stack } from "@mui/material";
import AboutUsServiceItem from "components/about_us_service_item";
import messages from "./messages";
import about_online from "assets/images/about_online.png";
import about_podcast from "assets/images/about_podcast.png";
import about_interactive from "assets/images/about_interactive.png";
import about_nomination from "assets/images/about_nomination.png";
import about_employee from "assets/images/about_employee.png";

const ServiceSection: FC = () => {
  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      columnGap={1}
      sx={styles.container}
    >
      <AboutUsServiceItem
        ic={about_online}
        title={messages.online_t.id}
        desc={messages.online_d.id}
      />
      <AboutUsServiceItem
        ic={about_podcast}
        title={messages.podcast_t.id}
        desc={messages.podcast_d.id}
      />
      <AboutUsServiceItem
        ic={about_interactive}
        title={messages.interactive_t.id}
        desc={messages.interactive_d.id}
      />
      <AboutUsServiceItem
        ic={about_employee}
        title={messages.employee_t.id}
        desc={messages.employee_d.id}
      />
      <AboutUsServiceItem
        ic={about_nomination}
        title={messages.nomination_t.id}
        desc={messages.nomination_d.id}
      />
    </Stack>
  );
};

export default ServiceSection;
