import arabic from "./arabic.json";
import english from "./english.json";
import { Local } from "types/local";

const LanguageMapper = {
  [Local.Arabic]: arabic,
  [Local.English]: english,
};

export default LanguageMapper;
