import { FC } from "react";
import Box from "@mui/material/Box";
import styles from "./styles";
import SidebarSection from "./sidebar_section";
import SpeakerList from "./speaker_list";
import EmptyList from "components/empty_list";
import { useAppSelector } from "store/hooks";
import { useGetMembershipListQuery } from "store/api/membership_service/endpoints";
import { UploadedFileStatus } from "types/uploaded_file_status";
import { useIntl } from "react-intl";
import messages from "./messages";

const ExploreSpeakers: FC = () => {
  const { categoryFilter, dateFilter, rateFilter, speakerFilter } =
    useAppSelector((state) => state.sidebar);

  const { data, isLoading } = useGetMembershipListQuery(
    {
      user: speakerFilter,
      category: categoryFilter,
      rate: rateFilter,
      date: dateFilter,
      status: UploadedFileStatus.ACCEPTED,
    },
    { refetchOnFocus: true, refetchOnMountOrArgChange: true }
  );

  const intl = useIntl();

  return (
    <>
      <Box sx={styles.container}>
        <Box marginInlineStart={{ lg: 5 }}>
          <SidebarSection />
        </Box>
        {data?.length === 0 || !data ? (
          <EmptyList
            label={intl.formatMessage(messages.no_result)}
            extraStyle={{ width: "70%", marginTop: 10 }}
            icon="speakers"
          />
        ) : (
          <SpeakerList isLoading={isLoading} data={data} />
        )}
      </Box>
    </>
  );
};

export default ExploreSpeakers;
