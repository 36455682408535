import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  footerContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: {
      xs: "center",
      sm: "center",
      md: "flex-start",
    },
    alignItems: "center",
    columnGap: {
      xs: 5,
      lg: 1.5,
    },
  },
};

export default styles;
