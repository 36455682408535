import { FC, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import HeaderSection from "./header_section";
import styles from "./styles";
import RelatedVideos from "./related_videos";
import Appbar from "components/appbar";
import FooterSection from "pages/home/footer_section";
import SnackBar from "components/snack_bar";
import { useAppDispatch, useAppSelector } from "store/hooks";
import Chat from "components/chat";
import AgoraRTC, { AgoraRTCProvider } from "agora-rtc-react";
import {
  useGetVideoLiveStreamByIdQuery,
  usePostChannelTokenActionMutation /* useUpdateVideoLiveStreamMutation */,
} from "store/api/streaming_service/endpoints";
import { CHANNEL_TOKEN_DURATION, UPCOMING_DURATION } from "types/upcoming_duration";
import { CHANNEL_ROLE } from "types/upcoming_role";
import { Upcoming } from "store/api/streaming_service/types";
import useSnack from "hooks/useSnack";
import { useNavigate, useParams } from "react-router-dom";
import { channelSliceActions } from "store/channel_slice";
import AgoraManager from "components/agora_manager";
import { chatSliceActions } from "store/chat_slice";
import StreamUtilItem from "components/stream_grid/stream_util_item";
import invite from "assets/images/invite.png";
import screen_share from "assets/images/screen_share.png";
import record from "assets/images/record.png";
import { Stack } from "@mui/material";

const APP_ID = "505c25f20ade46e5a8e22ad4201e69f5";

const StreamingOnlinePage: FC = () => {
  const dispatch = useAppDispatch();
  const navigation = useNavigate();
  const { id: upcomingId } = useParams();

  const { triggerSnack } = useSnack();

  // const [_, setVisible] = useState<boolean>(false);
  const [rtcToken, setRtcToken] = useState<string>("");

  const { access_token } = useAppSelector(
    (state) => state.global.credentials.token
  );

  const [postChannelToken] = usePostChannelTokenActionMutation();
  /* const [updateVideoLiveStream] = useUpdateVideoLiveStreamMutation(); */

  const { upcoming } = useAppSelector((state) => state.channel);

  const { customerId } = useAppSelector((state) => state.global.speakerProfile);

  const agoraClient = AgoraRTC.createClient({
    mode: "rtc",
    codec: "vp8",
  });

  // const [_, setRtcProps] = useState<RtcPropsInterface>({
  //   appId: APP_ID,
  //   channel: upcoming?.channel?.name || '',
  //   uid: customerId as number,
  //   token: rtcToken,
  //   layout: layout.pin,
  //   // customRtcClient: agoraClient,
  //   role: customerId == upcoming?.user?.customerId ? 'host' : 'audience'
  // });

  // const callbacks = {
  //   EndCall: () => setVisible(false),
  // };

  const {
    data: currentUpcoming,
    error: upcomingError,
    isSuccess,
    isError,
  } = useGetVideoLiveStreamByIdQuery(
    {
      id: upcomingId as string,
      token: access_token,
    },
    { refetchOnMountOrArgChange: true, refetchOnFocus: true }
  );

  useEffect(() => {
    if (isError) {
      console.log("useGetVideoLiveStreamByIdQuery Error ---->", upcomingError);
      navigation("/not-found");
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(channelSliceActions.setUpcoming(currentUpcoming));
    }
  }, [isSuccess]);

  const generateChannelToken = async (upcoming: Upcoming | null) => {
    if (upcoming == null) return;

    let resultToken: { data: { rtcToken: string } };

    try {
      resultToken = (await postChannelToken({
        token: access_token,
        body: {
          duration:
            upcoming.duration == UPCOMING_DURATION.TWO_HOURS
              ? CHANNEL_TOKEN_DURATION.TWO_HOURS
              : CHANNEL_TOKEN_DURATION.ONE_HOUR,
          channelName: upcoming.channel.name,
          startTime: upcoming.channel.startTime,
          role:
            customerId == upcoming.user.customerId
              ? CHANNEL_ROLE.admin
              : CHANNEL_ROLE.attendee,
        },
      })) as { data: { rtcToken: string } };

      if (resultToken.data) {
        setRtcToken(resultToken.data.rtcToken);
        triggerSnack("Token Generated Successfully ", "success");
        return;
      } /*  else {
        finishVideoLiveStream();
        navigation("/not-found");
      } */
    } catch (error) {
      triggerSnack("Error Generating Credentials", "error");
    }
  };

  useEffect(() => {
    window.scrollTo({ behavior: "smooth", top: 0 });
    window.scrollX = 0;
    window.scrollY = 0;
  }, []);

  /* const finishVideoLiveStream = async () => {
    try {
      await updateVideoLiveStream({
        id: `${upcomingId || upcoming?.id}`,
        token: access_token,
      });
      console.log("finishVideoLiveStream updated successfully");
    } catch (error) {
      console.log("finishVideoLiveStream error:", error);
    }
  }; */
  useEffect(() => {
    if (upcoming != null) {
      generateChannelToken(upcoming);
      dispatch(chatSliceActions.setConversation(upcoming?.conversation?.id));
    }

    /* return () => {
      if (customerId == `${upcoming?.user?.customerId}`) {
        finishVideoLiveStream();
      }
      window.location.reload();
      agoraClient.leave();
    }; */
  }, []);

  useEffect(() => {}, [rtcToken]);

  if (
    !upcoming ||
    upcoming === null ||
    (upcoming?.status != 0 && upcoming?.status != 2)
  )
    return (
      <>
        <Appbar />
        <div
          style={{
            display: "flex",
            width: "100vw",
            height: "200vh",
            justifyContent: "center",
          }}
        >
          <h2 style={{ alignSelf: "center" }}>Not Available</h2>
        </div>
        <FooterSection spaceTop={0} />
      </>
    );

  return (
    <>
      <AgoraRTCProvider client={agoraClient}>
        <Appbar />
        <Box sx={styles.root}>
          <HeaderSection upcoming={upcoming} />
          <Paper sx={styles.container}>
            {rtcToken ? (
              <AgoraManager
                type="video"
                config={{
                  appId: APP_ID,
                  channelName: upcoming.channel.name,
                  selectedProduct: "live",
                  upcoming,
                  uid: access_token ? parseInt(customerId as string) : 0,
                  rtcToken: rtcToken,
                }}
              />
            ) : (
              <Box
                component="img"
                sx={{ width: "75%", objectFit: "cover", height: 650 }}
                src={upcoming.thumbnail}
              />
            )}
            {access_token && <Chat convId={upcoming.conversation.id} />}
          </Paper>
          <Stack columnGap={1} direction="row" alignItems="center">
            <StreamUtilItem onClick={() => {}} icon={invite} />
            <StreamUtilItem onClick={() => {}} icon={screen_share} />
            <StreamUtilItem onClick={() => {}} icon={record} />
          </Stack>
          <SnackBar />
          <RelatedVideos />
        </Box>
        <FooterSection spaceTop={0} />
      </AgoraRTCProvider>
    </>
  );
};

export default StreamingOnlinePage;