import { SCHEDULE_TYPE } from "context/schedule_context";
import * as yup from "yup";

export enum PODCAST_PREMIER_FORM {
  DAY = "day",
  TIME = "time",
  DURATION = "duration",
  TITLE = "title",
  TYPE = "type",
}

const validationSchema: yup.Schema = yup.object({
  [PODCAST_PREMIER_FORM.DAY]: yup.string(),
  [PODCAST_PREMIER_FORM.TIME]: yup.string(),
  [PODCAST_PREMIER_FORM.DURATION]: yup.string(),
  [PODCAST_PREMIER_FORM.TITLE]: yup.string(),
  [PODCAST_PREMIER_FORM.TYPE]: yup.string().required(),
});

const initialValues: yup.InferType<typeof validationSchema> = {
  [PODCAST_PREMIER_FORM.DAY]: "",
  [PODCAST_PREMIER_FORM.TIME]: "",
  [PODCAST_PREMIER_FORM.DURATION]: "",
  [PODCAST_PREMIER_FORM.TITLE]: "",
  [PODCAST_PREMIER_FORM.TYPE]: SCHEDULE_TYPE.INSTANT,
};

export { validationSchema, initialValues };
