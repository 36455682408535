import { FC } from "react";
import Stack from "@mui/material/Stack";
import styles from "./styles";
import DateFilterSection from "pages/view_admin_profile/insight_section/date_filter_section";
import { Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import messages from "pages/explore_speakers/sidebar_section/messages";

const DateFilter: FC = () => {
  return (
    <Stack sx={styles.container}>
      <Typography sx={{ marginBlock: 0.5 }}>
        <FormattedMessage id={messages.select_date.id} />
      </Typography>
      <DateFilterSection />
    </Stack>
  );
};

export default DateFilter;
