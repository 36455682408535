import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    width: "80%",
    marginX: "auto",
  },
  header: {
    fontSize: {
      lg: 25,
    },
    fontWeight: "600",
    marginBottom: 1,
  },
  headerWrapper: {
    marginBlock: 15,
    width: {
      lg: "60%",
    },
  },
};

export default styles;
