import { FC, useCallback, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Grow from "@mui/material/Grow";
import styles from "./styles";
import FormContainer from "common/form_container";
import {
  initialFormState,
  NOMINATION_FORM_PROPERTIES,
  validateSchema,
} from "store/nomination_slice/form";
import useSnack from "hooks/useSnack";
import SnackBar from "components/snack_bar";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { nominationSliceActions } from "store/nomination_slice";
import { NavigationRoutes } from "navigation/routes";
import { useNavigate } from "react-router-dom";
import NotificationSection from "./notification_section";
import FormSection from "./form_section";
import { FormikValues } from "formik";
import { useAddNomineeMutation } from "store/api/nomination_service/endpoints";
import { Nomination } from "store/api/nomination_service/types";

const OutsideLiveTips: FC = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const [postNomination] = useAddNomineeMutation();

  const showForm = useAppSelector((state) => state.nomination.showForm);

  const {
    credentials: { token },
  } = useAppSelector((state) => state.global);

  const { triggerSnack } = useSnack();

  const getNominee = (values: FormikValues): Nomination => {
    const nominee: Nomination = {
      /* my own info */
      firstName: values[NOMINATION_FORM_PROPERTIES.FIRST_NAME],
      lastName: values[NOMINATION_FORM_PROPERTIES.LAST_NAME],
      city: values[NOMINATION_FORM_PROPERTIES.CITY],
      country: values[NOMINATION_FORM_PROPERTIES.COUNTRY],
      email: values[NOMINATION_FORM_PROPERTIES.EMAIL],
      telephone: values[NOMINATION_FORM_PROPERTIES.TELEPHONE],
      /* nominee info */
      nomineeFirstName: values[NOMINATION_FORM_PROPERTIES.NOMINEE_FIRST_NAME],
      nomineeLastName: values[NOMINATION_FORM_PROPERTIES.NOMINEE_LAST_NAME],
      nomineeCity: values[NOMINATION_FORM_PROPERTIES.NOMINEE_CITY],
      nomineeCountry: values[NOMINATION_FORM_PROPERTIES.NOMINEE_COUNTRY],
      nomineeTelephone: values[NOMINATION_FORM_PROPERTIES.NOMINEE_TELEPHONE],
      nomineeEmail: values[NOMINATION_FORM_PROPERTIES.NOMINEE_EMAIL],
      about: values[NOMINATION_FORM_PROPERTIES.ABOUT],
      active: true,
    };
    return nominee;
  };

  const handleSubmitNomination = async (values: FormikValues) => {
    try {
      await postNomination({
        body: getNominee(values),
        token: token.access_token,
      });
      triggerSnack("Pending Status !", "info");
      window.scrollTo({ behavior: "smooth", top: 0 });
      window.scrollX = 0;
      window.scrollY = 0;
      dispatch(nominationSliceActions.showNominationForm(false));
      triggerSnack("Your Suggestion Has Been Submitted !", "success");
    } catch (error) {
      triggerSnack("Error Submitting result", "error");
    }
  };

  useEffect(() => {
    return () => {
      dispatch(nominationSliceActions.showNominationForm(true));
    };
  }, [dispatch]);

  const RenderComponent = () =>
    useCallback(() => {
      window.scrollTo({ behavior: "smooth", top: 0 });
      window.scrollX = 0;
      window.scrollY = 0;
      if (!token.access_token) navigate(NavigationRoutes.login);
      if (!showForm)
        return (
          <Grow in timeout={600}>
            <Stack sx={styles.headerWrapper}>
              <Typography sx={styles.header}>
                Request Submitted , Stay tuned for updates !
              </Typography>
              <Typography>
                Live Tips Team will evaluate your request and data . make sure
                to turn on notification to stay up to date with latest news
              </Typography>
            </Stack>
          </Grow>
        );
      return (
        <>
          <FormContainer
            initialValues={initialFormState}
            validationSchema={validateSchema}
            onSubmit={handleSubmitNomination}
          >
            <FormSection />
            <SnackBar />
            <NotificationSection />
          </FormContainer>
        </>
      );
    }, []);

  return (
    <>
      <Box sx={styles.container}>{RenderComponent()()}</Box>
    </>
  );
};

export default OutsideLiveTips;
