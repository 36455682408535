import { FC } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import styles from "./styles";
import InsightsCard from "common/insights_card";
import DateFilterSection from "./date_filter_section";
import Button from "components/custom_button";
import { FormattedMessage, useIntl } from "react-intl";
import messages from "./messages";

const InsightSection: FC = () => {
  const intl = useIntl();
  return (
    <Box id="insights" sx={styles.container}>
      <Box sx={styles.insighHeader}>
        <Typography sx={styles.label}>
          <FormattedMessage id={messages.insights.id} />
        </Typography>
      </Box>
      <Grid container sx={styles.header}>
        <Grid
          textAlign={{ xs: "center", lg: "start" }}
          item
          xs={12}
          sm={12}
          md={5.6}
          lg={5}
          xl={8}
        >
          <Typography noWrap sx={styles.headerLabel}>
            <FormattedMessage id={messages.profileInsights.id} />
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={5.6} lg={5} xl={4}>
          <DateFilterSection />
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent={{
          md: "center",
          lg: "start",
        }}
        alignContent={"center"}
        sx={styles.insightsCardContainer}
      >
        <InsightsCard value="0" label={intl.formatMessage(messages.students)} />
        <InsightsCard value="0" label={intl.formatMessage(messages.watched)} />
        <InsightsCard value="0" label={intl.formatMessage(messages.rating)} />
        <InsightsCard
          value="0"
          label={intl.formatMessage(messages.active_users)}
        />
      </Grid>
      <Button
        label={intl.formatMessage(messages.see_more)}
        extraStyles={styles.seeMore}
      />
    </Box>
  );
};

export default InsightSection;