import { FormikValues } from "formik";
import * as yup from "yup";

export const enum NOMINATION_FORM_PROPERTIES {
  FIRST_NAME = "firstName",
  LAST_NAME = "lastName",
  EMAIL = "email",
  TELEPHONE = "telephone",
  CITY = "city",
  COUNTRY = "country",
  NOMINEE_CITY = "nomineeCity",
  DESCRIPTION = "description",
  GENDER = "gender",
  NOMINEE_COUNTRY = "nomineeCountry",
  WEB_LINKS = "weblinks",
  NOMINEE_EMAIL = "nomineeEmail",
  WEB_LINKS_FILE = "web_links_file",
  NOMINEE_FIRST_NAME = "nomineeFirstName",
  NOMINEE_LAST_NAME = "nomineeLastName",
  NOMINEE_TELEPHONE = "nomineeTelephone",
  ABOUT = "about",
}

export type NominationForm = FormikValues;

const validateSchema = yup.object({
  [NOMINATION_FORM_PROPERTIES.ABOUT]: yup.string().required(),
  [NOMINATION_FORM_PROPERTIES.COUNTRY]: yup.string(),
  [NOMINATION_FORM_PROPERTIES.CITY]: yup.string(),
  [NOMINATION_FORM_PROPERTIES.FIRST_NAME]: yup.string(),
  [NOMINATION_FORM_PROPERTIES.LAST_NAME]: yup.string(),
  [NOMINATION_FORM_PROPERTIES.EMAIL]: yup.string(),
  [NOMINATION_FORM_PROPERTIES.TELEPHONE]: yup.string(),
  [NOMINATION_FORM_PROPERTIES.NOMINEE_CITY]: yup.string(),
  [NOMINATION_FORM_PROPERTIES.NOMINEE_COUNTRY]: yup.string(),
  [NOMINATION_FORM_PROPERTIES.NOMINEE_EMAIL]: yup.string(),
  [NOMINATION_FORM_PROPERTIES.NOMINEE_FIRST_NAME]: yup.string(),
  [NOMINATION_FORM_PROPERTIES.NOMINEE_LAST_NAME]: yup.string(),
  [NOMINATION_FORM_PROPERTIES.NOMINEE_TELEPHONE]: yup.string(),
});

const initialFormState = {
  [NOMINATION_FORM_PROPERTIES.COUNTRY]: "United Arab Emirates",
  [NOMINATION_FORM_PROPERTIES.CITY]: "Dubai",
  [NOMINATION_FORM_PROPERTIES.ABOUT]: "",
  [NOMINATION_FORM_PROPERTIES.FIRST_NAME]: "",
  [NOMINATION_FORM_PROPERTIES.LAST_NAME]: "",
  [NOMINATION_FORM_PROPERTIES.EMAIL]: "",
  [NOMINATION_FORM_PROPERTIES.TELEPHONE]: "",
  [NOMINATION_FORM_PROPERTIES.NOMINEE_CITY]: "Dubai",
  [NOMINATION_FORM_PROPERTIES.NOMINEE_COUNTRY]: "United Arab Emirates",
  [NOMINATION_FORM_PROPERTIES.NOMINEE_EMAIL]: "",
  [NOMINATION_FORM_PROPERTIES.NOMINEE_FIRST_NAME]: "",
  [NOMINATION_FORM_PROPERTIES.NOMINEE_LAST_NAME]: "",
  [NOMINATION_FORM_PROPERTIES.NOMINEE_TELEPHONE]: "",
};

export default NominationForm;
export { validateSchema, initialFormState };
