import { FC } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import Typography from "@mui/material/Typography";
import home_gif from "assets/gifs/podcast_section_gif.gif";
import header from "assets/images/circles.webp";
import styles from "./styles";
import { FormattedMessage } from "react-intl";
import messages from "./messages";

const HeaderSection: FC = () => {
  const theme = useTheme();
  const lgScreen = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Box sx={styles.container}>
      <Box sx={styles.headerContainer}>
        <Typography
          sx={{
            marginTop: 5,
            fontSize: { lg: 70 },
            fontWeight: "500",
            cursor: "pointer",
            opacity: 0.8,
          }}
        >
          Live Tips
        </Typography>
        <Typography
          sx={{
            fontSize: { lg: 70 },
            fontWeight: "500",
            cursor: "pointer",
            opacity: 0.8,
            marginInlineStart: 15,
          }}
        >
          <FormattedMessage id={messages.podcasts.id} />
        </Typography>
      </Box>
      <Box
        component="img"
        style={{ width: lgScreen ? 550 : 370, aspectRatio: "4 / 3" }}
        src={home_gif}
        id="border-gradient"
        alt="border image"
      />
      <Box
        component="img"
        src={header}
        alt="podcast section header image"
        style={{
          position: "absolute",
          width: lgScreen ? 450 : 250,
          height: lgScreen ? 450 : 250,
          top: lgScreen ? -60 : 0,
          left: 150,
        }}
      />
    </Box>
  );
};

export default HeaderSection;
