import { FC } from "react";
import {
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox as MuiCheckBox,
} from "@mui/material";
import styles from "./styles";
import Button from "components/custom_button";
import { OnlineFormProperties } from "store/livetips_online_slice/form_properties";
import SubmitButton from "common/submit_button";
import ErrorMessage from "common/error_message";
import { FormikValues, useFormikContext } from "formik";

const NotificationSection: FC = () => {
  const { errors, handleChange } = useFormikContext();
  return (
    <Box sx={styles.container}>
      <Box mt={3}>
        <FormGroup>
          <FormControlLabel
            label={
              "By checking this box, I consent and agree to Livetips processing my information as defined above."
            }
            control={
              <MuiCheckBox
                name={OnlineFormProperties.CONSENTED}
                onChange={handleChange}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
          />
          <ErrorMessage>
            {(errors as FormikValues)[OnlineFormProperties.CONSENTED]}
          </ErrorMessage>
        </FormGroup>
        <Box sx={styles.mg}>
          <Button
            variant="outlined"
            extraStyles={styles.mgInline}
            label="Cancel"
          />
          <SubmitButton title="Submit" />
        </Box>
      </Box>
    </Box>
  );
};

export default NotificationSection;
