import { FC } from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import styles from "./styles";
import online_image1 from "assets/images/live_tips.webp";
import cropText from "utils/crop_text";
import { ReactSVG } from "react-svg";
import small_logo from "assets/svgs/small_logo.svg";
import { Upcoming } from "store/api/streaming_service/types";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "navigation/routes";

const UpcomingPodcastListItem: FC<Upcoming> = (upcoming) => {
  const navigate = useNavigate();

  const handleUpcomingDetails = (upcoming: Upcoming) => {
    navigate(`${NavigationRoutes.upcoming_details}/${upcoming.id}`);
  };

  return (
    <Paper
      onClick={() => handleUpcomingDetails(upcoming)}
      sx={styles.container}
    >
      <Box
        component="img"
        src={upcoming.thumbnail ?? online_image1}
        sx={styles.img}
      />
      <ReactSVG
        style={{ position: "absolute", top: 30, left: 25 }}
        src={small_logo}
      />
      <Stack marginTop={1} columnGap={1.3} direction="row" alignItems="center">
        <Avatar
          src={upcoming.user.profilePicture ?? online_image1}
          sx={{ width: 30, height: 30 }}
        />
        <Typography sx={styles.name}>{upcoming.user.name}</Typography>
      </Stack>
      <Stack sx={styles.thumb}>
        <Typography sx={styles.caption}>
          {cropText(upcoming.title, 25)}
        </Typography>
      </Stack>
      <Typography sx={styles.desc}>
        {cropText(upcoming.description, 70)}
      </Typography>
    </Paper>
  );
};

export default UpcomingPodcastListItem;
