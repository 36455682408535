import * as yup from "yup";

export enum EditLiveStreamModal {
  videoTitle = "title",
  description = "description",
  category = "category",
  exclusivity = "exclusivity",
  thumbnail = "thumbnail",
}

const validationSchema: yup.Schema = yup.object({
  [EditLiveStreamModal.videoTitle]: yup.string().required(),
  [EditLiveStreamModal.description]: yup.string().required(),
  [EditLiveStreamModal.category]: yup.string(),
  [EditLiveStreamModal.thumbnail]: yup.string(),
});

const initialValues: yup.InferType<typeof validationSchema> = {
  [EditLiveStreamModal.videoTitle]: "",
  [EditLiveStreamModal.description]: "",
  [EditLiveStreamModal.category]: "1",
  [EditLiveStreamModal.thumbnail]: "1",
};

export { validationSchema, initialValues };
