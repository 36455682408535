import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    marginTop: 5,
  },
  gender: {
    fontWeight: "700",
    font: "Inter, K2D",
  },
  genderContainer: {
    display: "flex",
    flexDirection: {
      xs: "column",
      sm: "row",
    },
  },
  center: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  loginLabel: {
    marginTop: 2,
  },
  buttonContainer: { marginTop: 5, width: { md: "100%" } },
  button: { width: { xs: "fit-content", md: 350, height: 40 } },
  formHeader: {
    width: {
      xs: "100%",
      sm: "80%",
      md: "60%",
    },
    marginX: "auto",
  },
  input: {
    minWidth: "100%",
  },
  spacing: {
    marginTop: 3,
  },
};

export default styles;
