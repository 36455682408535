import { FC } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import styles from "./styles";
import BodySection from "./body_section";
import color_pallette from "theme/color_pallette";
import { FormattedMessage } from "react-intl";
import messages from "./messages";

const ExploreInteractive: FC = () => {
  return (
    <Box sx={styles.container}>
      <Stack
        width={{ lg: "80%" }}
        marginBlock={5}
        direction="row"
        alignItems="center"
      >
        <Typography>
          <FormattedMessage id={messages.Home.id} />{" "}
          <span style={{ marginInline: "10px" }}>{">"}</span>
          <span style={{ color: color_pallette.primary }}>
            <FormattedMessage id={messages.Interactive.id} />
          </span>
        </Typography>
        <Typography sx={styles.header} textAlign="center">
          <FormattedMessage id={messages.expolore_Interactive.id} />
        </Typography>
      </Stack>
      <Box sx={styles.search}>
        <FormattedMessage id={messages.Search.id} />
      </Box>
      <BodySection />
    </Box>
  );
};

export default ExploreInteractive;
