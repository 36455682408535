import ReactDOM from "react-dom/client";
import { ThemeProvider } from "@mui/material";
import theme from "./theme";
import Root from "./root";
import { BrowserRouter } from "react-router-dom";
import { NavigationRoutes } from "navigation/routes";
import "react-dates/initialize";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <BrowserRouter basename={NavigationRoutes.home}>
    <ThemeProvider theme={theme}>
      <Root />
    </ThemeProvider>
  </BrowserRouter>
);
