import { FC } from "react";
import Box from "@mui/material/Box";
import ReactPlayer from "react-player/lazy";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import styles from "./styles";

type Props = {
  markAsViewed: () => void;
  videoUrl?: string;
};

const VideoPlayer: FC<Props> = ({ markAsViewed, videoUrl }) => {
  return (
    <Box sx={styles.videoContainer}>
      <ReactPlayer
        muted={true}
        config={{
          file: { attributes: { controlsList: "nodownload" } },
        }}
        playing={false}
        url={videoUrl}
        width="100%"
        height="100%"
        wrapper={Box}
        light={false}
        loop={false}
        playIcon={
          <Box>
            <PlayCircleIcon />
          </Box>
        }
        stopOnUnmount
        controls
        onPlay={() => markAsViewed()}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
        }}
      />
    </Box>
  );
};

export default VideoPlayer;
