import { defineMessages } from 'react-intl';

const scope = 'MyStreamSection';

export default defineMessages({
  my_online: {
    id: `${scope}.my_online`,
    defaultMessage: "My Online",
  },
  no_recorded: {
    id: `${scope}.no_recorded`,
    defaultMessage: "No Recorded Videos",
  },
  no_interactive: {
    id: `${scope}.no_interactive`,
    defaultMessage: "No Interactive Found",
  },
  streams: {
    id: `${scope}.streams`,
    defaultMessage: "My Streams",
  },
  my_podcasts: {
    id: `${scope}.my_podcasts`,
    defaultMessage: "My Podcasts",
  },
  no_podcasts: {
    id: `${scope}.no_podcasts`,
    defaultMessage: "No Podcasts",
  },
});