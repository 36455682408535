import { FC } from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import RelatedVideoItem from "components/related_video_item";
import { useGetVideoListQuery } from "store/api/video_service/endpoints";
import { UploadedFileStatus } from "types/uploaded_file_status";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "navigation/routes";
import { useAppSelector } from "store/hooks";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import styles from "./styles";
import { LIST_TYPE } from "types/list_types";
import { VideoServiceResponse } from "store/api/types";
import { FormattedMessage } from "react-intl";
import messages from "../messages";
import cropText from "utils/crop_text";

const MyOnline: FC = () => {
  const {
    global: { credentials },
    adminProfil: { currentMember },
  } = useAppSelector((state) => state);

  const navigate = useNavigate();

  const { data } = useGetVideoListQuery(
    {
      token: credentials.token.access_token,
      user: currentMember?.user.customerId,
      status: UploadedFileStatus.ACCEPTED,
    },
    {
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
    }
  ) as { data: { results: [] }; isSuccess: boolean; isLoading: boolean };

  const videosList: Array<VideoServiceResponse> = data?.results ?? [];

  const handleVideoDetails = (id: string | number) => {
    navigate(`${NavigationRoutes.services_online_video}/${id}`);
  };

  const handleDisplayAll = () => {
    navigate(`${NavigationRoutes.myVideos}/${currentMember?.user.customerId}`, {
      state: {
        listType: LIST_TYPE.ONLINE,
        username: currentMember?.user.name,
      },
    });
  };

  return (
    <Stack sx={styles.container}>
      {videosList && videosList.length > 0 && (
        <>
          <Stack
            onClick={handleDisplayAll}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ paddingInlineEnd: 2 }}
          >
            <Typography sx={styles.sectionLabel}>
              <FormattedMessage id={messages.online_content.id} />
            </Typography>
            <ArrowForwardRoundedIcon
              onClick={handleDisplayAll}
              sx={{ opacity: 0.6, cursor: "pointer" }}
            />
          </Stack>
          <Stack rowGap={1} mt={1.5}>
            {videosList.slice(0, 3).map((entry) => (
              <RelatedVideoItem
                onClick={() => handleVideoDetails(entry.id)}
                key={entry.id}
                thumbnail={entry.thumbnail}
                name={entry.user.name}
                likes_count={entry.likes_count}
                shares_count={entry.shares_count}
                views_count={entry.views_count}
                description={cropText(entry.description, 40)}
              />
            ))}
          </Stack>
        </>
      )}
    </Stack>
  );
};

export default MyOnline;
