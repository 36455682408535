import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {},
  iconWrapper: {
    background: "linear-gradient(289.44deg, #008755 -30.12%, #BCE0E1 67.62%);",
    paddingInline: 1,
    paddingBlock: 1.5,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: { cursor: "pointer" },
};

export default styles;
