import { Swiper, SwiperSlide } from "swiper/react";
import { Box, Breakpoint } from "@mui/material";
import { Pagination, Autoplay } from "swiper/modules";
import styles from "./styles";
import CardItem from "./card_item";
import data from "store/hero_slice/data";
import { useWidth } from "hooks/useWidth";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "navigation/routes";
import "swiper/css/pagination";
import "theme/css/home.css";
import "swiper/css";
import { ROLE } from "types/role";
import { FC } from "react";

const getSlides = (br: Breakpoint): number => {
  if (br === "xs") return 1;
  if (br === "sm") return 1;
  if (br === "lg" || br === "xl") return 3;
  if (br === "md") return 1.5;
  return 2;
};

const getSpacing = (br: Breakpoint): number => {
  if (br === "xs") return 200;
  if (br === "sm") return 30;
  if (br === "md") return 40;
  if (br === "lg") return 10;
  return 0;
};

const HeroSection: FC<{ role: ROLE }> = () => {
  const navigate = useNavigate();

  const br = useWidth();

  const handleNavigate = (path: NavigationRoutes | string) => navigate(path);

  const swipData = data;

  return (
    <Box sx={styles.container}>
      <Swiper
        className={br === "lg" || br === "xl" ? "custom-top-swiper" : ""}
        spaceBetween={getSpacing(br)}
        slidesPerView={getSlides(br)}
        loop
        speed={700}
        grabCursor={true}
        autoplay={{
          delay: 1000,
          pauseOnMouseEnter: true,
        }}
        style={{ paddingBottom: "60px" }}
        freeMode
        pagination={{
          clickable: true,
          renderBullet: function (_: number, className: string) {
            return (
              '<span class="' +
              className +
              '"><img class="pagination-bullet"/></span>'
            );
          },
        }}
        modules={[Pagination, Autoplay]}
      >
        {swipData.map((entry) => (
          <SwiperSlide key={entry.id} data-testid="swiper-slide-testid">
            <Box
              onClick={() => handleNavigate(entry.path)}
              sx={{ margin: { xs: 2 } }}
            >
              <CardItem
                hightlightedImage={entry.hightlightedImage}
                policeDepartement={entry.policeDepartement}
                label={entry.label}
                path={entry.path}
                id={entry.id}
              >
                {entry.children}
              </CardItem>
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};
export default HeroSection;
