import { QueryArgs } from "types/query_args";
import rootApi from "..";
import { GetById } from "../types";
import {
  getAllActiveChannels,
  getAllChannels,
  getAllExpiredChannels,
  getAllScheduledChannels,
  getChannelById,
  getLiveStreamings,
  patchVideoLiveStream,
  getPodcastLiveStreamings,
  postChannel,
  postChannelTokenAC,
  updateChannel,
  getLiveStreamingsDetails,
  getVideoLiveStream,
} from "./action_creators";
import {
  Channel,
  PostChannelBody,
  PostChannelTokenBody,
  RTCTokenResponse,
  Upcoming,
} from "./types";

export const streamingApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    postChannel: builder.mutation<
      Channel,
      { token: string; data: PostChannelBody }
    >({
      query: (body) => postChannel(body),
    }),
    postChannelTokenAction: builder.mutation<
      RTCTokenResponse,
      {
        token: string;
        body: PostChannelTokenBody;
      }
    >({
      query: (body) => postChannelTokenAC(body),
    }),
    /* generateToken: builder.mutation<
      RTCTokenResponse,
      {
        token: string;
        body: PostChannelTokenBody;
      }
    >({
      query: (body) => postChannelToken(body),
    }), */
    updateChannel: builder.mutation<string, { token: string; data: Channel }>({
      query: (body) => updateChannel(body),
    }),
    getExpiredChannels: builder.query<Array<Channel>, { token: string }>({
      query: (body) => getAllExpiredChannels(body),
    }),
    getChannelById: builder.query<Channel, GetById>({
      query: (body) => getChannelById(body),
    }),
    getActiveChannels: builder.query<Array<Channel>, { token: string }>({
      query: (body) => getAllActiveChannels(body),
    }),
    getScheduledChannels: builder.query<Array<Channel>, { token: string }>({
      query: (body) => getAllScheduledChannels(body),
    }),
    getAllChannels: builder.query<Array<Channel>, { token: string }>({
      query: (body) => getAllChannels(body),
    }),
    getLiveStreamings: builder.query<
      { results: Array<Upcoming> },
      { token: string; query: Partial<QueryArgs> }
    >({
      query: (body) => getLiveStreamings(body),
    }),
    getLiveStreamingsById: builder.query<
      { results: Array<Upcoming> },
      { token: string; query: Partial<QueryArgs> }
    >({
      query: (body) => getLiveStreamingsDetails(body),
    }),
    getVideoLiveStreamById: builder.query<Upcoming, GetById>({
      query: (param) => getVideoLiveStream(param),
    }),
    updateVideoLiveStream: builder.mutation<Upcoming, GetById>({
      query: (param) => patchVideoLiveStream(param),
    }),
    getPodcastLiveStreamings: builder.query<
      { results: Array<Upcoming> },
      { token: string; query: Partial<QueryArgs> }
    >({
      query: (body) => getPodcastLiveStreamings(body),
    }),
  }),
});

export const {
  usePostChannelMutation,
  useUpdateChannelMutation,
  useGetPodcastLiveStreamingsQuery,
  useGetLiveStreamingsByIdQuery,
  useGetExpiredChannelsQuery,
  useGetLiveStreamingsQuery,
  usePostChannelTokenActionMutation,
  useGetVideoLiveStreamByIdQuery,
  useUpdateVideoLiveStreamMutation,
  /* useGenerateTokenMutation, */
  useGetActiveChannelsQuery,
  useGetChannelByIdQuery,
  useGetAllChannelsQuery,
  useGetScheduledChannelsQuery,
} = streamingApi;
