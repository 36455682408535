import { Methods } from "types/http";
import apiUrls from "../urls";
import { Like } from "./types";
import { GetById } from "../types";

export const postLike = (param: { body: Like; token: string }) => ({
  url: apiUrls.postLike(),
  method: Methods.POST,
  body: param.body,
  headers: {
    Lng: "EN",
    Accept: "*/*",
    Authorization: `Bearer ${param.token}`,
  },
});

export const postDislike = (param: { body: Like; token: string }) => ({
  url: apiUrls.postDisLike(),
  method: Methods.POST,
  body: param.body,
  headers: {
    Lng: "EN",
    Accept: "*/*",
    Authorization: `Bearer ${param.token}`,
  },
});

export const getVideoLikesAC = (param: GetById) => ({
  url: apiUrls.getVideoLikes(param.id),
  method: Methods.GET,
  headers: {
    Lng: "EN",
    Accept: "*/*",
    Authorization: `Bearer ${param.token}`,
  },
});

export const getVideoLikesDetailsAC = (param: GetById) => ({
  url: apiUrls.getVideoLikesDetails(param.id),
  method: Methods.GET,
  headers: {
    Lng: "EN",
    Accept: "*/*",
    Authorization: `Bearer ${param.token}`,
  },
});

export const updateReaction = ({ param, body }: { param: GetById; body: Like }) => ({
  url: apiUrls.updateReaction(),
  method: Methods.POST,
  body,
  headers: {
    Lng: "EN",
    Accept: "*/*",
    Authorization: `Bearer ${param.token}`,
  },
});

export const getVideodisLikesAC = (param: GetById) => ({
  url: apiUrls.getVideodisLikes(param.id),
  method: Methods.GET,
  headers: {
    Lng: "EN",
    Accept: "*/*",
    Authorization: `Bearer ${param.token}`,
  },
});

export const getVideodisLikesDetailsAC = (param: GetById) => ({
  url: apiUrls.getVideodisLikesDetails(param.id),
  method: Methods.GET,
  headers: {
    Lng: "EN",
    Accept: "*/*",
    Authorization: `Bearer ${param.token}`,
  },
});

export const getUserLikesAC = (param: GetById) => ({
  url: apiUrls.getUserLikes(param.id),
  method: Methods.GET,
  headers: {
    Lng: "EN",
    Accept: "*/*",
    Authorization: `Bearer ${param.token}`,
  },
});

export const getUserLikesDetailsAC = (param: GetById) => ({
  url: apiUrls.getUserLikesDetails(param.id),
  method: Methods.GET,
  headers: {
    Lng: "EN",
    Accept: "*/*",
    Authorization: `Bearer ${param.token}`,
  },
});
