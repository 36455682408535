import { VideoQuery } from "types/video_query";
import { NAMESPACES } from "./namespaces";
import { MembershipQuery } from "types/membershipQuery";
import { MEDIA_TYPE } from "types/media_type";
import { QueryArgs } from "types/query_args";
import generateUrl, { QueryParams } from "utils/encoded_query";
import { Identification } from "types/identification";

const apiUrls = {
  getOnlineDetails: (id: number) => `online/${id}`,
  getOnlineEvents: () => `online`,

  addCategory: () => `${NAMESPACES.category}/addCustomerCats`,
  findAllCategories: () => `${NAMESPACES.category}/findAll`,
  getCustomer: (id: string | number) =>
    `${NAMESPACES.category}/getCustomer/${id}`,

  fineAllUser: () => `${NAMESPACES.user}/findAll`,
  updateUser: () => `${NAMESPACES.user}/update`,
  validateTransactionId: () => `${NAMESPACES.user}/validate`,

  registerUser: () => `${NAMESPACES.user}/reg`,
  userById: (id: string | number) => `${NAMESPACES.user}/user/${id}`,
  validateOTP: () => `${NAMESPACES.user}/validate`,

  resetPwd: () => `${NAMESPACES.user}/resetPass`,
  preResetPwd: () => `${NAMESPACES.user}/preResetPass`,
  generateToken: () => `${NAMESPACES.user}/login`,
  refreshToken: () => `${NAMESPACES.user}/token/refresh`,
  verifyToken: () => `${NAMESPACES.identity}/token/verify`,
  getRoles: () => `${NAMESPACES.identity}/roles`,

  podcastHealthCheck: () => `${NAMESPACES.podcast}/health_check`,
  createPodcast: () => `${NAMESPACES.podcast}/on_demand/`,

  podcastById: (id?: Identification) =>
    `${NAMESPACES.podcast}/on_demand/${id}/`,

  /* resolveQuery(query, `${NAMESPACES.video}/on_demand/all`, "?"), */
  /* getPodcastList: (query: VideoQuery) =>
    resolveQuery<VideoQuery>(query, `${NAMESPACES.podcast}/on_demand/all`), */

  getPodcastList: (params: VideoQuery) =>
    generateUrl(
      `${NAMESPACES.podcast}/on_demand/all?`,
      params as unknown as QueryParams
    ),

  getMembershipList: ({
    status,
    category,
    country,
    privacy,
  }: MembershipQuery) =>
    `${NAMESPACES.speaker}/membership/?status=${status}${
      category ? `&category=${category}` : ``
    }${country ? `&country=${country}` : ``}${
      privacy ? `&privacy=${privacy}` : ``
    }`,

  postMembership: () => `${NAMESPACES.speaker}/membership/`,
  membershipById: (id: string | number) =>
    `${NAMESPACES.speaker}/membership/${id}/`,
  queryMembershipById: (id: string | number) =>
    `${NAMESPACES.speaker}/membership/${id}/`,

  healthCheck: () => `${NAMESPACES.video}/on_demand/health_check`,
  createVideo: () => `${NAMESPACES.video}/on_demand/`,

  videoById: (id?: Identification) => `${NAMESPACES.video}/on_demand/${id}/`,

  getVideoList: (query: VideoQuery) =>
    generateUrl(
      `${NAMESPACES.video}/on_demand/all`,
      query as unknown as QueryParams
    ),

  /* `${NAMESPACES.video}/on_demand/all?status=${status}${
      category ? `&category=${category}` : ``
    }${privacy ? `&privacy=${privacy}` : ``}${user ? `&user=${user}` : ``}` */

  createLiveVideo: () => `${NAMESPACES.video}/live_streaming/`,
  liveVideoById: (id?: Identification) =>
    `${NAMESPACES.video}/live_streaming/${id}/`,

  getLiveVideoList: ({ status, category, privacy }: VideoQuery) =>
    `${NAMESPACES.video}/live_streaming/all?status=${status}${
      category ? `&category=${category}` : ``
    }${privacy ? `&privacy=${privacy}` : ``}`,

  getComments: () => `${NAMESPACES.comment}/comments`,
  updateComment: () => `${NAMESPACES.comment}/comment`,
  postComment: () => `${NAMESPACES.comment}/comment`,
  commentById: (id: string | number) => `${NAMESPACES.comment}/comment/${id}`,
  likeComment: () => `${NAMESPACES.comment}/commentLike`,
  getVideoComments: (id: string | number, mediaType: MEDIA_TYPE) =>
    `${NAMESPACES.comment}/comments/${mediaType}/${id}`,
  getProfileComments: (id: string | number) =>
    `${NAMESPACES.comment}/comments/${MEDIA_TYPE.USER_PROFILE}/${id}`,
  getUserComments: (id: string | number) =>
    `${NAMESPACES.comment}/comments/user/${id}`,
  getCommentLikes: () => `${NAMESPACES.comment}/likes/count/comment`,
  getCommentDisLikes: () => `${NAMESPACES.comment}/dislikes/count/comment`,

  postRate: () => `${NAMESPACES.rate}/rate`,
  rateVideo: (id: string | number) =>
    `${NAMESPACES.rate}/${MEDIA_TYPE.ON_DEMAND_VIDEO}/${id}`,

  getVideoRate: (id: string | number) =>
    `${NAMESPACES.rate}/${MEDIA_TYPE.ON_DEMAND_VIDEO}/${id}`,

  rateUser: (id: string | number) =>
    `${NAMESPACES.rate}/${MEDIA_TYPE.USER_PROFILE}/${id}`,

  getVideoRatesDetails: (id: string | number) =>
    `${NAMESPACES.rate}/rates/${MEDIA_TYPE.USER_PROFILE}/${id}`,

  postView: () => `${NAMESPACES.view}/view`,

  getVideoViewsDetails: (id: string | number) =>
    `${NAMESPACES.view}/views/${MEDIA_TYPE.ON_DEMAND_VIDEO}/${id}`,

  getPodcastViewsDetails: (id: string | number) =>
    `${NAMESPACES.view}/views/${MEDIA_TYPE.ON_DEMAND_PODCAST}/${id}`,

  getProfileViewsCount: (id: string | number) =>
    `${NAMESPACES.view}/views/user/count/${id}`,

  getVideoViewsCount: (id: string | number) =>
    `${NAMESPACES.view}/views/count/${MEDIA_TYPE.ON_DEMAND_VIDEO}/${id}`,

  getPodcastViewsCount: (id: string | number) =>
    `${NAMESPACES.view}/views/count/${MEDIA_TYPE.ON_DEMAND_PODCAST}/${id}`,

  getVideoViews: (id: string | number) =>
    `${NAMESPACES.view}/views/count/video/${id}`,

  postLike: () => `${NAMESPACES.like}/like`,
  postDisLike: () => `${NAMESPACES.like}/like`,
  updateReaction: () => `${NAMESPACES.like}/like`,

  getVideoLikes: (id: string | number) =>
    `${NAMESPACES.like}/likes/count/${MEDIA_TYPE.ON_DEMAND_VIDEO}/${id}`,

  getVideodisLikes: (id: string | number) =>
    `${NAMESPACES.like}/disLikes/count/${MEDIA_TYPE.ON_DEMAND_VIDEO}/${id}`,

  getVideoLikesDetails: (id: string | number) =>
    `${NAMESPACES.like}/likes/${MEDIA_TYPE.ON_DEMAND_VIDEO}/${id}`,

  getVideodisLikesDetails: (id: string | number) =>
    `${NAMESPACES.like}/disLikes/${MEDIA_TYPE.ON_DEMAND_VIDEO}/${id}`,

  getUserLikes: (id: string | number) =>
    `${NAMESPACES.like}/likes/user/count/${id}`,

  getUserLikesDetails: (id: string | number) =>
    `${NAMESPACES.like}/likes/user/${id}`,

  postLiveStreaming: () => `${NAMESPACES.video}/live_streaming/`,

  getLiveStreamings: (query: Partial<QueryArgs>) =>
    generateUrl(
      `${NAMESPACES.video}/live_streaming/all`,
      query as unknown as QueryArgs
    ),

  getLiveStreamingsByID: (query: Partial<QueryArgs>) =>
    generateUrl(
      `${query.endpoint ? query.endpoint : NAMESPACES.video}/live_streaming/`,
      query as unknown as QueryArgs
    ),

  getPodcastLiveStreamings: (query: Partial<QueryArgs>) =>
    generateUrl(
      `${NAMESPACES.podcast}/live_streaming/all`,
      query as unknown as QueryArgs
    ),

  postChannel: () => `${NAMESPACES.streaming}/channel`,
  updateChannel: () => `${NAMESPACES.streaming}/channel`,
  getAllScheduledChannels: () => `${NAMESPACES.streaming}/channels/scheduled`,
  getAllExpiredChannels: () => `${NAMESPACES.streaming}/channels/expired`,
  getAllActiveChannels: () => `${NAMESPACES.streaming}/channels/active`,
  getAllChannels: () => `${NAMESPACES.streaming}/channels`,
  getChannelById: (id: string | number) =>
    `${NAMESPACES.streaming}/channel/${id}`,
  postChannelTokenUrl: () => `${NAMESPACES.streaming}/generateToken`,
  getAllLiveStreams: () => `${NAMESPACES.streaming}/live_streaming/`,

  getCategories: () => `${NAMESPACES.category}/findAll`,

  createChatRoom: ({
    host,
    org_name,
    app_name,
  }: {
    host: string;
    org_name: string;
    app_name: string;
  }) => `https://${host}/${org_name}/${app_name}/chatrooms`,
};

export default apiUrls;