import { SxProps } from "@mui/material";
import color_pallette from "theme/color_pallette";

const styles: Record<string, SxProps> = {
  container: {},
  videoContainer: {
    backgroundColor: color_pallette.backgroundGray,
    width: "100%",
    height: { xs: 300, md: 450, lg: 700 },
    position: "relative",
    borderRadius: "20px",
    paddingTop: "51.25%",
  },
};

export default styles;
