import { FC } from "react";
import Typography from "@mui/material/Typography";
import Grow from "@mui/material/Grow";
import Box from "@mui/material/Box";
import styles from "./styles";
import online_image2 from "assets/images/blog_img.webp";

type Props = {
  img: string;
  title: string;
  onClick?: () => void;
};

const TopBlog: FC<Props> = ({ img, onClick, title }) => {
  return (
    <Grow in timeout={600}>
      <Box onClick={onClick} sx={styles.container}>
        <img
          alt="blog thumbnail"
          style={{ borderRadius: 20, aspectRatio: "5 / 3" }}
          src={img ?? online_image2}
        />
        <Box sx={{ maxWidth: "80%", textAlign: "start" }}>
          <Typography sx={styles.title}>{title}</Typography>
        </Box>
      </Box>
    </Grow>
  );
};

export default TopBlog;
