import rootApi from "store/api";
import {
  getUserLikesAC,
  getUserLikesDetailsAC,
  getVideoLikesAC,
  getVideoLikesDetailsAC,
  getVideodisLikesAC,
  getVideodisLikesDetailsAC,
  postDislike,
  postLike,
  updateReaction,
} from "./action_creators";
import { Like } from "./types";
import { GetById } from "../types";

export const LikesApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    likeVideo: builder.mutation<Like, { body: Like; token: string }>({
      query: (body) => postLike(body),
    }),
    disLikeVideo: builder.mutation<Like, { body: Like; token: string }>({
      query: (body) => postDislike(body),
    }),
    getVideoLikesCount: builder.query<number, GetById>({
      query: (body) => getVideoLikesAC(body),
    }),
    getVideoLikesDetails: builder.query<Array<Like>, GetById>({
      query: (body) => getVideoLikesDetailsAC(body),
    }),
    getVideoDisLikesCount: builder.query<number, GetById>({
      query: (body) => getVideodisLikesAC(body),
    }),
    updateUserReaction: builder.mutation<
      number,
      { param: GetById; body: Like }
    >({
      query: (body) => updateReaction(body),
    }),
    getVideoDisLikesDetails: builder.query<Array<Like>, GetById>({
      query: (body) => getVideodisLikesDetailsAC(body),
    }),
    getUserLikesCount: builder.query<number, GetById>({
      query: (body) => getUserLikesAC(body),
    }),
    getUserLikesDetails: builder.query<Array<Like>, GetById>({
      query: (body) => getUserLikesDetailsAC(body),
    }),
  }),
});

export const {
  useLikeVideoMutation,
  useDisLikeVideoMutation,
  useUpdateUserReactionMutation,
  useGetVideoLikesDetailsQuery,
  useGetVideoDisLikesCountQuery,
  useGetVideoDisLikesDetailsQuery,
  useGetUserLikesDetailsQuery,
  useGetUserLikesCountQuery,
  useGetVideoLikesCountQuery,
} = LikesApi;
