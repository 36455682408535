import { FC } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import styles from "./styles";
import { UploadedFileStatus } from "types/uploaded_file_status";
import Chronometer from "components/chronometer";
import { UploadPrivacy } from "types/upload_privacy";
import emptyPodcasts from "assets/images/placeholders/empty_podcasts.webp";
import { useGetLiveStreamingsQuery } from "store/api/streaming_service/endpoints";
import { useAppSelector } from "store/hooks";
import InteractiveListItem from "components/interactive_list_item";

const BodySection: FC = () => {
  const token = useAppSelector(
    (state) => state.global.credentials.token.access_token
  );
  function getRandomNumber(min: number, max: number): Date {
    const time = new Date();

    const seconds = Math.floor(Math.random() * (max - min + 1)) + min;

    time.setSeconds(time.getSeconds() + seconds);

    return time;
  }

  const { data } = useGetLiveStreamingsQuery(
    {
      query: {
        status: UploadedFileStatus.ACCEPTED,
        privacy: UploadPrivacy.PUBLIC,
        is_expired: false,
        is_live: false,
      },
      token,
    },
    {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
    }
  );

  const upcomingList = data?.results ?? [];

  return (
    <Grid
      sx={styles.container}
      justifyContent="stretch"
      alignItems="center"
      rowGap={10}
      columnGap={{ md: 2, lg: 7, xl: 2 }}
      container
    >
      {upcomingList && upcomingList.length ? (
        upcomingList.map((entry) => (
          <Grid xl={3.9} item key={entry.id}>
            <Stack marginBottom={1} direction="row" justifyContent="center">
              <Chronometer
                labelStyles={styles.labelStyle}
                timeBoxStyle={styles.chronoStyles}
                expiryTimestamp={getRandomNumber(10000, 1000000)}
              />
            </Stack>
            <InteractiveListItem {...entry} />
          </Grid>
        ))
      ) : (
        <Stack direction="row" width="100%" justifyContent="center">
          <Box component="img" src={emptyPodcasts} />
        </Stack>
      )}
    </Grid>
  );
};

export default BodySection;
