import { FC } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import styles from "./styles";
import GeneralInfoForm from "../general_info_form";
import UploadDocumentForm from "../upload_document_form";
import NomineeFormSection from "../nominee_form_section";
/* import UploadDocumentForm from "../upload_document_form"; */
/* import NominationForm from "../nomination_form"; */

const FormSection: FC = () => {
  return (
    <>
      <Box sx={styles.labels}>
        <Typography sx={styles.header}>Nomination Form</Typography>
        <Typography sx={styles.subHeader}>
          Please utilize this form to fill your information to nominate future
          speaker
        </Typography>
      </Box>
      <Box sx={styles.container}>
        <GeneralInfoForm />
        <NomineeFormSection />
        <UploadDocumentForm />
        {/* <NominationForm /> */}
      </Box>
    </>
  );
};

export default FormSection;
