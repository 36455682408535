import { FC, useEffect } from "react";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Grow from "@mui/material/Grow";
import styles from "./styles";
import { useAppDispatch, useAppSelector } from "store/hooks";
import HeaderSection from "./header_section";
import { UserEditPodcastFormNames } from "./form";
import Checkbox from "common/checkbox";
import { FormikValues, useFormikContext } from "formik";
import InputField from "common/input_field";
import MetaSection from "./meta_section";
import SubmitButton from "common/submit_button";
import UploadThumbSection from "./upload_thumb_section";
import SnackBar from "components/snack_bar";
import { useGetAllCategoriesQuery } from "store/api/categorie_service/endpoints";
import { useIntl } from "react-intl";
import messages from "pages/view_admin_profile/messages";
import { UploadPrivacy } from "types/upload_privacy";
import { modalSliceActions } from "store/modal_slice";
import { Typography } from "@mui/material";
import color_pallette from "theme/color_pallette";

const EditPodcastModal: FC = () => {
  const showModal = useAppSelector((state) => state.modals.showEditPodcast);

  const { toEditPodcast } = useAppSelector((state) => state.livetipsPodcast);

  const { handleChange, handleBlur, values } = useFormikContext<FormikValues>();

  const token = useAppSelector(
    (state) => state.global.credentials.token.access_token
  );

  const { data } = useGetAllCategoriesQuery(
    {
      token,
    },
    {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
    }
  );

  const intl = useIntl();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!toEditPodcast) dispatch(modalSliceActions.setEditPodcast(false));
  }, [toEditPodcast, dispatch]);

  if (!toEditPodcast) return null;

  return (
    <Modal open={showModal} sx={styles.container}>
      <Grow in={showModal} timeout={600}>
        <Paper sx={styles.paper}>
          <HeaderSection confirmation={true} />
          <SnackBar />
          <Stack sx={styles.bodyContainer}>
            <MetaSection podcast={toEditPodcast} />
            <Stack sx={styles.formContainer}>
              <UploadThumbSection />
              <InputField
                inputMode="text"
                label={intl.formatMessage(messages.title)}
                fullWidth
                sx={styles.bg}
                name={UserEditPodcastFormNames.videoTitle}
              />
              <InputField
                inputMode="text"
                label={intl.formatMessage(messages.desc)}
                fullWidth
                multiline
                rows={4}
                sx={styles.bg}
                name={UserEditPodcastFormNames.description}
              />
              <Select
                name={UserEditPodcastFormNames.category}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values[UserEditPodcastFormNames.category]}
                defaultValue={1}
                sx={styles.bg}
                fullWidth
              >
                {data &&
                  data.length > 0 &&
                  data.map((entry) => (
                    <MenuItem
                      key={entry.categoryId.toString()}
                      value={parseInt(entry.categoryId as string)}
                    >
                      {entry.nameEn}
                    </MenuItem>
                  ))}
              </Select>
              <Stack direction="row">
                <Checkbox
                  value={UploadPrivacy.DUBAI_POLICE}
                  label={intl.formatMessage(messages.dubai_police)}
                  name={UserEditPodcastFormNames.exclusivity}
                />
                <Checkbox
                  value={UploadPrivacy.PUBLIC}
                  label={intl.formatMessage(messages.public)}
                  name={UserEditPodcastFormNames.exclusivity}
                />
              </Stack>
            </Stack>
          </Stack>
          <SubmitButton
            extraStyles={styles.nextButton}
            title="Update Content"
          />
          <Typography sx={{ paddingBottom: 2, fontSize: { lg: 13 } }}>
            As you edit your content , these changes{" "}
            <span style={{ color: color_pallette.primary }}>
              will not reflect immediately
            </span>{" "}
            as they need to go through{" "}
            <span style={{ color: color_pallette.primary }}>approval</span> from{" "}
            <span style={{ color: color_pallette.primary }}>admin</span> first
          </Typography>
        </Paper>
      </Grow>
    </Modal>
  );
};

export default EditPodcastModal;
