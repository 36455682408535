import { SxProps } from "@mui/material";
import color_pallette from "theme/color_pallette";

const styles: Record<string, SxProps> = {
  container: {
    width: "100%",
    paddingBlock: 1.5,
  },
  play: { aspectRatio: "4 / 4", width: 40, objectFit: "scale-down" },
  host: {
    width: 60,
    aspectRatio: "4 / 4",
    borderRadius: 3,
  },
  icons: {
    color: color_pallette.primary,
    width: 45,
    height: 45,
  },
  playbackSpeed: {
    cursor: "pointer",
    color: color_pallette.primary,
  },
  progress: {
    borderBottom: "1px solid #f5f5f5",
  },
};

export default styles;
