import { FC } from "react";
import Snackbar, { SnackbarProps } from "@mui/material/Snackbar";
import Grow from "@mui/material/Grow";
import cropText from "utils/crop_text";
import { useAppSelector } from "store/hooks";
import { SNACK_DURATION } from "hooks/useSnack";

type Props = {
  extraProps?: SnackbarProps;
};

const SnackBar: FC<Props> = ({ extraProps }) => {
  const { open, backgroundColor, msg } = useAppSelector((state) => state.snack);

  return (
    <Grow in={open} timeout={700}>
      <Snackbar
        ContentProps={{
          sx: { backgroundColor, borderRadius: 4, boxShadow: 1 },
        }}
        open={open}
        autoHideDuration={SNACK_DURATION}
        transitionDuration={450}
        message={cropText(msg, 50)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        {...extraProps}
      />
    </Grow>
  );
};

export default SnackBar;
