import { defineMessages } from "react-intl";

const scope = "OnlineLessonsItem";

export default defineMessages({
  views: {
    id: `${scope}.views`,
    defaultMessage: "Views",
  },
  shares: {
    id: `${scope}.shares`,
    defaultMessage: "Shares",
  },
  rated: {
    id: `${scope}.rated`,
    defaultMessage: "Rating",
  },
  liked: {
    id: `${scope}.liked`,
    defaultMessage: "Likes",
  },
});
