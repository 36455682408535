import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBlock: 1,
    width: "fit-content",
  },
  header: {
    marginBottom: 0.5,
    fontSize: {
      lg: 15,
    },
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

export default styles;
