import { FC } from "react";
import Grow from "@mui/material/Grow";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import about_us_hero from "assets/images/new_about_us_hero.png";
import styles from "./styles";

const HeroSection: FC = () => {
  return (
    <Stack
      component="div"
      alignItems={{ xs: "center" }}
      justifyContent={{ md: "space-between" }}
      direction={{ xs: "column", md: "row" }}
      sx={styles.container}
    >
      <Grow in timeout={1600}>
        <Box component="img" sx={styles.heroImg} src={about_us_hero} />
      </Grow>
    </Stack>
  );
};

export default HeroSection;
