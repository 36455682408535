import { defineMessages } from "react-intl";

const scope = "Appbar";

export default defineMessages({
  signout: {
    id: `${scope}.signout`,
    defaultMessage: "sign out",
  },
  signout_confirmation: {
    id: `${scope}.signout_confirmation`,
    defaultMessage: "Are you sure you want to sign out ?",
  },
  name: {
    id: `${scope}.name`,
    defaultMessage: "Name",
  },
  email: {
    id: `${scope}.email`,
    defaultMessage: "Email",
  },
  livetipsid: {
    id: `${scope}.livetipsid`,
    defaultMessage: "Live Tips Id",
  },
  viewprofile: {
    id: `${scope}.viewprofile`,
    defaultMessage: "View Full Profile",
  },
  login: {
    id: `${scope}.login`,
    defaultMessage: "Login",
  },
  signup: {
    id: `${scope}.signup`,
    defaultMessage: "Sign up",
  },
});
