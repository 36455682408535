import { FC, useCallback } from "react";
/* import Typography from "@mui/material/Typography"; */
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import styles from "./styles";
import { ReactSVG } from "react-svg";
import FeaturedHost from "./featured_host";
import lessons from "assets/svgs/lessons.svg";
import { UploadedFileStatus } from "types/uploaded_file_status";
import { UploadPrivacy } from "types/upload_privacy";
import { useAppSelector } from "store/hooks";
/* import TopBlog from "components/top_blog";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "navigation/routes";
import { Identification } from "types/identification";
import { blogs } from "pages/blog/data"; */
/* import { useGetBlogsQuery } from "store/api/blog_service/endpoints"; */
/* import { FormattedMessage } from "react-intl";
import messages from "./messages"; */
import { useGetVideoListQuery } from "store/api/video_service/endpoints";
import { ROLE } from "types/role";
import { VideoServiceResponse } from "store/api/types";
import OnlineLessonsItem, {
  SwipableOnlineLessonsProps,
} from "components/online_lessons_item";
import withSwiper from "hoc/withSwiper";
import useLiveSwiperProps from "../live_section/swiper_props";

const LessonsSection: FC = () => {
  const {
    credentials: { token },
    permission,
  } = useAppSelector((state) => state.global);

  const { data } = useGetVideoListQuery({
    status: UploadedFileStatus.ACCEPTED,
    privacy: UploadPrivacy.PUBLIC,
    user: permission == ROLE.officer || permission == ROLE.admin ? 297 : 245,
    token: token.access_token,
  }) as {
    data: { results: VideoServiceResponse[] };
    isSuccess: boolean;
    isLoading: boolean;
  };

  /* const { data: blogs } = useGetBlogsQuery({ token }); */

  /* const navigate = useNavigate(); */

  /* const handleNavigate = (id?: Identification) => {
    if (id) navigate(`${NavigationRoutes.blog}/${id}`);
  }; */

  /* const handleViewHost = (id: Identification) => {
    if (id) navigate(`${NavigationRoutes.public_speaker_profile}/${id}`);
  }; */

  const { lessonsSwiperProps: swiperProps } = useLiveSwiperProps();

  const renderSwiper = useCallback(() => {
    return withSwiper<SwipableOnlineLessonsProps, VideoServiceResponse>(
      OnlineLessonsItem,
      data?.results.slice(0, 4) ?? []
    )({
      componentProps: {
        itemData: {} as VideoServiceResponse,
      },
      swiperProps,
    });
  }, [data, swiperProps]);

  const renderSwiper_2 = useCallback(() => {
    return withSwiper<SwipableOnlineLessonsProps, VideoServiceResponse>(
      OnlineLessonsItem,
      data?.results.slice(4, data?.results.length) ?? []
    )({
      componentProps: {
        itemData: {} as VideoServiceResponse,
      },
      swiperProps,
    });
  }, [data, swiperProps]);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.gridContainer}>
        <Grid container rowGap={2} columnGap={2}>
          <Grid item xs={12} lg={12} xl={12}>
            <Paper sx={styles.paperContainer}>
              <ReactSVG src={lessons} />
              <Box sx={styles.featuredLessonsContainer}>{renderSwiper()}</Box>
              <Box sx={styles.featuredLessonsContainer}>{renderSwiper_2()}</Box>
            </Paper>
          </Grid>
          {/* <Grid item xs={12} lg={12} xl={12}>
            <Paper sx={styles.blogContainer}>
              <Typography sx={styles.blogTitle}>
                <FormattedMessage id={messages.top_blogs.id} />
              </Typography>
              <Box sx={styles.featuredLessonsContainer}>{renderSwiper_2()}</Box>
            </Paper>
          </Grid> */}
        </Grid>
      </Box>
      <FeaturedHost />
    </Box>
  );
};

export default LessonsSection;
