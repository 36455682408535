import { SxProps } from "@mui/material";
import color_pallette from "theme/color_pallette";

const styles: Record<string, SxProps> = {
  container: {},
  videoInfo: {
    backgroundColor: color_pallette.backgroundGray,
    borderRadius: 3,
    paddingInline: 2,
    display: "flex",
    flexDirection: { xs: "row" },
    alignItems: "center",
    justifyContent: "space-between",
    flexGrow: 1,
    marginInlineEnd: 2,
    paddingBlock: { xs: 1, lg: 2 },
  },
  videoStatusContainer: {
    paddingBlock: 1.6,
  },
  uploadedText: {
    color: color_pallette.primary,
  },
  videoName: {
    marginInlineStart: 2,
  },
  divider: {
    width: "40%",
    marginInline: 1,
  },
};

export default styles;
