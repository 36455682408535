import { FC } from "react";
import { Paper, Typography } from "@mui/material";
import styles from "./styles";
import { ReactSVG } from "react-svg";

type Props = {
  label: string;
  iconPath?: string;
  onClick?: () => void;
};

const ActionCard: FC<Props> = ({ iconPath, label, onClick }) => {
  return (
    <Paper onClick={onClick} sx={styles.container}>
      {iconPath && <ReactSVG src={iconPath} />}
      <Typography sx={styles.label}>{label}</Typography>
    </Paper>
  );
};

export default ActionCard;
