import { ChangeEvent, FC, useRef } from "react";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Grow from "@mui/material/Grow";
import styles from "./styles";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { ReactSVG } from "react-svg";
import Button from "components/custom_button";
import upload from "assets/svgs/upload_podcast.svg";
import cancelIc from "assets/svgs/cancel.svg";
import { LivetipsPodcastSliceActions } from "store/livetips_podcast_slice";
import { ProfileSliceActions } from "store/profile_slice";
import { FormattedMessage } from "react-intl";
import messages from "pages/view_admin_profile/messages";

type Props = {
  handleUploadedFile: (file: File, path: string) => void;
};

const UploadPodcastModal: FC<Props> = ({ handleUploadedFile }) => {
  const showModal = useAppSelector(
    (state) => state.livetipsPodcast.showUploadModal
  );

  const dispatch = useAppDispatch();

  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = e.target.files?.item(0);
    if (uploadedFile != null) handleUploadedFile(uploadedFile, e.target.value);
  };

  const handleClick = () => {
    inputRef?.current?.click();
  };

  const handleCancel = () => {
    dispatch(LivetipsPodcastSliceActions.closeModals());
    dispatch(ProfileSliceActions.clearUploadFiles());
  };

  return (
    <Modal open={showModal} sx={styles.container}>
      <Grow in={showModal} timeout={600}>
        <Paper sx={styles.paper}>
          <Stack
            width="100%"
            direction="row"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
          >
            <Typography flexGrow={0.9} sx={styles.header}>
              <FormattedMessage id={messages.live.id} />
            </Typography>
            <ReactSVG
              onClick={handleCancel}
              style={{ cursor: "pointer", paddingBottom: "25px" }}
              src={cancelIc}
            />
          </Stack>
          <ReactSVG src={upload} />
          <Typography sx={styles.instruction}>
            <FormattedMessage id={messages.users_can.id} />
          </Typography>
          <Typography sx={styles.notice}>
            <FormattedMessage id={messages.privacy.id} />
          </Typography>
          <Button
            onClick={() => handleClick()}
            extraStyles={styles.button}
            label="Upload File"
          />
          <form encType="multipart/form-data">
            <input
              type="file"
              ref={inputRef}
              accept="audio/mp3"
              style={{ display: "none" }}
              name="upload_video"
              onChange={handleChange}
            />
          </form>
          <Typography sx={styles.privacy}>
            <FormattedMessage id={messages.privacy.id} />
          </Typography>
        </Paper>
      </Grow>
    </Modal>
  );
};

export default UploadPodcastModal;
