import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {},
  sendButton: {
    borderRadius: 3,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    background: "linear-gradient(145.46deg, #ACCFD2 -23.98%, #FFFFFF 126.67%)",
    alignItems: "center",
    padding: 1.5,
    boxShadow: 1,
  },
};

export default styles;
