import { FC, PropsWithChildren } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import styles from "./styles";
import { FormattedMessage } from "react-intl";

type Props = {
  onClick?: () => void;
  label: string;
  anchorLink?: string;
};

const SideBarItem: FC<PropsWithChildren<Props>> = ({
  onClick,
  label,
  anchorLink,
  children,
}) => {
  if (anchorLink)
    return (
      <Box
        component="a"
        href={anchorLink}
        sx={{
          ...styles.container,
          textDecoration: "none",
          color: "black",
        }}
      >
        <Typography sx={styles.menuItem}>
          <FormattedMessage id={label} />
        </Typography>
        {children}
      </Box>
    );

  return (
    <Box sx={styles.container} onClick={onClick}>
      <Typography sx={{ ...styles.menuItem, cursor: "pointer" }}>
        <FormattedMessage id={label} />
      </Typography>
      {children}
    </Box>
  );
};

export default SideBarItem;
