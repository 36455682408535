import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type State = {
  views: number;
};

const initialState: State = {
  views: 0,
};

const viewSlice = createSlice({
  name: "view_slice",
  initialState,
  reducers: {
    setCurrentViewCount: (state: State, { payload }: PayloadAction<number>) => {
      state.views = payload;
      return state;
    },
    updateCurrentViews: (state: State) => {
      state.views = state.views + 1;
      return state;
    },
    resetViewCount: (state: State) => {
      state.views = 0;
      return state;
    },
  },
});

export default viewSlice.reducer;
export const viewSliceActions = viewSlice.actions;
