import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    paddingTop: 5,
    paddingInlineStart: 10,
    width: "100%",
  },
};

export default styles;
