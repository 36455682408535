import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    border: "1px solid #7196A5",
    borderRadius: 2.5,
    paddingBlock: 1,
    width: { xs: "100%", md: "55%" },
    cursor: "pointer",
  },
  uploadLabel: {
    fontSize: {
      lg: 14,
    },
  },
};

export default styles;
