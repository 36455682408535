import { Nominee } from "./types";
import online_image1 from "assets/images/online_image1.webp";
import online_image2 from "assets/images/online_image2.webp";
import online_image3 from "assets/images/online_image3.webp";
import online_image4 from "assets/images/online_image4.webp";
import online_image5 from "assets/images/online_image5.webp";

const data: Array<Nominee> = [
  {
    id: "1",
    img: online_image1,
    name: "Seif Eddine",
    org: "Crowd Digital Portal",
  },
  {
    id: "2",
    img: online_image2,
    name: "Seif Eddine",
    org: "Crowd Digital Portal",
  },
  {
    id: "3",
    img: online_image3,
    name: "Seif Eddine",
    org: "Crowd Digital Portal",
  },
  {
    id: "4",
    img: online_image4,
    name: "Seif Eddine",
    org: "Crowd Digital Portal",
  },
  {
    id: "5",
    img: online_image5,
    name: "Seif Eddine",
    org: "Crowd Digital Portal",
  },
];

export default data;
