import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {},
  menuItemsWrapper: {
    cursor: "pointer",
  },
  labelWrapper: {
    borderRadius: 5,
    paddingBlock: 0.5,
    whiteSpace: "nowrap",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  menu: {
    height: 70,
    width: 200,
    position: "absolute",
    top: 40,
    borderRadius: 3,
    zIndex: 100,
    boxShadow: 5,
  },
};

export default styles;
