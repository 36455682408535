import { defineMessages } from "react-intl";

const scope = "MyNotifications";

export default defineMessages({
  notifications: {
    id: `${scope}.notifications`,
    defaultMessage: "My Notifications",
  },
  empty: {
    id: `${scope}.empty`,
    defaultMessage: "Box Empty..",
  },
});
