import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {},
  header: {
    fontSize: 30,
    fontFamily: "Nizzar ,K2D, Inter",
    fontWeight: "600",
    marginBottom: 2.5,
    marginInlineStart: 1,
  },
};

export default styles;
