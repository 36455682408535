import { FC, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Card from "components/card";
import LoginForm from "./login_form";
import styles from "./styles";
import { useIntl } from "react-intl";
import messages from "./messages";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { NavigationRoutes } from "navigation/routes";
import { useNavigate } from "react-router-dom";
import PrivacyPolicyModal from "components/privacy_policy_modal";
import color_pallette from "theme/color_pallette";
import { modalSliceActions } from "store/modal_slice";

const LoginPage: FC = () => {
  const { token } = useAppSelector((state) => state.global.credentials);

  const intl = useIntl();

  const navigate = useNavigate();

  useEffect(() => {
    if (token.access_token) navigate(NavigationRoutes.home);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch = useAppDispatch();

  const handleShowPrivacy = () => {
    dispatch(modalSliceActions.setPrivacyPolicy({ privacy: true }));
  };

  return (
    <>
      <Box sx={styles.container}>
        <Card extraStyle={styles.cardStyle}>
          <Typography sx={styles.font}>
            {intl.formatMessage(messages.loginLabel)}
          </Typography>
          <LoginForm />
          <Typography sx={{ fontSize: { lg: 13 }, opacity: 0.7, marginTop: 2 }}>
            By Creating a Live Tips account you agree to our{" "}
            <span
              onClick={handleShowPrivacy}
              style={{ color: color_pallette.primary, cursor: "pointer" }}
            >
              Privacy policy and Terms of Conditions
            </span>{" "}
          </Typography>
        </Card>
        <PrivacyPolicyModal />
      </Box>
    </>
  );
};

export default LoginPage;
