import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  root: {
    height: { xs: 300, xl: 400 },
    width: "98%",
    position: "relative",
    marginX: "auto",
    overflow: "visible",
    marginTop: 1,
  },
  container: {
    width: "100%",
    height: "100%",
    boxShadow: 0.5,
    objectFit: "cover",
    borderRadius: 3,
  },
  adminImage: {
    zIndex: 10,
    objectFit: "cover",
    width: 240,
    height: 235,
  },
  imageContainer: {
    height: undefined,
    aspectRatio: "4 / 4",
    width: 240,
    boxShadow: 0,
    borderRadius: 3,
    overflow: "hidden",
    left: {
      xs: "20%",
      sm: "37%",
      md: `56%`,
      lg: "46.25%",
      xl: "43%",
    },
    bottom: -55,
    position: "absolute",
  },
};

export default styles;