import { FC } from "react";
import styles from "./styles";
import Button from "components/custom_button";
import { useIntl } from "react-intl";
import messages from "./messages";

type Props = {
  label?: string;
  onClick?: () => void;
};

const ExploreFooterSection: FC<Props> = ({ label, onClick }) => {
  const intl = useIntl();
  return (
    <Button
      label={label ? label : `${intl.formatMessage(messages.explore)}`}
      onClick={onClick}
      extraStyles={styles.container}
    />
  );
};

export default ExploreFooterSection;
