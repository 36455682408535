import { Local } from "types/local";
import { GlobalState, LanguageDirection } from "./types";
import { ROLE } from "types/role";
import { UploadedFileStatus } from "types/uploaded_file_status";

export const globalInitialState: GlobalState = {
  lang: {
    prefix: Local.English,
    direction: LanguageDirection.leftToRight,
  },
  permission: ROLE.normal,
  activeMenuItem: "",
  speakerProfile: {
    customerId: "",
    mobile: "",
    email: "",
    status: UploadedFileStatus.PENDING,
    name: "",
    lng: "EN",
    org: "",
    nationality: "",
    country: "",
    role: ROLE.normal,
    gender: "",
    about: "",
    profilePicture: "",
    coverImage: "",
    createdAt: "",
    updatedAt: "",
  },
  darkTheme: false,
  credentials: {
    transactionId: "",
    token: {
      access_token: "",
      expires_in: 0,
      refresh_expires_in: 0,
      refresh_token: "",
      token_type: "Bearer",
      scope: "email openid profile",
      transactionId: "",
      userData: {
        id: "string",
        createdTimestamp: 0,
        username: "string",
        enabled: false,
        totp: false,
        emailVerified: false,
        firstName: "string",
        email: "string",
        role: ROLE.normal,
        disableableCredentialTypes: ["string", "string"],
        requiredActions: ["string", "string"],
        notBefore: 0,
        access: {
          manageGroupMembership: false,
          view: false,
          mapRoles: false,
          impersonate: false,
          manage: false,
        },
      },
    },
  },
};
