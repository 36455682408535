import { FC, useMemo } from "react";
import { SxProps, Grid, GridProps } from "@mui/material";
import OnlineCardListItem from "components/online_card_list_item";
import { VideoServiceResponse } from "store/api/types";
import SkeletonCards from "common/skeleton_section";
import combineStyles from "utils/combile_styles";
import styles from "./styles";
import { UploadedFileStatus } from "types/uploaded_file_status";

type Props = {
  dimensions?: GridProps;
  containerStyles?: SxProps;
  loadingSkeletons?: GridProps;
  videoList: Array<VideoServiceResponse> | undefined;
  isLoading: boolean;
  theme?: "black" | "white";
};

const OnlineItemList: FC<Props> = ({
  dimensions,
  loadingSkeletons,
  containerStyles,
  videoList,
  theme,
  isLoading,
}) => {
  const rootStyle = useMemo(() => {
    return combineStyles(styles.container, containerStyles);
  }, [containerStyles]);

  return (
    <Grid
      container
      rowGap={{
        xs: 4,
        lg: 2,
        xl: 2,
      }}
      sx={rootStyle}
    >
      {isLoading || !videoList ? (
        <SkeletonCards
          xs={12}
          sm={10}
          md={6}
          lg={3.7}
          xl={3}
          {...loadingSkeletons}
        />
      ) : (
        videoList &&
        videoList.length > 0 &&
        videoList
          .filter((entry) => entry.status == UploadedFileStatus.ACCEPTED)
          .map((entry: VideoServiceResponse) => (
            <Grid
              key={entry.id}
              item
              xs={12}
              sm={10}
              md={6}
              lg={4}
              xl={3}
              {...dimensions}
            >
              <OnlineCardListItem theme={theme} {...entry} />
            </Grid>
          ))
      )}
    </Grid>
  );
};

export default OnlineItemList;
