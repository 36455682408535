import { SxProps } from "@mui/material";
import color_pallette from "theme/color_pallette";

const styles: Record<string, SxProps> = {
  container: {
    marginTop: 2,
  },
  digitContainer: {
    backgroundColor: color_pallette.lightGray,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 2,
    width: { xs: 60, sm: 90, md: 90, lg: 90 },
    height: { xs: 70, sm: 100, md: 90, lg: 100 },
    color: color_pallette.primary,
  },
  digitLabel: {
    fontSize: {
      sm: 35,
      lg: 45,
    },
  },
};

export default styles;
