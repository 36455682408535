import { Methods } from "types/http";
import { bookmarkUrls } from "./urls";
import { Bookmark, isBookmarkedRequest } from "./types";
import { GetById } from "../types";
import { MEDIA_TYPE } from "types/media_type";
import { Identification } from "types/identification";

export const submitBookmark = (param: { body: Bookmark; token: string }) => ({
  url: bookmarkUrls.postBookmark(),
  method: Methods.POST,
  body: param.body,
  headers: {
    Lng: "EN",
    Accept: "*/*",
    "Content-type": "application/json",
    Authorization: `Bearer ${param.token}`,
  },
});

export const getBookmarkCount = (param: GetById) => ({
  url: bookmarkUrls.getVideoBookmarsCount(param.id),
  method: Methods.GET,
  headers: {
    Lng: "EN",
    Accept: "*/*",
    "Content-type": "application/json",
    Authorization: `Bearer ${param.token}`,
  },
});

export const getMediaBookmarkCount = (param: {
  id: Identification;
  media: MEDIA_TYPE;
  token: string;
}) => ({
  url: bookmarkUrls.getMediaBookmarsCount(param),
  method: Methods.GET,
  headers: {
    Lng: "EN",
    Accept: "*/*",
    "Content-type": "application/json",
    Authorization: `Bearer ${param.token}`,
  },
});

export const getBookmarksDetails = (param: GetById) => ({
  url: bookmarkUrls.getVideoBookmarksDetails(param.id),
  method: Methods.GET,
  headers: {
    Lng: "EN",
    Accept: "*/*",
    "Content-type": "application/json",
    Authorization: `Bearer ${param.token}`,
  },
});

export const getUserBookmarksList = (param: GetById) => ({
  url: bookmarkUrls.getUserBookmarks(param.id),
  method: Methods.GET,
  headers: {
    Lng: "EN",
    Accept: "*/*",
    "Content-type": "application/json",
    Authorization: `Bearer ${param.token}`,
  },
});

export const getUserBookmarksCount = (param: GetById) => ({
  url: bookmarkUrls.getUserBookmarksCount(param.id),
  method: Methods.GET,
  headers: {
    Lng: "EN",
    Accept: "*/*",
    "Content-type": "application/json",
    Authorization: `Bearer ${param.token}`,
  },
});

export const getIsBookmarked = (param: isBookmarkedRequest) => ({
  url: bookmarkUrls.isBookmarked(param),
  method: Methods.GET,
  headers: {
    Lng: "EN",
    Accept: "*/*",
    "Content-type": "application/json",
    Authorization: `Bearer ${param.token}`,
  },
});
