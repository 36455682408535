import { SxProps } from "@mui/material";
import color_pallette from "theme/color_pallette";

const styles: Record<string, SxProps> = {
  container: {
    width: {
      xs: "90%",
      lg: "75%",
      xl: "61%",
    },
    marginX: "auto",
    marginBottom: 2,
  },
  path: {
    cursor: "pointer",
    fontWeight: "500",
    color: color_pallette.primary,
  },
};

export default styles;
