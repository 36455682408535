import { FC } from "react";
import { NavigationRoutes } from "./routes";
import Home from "pages/home";
import JoinUs from "pages/join_us";
import RegisterPage from "pages/register_page";
import LoginPage from "pages/login_page";
import ExploreSpeakers from "pages/explore_speakers";
import ExploreOnlineVideos from "pages/explore_online_videos";
import PoliceSpeakers from "pages/explore_police_speakers";
import ExplorePodcasts from "pages/explore_podcasts";
import MyVideos from "pages/my_videos";
import OnlineVideoDetails from "pages/online_video_details";
import PodcastDetails from "pages/podcast_details";
import PublicViewProfile from "pages/public_view_profile";
import ViewAdminProfile from "pages/view_admin_profile";
import withNavigationHeaders from "hoc/withScreenHeaders";
import PoliceOnlineVideos from "pages/police_online_videos";
import ExploreUpcomingPodcasts from "pages/explore_upcoming_podcasts";
import AboutUs from "pages/about_us";
import Blog from "pages/blog";
import withCloseSnack from "hoc/withCloseSnack";
import MyBookmarks from "pages/my_bookmarks";
import MyNotifications from "pages/my_notifications";
import Nominations from "pages/nominations";
import NominationForm from "pages/nomination_form";
import StreamingOnlinePage from "pages/streaming_online_page";
import UpcomingDetails from "pages/upcoming_details";
import StreamingPodcastPage from "pages/streaming_podcast_page";
import ExploreInteractive from "pages/explore_interactive";
import SearchPage from "pages/search_page";
import OtpConfirmationPage from "pages/otp_confirmation_page";
import InsideLiveTips from "pages/inside_live_tips";
import NominationScope from "pages/nomination_scope";
import OutsideLiveTips from "pages/outside_live_tips";
import BlogDetails from "pages/blog_details";
import MyJoiningList from "pages/my_joining_list";
import MyAlerts from "pages/my_alerts";
import ResetPassword from "pages/pre_reset_password";
import ResetPwd from "pages/reset_pwd";
import NotFound from "pages/not_found";
import withAuth from "hoc/withAuth";
import withPrivilage from "hoc/withPrivilage";
import withRTL from "hoc/withRtl";

type Route = {
  id: string;
  Element: FC;
  path: NavigationRoutes | string;
};

export const pages: Array<Route> = [
  {
    id: "home",
    Element: withCloseSnack(withNavigationHeaders(withRTL(Home))),
    path: NavigationRoutes.home,
  },
  {
    id: "nomination scope",
    Element: withCloseSnack(
      withNavigationHeaders(withAuth(withRTL(NominationScope)))
    ),
    path: NavigationRoutes.nominationScope,
  },
  {
    id: "joining list",
    Element: withCloseSnack(
      withNavigationHeaders(withAuth(withRTL(MyJoiningList)))
    ),
    path: NavigationRoutes.joining_list,
  },
  {
    id: "Local Nomination",
    Element: withCloseSnack(
      withNavigationHeaders(withAuth(withRTL(InsideLiveTips)))
    ),
    path: NavigationRoutes.insideLiveTips,
  },
  {
    id: "Blog Details",
    Element: withCloseSnack(withNavigationHeaders(withAuth(BlogDetails))),
    path: `${NavigationRoutes.blog}/:id`,
  },
  {
    id: "otp",
    Element: withCloseSnack(
      withNavigationHeaders(withRTL(OtpConfirmationPage))
    ),
    path: NavigationRoutes.otp_confirmation,
  },
  {
    id: "search",
    Element: withCloseSnack(withNavigationHeaders(withRTL(SearchPage))),
    path: NavigationRoutes.search,
  },
  {
    id: "joinus",
    Element: withCloseSnack(withNavigationHeaders(withAuth(withRTL(JoinUs)))),
    path: NavigationRoutes.joinUs,
  },
  {
    id: "signup",
    Element: withCloseSnack(withNavigationHeaders(withRTL(RegisterPage))),
    path: NavigationRoutes.signup,
  },
  {
    id: "login",
    Element: withCloseSnack(withNavigationHeaders(withRTL(LoginPage))),
    path: NavigationRoutes.login,
  },
  {
    id: "speakers",
    Element: withCloseSnack(withNavigationHeaders(withRTL(ExploreSpeakers))),
    path: NavigationRoutes.speakers,
  },
  {
    id: "service_online_video",
    Element: withCloseSnack(
      withNavigationHeaders(withRTL(ExploreOnlineVideos))
    ),
    path: NavigationRoutes.services_online_video,
  },
  {
    id: "police_speakers",
    Element: withCloseSnack(
      withNavigationHeaders(withPrivilage(withRTL(PoliceSpeakers)))
    ),
    path: NavigationRoutes.police_speakers,
  },
  {
    id: "podcasts",
    Element: withCloseSnack(withNavigationHeaders(withRTL(ExplorePodcasts))),
    path: NavigationRoutes.podcasts,
  },
  {
    id: "myVideos",
    Element: withCloseSnack(withNavigationHeaders(withAuth(withRTL(MyVideos)))),
    path: `${NavigationRoutes.myVideos}/:id?`,
  },
  {
    id: "services_online_video_id",
    Element: withCloseSnack(withNavigationHeaders(withRTL(OnlineVideoDetails))),
    path: `${NavigationRoutes.services_online_video}/:id`,
  },
  {
    id: "podcasts_id",
    Element: withCloseSnack(withNavigationHeaders(withRTL(PodcastDetails))),
    path: `${NavigationRoutes.podcasts}/:id`,
  },
  {
    id: "public_speaker_profile_id",
    Element: withCloseSnack(withNavigationHeaders(withRTL(PublicViewProfile))),
    path: `${NavigationRoutes.public_speaker_profile}/:id`,
  },
  {
    id: "profile",
    Element: withCloseSnack(
      withNavigationHeaders(withAuth(withRTL(ViewAdminProfile)))
    ),
    path: NavigationRoutes.profile,
  },
  {
    id: "police_online_videos",
    Element: withCloseSnack(
      withNavigationHeaders(withPrivilage(withRTL(PoliceOnlineVideos)))
    ),
    path: NavigationRoutes.police_online_videos,
  },
  {
    id: "upcomingPodcasts",
    Element: withCloseSnack(
      withNavigationHeaders(withRTL(ExploreUpcomingPodcasts))
    ),
    path: NavigationRoutes.upcomingPodcasts,
  },
  {
    id: "upcomingPodcasts",
    Element: withCloseSnack(withNavigationHeaders(withAuth(withRTL(MyAlerts)))),
    path: NavigationRoutes.alerts,
  },
  {
    id: "finalResetPwd",
    Element: withCloseSnack(withNavigationHeaders(withRTL(ResetPwd))),
    path: NavigationRoutes.finalResetPwd,
  },
  {
    id: "aboutUs",
    Element: withCloseSnack(withNavigationHeaders(AboutUs)),
    path: NavigationRoutes.aboutUs,
  },
  {
    id: "forgotPassword",
    Element: withCloseSnack(withNavigationHeaders(withRTL(ResetPassword))),
    path: NavigationRoutes.forgotPassword,
  },
  {
    id: "my_bookmarks",
    Element: withCloseSnack(
      withNavigationHeaders(withAuth(withRTL(MyBookmarks)))
    ),
    path: NavigationRoutes.myBookmarks,
  },
  {
    id: "nominations",
    Element: withCloseSnack(
      withNavigationHeaders(withAuth(withRTL(NominationScope)))
    ),
    path: NavigationRoutes.nominations,
  },
  {
    id: "interactive",
    Element: withCloseSnack(withNavigationHeaders(withRTL(ExploreInteractive))),
    path: NavigationRoutes.interactive,
  },
  {
    id: "nominations",
    Element: withCloseSnack(
      withNavigationHeaders(withAuth(withRTL(Nominations)))
    ),
    path: NavigationRoutes.nominations,
  },
  {
    id: "nominations_outside",
    Element: withCloseSnack(
      withNavigationHeaders(withAuth(withRTL(OutsideLiveTips)))
    ),
    path: NavigationRoutes.outsideLiveTips,
  },
  {
    id: "my_notifications",
    Element: withCloseSnack(
      withNavigationHeaders(withAuth(withRTL(MyNotifications)))
    ),
    path: NavigationRoutes.myNotifications,
  },
  {
    id: "blog",
    Element: withCloseSnack(withNavigationHeaders(Blog)),
    path: NavigationRoutes.blog,
  },
  {
    id: "nomination_form",
    Element: withCloseSnack(
      withNavigationHeaders(withAuth(withRTL(NominationForm)))
    ),
    path: NavigationRoutes.nomination_form,
  },
  {
    id: "streaming_online_page",
    Element: withCloseSnack(StreamingOnlinePage),
    path: `${NavigationRoutes.streaming_online_page}/:id`,
  },
  {
    id: "streaming_podcast_page",
    Element: withCloseSnack(StreamingPodcastPage),
    path: `${NavigationRoutes.streaming_podcast_page}/:id`,
  },
  {
    id: "upcoming_details",
    Element: withNavigationHeaders(withAuth(withRTL(UpcomingDetails))),
    path: `${NavigationRoutes.upcoming_details}/:id`,
  },
  {
    id: "not_found",
    Element: withCloseSnack(NotFound),
    path: NavigationRoutes.not_found,
  },
];
