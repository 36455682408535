import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    display: "flex",
    flexDirection: {
      xs: "column",
      md: "row",
    },
    justifyContent: {
      xs: "space-between",
      lg: "flex-start",
    },
    alignItems: {
      xs: "center",
      md: "flex-start",
    },
    columnGap: { xs: 3, lg: 0 },
  },
  footerContainer: {
    width: { xs: "80%", sm: "90%", lg: "100%", xl: "90%" },
    marginTop: 5,
    marginX: { xs: "auto", md: "10px" },
  },
  gridContainer: {
    maxWidth: { xs: "90%", lg: "100%", xl: "100%" },
    marginTop: 5,
    rowGap: { xl: 3 },
  },
  upcomingContainer: {
    width: { md: "35%", sm: "60%", xs: "90%", xl: "30%", lg: "50%" },
    marginTop: 5,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  stackWrapper: {
    display: "flex",
    flexDirection: {
      xs: "column",
      lg: "row",
    },
    marginX: { xs: "auto", md: "10px" },
    justifyContent: { xs: "space-between", sm: "center", lg: "flex-start" },
    width: { xs: "80%", sm: "90%", lg: "100%", xl: 1500 },
  },
};

export default styles;
