import { useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { sidebarSliceActions } from "store/sidebar_slice";

const useRate = () => {
  const dispatch = useAppDispatch();

  const [visible, setVisible] = useState<boolean>(false);

  const selected = useAppSelector((state) => state.sidebar.rateFilter);

  const handleRateChange = (__: Event, newValue: number | number[]) => {
    dispatch(sidebarSliceActions.setRateFilter(newValue as number));
  };

  return { visible, setVisible, handleRateChange, selected };
};
 
export default useRate;
