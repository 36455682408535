import { Methods } from "types/http";
import apiUrls from "../urls";
import { Token } from "../podcast_api/types";
import {
  Comment,
  CommentPostBody,
  MinimalUser,
} from "store/api/comment_service/types";
import { GetById } from "../types";
import { MEDIA_TYPE } from "types/media_type";

export const getComments = (body: Token) => ({
  url: apiUrls.getComments(),
  method: Methods.GET,
  headers: {
    Lng: "EN",
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${body.token}`,
  },
});

export const updateComment = (param: { token: string; body: Comment }) => ({
  url: apiUrls.updateComment(),
  method: Methods.PUT,
  body: param.body,
  headers: {
    Lng: "EN",
    Accept: "application/json",
    Authorization: `Bearer ${param.token}`,
  },
});

export const postComment = (param: {
  token: string;
  body: Comment<MinimalUser> | CommentPostBody;
}) => ({
  url: apiUrls.postComment(),
  method: Methods.POST,
  body: param.body,
  headers: {
    Lng: "EN",
    Accept: "application/json",
    Authorization: `Bearer ${param.token}`,
  },
});

export const getCommentById = (param: GetById) => ({
  url: apiUrls.commentById(param.id),
  method: Methods.GET,
  headers: {
    Lng: "EN",
    Accept: "application/json",
    Authorization: `Bearer ${param.token}`,
  },
});

export const deleteCommentById = (param: GetById) => ({
  url: apiUrls.commentById(param.id),
  method: Methods.DELETE,
  headers: {
    Lng: "EN",
    Accept: "application/json",
    Authorization: `Bearer ${param.token}`,
  },
});

export const likeComment = (param: { comment: Comment }, token: string) => ({
  url: apiUrls.likeComment(),
  method: Methods.POST,
  body: param,
  headers: {
    Lng: "EN",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  },
});

export const getVideoComments = ({
  creds,
  type,
}: {
  creds: GetById;
  type: MEDIA_TYPE;
}) => ({
  url: apiUrls.getVideoComments(creds.id, type),
  method: Methods.GET,
  headers: {
    Lng: "EN",
    Accept: "application/json",
    Authorization: `Bearer ${creds.token}`,
  },
});

export const getUserComments = (body: GetById) => ({
  url: apiUrls.getUserComments(body.id),
  method: Methods.GET,
  headers: {
    Lng: "EN",
    Accept: "application/json",
    Authorization: `Bearer ${body.token}`,
  },
});

export const getProfileComments = (body: GetById) => ({
  url: apiUrls.getProfileComments(body.id),
  method: Methods.GET,
  headers: {
    Lng: "EN",
    Accept: "application/json",
    Authorization: `Bearer ${body.token}`,
  },
});