import { FC } from "react";
import { Stack } from "@mui/material";
import styles from "./styles";
import { ReactSVG } from "react-svg";
import Button from "components/custom_button";
import share from "assets/svgs/share.svg";
import save from "assets/svgs/save.svg";
import VideoRate from "pages/online_video_details/video_rate";

const IconSection: FC = () => {
  return (
    <Stack sx={styles.iconContainer}>
      <>
        <VideoRate />
        <ReactSVG style={{ cursor: "pointer" }} src={share} />
        <Button
          label="Share"
          variant="outlined"
          extraStyles={{
            ...styles.shareButton,
          }}
        />
      </>
      <>
        <ReactSVG style={{ cursor: "pointer" }} src={save} />
        <Button
          label="Save"
          variant="outlined"
          extraStyles={styles.shareButton}
        />
      </>
    </Stack>
  );
};

export default IconSection;
