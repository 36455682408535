import { FC } from "react";
import PodcastWrapper from "components/podcast_wrapper";
import { useIntl } from "react-intl";
import messages from "../messages";
import { UploadedFileStatus } from "types/uploaded_file_status";
import { UploadPrivacy } from "types/upload_privacy";

const TrendingSection: FC = () => {
  const intl = useIntl();
  return (
    <PodcastWrapper
      sectionLabel={intl.formatMessage(messages.Trending)}
      dimensions={{
        xs: 12,
        sm: 6,
        lg: 5.8,
        xl: 5,
      }}
      gridContainerProps={{ columnGap: 1 }}
      videoQuery={{
        status: UploadedFileStatus.ACCEPTED,
        privacy: UploadPrivacy.PUBLIC,
      }}
      limit={[0, 2]}
    />
  );
};

export default TrendingSection;
