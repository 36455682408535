import { defineMessages } from "react-intl";

const scope = "ResetPassword";

export default defineMessages({
  reset: {
    id: `${scope}.reset`,
    defaultMessage: "Reset Password",
  },
});
