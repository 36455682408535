import { SxProps } from "@mui/material";
import color_pallette from "theme/color_pallette";

const styles: Record<string, SxProps> = {
  captionText: {
    fontWeight: "600",
    color: "white",
    padding: 1,
    fontSize: {
      xs: 14,
      lg: 10,
      xl: 12,
    },
    lineHeight: 1.3,
  },
  rating: { marginInline: 0.5 },
  avatarImg: { width: 30, height: 30 },
  username: { marginInlineStart: 1, fontWeight: "600" },
  imgWrapper: {
    position: "relative",
  },
  stats: { fontSize: 13, fontWeight: "400", whiteSpace: "nowrap" },
  img: {
    borderRadius: "10px",
    aspectRatio: "16 / 9",
    height: 190,
  },
  desc: {
    marginTop: 0.5,
    lineHeight: 1.2,
    fontFamily: "Nizzar ,K2D, Inter",
    fontSize: 12,
    textAlign: "start",
  },
  footer: {
    justifyContent: {
      xs: "flex-start",
      md: "space-around",
    },
    rowGap: {
      xs: 0,
      sm: 1,
    },
    columnGap: {
      xs: 1.5,
      sm: 1.5,
      lg: 0.8,
    },
    marginTop: { xs: 1, lg: 0.5 },
    flexWrap: {
      xs: "no-wrap",
      sm: "wrap",
      lg: "nowrap",
    },
  },
  thumbContainer: {
    width: {
      xs: "65%",
      md: "90%",
      lg: "80%",
      xl: "80%",
    },
    borderBottomLeftRadius: "10px",
    borderTopRightRadius: "10px",
    backgroundColor: color_pallette.transparent_green,
    maxHeight: "50%",
    zIndex: 1,
    position: "absolute",
    left: 0,
    bottom: 4.1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  container: {
    display: "flex",
    width: {
      xs: "100%",
      sm: "80%",
      lg: 350,
    },
    justifyContent: "flex-start",
    paddingBottom: 1,
    paddingX: 1,
    flexDirection: "column",
    cursor: "pointer",
  },
  captionFont: {
    fontSize: "10px",
    color: "#000",
  },
  font: {
    fontSize: "14px",
    fontWeight: "700",
    color: "#000",
  },
  avatar: { width: 24, height: 24 },
  thumbnailTitle: {
    color: "white",
    width: "100%",
    fontWeight: "700",
    fontSize: 21,
    lineHeight: 1.2,
  },
  position: { position: "relative" },
  absolutePosition: {
    zIndex: 10,
    width: "70%",
    height: 50,
    position: "absolute",
    backgroundColor: "#4A6A7BE5",
    bottom: 6,
    left: 0,
    padding: 1,
    borderTopRightRadius: 6,
  },
};

export default styles;
