export const enum OnlineFormProperties {
  CITY = "city",
  COUNTRY = "country",
  GENDER = "gender",
  SPEAKER_CLASS = "category",
  WEB_LINKS = "article_url",
  WEB_LINKS_FILE = "article_file",
  CONSENTED = "consented",
  UPLOADED_FILE = "identity",
  DESCRIPTION = "bio",
}
