import { FC } from "react";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import styles from "./styles";
import Button from "components/custom_button";
import red_logo from "assets/images/dubai_gov_red_mobile.png";
import police from "assets/images/police_mobile.png";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useNavigate } from "react-router-dom";
import { globalSliceActions } from "store/global_slice";
import { NavigationRoutes } from "navigation/routes";

const UserProfileSection: FC = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { credentials, speakerProfile } = useAppSelector(
    (state) => state.global
  );

  const { access_token } = credentials.token;

  const handleSignout = () => {
    dispatch(globalSliceActions.signout());
    dispatch(globalSliceActions.clearStorage());
  };

  const handleRegister = () => {
    navigate(NavigationRoutes.signup);
  };

  const handleLogin = () => {
    navigate(NavigationRoutes.login);
  };

  const handleViewProfile = () => {
    navigate(NavigationRoutes.profile);
  };

  return (
    <Box sx={styles.container}>
      {access_token && (
        <Stack
          onClick={handleViewProfile}
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Avatar src={speakerProfile.profilePicture} sx={styles.avatar} />
          <Box sx={styles.accountWrapper}>
            <Typography sx={styles.username}>{speakerProfile.name}</Typography>
            <Typography sx={styles.email}>{speakerProfile.email}</Typography>
          </Box>
        </Stack>
      )}
      <Divider sx={styles.divider} />
      {!access_token ? (
        <Stack
          columnGap={5}
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Button
            extraStyles={styles.registerButton}
            onClick={handleRegister}
            label="Sign up"
          />
          <Typography sx={styles.cursor} onClick={handleLogin}>
            Login
          </Typography>
        </Stack>
      ) : (
        <Button
          extraStyles={styles.signout}
          onClick={handleSignout}
          label="Sign out"
        />
      )}
      <Stack
        marginTop={5}
        columnGap={5}
        justifyContent="center"
        direction="row"
        alignItems="center"
      >
        <Box
          component="img"
          sx={{ objectFit: "contain", width: 100 }}
          src={police}
        />
        <Box
          component="img"
          sx={{ objectFit: "contain", width: 100 }}
          src={red_logo}
        />
      </Stack>
    </Box>
  );
};

export default UserProfileSection;
