import { FC } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import UpcomingPodcastListItem from "components/upcoming_podcast_list_item";
import styles from "./styles";
import { useGetLiveStreamingsQuery } from "store/api/streaming_service/endpoints";
import { UploadPrivacy } from "types/upload_privacy";
import { UploadedFileStatus } from "types/uploaded_file_status";
import { useAppSelector } from "store/hooks";
import { FormattedMessage } from "react-intl";
import messages from "../messages";

const UpcomingSection: FC = () => {
  const token = useAppSelector(
    (state) => state.global.credentials.token.access_token
  );

  const { data } = useGetLiveStreamingsQuery({
    query: {
      status: UploadedFileStatus.ACCEPTED,
      privacy: UploadPrivacy.PUBLIC,
    },
    token,
  });

  return (
    <>
      <Typography sx={styles.header}>
        <span style={{ fontWeight: "700", letterSpacing: 1.3 }}>
          <FormattedMessage id={messages.upcoming.id} />
        </span>{" "}
        <FormattedMessage id={messages.this_week.id} />
      </Typography>
      {data &&
        data.results &&
        data.results.length > 0 &&
        data.results.slice(0, 2).map((entry) => (
          <Grid
            key={entry.id}
            marginBlock={2}
            marginX="auto"
            item
            xs={12}
            xl={5.3}
          >
            <UpcomingPodcastListItem {...entry} />
          </Grid>
        ))}
    </>
  );
};

export default UpcomingSection;
