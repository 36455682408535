import {
  // RemoteUser,
  useJoin,
  useLocalCameraTrack,
  useLocalMicrophoneTrack,
  usePublish,
  useRTCClient,
  useRemoteUsers,
  // useClientEvent,
  LocalUser,
  useRemoteVideoTracks,
  useRemoteAudioTracks,
} from "agora-rtc-react";
import React, { createContext, FC, useContext, useEffect } from "react";
import { IMicrophoneAudioTrack, ICameraVideoTrack } from "agora-rtc-sdk-ng";
import {
  CircularProgress,
  Grow,
  Modal,
  Paper,
  Typography,
} from "@mui/material";
import styles from "./styles";
// import color_pallette from "theme/color_pallette";
import { Upcoming } from "store/api/streaming_service/types";
// import useSnack from "hooks/useSnack";
import { FormattedMessage } from "react-intl";
import messages from "./messages";
import VideoGrid from "components/video_grid";
import { useAppSelector } from "store/hooks";

type configType = {
  appId: string;
  channelName: string;
  rtcToken: string;
  selectedProduct: "live";
  uid: number;
  upcoming: Upcoming;
};

interface AgoraContextType {
  localCameraTrack: ICameraVideoTrack | null;
  localMicrophoneTrack: IMicrophoneAudioTrack | null;
  children: React.ReactNode;
}

const AgoraContext = createContext<AgoraContextType | null>(null);

export const AgoraProvider: React.FC<AgoraContextType> = ({
  children,
  localCameraTrack,
  localMicrophoneTrack,
}) => (
  <AgoraContext.Provider
    value={{ localCameraTrack, localMicrophoneTrack, children }}
  >
    {children}
  </AgoraContext.Provider>
);

export const useAgoraContext = () => {
  const context = useContext(AgoraContext);
  if (!context)
    throw new Error("useAgoraContext must be used within an AgoraProvider");
  return context;
};

type Props = {
  config: configType;
  children?: React.ReactNode;
  type: "audio" | "video";
};

export const AgoraManager: FC<Props> = ({ config, type, children }) => {
  const { audio, video } = useAppSelector((state) => state.livetipsInteractive);

  const agoraEngine = useRTCClient();

  useJoin(
    {
      appid: config.appId,
      channel: config.channelName,
      token: config.rtcToken,
      uid: config.uid,
    },
    true,
    agoraEngine
  );

  const remoteUsers = useRemoteUsers(agoraEngine);

  const { videoTracks } = useRemoteVideoTracks(remoteUsers);
  const { audioTracks } = useRemoteAudioTracks(remoteUsers);
  audioTracks.map((track) => track.play());
  videoTracks.map((track, index) =>
    track.play(`remote-user-${index}`, { fit: "cover" })
  );

  const { isLoading: isLoadingCam, localCameraTrack } = useLocalCameraTrack(
    true,
    undefined,
    agoraEngine
  );

  const { isLoading: isLoadingMic, localMicrophoneTrack } =
    useLocalMicrophoneTrack(true, undefined, agoraEngine);

  usePublish([localMicrophoneTrack, localCameraTrack], true, agoraEngine);

  useEffect(() => {
    return () => {
      agoraEngine.localTracks?.forEach((track) => {
        track.close();
      });
      agoraEngine.leave();
    };
  }, []);

  const deviceLoading = isLoadingMic || isLoadingCam;

  if (deviceLoading || !config.rtcToken)
    return (
      <Modal
        open
        sx={{
          width: 500,
          height: 300,
          marginX: "auto",
          marginTop: 20,
          overflow: "hidden",
        }}
      >
        <Grow in timeout={600}>
          <Paper sx={styles.loadingModal}>
            <CircularProgress />
            <Typography>
              <FormattedMessage id={messages.loading.id} />
            </Typography>
          </Paper>
        </Grow>
      </Modal>
    );

  return (
    <AgoraProvider
      localCameraTrack={localCameraTrack as any}
      localMicrophoneTrack={localMicrophoneTrack as any}
    >
      {children}
      <VideoGrid
        localUser={
          <LocalUser
            audioTrack={localMicrophoneTrack}
            videoTrack={localCameraTrack}
            allowFullScreen
            className="video-tile"
            cameraOn
            micOn
            playAudio={audio}
            playVideo={type == "video" ? video : false}
          />
        }
        remoteUsers={remoteUsers}
      />
    </AgoraProvider>
  );
};

export default AgoraManager;