import { FC } from "react";
import { Box, Grow, LinearProgress, Typography } from "@mui/material";
import color_pallette from "theme/color_pallette";

type Props = {
  isLoading: boolean;
};

const UploadProgress: FC<Props> = ({ isLoading = false }) => {
  return (
    <Grow in={isLoading} timeout={700}>
      <Box
        sx={{
          borderRadius: 3,
          backgroundColor: color_pallette.primary,
          paddingBlock: 0.5,
          paddingInline: 5,
        }}
      >
        <Typography sx={{ color: "white" }}>
          Uploading Video in progress
        </Typography>
        <LinearProgress sx={{ marginBlock: 0.5 }} />
      </Box>
    </Grow>
  );
};

export default UploadProgress;
