import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    height: 815,
    width: {
      lg: "25%",
      xl: "30%",
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    overflow: "hidden",
    borderRadius: 15,
    boxShadow: 2,
    rowGap: 5,
    paddingBlock: 3,
  },
  footer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    textAlign: "center",
    rowGap: 2,
  },
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: {
      xs: "space-around",
    },
    textAlign: "center",
    rowGap: 2,
    width: "80%",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: {
      xs: "space-around",
      sm: "center",
    },
    columnGap: {
      sm: 5,
      lg: 1.5,
      xl: 2,
    },
    width: "90%",
    paddingBlock: 2,
  },
};

export default styles;
