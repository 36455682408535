import { FC } from "react";
import Stack from "@mui/material/Stack";
import styles from "./styles";
import Button from "components/custom_button";
import VideoRate from "pages/online_video_details/video_rate";
import useBookmark from "hooks/useBookmark";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import ReplyIcon from "@mui/icons-material/Reply";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { modalSliceActions } from "store/modal_slice";
import { LivetipsOnlineSliceActions } from "store/livetips_online_slice";
import { useAddShareMutation } from "store/api/share_service/endpoints";
import useSnack from "hooks/useSnack";
import SnackBar from "components/snack_bar";
import { useIntl } from "react-intl";
import messages from "../messages";
import { MEDIA_TYPE } from "types/media_type";

const ActionSection: FC = () => {
  const { bookmarkVideo } = useBookmark();

  const { triggerSnack } = useSnack();

  const dispatch = useAppDispatch();

  const {
    global: { credentials, speakerProfile },
    livetipsOnline: { currentVideo },
  } = useAppSelector((state) => state);

  const handleBookmarkVideo = async () => {
    if (!credentials.token.access_token) {
      triggerSnack("You Must Login !", "error");
      return;
    }
    try {
      await bookmarkVideo();
      dispatch(LivetipsOnlineSliceActions.updateIsBookmarked());
      triggerSnack("Video Saved Successfully !", "success");
    } catch (error) {
      dispatch(LivetipsOnlineSliceActions.updateIsBookmarked());
    }
  };

  const [shareVideo] = useAddShareMutation();

  const handleShareVideo = async () => {
    if (!credentials.token.access_token) {
      triggerSnack("You Must Login !", "error");
      return;
    }
    dispatch(LivetipsOnlineSliceActions.shareVideo());
    dispatch(modalSliceActions.setSharedContent(currentVideo.media));
    try {
      await shareVideo({
        token: credentials.token.access_token,
        body: {
          ownerId: currentVideo.user.customerId,
          userId: speakerProfile.customerId,
          digitalMediumType: MEDIA_TYPE.ON_DEMAND_VIDEO,
          videoId: currentVideo.id,
        },
      });
      dispatch(modalSliceActions.shareVideoStep(true));
    } catch (error) {
      dispatch(LivetipsOnlineSliceActions.revertShareVideo());
    }
  };

  const intl = useIntl();

  return (
    <Stack sx={styles.iconContainer}>
      <>
        <VideoRate />
        <ReplyIcon sx={styles.icon} />
        <SnackBar />
        <Button
          label={intl.formatMessage(messages.share)}
          variant="outlined"
          onClick={handleShareVideo}
          extraStyles={styles.shareButton}
        />
      </>
      <>
        {currentVideo.is_bookmarked ? (
          <BookmarkIcon sx={styles.icon} />
        ) : (
          <BookmarkBorderIcon onClick={handleBookmarkVideo} sx={styles.icon} />
        )}
        <Button
          label={intl.formatMessage(messages.save)}
          variant="outlined"
          onClick={handleBookmarkVideo}
          extraStyles={styles.shareButton}
        />
      </>
    </Stack>
  );
};

export default ActionSection;
