import { FC } from "react";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import styles from "./styles";
import avatar from "assets/images/placeholders/thumbnail_pl.webp";
import usePodcastDetails from "hooks/use_podcast_details";
import MetaSection from "./meta_section";
import ActionSection from "./action_section";
import PodcastItem from "types/podcast_item";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "navigation/routes";
import ScreenNavigationTracker from "components/screen_navigation_tracker";
import { data } from "./data";

type Props = {
  podcastDetails: PodcastItem;
};

const BodySection: FC<Props> = ({ podcastDetails }) => {
  const navigate = useNavigate();

  const helper = usePodcastDetails();

  const handleViewProfile = () => {
    navigate(
      `${NavigationRoutes.public_speaker_profile}/${podcastDetails.user.customerId}`
    );
  };

  return (
    <Box sx={styles.root}>
      <Box sx={styles.path}>
        <ScreenNavigationTracker
          path={[
            ...data,
            {
              label: podcastDetails.user.name,
              path: `${NavigationRoutes.public_speaker_profile}/${podcastDetails.user.customerId}`,
            },
          ]}
          extraStyles={{ color: "white" }}
        />
      </Box>
      <Box sx={styles.container}>
        <Stack sx={styles.wrapper}>
          <MetaSection
            profileId={podcastDetails.user.customerId}
            thumb={podcastDetails.thumbnail}
          />
          <Stack rowGap={2} sx={styles.contentContainer}>
            <Stack
              onClick={() => handleViewProfile()}
              direction="row"
              sx={{ cursor: "pointer" }}
              columnGap={1}
              alignItems="center"
            >
              <Avatar
                src={podcastDetails.user.profilePicture ?? avatar}
                sx={styles.avatar}
              />
              <Typography sx={{ ...styles.username, color: "white" }}>
                {podcastDetails.user.name}
              </Typography>
            </Stack>
            <Typography sx={{ ...styles.subTitle, color: "white" }}>
              {podcastDetails.user.org}
            </Typography>
            <Typography sx={{ ...styles.caption, color: "white" }}>
              {podcastDetails?.title}
            </Typography>
            <ActionSection
              playing={helper?.storedRecord?.playing}
              podcastId={podcastDetails.id}
              duration={`${(Math.ceil(helper?.duration) / 60).toPrecision(
                2
              )} Mins`}
              handleToggleAudio={helper?.handleToggleAudio}
            />
            <Typography sx={{ ...styles.description, color: "white" }}>
              {podcastDetails?.description}
            </Typography>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};

export default BodySection;
