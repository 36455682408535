import { FC } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import styles from "./styles";
import FilterComponent from "components/filter_component";
import data from "./data";
import { FormattedMessage } from "react-intl";
import messages from "../messages";
/* import RecentSearch from "components/recent_search"; */

const SidebarSection: FC = () => {
  return (
    <Paper sx={styles.container}>
      {/* <Typography sx={styles.label}>Recent Searches</Typography> */}
      {/* {data.filters.map((entry) => (
        <Box sx={{ marginBlock: 1.5 }} key={entry.id}>
          <RecentSearch value={entry.value} />
        </Box>
      ))} */}
      <Typography sx={{ ...styles.label, marginBottom: 1.5 }}>
        <FormattedMessage id={messages.sort.id} />
      </Typography>
      <Divider />
      {data.recentSearches.map((entry) => (
        <Box sx={{ marginBlock: 1.5 }} key={entry.id}>
          <FilterComponent items={entry.items} filterValue={entry.value} />
        </Box>
      ))}
    </Paper>
  );
};

export default SidebarSection;
