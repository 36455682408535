import { createSlice } from "@reduxjs/toolkit";
import HeroCard from "types/hero_card";
import data from "./data";

type State = {
  data: Array<HeroCard>;
};

const initialState: State = {
  data,
};

const heroSlice = createSlice({
  name: "hero",
  initialState,
  reducers: {},
});

export default heroSlice.reducer;
export const heroSliceActions = heroSlice.actions;
