import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Bookmark } from "store/api/bookmark_service/types";
import { OPTIMISTIC_COMMENT_ID } from "store/comment_slice";
import { Identification } from "types/identification";
import { MEDIA_TYPE } from "types/media_type";

type State = {
  online: Array<Bookmark>;
  blog: Array<Bookmark>;
  podcast: Array<Bookmark>;
  interactive: Array<Bookmark>;
};

const initialState: State = {
  online: [],
  podcast: [],
  interactive: [],
  blog: [],
};

const bookmarkSlice = createSlice({
  name: "bookmark_slice",
  initialState,
  reducers: {
    pushBookmarks: (
      state: State,
      { payload }: PayloadAction<Array<Bookmark>>
    ) => {
      const filteredData = payload.filter(
        (entry) => entry.digitalMediumType !== null
      );
      filteredData.forEach((entry) => {
        switch (entry.digitalMediumType) {
          case MEDIA_TYPE.ON_DEMAND_VIDEO: {
            state.online.push(entry);
            break;
          }
          case MEDIA_TYPE.ON_DEMAND_PODCAST: {
            state.podcast.push(entry);
            break;
          }
        }
      });
      return state;
    },
    pushBookmark: (state: State, { payload }: PayloadAction<Bookmark>) => {
      switch (payload.digitalMediumType) {
        case MEDIA_TYPE.ON_DEMAND_PODCAST: {
          state.podcast.push(payload);
          break;
        }
        case MEDIA_TYPE.ON_DEMAND_VIDEO: {
          state.online.push(payload);
          break;
        }
      }
      return state;
    },
    clearBookmarks: (state: State) => {
      state.online = [];
      state.podcast = [];
      return state;
    },
    replaceBookmarkId: (
      state: State,
      { payload }: PayloadAction<{ type: MEDIA_TYPE; id: Identification }>
    ) => {
      switch (payload.type) {
        case MEDIA_TYPE.ON_DEMAND_PODCAST: {
          state.podcast = state.podcast.filter((entry) => {
            if (entry.bookmarkId == OPTIMISTIC_COMMENT_ID) {
              entry.bookmarkId = payload.id;
            }
          });
          break;
        }
        case MEDIA_TYPE.ON_DEMAND_VIDEO: {
          state.online = state.online.filter((entry) => {
            if (entry.bookmarkId == OPTIMISTIC_COMMENT_ID) {
              entry.bookmarkId = payload.id;
            }
          });
          break;
        }
      }
      return state;
    },
    revertBookmark: (
      state: State,
      { payload }: PayloadAction<{ id: Identification; type: MEDIA_TYPE }>
    ) => {
      switch (payload.type) {
        case MEDIA_TYPE.ON_DEMAND_PODCAST: {
          state.podcast = state.podcast.filter(
            (entry) => entry.videoId !== payload.id
          );
          break;
        }
        case MEDIA_TYPE.ON_DEMAND_VIDEO: {
          state.online = state.online.filter(
            (entry) => entry.videoId !== payload.id
          );
          break;
        }
      }
      return state;
    },
  },
});

export default bookmarkSlice.reducer;
export const bookmarkSliceActions = bookmarkSlice.actions;
