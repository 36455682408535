import * as yup from "yup";

export enum EditPodcastFormNames {
  videoTitle = "title",
  description = "description",
  category = "category",
  exclusivity = "exclusivity",
  thumbnail = "thumbnail",
}

const validationSchema: yup.Schema = yup.object({
  [EditPodcastFormNames.videoTitle]: yup.string().required(),
  [EditPodcastFormNames.description]: yup.string().required(),
  [EditPodcastFormNames.category]: yup.string(),
  [EditPodcastFormNames.exclusivity]: yup.string().required(),
  [EditPodcastFormNames.thumbnail]: yup.string().required(),
});

const initialValues: yup.InferType<typeof validationSchema> = {
  [EditPodcastFormNames.videoTitle]: "",
  [EditPodcastFormNames.description]: "",
  [EditPodcastFormNames.category]: "1",
  [EditPodcastFormNames.exclusivity]: "1",
  [EditPodcastFormNames.thumbnail]: "",
};

export { validationSchema, initialValues };
