import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {},
  iconsContainer: {
    marginTop: { xs: 3, md: 0.5, lg: 5 },
    display: "flex",
    flexDirection: "row",
    justifyContent: { xs: "center", md: "flex-start" },
    columnGap: 1.5,
    alignItems: "center",
  },
  iconSubTitle: {
    fontSize: {
      xs: 9,
      lg: 11,
    },
  },
  iconContainerStyle: {
    paddingBlock: {
      xs: 5,
      lg: 5.5,
    },
    paddingInline: {
      xs: 4,
      lg: 5,
    },
  },
  button: {
    fontWeight: "800",
    letterSpacing: 1.5,
    marginInlineStart: 3,
    background:
      "linear-gradient(100.26deg, #008755 16.92%, rgba(153, 191, 198, 0) 163.21%);",
  },
};

export default styles;
