import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    marginTop: {
      lg: 7,
    },
  },
  header: {
    fontSize: { lg: 25 },
    marginInlineStart: 1,
    marginBottom: 2,
    fontWeight: "600",
  },
};

export default styles;
