import { FC } from "react";
import Box from "@mui/material/Box";
import OnlineWrapper from "components/online_wrapper";
import styles from "./styles";
import { useAppSelector } from "store/hooks";
import { UploadedFileStatus } from "types/uploaded_file_status";
import { useIntl } from "react-intl";
import messages from "../messages";
import { UploadPrivacy } from "types/upload_privacy";
import { Stack } from "@mui/material";

const FilteredSection: FC = () => {
  const intl = useIntl();

  const { dateFilter, categoryFilter, rateFilter, speakerFilter } =
    useAppSelector((state) => state.sidebar);

  const getDateFilters = (prop: "startDate" | "endDate") => {
    if (dateFilter == null) return "";
    return dateFilter[prop]?.toISOString() as any as string;
  };

  return (
    <Box sx={styles.container}>
      <OnlineWrapper
        listLabel={intl.formatMessage(messages.results)}
        emptyLabel={intl.formatMessage(messages.no_result)}
        videoQuery={{
          status: UploadedFileStatus.ACCEPTED,
          privacy: UploadPrivacy.PUBLIC,
          category: categoryFilter,
          scheduled_on_before: getDateFilters("startDate"),
          scheduled_on_after: getDateFilters("endDate"),
          rate: rateFilter,
          user: speakerFilter,
        }}
        dimensions={{
          xs: 12,
          sm: 6,
          lg: 4,
          xl: 3,
        }}
      />
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        width="100%"
        marginBlock={2}
      ></Stack>
    </Box>
  );
};

export default FilteredSection;
