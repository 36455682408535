import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {},
  header: {
    fontSize: {
      lg: 40,
    },
    fontWeight: "600",
    textAlign: "center",
    marginTop: {
      lg: 5,
    },
  },
};

export default styles;
