import { SxProps } from "@mui/material";
import color_pallette from "theme/color_pallette";

const styles: Record<string, SxProps> = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    paddingInlineStart: 1.5,
    paddingTop: 2,
    rowGap: 2,
    scrollBehavior: "auto",
    scrollbarWidth: 50,
    scrollbarColor: color_pallette.primary,
    overflow: "scroll",
    scrollbarGutter: "auto",
  },
};

export default styles;
