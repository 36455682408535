import moment from "moment";

export function calculateStartTime(day: string, hour: string): string {
  const days = moment.duration(day, "d");
  const hours = moment.duration(hour, "h");

  return `${days.hours() + hours.hours()}`;
}


export function formatDateISO(day: string, hour: string): string {
  const dayDate = new Date(day);
  const dayHour = moment.duration(hour, "h");

  const formattedDate = new Date(
    `${dayDate.getFullYear()}-${dayDate.getMonth() + 1}-${dayDate.getDay()}`
  ).toISOString();

  console.log(dayHour);

  return formattedDate;
}