import { FC, useMemo } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import styles from "./styles";
import cropText from "utils/crop_text";
import { FormikValues, useFormikContext } from "formik";
import { UserEditPodcastFormNames } from "../form";
import thumbNail from "assets/images/placeholders/thumbnail_pl.webp";
import { FormattedMessage } from "react-intl";
import messages from "pages/view_admin_profile/messages";
import { PodcastItemResponse } from "store/api/podcast_api/types";

export type Props = {
  podcast?: PodcastItemResponse | null;
};

const MetaSection: FC<Props> = ({ podcast }) => {
  const { values } = useFormikContext<FormikValues>();

  const thumb = values[UserEditPodcastFormNames.thumbnail];

  const url = useMemo(() => {
    try {
      return URL.createObjectURL(thumb);
    } catch (error) {
      return thumbNail;
    }
  }, [podcast, thumb]);

  if (podcast == null) return null;

  return (
    <Box sx={styles.container}>
      <Box
        component="img"
        width={350}
        height={200}
        src={url ? url : podcast.thumbnail}
      />
      <Box sx={styles.meta}>
        <>
          <Typography>
            <FormattedMessage id={messages.link.id} />
          </Typography>
          <Typography sx={styles.link}>
            {cropText(`${podcast?.media}`, 40)}
          </Typography>
        </>
        <Box mt={2}>
          <Typography>File title</Typography>
          <Typography sx={styles.videoName}> {podcast?.title}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default MetaSection;
