import { FC } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
/* import Stack from "@mui/material/Stack";
import FormGroup from "@mui/material/FormGroup";
import Button from "components/custom_button"; */
import styles from "./styles";
import { NOMINATION_FORM_PROPERTIES } from "store/nomination_slice/form";
import InputField from "common/input_field";
/* import { genderData } from "./data";
import Checkbox from "common/checkbox";
import ErrorMessage from "common/error_message";
import { FormikValues, useFormikContext } from "formik"; */

const UploadDocumentForm: FC = () => {
  /* const { errors, setFieldValue } = useFormikContext(); */

  /* const ref = useRef<HTMLInputElement>(null); */

  /* const handleClick = () => {
    ref?.current?.click();
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = e.target?.files?.item(0);
    if (uploadedFile != null)
      setFieldValue(NOMINATION_FORM_PROPERTIES.UPLOADED_FILE, uploadedFile);
  }; */

  return (
    <Box sx={styles.container}>
      {/* <Typography>
        Please Upload related documents of the future speaker
      </Typography>
      <input
        ref={ref}
        type="file"
        style={{ display: "none" }}
        onChange={handleChange}
        name={NOMINATION_FORM_PROPERTIES.UPLOADED_FILE}
      /> */}
      {/* <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        columnGap={2}
      >
        <Button
          onClick={handleClick}
          extraStyles={{ mt: 1 }}
          label="Attach File"
        />
        {ref?.current?.value && (
          <Typography sx={styles.filename}>{ref.current.value}</Typography>
        )}
      </Stack> */}
      {/* <Box mt={2}>
        <Typography sx={styles.gender}>Gender</Typography>
        <FormGroup sx={styles.genderContainer}>
          {genderData.map((entry) => (
            <Checkbox
              name={NOMINATION_FORM_PROPERTIES.GENDER}
              key={entry.value}
              value={entry.value.toString()}
              label={entry.label}
            />
          ))}
        </FormGroup>
        <ErrorMessage>
          {(errors as FormikValues)[NOMINATION_FORM_PROPERTIES.GENDER]}
        </ErrorMessage>
      </Box> */}
      {/* <Box sx={{ marginTop: 1.5 }}>
        <Typography sx={{ font: "Inter, K2D" }}>
          Please share a brief biography that includes information about this
          potential future Live Tips speaker's connection to their talk topic.
        </Typography>
        <InputField
          multiline
          rows={5}
          fullWidth
          name={NOMINATION_FORM_PROPERTIES.DESCRIPTION}
          sx={{ backgroundColor: "white" }}
        />
      </Box> */}
      <Box sx={{ marginTop: 1.5 }}>
        <Typography sx={{ font: "Inter, K2D" }}>
          What is the unique idea that You want them to share in a Live Tips
          Podcast or Live Streaming ? In a few sentences, please describe the
          core of the talk idea, and what makes the talk idea new.
        </Typography>
        <InputField
          multiline
          rows={5}
          fullWidth
          name={NOMINATION_FORM_PROPERTIES.ABOUT}
          sx={{ backgroundColor: "white" }}
        />
      </Box>
    </Box>
  );
};

export default UploadDocumentForm;
