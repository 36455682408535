import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {},
  tabLabel: {
    marginInlineStart: { lg: 1.5 },
    fontSize: { lg: 26 },
    fontWeight: "600",
    marginBottom: 1.5,
  },
  buttonWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
  },
  exploreButton: {
    marginTop: 5,
    color: "black",
    ":hover": {
      backgroundColor: "white",
    },
  },
};

export default styles;
