import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    boxShadow: 5,
    borderRadius: 3,
    marginTop: 5,
    marginInline: 2,
    paddingInline: 3,
    paddingBlock: 2.5,
    minWidth: {
      xs: 400,
      lg: 280,
      xl: 330,
    },
  },
  label: {
    fontSize: {
      xs: 20,
    },
    marginBottom: 4,
    textAlign: { xs: "center" },
  },
};

export default styles;
