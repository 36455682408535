import rootApi from "..";
import { GetById } from "../types";
import {
  getUserRate,
  getVideoRate,
  getVideoRateDetails,
  postRate,
} from "./action_creators";
import { Rate } from "./types";

export const rateApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    postRate: builder.mutation<Rate, { token: string; data: Rate }>({
      query: (body) => postRate(body),
    }),
    getVideoRate: builder.query<number, GetById>({
      query: (body) => getVideoRate(body),
    }),
    getVideoRateDetails: builder.query<Array<Rate>, GetById>({
      query: (body) => getVideoRateDetails(body),
    }),
    getUserRate: builder.query<number, GetById>({
      query: (body) => getUserRate(body),
    }),
  }),
});

export const {
  useGetVideoRateDetailsQuery,
  usePostRateMutation,
  useGetUserRateQuery,
  useGetVideoRateQuery,
} = rateApi;
