import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    marginTop: { xs: 5 },
    marginX: "auto",
    maxWidth: { xs: "80%", lg: "80%", xl: "100%" },
  },
  headerLabel: {
    fontSize: { xs: 30, lg: 45 },
    textAlign: "center",
    marginBottom: 3,
    fontWeight: "500",
  },
};

export default styles;