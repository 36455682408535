import { createTheme } from "@mui/material/styles";
import color_pallette from "./color_pallette";

const theme = createTheme({
  palette: {
    primary: {
      light: "#757ce8",
      main: color_pallette.primary,
      dark: color_pallette.primary,
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff7961",
      main: color_pallette.primary,
      dark: "#ba000d",
      contrastText: "#000",
    },
  },
  typography: {
    fontFamily: `"K2D", "Nizzar", Inter, Roboto`,
  },
  transitions: {
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 250,
      leavingScreen: 200,
    },
    easing: {
      easeInOut: "cubic-bezier(0.4, 0, 0.2, 1)",
      easeOut: "cubic-bezier(0.0, 0, 0.2, 1)",
      easeIn: "cubic-bezier(0.4, 0, 1, 1)",
      sharp: "cubic-bezier(0.4, 0, 0.6, 1)",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            background: color_pallette.primary,
          },
          padding: 0,
          textTransform: "inherit",
          minWidth: "unset",
        },
      },
    },
  },
});

export default theme;
