import { FC } from "react";
import Box from "@mui/material/Box";
import styles from "./styles";
import SnackBar from "components/snack_bar";
import Card from "components/card";
import { Stack, Typography } from "@mui/material";
import FormContainer from "common/form_container";
import SubmitButton from "common/submit_button";
import { initialValues, RESETPWD_FORM, validationSchema } from "./form_data";
import { FormikValues } from "formik";
import InputField from "common/input_field";
import { FormattedMessage } from "react-intl";
import messages from "./messages";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "navigation/routes";
import { usePreResetPwdMutation } from "store/api/identity_service/endpoints";
import SelectInputField from "common/select_input_field";
import {
  countryList,
  CountryType,
} from "pages/register_page/register_form/data";
import { useAppDispatch } from "store/hooks";
import { transactionSliceActions } from "store/transaction_slice";

const ResetPassword: FC = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const [preReset] = usePreResetPwdMutation();

  const handleSubmit = async (values: FormikValues) => {
    try {
      const { data } = (await preReset({
        mobile: `${values[RESETPWD_FORM.countryCode]}${
          values[RESETPWD_FORM.mobile]
        }`,
        email: values[RESETPWD_FORM.email],
      })) as { data: string };

      dispatch(transactionSliceActions.setCurrentTransactionId(data));

      navigate(NavigationRoutes.finalResetPwd, {
        state: {
          mobile: values[RESETPWD_FORM.mobile],
          email: values[RESETPWD_FORM.email],
          transactionId: data,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const mobileCodeLists = () => {
    const codes = countryList.map((entry) => {
      return {
        name: entry.mobileCode,
      };
    });
    return codes;
  };

  return (
    <Box sx={styles.container}>
      <SnackBar />
      <>
        <Box sx={styles.container}>
          <Card extraStyle={styles.cardStyle}>
            <Typography sx={styles.font}>
              <FormattedMessage id={messages.reset.id} />
            </Typography>
            <FormContainer
              validationSchema={validationSchema}
              initialValues={initialValues}
              onSubmit={handleSubmit}
            >
              <Stack
                columnGap={1}
                marginBottom={1}
                direction="row"
                justifyContent="center"
                alignItems="center"
                width="100%"
              >
                <SelectInputField
                  label="Country Code"
                  placeholder="Choose Country Code"
                  name={RESETPWD_FORM.countryCode}
                  dataList={mobileCodeLists() as CountryType[]}
                />
                <InputField
                  name={RESETPWD_FORM.mobile}
                  label="Phone Number"
                  placeholder="e.g 523547807"
                  extraStyles={{ width: 300 }}
                />
              </Stack>
              <InputField
                name={RESETPWD_FORM.email}
                label="Email"
                placeholder="e.g rash@example.com"
                extraStyles={{ width: 390, alignSelf: "center" }}
              />
              <Typography
                sx={{
                  marginBottom: 2,
                  marginTop: 1,
                  opacity: 0.7,
                  textAlign: "center",
                }}
              >
                OTP will be sent to this phone number
              </Typography>
              <Box sx={styles.center}>
                <SubmitButton title={"Submit"} />
              </Box>
            </FormContainer>
          </Card>
        </Box>
      </>
    </Box>
  );
};

export default ResetPassword;
