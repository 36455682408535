import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    marginTop: 3,
    width: "100%",
  },
  avatar: { width: 50, height: 50 },
  accountWrapper: { marginInlineStart: 1.6 },
  username: { fontWeight: "600", fontSize: { xs: 16 } },
  email: { opacity: 0.7, fontSize: { xs: 13 } },
  divider: { marginBlock: 3 },
  registerButton: {
    flexGrow: 0.3,
    backgroundColor: "white",
    boxShadow: 2,
    borderRadius: 5,
    color: "black",
    ":hover": {
      backgroundColor: "white",
    },
  },
  cursor: { cursor: "pointer" },
  signout: {
    flexGrow: 0.3,
    backgroundColor: "white",
    boxShadow: 2,
    borderRadius: 5,
    color: "black",
  },
};

export default styles;
