import { FC, ReactNode } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import magnifier from "assets/svgs/magnifier.svg";
import nav_save_ic from "assets/svgs/nav_save_ic.svg";
import notifications from "assets/svgs/notification.svg";
/* import msg_ic from "assets/svgs/msg_ic.svg"; */
import styles from "./styles";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "navigation/routes";

const Icons: Array<{ Component: ReactNode; path: NavigationRoutes }> = [
  {
    Component: <Box component="img" sx={styles.icon} src={magnifier} />,
    path: NavigationRoutes.search,
  },
  {
    Component: <Box component="img" sx={styles.icon} src={nav_save_ic} />,
    path: NavigationRoutes.myBookmarks,
  },
  {
    Component: <Box component="img" sx={styles.icon} src={notifications} />,
    path: NavigationRoutes.myNotifications,
  },
  /* {
    Component: <Box component="img" sx={styles.icon} src={msg_ic} />,
    path: NavigationRoutes.myComments,
  }, */
];

const IconSection: FC = () => {
  const navigate = useNavigate();

  const handleNavigate = (path: NavigationRoutes) => {
    navigate(path);
  };

  return (
    <Stack width="13%" rowGap={1} direction="column">
      {/* ITS OK TO USE INDEX AS KEY FOR IT IS CONSTANT NEVER GOING TO CHANGE */}
      {Icons.map((entry, index) => (
        <Box
          onClick={() => handleNavigate(entry.path)}
          key={index}
          sx={styles.iconWrapper}
        >
          {entry.Component}
        </Box>
      ))}
    </Stack>
  );
};

export default IconSection;
