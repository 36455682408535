import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { ProfileSliceActions } from "store/profile_slice";

const useAudioPreview = () => {
  const dispatch = useAppDispatch();

  const { meta } = useAppSelector((state) => state.adminProfil.onlineTips);

  const { media } = useAppSelector(
    (state) => state.livetipsPodcast.currentRecord
  );

  const [audioFile, setAudioFile] = useState<HTMLAudioElement | null>(null);
  const [playing, setPlaying] = useState<boolean>(false);

  const { speakerProfile } = useAppSelector((state) => state.global);

  useEffect(() => {
    setAudioFile(new Audio(media));
    setPlaying(false);
  }, [media]);

  useEffect(() => {
    toggleAudio();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playing, audioFile]);

  const toggleAudio = async () => {
    playing && audioFile?.HAVE_ENOUGH_DATA
      ? audioFile.play()
      : audioFile?.pause();
  };

  const handleListen = () => {
    setPlaying(!playing);
  };

  useEffect(() => {
    dispatch(
      ProfileSliceActions.setDuration(audioFile?.duration.toPrecision(1))
    );
  }, [audioFile, dispatch]);

  const getDuration = (): string => {
    return `${audioFile?.duration.toPrecision(1)} Mins`;
  };

  return {
    getDuration,
    playing,
    handleListen,
    profile_picture: speakerProfile.profilePicture,
    user: speakerProfile,
    meta,
  };
};

export default useAudioPreview;
