import { FC } from "react";
import { FormControlLabel, Checkbox as MuiCheckbox } from "@mui/material";
import { FormikValues, useFormikContext } from "formik";
import { Identification } from "types/identification";

type Props = {
  label: string;
  name: string;
  value: Identification;
  selectMany?: boolean;
};

const Checkbox: FC<Props> = ({ name, label, value, selectMany = false }) => {
  const { handleChange, values } = useFormikContext<FormikValues>();

  const props = !selectMany
    ? {
        checked: values[name] === value,
      }
    : {};

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange({
      ...e,
      target: {
        value: getValue(value),
        name,
      },
    });
  };

  const getValue = (entry: Identification) => {
    return selectMany ? [...(values as FormikValues)[name], entry] : value;
  };

  return (
    <FormControlLabel
      sx={{ cursor: "pointer" }}
      label={label}
      control={
        <MuiCheckbox
          name={name}
          onChange={handleOnChange}
          inputProps={{ "aria-label": "controlled" }}
          {...props}
        />
      }
    />
  );
};

export default Checkbox;
