import { SxProps } from "@mui/material";
import color_pallette from "theme/color_pallette";

const styles: Record<string, SxProps> = {
  containerStack: { width: { lg: "50%" } },
  headerContainer: { position: "relative", width: { lg: "90%" } },
  timeLabelStyle: {
    fontSize: {
      xs: 12,
      lg: 9,
    },
  },
  iconsContainer: {
    marginTop: { xs: 1, md: 0.5, lg: 0 },
    display: "flex",
    flexDirection: "row",
    justifyContent: { xs: "center", md: "flex-start" },
    columnGap: 1.5,
    alignItems: "center",
  },
  iconSubTitle: {
    fontSize: {
      xs: 9,
      lg: 11,
    },
  },
  iconContainerStyle: {
    paddingBlock: {
      xs: 5,
      lg: 5.6,
    },
    paddingInline: {
      xs: 4,
      lg: 4.6,
    },
  },
  img: {
    objectFit: "cover",
    aspectRatio: "4 / 4",
    borderRadius: "10px",
    height: 250,
    position: "relative",
  },
  timeBoxStyle: {
    width: {
      xs: 70,
      md: 55,
      lg: 40,
    },
    height: {
      xs: 70,
      md: 55,
      lg: 40,
    },
    fontSize: {
      xs: 40,
      lg: 20,
    },
  },
  avatarPic: { width: 30, height: 30 },
  captionText: {
    fontWeight: "600",
    color: "white",
    padding: 1,
    fontSize: {
      xs: 14,
      lg: 10,
      xl: 12,
    },
    lineHeight: 1.3,
  },
  rate: {
    fontSize: {
      xs: 13,
    },
  },
  ratingLabel: {
    fontSize: {
      xs: 11,
      md: 13,
    },
  },
  stats: {
    fontSize: { xs: 11, md: 13 },
    fontWeight: "400",
    whiteSpace: "nowrap",
  },
  speakerName: {
    marginInlineStart: { xs: 2, lg: 1 },
    fontSize: {
      xs: 13,
      lg: 11,
    },
    color: "white",
    fontWeight: "700",
  },
  footer: {
    justifyContent: {
      xs: "space-between",
      sm: "flex-start",
      md: "flex-around",
      lg: "flex-around",
    },
    rowGap: {
      xs: 0,
      sm: 1,
      lg: 0.5,
    },
    columnGap: {
      xs: 0,
      sm: 1.5,
    },
    marginTop: 0.5,
    flexWrap: {
      xs: "no-wrap",
      sm: "wrap",
    },
  },
  thumbContainer: {
    width: {
      xs: "65%",
      md: "90%",
      lg: "80%",
      xl: "80%",
    },
    backgroundColor: color_pallette.transparent,
    maxHeight: "50%",
    zIndex: 10,
    position: "absolute",
    left: 0,
    bottom: 4.39,
    borderTopRightRadius: 3,
    borderBottomLeftRadius: "7px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  container: {
    display: "flex",
    width: {
      xs: "100%",
      sm: "80%",
    },
    justifyContent: "flex-start",
    paddingBottom: {
      xs: 1,
      lg: 0,
    },
    paddingX: 1,
    flexDirection: "column",
    cursor: "pointer",
    borderRadius: 3,
    overflow: "hidden",
  },
  captionFont: {
    fontSize: "10px",
    color: "#000",
  },
  font: {
    fontSize: "14px",
    fontWeight: "700",
    color: "#000",
  },
  avatar: { width: 24, height: 24 },
  thumbnailTitle: {
    color: "white",
    width: "100%",
    fontWeight: "700",
    fontSize: 21,
    lineHeight: 1.2,
  },
  desc: {
    marginTop: 0.5,
    lineHeight: 1.2,
    fontSize: {
      xs: 12,
      lg: 15,
    },
  },
  position: { position: "relative" },
  absolutePosition: {
    zIndex: 10,
    width: "70%",
    height: 50,
    position: "absolute",
    backgroundColor: "#4A6A7BE5",
    bottom: 6,
    left: 0,
    padding: 1,
    borderTopRightRadius: 6,
  },
};

export default styles;
