import { Provider } from "react-redux";
import { FC } from "react";
import App from "./App";
import initStore from "store";
import { PersistGate } from "redux-persist/integration/react";
import "theme/css/App.css";
import "theme/css/init_date_picker.css";

const Root: FC = () => {
  const { store, persistor } = initStore();
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  );
};

export default Root;
