import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    marginTop: 5,
  },
  gender: {
    fontWeight: "700",
    font: "Inter, K2D",
  },
  genderContainer: {
    display: "flex",
    flexDirection: {
      xs: "column",
      sm: "row",
    },
  },
  filename: {
    opacity: 0.6,
    fontSize: 12,
    marginTop: 1,
  },
};

export default styles;
