import { FC } from "react";
import Box from "@mui/material/Box";
import styles from "./styles";

type Props = {
  icon: string;
  onClick: () => void;
};

const StreamUtilItem: FC<Props> = ({ icon, onClick }) => {
  return (
    <Box onClick={onClick} sx={styles.container}>
      <Box component="img" sx={styles.icon} src={icon} />
    </Box>
  );
};

export default StreamUtilItem;
