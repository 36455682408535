import { FC, useState } from "react";
import Stack from "@mui/material/Stack";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Grow from "@mui/material/Grow";
import styles from "./styles";
import { useAppDispatch, useAppSelector } from "store/hooks";
import HeaderSection from "../edit_podcast_modal/header_section";
import PodcastScheduleSection from "./podcast_schedule_section";
import FormSection from "./form_section";
import { SCHEDULE_TYPE, ScheduleContext } from "context/schedule_context";
import SubmitButton from "common/submit_button";
import { LivetipsPodcastSliceActions } from "store/livetips_podcast_slice";

const PodcastPremier: FC = () => {
  const [schedule, setShowSchedule] = useState<SCHEDULE_TYPE | undefined>();

  const dispatch = useAppDispatch();

  const showPremier = useAppSelector(
    (state) => state.livetipsPodcast.showPodcastPremier
  );

  const handleBack = () => {
    dispatch(LivetipsPodcastSliceActions.showGuideModal());
  };

  return (
    <Modal open={showPremier} sx={styles.container}>
      <ScheduleContext.Provider value={{ schedule, setShowSchedule }}>
        <Grow in={showPremier} timeout={600}>
          <Paper>
            <HeaderSection onBack={handleBack} confirmation={false} />
            <PodcastScheduleSection />
            {schedule && schedule === SCHEDULE_TYPE.SCHEDULED && (
              <FormSection />
            )}
            <Stack width="95%" direction="row" justifyContent="flex-end">
              <SubmitButton extraStyles={styles.button} title="Next" />
            </Stack>
          </Paper>
        </Grow>
      </ScheduleContext.Provider>
    </Modal>
  );
};

export default PodcastPremier;
