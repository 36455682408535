import { Methods } from "types/http";
import { blogUrls } from "./urls";
import { Blog } from "./types";
import { Identification } from "types/identification";

export const getBlogList = (param: { token: string }) => ({
  url: blogUrls.getBlogs(),
  method: Methods.GET,
  headers: {
    Lng: "EN",
    "Content-type": "application/json",
    Accept: "*/*",
    Authorization: `Bearer ${param.token}`,
  },
});

export const getBlogById = (param: { token: string; id: Identification }) => ({
  url: blogUrls.getBlogById(param.id),
  method: Methods.GET,
  headers: {
    Lng: "EN",
    "Content-type": "application/json",
    Accept: "*/*",
    Authorization: `Bearer ${param.token}`,
  },
});

export const postBlog = (param: { token: string; body: Blog }) => ({
  url: blogUrls.postBlog(),
  method: Methods.POST,
  body: param.body,
  headers: {
    Lng: "EN",
    "Content-type": "application/json",
    Accept: "*/*",
    Authorization: `Bearer ${param.token}`,
  },
});
