import { FC } from "react";
import { Bookmark } from "store/api/bookmark_service/types";
import OnlineCardListItem from "components/online_card_list_item";
import { useGetVideoByIdQuery } from "store/api/video_service/endpoints";
import { useAppSelector } from "store/hooks";

const OnlineItemBookmarkWrapper: FC<Bookmark> = ({ videoId }) => {
  const { access_token } = useAppSelector(
    (state) => state.global.credentials.token
  );

  const { data, isLoading } = useGetVideoByIdQuery({
    id: videoId as string,
    token: access_token,
  });

  if (!videoId) return null;

  return <>{!isLoading && data && <OnlineCardListItem {...data} />}</>;
};

export default OnlineItemBookmarkWrapper;
