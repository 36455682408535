import { FC, useMemo } from "react";
import Grow from "@mui/material/Grow";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { SxProps } from "@mui/material/styles";
import styles from "./styles";
import {
  EmptyIconTypes,
  EmptyListIconMapper,
} from "types/empty_list_icon_mapper";

type Props = {
  label?: string;
  icon: EmptyIconTypes;
  extraStyle?: SxProps;
};

const EmptyList: FC<Props> = ({ icon, label, extraStyle }) => {
  const style = useMemo(() => {
    return { ...styles.container, ...extraStyle };
  }, [extraStyle]);
  return (
    <Grow in timeout={800}>
      <Stack justifyContent="center" alignItems="center" sx={style}>
        <Box
          width={200}
          component="img"
          alt="Empty List Image"
          src={EmptyListIconMapper[icon]}
        />
        <Typography sx={styles.emptyLabel}>{label ? label : ""}</Typography>
      </Stack>
    </Grow>
  );
};

export default EmptyList;
