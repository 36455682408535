import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import ExploreMember from "types/police_member";
import { data } from "./data";
import UploadedFileMeta, { Upcoming } from "types/uploaded_file_meta";
import { UploadedFileStatus } from "types/uploaded_file_status";
import UploadedFile from "types/uploaded_file";
import { UserResponse } from "store/api/types";
import { Channel } from "store/api/streaming_service/types";

export const enum MODAL_ACTION_TYPE {
  online = "online",
  interactive = "interactive",
  podcast = "podcast",
  idle = "",
}

type State = {
  exploreMembers: Array<ExploreMember>;
  currentMember: UserResponse | null;
  upcomingOnline: Channel | null;
  actionType: MODAL_ACTION_TYPE;
  modals: {
    showUploadFile: boolean;
    showEditUploadFile: boolean;
    showEditLiveStream: boolean;
    showConfirmation: boolean;
    showEditModal: boolean;
    showGuideModal: boolean;
    showOnlinePremier: boolean;
    showInviteSpeakers: boolean;
  };
  onlineTips: {
    file: UploadedFile;
    thumb?: File;
    meta: UploadedFileMeta;
    status: UploadedFileStatus;
  };
};

const initialState: State = {
  exploreMembers: data,
  upcomingOnline: null,
  currentMember: null,
  actionType: MODAL_ACTION_TYPE.idle,
  modals: {
    showUploadFile: false,
    showEditUploadFile: false,
    showConfirmation: false,
    showInviteSpeakers: false,
    showGuideModal: false,
    showEditLiveStream: false,
    showOnlinePremier: false,
    showEditModal: false,
  },
  onlineTips: {
    file: {} as UploadedFile,
    meta: {} as UploadedFileMeta,
    status: UploadedFileStatus.PENDING,
  },
};

const ProfileSlice = createSlice({
  name: "adminProfile",
  initialState,
  reducers: {
    submitCurrentMember: (
      state: State,
      { payload }: PayloadAction<UserResponse>
    ) => {
      state.currentMember = payload;
      return state;
    },
    clearCurrentMember: (state: State) => {
      state.currentMember = null;
      return state;
    },
    setActionType: (
      state: State,
      { payload }: PayloadAction<MODAL_ACTION_TYPE>
    ) => {
      state.actionType = payload;
      return state;
    },
    clearActionType: (state: State) => {
      state.actionType = MODAL_ACTION_TYPE.idle;
      return state;
    },
    showUploadModal: (state: State) => {
      state.modals.showUploadFile = true;
      state.modals.showConfirmation = false;
      state.modals.showOnlinePremier = false;
      state.modals.showGuideModal = false;
      state.modals.showInviteSpeakers = false;
      state.modals.showEditLiveStream = false;
      state.modals.showEditUploadFile = false;
      return state;
    },
    showEditProfileModal: (state: State) => {
      state.modals.showUploadFile = false;
      state.modals.showConfirmation = false;
      state.modals.showEditUploadFile = false;
      state.modals.showEditLiveStream = false;
      state.modals.showGuideModal = false;
      state.modals.showInviteSpeakers = false;
      state.modals.showEditModal = true;
      state.modals.showOnlinePremier = false;
      return state;
    },
    showEditUploadModal: (state: State) => {
      state.modals.showEditUploadFile = true;
      state.modals.showUploadFile = false;
      state.modals.showConfirmation = false;
      state.modals.showEditLiveStream = false;
      state.modals.showOnlinePremier = false;
      state.modals.showInviteSpeakers = false;
      state.modals.showGuideModal = false;
      return state;
    },
    showUploadConfirmationModal: (state: State) => {
      state.modals.showConfirmation = true;
      state.modals.showUploadFile = false;
      state.modals.showEditUploadFile = false;
      state.modals.showGuideModal = false;
      state.modals.showEditLiveStream = false;
      state.modals.showOnlinePremier = false;
      state.modals.showInviteSpeakers = false;
      state.modals.showEditModal = false;
      return state;
    },
    showGuideModal: (state: State) => {
      state.modals.showConfirmation = false;
      state.modals.showUploadFile = false;
      state.modals.showEditUploadFile = false;
      state.modals.showGuideModal = true;
      state.modals.showEditModal = false;
      state.modals.showEditLiveStream = false;
      state.modals.showInviteSpeakers = false;
      state.modals.showOnlinePremier = false;
      return state;
    },
    closeModals: (state: State) => {
      state.modals.showConfirmation = false;
      state.modals.showUploadFile = false;
      state.modals.showEditUploadFile = false;
      state.modals.showInviteSpeakers = false;
      state.modals.showGuideModal = false;
      state.modals.showEditLiveStream = false;
      state.modals.showOnlinePremier = false;
      state.modals.showEditModal = false;
      return state;
    },
    showOnlinePremier: (state: State) => {
      state.modals.showConfirmation = false;
      state.modals.showUploadFile = false;
      state.modals.showEditUploadFile = false;
      state.modals.showGuideModal = false;
      state.modals.showEditLiveStream = false;
      state.modals.showOnlinePremier = true;
      state.modals.showInviteSpeakers = false;
      state.modals.showEditModal = false;
      return state;
    },
    showEditLiveStream: (state: State) => {
      state.modals.showConfirmation = false;
      state.modals.showUploadFile = false;
      state.modals.showEditUploadFile = false;
      state.modals.showGuideModal = false;
      state.modals.showEditLiveStream = true;
      state.modals.showOnlinePremier = true;
      state.modals.showInviteSpeakers = false;
      state.modals.showEditModal = false;
      return state;
    },
    showInviteSpeakersModal: (state: State) => {
      state.modals.showConfirmation = false;
      state.modals.showUploadFile = false;
      state.modals.showEditUploadFile = false;
      state.modals.showGuideModal = false;
      state.modals.showEditLiveStream = false;
      state.modals.showOnlinePremier = false;
      state.modals.showEditModal = false;
      state.modals.showInviteSpeakers = true;
      return state;
    },
    clearUploadFiles: (state: State) => {
      state.onlineTips = {
        file: {} as UploadedFile,
        meta: {} as UploadedFileMeta,
        status: UploadedFileStatus.PENDING,
      };
      return state;
    },
    changeUploadedVideoStatus: (
      state: State,
      { payload }: PayloadAction<UploadedFileStatus>
    ) => {
      state.onlineTips.status = payload;
      return state;
    },
    uploadFile: (state: State, { payload }: PayloadAction<UploadedFile>) => {
      state.onlineTips.file = payload;
      return state;
    },
    editUpload: (
      state: State,
      { payload }: PayloadAction<UploadedFileMeta>
    ) => {
      state.onlineTips.meta = payload;
      return state;
    },
    insertOnlineChannel: (
      state: State,
      { payload }: PayloadAction<Channel>
    ) => {
      state.upcomingOnline = payload;
      return state;
    },
    schedule: (state: State, { payload }: PayloadAction<Upcoming>) => {
      state.onlineTips.meta.upcoming = payload;
    },
    setDuration: (
      state: State,
      { payload }: PayloadAction<string | undefined>
    ) => {
      state.onlineTips.meta.duration = payload;
    },
  },
});

export default ProfileSlice.reducer;
export const ProfileSliceActions = ProfileSlice.actions;
