import { defineMessages } from 'react-intl';

const scope = 'FooterSection';

export default defineMessages({
  about_livetips: {
    id: `${scope}.about_livetips`,
    defaultMessage: "About Live Tips",
  },
  about_title: {
    id: `${scope}.about_title`,
    defaultMessage: "ABOUT TIPS APP",
  },
  enteremail: {
    id: `${scope}.enteremail`,
    defaultMessage: "Enter Your Email",
  },
  home: {
    id: `${scope}.home`,
    defaultMessage: "Home",
  },
  get_in_touch: {
    id: `${scope}.get_in_touch`,
    defaultMessage: "Get in touch",
  },
  our_services: {
    id: `${scope}.our_services`,
    defaultMessage: "Our Services",
  },
  help: {
    id: `${scope}.help`,
    defaultMessage: "Help",
  },
  testimonial: {
    id: `${scope}.testimonial`,
    defaultMessage: "Testimonial",
  },
  how_it_works: {
    id: `${scope}.how_it_works`,
    defaultMessage: "How it Works",
  },
  not_ready: {
    id: `${scope}.not_ready`,
    defaultMessage: "NOT QUITE READY FOR SURVAY?",
  },
  no_spam: {
    id: `${scope}.no_spam`,
    defaultMessage: "join our online no-community for free. no spam,ever.",
  },
  subscribe: {
    id: `${scope}.subscribe`,
    defaultMessage: "Subscribe",
  },
  queries: {
    id: `${scope}.queries`,
    defaultMessage: "Any Queries? Visit us on",
  },
  copyrights: {
    id: `${scope}.copyrights`,
    defaultMessage: "Live Tips - All Rights Reserved",
  },
  dubai_police: {
    id: `${scope}.dubai_police`,
    defaultMessage: "Dubai Police",
  },
  about: {
    id: `${scope}.about`,
    defaultMessage:
      "Live tips is an initiative initiated by the College and University Council of Dubai Police to allow for University Students to participate in informative and awareness content for the purpose of self- development, motivation and excellence.The Platform is used to provide online based content in the form of Online Sessions where speakers from different backgrounds can attend to provide their knowledge and insight on certain topics as well as success stories which boost the morality of university students to greater heights",
  },
});