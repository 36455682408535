import { FC } from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import styles from "./styles";
import color_pallette from "theme/color_pallette";
import { FormattedMessage } from "react-intl";
import messages from "../messages";

const IntroSection: FC = () => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={styles.container}
    >
      <Typography sx={{ fontSize: { lg: 50 } }}>
        <FormattedMessage id={messages.livetips.id} />{" "}
        <span style={{ color: color_pallette.primary, fontWeight: "700" }}>
          Live Tips
        </span>
      </Typography>
      <Typography
        sx={{
          lineHeight: 1.5,
          fontWeight: "300",
          width: "50%",
          fontSize: { lg: 25 },
        }}
      >
        <FormattedMessage id={messages.intro.id} />
      </Typography>
    </Stack>
  );
};

export default IntroSection;
