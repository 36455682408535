import { FC, useCallback } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import styles from "./styles";
import PodcastHistoryItem from "components/podcast_history_item";
import { useAppSelector } from "store/hooks";
import {
  useGetPodcastByIdQuery,
  useGetPodcastListQuery,
} from "store/api/podcast_api/endpoints";
import { UploadedFileStatus } from "types/uploaded_file_status";
import EmptyList from "components/empty_list";
import { useParams } from "react-router-dom";
import { PodcastItemResponse } from "store/api/podcast_api/types";
import { FormattedMessage } from "react-intl";
import messages from "../messages";

type Props = {
  name?: string;
};

const HistorySection: FC<Props> = ({ name }) => {
  const {
    credentials: { token },
  } = useAppSelector((state) => state.global);

  const params = useParams();

  const { data: currentPodcast } = useGetPodcastByIdQuery(
    {
      id: params.id,
      token: token.access_token,
    },
    {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
    }
  );

  const { data } = useGetPodcastListQuery(
    {
      status: UploadedFileStatus.ACCEPTED,
      user: currentPodcast?.user.customerId,
    },
    {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
    }
  );

  const memoData = useCallback(() => {
    let results: PodcastItemResponse[] = data?.results ?? [];
    if (data?.results) {
      results = data.results.filter(
        (entry) => entry.id.toString() !== params.id
      );
    }
    return results;
  }, [params.id]);

  return (
    <Grid item>
      <Stack rowGap={2} sx={styles.container}>
        <Typography sx={styles.sectionHeader}>
          <span style={{ fontWeight: "800" }}>
            <FormattedMessage id={messages.more.id} />
          </span>{" "}
          {name}
        </Typography>
        {memoData().length > 0 ? (
          memoData().map((entry) => (
            <Grid key={entry.id} item xs={12}>
              <PodcastHistoryItem {...entry} />
            </Grid>
          ))
        ) : (
          <EmptyList label="No Histroy Found" icon="podcast" />
        )}
      </Stack>
    </Grid>
  );
};

export default HistorySection;
