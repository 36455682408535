import { FC, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Grow from "@mui/material/Grow";
import Stack from "@mui/material/Stack";
import Button from "components/custom_button";
import cropText from "utils/crop_text";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { globalSliceActions } from "store/global_slice";
import { NavigationRoutes } from "navigation/routes";
import { useNavigate } from "react-router-dom";
import avatar from "assets/images/placeholders/thumbnail_pl.webp";
import styles from "./styles";
import { FormattedMessage, useIntl } from "react-intl";
import messages from "./messages";
import { ROLE } from "types/role";
import { Identification } from "types/identification";
import SignoutConfirmation from "components/signout_confirmation";
import { badgeSliceActions } from "store/badge_slice";

type AccountLabelProps = {
  label: string;
  value: Identification;
};

const AccountLabel: FC<AccountLabelProps> = ({ label, value }) => {
  return (
    <Typography sx={{ fontSize: 14, fontWeight: "400" }}>
      <span
        style={{
          fontWeight: "bold",
          marginInlineEnd: "8px",
          fontSize: 14,
        }}
      >
        {label} :
      </span>
      {value}
    </Typography>
  );
};

const AccountSection: FC = () => {
  const dispatch = useAppDispatch();

  const [isSignout, setIsSignout] = useState<boolean>(false);

  const navigate = useNavigate();

  const { credentials, speakerProfile } = useAppSelector(
    (state) => state.global
  );

  const { access_token } = credentials.token;

  const handleSignout = () => {
    setIsSignout(true);
  };

  const handleSignoutConfirmation = () => {
    setIsSignout(false);
    dispatch(globalSliceActions.signout());
    dispatch(globalSliceActions.setRole(ROLE.normal));
    dispatch(badgeSliceActions.setNotifications(0));
    dispatch(badgeSliceActions.setSaved(0));
    dispatch(globalSliceActions.clearStorage());
    navigate(NavigationRoutes.home);
  };

  const handleRegister = () => {
    navigate(NavigationRoutes.signup);
  };

  const handleLogin = () => {
    navigate(NavigationRoutes.login);
  };

  const handleCancel = () => {
    setIsSignout(false);
  };

  const [visibile, toggleVisibility] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      toggleVisibility(false);
    };
  }, []);

  const handleDisplayMenu = () => {
    toggleVisibility(!visibile);
  };

  const handleViewProfile = () => {
    navigate(NavigationRoutes.profile);
  };

  const intl = useIntl();

  return (
    <Box sx={styles.container}>
      {access_token ? (
        <Stack
          columnGap={2}
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Typography onClick={handleDisplayMenu} sx={styles.name}>
            {speakerProfile.name}
          </Typography>
          <Avatar
            alt="account avatar small"
            onClick={handleDisplayMenu}
            sx={styles.avatar}
            src={speakerProfile.profilePicture ?? avatar}
          />
          <Typography onClick={handleSignout}>
            <FormattedMessage id={messages.signout.id} />
          </Typography>
        </Stack>
      ) : (
        <Stack direction="row" alignItems="center" columnGap={1}>
          <Typography onClick={handleLogin}>
            <FormattedMessage id={messages.login.id} />
          </Typography>{" "}
          /
          <Typography onClick={handleRegister}>
            <FormattedMessage id={messages.signup.id} />
          </Typography>
        </Stack>
      )}
      <SignoutConfirmation
        isVisible={isSignout}
        handleSignout={handleSignoutConfirmation}
        handleCancel={handleCancel}
      />
      <Grow in={visibile} timeout={700}>
        <Box
          visibility={visibile ? "visible" : "hidden"}
          sx={styles.accountMenu}
        >
          <Stack
            columnGap={1.5}
            alignItems="center"
            justifyContent="flex-start"
            direction="row"
          >
            <Avatar
              alt="Account Avatar"
              sx={styles.accountMenuAvatar}
              src={speakerProfile.profilePicture ?? avatar}
            />
            <Box>
              <AccountLabel
                label={intl.formatMessage(messages.name)}
                value={cropText(speakerProfile.name, 40)}
              />
              <AccountLabel
                label={intl.formatMessage(messages.livetipsid)}
                value={
                  speakerProfile?.customerId ? speakerProfile.customerId : ""
                }
              />
              <AccountLabel
                label={intl.formatMessage(messages.email)}
                value={cropText(speakerProfile.email, 40)}
              />
            </Box>
          </Stack>
          <Button
            onClick={handleViewProfile}
            extraStyles={{ width: "100%" }}
            label={intl.formatMessage(messages.viewprofile)}
          />
        </Box>
      </Grow>
    </Box>
  );
};

export default AccountSection;
