import { defineMessages } from "react-intl";

const scope = "Tabs";

export default defineMessages({
  live_online: {
    id: `${scope}.live_online`,
    defaultMessage: "Live Online",
  },
  explore: {
    id: `${scope}.explore`,
    defaultMessage: "Explore More",
  },
  podcasts: {
    id: `${scope}.podcasts`,
    defaultMessage: "Podcasts",
  },
  interactive: {
    id: `${scope}.interactive`,
    defaultMessage: "Live Interactive",
  },
});
