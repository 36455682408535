import { SxProps } from "@mui/material";
import color_pallette from "theme/color_pallette";

const styles: Record<string, SxProps> = {
  container: {
    background: "linear-gradient(232.83deg, #008755 1.43%, #BCE0E1 91.6%)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 5,
    width: {
      xs: 200,
      lg: 200,
    },
    height: {
      xs: 200,
      lg: 200,
    },
  },
  label: {
    fontWeight: "600",
    fontSize: { lg: 21 },
    opacity: 0.7,
    marginTop: 1.6,
  },
  root: {
    backgroundColor: color_pallette.backgroundGray,
    borderRadius: 5,
    display: "flex",
    cursor: "pointer",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: 350,
    paddingBlock: 3,
  },
};

export default styles;
