import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    margin: "auto",
    width: "100vw",
    background:
      "linear-gradient(334deg, #008751 -28.95%, rgba(188, 224, 225, 0.000) 87.27%)",
  },
};

export default styles;
