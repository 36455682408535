import { FC, useState } from "react";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Grow from "@mui/material/Grow";
import { Comment as CommentType } from "store/api/comment_service/types";
import ReactionCounter from "components/reaction_counter";
import thumbNail from "assets/images/placeholders/thumbnail_pl.webp";
import useComment from "hooks/useComment";
import { useAppDispatch } from "store/hooks";
import { commentSliceActions } from "store/comment_slice";
import { substractDates } from "utils/date_subtracter";
import styles from "./styles";
import ReplyIcon from "@mui/icons-material/Reply";
import CommentReplyForm from "components/comment_reply_form";
import { Identification } from "types/identification";

const Comment: FC<CommentType> = (comment) => {
  const { handleDislike, updateComment, handleLike, userId, reaction } =
    useComment(comment);

  const dispatch = useAppDispatch();

  const [reply, setReply] = useState<boolean>(false);

  const dislikeComment = async (commentId: Identification) => {
    if (reaction == "disliked") return;

    dispatch(commentSliceActions.disLikeComment({ commentId, userId }));

    if (reaction == "idle") await handleDislike();
    else {
      const updatedComment = comment;
      const dislikeIndex = updatedComment.likes.findIndex(
        (entry) => entry.userId !== comment.userId
      );
      const dislike = updatedComment.likes[dislikeIndex];
      delete updatedComment.likes[dislikeIndex];
      updatedComment.dislikes.push(dislike);

      await updateComment(updatedComment);
    }
  };

  const likeComment = async (commentId: Identification) => {
    if (reaction == "liked") return;

    dispatch(commentSliceActions.likeComment({ commentId, userId }));

    if (reaction == "idle") await handleLike();
    else {
      const updatedComment = comment;
      const likeIndex = updatedComment.dislikes.findIndex(
        (entry) => entry.userId !== comment.userId
      );
      const like = updatedComment.dislikes[likeIndex];
      delete updatedComment.dislikes[likeIndex];
      updatedComment.likes.push(like);

      await updateComment(updatedComment);
    }
  };

  return (
    <Grow in timeout={700}>
      <Stack direction="column">
        <Stack direction="row" alignItems="center">
          <Paper sx={styles.container}>
            <Stack sx={styles.flex} justifyContent="space-between">
              <Stack sx={styles.flex} columnGap={1}>
                <Avatar src={comment.user?.profilePicture ?? thumbNail} />
                <Typography sx={styles.content}>
                  {comment.commentString}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" columnGap={2}>
                <Typography sx={styles.createdAt}>
                  {`${
                    substractDates(comment?.createdAt ?? "")
                      ? `${substractDates(comment?.createdAt ?? "")} Day Ago`
                      : "Just Now"
                  }`}
                </Typography>
                <ReactionCounter
                  reaction={reaction}
                  onDisLike={() => dislikeComment(comment.commentId as string)}
                  onLike={() => likeComment(comment.commentId as string)}
                  likes={comment.likesCount ?? 0}
                  dislikes={comment.dislikesCount ?? 0}
                />
              </Stack>
            </Stack>
          </Paper>
          <ReplyIcon
            sx={{ cursor: "pointer", opacity: 0.65, marginInlineStart: 1.5 }}
            onClick={() => setReply(true)}
          />
        </Stack>
        {reply && (
          <CommentReplyForm
            type={comment.digitalMediumType}
            onAbort={() => setReply(false)}
            parent={comment.parent !== null ? comment.parent : comment}
          />
        )}
      </Stack>
    </Grow>
  );
};

export default Comment;
