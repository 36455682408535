import { FC } from "react";
import Box from "@mui/material/Box";
import FilterSection from "../filter_section";
import ExploreFooterSection from "components/explore_footer_section";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "navigation/routes";
import OnlineWrapper from "components/online_wrapper";
import { UploadedFileStatus } from "types/uploaded_file_status";
import { UploadPrivacy } from "types/upload_privacy";
import styles from "./styles";
import usePagination from "hooks/use_pagination";
import Pagination from "components/pagination";

const ContentSection: FC = () => {
  const navigate = useNavigate();

  const handleExploreOnlineVideos = () => {
    navigate(NavigationRoutes.services_online_video);
  };

  const { paginationKey } = usePagination({
    name: "content_section",
    perPage: 6,
  });

  return (
    <>
      <FilterSection />
      <>
        <OnlineWrapper
          paginationName={paginationKey}
          listLabel=""
          limit={[0, 6]}
          videoQuery={{
            status: UploadedFileStatus.ACCEPTED,
            privacy: UploadPrivacy.PUBLIC,
          }}
          perPage={6}
        />
        <Pagination name={paginationKey} />
        <Box sx={styles.footer}>
          <ExploreFooterSection onClick={handleExploreOnlineVideos} />
        </Box>
      </>
    </>
  );
};
export default ContentSection;
