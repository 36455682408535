import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    cursor: "pointer",
  },
  pic: {
    width: 150,
    height: 90,
    borderRadius: 2,
    boxShadow: 0,
  },
  footerSection: { fontSize: 11, fontWeight: "400", whiteSpace: "nowrap" },
  desc: {
    fontSize: { xs: 12, xl: 11 },
    mt: 0.5,
    opacity: 0.7,
    maxWidth: 170,
  },
  publisherName: { fontSize: { xl: 13 } },
  footer: {
    justifyContent: {
      xs: "flex-start",
      sm: "flex-start",
      md: "flex-around",
      lg: "flex-around",
    },
    rowGap: {
      xs: 0,
      sm: 1,
    },
    columnGap: {
      xs: 1.6,
      sm: 1.5,
      xl: 1,
    },
    marginTop: { xs: 1, lg: 0.5, xl: 1 },
    flexWrap: {
      xs: "no-wrap",
      sm: "wrap",
    },
  },
};

export default styles;
