import { createContext } from "react";

export type AudioContext = {
  audioFile: HTMLAudioElement | null;
  setAudioFile: React.Dispatch<React.SetStateAction<HTMLAudioElement | null>>;
};

const AudioContext = createContext<AudioContext>({
  audioFile: null,
  setAudioFile: () => {},
});

export default AudioContext;
