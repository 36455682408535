import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ChatMessage } from "store/api/chat/types";
import { Identification } from "types/identification";

type State = {
  chatHistory: Array<ChatMessage>;
  conversationId: Identification;
};

const initialState: State = {
  chatHistory: [],
  conversationId: 0,
};

const chatSlice = createSlice({
  name: "chat_slice",
  initialState,
  reducers: {
    setConversation: (
      state: State,
      { payload }: PayloadAction<Identification>
    ) => {
      state.conversationId = payload;
      return state;
    },
    addMessage: (state: State, { payload }: PayloadAction<ChatMessage>) => {
      state.chatHistory = [payload, ...state.chatHistory];
      return state;
    },
    removeMessage: (state: State, { payload }: PayloadAction<ChatMessage>) => {
      state.chatHistory.filter(
        (entry) => entry.user != payload.user && entry.text != payload.text
      );
      return state;
    },
    clearChat: (state: State) => {
      state.chatHistory = [];
      return state;
    },
    addChatMessages: (
      state: State,
      { payload }: PayloadAction<Array<ChatMessage>>
    ) => {
      state.chatHistory = payload;
      return state;
    },
  },
});

export default chatSlice.reducer;
export const chatSliceActions = chatSlice.actions;
