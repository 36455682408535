import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    marginTop: 5,
  },
  center: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  formHeader: {
    width: {
      xs: "100%",
      sm: "80%",
      md: "60%",
    },
    marginX: "auto",
  },
  input: {
    minWidth: "100%",
  },
  spacing: {
    flexGrow: 1,
  },
};

export default styles;
