import twitter from "assets/svgs/social_media/twitter.svg";
import facebook from "assets/svgs/social_media/facebook.svg";
import pinterest from "assets/svgs/social_media/pinterest.svg";
import youtube from "assets/svgs/social_media/youtube.svg";
import snap from "assets/svgs/social_media/snap.svg";
import dribble from "assets/svgs/social_media/dribble.svg";
import embedd from "assets/svgs/social_media/embed.svg";
import insta from "assets/svgs/social_media/insta.svg";
import behance from "assets/svgs/social_media/behance.svg";

export type SocialMedia = {
  label: string;
  icon: string;
};

export const SocialMediaPlatforms: Array<SocialMedia> = [
  {
    label: "Youtube",
    icon: youtube,
  },
  {
    label: "Pinterest",
    icon: pinterest,
  },
  {
    label: "Behance",
    icon: behance,
  },
  {
    label: "Dribble",
    icon: dribble,
  },
  {
    label: "Snapchat",
    icon: snap,
  },
  {
    label: "Twitter",
    icon: twitter,
  },
  {
    label: "Facebook",
    icon: facebook,
  },
  {
    label: "Instagram",
    icon: insta,
  },
  {
    label: "Instagram",
    icon: insta,
  },
  {
    label: "Embedded",
    icon: embedd,
  },
];
