import { FC, PropsWithChildren, useMemo } from "react";
import { Box, SxProps } from "@mui/material";
import styles from "./styles";

type Props = {
  extraStyle?: SxProps;
};

const Card: FC<PropsWithChildren<Props>> = ({ children, extraStyle }) => {
  const containerStyle = useMemo(() => {
    return { ...styles.container, ...extraStyle };
  }, [extraStyle]);

  return <Box sx={containerStyle}>{children}</Box>;
};

export default Card;
