import { SxProps } from "@mui/material";
import color_pallette from "theme/color_pallette";

const styles: Record<string, SxProps> = {
  container: {
    borderRadius: 3,
    cursor: "pointer",
    boxShadow: 0,
    backgroundColor: color_pallette.backgroundGray,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    paddingBlock: 1.5,
    paddingInline: 1,
    zIndex: 1,
    overflow: "hidden",
  },
};

export default styles;
