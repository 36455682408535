import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import OnlineItem from "types/online_item";
import data from "./data";
import SpeakerForm, { initialFormState } from "./form";
import { VideoResponse, VideoServiceResponse } from "store/api/types";
import { View } from "store/api/view_service/types";
import { Reaction } from "types/reactions";
import { Like } from "store/api/likes_service/types";

type State = {
  dataList: Array<OnlineItem>;
  speakerForm: SpeakerForm;
  showConfirmationModal: boolean;
  currentVideo: VideoResponse;
  currentVideoViewsDetails: {
    data: Array<View>;
    viewed: boolean;
  };
  toEditVideo: VideoServiceResponse | null;
  currentVideoBookmarked: boolean;
  currentVideoBookmarksCount: number;
  currentVideoSharedCount: number;
  currentVideoViewsCount: number;
  currentVideoReaction: {
    likesDetails: Array<Like>;
    dislikesDetails: Array<Like>;
    userReact: Reaction;
    previousReact: Reaction;
    likesCount: number;
    dislikesCount: number;
  };
};

const initialState: State = {
  dataList: data,
  toEditVideo: null,
  speakerForm: initialFormState,
  currentVideoBookmarksCount: 0,
  currentVideoBookmarked: false,
  currentVideoSharedCount: 0,
  showConfirmationModal: false,
  currentVideo: {} as VideoResponse,
  currentVideoViewsCount: 0,
  currentVideoViewsDetails: { data: [], viewed: false },
  currentVideoReaction: {
    likesDetails: [],
    dislikesDetails: [],
    dislikesCount: 0,
    likesCount: 0,
    userReact: "idle",
    previousReact: "idle",
  },
};

const LivetipsOnlineSlice = createSlice({
  name: "livetipsOnline",
  initialState,
  reducers: {
    submitSpeaker: (state: State, { payload }: PayloadAction<SpeakerForm>) => {
      state.speakerForm = payload;
      state.showConfirmationModal = false;
      return state;
    },
    setToEditVideo: (
      state: State,
      { payload }: PayloadAction<VideoServiceResponse | null>
    ) => {
      state.toEditVideo = payload;
      return state;
    },
    bookmarkVideo: (state: State, { payload }: PayloadAction<boolean>) => {
      state.currentVideoBookmarked = payload;
      state.currentVideoBookmarksCount = payload
        ? state.currentVideoBookmarksCount++
        : state.currentVideoBookmarksCount--;
      return state;
    },
    submitBookmakrsCount: (
      state: State,
      { payload }: PayloadAction<number>
    ) => {
      state.currentVideoBookmarksCount = payload;
      return state;
    },
    shareVideo: (state: State) => {
      state.currentVideoSharedCount = state.currentVideoSharedCount++;
      state.currentVideo.shares_count =
        parseInt(state.currentVideo.shares_count.toString()) + 1;
      return state;
    },
    submitSharesCount: (state: State, { payload }: PayloadAction<number>) => {
      state.currentVideoSharedCount = payload;
      return state;
    },
    revertShareVideo: (state: State) => {
      state.currentVideoSharedCount = state.currentVideoSharedCount--;
      return state;
    },
    toggleBookmark: (state: State) => {
      state.currentVideoBookmarked = !state.currentVideoBookmarked;
      return state;
    },
    postVideoViewsDetails: (
      state: State,
      { payload }: PayloadAction<{ data: Array<View>; myId: string | number }>
    ) => {
      state.currentVideoViewsDetails.data = payload.data;
      const isViewed =
        payload.data.filter((entry) => entry.userId == payload.myId).length > 0;
      state.currentVideoViewsDetails.viewed = isViewed;
      return state;
    },
    addDetailedVideoView: (state: State, { payload }: PayloadAction<View>) => {
      state.currentVideoViewsDetails.data.push(payload);
      state.currentVideoViewsDetails.viewed = true;
      return state;
    },
    submitCurrentVideo: (
      state: State,
      { payload }: PayloadAction<VideoResponse>
    ) => {
      state.currentVideo = payload;
      return state;
    },
    postVideoViewsCount: (state: State, { payload }: PayloadAction<number>) => {
      state.currentVideoViewsCount = payload;
      return state;
    },
    likeVideo: (state: State) => {
      state.currentVideoReaction.previousReact =
        state.currentVideoReaction.userReact;
      state.currentVideoReaction.userReact = "liked";
      state.currentVideoReaction.likesCount =
        state.currentVideoReaction.likesCount + 1;
      return state;
    },
    disLikeVideo: (state: State) => {
      state.currentVideoReaction.previousReact =
        state.currentVideoReaction.userReact;
      state.currentVideoReaction.userReact = "disliked";
      state.currentVideoReaction.dislikesCount =
        state.currentVideoReaction.dislikesCount + 1;
      return state;
    },
    setVideoLikes: (state: State, { payload }: PayloadAction<number>) => {
      state.currentVideoReaction.likesCount =
        payload ?? state.currentVideo.likes_count;
      return state;
    },
    setVideoDisLikes: (state: State, { payload }: PayloadAction<number>) => {
      state.currentVideoReaction.dislikesCount =
        payload ?? state.currentVideo.dislikes_count;
      return state;
    },
    updateIsBookmarked: (state: State) => {
      state.currentVideo.is_bookmarked = !state.currentVideo.is_bookmarked;
      return state;
    },
    setVideoLikesReactionDetails: (
      state: State,
      { payload }: PayloadAction<{ data: Array<Like>; myId: string | number }>
    ) => {
      state.currentVideoReaction.likesDetails = payload.data;
      const res = payload.data.filter((entry) => entry.userId == payload.myId);

      state.currentVideoReaction.previousReact =
        state.currentVideoReaction.userReact;

      state.currentVideoReaction.userReact =
        res.length > 0
          ? res[0].like
            ? "liked"
            : "disliked"
          : state.currentVideoReaction.userReact;

      return state;
    },
    setVideoDisLikesReactionDetails: (
      state: State,
      { payload }: PayloadAction<{ data: Array<Like>; myId: string | number }>
    ) => {
      state.currentVideoReaction.dislikesDetails = payload.data;
      const res = payload.data.filter((entry) => entry.userId == payload.myId);

      state.currentVideoReaction.previousReact =
        state.currentVideoReaction.userReact;

      state.currentVideoReaction.userReact =
        res.length > 0
          ? res[0].like
            ? "liked"
            : "disliked"
          : state.currentVideoReaction.userReact;

      return state;
    },
    likeVideoDetails: (state: State, { payload }: PayloadAction<Like>) => {
      state.currentVideoReaction.dislikesDetails =
        state.currentVideoReaction.dislikesDetails.filter(
          (entry) => entry.likeId !== payload.likeId
        );
      state.currentVideoReaction.likesDetails.push(payload);
      return state;
    },
    disLikeVideoDetails: (state: State, { payload }: PayloadAction<Like>) => {
      state.currentVideoReaction.likesDetails =
        state.currentVideoReaction.likesDetails.filter(
          (entry) => entry.likeId !== payload.likeId
        );
      state.currentVideoReaction.dislikesDetails.push(payload);
      return state;
    },
    setReactionState: (state: State, { payload }: PayloadAction<Reaction>) => {
      state.currentVideoReaction.previousReact =
        state.currentVideoReaction.userReact;
      state.currentVideoReaction.userReact = payload;
      return state;
    },
    revertReaction: (state: State) => {
      state.currentVideoReaction.userReact =
        state.currentVideoReaction.previousReact;
      return state;
    },
    decreaseDislikes: (state: State) => {
      if (state.currentVideoReaction.dislikesCount > 0) {
        state.currentVideoReaction.dislikesCount =
          state.currentVideoReaction.dislikesCount - 1;
      }
      return state;
    },
    decreaseLikes: (state: State) => {
      if (state.currentVideoReaction.likesCount > 0) {
        state.currentVideoReaction.likesCount =
          state.currentVideoReaction.likesCount - 1;
      }
      return state;
    },
    closeModal: (state: State) => {
      state.showConfirmationModal = false;
      return state;
    },
    submitCurrentVideoRate: (
      state: State,
      { payload }: PayloadAction<number>
    ) => {
      state.currentVideo.rating = payload;
      return state;
    },
    updateVideoRate: (state: State, { payload }: PayloadAction<number>) => {
      state.currentVideo.rating = payload;
    },
  },
});

export default LivetipsOnlineSlice.reducer;
export const LivetipsOnlineSliceActions = LivetipsOnlineSlice.actions;
