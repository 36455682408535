import { defineMessages } from 'react-intl';

const scope = 'ExploreSpeakers';

export default defineMessages({
  search: {
    id: `${scope}.search`,
    defaultMessage: "Search",
  },
  no_result: {
    id: `${scope}.no_result`,
    defaultMessage: "No Speakers Were Found",
  },
});