import { useEffect } from "react";
import {
  useAddBookmarkMutation,
  useGetIsBookmarkedQuery,
} from "store/api/bookmark_service/endpoints";
import { Bookmark } from "store/api/bookmark_service/types";
import { bookmarkSliceActions } from "store/bookmarks_slice";
import { OPTIMISTIC_COMMENT_ID } from "store/comment_slice";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { LivetipsOnlineSliceActions } from "store/livetips_online_slice";
import { MEDIA_TYPE } from "types/media_type";

const useBookmark = () => {
  const dispatch = useAppDispatch();

  const {
    global: { credentials, speakerProfile },
    livetipsOnline: { currentVideo },
  } = useAppSelector((state) => state);

  const { data, isSuccess } = useGetIsBookmarkedQuery(
    {
      token: credentials.token.access_token,
      userId: speakerProfile.customerId,
      videoId: currentVideo.id,
    },
    {
      refetchOnFocus: true,
    }
  );

  const [bookmark] = useAddBookmarkMutation();

  useEffect(() => {
    if (isSuccess && data)
      dispatch(LivetipsOnlineSliceActions.bookmarkVideo(data.is_bookmarked));
  }, [dispatch, isSuccess, data]);

  const handleBookmarkVideo = async () => {
    const body = {
      userId: speakerProfile.customerId,
      videoId: currentVideo.id,
      digitalMediumType: MEDIA_TYPE.ON_DEMAND_VIDEO,
      bookmarkId: OPTIMISTIC_COMMENT_ID,
    };

    dispatch(LivetipsOnlineSliceActions.toggleBookmark());
    dispatch(bookmarkSliceActions.pushBookmark(body));

    try {
      const res = (await bookmark({
        token: credentials.token.access_token,
        body,
      })) as { data: Bookmark };
      dispatch(
        bookmarkSliceActions.replaceBookmarkId({
          type: MEDIA_TYPE.ON_DEMAND_VIDEO,
          id: res.data.bookmarkId as string,
        })
      );
    } catch (error) {
      dispatch(
        bookmarkSliceActions.revertBookmark({
          id: body.videoId,
          type: MEDIA_TYPE.ON_DEMAND_VIDEO,
        })
      );
      dispatch(LivetipsOnlineSliceActions.toggleBookmark());
    }
  };

  return {
    bookmarkVideo: handleBookmarkVideo,
  };
};

export default useBookmark;
