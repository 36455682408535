import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ActiveGallery, Step } from "./types";
import { Comment } from "store/api/comment_service/types";
import { Album } from "store/api/cms_service/types";

type State = {
  onlineVideos: Step;
  podcast: Step;
  share: boolean;
  privacyPolicy: boolean;
  showEditVideo: boolean;
  showEditPodcast: boolean;
  shared_content: {
    url: string;
  };
  editProfile: boolean;
  newUser: boolean;
  watchNow: boolean;
  showGallery: boolean;
  activeAlbum: Album | null;
  comments: { data: Array<Comment>; step_1: boolean };
  activeGalleryImage: ActiveGallery;
};

const clearedState: State = {
  showGallery: false,
  showEditPodcast: false,
  showEditVideo: false,
  newUser: false,
  activeAlbum: null,
  privacyPolicy: false,
  shared_content: {
    url: "",
  },
  activeGalleryImage: {
    title: "",
    path: "",
  },
  onlineVideos: {
    step_1: false,
    step_2: false,
    step_3: false,
    step_4: false,
    step_5: false,
  },
  podcast: {
    step_1: false,
    step_2: false,
    step_3: false,
  },
  share: false,
  editProfile: false,
  comments: {
    data: [] as Array<Comment>,
    step_1: false,
  },
  watchNow: false,
};

const initialState: State = clearedState;

const modalSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    clearAllSteps: (state: State) => {
      state = clearedState;
      return state;
    },
    setSharedContent: (state: State, { payload }: PayloadAction<string>) => {
      state.shared_content.url = payload;
      return state;
    },
    setPrivacyPolicy: (
      state: State,
      { payload }: PayloadAction<{ privacy: boolean }>
    ) => {
      state.privacyPolicy = payload.privacy;
      return state;
    },
    uploadVideoStep: (state: State, { payload }: PayloadAction<boolean>) => {
      state.onlineVideos.step_1 = payload;
      return state;
    },
    setEditPodcast: (state: State, { payload }: PayloadAction<boolean>) => {
      state.showEditPodcast = payload;
      return state;
    },
    setEditVideo: (state: State, { payload }: PayloadAction<boolean>) => {
      state.showEditVideo = payload;
      return state;
    },
    showGalleryModal: (state: State, { payload }: PayloadAction<boolean>) => {
      state.showGallery = payload;
      return state;
    },
    setActiveGalleryImage: (
      state: State,
      { payload }: PayloadAction<ActiveGallery>
    ) => {
      state.activeGalleryImage = payload;
      return state;
    },
    setActiveAlbum: (
      state: State,
      { payload }: PayloadAction<Album | null>
    ) => {
      state.activeAlbum = payload;
      return state;
    },
    editVideoStep: (state: State, { payload }: PayloadAction<boolean>) => {
      state.onlineVideos.step_2 = payload;
      return state;
    },
    watchNow: (state: State, { payload }: PayloadAction<boolean>) => {
      state.watchNow = payload;
      return state;
    },
    confirmVideoStep: (state: State, { payload }: PayloadAction<boolean>) => {
      state.onlineVideos.step_3 = payload;
      return state;
    },
    uploadPodcastStep: (state: State, { payload }: PayloadAction<boolean>) => {
      state.podcast.step_1 = payload;
      return state;
    },
    editPodcastStep: (state: State, { payload }: PayloadAction<boolean>) => {
      state.podcast.step_2 = payload;
      return state;
    },
    confirmPodcastStep: (state: State, { payload }: PayloadAction<boolean>) => {
      state.podcast.step_3 = payload;
      return state;
    },
    editProfileStep: (state: State, { payload }: PayloadAction<boolean>) => {
      state.editProfile = payload;
      return state;
    },
    shareVideoStep: (state: State, { payload }: PayloadAction<boolean>) => {
      state.share = payload;
      return state;
    },
    showComments: (
      state: State,
      { payload }: PayloadAction<{ showModal: boolean; data: Array<Comment> }>
    ) => {
      state.comments.step_1 = payload.showModal;
      state.comments.data = payload.data;
      return state;
    },
    setNewUserModal: (state: State, { payload }: PayloadAction<boolean>) => {
      state.newUser = payload;
      return state;
    },
  },
});

export default modalSlice.reducer;
export const modalSliceActions = modalSlice.actions;
