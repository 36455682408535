import { useAppDispatch, useAppSelector } from "store/hooks";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "navigation/routes";
import { ProfileSliceActions } from "store/profile_slice";
import { useEffect } from "react";
import { globalSliceActions } from "store/global_slice";
import { DispatchType } from "store";
import { FormikValues } from "formik";
import { EditProfileFormNames } from "pages/view_admin_profile/edit_profile_modal/form";
import { QueryStatus } from "@reduxjs/toolkit/query";
import { useRefreshTokenMutation } from "store/api/identity_service/endpoints";
import { LivetipsPodcastSliceActions } from "store/livetips_podcast_slice";
import { updateProfileAC } from "store/api/identity_service/action_creators";
import { Identification } from "types/identification";
import { useGetUserByIdQuery } from "store/api/auth_service/endpoints";
import { verificationSliceActions } from "store/verification_slice";
import { useGetMembershipByIdQuery } from "store/api/membership_service/endpoints";

const useViewProfile = () => {
  const dispatch = useAppDispatch();

  const {
    credentials: { token },
    speakerProfile,
  } = useAppSelector((state) => state.global);

  const { lastOTP } = useAppSelector((state) => state.otp);

  const { transactionId } = useAppSelector((state) => state.transaction);

  const { access_token, userData } = token;

  const { data, status } = useGetUserByIdQuery(
    {
      id: token.userData.username,
      token: access_token,
    },
    { refetchOnMountOrArgChange: true, refetchOnFocus: true }
  );

  const { data: speaker } = useGetMembershipByIdQuery(
    {
      id: speakerProfile.customerId,
      token: access_token,
    },
    { refetchOnMountOrArgChange: true, refetchOnFocus: true }
  );

  const [refresh] = useRefreshTokenMutation();

  const navigate = useNavigate();

  useEffect(() => {
    if (status === QueryStatus.rejected && access_token)
      refresh({ token: access_token });
    if (!access_token) navigate(NavigationRoutes.login);
    if (data) {
      dispatch(globalSliceActions.submitUserData(data));
    }
    if (speaker) {
      dispatch(ProfileSliceActions.submitCurrentMember(speaker));
    }

    return () => {
      dispatch(LivetipsPodcastSliceActions.closeModals());
      dispatch(ProfileSliceActions.closeModals());
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [access_token, speaker, data, status, dispatch, navigate]);

  const handleSubmit = async (values: object) => {
    const body = {
      about: (values as FormikValues)[EditProfileFormNames.bio],
      name: (values as FormikValues)[EditProfileFormNames.username],
      org: (values as FormikValues)[EditProfileFormNames.org],
      profilePicture: (values as FormikValues)[
        EditProfileFormNames.profilePicture
      ]
        ? (values as FormikValues)[EditProfileFormNames.profilePicture]
        : speakerProfile.profilePicture,
      coverImage: (values as FormikValues)[EditProfileFormNames.coverPicture]
        ? (values as FormikValues)[EditProfileFormNames.coverPicture]
        : speakerProfile.coverImage,
      otp: lastOTP,
      transactionId: transactionId,
      customerId: speakerProfile.customerId,
    };
    try {
      await upload(dispatch)(body, userData.id, access_token);
    } catch (error) {
      console.log(error);
    }
    dispatch(ProfileSliceActions.closeModals());
    dispatch(verificationSliceActions.setVerifyOTP(true));
  };

  return {
    handleSubmit,
    bio: speakerProfile?.about,
    name: speakerProfile.name,
    org: speakerProfile.org,
    ownerId: speakerProfile.customerId,
  };
};

export const upload =
  (dispatch: DispatchType) =>
  async (body: object, id: Identification, token: string) => {
    const res = await updateProfileAC({ body, token, id });
    if (res != null) {
      dispatch(globalSliceActions.submitUserUpdate(res.data));
    }
  };

export default useViewProfile;
