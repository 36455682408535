import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    width: {
      lg: "80%",
    },
    marginX: "auto",
    paddingTop: 5,
    paddingBottom: 10,
  },
};

export default styles;