import { FC } from "react";
import Box from "@mui/material/Box";
import OnlineWrapper from "components/online_wrapper";
import styles from "./styles";
import { UploadPrivacy } from "types/upload_privacy";
import { UploadedFileStatus } from "types/uploaded_file_status";
import { useAppSelector } from "store/hooks";
import { useIntl } from "react-intl";
import messages from "../messages";

const FilteredSection: FC = () => {
  const { categoryFilter, dateFilter, rateFilter, speakerFilter } =
    useAppSelector((state) => state.sidebar);

  const intl = useIntl();

  return (
    <Box sx={styles.container}>
      <OnlineWrapper
        paginationName={intl.formatMessage(messages.results)}
        listLabel={intl.formatMessage(messages.results)}
        videoQuery={{
          category: categoryFilter,
          date: dateFilter,
          rate: rateFilter,
          user: speakerFilter,
          privacy: UploadPrivacy.DUBAI_POLICE,
          status: UploadedFileStatus.ACCEPTED,
        }}
        dimensions={{
          xs: 12,
          sm: 6,
          lg: 4,
          xl: 3,
        }}
      />
    </Box>
  );
};

export default FilteredSection;
