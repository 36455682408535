import { FC } from "react";
import { Box, Stack, Typography } from "@mui/material";
import styles from "./styles";

type Props = {
  label: number;
};

const DigitsSection: FC<Props> = ({ label }) => {
  return (
    <Box sx={styles.container}>
      <Stack direction="row" justifyContent="space-evenly" alignItems="center">
        <Box sx={styles.digitContainer}>
          <Typography sx={styles.digitLabel}>{label.toString()}</Typography>
        </Box>
        <Box sx={styles.digitContainer}>
          <Typography sx={styles.digitLabel}>{label.toString()}</Typography>
        </Box>
        <Box sx={styles.digitContainer}>
          <Typography sx={styles.digitLabel}>{label.toString()}</Typography>
        </Box>
        <Box sx={styles.digitContainer}>
          <Typography sx={styles.digitLabel}>{label.toString()}</Typography>
        </Box>
      </Stack>
    </Box>
  );
};

export default DigitsSection;
