import { FC } from "react";
import { Modal, Paper } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import styles from "./styles";
import { FormikValues, useFormikContext } from "formik";
import { ONLINE_PREMIER_FORM } from "../form";
import { StaticTimePicker } from "@mui/x-date-pickers/StaticTimePicker";

type Props = {
  showTime: boolean;
  onPick: () => void;
};

const TimerModal: FC<Props> = ({ showTime, onPick }) => {
  const { setFieldValue } = useFormikContext<FormikValues>();

  const handleDayPick = (value: any) => {
    setFieldValue(ONLINE_PREMIER_FORM.TIME, `${value["$H"]}:${value["$m"]}`);
    onPick();
  };

  return (
    <Modal sx={styles.container} open={showTime}>
      <Paper>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <StaticTimePicker
            onAccept={handleDayPick}
            defaultValue={dayjs(new Date())}
          />
        </LocalizationProvider>
      </Paper>
    </Modal>
  );
};

export default TimerModal;
