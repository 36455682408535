import {
  Box,
  BoxProps,
  Rating,
  SxProps,
  Typography,
} from "@mui/material";
import { FC, useMemo } from "react";
import styles from "./styles";
import InteractiveIcon from "components/interactive_icon";
import ActionIcon from "components/action_icon";
import cropText from "utils/crop_text";
import InteractiveEvent from "types/interactive_item";
import color_pallette from "theme/color_pallette";
import { ReactSVG } from "react-svg";
import { useWidth } from "hooks/useWidth";
import share from "assets/svgs/share.svg";
import save from "assets/svgs/save.svg";
import HeaderSection from "./header_section";
import gpsSvg from "assets/svgs/gps_gradient.svg";
import online from "assets/svgs/support.svg";
import attendees from "assets/svgs/attendees.svg";
import { Upcoming } from "store/api/streaming_service/types";
import { useIntl } from "react-intl";
import messages from "./messages";
import NotificationsNoneRoundedIcon from "@mui/icons-material/NotificationsNoneRounded";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "navigation/routes";

enum IconSubtitleMapper {
  online = "Online",
  onLocation = "Location",
  attendees = "Attendees",
}

const IconMapper: Record<string, string> = {
  onLocation: gpsSvg,
  online: online,
  attendees: attendees,
};

type Props = {
  containerStyles?: SxProps<BoxProps>;
} & Upcoming;

const InteractiveListItem: FC<Props> = (props) => {
  const style = useMemo(() => {
    return { ...styles.container, ...props.containerStyles };
  }, [props.containerStyles]);

  const br = useWidth();

  const stats: InteractiveEvent["stats"] = {
    online: 2,
    onLocation: 3,
    attendees: 319,
  };

  const intl = useIntl();

  const navigate = useNavigate();

  const handleUpcomingDetails = () => {
    navigate(`${NavigationRoutes.upcoming_details}/${props.id}`);
  };

  return (
    <>
      <Box onClick={handleUpcomingDetails} sx={style}>
        <HeaderSection
          image={props.thumbnail}
          speakerName={props.user.name}
          thumbnailText={props.title}
          speakerAvatar={props.user.profilePicture}
        />
        <Box sx={styles.contentContainer}>
          <Typography sx={styles.contentTitle}>{props.user.name}</Typography>
          <Typography sx={styles.contentSubTitle}>
            <span
              style={{
                fontWeight: "600",
                letterSpacing: 0.5,
                color: color_pallette.primary,
              }}
            >
              {props.user.name} |
            </span>
            {cropText(
              props.description,
              br === "xs" || br === "sm" ? 200 : 115
            )}
          </Typography>
          <Box sx={styles.ratingContainer}>
            <Rating
              readOnly
              sx={{ color: color_pallette.primary }}
              size="small"
              value={parseInt(props.rating)}
            />
            <Typography sx={{ marginInlineStart: 1 }}>
              {props.rating}
            </Typography>
          </Box>
          <Box sx={styles.iconsContainer}>
            {stats &&
              Object.keys(stats).map((entry) => (
                <InteractiveIcon
                  key={entry}
                  containerStyle={styles.iconContainerStyle}
                  subTitleStyle={styles.iconSubTitle}
                  Icon={<ReactSVG src={IconMapper[entry]} />}
                  subTitle={
                    (IconSubtitleMapper as any)[
                      entry
                    ] as keyof InteractiveEvent["stats"]
                  }
                  title={(stats as any)[entry]}
                />
              ))}
          </Box>
          {/* FOOTER SECTION */}
          <Box sx={styles.footerContainer}>
            <ActionIcon
              label={intl.formatMessage(messages.listen)}
              Icon={
                <NotificationsNoneRoundedIcon
                  sx={{
                    color: color_pallette.primary,
                    width: 25,
                    height: 25,
                    marginBottom: 0.5,
                  }}
                />
              }
            />
            <ActionIcon
              label={intl.formatMessage(messages.favorites)}
              Icon={<ReactSVG src={save} />}
            />
            <ActionIcon
              label={intl.formatMessage(messages.share)}
              Icon={<ReactSVG src={share} />}
            />
          </Box>
        </Box>
      </Box>
      {/* <Box sx={styles.footerBox}>
        <CustomButton
          extraStyles={{ ...styles.enrollButton, width: upLarge ? 150 : 220 }}
          label="Enroll Now"
        />
      </Box> */}
    </>
  );
};

export default InteractiveListItem;
