import { SxProps } from "@mui/material";
import color_pallette from "theme/color_pallette";

const styles: Record<string, SxProps> = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  subHeader: {
    fontSize: { xl: 20 },
    fontWeight: "300",
  },
  header: {
    flexGrow: 0.85,
    fontSize: { xl: 35 },
    fontWeight: "600",
    background:
      "linear-gradient(94.9deg, #FE9867 20.84%, #5A834F 52.4%, #5D8495 88.64%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  search: {
    height: 40,
    width: "60%",
    borderRadius: 3,
    backgroundColor: color_pallette.backgroundGray,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingInlineStart: 3,
    marginTop: 1,
    opacity: 0.6,
    fontSize: { xl: 20 },
    marginBottom: 5,
  },
};

export default styles;
