import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    width: "100%",
    paddingInline: 10,
    paddingTop: 5,
  },
  header: {
    fontSize: {
      lg: 45,
    },
    fontWeight: "700",
    textAlign: "center",
  },
};

export default styles;
