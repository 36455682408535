import { defineMessages } from "react-intl";

const scope = "MyAlerts";

export default defineMessages({
  my_alerts: {
    id: `${scope}.my_alerts`,
    defaultMessage: "My Alerts",
  },
  profile: {
    id: `${scope}.profile`,
    defaultMessage: "My Profile",
  },
});
