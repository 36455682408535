import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    width: 60,
    height: 60,
    borderRadius: 3.5,
    backgroundColor: "#232323",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    cursor: "pointer",
  },
  icon: {
    objectFit: "contain",
  },
};

export default styles;
