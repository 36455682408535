import * as yup from "yup";

export const enum SEARCH_FORM {
  type = "type",
  q = "q",
  limit = "limit",
}

const validationSchema: yup.Schema = yup.object({
  [SEARCH_FORM.type]: yup.string(),
  [SEARCH_FORM.q]: yup.string(),
  [SEARCH_FORM.limit]: yup.string(),
});

const initialValues: yup.InferType<typeof validationSchema> = {
  [SEARCH_FORM.type]: "Online Videos",
  [SEARCH_FORM.q]: "",
  [SEARCH_FORM.limit]: "5",
};

export { validationSchema, initialValues };
