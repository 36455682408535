import { object, string } from "yup";

export enum FormNames {
  mobile = "email",
  password = "passWord",
  countryCode = "countryCode",
}

const initialValues = {
  [FormNames.mobile]: "",
  [FormNames.password]: "",
  [FormNames.countryCode]: "+971",
};

const validationSchema = object({
  [FormNames.mobile]: string().required(),
  [FormNames.password]: string().required(),
  [FormNames.countryCode]: string().required(),
});

export { initialValues, validationSchema };
