import { FC } from "react";
import OnlineWrapper from "components/online_wrapper";
import { UploadPrivacy } from "types/upload_privacy";
import Box from "@mui/material/Box";
import { UploadedFileStatus } from "types/uploaded_file_status";
import { useIntl } from "react-intl";
import messages from "../messages";

const MostViewedSection: FC = () => {
  const intl = useIntl();

  return (
    <Box sx={{ marginBlock: 1.5 }}>
      <OnlineWrapper
        paginationName="most_viewed_podcast"
        listLabel={intl.formatMessage(messages.mostviewed)}
        icon="podcast"
        emptyLabel={intl.formatMessage(messages.no_podcast)}
        limit={[3, 6]}
        skeletons={{
          xs: 12,
          sm: 6,
          md: 9,
          lg: 5.6,
          xl: 4,
        }}
        videoQuery={{
          privacy: UploadPrivacy.DUBAI_POLICE,
          status: UploadedFileStatus.ACCEPTED,
        }}
        dimensions={{
          xs: 12,
          sm: 6,
          md: 9,
          lg: 6,
          xl: 4,
        }}
      />
    </Box>
  );
};

export default MostViewedSection;
