import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  root: {
    paddingTop: 5,
    background:
      "linear-gradient(110.77deg, #008755 -10.2%, rgba(153, 191, 198, .9) 93.35%)",
  },
  thumbImage: {
    marginBottom: 2,
    borderRadius: 3,
    width: { xs: 376, lg: 476 },
    height: { xs: 300, lg: 400 },
    boxShadow: 0,
  },
  container: {
    borderRadius: 3,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginX: "auto",
    overflow: "hidden",
    paddingBlock: {
      xs: 3,
    },
    width: {
      xs: "90%",
      md: "100%",
      lg: "85%",
      xl: "70%",
    },
  },
  ratingValue: {
    fontSize: { lg: 14 },
  },
  contentContainer: {},
  viewProfile: {
    borderColor: "black",
    color: "black",
    marginBottom: { xs: 2, md: 0 },
  },
  username: { fontSize: { lg: 20 } },
  thumb: { width: 476, height: 412, marginBottom: 2 },
  path: {
    width: {
      xs: "90%",
      lg: "75%",
      xl: "61%",
    },
    marginX: "auto",
    marginBottom: 2,
  },
  description: {
    fontSize: {
      lg: 18,
    },
    marginTop: {
      lg: 1.5,
    },
  },
  subTitle: {
    fontSize: {
      lg: 15,
    },
  },
  caption: {
    fontSize: {
      lg: 40,
      xl: 47,
    },
    marginBottom: 2,
    fontWeight: "300",
  },
  headerContainer: {
    marginInlineStart: { md: 10 },
  },
  notifyButton: {
    borderRadius: 10,
    fontSize: {
      xs: 12,
    },
    marginInlineStart: 2,
  },
  avatar: {
    width: 50,
    height: 50,
  },
  speaker: {
    fontWeight: "700",
  },
  datePublished: {
    opacity: 0.7,
    marginBottom: 1,
  },
  flex: {
    marginTop: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "90%",
  },
  statsContainer: {
    display: "flex",
    flexDirection: {
      xs: "column",
      lg: "row",
    },
    width: "100%",
    justifyContent: { lg: "space-between" },
    alignItems: "center",
    rowGap: 2,
    mt: 3,
  },
  wrapper: {
    display: "flex",
    width: { xs: "80%", md: "100%" },
    flexDirection: { xs: "column", md: "row" },
    justifyContent: { md: "flex-start", lg: "flex-start" },
    alignItems: "flex-start",
    columnGap: { md: 3, lg: 8 },
  },
};

export default styles;
