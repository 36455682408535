import rootApi from "store/api";
import {
  getUserSharesCount,
  getVideoSharesCount,
  submitShare,
} from "./action_creators";
import { Share } from "./types";
import { GetById } from "../types";

export const ShareApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    addShare: builder.mutation<Share, { body: Share; token: string }>({
      query: (body) => submitShare(body),
    }),
    getVideoSharesCount: builder.query<number, GetById>({
      query: (body) => getVideoSharesCount(body),
    }),
    getUserSharesCount: builder.query<number, GetById>({
      query: (body) => getUserSharesCount(body),
    }),
  }),
});

export const {
  useAddShareMutation,
  useGetUserSharesCountQuery,
  useGetVideoSharesCountQuery,
} = ShareApi;
