import { FC } from "react";
import Box from "@mui/material/Box";
import styles from "./styles";
import { Grid, Stack, Typography } from "@mui/material";
import color_pallette from "theme/color_pallette";
import TopBlog from "components/top_blog";
import { useAppSelector } from "store/hooks";
import thumbNail from "assets/images/placeholders/thumbnail_pl.webp";
import blog_side from "assets/images/blog_side.png";

type Props = {
  label: string;
};

const Tag: FC<Props> = ({ label }) => {
  return (
    <Box
      sx={{
        borderRadius: 5,
        paddingBlock: 1,
        paddingInline: 2.5,
        border: `2px solid ${color_pallette.primary}`,
        borderColor: color_pallette.primary,
      }}
    >
      <Typography
        sx={{
          fontWeight: "800",
          fontSize: { lg: 19 },
          color: color_pallette.primary,
        }}
      >
        {label}
      </Typography>
    </Box>
  );
};

const BlogDetails: FC = () => {
  const { currentBlog, blogList } = useAppSelector((state) => state.blog);

  if (currentBlog == null) return null;

  return (
    <Box sx={styles.container}>
      <Typography
        sx={{
          fontSize: { lg: 30 },
          width: "60%",
          color: color_pallette.primary,
        }}
      >
        {currentBlog.slug}
      </Typography>
      <Stack
        direction="row"
        justifyContent="space-between"
        paddingInlineEnd={15}
        alignItems="center"
      >
        <Box
          component="img"
          src={currentBlog.main_image}
          sx={{ width: 700, height: 450, marginTop: 5, objectFit: "fill" }}
        />
        <Box component="img" src={blog_side ?? thumbNail} />
      </Stack>
      <Typography
        sx={{
          marginTop: 3,
          lineHeight: 2,
          fontSize: { lg: 23 },
          width: "80%",
        }}
      >
        {currentBlog.slug}
      </Typography>
      <Stack gap={2} direction="row" marginTop={5} flexWrap="wrap">
        <Tag label="# Mohammed bin Rashid" />
        <Tag label="# His Highness Sheikh Mohammed" />
        <Tag label="# Ruler of Dubai" />
        <Tag label="# The UAE" />
        <Tag label="# Dubai Police" />
        <Tag label="# Abdullah Al-Marri" />
      </Stack>
      <Typography sx={{ fontSize: { lg: 25 }, marginTop: 5 }}>
        Related <span style={{ fontWeight: "700" }}>Blogs</span>
      </Typography>
      <Grid container marginTop={2}>
        {blogList
          .filter((entry) => entry.id != currentBlog.id)
          .map((entry) => (
            <Grid item lg={3}>
              <TopBlog
                key={entry.id}
                img={entry.main_image ?? thumbNail}
                title={entry.slug}
              />
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};

export default BlogDetails;
