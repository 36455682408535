import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    marginTop: 1.5,
    width: "100%",
  },
  button: {
    borderImageSlice: 1,
    borderImageSource:
      "linear-gradient(113.77deg, #E5E5E5 5.61%, #A1C7CD 18.69%, #5D8495 53.99%, #FF5757 80.93%)",
    width: "100%",
    ":hover": {
      backgroundColor: "black",
    },
  },
};

export default styles;
