import { LivetipsPodcastSliceActions } from "store/livetips_podcast_slice";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useContext } from "react";
import AudioContext from "context/audio_context";

const usePodcastDetails = () => {
  const dispatch = useAppDispatch();

  const { audioFile } = useContext(AudioContext);

  const { currentRecord: storedRecord } = useAppSelector(
    (state) => state.livetipsPodcast
  );

  const handleToggleAudio = () => {
    dispatch(LivetipsPodcastSliceActions.toggleCurrentPodcast());
  };

  return {
    storedRecord,
    duration: Math.ceil(audioFile?.duration ?? 0),
    handleToggleAudio,
  };
};

export default usePodcastDetails;
