import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    textAlign: "center",
    width: { xs: "80%", lg: "80%" },
    marginX: "auto",
  },
  servicesWrapper: {
    marginBlock: 5,
  },
  header: {
    fontSize: {
      xs: 40,
    },
    marginBlock: 5,
  },
  desc: {
    fontSize: {
      xs: 23,
    },
    marginBottom: 5,
    paddingTop: 2,
    opacity: 0.8,
  },
  videoContainer: {
    marginBlock: 10,
    marginX: "auto",
    overflow: "hidden",
    borderTopLeftRadius: 100,
    borderTopRightRadius: 100,
    borderBottomLeftRadius: 100,
    width: { xs: 400, md: 700 },
    height: { xs: 300, md: 400 },
  },
};

export default styles;
