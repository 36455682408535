import { defineMessages } from 'react-intl';

const scope = 'PodcastDetails';

export default defineMessages({
  podcast_saved: {
    id: `${scope}.podcast_saved`,
    defaultMessage: "Podcast Saved",
  },
  error: {
    id: `${scope}.error`,
    defaultMessage: "Unexpected Error Occurred",
  },
  related: {
    id: `${scope}.related`,
    defaultMessage: "Related Podcasts",
  },
  relative: {
    id: `${scope}.relative`,
    defaultMessage: "Related Podcasts",
  },
  host: {
    id: `${scope}.host`,
    defaultMessage: "Host and Creators",
  },
  more: {
    id: `${scope}.more`,
    defaultMessage: "More From",
  },
  share: {
    id: `${scope}.share`,
    defaultMessage: "Share",
  },
  play_podcast: {
    id: `${scope}.play_podcast`,
    defaultMessage: "Play Podcast",
  },
  save_list: {
    id: `${scope}.save_list`,
    defaultMessage: "Save in List",
  },
  save: {
    id: `${scope}.save`,
    defaultMessage: "Save",
  },
});