import { FC } from "react";
import Box from "@mui/material/Box";
import styles from "./styles";
import SidebarSection from "./sidebar_section";
import PoliceSpeakerList from "./police_speaker_list";
import EmptyList from "components/empty_list";
import { useAppSelector } from "store/hooks";
import { useGetMembershipListQuery } from "store/api/membership_service/endpoints";
import { UploadedFileStatus } from "types/uploaded_file_status";
import { UploadPrivacy } from "types/upload_privacy";
import ScreenNavigationTracker from "components/screen_navigation_tracker";
import { data as paths } from "./data";
import { useIntl } from "react-intl";
import messages from "./messages";
import { ROLE } from "types/role";

const ExplorePoliceSpeakers: FC = () => {
  const role = useAppSelector((state) => state.global.speakerProfile.role);

  const { categoryFilter, dateFilter, rateFilter, speakerFilter } =
    useAppSelector((state) => state.sidebar);

  const { access_token } = useAppSelector(
    (state) => state.global.credentials.token
  );

  const { data, isLoading } = useGetMembershipListQuery(
    {
      token: access_token,
      category: categoryFilter,
      user: speakerFilter,
      date: dateFilter,
      rate: rateFilter,
      privacy: UploadPrivacy.DUBAI_POLICE,
      status: UploadedFileStatus.ACCEPTED,
    },
    { refetchOnFocus: true, refetchOnMountOrArgChange: true }
  );

  const intl = useIntl();

  if (role == ROLE.normal) return null;

  return (
    <>
      <Box sx={styles.container}>
        <Box mt={5} textAlign="center">
          <Box marginInlineStart={10}>
            <ScreenNavigationTracker path={paths} />
          </Box>
          <Box marginInlineStart={{ lg: 5 }}>
            <SidebarSection />
          </Box>
        </Box>
        {data?.length === 0 || !data ? (
          <EmptyList
            extraStyle={{ width: "70%", marginTop: 15 }}
            label={intl.formatMessage(messages.no_police_speakers)}
            icon="speakers"
          />
        ) : (
          <PoliceSpeakerList data={data} isLoading={isLoading} />
        )}
      </Box>
    </>
  );
};

export default ExplorePoliceSpeakers;
