import { FC } from "react";
import Box from "@mui/material/Box";
import styles from "./styles";
import { Paper, Stack, Typography } from "@mui/material";
import homeab_support from "assets/gifs/interactive.gif";
import microphone_ha from "assets/gifs/ONLINE.gif";
import podcast_ha from "assets/gifs/nomination-02.gif";
import play_ha from "assets/gifs/podcast-2.gif";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "navigation/routes";
import Button from "components/custom_button";
import { FormattedMessage, useIntl } from "react-intl";
import messages from "./messages";

const Icon: FC<{ ic: string; h: number; w: number }> = ({
  ic,
  h = 120,
  w = 120,
}) => {
  return (
    <Paper
      sx={{
        borderRadius: 3,
        border: "10px solid #FFFFFF",
        backgroundColor: "white",
        boxShadow: 3,
      }}
    >
      <Box component="img" height={h} width={w} src={ic} />
    </Paper>
  );
};

const HomeAboutSection: FC = () => {
  const navigate = useNavigate();

  const intl = useIntl();

  const handleAbout = () => {
    navigate(NavigationRoutes.aboutUs);
  };

  return (
    <Box sx={styles.container}>
      <Stack
        direction={{ lg: "row", xs: "column" }}
        width={{ lg: "85%", xs: "100%" }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box sx={{ order: { lg: 0, xs: 2 }, marginBlock: { xs: 3, lg: 0 } }}>
          <Icon h={120} w={120} ic={microphone_ha} />
        </Box>
        <Stack
          direction="column"
          marginInline={5}
          rowGap={{ lg: 6, xs: 5 }}
          alignItems="center"
        >
          <Typography
            onClick={handleAbout}
            sx={{
              fontSize: { xs: 30, lg: 40 },
              fontWeight: "500",
              cursor: "pointer",
              order: { lg: 0, xs: 0 },
              opacity: 0.8,
            }}
          >
            <FormattedMessage id={messages.about.id} />
          </Typography>
          <Typography textAlign="center" sx={styles.title}>
            <FormattedMessage id={messages.empower.id} />{" "}
            <span style={{ fontWeight: "600" }}>Live Tips</span>
            <FormattedMessage id={messages.inspiring.id} />
          </Typography>
        </Stack>
        <Box sx={{ marginTop: { xs: 3, lg: 0 } }}>
          <Icon h={150} w={170} ic={play_ha} />
        </Box>
      </Stack>
      <Stack
        direction={{ lg: "row", xs: "column" }}
        width={{ lg: "85%", xs: "100%" }}
        marginTop={{ xs: 2, lg: 1 }}
        rowGap={{ xs: 3, lg: 0 }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box sx={{ paddingTop: 3 }}>
          <Icon h={180} w={165} ic={homeab_support} />
        </Box>
        <Stack
          direction="column"
          marginInline={5}
          rowGap={6}
          alignItems="center"
        >
          <Typography sx={styles.desc}>
            <FormattedMessage id={messages.desc.id} />
          </Typography>
        </Stack>
        <Icon h={120} w={120} ic={podcast_ha} />
      </Stack>
      <Button
        onClick={handleAbout}
        extraStyles={{ marginBlock: 10 }}
        label={intl.formatMessage(messages.learnmore)}
      />
    </Box>
  );
};

export default HomeAboutSection;
