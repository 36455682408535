import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    width: "100%",
  },
  chipStyle: {
    borderRadius: "14px",
    background: "#fff",
    color: "#000",
    height: "38px",
    minWidth: "187px",
    fontSize: "16px",
    padding: "10px",
    filter: "drop-shadow(0px 4px 13px rgba(0, 0, 0, 0.10))",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  filterStack: {
    width: "100%",
    display: "flex",
    justifyContent: {
      xs: "space-around",
      md: "center",
    },
    alignItems: "center",
    flexWrap: "wrap",
    rowGap: 2,
    columnGap: {
      xs: 1,
      md: 3,
    },
    paddingY: 2,
  },
};

export default styles;
