import { ChangeEvent, FC, useRef } from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import FormGroup from "@mui/material/FormGroup";
import styles from "./styles";
import Button from "components/custom_button";
import { OnlineFormProperties } from "store/livetips_online_slice/form_properties";
import InputField from "common/input_field";
import { genderData } from "./data";
import Checkbox from "common/checkbox";
import ErrorMessage from "common/error_message";
import { FormikValues, useFormikContext } from "formik";

const UploadDocumentForm: FC = () => {
  const { errors, setFieldValue } = useFormikContext();

  const ref = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    ref?.current?.click();
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = e.target?.files?.item(0);
    if (uploadedFile != null)
      setFieldValue(OnlineFormProperties.UPLOADED_FILE, uploadedFile);
  };

  return (
    <Box sx={styles.container}>
      <Typography>Please Upload your ID, Passport Copy.</Typography>
      <input
        ref={ref}
        type="file"
        style={{ display: "none" }}
        onChange={handleChange}
        name={OnlineFormProperties.UPLOADED_FILE}
      />
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        columnGap={2}
      >
        <Button
          onClick={handleClick}
          extraStyles={{ mt: 1 }}
          label="Attach File"
        />
        {ref?.current?.value && (
          <Typography sx={styles.filename}>{ref.current.value}</Typography>
        )}
      </Stack>
      <Box mt={2}>
        <Typography sx={styles.gender}>Gender</Typography>
        <FormGroup sx={styles.genderContainer}>
          {genderData.map((entry) => (
            <Checkbox
              name={OnlineFormProperties.GENDER}
              key={entry.value}
              value={entry.value.toString()}
              label={entry.label}
            />
          ))}
        </FormGroup>
        <ErrorMessage>
          {(errors as FormikValues)[OnlineFormProperties.GENDER]}
        </ErrorMessage>
      </Box>
      <Box sx={{ marginTop: 1.5 }}>
        <Typography sx={{ font: "Inter, K2D" }}>
          Tell us about yourself
        </Typography>
        <InputField
          multiline
          rows={5}
          fullWidth
          name={OnlineFormProperties.DESCRIPTION}
          sx={{ backgroundColor: "white" }}
        />
      </Box>
    </Box>
  );
};

export default UploadDocumentForm;
