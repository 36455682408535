import { SxProps } from "@mui/material";
import color_pallette from "theme/color_pallette";

const styles: Record<string, SxProps> = {
  container: {
    backgroundColor: color_pallette.backgroundGray,
    borderRadius: 2,
    overflow: "hidden",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    padding: 1.3,
    cursor: "pointer",
    columnGap: 0.2,
    paddingInline: 1,
  },
  icons: {
    color: color_pallette.primary,
  },
  listen: { width: 15, aspectRatio: "4 / 4" },
  listenLabel: { fontSize: { xs: 13, lg: 15 }, opacity: 0.7 },
};

export default styles;
