import { FC, useState } from "react";
import styles from "./styles";
import { Stack, Typography } from "@mui/material";
import EmptyLike from "@mui/icons-material/ThumbUpOffAlt";
import EmptyDislike from "@mui/icons-material/ThumbDownOffAlt";
import FullLike from "@mui/icons-material/ThumbUpAlt";
import FullDisLike from "@mui/icons-material/ThumbDownAlt";
import { Reaction } from "types/reactions";

type Props = {
  likes: number | string;
  dislikes: number | string;
  onLike?: () => void;
  onDisLike?: () => void;
  reaction: Reaction;
};

const ReactionCounter: FC<Props> = ({
  likes,
  onDisLike,
  onLike,
  reaction,
  dislikes,
}) => {
  const [react, setReact] = useState<Reaction>(reaction);

  return (
    <Stack sx={styles.flex} columnGap={2}>
      <Stack
        onClick={() => {
          setReact("liked");
          if (onLike) onLike();
        }}
        sx={styles.flex}
        columnGap={1}
      >
        {react === "liked" ? (
          <FullLike sx={styles.full} />
        ) : (
          <EmptyLike sx={styles.empty} />
        )}
        <Typography sx={styles.counter}>{likes}</Typography>
      </Stack>
      <Stack
        onClick={() => {
          setReact("disliked");
          if (onDisLike) onDisLike();
        }}
        sx={styles.flex}
        columnGap={1}
      >
        {react === "disliked" ? (
          <FullDisLike sx={styles.full_dislike} />
        ) : (
          <EmptyDislike sx={styles.empty} />
        )}
        <Typography sx={styles.counter}>{dislikes}</Typography>
      </Stack>
    </Stack>
  );
};

export default ReactionCounter;
