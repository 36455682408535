import { useEffect } from "react";
import { useAppDispatch } from "store/hooks";
import { paginationSliceeActions } from "store/pagination_slice";

type Params = { name: string; perPage: number };

const usePagination = (params: Params) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      paginationSliceeActions.setPaginationItem({
        key: params.name,
        body: {
          perPage: params.perPage,
          page: 1,
        },
      })
    );
  }, [dispatch, params]);

  return {
    paginationKey: params.name,
  };

};

export default usePagination;
