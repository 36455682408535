import * as yup from "yup";

export enum CHAT_FORM {
  message = "message",
}

const validationSchema: yup.Schema = yup.object({
  [CHAT_FORM.message]: yup.string().max(500),
});

const initialValues: yup.InferType<typeof validationSchema> = {
  [CHAT_FORM.message]: "",
};

export { validationSchema, initialValues };
