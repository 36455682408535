import { defineMessages } from 'react-intl';

const scope = 'OnlineSection';

export default defineMessages({
  livetips_online: {
    id: `${scope}.livetips_online`,
    defaultMessage: "Online",
  },
  livetips: {
    id: `${scope}.livetips`,
    defaultMessage: "Live Tips",
  },
  title: {
    id: `${scope}.title`,
    defaultMessage: "Online",
  },
});