import { FC } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "components/card";
import styles from "./styles";
import RegisterForm from "./register_form";
import { FormattedMessage } from "react-intl";
import messages from "./messages";

const RegisterPage: FC = () => {
  return (
    <>
      <Box sx={styles.headerContainer}>
        <Typography sx={styles.font}>
          <FormattedMessage id={messages.signup.id} />
        </Typography>
      </Box>
      <Box sx={styles.container}>
        <Card extraStyle={styles.cardStyle}>
          <RegisterForm />
        </Card>
      </Box>
    </>
  );
};

export default RegisterPage;
