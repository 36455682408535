import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Calendar, DATES } from "types/date_picker";
import { Identification } from "types/identification";

export type Filter<T> = T | null;

type State = {
  dateFilter: Calendar;
  speakerFilter: Filter<Identification>;
  rateFilter: Filter<number>;
  categoryFilter: Filter<number>;
  isFiltering: boolean;
  isInitialRate: boolean;
};

export const initialDate = {
  [DATES.startDate]: null,
  [DATES.endDate]: null,
};

const initialState: State = {
  categoryFilter: null,
  rateFilter: null,
  speakerFilter: null,
  dateFilter: initialDate,
  isFiltering: false,
  isInitialRate: true,
};

const sidebarSlice = createSlice({
  name: "sidebar_slice",
  initialState,
  reducers: {
    setCategoryFilter: (
      state: State,
      { payload }: PayloadAction<number | null>
    ) => {
      state.categoryFilter = payload;
      state.isFiltering = true;
      return state;
    },
    setDateFilter: (state: State, { payload }: PayloadAction<Calendar>) => {
      state.dateFilter = payload;
      state.isFiltering = true;
      return state;
    },
    setRateFilter: (
      state: State,
      { payload }: PayloadAction<number | null>
    ) => {
      state.rateFilter = payload;
      state.isFiltering = true;
      state.isInitialRate = false;
      return state;
    },
    setSpeakerFilter: (
      state: State,
      { payload }: PayloadAction<Identification>
    ) => {
      state.speakerFilter = payload;
      state.isFiltering = true;
      return state;
    },
    reset: (state: State) => {
      state.speakerFilter = null;
      state.categoryFilter = null;
      state.dateFilter = {
        startDate: null,
        endDate: null,
      };
      state.rateFilter = null;
      state.isFiltering = false;
      state.isInitialRate = true;
      return state;
    },
  },
});

export default sidebarSlice.reducer;
export const sidebarSliceActions = sidebarSlice.actions;
