import { FC, useCallback } from "react";
import Box from "@mui/material/Box";
import styles from "./styles";
import {
  useGetLiveStreamingsQuery,
  useGetPodcastLiveStreamingsQuery,
} from "store/api/streaming_service/endpoints";
import { useAppSelector } from "store/hooks";
import { Upcoming } from "store/api/streaming_service/types";
import { SwipableInteractiveProps } from "components/swipable_interactive_item";
import withSwiper from "hoc/withSwiper";
import UpcomingLiveCard, { LiveOnlineProps } from "components/upcoming_live";
import Stack from "@mui/material/Stack";
import UpcomingLivePodcast from "components/upcoming_live_podcast";
import useLiveSwiperProps from "./swiper_props";
import live_now from "assets/images/live_now_text.png";
import live_now_ar from "assets/images/live_now_ar.png";
import live_ic from "assets/images/live_ic.png";
import { Paper, Skeleton } from "@mui/material";
import color_pallette from "theme/color_pallette";
import { UploadedFileStatus } from "types/uploaded_file_status";
import { Local } from "types/local";

const LiveSection: FC = () => {
  const { swiperProps } = useLiveSwiperProps();

  const token = useAppSelector(
    (state) => state.global.credentials.token.access_token
  );

  const {
    data,
    isLoading: isVideoLoading,
    isSuccess: isVideoSuccess,
  } = useGetLiveStreamingsQuery(
    {
      token,
      query: {
        is_live: true,
        is_expired: false,
        status: UploadedFileStatus.ACCEPTED,
      },
    },
    {
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
    }
  );

  const {
    data: podcastLive,
    isLoading: isPodcastLoading,
    isSuccess: isPodcastSuccess,
  } = useGetPodcastLiveStreamingsQuery(
    {
      token,
      query: {
        is_live: true,
        is_expired: false,
        status: UploadedFileStatus.ACCEPTED,
      },
    },
    {
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
    }
  );

  const renderSwiper = useCallback(() => {
    if (isVideoLoading || (data?.results?.length || 0) > 0) {
      return withSwiper<LiveOnlineProps, Upcoming>(
        UpcomingLiveCard,
        data?.results ?? []
      )({
        componentProps: {
          itemData: {} as Upcoming,
        },
        swiperProps,
        SkeletonComponent: (
          <Paper
            sx={{
              boxShadow: 10,
              borderRadius: 10,
              padding: 1.5,
              marginInlineStart: 10,
              overflow: "hidden",
              paddingInlineStart: 4,
              width: { xs: "100%", sm: 500, lg: "80%" },
              height: { lg: 450 },
              zIndex: 10,
              backgroundColor: color_pallette.backgroundGray,
            }}
          >
            <Skeleton
              sx={{ width: 220, borderRadius: 3, height: 80 }}
              animation="wave"
            />
            <Skeleton
              sx={{ width: 180, borderRadius: 3, height: 50 }}
              animation="wave"
            />
            <Skeleton
              sx={{ width: "70%", borderRadius: 3, height: 80 }}
              animation="wave"
            />
            <Skeleton
              sx={{ width: "90%", borderRadius: 3, height: 30 }}
              animation="wave"
            />
            <Skeleton
              sx={{ width: "90%", borderRadius: 3, height: 220 }}
              animation="wave"
            />
          </Paper>
        ),
      });
    }
    return null;
  }, [data, swiperProps]);

  const renderPodcastSwiper = useCallback(() => {
    if (isPodcastLoading || (podcastLive?.results?.length || 0) > 0) {
      return withSwiper<SwipableInteractiveProps, Upcoming>(
        UpcomingLivePodcast,
        podcastLive?.results ?? []
      )({
        componentProps: {
          itemData: {} as Upcoming,
        },
        swiperProps,
        SkeletonComponent: (
          <Paper
            sx={{
              boxShadow: 10,
              borderRadius: 10,
              padding: 1.5,
              marginInlineStart: 10,
              overflow: "hidden",
              paddingInlineStart: 4,
              width: { xs: "100%", sm: 500, lg: "80%" },
              height: { lg: 450 },
              zIndex: 10,
              backgroundColor: color_pallette.backgroundGray,
            }}
          >
            <Skeleton
              sx={{ width: 220, borderRadius: 3, height: 80 }}
              animation="wave"
            />
            <Skeleton
              sx={{ width: 180, borderRadius: 3, height: 50 }}
              animation="wave"
            />
            <Skeleton
              sx={{ width: "70%", borderRadius: 3, height: 80 }}
              animation="wave"
            />
            <Skeleton
              sx={{ width: "90%", borderRadius: 3, height: 30 }}
              animation="wave"
            />
            <Skeleton
              sx={{ width: "90%", borderRadius: 3, height: 220 }}
              animation="wave"
            />
          </Paper>
        ),
      });
    }
    return null;
  }, [podcastLive, swiperProps]);

  const { prefix } = useAppSelector((state) => state.global.lang);

  if (
    isPodcastSuccess &&
    (podcastLive?.results?.length || 0) == 0 &&
    isVideoSuccess &&
    (data?.results?.length || 0) == 0
  ) {
    return null;
  }

  return (
    <Box sx={styles.container}>
      <Stack
        direction={{ xs: "column", md: "row" }}
        sx={{ marginBlock: 5 }}
        justifyContent="center"
        alignItems="center"
        columnGap={2}
      >
        {prefix == Local.English ? (
          <>
            <Box component="img" src={live_ic} />
            <Box component="img" src={live_now} />
          </>
        ) : (
          <Box component="img" src={live_now_ar} />
        )}
      </Stack>
      <Stack
        direction={{ xs: "column", md: "row" }}
        justifyContent="center"
        marginX="auto"
        alignItems="center"
        columnGap={2}
        width={{ xs: 400, lg: "100%", xl: "75%" }}
      >
        {renderPodcastSwiper()}
        {renderSwiper()}
      </Stack>
    </Box>
  );
};

export default LiveSection;
