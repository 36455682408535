import { SxProps } from "@mui/material";
import color_pallette from "theme/color_pallette";

const styles: Record<string, SxProps> = {
  container: {
    marginTop: {
      xs: 10,
    },
  },
  insighHeader: {
    width: "100%",
    backgroundColor: color_pallette.primary,
    height: 100,
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  label: {
    fontSize: 30,
    fontWeight: "400",
  },
};

export default styles;
