import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    width: { xs: 400, md: "100%" },
    marginBlock: 10,
    paddingInline: 1.5,
    height: { xs: 500, md: "fit-content" },
    background:
      "linear-gradient(350deg, #BCE0E1 -56.82%, rgba(188, 224, 225, 0.00) 19.57%) no-repeat",
  },
};

export default styles;
