import { SxProps } from "@mui/material";
import color_pallette from "theme/color_pallette";

const styles: Record<string, SxProps> = {
  container: {
    boxShadow: 3,
    position: "relative",
    borderRadius: 6,
    cursor: "pointer",
    padding: 2,
    width: { xs: 340, lg: 300, xl: 300 },
  },
  caption: {
    fontSize: 12,
    color: "white",
    fontFamily: "Nizzar ,K2D, Inter",
    fontWeight: "500",
    letterSpacing: 0.6,
  },
  thumb: {
    backgroundColor: color_pallette.transparent,
    borderBottomLeftRadius: "15px",
    borderTopRightRadius: "15px",
    position: "absolute",
    padding: 1,
    width: { lg: 200, xl: 210 },
    height: 35,
    top: { lg: 184, xs: 211, xl: 184 },
  },
  img: {
    aspectRatio: "4 / 3",
    borderRadius: "15px",
    objectFit: "cover",
    width: {
      xs: "100%",
      lg: 270,
    },
  },
  name: {
    fontSize: {
      lg: 13,
    },
    fontWeight: "700",
  },
  desc: {
    fontSize: {
      lg: 12,
    },
    marginTop: 1,
    width: "90%",
    opacity: 0.6,
    fontWeight: "400",
  },
};

export default styles;
