import { SxProps } from "@mui/material";
import color_pallette from "theme/color_pallette";

const styles: Record<string, SxProps> = {
  container: {
    paddingInline: 1.5,
    maxWidth: {
      md: "75%",
      lg: "55%",
      xl: "45%",
    },
    flexGrow: 0.3,
    marginInlineStart: {
      md: 0,
      lg: 7,
    },
    order: {
      xs: 2,
      lg: 1,
    },
    marginTop: { xs: 5, md: 0 },
  },
  label: {
    fontSize: 30,
    fontWeight: "400",
  },
  insighHeader: {
    width: "100%",
    backgroundColor: color_pallette.primary,
    height: 100,
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBlock: { xl: 10 },
  },
  font: { fontWeight: "400" },
  sectionLabel: {
    fontSize: {
      xs: 25,
    },
    textAlign: "center",
  },
  commentContainer: { marginBlock: 2 },
  commentInput: {
    border: `1px solid ${color_pallette.cyanPrimary}`,
    backgroundColor: color_pallette.lightGray,
    marginBlock: 2,
    borderRadius: 5,
    overflow: "hidden",
  },
};

export default styles;