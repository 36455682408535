import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  menu: {
    width: 200,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    position: "absolute",
    padding: 1.5,
    rowGap: 1,
    top: 40,
    borderRadius: 3,
    zIndex: 100,
    boxShadow: 5,
    fontWeight: "400",
  },
  item: {
    color: "white",
    fontSize: {
      xl: 13,
    },
  },
};

export default styles;
