import { FC } from "react";
import { Divider, Modal, Paper, Typography } from "@mui/material";
import styles from "./styles";
import DigitsSection from "../digits_section";
import Button from "components/custom_button";
import color_pallette from "theme/color_pallette";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { LivetipsOnlineSliceActions } from "store/livetips_online_slice";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "navigation/routes";
import { FormattedMessage, useIntl } from "react-intl";
import messages from "./messages";

const ConfirmationModal: FC = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const showModal = useAppSelector(
    (state) => state.livetipsOnline.showConfirmationModal
  );

  const handleClick = () => {
    /* TODO: verify code && close modal */
    dispatch(LivetipsOnlineSliceActions.closeModal());
    navigate(NavigationRoutes.home);
  };

  const intl = useIntl();

  return (
    <Modal open={showModal} sx={styles.modal}>
      <Paper sx={styles.container}>
        <Typography sx={styles.header}>
          <FormattedMessage id={messages.otp.id} />
        </Typography>
        <Typography sx={styles.subHeader}>
          <FormattedMessage id={messages.otp.id} />
        </Typography>
        <DigitsSection label={2} />
        <Divider />
        <Button
          onClick={handleClick}
          extraStyles={styles.button}
          label={intl.formatMessage(messages.verify)}
        />
        <Typography sx={styles.resendMessage}>
          Did'nt Recieve the verification OTP ?
          <span
            style={{
              cursor: "pointer",
              color: color_pallette.primary,
            }}
          >
            <FormattedMessage id={messages.send_again.id} />
          </span>
        </Typography>
      </Paper>
    </Modal>
  );
};

export default ConfirmationModal;
