import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "navigation/routes";

const FormActionSection: FC = () => {
  const navigate = useNavigate();

  const resetPassword = () => {
    navigate(NavigationRoutes.forgotPassword);
  };

  const signup = () => {
    navigate(NavigationRoutes.signup);
  };

  return (
    <Stack
      direction="row"
      marginBlock={1.5}
      alignItems="center"
      justifyContent="space-between"
    >
      <Typography
        onClick={signup}
        sx={{ cursor: "pointer", fontSize: { lg: 13 }, opacity: 0.7 }}
      >
        Create An Account ?{" "}
      </Typography>
      <Typography
        onClick={resetPassword}
        sx={{ cursor: "pointer", fontSize: { lg: 13 }, opacity: 0.7 }}
      >
        Reset Password{" "}
      </Typography>
    </Stack>
  );
};

export default FormActionSection;
