import { FC } from "react";
import PodcastWrapper from "components/podcast_wrapper";
import messages from "../messages";
import { useIntl } from "react-intl";
import { UploadPrivacy } from "types/upload_privacy";
import { UploadedFileStatus } from "types/uploaded_file_status";

const MostViewedSection: FC = () => {
  const intl = useIntl();
  return (
    <PodcastWrapper
      sectionLabel={intl.formatMessage(messages.Most_Viewed)}
      dimensions={{
        xs: 12,
        sm: 6,
        lg: 5.8,
        xl: 5,
      }}
      limit={[3, 5]}
      videoQuery={{
        status: UploadedFileStatus.ACCEPTED,
        privacy: UploadPrivacy.PUBLIC,
      }}
    />
  );
};

export default MostViewedSection;
