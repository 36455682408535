import { SxProps } from "@mui/material";
import color_pallette from "theme/color_pallette";

const styles: Record<string, SxProps> = {
  container: {
    position: "relative",
    cursor: "pointer",
  },
  avatar: { width: 30, height: 30 },
  name: {
    color: color_pallette.primary,
    fontWeight: "500",
  },
  accountMenuAvatar: {
    width: 75,
    height: 75,
  },
  accountMenu: {
    width: 350,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "flex-start",
    paddingInline: 1.5,
    height: 140,
    position: "absolute",
    borderRadius: 3,
    boxShadow: 3,
    top: 45,
    right: 20,
    backgroundColor: "white",
    zIndex: 10,
  },
};

export default styles;
