import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {},
  header: {
    fontSize: { xs: 30, lg: 28 },
    fontFamily: "Nizzar ,K2D, Inter",
    marginBottom: { xs: 4, lg: 2.5 },
    marginInlineStart: { xs: 3.5, md: 1 },
  },
};

export default styles;