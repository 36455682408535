import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    display: "flex",
    flexDirection: {
      xs: "column",
      md: "row",
    },
    justifyContent: {
      xs: "space-between",
      lg: "flex-start",
    },
    columnGap: { lg: 5 },
    alignItems: {
      xs: "center",
      md: "flex-start",
    },
  },
};

export default styles;
