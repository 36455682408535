import {
  Avatar,
  Box,
  Stack,
  Typography,
  SxProps,
  BoxProps,
} from "@mui/material";
import { FC, useMemo } from "react";
import styles from "./styles";
import cropText from "utils/crop_text";
import Chronometer from "components/chronometer";
import useTime from "hooks/useTime";
import color_pallette from "theme/color_pallette";
import InteractiveIcon from "components/interactive_icon";
import { ReactSVG } from "react-svg";
import gpsSvg from "assets/svgs/gps_gradient.svg";
import online from "assets/svgs/support.svg";
import attendees from "assets/svgs/attendees.svg";
import InteractiveEvent from "types/interactive_item";
import Button from "components/custom_button";
import { Upcoming } from "store/api/streaming_service/types";
import { getUpcomingDate } from "utils/get_upcoming_date";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "navigation/routes";
import { NAMESPACES } from "store/api/namespaces";

export type SwipableOnlineItemProps = {
  containerStyles?: SxProps<BoxProps>;
  itemData: Upcoming;
};

enum IconSubtitleMapper {
  online = "Online",
  onLocation = "Location",
  attendees = "Attendees",
}

const IconMapper: Record<string, string> = {
  onLocation: gpsSvg,
  online: online,
  attendees: attendees,
};

const SwipableOnlineItem: FC<SwipableOnlineItemProps> = (props) => {
  const { itemData, containerStyles } = props;

  const style = useMemo(() => {
    return { ...styles.container, ...containerStyles };
  }, [containerStyles]);

  const time = useTime(getUpcomingDate(itemData.scheduled_on));

  const stats: InteractiveEvent["stats"] = {
    online: 2,
    onLocation: 3,
    attendees: 319,
  };

  const navigate = useNavigate();

  if (!itemData) return null;

  const handleUpcomingDetails = () => {
    navigate(`${NavigationRoutes.upcoming_details}/${itemData.id}`, {
      state: {
        endpoint: NAMESPACES.video,
      },
    });
  };

  return (
    <Stack
      direction="row"
      alignItems="flex-start"
      justifyContent="space-between"
    >
      <Box sx={style} onClick={handleUpcomingDetails}>
        <Box sx={styles.headerContainer}>
          <Box
            component="img"
            sx={styles.img}
            alt="upcoming online event"
            src={
              itemData.thumbnail
                ? itemData.thumbnail
                : itemData.user.profilePicture
            }
          />
        </Box>
        <Box sx={{ marginInlineStart: 2.5 }}>
          <Chronometer
            timeLabelStyle={styles.timeLabelStyle}
            timeBoxStyle={styles.timeBoxStyle}
            expiryTimestamp={time}
          />
        </Box>
        <Stack
          direction="row"
          alignItems="center"
          sx={{ marginTop: 0.5, position: "absolute", top: 10, left: 20 }}
        >
          <Avatar
            alt="upcoming interactive event host"
            sx={styles.avatarPic}
            src={itemData.user.profilePicture}
          />
          <Typography sx={styles.speakerName}>{itemData.user.name}</Typography>
        </Stack>
      </Box>
      <Stack
        direction="column"
        sx={{ marginInlineStart: 2 }}
        alignItems="flex-start"
      >
        <Typography sx={{ fontSize: { lg: 20 } }}>{itemData.title}</Typography>
        <Typography
          sx={{
            fontSize: { lg: 13 },
            marginTop: 1,
            color: color_pallette.primary,
          }}
        >
          {cropText(itemData.description, 60)}
        </Typography>
        <Typography sx={{ fontSize: { lg: 12 }, marginTop: 1 }}>
          {cropText(itemData.description, 40)}
        </Typography>
        <Stack
          direction="row"
          justifyContent="flex-start"
          columnGap={1.5}
          marginTop={2}
          alignItems="center"
        >
          <Box sx={styles.iconsContainer}>
            {stats &&
              Object.keys(stats).map((entry) => (
                <InteractiveIcon
                  key={entry}
                  containerStyle={styles.iconContainerStyle}
                  subTitleStyle={styles.iconSubTitle}
                  Icon={<ReactSVG src={IconMapper[entry]} />}
                  subTitle={
                    (IconSubtitleMapper as any)[
                      entry
                    ] as keyof InteractiveEvent["stats"]
                  }
                  title={(stats as any)[entry]}
                />
              ))}
          </Box>
        </Stack>
        <Button extraStyles={{ marginTop: 2 }} label="Join" />
      </Stack>
    </Stack>
  );
};

export default SwipableOnlineItem;
