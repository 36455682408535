import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import facebook from "assets/home/footer/facebook.svg";
import twitter from "assets/home/footer/twitter.svg";
import instagram from "assets/home/footer/instagram.svg";
import linkedin from "assets/home/footer/linkedin.svg";
import { FC } from "react";
import styles from "./styles";
import { ReactSVG } from "react-svg";
import red_logo from "assets/svgs/government-of-dubai.svg";
import whitepolice from "assets/svgs/dubai-police.svg";
import whitetips from "assets/svgs/white_live_tips.svg";
import { FormattedMessage, useIntl } from "react-intl";
import messages from "./messages";
import color_pallette from "theme/color_pallette";
import PrivacyPolicy from "components/privacy_policy";
import { useAppSelector } from "store/hooks";

export type FooterProps = {
  bgColor?: string;
  btnColor?: string;
  spaceTop?: number;
};

const FooterSection: FC<FooterProps> = ({
  /* bgColor = "linear-gradient(117deg, #E5E5E5 5.05%, #A1C7CD 26%, #5D8495 82.59%, #FF5757 125.77%)", */
  bgColor = "linear-gradient(117deg, #227C5A 22.35%, rgba(34, 124, 90, 0) 157.61%)",
  btnColor = color_pallette.primary,
  spaceTop = 50,
}) => {
  const {
    lang: { direction },
  } = useAppSelector((state) => state.global);

  const intl = useIntl();

  return (
    <>
      <Box
        component="div"
        dir={direction}
        sx={{ background: bgColor, mt: spaceTop, position: "relative" }}
      >
        <Box
          sx={{
            background: btnColor,
            ...styles.elevatedBox,
          }}
        >
          <Typography sx={styles.boxLabel}>
            <FormattedMessage id={messages.about_livetips.id} />
          </Typography>
          <Typography sx={styles.boxDesc}>
            <FormattedMessage id={messages.about.id} />
          </Typography>
        </Box>
        <Box sx={styles.gridWrapper}>
          <Grid
            container
            alignItems="center"
            mb="72px"
            justifyContent="space-between"
          >
            <Grid item md={3}>
              <Box>
                <Box width="156px" mb={7}>
                  <ReactSVG src={whitetips} />
                </Box>
                <Stack
                  direction="row"
                  spacing={1}
                  columnGap={2}
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <ReactSVG style={{ width: 130, height: 50 }} src={red_logo} />
                  <Box sx={{ width: "88px" }}>
                    <ReactSVG
                      style={{ width: 130, height: 50 }}
                      src={whitepolice}
                    />
                  </Box>
                </Stack>
              </Box>
            </Grid>
            <Grid item md={2}>
              <Box sx={styles.gridWrapper1}>
                <Typography sx={styles.colorWhite}>
                  <FormattedMessage id={messages.about_title.id} />
                </Typography>
                <Typography sx={styles.colorWhite}>
                  <FormattedMessage id={messages.home.id} />
                </Typography>
                <Typography sx={styles.colorWhite}>
                  <FormattedMessage id={messages.get_in_touch.id} />
                </Typography>
                <Typography sx={styles.colorWhite}>
                  <FormattedMessage id={messages.our_services.id} />
                </Typography>
              </Box>
            </Grid>
            <Grid item md={2}>
              <Box sx={styles.gridWrapper1}>
                <Typography sx={styles.colorWhite}>
                  <FormattedMessage id={messages.help.id} />
                </Typography>
                <Typography sx={styles.colorWhite}>
                  <FormattedMessage id={messages.testimonial.id} />
                </Typography>
                <Typography sx={styles.colorWhite}>
                  <FormattedMessage id={messages.how_it_works.id} />
                </Typography>
                <Typography sx={styles.colorWhite}>
                  <FormattedMessage id={messages.dubai_police.id} />
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              md={4}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Box sx={styles.footerWrapper}>
                <Stack direction="row" spacing={2} width="100%">
                  <input
                    type="text"
                    placeholder={intl.formatMessage(messages.enteremail)}
                    style={{
                      width: "70%",
                      height: 52,
                      backgroundColor: "#FFF",
                      border: "0",
                      outline: "none",
                      padding: "0 8px",
                    }}
                  />
                  <Box>
                    <Button sx={{ ...styles.button, background: btnColor }}>
                      <FormattedMessage id={messages.subscribe.id} />
                    </Button>
                  </Box>
                </Stack>
              </Box>
            </Grid>
          </Grid>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box sx={{ width: "30%" }}>
              <Typography sx={styles.queries}>
                <FormattedMessage id={messages.queries.id} />
              </Typography>
              <PrivacyPolicy
                hightlightColor="white"
                extraStyle={{
                  color: "white",
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
              />
            </Box>
            <Box>
              <Typography sx={styles.copyrights}>
                ©
                <FormattedMessage id={messages.copyrights.id} />
              </Typography>
            </Box>
            <Box>
              <Stack direction="row" alignItems="center" spacing={4.5}>
                <ReactSVG src={instagram} />
                <ReactSVG src={twitter} />
                <ReactSVG src={linkedin} />
                <ReactSVG src={facebook} />
              </Stack>
            </Box>
          </Stack>
        </Box>
      </Box>
    </>
  );
};

export default FooterSection;
