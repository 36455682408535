import { FC } from "react";
import { Box, Stack, Typography } from "@mui/material";
import styles from "./styles";
import UpcomingPodcast from "types/upcoming_podcast";
import InteractiveIcon from "components/interactive_icon";
import InteractiveEvent from "types/interactive_item";
import { ReactSVG } from "react-svg";
import gpsSvg from "assets/svgs/gps_gradient.svg";
import online from "assets/svgs/support.svg";
import attendees from "assets/svgs/attendees.svg";

type Props = Pick<
  UpcomingPodcast,
  "podcasterPosition" | "desc" | "title" | "podcasterName"
>;

enum IconSubtitleMapper {
  online = "Online",
  onLocation = "Location",
  attendees = "Attendees",
}

const IconMapper: Record<string, string> = {
  onLocation: gpsSvg,
  online: online,
  attendees: attendees,
};

const ContentSection: FC<Props> = ({
  desc,
  podcasterName,
  podcasterPosition,
  title,
}) => {
  const stats: InteractiveEvent["stats"] = {
    online: 2,
    onLocation: 3,
    attendees: 319,
  };

  return (
    <Stack
      direction="column"
      alignItems="center"
      textAlign="center"
      rowGap={1}
      sx={{ width: { xs: "70%" } }}
    >
      <Box sx={styles.iconsContainer}>
        {stats &&
          Object.keys(stats).map((entry) => (
            <InteractiveIcon
              key={entry}
              containerStyle={styles.iconContainerStyle}
              subTitleStyle={styles.iconSubTitle}
              Icon={<ReactSVG src={IconMapper[entry]} />}
              subTitle={
                (IconSubtitleMapper as any)[
                  entry
                ] as keyof InteractiveEvent["stats"]
              }
              title={(stats as any)[entry]}
            />
          ))}
      </Box>
      <Typography sx={styles.headerLabel}>{title}</Typography>
      <Box sx={styles.body}>
        <Typography sx={styles.name}>{podcasterName}</Typography>
        <Typography sx={styles.position}>{podcasterPosition}</Typography>
      </Box>
      <Typography sx={styles.desc}>{desc}</Typography>
    </Stack>
  );
};

export default ContentSection;
