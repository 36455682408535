import { defineMessages } from "react-intl";

const scope = "{{pascalCase folder-name}}";

export default defineMessages({
  live_interaction: {
    id: `${scope}.live_interaction`,
    defaultMessage: "Live Interactions",
  },
  interaction: {
    id: `${scope}.interaction`,
    defaultMessage: "Interactions",
  },
});
