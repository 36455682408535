import rootApi from "store/api";
import { Bookmark, isBookmarked, isBookmarkedRequest } from "./types";
import {
  getBookmarkCount,
  getBookmarksDetails,
  getIsBookmarked,
  getMediaBookmarkCount,
  getUserBookmarksCount,
  getUserBookmarksList,
  submitBookmark,
} from "./action_creators";
import { GetById } from "../types";
import { Identification } from "types/identification";
import { MEDIA_TYPE } from "types/media_type";

export const BookmarkApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    addBookmark: builder.mutation<Bookmark, { body: Bookmark; token: string }>({
      query: (body) => submitBookmark(body),
    }),
    getBookmarkCount: builder.query<number, GetById>({
      query: (body) => getBookmarkCount(body),
    }),
    getMediaBookmarkCount: builder.query<
      number,
      { id: Identification; media: MEDIA_TYPE; token: string }
    >({
      query: (body) => getMediaBookmarkCount(body),
    }),
    getUserBookmarkCount: builder.query<number, GetById>({
      query: (body) => getUserBookmarksCount(body),
    }),
    getUserBookmarksList: builder.query<Array<Bookmark>, GetById>({
      query: (body) => getUserBookmarksList(body),
    }),
    getBookmarkDetails: builder.query<Array<Bookmark>, GetById>({
      query: (body) => getBookmarksDetails(body),
    }),
    getIsBookmarked: builder.query<isBookmarked, isBookmarkedRequest>({
      query: (body) => getIsBookmarked(body),
    }),
  }),
});

export const {
  useAddBookmarkMutation,
  useGetMediaBookmarkCountQuery,
  useGetBookmarkCountQuery,
  useGetUserBookmarkCountQuery,
  useGetUserBookmarksListQuery,
  useGetIsBookmarkedQuery,
  useGetBookmarkDetailsQuery,
} = BookmarkApi;
