import { FC } from "react";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import styles from "./styles";
import online_image2 from "assets/images/placeholders/thumbnail_pl.webp";
import { FormattedMessage } from "react-intl";
import messages from "../messages";

type Props = {
  name?: string;
  image?: string;
  onClick?: () => void;
};

const HostSection: FC<Props> = ({ name, onClick, image }) => {
  return (
    <Grid
      onClick={onClick}
      sx={{ cursor: "pointer" }}
      item
      xs={6}
      lg={4}
      order={{ lg: 2 }}
    >
      <Stack alignItems="center">
        <Typography sx={styles.sectionHeader}>
          <FormattedMessage id={messages.host.id} />
        </Typography>
        <Stack alignItems="center" sx={styles.container}>
          <Avatar sx={styles.hostImage} src={image ?? online_image2} />
          <Typography sx={styles.hostName}>{name}</Typography>
        </Stack>
      </Stack>
    </Grid>
  );
};

export default HostSection;
