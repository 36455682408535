import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    width: "100%",
  },
  sectionHeader: {
    textAlign: { xs: "center", lg: "start" },
    fontSize: { xs: 30 },
    marginBlock: 3,
  },
};

export default styles;
