import { FC } from "react";
import Box from "@mui/material/Box";
import styles from "./styles";
import PodcastWrapper from "components/podcast_wrapper";
import { UploadedFileStatus } from "types/uploaded_file_status";
import { useAppSelector } from "store/hooks";
import { useIntl } from "react-intl";
import messages from "../messages";

const FilteredSection: FC = () => {
  const { dateFilter, categoryFilter, rateFilter, speakerFilter } =
    useAppSelector((state) => state.sidebar);

  const getDateFilters = (prop: "startDate" | "endDate") => {
    if (dateFilter == null) return "";
    return dateFilter[prop]?.toISOString() as any as string;
  };

  const intl = useIntl();

  return (
    <Box sx={styles.container}>
      <PodcastWrapper
        sectionLabel={intl.formatMessage(messages.results)}
        dimensions={{
          xs: 12,
          sm: 6,
          lg: 4,
          xl: 3,
        }}
        videoQuery={{
          status: UploadedFileStatus.ACCEPTED,
          category: categoryFilter,
          scheduled_on_before: getDateFilters("startDate"),
          scheduled_on_after: getDateFilters("endDate"),
          rate: rateFilter,
          user: speakerFilter,
        }}
      />
    </Box>
  );
};

export default FilteredSection;
