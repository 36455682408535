type Param = {
  passWord: string;
  mobile: string;
  countryCode: string;
};

export type LoginValidationReturnType = {
  [K in string]: string;
} & {
  passWord: string;
};

export const validateLoginCredentials = (
  value: Param
): LoginValidationReturnType | null => {
  const validatedValue = isEmail(value.mobile);

  if (validatedValue == "invalid") return null;

  const key =
    validatedValue == "mobile"
      ? `${value.countryCode}${value.mobile}`
      : value.mobile;

  return {
    [validatedValue]: key,
    passWord: value.passWord,
  };
};

export const isEmail = (value: string): "email" | "mobile" | "invalid" => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegex =
    /^\+?(\d{1,3})?[-.\s]?\(?\d{1,4}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;

  if (emailRegex.test(value)) {
    return "email";
  } else if (phoneRegex.test(value)) {
    return "mobile";
  } else {
    return "invalid";
  }
};
