import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import search from "assets/svgs/white_search.svg";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "navigation/routes";
import { useIntl } from "react-intl";
import messages from "./messages";
import { useAppSelector } from "store/hooks";

const Searchbar: FC = () => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(NavigationRoutes.search);
  };

  const { direction } = useAppSelector((state) => state.global.lang);

  const intl = useIntl();

  return (
    <Box sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
      <input
        dir={direction}
        type="text"
        placeholder={intl.formatMessage(messages.search)}
        onFocus={() => handleNavigate()}
        style={{
          borderRadius: "14px",
          backgroundColor: "#F3F3F3",
          textAlign: "left",
          outline: "none",
          border: "none",
          padding: "9px 25px",
          width: "70%",
          fontSize: "20px",
        }}
      />
      <Button
        aria-label="search button"
        sx={{
          background:
            "linear-gradient(100.26deg, #008755 16.92%, rgba(153, 191, 198, .9) 163.21%);",
          borderRadius: "14px",
          marginLeft: "11px",
          minWidth: "78px",
        }}
      >
        <img alt="search icon" src={search} />
      </Button>
    </Box>
  );
};

export default Searchbar;
