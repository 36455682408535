import { defineMessages } from 'react-intl';

const scope = 'ConfirmationModal';

export default defineMessages({
  otp: {
    id: `${scope}.otp`,
    defaultMessage:
      "We will send you 4 Digit One Time Password on your Phone number and Email",
  },
  verify: {
    id: `${scope}.verify`,
    defaultMessage: "VERIFY",
  },
  no_otp: {
    id: `${scope}.no_otp`,
    defaultMessage: "Did'nt Recieve the verification OTP ?",
  },
  send_again: {
    id: `${scope}.send_again`,
    defaultMessage: "Send Again",
  },
});