import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    width: { lg: "95%" },
    marginX: "auto",
    marginTop: 2,
    color: "white",
  },
  desc: {
    fontWeight: "600",
    fontSize: {
      lg: 18,
    },
    lineHeight: 1.3,
    maxWidth: "60%",
  },
  date: {
    fontSize: {
      lg: 25,
    },
  },
  name: {
    fontWeight: "600",
    fontSize: {
      lg: 18,
    },
  },
  header: {
    fontWeight: "700",
    fontSize: {
      lg: 50,
    },
    borderBottom: "1px solid black",
  },
};

export default styles;
