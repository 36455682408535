import { FC } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import styles from "./styles";
import useRate from "hooks/use_rate";
import { Grow, Slider } from "@mui/material";
import combineStyles from "utils/combile_styles";
import ArrowDown from "@mui/icons-material/KeyboardArrowDown";
import ArrowUp from "@mui/icons-material/KeyboardArrowUp";
import StarIcon from "@mui/icons-material/Star";
import messages from "pages/explore_speakers/sidebar_section/messages";
import { FormattedMessage } from "react-intl";

const RateFilter: FC = () => {
  const { visible, handleRateChange, selected, setVisible } = useRate();

  const getArrowIcon = () => {
    return visible ? (
      <ArrowUp onClick={() => setVisible((visible) => !visible)} />
    ) : (
      <ArrowDown onClick={() => setVisible((visible) => !visible)} />
    );
  };

  return (
    <Stack sx={combineStyles(styles.container, styles.cursor)}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={styles.cursor}
      >
        <Typography>
          <FormattedMessage id={messages.rate.id} />
        </Typography>
        {getArrowIcon()}
      </Stack>
      {visible && (
        <Grow in={true} timeout={600}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            width={"100%"}
          >
            <Slider
              sx={styles.slider}
              onChange={handleRateChange}
              aria-label="Rating"
              marks
              defaultValue={5}
              step={1}
              min={1}
              max={5}
            />
            <StarIcon sx={styles.starIcon} />
            <Typography sx={{ fontSize: { lg: 19 } }}>{selected}</Typography>
          </Stack>
        </Grow>
      )}
    </Stack>
  );
};

export default RateFilter;
