import { FC } from "react";
import {
  Box,
  BoxProps,
  SxProps,
  Typography,
  TypographyProps,
} from "@mui/material";
import { useTimer } from "react-timer-hook";
import styles from "./styles";
import ChronometerBox from "components/chronometer_box";
import combineStyles from "utils/combile_styles";

type TextStyles = SxProps<TypographyProps>;

type Props = {
  expiryTimestamp: Date;
  labelStyles?: TextStyles;
  timeLabelStyle?: TextStyles;
  timeBoxStyle?: SxProps<BoxProps>;
  onTimeExpires?: (seconds: number) => void;
};

const Chronometer: FC<Props> = ({
  expiryTimestamp,
  timeBoxStyle,
  timeLabelStyle,
  labelStyles,
  onTimeExpires,
}) => {
  const { hours, days, totalSeconds, minutes, seconds } = useTimer({
    expiryTimestamp,
    autoStart: true,
    onExpire() {
      if (onTimeExpires) onTimeExpires(totalSeconds);
    },
  });

  return (
    <Box sx={styles.root}>
      <Typography sx={combineStyles(styles.header, labelStyles)}>
        Remaining
        <span
          style={{
            marginInlineStart: 1.5,
            fontWeight: "600",
          }}
        >
          Time
        </span>
      </Typography>
      <Box sx={styles.container}>
        <ChronometerBox
          timeBoxStyle={timeBoxStyle}
          timeLabelStyle={timeLabelStyle}
          remainingTime={days}
          label="Days"
        />
        <ChronometerBox
          timeBoxStyle={timeBoxStyle}
          timeLabelStyle={timeLabelStyle}
          remainingTime={hours}
          label="Hours"
        />
        <ChronometerBox
          timeBoxStyle={timeBoxStyle}
          timeLabelStyle={timeLabelStyle}
          remainingTime={minutes}
          label="Minutes"
        />
        <ChronometerBox
          timeBoxStyle={timeBoxStyle}
          timeLabelStyle={timeLabelStyle}
          remainingTime={seconds}
          label="Seconds"
        />
      </Box>
    </Box>
  );
};

export default Chronometer;