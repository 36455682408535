import { SxProps } from "@mui/material";
import color_pallette from "theme/color_pallette";

const styles: Record<string, SxProps> = {
  container: {
    boxShadow: 3,
    borderRadius: 4,
    width: 200,
    height: "fit-content",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    columnGap: 2,
    paddingInline: 2,
    paddingBlock: 2,
    cursor: "pointer",
    ":hover": {
      backgroundColor: color_pallette.backgroundGray,
      color: "black",
    },
  },
  label: {
    fontSize: 18,
    fontWeight: "400",
    whiteSpace: "nowrap",
  },
};

export default styles;
