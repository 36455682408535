import { FC, useMemo } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import styles from "./styles";
import { useAppSelector } from "store/hooks";
import cropText from "utils/crop_text";
import PlaceholderImage from "components/placeholder_image";
import { FormikValues, useFormikContext } from "formik";
import { EditPodcastFormNames } from "../form";
import thumbNail from "assets/images/placeholders/thumbnail_pl.webp";
import { FormattedMessage } from "react-intl";
import messages from "pages/view_admin_profile/messages";

const MetaSection: FC = () => {
  const { file } = useAppSelector((state) => state.adminProfil.onlineTips);

  const { values } = useFormikContext<FormikValues>();
  const thumb = values[EditPodcastFormNames.thumbnail];

  const url = useMemo(() => {
    try {
      return URL.createObjectURL(thumb);
    } catch (error) {
      return thumbNail;
    }
  }, [thumb]);

  return (
    <Box sx={styles.container}>
      {thumb ? (
        <img width={350} height={200} src={url} />
      ) : (
        <PlaceholderImage />
      )}
      <Box sx={styles.meta}>
        <>
          <Typography>
            <FormattedMessage id={messages.link.id} />
          </Typography>
          <Typography sx={styles.link}>
            {cropText(`${file.path}`, 40)}
          </Typography>
        </>
        <Box mt={2}>
          <Typography>Filename</Typography>
          <Typography sx={styles.videoName}> {file.file?.name}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default MetaSection;
