import HeroCard from "types/hero_card";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import group_1 from "assets/images/group_1.webp";
import group_2 from "assets/images/group_2.webp";
/* import group_3 from "assets/images/group_3.webp"; */
import group_4 from "assets/images/group_4.webp";
import group_5 from "assets/images/group_5.webp";
import microphone from "assets/svgs/microphone.svg";
import { NavigationRoutes } from "navigation/routes";
import messages from "./messages";
import { FormattedMessage } from "react-intl";

const data: Array<HeroCard> = [
  {
    id: "1",
    label: messages.nomination.id,
    policeDepartement: false,
    hightlightedImage: group_4,
    path: NavigationRoutes.nominations,
    children: (
      <Stack width="90%" direction="row" alignItems="center" columnGap={2}>
        <Box sx={{ width: 50 }} component="img" src={microphone} />
        <Typography sx={{ fontSize: { lg: 10 } }}>
          <FormattedMessage id={messages.nomination_desc.id} />
        </Typography>
      </Stack>
    ),
  },
  {
    id: "2",
    label: messages.online.id,
    policeDepartement: false,
    path: NavigationRoutes.services_online_video,
    hightlightedImage: group_1,
    children: (
      <Box width="90%">
        <Typography sx={{ fontSize: { lg: 10 } }}>
          <FormattedMessage id={messages.online_desc.id} />
        </Typography>
      </Box>
    ),
  },
  {
    id: "3",
    label: messages.podcast.id,
    policeDepartement: false,
    path: NavigationRoutes.podcasts,
    hightlightedImage: group_2,
    children: (
      <Stack width="90%" direction="row" alignItems="center" columnGap={2}>
        <Box sx={{ width: 50 }} component="img" src={microphone} />
        <Typography sx={{ fontSize: { lg: 10 } }}>
          <FormattedMessage id={messages.podcast_desc.id} />
        </Typography>
      </Stack>
    ),
  },
  /* {
    id: "4",
    label: "Interactive",
    policeDepartement: false,
    path: NavigationRoutes.interactive,
    hightlightedImage: group_3,
    children: (
      <Box width="85%">
        <Typography sx={{ fontSize: { lg: 10 } }}>
          An interactive and engaging service that provide live videoing of
          events that are hosted by Dubai police
        </Typography>
      </Box>
    ),
  }, */
  {
    id: "5",
    label: messages.employees.id,
    policeDepartement: true,
    hightlightedImage: group_5,
    path: NavigationRoutes.police_speakers,
    children: (
      <Stack width="90%" direction="row" alignItems="center" columnGap={2}>
        <Box sx={{ width: 50 }} component="img" src={microphone} />
        <Typography sx={{ fontSize: { lg: 10 } }}>
          <FormattedMessage id={messages.employees_desc.id} />
        </Typography>
      </Stack>
    ),
  },
];

export default data;
