import { defineMessages } from "react-intl";

const scope = "PodcastMetaSection";

export default defineMessages({
  viewProfile: {
    id: `${scope}.viewProfile`,
    defaultMessage: "View Profile",
  },
  rating: {
    id: `${scope}.rating`,
    defaultMessage: "Rating",
  },
});
