import { NavigationRoutes } from "navigation/routes";
import { ScreenHighlight } from "types/screen_highlight";
import messages from "./messages";

export const data: Array<ScreenHighlight> = [
  {
    label: messages.services.id,
    path: NavigationRoutes.home,
  },
  {
    label: messages.podcasts.id,
    path: NavigationRoutes.podcasts,
  },
];
