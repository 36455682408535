import { FC, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import styles from "./styles";
import appLogo from "assets/svgs/logo_green.svg";
import { ReactSVG } from "react-svg";
import MenuItems from "./menu_items";
import red_logo from "assets/svgs/government-of-dubai-red_re.svg";
import IconSection from "./icon_section";
import AccountSection from "./account_section";
import police from "assets/svgs/police_logo.svg";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { globalSliceActions } from "store/global_slice";

const Appbar: FC = () => {
  const activeItem = useAppSelector((state) => state.global.activeMenuItem);

  const {
    lang: { direction },
  } = useAppSelector((state) => state.global);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(globalSliceActions.setActiveMenuItem(""));
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, [activeItem, dispatch]);

  return (
    <>
      <Box component="div" dir={direction} rowGap={1} sx={styles.container}>
        <Stack
          direction="row"
          justifyContent="space-between"
          paddingBlock={0}
          height={50}
          alignItems="flex-start"
          width="100%"
        >
          <Box
            sx={{ objectFit: "cover", width: 190 }}
            component="img"
            alt="Police Departement Logo"
            src={red_logo}
          />
          <ReactSVG style={{ width: 150 }} src={police} />
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          width="100%"
        >
          <MenuItems />
          <ReactSVG style={{ paddingInlineStart: 10 }} src={appLogo} />
          <Stack
            flexGrow={{ lg: 0.55, xl: 0.3 }}
            alignItems="flex-end"
            direction="column"
            rowGap={1.5}
          >
            <AccountSection />
            <IconSection />
          </Stack>
        </Stack>
      </Box>
      <LinearProgress
        aria-label="progress animation"
        variant="indeterminate"
        sx={styles.progress}
      />
    </>
  );
};

export default Appbar;
