import { SxProps } from "@mui/material";
import color_pallette from "theme/color_pallette";

const styles: Record<string, SxProps> = {
  container: {
    backgroundColor: color_pallette.backgroundGray,
    borderRadius: 5,
    marginTop: 2,
    paddingTop: 2,
    overflow: "hidden",
  },
  header: {
    paddingInlineStart: 3,
    fontSize: {
      lg: 22,
    },
  },
  subHeader: { paddingInlineStart: 3 },
};

export default styles;
