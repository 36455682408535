import { FC } from "react";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Grow from "@mui/material/Grow";
import NominateAction from "components/nominate_action";
import CallToJoin from "pages/view_admin_profile/call_to_join";
import styles from "./styles";
import nomination_suggestion from "assets/images/nomination_suggestion.png";
import nomination_call from "assets/images/nomination_call.svg";
import nomination_poll from "assets/images/nomination_poll.png";
import nomination_image_1 from "assets/images/nomination_image_1.png";
import PollSuggestionVote from "components/poll_suggestion_vote";
import useSnack from "hooks/useSnack";
import SnackBar from "components/snack_bar";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "navigation/routes";
import { useIntl } from "react-intl";
import messages from "../messages";

const ActionSection: FC = () => {
  const navigate = useNavigate();

  const { triggerSnack } = useSnack();

  const intl = useIntl();

  const handlePoll = () => {
    triggerSnack(intl.formatMessage(messages.coming_soon_feature), "info");
  };

  const handleSuggestion = () => {
    navigate(NavigationRoutes.nominationScope);
  };

  return (
    <Grid container rowGap={3} columnGap={10} sx={styles.container}>
      <Grid item lg={5}>
        <SnackBar />
        <Stack
          rowGap={{ xs: 2, md: 0 }}
          direction={{ xs: "column", md: "row" }}
          alignItems="center"
          columnGap={5}
        >
          <NominateAction
            onClick={handlePoll}
            icon={nomination_poll}
            label={intl.formatMessage(messages.poll)}
          />
          <NominateAction
            onClick={handleSuggestion}
            icon={nomination_suggestion}
            label={intl.formatMessage(messages.suggestion)}
          />
        </Stack>
        <Box sx={styles.callWrapper}>
          <CallToJoin nominate img={nomination_call} />
        </Box>
      </Grid>
      <Grid item lg={5}>
        <Grow in timeout={700}>
          <Box
            component="img"
            sx={{ width: { xs: 400, md: 600 } }}
            src={nomination_image_1}
          />
        </Grow>
        <PollSuggestionVote />
      </Grid>
    </Grid>
  );
};

export default ActionSection;
