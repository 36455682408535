import { defineMessages } from "react-intl";

const scope = "{{pascalCase folder-name}}";

export default defineMessages({
  userleft: {
    id: `${scope}.userleft`,
    defaultMessage: "A User Has Left The stream",
  },
  userJoined: {
    id: `${scope}.userJoined`,
    defaultMessage: "A User Has Joined The stream",
  },
  loading: {
    id: `${scope}.loading`,
    defaultMessage: "Loading Hardware...",
  },
});
