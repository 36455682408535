import { ChangeEvent, FC, useRef } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import InputField from "common/input_field";
import styles from "./styles";
import { NOMINATION_FORM_PROPERTIES } from "store/nomination_slice/form";
import { FormikValues, useFormikContext } from "formik";
import { countryList } from "utils/data";
import { FormGroup, Stack, Typography } from "@mui/material";
import Button from "components/custom_button";
import { genderData } from "../upload_document_form/data";
import ErrorMessage from "common/error_message";
import Checkbox from "common/checkbox";

const NomineeFormSetion: FC = () => {
  const { values, handleChange, setFieldValue, errors, handleBlur } =
    useFormikContext<FormikValues>();

  const inputRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    inputRef?.current?.click();
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = e.target.files?.item(0);
    if (uploadedFile != null)
      setFieldValue(NOMINATION_FORM_PROPERTIES.WEB_LINKS_FILE, uploadedFile);
  };

  return (
    <Box sx={styles.container}>
      <Typography sx={{ fontWeight: "500" }}>Nominee Information</Typography>
      <Grid container columnGap={1.5} rowGap={1} sx={{ marginTop: 2 }}>
        <Grid item xs={12} md={5.7} lg={5.8}>
          <InputField
            inputMode="text"
            label="First Name"
            name={NOMINATION_FORM_PROPERTIES.NOMINEE_FIRST_NAME}
          />
        </Grid>
        <Grid item xs={12} md={5.7} lg={5.8}>
          <InputField
            inputMode="text"
            label="Last Name"
            name={NOMINATION_FORM_PROPERTIES.NOMINEE_LAST_NAME}
          />
        </Grid>
        <Grid item xs={12} md={5.7} lg={5.8}>
          <InputField
            inputMode="text"
            label="City"
            name={NOMINATION_FORM_PROPERTIES.NOMINEE_CITY}
          />
        </Grid>
        <Grid item xs={12} md={5.7} lg={5.8}>
          <Select
            name={NOMINATION_FORM_PROPERTIES.NOMINEE_COUNTRY}
            label="Country"
            defaultValue={1}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values[NOMINATION_FORM_PROPERTIES.NOMINEE_COUNTRY]}
            sx={{ backgroundColor: "white" }}
            fullWidth
          >
            {countryList.map((entry) => (
              <MenuItem key={entry.name} value={entry.name}>
                {entry.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} md={5.7} lg={5.8}>
          <InputField
            inputMode="text"
            label="Email"
            name={NOMINATION_FORM_PROPERTIES.NOMINEE_EMAIL}
          />
        </Grid>
        <Grid item xs={12} md={5.7} lg={5.8}>
          <InputField
            inputMode="text"
            label="Telephone Number"
            name={NOMINATION_FORM_PROPERTIES.NOMINEE_TELEPHONE}
          />
        </Grid>
        <Grid item xs={12} md={5.7} lg={5.8}>
          <Box sx={styles.container}>
            <Typography>Please Upload your ID, Passport Copy.</Typography>
            <input
              ref={inputRef}
              type="file"
              style={{ display: "none" }}
              onChange={handleFileChange}
              name={NOMINATION_FORM_PROPERTIES.WEB_LINKS_FILE}
            />
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              columnGap={2}
            >
              <Button
                onClick={handleClick}
                extraStyles={{ mt: 1 }}
                label="Attach File"
              />
              {inputRef?.current?.value && (
                <Typography sx={styles.filename}>
                  {inputRef.current.value}
                </Typography>
              )}
            </Stack>
            <Box mt={2}>
              <Typography sx={styles.gender}>Gender</Typography>
              <FormGroup sx={styles.genderContainer}>
                {genderData.map((entry) => (
                  <Checkbox
                    name={NOMINATION_FORM_PROPERTIES.GENDER}
                    key={entry.value}
                    value={entry.value.toString()}
                    label={entry.label}
                  />
                ))}
              </FormGroup>
              <ErrorMessage>
                {(errors as FormikValues)[NOMINATION_FORM_PROPERTIES.GENDER]}
              </ErrorMessage>
            </Box>
            <Box sx={{ marginTop: 1.5 }}>
              <Typography sx={{ font: "Inter, K2D" }}>
                Tell us about yourself
              </Typography>
              <InputField
                multiline
                rows={5}
                fullWidth
                name={NOMINATION_FORM_PROPERTIES.DESCRIPTION}
                sx={{ backgroundColor: "white" }}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NomineeFormSetion;
