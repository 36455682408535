import { object, string } from "yup";

export enum RESETPWD_FORM {
  mobile = "mobile",
  email = "email",
  countryCode = "countryCode",
}

const initialValues = {
  [RESETPWD_FORM.mobile]: "",
  [RESETPWD_FORM.email]: "",
  [RESETPWD_FORM.countryCode]: "+971",
};

const validationSchema = object({
  [RESETPWD_FORM.mobile]: string().required(),
  [RESETPWD_FORM.email]: string().required(),
  [RESETPWD_FORM.countryCode]: string().required(),
});

export { initialValues, validationSchema };
