import { MEDIA_TYPE } from "types/media_type";
import { NAMESPACES } from "../namespaces";
import { Identification } from "types/identification";

export const bookmarkUrls = {
  postBookmark: () => `${NAMESPACES.bookmark}/bookmark`,

  getVideoBookmarsCount: (id: Identification) =>
    `${NAMESPACES.bookmark}/bookmarks/count/${MEDIA_TYPE.USER_PROFILE}/${id}`,

  getMediaBookmarsCount: (param: { id: Identification; media: MEDIA_TYPE }) =>
    `${NAMESPACES.bookmark}/bookmarks/count/${param.media}/${param.id}`,

  getUserBookmarks: (id: Identification) =>
    `${NAMESPACES.bookmark}/bookmarks/user/${id}`,

  getUserBookmarksCount: (id: Identification) =>
    `${NAMESPACES.bookmark}/bookmarks/user/count/${id}`,

  isBookmarked: ({
    userId,
    videoId,
  }: {
    videoId: Identification;
    userId: Identification;
  }) => `${NAMESPACES.bookmark}/is_bookmarked/${userId}/${videoId}`,

  getVideoBookmarksDetails: (id: Identification) =>
    `${NAMESPACES.bookmark}/bookmarks/video/${id}`,
};
