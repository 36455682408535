import { FC } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import styles from "./styles";
import { useAppSelector } from "store/hooks";
import cropText from "utils/crop_text";

const MetaSection: FC = () => {
  const { thumbnail, title } = useAppSelector(
    (state) => state.livetipsOnline.currentVideo
  );

  return (
    <Box sx={styles.container}>
      <Box
        component="img"
        src={thumbnail}
        sx={{ aspectRatio: "3 / 2", borderRadius: "15px", width: 320 }}
        width={350}
      />
      <Box sx={styles.meta}>
        <>
          <Typography>Video Link</Typography>
          <Typography sx={styles.link}>
            {cropText(`${thumbnail ?? ""}`, 35)}
          </Typography>
        </>
        <Box mt={2}>
          <Typography>Filename</Typography>
          <Typography sx={styles.videoName}>{title}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default MetaSection;
