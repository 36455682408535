import { FC } from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import styles from "./styles";
import bg from "assets/images/view_profile_hero.webp";
import avatar from "assets/images/avatar_place.webp";
import { useAppSelector } from "store/hooks";

const HeaderSection: FC = () => {
  const { currentMember } = useAppSelector((state) => state.adminProfil);
  return (
    <Box sx={styles.root}>
      <Paper
        component="img"
        loading="lazy"
        src={currentMember?.user.coverImage ?? bg}
        sx={styles.container}
      />
      <Paper sx={styles.imageContainer}>
        <Box
          sx={styles.adminImage}
          component="img"
          loading="lazy"
          src={currentMember?.user.profilePicture ?? avatar}
        />
      </Paper>
    </Box>
  );
};

export default HeaderSection;
