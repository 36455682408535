import { FC } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import styles from "./styles";
import HeaderSection from "./header_section";
import ExploreFooterSection from "components/explore_footer_section";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "navigation/routes";
import PodcastWrapper from "components/podcast_wrapper";

const PodcastSection: FC = () => {
  const navigate = useNavigate();

  const handelExplorePodcasts = () => {
    navigate(NavigationRoutes.podcasts);
  };

  return (
    <Box sx={styles.container}>
      <HeaderSection />
      <Stack
        direction="row"
        justifyContent="center"
        width={{ lg: "90%", xs: "100%" }}
        mt={15}
      >
        <PodcastWrapper
          sectionLabel=""
          limit={[0, 3]}
          skeletons={{ xs: 12, sm: 7.3, lg: 3.6 }}
          skeletonsLength={3}
          gridContainerProps={{
            justifyContent: {
              lg: "center",
              md: "flex-start",
              sm: "center",
            },
            columnGap: 1,
          }}
          dimensions={{
            xs: 12,
            sm: 7.3,
            lg: 3.9,
          }}
        />
      </Stack>
      <Stack
        direction="row"
        justifyContent="center"
        width={{ lg: "90%", xs: "100%" }}
        sx={{ marginBlock: 2 }}
      >
        <PodcastWrapper
          sectionLabel=""
          limit={[4, 7]}
          gridContainerProps={{
            justifyContent: {
              sm: "center",
              md: "flex-start",
              lg: "center",
            },
            columnGap: 1,
          }}
          skeletons={{ xs: 12, sm: 7.3, lg: 3.6 }}
          skeletonsLength={3}
          dimensions={{
            xs: 12,
            sm: 7.3,
            lg: 3.9,
          }}
        />
      </Stack>
      <Box sx={styles.footer}>
        <ExploreFooterSection onClick={handelExplorePodcasts} />
      </Box>
    </Box>
  );
};

export default PodcastSection;
