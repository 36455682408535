import { SxProps } from "@mui/material";
import color_pallette from "theme/color_pallette";

const styles: Record<string, SxProps> = {
  container: {
    position: "relative",
    textAlign: "start",
  },
  captionText: {
    color: "white",
    padding: 1,
    fontSize: {
      xs: 14,
      lg: 15,
    },
    lineHeight: 1.3,
  },
  avatarLabel: {
    color: "white",
    fontSize: { xs: 14, lg: 12 },
    marginInlineStart: 1,
  },
  thumbContainer: {
    overflow: "hidden",
    borderBottomLeftRadius: { xs: "20px", lg: "15px" },
    borderBottomRightRadius: { xs: "20px", lg: "15px" },
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
    backgroundColor: color_pallette.transparent_green,
    width: "100%",
    height: {
      xs: 260 / 2.3,
      lg: 150 / 2.3,
      xl: 280 / 2.3,
    },
    zIndex: 10,
    position: "absolute",
    left: 0,
    bottom: 4,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  speakerAvatar: {
    marginInlineStart: 1,
    width: {
      lg: 35,
    },
    height: {
      lg: 35,
    },
  },
  avatarContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    position: "absolute",
    zIndex: 10,
    left: 0,
    top: 0,
    paddingTop: 0.8,
    width: {
      xs: 220,
      lg: 240,
    },
  },
};

export default styles;
