import { Methods } from "types/http";
import { Nomination } from "./types";
import { nominationUrls } from "./urls";
import { Identification } from "types/identification";

export const submitNominee = (param: { body: Nomination; token: string }) => ({
  url: nominationUrls.postNomination(),
  method: Methods.POST,
  body: param.body,
  headers: {
    "Accept-Language": "EN",
    Accept: "*/*",
    "Content-type": "application/json",
    Authorization: `Bearer ${param.token}`,
  },
});

export const getNomineeById = (param: { id: Identification; token: string }) => ({
  url: nominationUrls.getNominationById(param.id),
  method: Methods.GET,
  headers: {
    "Accept-Language": "EN",
    Accept: "*/*",
    "Content-type": "application/json",
    Authorization: `Bearer ${param.token}`,
  },
});

export const getNomineeList = (param: { token: string }) => ({
  url: nominationUrls.getNomineeList(),
  method: Methods.GET,
  headers: {
    "Accept-Language": "EN",
    Accept: "*/*",
    "Content-type": "application/json",
    Authorization: `Bearer ${param.token}`,
  },
});
