import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    display: "flex",
    flexDirection: {
      xs: "column",
      md: "row",
    },
    justifyContent: {
      xs: "space-between",
      lg: "flex-start",
    },
    alignItems: {
      xs: "center",
      md: "flex-start",
    },
    columnGap: { xs: 3, lg: 0, xl: 3 },
  },
  mic: {
    marginBlock: 10,
    width: "100%",
  },
  emptyLabel: {
    fontWeight: "600",
    fontSize: 25,
    textAlign: "center",
  },
  footerContainer: {
    width: { xs: "80%", sm: "90%", lg: "80%", xl: "85%" },
    marginTop: 5,
  },
  gridContainer: {
    width: { xs: "90%", lg: 850, xl: 1200 },
    marginTop: 5,
    paddingInlineStart: { xl: 5 },
    rowGap: { xl: 3 },
  },
  upcomingContainer: {
    width: { md: "30%", lg: "30%", sm: "100%", xs: "90%" },
    marginTop: 5,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  gridWrapper: {
    display: "flex",
    flexDirection: {
      xs: "column",
      lg: "row",
    },
    justifyContent: {
      xs: "space-between",
      sm: "center",
    },
    alignItems: "center",
    columnGap: { xs: 3, lg: 2 },
    width: { xs: "80%", sm: "90%", lg: "93%", xl: "92%" },
  },
};

export default styles;
