import { FC } from "react";
import Grid, { GridProps } from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import styles from "./styles";

type Props = {
  length?: number;
} & GridProps;

const SkeletonCards: FC<Props> = ({ length = 6, ...args }) => {
  return (
    <Grid container columnGap={4} rowGap={3}>
      {Array.from(Array(length).keys()).map((entry) => (
        <Grid key={entry} item xs={12} sm={10} md={6} lg={4} xl={3} {...args}>
          <Grow in timeout={1000}>
            <Paper sx={styles.container}>
              <Skeleton width="100%" height={250} animation="pulse" />
              <Skeleton sx={{ width: "100%", height: 60 }} animation="pulse" />
              <Skeleton sx={{ width: "100%", height: 30 }} animation="pulse" />
            </Paper>
          </Grow>
        </Grid>
      ))}
    </Grid>
  );
};

export default SkeletonCards;
