import { FC, useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import SidebarSection from "pages/explore_speakers/sidebar_section";
import TrendingSection from "./trending_section";
import MostViewedSection from "./most_viewed_section";
import UpcomingSection from "./upcoming_section";
import SectionByCategory from "./section_by_category";
import FilteredSection from "./filtered_section";
import styles from "./styles";
import { useAppSelector } from "store/hooks";
import { ROLE } from "types/role";

const PoliceOnlineVideos: FC = () => {
  const role = useAppSelector((state) => state.global.speakerProfile.role);
  const [filter, setFilter] = useState<boolean>(false);

  const {
    categoryFilter,
    dateFilter,
    rateFilter,
    isFiltering,
    isInitialRate,
    speakerFilter,
  } = useAppSelector((state) => state.sidebar);

  const isFilter = () => {
    const res =
      categoryFilter != null ||
      rateFilter != null ||
      speakerFilter != null ||
      dateFilter?.endDate !== null ||
      dateFilter?.startDate !== null;

    setFilter(res);
  };

  useEffect(() => {
    isFilter();
  }, [
    categoryFilter,
    isInitialRate,
    isFiltering,
    rateFilter,
    dateFilter,
    speakerFilter,
  ]);

  if (role == ROLE.normal) return null;

  return (
    <Box sx={styles.container}>
      <Box sx={{ marginInlineStart: { lg: 1.5 } }}>
        <SidebarSection />
      </Box>
      {!filter ? (
        <Box>
          <Stack sx={styles.stackWrapper}>
            <Grid container sx={styles.gridContainer}>
              <TrendingSection />
              <MostViewedSection />
            </Grid>
            <Box sx={styles.upcomingContainer}>
              <UpcomingSection />
            </Box>
          </Stack>
          <Box sx={styles.footerContainer}>
            <SectionByCategory sectionHeader="Technology" category={1} />
            <SectionByCategory sectionHeader="Environment" category={2} />
          </Box>
        </Box>
      ) : (
        <FilteredSection />
      )}
    </Box>
  );
};

export default PoliceOnlineVideos;
