import { defineMessages } from "react-intl";

const scope = "EventSection";

export default defineMessages({
  make: {
    id: `${scope}.make`,
    defaultMessage: "Let's make something great work together. ",
  },
  question: {
    id: `${scope}.question`,
    defaultMessage: "Any Questions ?",
  },
  event_location_title: {
    id: `${scope}.event_location_title`,
    defaultMessage: "Event Locations",
  },
  event_location: {
    id: `${scope}.event_location`,
    defaultMessage:
      "LiveTips is an Online Platform for Students and Professionals seeking to Excel in their Skills, Learn New Abilities, and Elevate their Knowledge. By joining LiveTips, we offer you the opportunity to connect with international speakers and professionals, enabling you to contribute to the growth of our society.",
  },
});
