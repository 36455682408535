import moment from "moment";

export const enum TIME_UNIT {
  MONTH,
  DAY,
  YEAR,
  HOUR,
}

/* RETURN NUMBER OF DAYS FROM DATE TO NOW */
export function substractDates(
  date: string,
  unit: TIME_UNIT = TIME_UNIT.HOUR
): number {
  console.log(unit);
  const date1 = moment(date);
  const date2 = moment(new Date());

  switch (unit) {
    case TIME_UNIT.HOUR:
      return Math.ceil(date2.diff(date1) / 1000 / 60 / 60);
    case TIME_UNIT.DAY:
      return Math.ceil(date2.diff(date1) / 1000 / 60 / 60 / 24);
    case TIME_UNIT.MONTH:
      return Math.ceil(date2.diff(date1) / 1000 / 60 / 60 / 24 / 30);
    case TIME_UNIT.YEAR:
      return Math.ceil(date2.diff(date1) / 1000 / 60 / 60 / 24 / 30 / 12);
  }
}
