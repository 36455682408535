import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    borderRadius: 5,
    overflow: "hidden",
    width: {
      xs: 300,
    },
    paddingBottom: 1.4,
    marginBottom: 1,
  },
  contentContainer: {
    paddingInline: 2,
    paddingTop: 0.5,
  },
  img: {
    width: "100%",
    height: { xs: 200 },
    borderRadius: 5,
    objectFit: "cover",
  },
  header: {
    font: "Inter, K2D",
    fontWeight: 400,
    fontSize: 15,
  },
  position: {
    font: "Inter, K2D",
    fontWeight: 400,
    fontSize: 13,
  },
};

export default styles;
