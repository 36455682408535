import { FC } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Grow from "@mui/material/Grow";
import thumbNail from "assets/images/placeholders/thumbnail_pl.webp";
import styles from "./styles";
import { FlattenedMember } from "store/api/membership_service/endpoints";
import cropText from "utils/crop_text";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "navigation/routes";

const Nominee: FC<FlattenedMember> = (props) => {
  const navigate = useNavigate();

  const handleNomineeClick = () => {
    navigate(`${NavigationRoutes.public_speaker_profile}/${props.id}`);
  };

  return (
    <Grow in timeout={700}>
      <Box sx={styles.container} onClick={() => handleNomineeClick()}>
        <Avatar sx={styles.img} src={props.profile_picture ?? thumbNail} />
        <Typography sx={styles.header}>{cropText(props.name, 25)}</Typography>
        <Typography sx={styles.subHeader}>{cropText(props.org, 20)}</Typography>
      </Box>
    </Grow>
  );
};

export default Nominee;
