import { FC } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import styles from "./styles";
import { useSendOTPMutation } from "store/api/otp_service/endpoints";
import { useAppDispatch, useAppSelector } from "store/hooks";
import useSnack from "hooks/useSnack";
import { ErrorResponse, SendOTPResponse } from "store/api/otp_service/types";
import { transactionSliceActions } from "store/transaction_slice";

const FormActionSection: FC = () => {
  const dispatch = useAppDispatch();

  const { access_token } = useAppSelector(
    (state) => state.global.credentials.token
  );

  const { speakerProfile } = useAppSelector((state) => state.global);

  const { triggerSnack } = useSnack();

  const [sendOTP] = useSendOTPMutation();

  const handleSendOTP = async () => {
    try {
      const res = (await sendOTP({
        token: access_token,
        body: {
          email: speakerProfile.email,
          mobile: speakerProfile.mobile,
        },
      })) as SendOTPResponse;
      console.log("handleSendOTP res ----->", res);
      if (res.error.status > 199 && res.error.status < 400) {
        dispatch(
          transactionSliceActions.setCurrentTransactionId(
            res.error.data as string
          )
        );
      } else {
        triggerSnack(`${(res.error.data as ErrorResponse).message}`, "error");
      }
    } catch (error: any) {
      console.log(error);
      triggerSnack(`${error.response.data.messsage}`, "error");
    }
  };

  return (
    <Box sx={styles.flex}>
      <Box sx={styles.onHover} onClick={handleSendOTP}>
        <Typography sx={styles.textFormControlls}>Send Again</Typography>
      </Box>
    </Box>
  );
};

export default FormActionSection;
