import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SCHEDULE_TYPE } from "context/schedule_context";
import {
  Channel,
  LiveStreaming,
  Upcoming,
} from "store/api/streaming_service/types";

export type MetaChannel = {
  channel: Channel | null;
  name: string;
  token: string;
  appId: string;
};

type State = {
  currentChannel: MetaChannel;
  upcoming: Upcoming | null;
  liveStreaming: Partial<LiveStreaming>;
  type: SCHEDULE_TYPE;
  scheduledOn: string;
};

const initialState: State = {
  currentChannel: {
    channel: null,
    appId: "APP_ID",
    name: "cdp",
    token: "",
  },
  upcoming: null,
  type: SCHEDULE_TYPE.PRERECORDED,
  scheduledOn: "",
  liveStreaming: {},
};

const channelSlice = createSlice({
  name: "channel_slice",
  initialState,
  reducers: {
    setCurrentChannel: (
      state: State,
      { payload }: PayloadAction<MetaChannel>
    ) => {
      state.currentChannel = payload;
      return state;
    },
    setUpcoming: (state: State, { payload }: PayloadAction<Upcoming>) => {
      state.upcoming = payload;
      return state;
    },
    setScheduledOn: (state: State, { payload }: PayloadAction<string>) => {
      state.scheduledOn = payload;
      return state;
    },
    setLiveStreamType: (
      state: State,
      { payload }: PayloadAction<SCHEDULE_TYPE>
    ) => {
      state.type = payload;
      return state;
    },
    setLiveStreaming: (
      state: State,
      { payload }: PayloadAction<Partial<LiveStreaming>>
    ) => {
      state.liveStreaming = { ...state.liveStreaming, ...payload };
      return state;
    },
    setCurrentChannelToken: (
      state: State,
      { payload }: PayloadAction<string>
    ) => {
      state.currentChannel.token = payload;
      return state;
    },
    setCurrentChannelData: (
      state: State,
      { payload }: PayloadAction<Channel>
    ) => {
      state.currentChannel.channel = payload;
      return state;
    },
  },
});

export default channelSlice.reducer;
export const channelSliceActions = channelSlice.actions;
