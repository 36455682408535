import { SxProps } from "@mui/material";
import color_pallette from "theme/color_pallette";

const styles: Record<string, SxProps> = {
  container: {},
  icon: { height: 70, objectFit: "contain", width: 70 },
  typeWrapper: {
    backgroundColor: color_pallette.backgroundGray,
    width: 200,
    paddingBlock: 2,
    borderRadius: 3,
  },
  checkbox: { width: 30, height: 30, marginInlineEnd: 1.5 },
  label: {
    fontSize: {
      xl: 12,
    },
    marginTop: 1.5,
  },
};

export default styles;
