import { FC, ReactNode, useMemo } from "react";
import styles from "./styles";
import { Box } from "@mui/material";
import messages from "./messages";
import { NavigationRoutes } from "navigation/routes";
import { useAppSelector } from "store/hooks";
import { ROLE } from "types/role";
import { UploadPrivacy } from "types/upload_privacy";
import { useIntl } from "react-intl";
import BasicTabs from "common/tab_container";
import { Identification } from "types/identification";
import { LIST_TYPE } from "types/list_types";
import OnlineWrapper from "components/online_wrapper";
import PodcastWrapper from "components/podcast_wrapper";
import ScreenNavigationTracker from "components/screen_navigation_tracker";

export type TabContent = {
  id: number;
  index: number;
  label: string;
  Content: ReactNode;
  listType: LIST_TYPE;
  path: NavigationRoutes;
};

const getTabs = (userId: Identification, role: ROLE): Array<TabContent> => {
  return [
    {
      id: 1,
      index: 0,
      label: "",
      listType: LIST_TYPE.ONLINE,
      path: NavigationRoutes.myVideos,
      Content: (
        <OnlineWrapper
          paginationName="my_videos_3"
          videoQuery={{
            user: userId,
            privacy:
              role == ROLE.normal
                ? UploadPrivacy.PUBLIC
                : UploadPrivacy.DUBAI_POLICE,
          }}
          listLabel=""
          emptyLabel="No Recorded Videos"
        />
      ),
    },
    {
      id: 2,
      index: 1,
      path: NavigationRoutes.myPodcasts,
      label: "",
      listType: LIST_TYPE.PODCAST,
      Content: (
        <PodcastWrapper
          videoQuery={{
            user: userId,
            privacy:
              role == ROLE.normal
                ? UploadPrivacy.PUBLIC
                : UploadPrivacy.DUBAI_POLICE,
          }}
          dimensions={{ lg: 4.8 }}
          sectionLabel=""
          icon="podcast"
          emptyLabel="No Podcasts"
        />
      ),
    },
    /* {
      id: 3,
      index: 2,
      label: "My Streams",
      listType: LIST_TYPE.INTERACTIVE,
      path: NavigationRoutes.myStreams,
      Content: <InteractiveWrapper />,
    }, */
  ];
};

const MyAlerts: FC = () => {
  const {
    speakerProfile: { customerId, role },
  } = useAppSelector((state) => state.global);

  const tabs = useMemo(() => {
    return getTabs(customerId, role);
  }, [customerId, role]);

  const intl = useIntl();

  return (
    <Box sx={styles.container}>
      <Box sx={{ paddingInlineStart: 20, paddingBottom: 2 }}>
        <ScreenNavigationTracker
          path={[
            {
              label: intl.formatMessage(messages.profile),
              path: NavigationRoutes.profile,
            },
            {
              label: intl.formatMessage(messages.my_alerts),
              path: NavigationRoutes.alerts,
            },
          ]}
        />
      </Box>
      <BasicTabs content={tabs} />
    </Box>
  );
};

export default MyAlerts;
