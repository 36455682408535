import { Methods } from "types/http";
import { CategoryItem } from "../types";
import apiUrls from "../urls";

export const submitCategoryAction = (body: CategoryItem) => ({
  url: apiUrls.addCategory(),
  method: Methods.POST,
  body,
  headers: {
    Lng: "EN",
    "Content-type": "application/json",
    Accept: "*/*",
  },
});

export const getAllCategoriesAction = (param: { token: string }) => ({
  url: apiUrls.findAllCategories(),
  method: Methods.GET,
  headers: {
    Lng: "EN",
    "Content-type": "application/json",
    Accept: "*/*",
    Authorization: param.token,
  },
});

export const getCustomerAction = (id: string) => ({
  url: apiUrls.getCustomer(id),
  method: Methods.GET,
  headers: {
    Lng: "EN",
    "Content-type": "application/json",
    Accept: "*/*",
  },
});
