import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    width: "90%",
    marginX: "auto",
    marginTop: 10,
  },
};

export default styles;
