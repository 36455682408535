import empty_podcasts from "assets/images/placeholders/empty_podcasts.webp";
import empty_interactive from "assets/images/placeholders/empty_interactive.webp";
import empty_online from "assets/images/placeholders/empty_online.webp";
import emptySpeakers from "assets/images/placeholders/empty_interactive.webp";

export const EmptyListIconMapper: Record<string, string> = {
  podcast: empty_podcasts,
  interactive: empty_interactive,
  online: empty_online,
  upcoming: empty_online,
  speakers: emptySpeakers,
};

export type EmptyIconTypes =
  | "online"
  | "upcoming"
  | "interactive"
  | "podcast"
  | "speakers";
