import { FC } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import styles from "./styles";
import lessons from "assets/gifs/lessons_gif.gif";

const FeaturedHost: FC = () => {
  return (
    <Paper sx={styles.container}>
      <Box component="img" sx={{ height: 815 }} src={lessons} />
    </Paper>
  );
};

export default FeaturedHost;
