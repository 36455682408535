import { FC } from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import styles from "./styles";
import BodySection from "./body_section";
import ScreenNavigationTracker from "components/screen_navigation_tracker";
import { data } from "./data";
import { FormattedMessage } from "react-intl";
import messages from "./messages";

const Blog: FC = () => {
  return (
    <Box sx={styles.container}>
      <Stack
        width={{ lg: "80%" }}
        marginBlock={5}
        direction="row"
        alignItems="center"
      >
        <ScreenNavigationTracker path={data} />
      </Stack>
      <Box sx={styles.search}>Search</Box>
      <Stack direction="row" mt={2} width="80%" justifyContent="flex-start">
        <Typography sx={styles.subHeader}>
          <FormattedMessage id={messages.Trending.id} />{" "}
          <span style={{ fontWeight: "700" }}>
            <FormattedMessage id={messages.Blogs.id} />
          </span>
        </Typography>
      </Stack>
      <BodySection />
    </Box>
  );
};

export default Blog;
