import { FC } from "react";
import Box from "@mui/material/Box";
import { UploadedFileStatus } from "types/uploaded_file_status";
import { useAppSelector } from "store/hooks";
import { UploadPrivacy } from "types/upload_privacy";
import styles from "./styles";
import PodcastWrapper from "components/podcast_wrapper";
import { VideoQuery } from "types/video_query";
import { useIntl } from "react-intl";
import messages from "../messages";

const query: VideoQuery = {
  status: UploadedFileStatus.ACCEPTED,
  privacy: UploadPrivacy.PUBLIC,
};

const RelatedPodcastsSection: FC = () => {
  const token = useAppSelector(
    (state) => state.global.credentials.token.access_token
  );

  const intl = useIntl();

  return (
    <Box sx={styles.container}>
      <Box sx={styles.listContainer} mt={15}>
        <PodcastWrapper
          sectionLabel={intl.formatMessage(messages.related)}
          limit={[0, 3]}
          videoQuery={{
            ...query,
            token,
          }}
          dimensions={{
            xs: 11,
            sm: 10,
            md: 5.9,
            lg: 3.5,
            xl: 3.8,
          }}
        />
      </Box>
      <Box sx={styles.listContainer} mt={15}>
        <PodcastWrapper
          sectionLabel={intl.formatMessage(messages.relative)}
          limit={[0, 3]}
          videoQuery={{
            ...query,
            token,
          }}
          dimensions={{
            xs: 11,
            sm: 10,
            md: 5.9,
            lg: 3.5,
            xl: 3.8,
          }}
        />
      </Box>
    </Box>
  );
};

export default RelatedPodcastsSection;
