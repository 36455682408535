import { FC } from "react";
import Grid from "@mui/material/Grid";
import styles from "./styles";
import { UploadedFileStatus } from "types/uploaded_file_status";
import { UploadPrivacy } from "types/upload_privacy";
import { useGetLiveStreamingsQuery } from "store/api/streaming_service/endpoints";
import { useAppSelector } from "store/hooks";
import InteractiveListItem from "components/interactive_list_item";
import EmptyList from "components/empty_list";
import messages from "../messages";
import { useIntl } from "react-intl";
import { ROLE } from "types/role";

const BodySection: FC = () => {
  const token = useAppSelector(
    (state) => state.global.credentials.token.access_token
  );

  const { role } = useAppSelector((state) => state.global.speakerProfile);

  const intl = useIntl();

  const { data } = useGetLiveStreamingsQuery(
    {
      query: {
        status: UploadedFileStatus.ACCEPTED,
        privacy:
          role == ROLE.normal
            ? UploadPrivacy.PUBLIC
            : UploadPrivacy.DUBAI_POLICE,
        is_expired: false,
        is_live: false,
      },
      token,
    },
    {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
    }
  );

  const interactiveList = data?.results ?? [];

  return (
    <Grid
      sx={styles.container}
      justifyContent="stretch"
      alignItems="center"
      rowGap={10}
      columnGap={{ md: 2, lg: 7, xl: 2 }}
      container
    >
      {interactiveList && interactiveList.length ? (
        interactiveList.map((entry) => (
          <Grid xl={3.9} item key={entry.id}>
            <InteractiveListItem {...entry} />
          </Grid>
        ))
      ) : (
        <EmptyList
          icon="interactive"
          label={intl.formatMessage(messages.empty_result)}
        />
      )}
    </Grid>
  );
};

export default BodySection;
