import { defineMessages } from 'react-intl';

const scope = 'ActionSection';

export default defineMessages({
  error: {
    id: `${scope}.error`,
    defaultMessage: "Unexpected Error Occured, Please upload new file !",
  },
  token_success: {
    id: `${scope}.token_success`,
    defaultMessage: "Token Generated Successfully ",
  },
  success: {
    id: `${scope}.success`,
    defaultMessage: "Successfully created Live Stream ",
  },
});