import { FC, ReactNode } from "react";
import { Box } from "@mui/material";
import { IAgoraRTCRemoteUser } from "agora-rtc-react";
import { useAppSelector } from "store/hooks";
import "./style.css";

type Props = {
  remoteUsers: Array<IAgoraRTCRemoteUser>;
  localUser: ReactNode;
};

const VideoGrid: FC<Props> = ({ remoteUsers, localUser }) => {
  const { access_token } = useAppSelector(
    (state) => state.global.credentials.token
  );

  return (
    <div className={`video-grid participants-${remoteUsers.length}`}>
      <>
        {access_token && localUser}
        {remoteUsers.map((remoteUser, index) => (
          <Box key={index} className="video-tile">
            <>
              {remoteUser.videoTrack?.play(`remote-video-${index}`)}
              <p>{remoteUser.uid}</p>
            </>
          </Box>
        ))}
      </>
    </div>
  );
};

export default VideoGrid;