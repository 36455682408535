import { FC } from "react";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Grow from "@mui/material/Grow";
import styles from "./styles";
import { useAppDispatch, useAppSelector } from "store/hooks";
import HeaderSection from "./header_section";
import { EditLiveStreamModal } from "./form";
import Checkbox from "common/checkbox";
import { FormikValues, useFormikContext } from "formik";
import InputField from "common/input_field";
import MetaSection from "./meta_section";
import SubmitButton from "common/submit_button";
import { ProfileSliceActions } from "store/profile_slice";
import SnackBar from "components/snack_bar";
import UploadThumbSection from "./upload_thumb_section";
import { useGetAllCategoriesQuery } from "store/api/categorie_service/endpoints";
import { UploadPrivacy } from "types/upload_privacy";

const EditLiveStreamingModal: FC = () => {
  const dispatch = useAppDispatch();

  const showModal = useAppSelector(
    (state) => state.adminProfil.modals.showEditLiveStream
  );

  const { handleChange, handleBlur, values } = useFormikContext<FormikValues>();

  const handleBack = () => {
    dispatch(ProfileSliceActions.showOnlinePremier());
  };

  const token = useAppSelector(
    (state) => state.global.credentials.token.access_token
  );

  const { data } = useGetAllCategoriesQuery({
    token,
  });

  return (
    <Modal open={showModal} sx={styles.container}>
      <Grow in={showModal} timeout={600}>
        <Paper sx={styles.paper}>
          <HeaderSection onBackClick={handleBack} confirmation={false} />
          <SnackBar />
          <Stack sx={styles.bodyContainer}>
            <MetaSection />
            <Stack sx={styles.formContainer}>
              <UploadThumbSection />
              <InputField
                inputMode="text"
                label="Video Title"
                fullWidth
                sx={styles.bg}
                name={EditLiveStreamModal.videoTitle}
              />
              <InputField
                inputMode="text"
                label="Description"
                fullWidth
                multiline
                rows={4}
                sx={styles.bg}
                name={EditLiveStreamModal.description}
              />
              <Select
                name={EditLiveStreamModal.category}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values[EditLiveStreamModal.category]}
                defaultValue={1}
                sx={styles.bg}
                fullWidth
              >
                {data &&
                  data.length > 0 &&
                  data.map((entry) => (
                    <MenuItem key={entry.categoryId} value={entry.categoryId}>
                      {entry.nameEn}
                    </MenuItem>
                  ))}
              </Select>
              <Stack direction="row">
                <Checkbox
                  value={UploadPrivacy.DUBAI_POLICE}
                  label={"Dubai Police"}
                  name={EditLiveStreamModal.exclusivity}
                />
                <Checkbox
                  value={UploadPrivacy.PUBLIC}
                  label={"Public"}
                  name={EditLiveStreamModal.exclusivity}
                />
              </Stack>
            </Stack>
          </Stack>
          <SubmitButton extraStyles={styles.nextButton} title="Next" />
        </Paper>
      </Grow>
    </Modal>
  );
};

export default EditLiveStreamingModal;
