import Appbar from "components/appbar";
import FooterSection from "pages/home/footer_section";
import { FC } from "react"

const NotFound: FC = () => {
    return (
        <>
            <Appbar />
            <div style={{ display: "flex", width: "100vw", height: "100vh", justifyContent: 'center' }}>
                <h2 style={{ alignSelf: 'center' }}>404 - Page Not Found</h2>
            </div>
            <FooterSection spaceTop={0} />
        </>
    );
}

export default NotFound