import { FC } from "react";
import Rate from "components/rate";
import { usePostRateMutation } from "store/api/rate_service/endpoints";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { RatingSliceActions } from "store/rate_slice";
import { MEDIA_TYPE } from "types/media_type";
import { Rate as RatingType } from "store/api/rate_service/types";
import { LivetipsOnlineSliceActions } from "store/livetips_online_slice";
import useSnack from "hooks/useSnack";

const VideoRate: FC = () => {
  const dispatch = useAppDispatch();

  const {
    global: { speakerProfile, credentials },
    livetipsOnline: { currentVideo },
  } = useAppSelector((state) => state);

  const [rate, asyncRes] = usePostRateMutation();

  const { triggerSnack } = useSnack();

  async function handleRate(rateNo: number) {
    if (!credentials.token.access_token) {
      triggerSnack("You Must Login !", "error");
      return;
    }
    /* you cannot rate yourself so ownerId !== customerId */
    const ownerId = currentVideo.user.customerId;
    const videoId = currentVideo?.id;
    const userId = speakerProfile.customerId;

    /* if (ownerId === userId) return; */

    try {
      const res = (await rate({
        token: credentials.token.access_token,
        data: {
          ownerId,
          digitalMediumType: MEDIA_TYPE.ON_DEMAND_VIDEO,
          userId,
          videoId,
          rateNo,
        },
      })) as unknown as { data: RatingType };

      if (asyncRes.isError) {
        dispatch(RatingSliceActions.revertRate());
        return;
      }

      dispatch(RatingSliceActions.submitRate(res.data.rateNo));
      dispatch(
        LivetipsOnlineSliceActions.submitCurrentVideoRate(res.data.rateNo)
      );
    } catch (error) {
      dispatch(RatingSliceActions.revertRate());
    }
  }

  return <Rate handleRate={handleRate} />;
};

export default VideoRate;
