import axios, { AxiosHeaderValue } from "axios";
import generateFormData from "./to_form_data";
import { baseUrl } from "store/api";
import { Methods } from "types/http";

type Props = {
  code: Methods;
  body: Record<string, File | string | number>;
  url: string;
  headers: Record<string, AxiosHeaderValue>;
};

type Res<T> = {
  data: T | null;
  error: boolean;
  statusCode?: number;
};

const handleFormDataRequests = async <T>(args: Props): Promise<Res<T>> => {
  let res;

  const { code, body, url, headers } = args;

  const formData = generateFormData(body);

  try {
    res = await axios[code](url, formData, {
      baseURL: baseUrl,
      headers,
    });
  } catch (error) {
    res = {
      data: null,
      error: true,
      statusCode: res?.status,
    };
    return res;
  }

  return { data: res.data, error: false, statusCode: res?.status };
};

export default handleFormDataRequests;
