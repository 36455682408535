import { FC, useEffect } from "react";
import { Rating, Typography } from "@mui/material";
import styles from "./styles";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { RatingSliceActions } from "store/rate_slice";
import { FormattedMessage } from "react-intl";
import messages from "store/hero_slice/messages";

type Props = {
  handleRate: (value: number) => void;
};

const Rate: FC<Props> = ({ handleRate }) => {
  const dispatch = useAppDispatch();

  const { rating } = useAppSelector(
    (state) => state.livetipsOnline.currentVideo
  );

  const getRate = (): number => {
    const rate = parseInt(rating as string);
    return isNaN(rate) ? 0 : rate;
  };

  useEffect(() => {
    return () => {
      dispatch(RatingSliceActions.clear());
    };
  }, [dispatch]);

  return (
    <>
      <Typography sx={styles.ratingLabel}>
        <FormattedMessage id={messages.rate.id} />
      </Typography>
      <Rating
        value={getRate()}
        onChange={(_, value) => handleRate(value ?? 0)}
        size="small"
        sx={styles.ratingContainer}
      />
      <Typography sx={styles.ratingValue}>{getRate()}</Typography>
    </>
  );
};

export default Rate;
