import { FC, useMemo } from "react";
import {
  Box,
  BoxProps,
  Grid,
  GridProps,
  SxProps,
  Typography,
} from "@mui/material";
import styles from "./styles";
import PodcastCardListItem from "components/podcast_card_list_item";
import combineStyles from "utils/combile_styles";
import { PodcastItemResponse } from "store/api/podcast_api/types";

type Props = {
  dimensions?: GridProps;
  label?: string;
  wrapperStyles?: BoxProps;
  gridContainerProps?: SxProps;
  data: Array<PodcastItemResponse>;
};

const PodcastList: FC<Props> = ({
  dimensions,
  data,
  label,
  wrapperStyles,
  gridContainerProps,
}) => {
  const gridStyle = useMemo(() => {
    return combineStyles(styles.gridContainer, gridContainerProps);
  }, [gridContainerProps]);

  return (
    <>
      {data && data.length > 0 && (
        <Box sx={{ ...styles.wrapper, ...wrapperStyles }}>
          {label && <Typography sx={styles.contentLabel}>{label}</Typography>}
          <Grid container sx={gridStyle}>
            {data.map((entry, index) => (
              <Grid
                key={index}
                item
                xs={12}
                sm={10}
                md={5.9}
                lg={4}
                xl={3.8}
                {...dimensions}
              >
                <PodcastCardListItem {...entry} />
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </>
  );
};

export default PodcastList;
