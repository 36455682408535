import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    width: {
      xs: "95%",
      lg: "100%",
      xl: "75%",
    },
    margin: "250px auto",
    display: "flex",
    flexDirection: {
      xs: "column",
      lg: "row",
    },
    columnGap: {
      xs: 3,
      lg: 2,
      xl: 2,
    },
    rowGap: {
      xs: 3,
    },
    justifyContent: "center",
    alignItems: "stretch",
  },
  blogTitle: {
    fontSize: {
      xs: 30,
      lg: 25,
      md: 35,
    },
    fontFamily: "Nizzar ,K2D, Inter",
    alignSelf: {
      md: "start",
    },
    paddingInlineStart: {
      md: 3,
    },
  },
  blogsContainer: {
    display: "flex",
    flexDirection: {
      xs: "column",
      md: "row",
    },
    alignItems: "center",
    justifyContent: "space-around",
    rowGap: {
      xs: 3,
      md: 0,
    },
    columnGap: {
      xs: 0,
      md: 1.5,
      xl: 1,
    },
  },
  gridContainer: { width: { lg: "60%", xl: "60%" } },
  paperContainer: {
    background: "url('src/assets/images/lessons_background.webp')",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top bottom",
    padding: 3,
    overflow: "hidden",
    borderRadius: 15,
    boxShadow: 2,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    rowGap: 3,
  },
  blogContainer: {
    padding: 2,
    overflow: "hidden",
    borderRadius: 15,
    boxShadow: 2,
    display: "flex",
    cursor: "pointer",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    rowGap: 1.5,
  },
  featuredLessonsContainer: {
    rowGap: {
      xs: 3,
      md: 0,
    },
    columnGap: {
      xs: 0,
      md: 3,
    },
    display: "flex",
    flexDirection: {
      xs: "column",
      md: "row",
    },
    alignItems: "center",
    justifyContent: "space-around",
  },
};

export default styles;
