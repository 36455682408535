import { FC } from "react";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import avatar from "assets/images/avatar_place.webp";
import styles from "./styles";
import Button from "components/custom_button";
import { useAppSelector } from "store/hooks";

type Props = {
  profile_picture?: string | null;
  username?: string;
  handleViewProfile?: () => void;
  handleEditVideo: () => void;
};

const UserInfoSection: FC<Props> = ({
  username,
  handleViewProfile,
  profile_picture,
  handleEditVideo,
}) => {
  const { currentVideo } = useAppSelector((state) => state.livetipsOnline);
  const { speakerProfile } = useAppSelector((state) => state.global);

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ width: "100%" }}
    >
      <Stack
        sx={{ cursor: "pointer" }}
        onClick={handleViewProfile ? () => handleViewProfile() : () => {}}
        direction="row"
        columnGap={1}
        alignItems="center"
      >
        <Avatar sx={styles.avatar} src={profile_picture ?? avatar} />
        <Typography sx={styles.speaker}>{username}</Typography>
      </Stack>
      {speakerProfile?.customerId == currentVideo?.user?.customerId && (
        <Button onClick={handleEditVideo} label="Edit Content" />
      )}
    </Stack>
  );
};

export default UserInfoSection;
