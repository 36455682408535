import rootApi from "store/api";
import { Nomination } from "./types";
import {
  getNomineeById,
  getNomineeList,
  submitNominee,
} from "./action_creators";
import { Identification } from "types/identification";

export const nomineeApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    addNominee: builder.mutation<
      Nomination,
      { body: Nomination; token: string }
    >({
      query: (body) => submitNominee(body),
    }),
    getNomineeById: builder.query<
      Nomination,
      { token: string; id: Identification }
    >({
      query: (body) => getNomineeById(body),
    }),
    getNomineeList: builder.query<Array<Nomination>, { token: string }>({
      query: (body) => getNomineeList(body),
    }),
  }),
});

export const {
  useAddNomineeMutation,
  useGetNomineeByIdQuery,
  useGetNomineeListQuery,
} = nomineeApi;
