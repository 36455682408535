import { defineMessages } from "react-intl";

const scope = "PodcastBodySection";

export default defineMessages({
  services: {
    id: `${scope}.services`,
    defaultMessage: "Services",
  },
  podcasts: {
    id: `${scope}.podcasts`,
    defaultMessage: "Podcasts",
  },
});
