import { FC } from "react";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import styles from "./styles";
import thumbNail from "assets/images/placeholders/thumbnail_pl.webp";
import { NotificationType } from "store/api/notification_service/types";

const NotificationListItem: FC<NotificationType> = (props) => {
  return (
    <>
      <Stack direction="row" alignItems="center" sx={styles.container}>
        <Avatar
          sx={{
            width: 80,
            height: 80,
          }}
          src={props.senderPic ?? thumbNail}
        />
        <Stack direction="column" rowGap={0.5}>
          <Typography sx={styles.header}>{props.senderName}</Typography>
          {props.action && (
            <Typography sx={styles.desc}>{props.messageEn}</Typography>
          )}
        </Stack>
      </Stack>
      <Divider sx={styles.divider} />
    </>
  );
};

export default NotificationListItem;
