import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Comment, CommentReaction } from "store/api/comment_service/types";
import { Identification } from "types/identification";
import { Reaction } from "types/reactions";

export const OPTIMISTIC_COMMENT_ID = 0;

type State = {
  commentList: Array<Comment>;
  myCommentReactions: Record<
    string,
    { commentId: Identification; userId: Identification; reaction: Reaction }
  >;
};

const initialState: State = {
  commentList: [],
  myCommentReactions: {},
};

const commentSlice = createSlice({
  name: "comment_slice",
  initialState,
  reducers: {
    pushCommentList: (
      state: State,
      {
        payload,
      }: PayloadAction<{ data: Array<Comment>; userId: Identification }>
    ) => {
      const { data, userId } = payload;
      state.commentList = data.toReversed();

      data.map((comment) => {
        comment.likes.forEach((entry) => {
          if (entry.userId == userId) {
            state.myCommentReactions[comment.commentId] = {
              commentId: comment.commentId,
              userId: comment.userId,
              reaction: "liked",
            };
          }
        });
        comment.dislikes.forEach((entry) => {
          if (entry.userId == userId) {
            state.myCommentReactions[comment.commentId] = {
              commentId: comment.commentId,
              userId: comment.userId,
              reaction: "disliked",
            };
          }
        });
      });
    },
    pushComment: (state: State, { payload }: PayloadAction<Comment>) => {
      state.commentList.unshift(payload);
      return state;
    },
    reply: (
      state: State,
      { payload }: PayloadAction<{ reply: Comment; parent: Comment }>
    ) => {
      const toReplyIndex = state.commentList.findIndex(
        (entry) => entry.commentId === payload.parent.commentId
      );
      state.commentList[toReplyIndex].children?.push(payload.reply);

      return state;
    },
    replaceReplyId: (
      state: State,
      {
        payload: { reply, parent },
      }: PayloadAction<{ reply: Identification; parent: Comment }>
    ) => {
      const toReplaceIndex = parent.children?.findIndex(
        (entry) => entry.commentId == OPTIMISTIC_COMMENT_ID
      );
      if (typeof toReplaceIndex == "number" && toReplaceIndex !== -1)
        parent.children![toReplaceIndex].commentId = reply;
      return state;
    },

    revertComment: (state: State) => {
      state.commentList = state.commentList.filter(
        (entry) => entry.commentId !== OPTIMISTIC_COMMENT_ID
      );
      return state;
    },
    revertCommentReply: (
      state: State,
      { payload }: PayloadAction<Identification>
    ) => {
      const parentCommentIndex = state.commentList.findIndex(
        (entry) => entry.commentId === payload
      );
      const parentComment = state.commentList[parentCommentIndex];

      parentComment.children?.filter(
        (entry) => entry.commentId !== OPTIMISTIC_COMMENT_ID
      );

      return state;
    },
    replaceCommentId: (state: State, { payload }: PayloadAction<Comment>) => {
      const toUpdateIndex = state.commentList.findIndex(
        (entry) => entry.commentId === OPTIMISTIC_COMMENT_ID
      );
      state.commentList[toUpdateIndex] = payload;
      state.myCommentReactions = {
        ...state.myCommentReactions,
        [payload.commentId]: {
          commentId: payload.commentId,
          userId: payload.userId,
          reaction: "idle" as Reaction,
        },
      };
      return state;
    },
    likeComment: (
      state: State,
      {
        payload,
      }: PayloadAction<{ commentId: Identification; userId: Identification }>
    ) => {
      const previousReaction =
        state.myCommentReactions[payload.commentId].reaction;
      if (previousReaction !== "liked") {
        state.myCommentReactions = {
          ...state.myCommentReactions,
          [payload.commentId]: {
            commentId: payload.commentId,
            userId: payload.userId,
            reaction: "liked" as Reaction,
          },
        };
        state.commentList.forEach((entry) => {
          if (entry.commentId == payload.commentId) {
            switch (previousReaction) {
              case "idle": {
                entry.likesCount++;
                break;
              }
              case "disliked": {
                entry.dislikesCount--;
                entry.likesCount++;
                break;
              }
            }
          }
        });
      }
      return state;
    },
    pushLike: (state: State, { payload }: PayloadAction<CommentReaction>) => {
      state.commentList.forEach((entry) => {
        if (entry.userId === payload.userId) {
          entry.likes.push(payload);
        }
      });
      return state;
    },
    pushDisLike: (
      state: State,
      { payload }: PayloadAction<CommentReaction>
    ) => {
      state.commentList.forEach((entry) => {
        if (entry.userId === payload.userId) {
          entry.dislikes.push(payload);
        }
      });
      return state;
    },
    disLikeComment: (
      state: State,
      {
        payload,
      }: PayloadAction<{ commentId: number | string; userId: string | number }>
    ) => {
      const previousReaction =
        state.myCommentReactions[payload.commentId].reaction;
      if (previousReaction !== "disliked") {
        state.myCommentReactions = {
          ...state.myCommentReactions,
          [payload.commentId]: {
            commentId: payload.commentId,
            userId: payload.userId,
            reaction: "disliked" as Reaction,
          },
        };
        state.commentList.forEach((entry) => {
          if (entry.commentId == payload.commentId) {
            switch (previousReaction) {
              case "idle": {
                entry.dislikesCount++;
                break;
              }
              case "liked": {
                entry.likesCount--;
                entry.dislikesCount++;
                break;
              }
            }
          }
        });
      }
      return state;
    },
  },
});

export default commentSlice.reducer;
export const commentSliceActions = commentSlice.actions;
