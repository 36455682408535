import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {},
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 100000,
  },
};

export default styles;