import { defineMessages } from "react-intl";

const scope = "OnlineVideoDetails";

export default defineMessages({
  share: {
    id: `${scope}.share`,
    defaultMessage: "Share",
  },
  reacted: {
    id: `${scope}.reacted`,
    defaultMessage: "Reacted to your Video",
  },
  save: {
    id: `${scope}.save`,
    defaultMessage: "Save",
  },
  comment_submitted: {
    id: `${scope}.comment_submitted`,
    defaultMessage: "Comment Submitted, stay tuned !",
  },
  failed_comment_submitted: {
    id: `${scope}.failed_comment_submitted`,
    defaultMessage: "Failed to save comment try again !",
  },
  related_videos: {
    id: `${scope}.related_videos`,
    defaultMessage: "Related Videos",
  },
  most_viewed: {
    id: `${scope}.most_viewed`,
    defaultMessage: "Most Viewed",
  },
  write_comment: {
    id: `${scope}.write_comment`,
    defaultMessage: "Write Comment",
  },
  unexpected_error: {
    id: `${scope}.unexpected_error`,
    defaultMessage: "Something Unexpected happened ! please try again",
  },
  views: {
    id: `${scope}.views`,
    defaultMessage: "Views",
  },
  shared: {
    id: `${scope}.shared`,
    defaultMessage: "Shared",
  },
  saved: {
    id: `${scope}.saved`,
    defaultMessage: "Saved",
  },
  comment: {
    id: `${scope}.comment`,
    defaultMessage: "Comment",
  },
  click_more: {
    id: `${scope}.click_more`,
    defaultMessage: "click to see more...",
  },
  no_comments: {
    id: `${scope}.no_comments`,
    defaultMessage: "Found No Comments",
  },
});
