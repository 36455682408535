import { FC, PropsWithChildren } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Grow from "@mui/material/Grow";
import styles from "./styles";
import redLogo from "assets/images/red_logo.webp";
import police from "assets/images/police.webp";
import online_image1 from "assets/images/placeholders/thumbnail_pl.webp";
import { Upcoming } from "store/api/streaming_service/types";
import { Avatar, Rating } from "@mui/material";
import color_pallette from "theme/color_pallette";
import Button from "components/custom_button";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "navigation/routes";
import { useAppDispatch } from "store/hooks";
import { channelSliceActions } from "store/channel_slice";

export type LiveOnlineProps = {
  itemData: PropsWithChildren<Upcoming & { policeDepartement?: boolean }>;
};

const UpcomingLiveCard: FC<LiveOnlineProps> = ({ itemData }) => {
  const { policeDepartement, user, id, thumbnail } = itemData;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleJoinStream = async () => {
    try {
      dispatch(channelSliceActions.setUpcoming(itemData));
      navigate(`${NavigationRoutes.streaming_online_page}/${id}`);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Grow in timeout={600}>
      <Paper sx={styles.container}>
        <Stack sx={styles.cardHeader}>
          <Typography sx={styles.cardHeaderLabel}>Live Interactive</Typography>
        </Stack>
        <Stack
          paddingInlineStart={3}
          direction="row"
          paddingBlock={1.5}
          paddingInlineEnd={3}
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" columnGap={1} alignItems="center">
            <Avatar src={user.profilePicture} sx={{ height: 50, width: 50 }} />
            <Box>
              <Typography sx={styles.name}>{user.name}</Typography>
              <Typography sx={{ fontSize: { lg: 13 } }}>{user.org}</Typography>
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center">
            <Typography sx={styles.ratingLabel}>Rating</Typography>
            <Rating
              sx={{ marginInline: 0.5, color: color_pallette.primary }}
              size="small"
              readOnly
              defaultValue={1}
              value={parseInt(itemData.rating)}
            />
            <Typography sx={styles.rate}>{3}</Typography>
          </Stack>
          {policeDepartement && (
            <Box>
              <Box
                sx={{ width: 50, objectFit: "scale-down", height: 30 }}
                component="img"
                alt="Police Departement Logo"
                src={police}
              />
              <Box
                sx={{ width: 50, objectFit: "contain", height: 30 }}
                component="img"
                alt="Police Departement Logo"
                src={redLogo}
              />
            </Box>
          )}
        </Stack>
        <Box
          sx={styles.img}
          component="img"
          alt="Live Tips Events Types"
          src={thumbnail ?? online_image1}
        />
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          paddingInlineEnd={3}
          sx={styles.childrenWrapper}
        >
          <Typography
            sx={{ maxWidth: "60%", fontSize: { lg: 14 }, fontWeight: "700" }}
          >
            {itemData.description}
          </Typography>
          <Button
            label="Join Now"
            onClick={() => handleJoinStream()}
            extraStyles={{ height: 30 }}
          />
        </Stack>
      </Paper>
    </Grow>
  );
};

export default UpcomingLiveCard;
