import { FC, useCallback } from "react";
import { Box, Breakpoint, Link, Paper, Typography } from "@mui/material";
import styles from "./styles";
import { useAppSelector } from "store/hooks";
import withSwiper from "hoc/withSwiper";
import ExploreMemberCard, {
  ExploreCardProps,
} from "components/explore_member_card";
import ExploreMember from "types/police_member";
import { EffectCards } from "swiper/modules";
import { useWidth } from "hooks/useWidth";
import { NavigationRoutes } from "navigation/routes";
import { FormattedMessage } from "react-intl";
import messages from "pages/register_page/messages";

const SwiperCard: FC = () => {
  const br = useWidth();

  const getPaddingInline = (br: Breakpoint): number => {
    if (br === "xs") return 60;
    if (br === "sm") return 230;
    if (br === "md") return 70;
    return 200;
  };

  const getOffset = (br: Breakpoint): number => {
    if (br === "sm") return 30;
    if (br === "md" || br === "xs") return 10;
    return 30;
  };

  const { exploreMembers } = useAppSelector((state) => state.adminProfil);

  const renderSwiper = useCallback(() => {
    return withSwiper<ExploreCardProps, ExploreMember>(
      ExploreMemberCard,
      exploreMembers
    )({
      componentProps: {} as ExploreCardProps,
      swiperProps: {
        effect: "cards",
        modules: [EffectCards],
        cardsEffect: {
          slideShadows: false,
          perSlideOffset: getOffset(br),
          perSlideRotate: 0,
        },
        initialSlide: 2,
        style: {
          paddingInline: getPaddingInline(br),
          paddingBlock: 30,
        },
        grabCursor: true,
        centeredSlides: true,
        centeredSlidesBounds: true,
        freeMode: true,
        autoplay: false,
        autoFocus: true,
        navigation: false,
        loop: true,
      },
    });
  }, [exploreMembers, br]);

  return (
    <Link
      sx={{ textDecoration: "none", ...styles.container }}
      href={NavigationRoutes.police_official_website}
      target="_blank"
    >
      <Paper sx={styles.container}>
        <Box sx={styles.header}>
          <Typography sx={styles.headerTitle}>
            <FormattedMessage id={messages.explore_dp.id} />
          </Typography>
        </Box>
        {renderSwiper()}
      </Paper>
    </Link>
  );
};

export default SwiperCard;
