import { FC } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import Typography from "@mui/material/Typography";
import styles from "./styles";
import cropText from "utils/crop_text";

type Props = {
  image: string;
  thumbnailText: string;
  speakerAvatar: string;
  speakerName: string;
};

const HeaderSection: FC<Props> = ({
  image,
  thumbnailText,
  speakerAvatar,
  speakerName,
}) => {
  const theme = useTheme();
  const upLarge = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Box sx={styles.container}>
      <Box
        component="img"
        src={image}
        alt="interactive item image"
        style={{
          borderRadius: "10px",
          aspectRatio: "3 / 4",
          objectFit: "cover",
          height: upLarge ? 290 : 350,
        }}
      />
      <Box sx={styles.thumbContainer}>
        <Typography sx={styles.captionText}>
          {cropText(thumbnailText, 120)}
        </Typography>
      </Box>
      <Box sx={styles.avatarContainer}>
        <Avatar src={speakerAvatar} sx={styles.speakerAvatar} />
        <Typography sx={styles.avatarLabel}>{speakerName}</Typography>
      </Box>
    </Box>
  );
};

export default HeaderSection;
