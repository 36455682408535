import { defineMessages } from "react-intl";

const scope = "LoginForm";

export default defineMessages({
  username: {
    id: `${scope}.username`,
    defaultMessage: "Username",
  },
  usernamePlaceholder: {
    id: `${scope}.usernamePlaceholder`,
    defaultMessage: "username",
  },
  register: {
    id: `${scope}.register`,
    defaultMessage: "Register",
  },
  password: {
    id: `${scope}.password`,
    defaultMessage: "Password",
  },
  passwordPlaceholder: {
    id: `${scope}.passwordPlaceholder`,
    defaultMessage: "Password",
  },
  submitLabel: {
    id: `${scope}.submitLabel`,
    defaultMessage: "Submit",
  },
  full_name: {
    id: `${scope}.full_name`,
    defaultMessage: "Full name",
  },
  have_account_already: {
    id: `${scope}.have_account_already`,
    defaultMessage: "Already Have an account ?",
  },
  login: {
    id: `${scope}.login`,
    defaultMessage: "Login",
  },
  email: {
    id: `${scope}.email`,
    defaultMessage: "email",
  },
  nationality: {
    id: `${scope}.nationality`,
    defaultMessage: "Nationality",
  },
  country: {
    id: `${scope}.country`,
    defaultMessage: "Country",
  },
  univ: {
    id: `${scope}.univ`,
    defaultMessage: "University / Organization",
  },
  country_code: {
    id: `${scope}.country_code`,
    defaultMessage: "Country Code",
  },
  phone: {
    id: `${scope}.phone`,
    defaultMessage: "Phone Number",
  },
  choose_country_code: {
    id: `${scope}.choose_country_code`,
    defaultMessage: "Choose Country Code",
  },
});
