import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    paddingBlock: 5,
  },
  inputWrapper: { width: "40%" },
};

export default styles;
