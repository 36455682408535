import * as yup from "yup";

export enum COMMENT_FORM {
  comment = "comment",
}

const validationSchema: yup.Schema = yup.object({
  [COMMENT_FORM.comment]: yup.string().max(200),
});

const initialValues: yup.InferType<typeof validationSchema> = {
  [COMMENT_FORM.comment]: "",
};

export { validationSchema, initialValues };
