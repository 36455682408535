import { FC } from "react";
import styles from "./styles";
import ReactPlayer from "react-player";
import { Stack } from "@mui/material";

const VideoIntro: FC = () => {
  return (
    <Stack
      rowGap={5}
      direction="column"
      alignItems="center"
      sx={styles.videoContainer}
    >
      <ReactPlayer
        muted={false}
        playing={false}
        controls={false}
        stopOnUnmount
        width="100%"
        height="100%"
        style={{ aspectRatio: "16 / 9" }}
        url="https://www.youtube.com/watch?v=VE75g0frvr4"
      />
    </Stack>
  );
};

export default VideoIntro;
