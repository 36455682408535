import { FC, useState } from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Slider from "@mui/material/Slider";
import Box from "@mui/material/Box";
import { ReactSVG } from "react-svg";
import audioBack from "assets/svgs/audio_back.svg";
import audioforward from "assets/svgs/audio_forward.svg";
import volume from "assets/svgs/audio_volume.svg";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import PauseRoundedIcon from "@mui/icons-material/PauseRounded";
import useAudio from "hooks/useAudio";
import AudioTracker from "components/audio_tracker";
import styles from "./styles";
import { useGetPodcastViewsCountQuery } from "store/api/view_service/endpoints";
import Button from "components/custom_button";
import { useAppSelector } from "store/hooks";
import { Identification } from "types/identification";

type Props = {
  profilePic: string | null;
  hostName: string;
  userId: Identification;
  handleEditContent?: () => void;
};

const PodcastControls: FC<Props> = (props) => {
  const [skipBack, setSkipBack] = useState<boolean>(false);
  const [skipForward, setSkipForward] = useState<boolean>(false);

  const { speakerProfile, credentials } = useAppSelector(
    (state) => state.global
  );

  const {
    handleToggleAudio,
    handleAudioBack,
    handleAudioForward,
    handleAudioPlaySpeed,
    getAudioDuration,
    handleAudioVolume,
    currentRecord,
  } = useAudio();

  const handleBack = () => {
    setSkipBack(true);
    handleAudioBack();
    const timer = setTimeout(() => {
      setSkipBack(false);
    }, 1500);

    clearTimeout(timer);
  };

  const handleForward = () => {
    setSkipForward(true);
    handleAudioForward();
    const timer = setTimeout(() => {
      setSkipForward(false);
    }, 1500);

    clearTimeout(timer);
  };

  const { data } = useGetPodcastViewsCountQuery(
    {
      id: currentRecord.podcastId,
    },
    {
      refetchOnFocus: true,
      pollingInterval: 3000,
      refetchOnMountOrArgChange: true,
    }
  );

  return (
    <Box sx={styles.progress}>
      <Stack
        direction={{ xs: "row", md: "row" }}
        justifyContent="space-around"
        alignItems={{ xs: "center" }}
        rowGap={2}
        flexWrap="wrap"
        sx={styles.container}
      >
        <Stack
          order={{ xs: 2, md: 1 }}
          columnGap={{ xs: 3, md: 5 }}
          alignItems="center"
          direction="row"
        >
          <Box onClick={handleAudioPlaySpeed}>
            <Typography sx={styles.playbackSpeed}>
              {currentRecord.playbackSpeed}x
            </Typography>
          </Box>
          <Stack
            sx={{ cursor: "pointer" }}
            columnGap={1}
            onClick={handleBack}
            direction="row"
            alignItems="center"
          >
            <ReactSVG src={audioBack} />
            <Typography>15s</Typography>
          </Stack>
          <Box sx={{ cursor: "pointer" }} onClick={handleToggleAudio}>
            {currentRecord.playing ? (
              <PauseRoundedIcon sx={styles.icons} />
            ) : (
              <PlayArrowRoundedIcon sx={styles.icons} />
            )}
          </Box>
          <Stack
            sx={{ cursor: "pointer" }}
            columnGap={1}
            direction="row"
            onClick={handleForward}
            alignItems="center"
          >
            <ReactSVG src={audioforward} />
            <Typography>15s</Typography>
          </Stack>
          <Stack
            direction="row"
            columnGap={2}
            alignItems="center"
            sx={{ cursor: "pointer" }}
          >
            <ReactSVG src={volume} />
            <Slider
              onChange={(_: Event, value: number | number[]) =>
                handleAudioVolume(value as number)
              }
              aria-label="Podcast Volume"
              sx={{ width: 150 }}
              defaultValue={0.5}
              valueLabelDisplay="auto"
              step={0.2}
              marks
              min={0.1}
              max={1}
            />
          </Stack>
        </Stack>
        <Typography sx={{ fontSize: { lg: 20 }, fontWeight: "500" }}>
          {data ? data : 0} Listeners
        </Typography>
        <Stack
          order={{ xs: 1, md: 2 }}
          columnGap={2}
          alignItems="center"
          direction="row"
        >
          {credentials.token.access_token &&
            speakerProfile.customerId == props.userId && (
              <Button
                label="Edit Content"
                onClick={
                  props.handleEditContent ? props.handleEditContent : () => {}
                }
              />
            )}
        </Stack>
      </Stack>
      <AudioTracker
        playRate={currentRecord.playbackSpeed}
        recordLength={getAudioDuration()}
        playing={currentRecord.playing}
        completed={handleToggleAudio}
        skipBackword={skipBack}
        skipForward={skipForward}
      />
    </Box>
  );
};

export default PodcastControls;
