import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    width: { lg: 500, xl: 900 },
  },
  emptyLabel: {
    fontWeight: "600",
    fontSize: 25,
    textAlign: "center",
  },
};

export default styles;
