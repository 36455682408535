import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {},
  notifyButton: {
    borderRadius: 10,
    fontSize: {
      xs: 12,
    },
    marginInlineStart: 2,
  },
  avatar: {
    width: 35,
    height: 35,
  },
  speaker: {
    fontWeight: "700",
  },
};

export default styles;
