import { FC, useEffect } from "react";
import Box from "@mui/material/Box";
import styles from "./styles";
import SwiperCard from "components/swiper_card";
import ProfileActions from "../profile_actions";
import UploadVideoModal from "../create_online/upload_video_modal";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { ProfileSliceActions } from "store/profile_slice";
import ConfirmationModal from "../create_online/confirmation_modal";
import PodcastConfirmationModal from "../create_podcast/confirmation_modal";
import FormSection from "./form_section";
import { UploadedFileStatus } from "types/uploaded_file_status";
import { QueryStatus } from "@reduxjs/toolkit/query";
import { useRefreshTokenMutation } from "store/api/identity_service/endpoints";
import CallToJoin from "../call_to_join";
import UploadPodcastModal from "../create_podcast/upload_podcast_modal";
import { LivetipsPodcastSliceActions } from "store/livetips_podcast_slice";
import useThumbnail from "hooks/useThumbnail";
import StepGuidanceModal from "../create_podcast/step_guidance_modal";
import useSnack from "hooks/useSnack";
import SnackBar from "components/snack_bar";
import StepGuidanceModalOnline from "../create_online/step_guidance_modal";
import { useIntl } from "react-intl";
import messages from "./messages";

const ActionSection: FC = () => {
  const { extractThumbnail } = useThumbnail();

  const { access_token } = useAppSelector(
    (state) => state.global.credentials.token
  );

  const { currentMember } = useAppSelector((state) => state.adminProfil);

  const { triggerSnack } = useSnack();

  const [refresh] = useRefreshTokenMutation();

  useEffect(() => {
    if (status === QueryStatus.rejected && access_token)
      refresh({ token: access_token });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, access_token]);

  const dispatch = useAppDispatch();

  const handleFileUpload = async (file: File, path: string) => {
    dispatch(ProfileSliceActions.uploadFile({ file, path }));
  };

  const intl = useIntl();

  const handleOnlineFileUpload = async (file: File, path: string) => {
    handleFileUpload(file, path);
    try {
      await extractThumbnail(file);
      dispatch(ProfileSliceActions.showEditUploadModal());
    } catch (error) {
      triggerSnack(intl.formatMessage(messages.error), "error");
    }
  };

  return (
    <Box sx={styles.container}>
      <UploadVideoModal
        handleUploadedFile={(file: File, path: string) =>
          handleOnlineFileUpload(file, path)
        }
      />
      <UploadPodcastModal
        handleUploadedFile={(file: File, path: string) => {
          handleFileUpload(file, path);
          dispatch(LivetipsPodcastSliceActions.showEditModal());
        }}
      />
      <FormSection />
      <ConfirmationModal />
      <PodcastConfirmationModal />
      <StepGuidanceModal />
      <StepGuidanceModalOnline />
      <SnackBar />
      <SwiperCard />
      {currentMember != null &&
      currentMember.status === UploadedFileStatus.ACCEPTED ? (
        <ProfileActions />
      ) : (
        <CallToJoin />
      )}
    </Box>
  );
};

export default ActionSection;
