import { fetchFile } from "@ffmpeg/util";
import ThumbContext from "context/ThumbnailContext";
import { useContext } from "react";

const inputPath = "video.mp4";
const outputPath = "image.jpg";

const useThumbnail = () => {
  const {
    mpegInstance: ffmpeg,
    setLoading,
    setThumb,
  } = useContext(ThumbContext);

  const extractThumbnail = async (file: File): Promise<void> => {
    if (ffmpeg === null) return;

    setLoading(true);

    try {
      await ffmpeg.writeFile(inputPath, await fetchFile(file));

      await ffmpeg.exec(["-ss", "1", "-i", inputPath, outputPath]);

      await ffmpeg.readFile(outputPath).then((value: any) => {
        const url = new Blob([value.buffer], { type: "video/mp4" });
        setThumb(URL.createObjectURL(url));
      });

    } catch (error) {
      console.log("WRITING FILE ERROR  ", error);
    }

    setLoading(false);
  };

  return { extractThumbnail };
};

export default useThumbnail;
