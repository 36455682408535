import * as yup from "yup";

export enum WATCHNWO_FORM_NAME {
  TYPE = "type",
  ONLINE = "online",
  LOCATION = "location",
}

const validationSchema: yup.Schema = yup.object({
  [WATCHNWO_FORM_NAME.TYPE]: yup.string(),
  [WATCHNWO_FORM_NAME.ONLINE]: yup.string(),
  [WATCHNWO_FORM_NAME.LOCATION]: yup.string(),
});

const initialValues: yup.InferType<typeof validationSchema> = {
  [WATCHNWO_FORM_NAME.TYPE]: "",
  [WATCHNWO_FORM_NAME.ONLINE]: "",
  [WATCHNWO_FORM_NAME.LOCATION]: "",
};

export { validationSchema, initialValues };
