import { defineMessages } from 'react-intl';

const scope = 'CommentSection';

export default defineMessages({
  ask: {
    id: `${scope}.ask`,
    defaultMessage: "Ask To",
  },
  save: {
    id: `${scope}.save`,
    defaultMessage: "save",
  },
  share: {
    id: `${scope}.share`,
    defaultMessage: "share",
  },
  error: {
    id: `${scope}.error`,
    defaultMessage: "Unexpected Error Occured !",
  },
  comments: {
    id: `${scope}.comments`,
    defaultMessage: "Comments",
  },
  comment_success: {
    id: `${scope}.error`,
    defaultMessage: "Unexpected Error Occured !",
  },
  commented: {
    id: `${scope}.commented`,
    defaultMessage: "Commented on your profile",
  },
  write_comment: {
    id: `${scope}.write_comment`,
    defaultMessage: "Write Comment",
  },
  replies: {
    id: `${scope}.replies`,
    defaultMessage: "Replies",
  },
  no_comments: {
    id: `${scope}.no_comments`,
    defaultMessage: "No Comments",
  },
  submit: {
    id: `${scope}.submit`,
    defaultMessage: "Submit",
  },
});