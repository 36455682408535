import { FC } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import Button from "components/custom_button";
import color_pallette from "theme/color_pallette";
import styles from "./styles";

type Props = {
  rate: number;
  image: string;
  handleViewProfile: () => void;
};

const HeaderSection: FC<Props> = ({ image, handleViewProfile, rate }) => {
  return (
    <Box sx={styles.container}>
      <Box
        component="img"
        alt="upcoming podcast event image"
        sx={styles.image}
        src={image}
      />
      <Box sx={styles.headerFooter}>
        <Button
          variant="outlined"
          label="View Profile"
          onClick={handleViewProfile}
          extraStyles={styles.button}
        />
        <Box display="inherit" alignItems="inherit">
          <Typography sx={styles.ratingLabel}>Rating {rate}</Typography>
          <Rating
            sx={{ color: color_pallette.primary, marginInlineStart: 1 }}
            size="small"
            readOnly
            value={rate}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default HeaderSection;
