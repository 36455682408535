import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    marginTop: 10,
  },
  header: {
    fontSize: {
      xs: 40,
    },
    marginBottom: 5,
    textAlign: "center",
  },
  seperator: {
    marginBlock: 4,
  },
};

export default styles;
