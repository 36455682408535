import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    zIndex: 100,
    width: "100%",
    position: "absolute",
    backgroundColor: "#FFFFFF",
    top: 100,
    left: 0,
    borderTop: "2px solid #D6ECEC",
    paddingBottom: 5,
    boxShadow: 2,
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
  },
};

export default styles;
