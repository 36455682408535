import * as yup from "yup";

export enum EditVideoFormNames {
  videoTitle = "title",
  description = "description",
  category = "category",
  exclusivity = "exclusivity",
}

const validationSchema: yup.Schema = yup.object({
  [EditVideoFormNames.videoTitle]: yup.string().required(),
  [EditVideoFormNames.description]: yup.string().required(),
  [EditVideoFormNames.category]: yup.string(),
  [EditVideoFormNames.exclusivity]: yup.string(),
});

const initialValues: yup.InferType<typeof validationSchema> = {
  [EditVideoFormNames.videoTitle]: "",
  [EditVideoFormNames.description]: "",
  [EditVideoFormNames.category]: "1",
  [EditVideoFormNames.exclusivity]: "1",
};

export { validationSchema, initialValues };
