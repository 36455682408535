import { SxProps } from "@mui/material";

const styles: Record<string, SxProps> = {
  container: {
    maxWidth: {
      xs: "90%",
      lg: 1350,
      xl: 1400,
    },
    marginX: "auto",
    textAlign: { xs: "center", md: "start" },
  },
};

export default styles;
