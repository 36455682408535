import { defineMessages } from "react-intl";

const scope = "InteractiveListItem";

export default defineMessages({
  listen: {
    id: `${scope}.listen`,
    defaultMessage: "Listen",
  },
  favorites: {
    id: `${scope}.favorites`,
    defaultMessage: "Favorites",
  },
  share: {
    id: `${scope}.share`,
    defaultMessage: "Share",
  },
});
